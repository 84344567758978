(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.cY.bd === region.dR.bd)
	{
		return 'on line ' + region.cY.bd;
	}
	return 'on lines ' + region.cY.bd + ' through ' + region.dR.bd;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hn,
		impl.jC,
		impl.jl,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		hK: func(record.hK),
		c_: record.c_,
		cO: record.cO
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.hK;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.c_;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.cO) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hn,
		impl.jC,
		impl.jl,
		function(sendToApp, initialModel) {
			var view = impl.jN;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hn,
		impl.jC,
		impl.jl,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.cW && impl.cW(sendToApp)
			var view = impl.jN;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.gs);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.fx) && (_VirtualDom_doc.title = title = doc.fx);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.h7;
	var onUrlRequest = impl.h8;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		cW: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.eS === next.eS
							&& curr.he === next.he
							&& curr.eN.a === next.eN.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		hn: function(flags)
		{
			return A3(impl.hn, flags, _Browser_getUrl(), key);
		},
		jN: impl.jN,
		jC: impl.jC,
		jl: impl.jl
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { hd: 'hidden', gA: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { hd: 'mozHidden', gA: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { hd: 'msHidden', gA: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { hd: 'webkitHidden', gA: 'webkitvisibilitychange' }
		: { hd: 'hidden', gA: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		e3: _Browser_getScene(),
		fM: {
			jW: _Browser_window.pageXOffset,
			j4: _Browser_window.pageYOffset,
			jS: _Browser_doc.documentElement.clientWidth,
			hb: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		jS: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		hb: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			e3: {
				jS: node.scrollWidth,
				hb: node.scrollHeight
			},
			fM: {
				jW: node.scrollLeft,
				j4: node.scrollTop,
				jS: node.clientWidth,
				hb: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			e3: _Browser_getScene(),
			fM: {
				jW: x,
				j4: y,
				jS: _Browser_doc.documentElement.clientWidth,
				hb: _Browser_doc.documentElement.clientHeight
			},
			gU: {
				jW: x + rect.left,
				j4: y + rect.top,
				jS: rect.width,
				hb: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.dU.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.dU.b, xhr)); });
		$elm$core$Maybe$isJust(request.jz) && _Http_track(router, xhr, request.jz.a);

		try {
			xhr.open(request.ex, request.fE, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.fE));
		}

		_Http_configureRequest(xhr, request);

		request.gs.a && xhr.setRequestHeader('Content-Type', request.gs.a);
		xhr.send(request.gs.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.d3; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.jx.a || 0;
	xhr.responseType = request.dU.d;
	xhr.withCredentials = request.f4;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		fE: xhr.responseURL,
		i5: xhr.status,
		i6: xhr.statusText,
		d3: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			i_: event.loaded,
			fg: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			iy: event.loaded,
			fg: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


// BYTES

function _Bytes_width(bytes)
{
	return bytes.byteLength;
}

var _Bytes_getHostEndianness = F2(function(le, be)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(new Uint8Array(new Uint32Array([1]))[0] === 1 ? le : be));
	});
});


// ENCODERS

function _Bytes_encode(encoder)
{
	var mutableBytes = new DataView(new ArrayBuffer($elm$bytes$Bytes$Encode$getWidth(encoder)));
	$elm$bytes$Bytes$Encode$write(encoder)(mutableBytes)(0);
	return mutableBytes;
}


// SIGNED INTEGERS

var _Bytes_write_i8  = F3(function(mb, i, n) { mb.setInt8(i, n); return i + 1; });
var _Bytes_write_i16 = F4(function(mb, i, n, isLE) { mb.setInt16(i, n, isLE); return i + 2; });
var _Bytes_write_i32 = F4(function(mb, i, n, isLE) { mb.setInt32(i, n, isLE); return i + 4; });


// UNSIGNED INTEGERS

var _Bytes_write_u8  = F3(function(mb, i, n) { mb.setUint8(i, n); return i + 1 ;});
var _Bytes_write_u16 = F4(function(mb, i, n, isLE) { mb.setUint16(i, n, isLE); return i + 2; });
var _Bytes_write_u32 = F4(function(mb, i, n, isLE) { mb.setUint32(i, n, isLE); return i + 4; });


// FLOATS

var _Bytes_write_f32 = F4(function(mb, i, n, isLE) { mb.setFloat32(i, n, isLE); return i + 4; });
var _Bytes_write_f64 = F4(function(mb, i, n, isLE) { mb.setFloat64(i, n, isLE); return i + 8; });


// BYTES

var _Bytes_write_bytes = F3(function(mb, offset, bytes)
{
	for (var i = 0, len = bytes.byteLength, limit = len - 4; i <= limit; i += 4)
	{
		mb.setUint32(offset + i, bytes.getUint32(i));
	}
	for (; i < len; i++)
	{
		mb.setUint8(offset + i, bytes.getUint8(i));
	}
	return offset + len;
});


// STRINGS

function _Bytes_getStringWidth(string)
{
	for (var width = 0, i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		width +=
			(code < 0x80) ? 1 :
			(code < 0x800) ? 2 :
			(code < 0xD800 || 0xDBFF < code) ? 3 : (i++, 4);
	}
	return width;
}

var _Bytes_write_string = F3(function(mb, offset, string)
{
	for (var i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		offset +=
			(code < 0x80)
				? (mb.setUint8(offset, code)
				, 1
				)
				:
			(code < 0x800)
				? (mb.setUint16(offset, 0xC080 /* 0b1100000010000000 */
					| (code >>> 6 & 0x1F /* 0b00011111 */) << 8
					| code & 0x3F /* 0b00111111 */)
				, 2
				)
				:
			(code < 0xD800 || 0xDBFF < code)
				? (mb.setUint16(offset, 0xE080 /* 0b1110000010000000 */
					| (code >>> 12 & 0xF /* 0b00001111 */) << 8
					| code >>> 6 & 0x3F /* 0b00111111 */)
				, mb.setUint8(offset + 2, 0x80 /* 0b10000000 */
					| code & 0x3F /* 0b00111111 */)
				, 3
				)
				:
			(code = (code - 0xD800) * 0x400 + string.charCodeAt(++i) - 0xDC00 + 0x10000
			, mb.setUint32(offset, 0xF0808080 /* 0b11110000100000001000000010000000 */
				| (code >>> 18 & 0x7 /* 0b00000111 */) << 24
				| (code >>> 12 & 0x3F /* 0b00111111 */) << 16
				| (code >>> 6 & 0x3F /* 0b00111111 */) << 8
				| code & 0x3F /* 0b00111111 */)
			, 4
			);
	}
	return offset;
});


// DECODER

var _Bytes_decode = F2(function(decoder, bytes)
{
	try {
		return $elm$core$Maybe$Just(A2(decoder, bytes, 0).b);
	} catch(e) {
		return $elm$core$Maybe$Nothing;
	}
});

var _Bytes_read_i8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getInt8(offset)); });
var _Bytes_read_i16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getInt16(offset, isLE)); });
var _Bytes_read_i32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getInt32(offset, isLE)); });
var _Bytes_read_u8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getUint8(offset)); });
var _Bytes_read_u16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getUint16(offset, isLE)); });
var _Bytes_read_u32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getUint32(offset, isLE)); });
var _Bytes_read_f32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getFloat32(offset, isLE)); });
var _Bytes_read_f64 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 8, bytes.getFloat64(offset, isLE)); });

var _Bytes_read_bytes = F3(function(len, bytes, offset)
{
	return _Utils_Tuple2(offset + len, new DataView(bytes.buffer, bytes.byteOffset + offset, len));
});

var _Bytes_read_string = F3(function(len, bytes, offset)
{
	var string = '';
	var end = offset + len;
	for (; offset < end;)
	{
		var byte = bytes.getUint8(offset++);
		string +=
			(byte < 128)
				? String.fromCharCode(byte)
				:
			((byte & 0xE0 /* 0b11100000 */) === 0xC0 /* 0b11000000 */)
				? String.fromCharCode((byte & 0x1F /* 0b00011111 */) << 6 | bytes.getUint8(offset++) & 0x3F /* 0b00111111 */)
				:
			((byte & 0xF0 /* 0b11110000 */) === 0xE0 /* 0b11100000 */)
				? String.fromCharCode(
					(byte & 0xF /* 0b00001111 */) << 12
					| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
					| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
				)
				:
				(byte =
					((byte & 0x7 /* 0b00000111 */) << 18
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 12
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
						| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
					) - 0x10000
				, String.fromCharCode(Math.floor(byte / 0x400) + 0xD800, byte % 0x400 + 0xDC00)
				);
	}
	return _Utils_Tuple2(offset, string);
});

var _Bytes_decodeFailure = F2(function() { throw 0; });


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.hP) { flags += 'm'; }
	if (options.gy) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $author$project$Messages$OnUrlChange = function (a) {
	return {$: 25, a: a};
};
var $author$project$Messages$OnUrlRequest = function (a) {
	return {$: 24, a: a};
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.j) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.t),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.t);
		} else {
			var treeLen = builder.j * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.B) : builder.B;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.j);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.t) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.t);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{B: nodeList, j: (len / $elm$core$Array$branchFactor) | 0, t: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {d_: fragment, he: host, ig: path, eN: port_, eS: protocol, is: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Effect$NoEffect = {$: 0};
var $author$project$Effect$PerformLaterEffect = F2(
	function (a, b) {
		return {$: 45, a: a, b: b};
	});
var $author$project$Messages$PlayAllAutoplaySpeech = function (a) {
	return {$: 56, a: a};
};
var $author$project$Effect$BatchedEffect = function (a) {
	return {$: 1, a: a};
};
var $author$project$Effect$batch = $author$project$Effect$BatchedEffect;
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$browser$Browser$Navigation$reload = _Browser_reload(false);
var $elm$browser$Browser$Navigation$reloadAndSkipCache = _Browser_reload(true);
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Helpers$NavigationHelper$constructNavigation = function (key) {
	return {
		bH: $elm$browser$Browser$Navigation$load,
		cP: $elm$browser$Browser$Navigation$pushUrl(key),
		bU: $elm$browser$Browser$Navigation$reload,
		bV: $elm$browser$Browser$Navigation$reloadAndSkipCache,
		cU: $elm$browser$Browser$Navigation$replaceUrl(key)
	};
};
var $author$project$Messages$AppHasInitialized = {$: 1};
var $author$project$LoggedIn$Messages$AppTimerCallbackSetup = F2(
	function (a, b) {
		return {$: 51, a: a, b: b};
	});
var $author$project$LoggedIn$Messages$AppTimerInitialize = F2(
	function (a, b) {
		return {$: 50, a: a, b: b};
	});
var $author$project$Messages$CheckIfYoutubeVideoIsPlaying = function (a) {
	return {$: 78, a: a};
};
var $author$project$Messages$CloseExternalWindowByTimer = function (a) {
	return {$: 35, a: a};
};
var $author$project$LoggedIn$Messages$CompleteStudentVideoResponse = function (a) {
	return {$: 76, a: a};
};
var $author$project$LoggedIn$Messages$EnsureCameraHasBecomeReady = function (a) {
	return {$: 66, a: a};
};
var $author$project$LoggedIn$Messages$ErroredStudentVideoResponse = function (a) {
	return {$: 77, a: a};
};
var $author$project$Messages$EvaluateWebAppForAutoClose = F3(
	function (a, b, c) {
		return {$: 33, a: a, b: b, c: c};
	});
var $author$project$Messages$EvaluateWebAppReturnPrompts = F2(
	function (a, b) {
		return {$: 34, a: a, b: b};
	});
var $author$project$LoggedIn$Messages$FetchStudentVideoUploadUrlResponse = function (a) {
	return {$: 55, a: a};
};
var $author$project$Effect$FetchVideoUploadUrlEffect = F3(
	function (a, b, c) {
		return {$: 15, a: a, b: b, c: c};
	});
var $author$project$LoggedIn$Messages$FlashTimerAlert = function (a) {
	return {$: 54, a: a};
};
var $author$project$Messages$LoggedInRequiredMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$LoginApp$Messages$LoginFromQRCode = function (a) {
	return {$: 10, a: a};
};
var $author$project$Messages$LoginMessage = function (a) {
	return {$: 15, a: a};
};
var $author$project$Messages$PauseEmbeddedYoutubePlayer = {$: 84};
var $author$project$Messages$PauseVideo = {$: 50};
var $author$project$Messages$PlayEmbeddedYoutubePlayer = {$: 83};
var $author$project$Messages$PlayVideo = function (a) {
	return {$: 45, a: a};
};
var $author$project$LoggedIn$Messages$SendReturnNotification = function (a) {
	return {$: 6, a: a};
};
var $author$project$Logger$SentryError = 1;
var $author$project$LoggedIn$Messages$SetCanCompleteProgress = function (a) {
	return {$: 53, a: a};
};
var $author$project$LoggedIn$Messages$StoredStudentVideoUploadedResponse = function (a) {
	return {$: 87, a: a};
};
var $author$project$LoggedIn$Messages$StudentActivityTimerComplete = function (a) {
	return {$: 30, a: a};
};
var $author$project$Models$Ids$toString = function (_v0) {
	var value = _v0;
	return value;
};
var $author$project$Models$StudentActivity$idToString = $author$project$Models$Ids$toString;
var $author$project$Models$StudentActivity$activityKey = function (id) {
	return 'activity-practice-' + $author$project$Models$StudentActivity$idToString(id);
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Assets$Favicon$animationFrameRateEncoder = function (animationFrameRate) {
	if (!animationFrameRate.$) {
		return $elm$json$Json$Encode$float(1.0);
	} else {
		var frameRate = animationFrameRate.a;
		return $elm$json$Json$Encode$float(frameRate);
	}
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $author$project$Assets$Favicon$animationIterationEncoder = function (animationIteration) {
	switch (animationIteration.$) {
		case 0:
			return $elm$json$Json$Encode$int(1);
		case 1:
			return $elm$json$Json$Encode$int(-1);
		default:
			var iterationCount = animationIteration.a;
			return $elm$json$Json$Encode$int(iterationCount);
	}
};
var $author$project$Assets$path = function (_v0) {
	var string = _v0;
	return string;
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Assets$assetPathEncoder = function (assetpath) {
	return $elm$json$Json$Encode$string(
		$author$project$Assets$path(assetpath));
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Assets$Favicon$faviconEncoder = function (favicon) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'small',
				$author$project$Assets$assetPathEncoder(favicon.T)),
				_Utils_Tuple2(
				'large',
				$author$project$Assets$assetPathEncoder(favicon.Q))
			]));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Assets$Favicon$faviconsEncoder = function (favicons) {
	return A2($elm$json$Json$Encode$list, $author$project$Assets$Favicon$faviconEncoder, favicons);
};
var $author$project$Assets$Favicon$animationEncoder = function (faviconAnimation) {
	if (!faviconAnimation.$) {
		var favicon = faviconAnimation.a;
		return $author$project$Assets$Favicon$faviconEncoder(favicon);
	} else {
		var favicons = faviconAnimation.a;
		var animationFrameRate = faviconAnimation.b;
		var animationIteration = faviconAnimation.c;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'favicons',
					$author$project$Assets$Favicon$faviconsEncoder(favicons)),
					_Utils_Tuple2(
					'frameRate',
					$author$project$Assets$Favicon$animationFrameRateEncoder(animationFrameRate)),
					_Utils_Tuple2(
					'iterationCount',
					$author$project$Assets$Favicon$animationIterationEncoder(animationIteration))
				]));
	}
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $author$project$NewVersionAvailability$delayIntervalOfTenMinutes = 600000.0;
var $author$project$Messages$NewAppVersionAvailable = {$: 14};
var $author$project$Messages$ScheduleNewVersionCheck = {$: 13};
var $author$project$NewVersionAvailability$interpretResponse = function (result) {
	if (((result.$ === 1) && (result.a.$ === 3)) && (result.a.a === 404)) {
		return $author$project$Messages$NewAppVersionAvailable;
	} else {
		return $author$project$Messages$ScheduleNewVersionCheck;
	}
};
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.i5));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $author$project$NewVersionAvailability$request = function (newVersionCheckUrl) {
	return {
		gs: $elm$http$Http$emptyBody,
		gN: $elm$core$Maybe$Nothing,
		dU: $elm$http$Http$expectWhatever($author$project$NewVersionAvailability$interpretResponse),
		d3: _List_fromArray(
			[
				A2($elm$http$Http$header, 'Cache-Control', 'no-cache')
			]),
		ex: 'HEAD',
		jx: $elm$core$Maybe$Nothing,
		fE: newVersionCheckUrl,
		fT: false
	};
};
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Helpers$BrowserConsole$debugLog = function (_v0) {
	return $elm$core$Basics$identity;
};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $author$project$Api$RequestHelper$httpResponseHandler = F2(
	function (decoder, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.i5));
			default:
				var body = response.b;
				var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
				if (!_v1.$) {
					var value = _v1.a;
					return $elm$core$Result$Ok(value);
				} else {
					var err = _v1.a;
					var _v2 = A2($author$project$Helpers$BrowserConsole$debugLog, 'JSON Decoding error: ', err);
					return $elm$core$Result$Err(
						$elm$http$Http$BadBody(body));
				}
		}
	});
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $author$project$Api$RequestHelper$httpResolver = function (decoder) {
	return $elm$http$Http$stringResolver(
		$author$project$Api$RequestHelper$httpResponseHandler(decoder));
};
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{f4: false, gs: r.gs, dU: r.iD, d3: r.d3, ex: r.ex, jx: r.jx, jz: $elm$core$Maybe$Nothing, fE: r.fE});
};
var $author$project$Api$RequestHelper$toTask = F2(
	function (decoder, request) {
		return $elm$http$Http$task(
			{
				gs: request.gs,
				d3: request.d3,
				ex: request.ex,
				iD: $author$project$Api$RequestHelper$httpResolver(decoder),
				jx: request.jx,
				fE: request.fE
			});
	});
var $author$project$NewVersionAvailability$delayedCheck = function (_v0) {
	var newVersionCheckUrl = _v0.bM;
	return A2(
		$elm$core$Task$attempt,
		$author$project$NewVersionAvailability$interpretResponse,
		A2(
			$elm$core$Task$andThen,
			$elm$core$Basics$always(
				A2(
					$author$project$Api$RequestHelper$toTask,
					$elm$json$Json$Decode$succeed(0),
					$author$project$NewVersionAvailability$request(newVersionCheckUrl))),
			$elm$core$Process$sleep($author$project$NewVersionAvailability$delayIntervalOfTenMinutes)));
};
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {eU: reqs, fo: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.jz;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.eU));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.fo)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					f4: r.f4,
					gs: r.gs,
					dU: A2(_Http_mapExpect, func, r.dU),
					d3: r.d3,
					ex: r.ex,
					jx: r.jx,
					jz: r.jz,
					fE: r.fE
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{f4: false, gs: r.gs, dU: r.dU, d3: r.d3, ex: r.ex, jx: r.jx, jz: r.jz, fE: r.fE}));
};
var $author$project$Api$RequestHelper$send = function (request) {
	return $elm$http$Http$request(
		{gs: request.gs, dU: request.dU, d3: request.d3, ex: request.ex, jx: request.jx, jz: $elm$core$Maybe$Nothing, fE: request.fE});
};
var $author$project$NewVersionAvailability$immediateCheck = function (_v0) {
	var newVersionCheckUrl = _v0.bM;
	return $author$project$Api$RequestHelper$send(
		$author$project$NewVersionAvailability$request(newVersionCheckUrl));
};
var $author$project$NewVersionAvailability$check = F2(
	function (delayType, configuration) {
		if (!delayType) {
			return $author$project$NewVersionAvailability$immediateCheck(configuration);
		} else {
			return $author$project$NewVersionAvailability$delayedCheck(configuration);
		}
	});
var $author$project$Ports$closeExternalWindow = _Platform_outgoingPort('closeExternalWindow', $elm$json$Json$Encode$string);
var $author$project$Ports$connectToEmbeddedYoutubePlayer = _Platform_outgoingPort(
	'connectToEmbeddedYoutubePlayer',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'activityKey',
					$elm$json$Json$Encode$string($.f$)),
					_Utils_Tuple2(
					'startTime',
					$elm$json$Json$Encode$float($.i3)),
					_Utils_Tuple2(
					'studentActivityId',
					$elm$json$Json$Encode$string($.G))
				]));
	});
var $author$project$AppConfiguration$isInTeacherTour = function (_v0) {
	var embeddedInTeacherTour = _v0.cn;
	if (!embeddedInTeacherTour.$) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Helpers$NavigationHelper$pushUrl = F2(
	function (navigation, path) {
		return navigation.cP(path);
	});
var $author$project$Helpers$NavigationHelper$replaceUrl = F2(
	function (navigation, path) {
		return navigation.cU(path);
	});
var $author$project$Helpers$NavigationHelper$updateUrl = F2(
	function (navigation, appConfiguration) {
		return $author$project$AppConfiguration$isInTeacherTour(appConfiguration) ? $author$project$Helpers$NavigationHelper$replaceUrl(navigation) : $author$project$Helpers$NavigationHelper$pushUrl(navigation);
	});
var $author$project$Effect$Resolution$constructEffectResolver = function (navigation) {
	return {
		bH: navigation.bH,
		cP: navigation.cP,
		bU: navigation.bU,
		bV: navigation.bV,
		cU: navigation.cU,
		c4: $author$project$Helpers$NavigationHelper$updateUrl(navigation)
	};
};
var $author$project$Models$VideoUploadData$encodeForWeb = function (videoUploadData) {
	var jsonObject = $elm$json$Json$Encode$string(videoUploadData.fD);
	return A2($elm$json$Json$Encode$encode, 0, jsonObject);
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Models$ActiveQuest$findActivity = F2(
	function (activeQuest, activityId) {
		return A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.f_;
			},
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (sa) {
						return _Utils_eq(sa.hj, activityId);
					},
					activeQuest.jd)));
	});
var $elm_community$maybe_extra$Maybe$Extra$join = function (mx) {
	if (!mx.$) {
		var x = mx.a;
		return x;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Heartbeat$withUnwrappedRemoteData = F2(
	function (f, remoteData) {
		if (remoteData.$ === 3) {
			var data = remoteData.a;
			return $elm$core$Maybe$Just(
				f(data));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Heartbeat$activityForStudentActivityId = F2(
	function (remoteData, studentActivityId) {
		return $elm_community$maybe_extra$Maybe$Extra$join(
			A2(
				$author$project$Heartbeat$withUnwrappedRemoteData,
				function (activeQuest) {
					return A2($author$project$Models$ActiveQuest$findActivity, activeQuest, studentActivityId);
				},
				remoteData));
	});
var $author$project$Models$Ids$encoder = A2($elm$core$Basics$composeR, $author$project$Models$Ids$toString, $elm$json$Json$Encode$string);
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $elm_community$maybe_extra$Maybe$Extra$unwrap = F3(
	function (d, f, m) {
		if (m.$ === 1) {
			return d;
		} else {
			var a = m.a;
			return f(a);
		}
	});
var $author$project$Json$Encode$Extra$nullable = $elm_community$maybe_extra$Maybe$Extra$unwrap($elm$json$Json$Encode$null);
var $author$project$Models$SubjectArea$toBackendId = function (subjectArea) {
	switch (subjectArea) {
		case 0:
			return 1;
		case 1:
			return 2;
		default:
			return 3;
	}
};
var $author$project$Models$SubjectArea$toString = function (subjectArea) {
	switch (subjectArea) {
		case 0:
			return 'mathematics';
		case 1:
			return 'reading';
		default:
			return 'writing';
	}
};
var $author$project$Heartbeat$encoder = function (data) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'student_id',
				$author$project$Models$Ids$encoder(data.b1)),
				_Utils_Tuple2(
				'organization_id',
				$author$project$Models$Ids$encoder(data.cL)),
				_Utils_Tuple2(
				'student_quest_id',
				A2($author$project$Json$Encode$Extra$nullable, $author$project$Models$Ids$encoder, data.a_)),
				_Utils_Tuple2(
				'student_activity_id',
				A2($author$project$Json$Encode$Extra$nullable, $author$project$Models$Ids$encoder, data.G)),
				_Utils_Tuple2(
				'quest_id',
				A2($author$project$Json$Encode$Extra$nullable, $author$project$Models$Ids$encoder, data.cQ)),
				_Utils_Tuple2(
				'activity_id',
				A2($author$project$Json$Encode$Extra$nullable, $author$project$Models$Ids$encoder, data.b6)),
				_Utils_Tuple2(
				'subject_area_name',
				A2(
					$author$project$Json$Encode$Extra$nullable,
					A2($elm$core$Basics$composeR, $author$project$Models$SubjectArea$toString, $elm$json$Json$Encode$string),
					data.bp)),
				_Utils_Tuple2(
				'subject_area_id',
				A2(
					$author$project$Json$Encode$Extra$nullable,
					A2($elm$core$Basics$composeR, $author$project$Models$SubjectArea$toBackendId, $elm$json$Json$Encode$int),
					data.bp))
			]));
};
var $elm_community$maybe_extra$Maybe$Extra$or = F2(
	function (ma, mb) {
		if (ma.$ === 1) {
			return mb;
		} else {
			return ma;
		}
	});
var $author$project$Heartbeat$questForStudentQuestIdFromActiveQuest = F2(
	function (remoteData, studentQuestId) {
		return $elm_community$maybe_extra$Maybe$Extra$join(
			A2(
				$author$project$Heartbeat$withUnwrappedRemoteData,
				function (activeQuest) {
					return _Utils_eq(activeQuest.a_, studentQuestId) ? $elm$core$Maybe$Just(activeQuest.it) : $elm$core$Maybe$Nothing;
				},
				remoteData));
	});
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Models$AvailableQuest$findAvailableQuest = F2(
	function (availableQuestData, studentQuestId) {
		return A2(
			$elm_community$list_extra$List$Extra$find,
			function (q) {
				return _Utils_eq(q.a_, studentQuestId);
			},
			availableQuestData.go);
	});
var $author$project$Heartbeat$questForStudentQuestIdFromAvailableQuests = F2(
	function (remoteData, studentQuestId) {
		return $elm_community$maybe_extra$Maybe$Extra$join(
			A2(
				$author$project$Heartbeat$withUnwrappedRemoteData,
				function (availableQuestData) {
					return A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.it;
						},
						A2($author$project$Models$AvailableQuest$findAvailableQuest, availableQuestData, studentQuestId));
				},
				remoteData));
	});
var $author$project$Heartbeat$questForStudentQuestId = F2(
	function (model, studentQuestId) {
		return A2(
			$elm_community$maybe_extra$Maybe$Extra$or,
			A2($author$project$Heartbeat$questForStudentQuestIdFromAvailableQuests, model.go, studentQuestId),
			A2($author$project$Heartbeat$questForStudentQuestIdFromActiveQuest, model.fZ, studentQuestId));
	});
var $author$project$Routes$ActivityRoutes$studentActivityId = function (route) {
	switch (route.$) {
		case 0:
			var aStudentActivityId = route.b;
			return aStudentActivityId;
		case 1:
			var aStudentActivityId = route.b;
			return aStudentActivityId;
		case 2:
			var aStudentActivityId = route.c;
			return aStudentActivityId;
		default:
			var aStudentActivityId = route.b;
			return aStudentActivityId;
	}
};
var $author$project$Routes$QuizRoutes$studentActivityId = function (route) {
	switch (route.$) {
		case 0:
			var aStudentActivityId = route.b;
			return aStudentActivityId;
		case 1:
			var aStudentActivityId = route.c;
			return aStudentActivityId;
		default:
			var aStudentActivityId = route.b;
			return aStudentActivityId;
	}
};
var $author$project$Routes$studentActivityIdFromRoute = function (route) {
	switch (route.$) {
		case 8:
			var activityRoute = route.a;
			return $elm$core$Maybe$Just(
				$author$project$Routes$ActivityRoutes$studentActivityId(activityRoute));
		case 10:
			var quizRoute = route.a;
			return $elm$core$Maybe$Just(
				$author$project$Routes$QuizRoutes$studentActivityId(quizRoute));
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Routes$ActivityRoutes$studentQuestId = function (route) {
	switch (route.$) {
		case 0:
			var aStudentQuestId = route.a;
			return aStudentQuestId;
		case 1:
			var aStudentQuestId = route.a;
			return aStudentQuestId;
		case 2:
			var aStudentQuestId = route.b;
			return aStudentQuestId;
		default:
			var aStudentQuestId = route.a;
			return aStudentQuestId;
	}
};
var $author$project$Routes$QuestRoutes$studentQuestId = function (route) {
	switch (route.$) {
		case 0:
			var aStudentQuestId = route.a;
			return aStudentQuestId;
		case 1:
			var aStudentQuestId = route.a;
			return aStudentQuestId;
		default:
			var aStudentQuestId = route.a;
			return aStudentQuestId;
	}
};
var $author$project$Routes$QuizRoutes$studentQuestId = function (route) {
	switch (route.$) {
		case 0:
			var aStudentQuestId = route.a;
			return aStudentQuestId;
		case 1:
			var aStudentQuestId = route.b;
			return aStudentQuestId;
		default:
			var aStudentQuestId = route.a;
			return aStudentQuestId;
	}
};
var $author$project$Routes$VideoRoutes$studentQuestId = function (route) {
	switch (route.$) {
		case 0:
			var aStudentQuestId = route.a;
			return aStudentQuestId;
		case 1:
			var aStudentQuestId = route.a;
			return aStudentQuestId;
		default:
			var aStudentQuestId = route.a;
			return aStudentQuestId;
	}
};
var $author$project$Routes$studentQuestIdFromRoute = function (route) {
	switch (route.$) {
		case 7:
			var questRoute = route.a;
			return $elm$core$Maybe$Just(
				$author$project$Routes$QuestRoutes$studentQuestId(questRoute));
		case 9:
			var videoRoute = route.a;
			return $elm$core$Maybe$Just(
				$author$project$Routes$VideoRoutes$studentQuestId(videoRoute));
		case 8:
			var activityRoute = route.a;
			return $elm$core$Maybe$Just(
				$author$project$Routes$ActivityRoutes$studentQuestId(activityRoute));
		case 10:
			var quizRoute = route.a;
			return $elm$core$Maybe$Just(
				$author$project$Routes$QuizRoutes$studentQuestId(quizRoute));
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Heartbeat$encodeHeartbeatDataFromModel = function (model) {
	var _v0 = model.jF;
	if (_v0.$ === 2) {
		var student = _v0.a;
		var studentQuestId = $author$project$Routes$studentQuestIdFromRoute(model.eY);
		var studentActivityId = $author$project$Routes$studentActivityIdFromRoute(model.eY);
		var quest = $elm_community$maybe_extra$Maybe$Extra$join(
			A2(
				$elm$core$Maybe$map,
				$author$project$Heartbeat$questForStudentQuestId(model),
				studentQuestId));
		var activity = $elm_community$maybe_extra$Maybe$Extra$join(
			A2(
				$elm$core$Maybe$map,
				$author$project$Heartbeat$activityForStudentActivityId(model.fZ),
				studentActivityId));
		return $elm$core$Maybe$Just(
			$author$project$Heartbeat$encoder(
				{
					b6: A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.hj;
						},
						activity),
					cL: student.ib.hj,
					cQ: A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.hj;
						},
						quest),
					G: studentActivityId,
					b1: student.hj,
					a_: studentQuestId,
					bp: A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.bp;
						},
						quest)
				}));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$RequestHelper$createConfig = F5(
	function (resolver, loginInfo, _v0, body, decoder) {
		var platform = _v0.ii;
		var serverBaseUrl = _v0.fa;
		var devFlags = _v0.dO;
		return {bx: serverBaseUrl, gs: body, gN: decoder, hA: loginInfo, ii: platform, bS: _List_Nil, iD: resolver, jx: devFlags.iC};
	});
var $author$project$Models$VideoUploadData$VideoUploadData = F6(
	function (s3Bucket, s3Filename, completionUrl, writtenInstructions, spokenInstructions, uploadUrl) {
		return {dF: completionUrl, e$: s3Bucket, e0: s3Filename, fj: spokenInstructions, fD: uploadUrl, fU: writtenInstructions};
	});
var $elm$json$Json$Decode$map6 = _Json_map6;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Models$VideoUploadData$decoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Models$VideoUploadData$VideoUploadData,
	A2($elm$json$Json$Decode$field, 's3_bucket', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 's3_file_name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'completion_url', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'written_instructions', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'spoken_instructions', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'upload_url', $elm$json$Json$Decode$string));
var $author$project$Api$AuthedRequest$authHeaders = function (loginInfo) {
	return _List_fromArray(
		[
			_Utils_Tuple2('X-Espark-Access-Token', loginInfo.fX)
		]);
};
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $author$project$Api$AuthedRequest$noCacheHeaders = _List_fromArray(
	[
		_Utils_Tuple2('Cache-Control', 'max-age=0,no-cache,no-store,post-check=0,pre-check=0')
	]);
var $lukewestby$elm_http_builder$HttpBuilder$withExpect = F2(
	function (expect, builder) {
		return {gs: builder.gs, dU: expect, d3: builder.d3, ex: builder.ex, jx: builder.jx, fE: builder.fE, fT: builder.fT};
	});
var $lukewestby$elm_http_builder$HttpBuilder$withHeaders = F2(
	function (headerPairs, builder) {
		return _Utils_update(
			builder,
			{
				d3: _Utils_ap(
					A2(
						$elm$core$List$map,
						function (_v0) {
							var key = _v0.a;
							var value = _v0.b;
							return A2($elm$http$Http$header, key, value);
						},
						headerPairs),
					builder.d3)
			});
	});
var $lukewestby$elm_http_builder$HttpBuilder$withTimeout = F2(
	function (timeout, builder) {
		return _Utils_update(
			builder,
			{
				jx: $elm$core$Maybe$Just(timeout)
			});
	});
var $author$project$Api$AuthedRequest$buildRequest = F2(
	function (_v0, requestBuilder) {
		var loginInfo = _v0.hA;
		var platform = _v0.ii;
		var timeout = _v0.jx;
		var resolver = _v0.iD;
		var decoder = _v0.gN;
		return A2(
			$lukewestby$elm_http_builder$HttpBuilder$withTimeout,
			timeout,
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				A2($elm$http$Http$expectJson, resolver, decoder),
				A2(
					$lukewestby$elm_http_builder$HttpBuilder$withHeaders,
					$elm$core$List$concat(
						_List_fromArray(
							[
								$author$project$Api$AuthedRequest$authHeaders(loginInfo),
								$author$project$Api$AuthedRequest$noCacheHeaders
							])),
					requestBuilder)));
	});
var $author$project$Api$AuthedRequest$buildRequestNoBody = F2(
	function (config, requestBuilder) {
		return A2($author$project$Api$AuthedRequest$buildRequest, config, requestBuilder);
	});
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$relative = F2(
	function (pathSegments, parameters) {
		return _Utils_ap(
			A2($elm$core$String$join, '/', pathSegments),
			$elm$url$Url$Builder$toQuery(parameters));
	});
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Api$RequestHelper$constructUrl = F2(
	function (url, queryParams) {
		var queryParameters = A2(
			$elm$core$List$map,
			function (_v0) {
				var key = _v0.a;
				var value = _v0.b;
				return A2($elm$url$Url$Builder$string, key, value);
			},
			queryParams);
		return A2(
			$elm$url$Url$Builder$relative,
			_List_fromArray(
				[url]),
			queryParameters);
	});
var $elm$http$Http$expectString = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$elm$http$Http$resolve($elm$core$Result$Ok));
};
var $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl = F2(
	function (method, url) {
		return {
			gs: $elm$http$Http$emptyBody,
			dU: $elm$http$Http$expectString(
				function (_v0) {
					return 0;
				}),
			d3: _List_Nil,
			ex: method,
			jx: $elm$core$Maybe$Nothing,
			fE: url,
			fT: false
		};
	});
var $lukewestby$elm_http_builder$HttpBuilder$get = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('GET');
var $author$project$Api$RequestHelper$requestBuilderToRequest = function (requestBuilder) {
	return {gs: requestBuilder.gs, gN: $elm$core$Maybe$Nothing, dU: requestBuilder.dU, d3: requestBuilder.d3, ex: requestBuilder.ex, jx: requestBuilder.jx, fE: requestBuilder.fE, fT: requestBuilder.fT};
};
var $author$project$Api$AuthedRequest$get = F2(
	function (endpoint, config) {
		var baseUrl = config.bx;
		var queryParams = config.bS;
		return $author$project$Api$RequestHelper$requestBuilderToRequest(
			A2(
				$author$project$Api$AuthedRequest$buildRequestNoBody,
				config,
				$lukewestby$elm_http_builder$HttpBuilder$get(
					A2(
						$author$project$Api$RequestHelper$constructUrl,
						_Utils_ap(baseUrl, endpoint),
						queryParams))));
	});
var $author$project$Api$HttpRetry$defaultConfig = {cx: 1 * 1000, cy: 1000, bk: 4};
var $elm$random$Random$Generator = $elm$core$Basics$identity;
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$float = F2(
	function (a, b) {
		return function (seed0) {
			var seed1 = $elm$random$Random$next(seed0);
			var range = $elm$core$Basics$abs(b - a);
			var n1 = $elm$random$Random$peel(seed1);
			var n0 = $elm$random$Random$peel(seed0);
			var lo = (134217727 & n1) * 1.0;
			var hi = (67108863 & n0) * 1.0;
			var val = ((hi * 134217728.0) + lo) / 9007199254740992.0;
			var scaled = (val * range) + a;
			return _Utils_Tuple2(
				scaled,
				$elm$random$Random$next(seed1));
		};
	});
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$Helpers$Task$randomIntervalInMilliseconds = F3(
	function (avgDelayInMilliseconds, rangeInMilliseconds, randomFloat) {
		return (avgDelayInMilliseconds - (rangeInMilliseconds / 2)) + randomFloat;
	});
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0;
		return generator(seed);
	});
var $author$project$Helpers$Task$randomDelay = F3(
	function (avgDelayInMilliseconds, rangeInMilliseconds, task) {
		var randomFloatGenerator = A2(
			$elm$core$Basics$composeR,
			$elm$random$Random$step(
				A2($elm$random$Random$float, 0, rangeInMilliseconds)),
			$elm$core$Tuple$first);
		var intervalGeneratorTask = A2(
			$elm$core$Task$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$time$Time$posixToMillis,
				A2($elm$core$Basics$composeR, $elm$random$Random$initialSeed, randomFloatGenerator)),
			$elm$time$Time$now);
		var generatedInterval = A2($author$project$Helpers$Task$randomIntervalInMilliseconds, avgDelayInMilliseconds, rangeInMilliseconds);
		return (!rangeInMilliseconds) ? A2(
			$elm$core$Task$andThen,
			function (_v0) {
				return task;
			},
			$elm$core$Process$sleep(avgDelayInMilliseconds)) : A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return task;
			},
			A2(
				$elm$core$Task$andThen,
				A2($elm$core$Basics$composeR, generatedInterval, $elm$core$Process$sleep),
				intervalGeneratorTask));
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $elm$core$Basics$not = _Basics_not;
var $author$project$Api$HttpRetry$shouldFailImmediately = function (error) {
	return !A2(
		$elm$core$List$member,
		error,
		_List_fromArray(
			[$elm$http$Http$Timeout, $elm$http$Http$NetworkError]));
};
var $author$project$Api$HttpRetry$handleRetriesOnError = F3(
	function (config, task, error) {
		if ((!config.bk) || $author$project$Api$HttpRetry$shouldFailImmediately(error)) {
			return $elm$core$Task$fail(error);
		} else {
			var next = A2(
				$elm$core$Task$onError,
				A2(
					$author$project$Api$HttpRetry$handleRetriesOnError,
					_Utils_update(
						config,
						{bk: config.bk - 1}),
					task),
				task);
			return A3($author$project$Helpers$Task$randomDelay, config.cx, config.cy, next);
		}
	});
var $author$project$Api$HttpRetry$sendTaskWithConfig = F3(
	function (config, resultToMessage, task) {
		return A2(
			$elm$core$Task$attempt,
			resultToMessage,
			A2(
				$elm$core$Task$onError,
				A2($author$project$Api$HttpRetry$handleRetriesOnError, config, task),
				task));
	});
var $author$project$Api$HttpRetry$sendWithConfig = F3(
	function (config, decoder, resultToMessage) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Api$RequestHelper$toTask(decoder),
			A2($author$project$Api$HttpRetry$sendTaskWithConfig, config, resultToMessage));
	});
var $author$project$Api$HttpRetry$send = F2(
	function (decoder, resultToMessage) {
		return A3($author$project$Api$HttpRetry$sendWithConfig, $author$project$Api$HttpRetry$defaultConfig, decoder, resultToMessage);
	});
var $author$project$Api$StudentVideoUploadApi$fetchUploadUrl = F4(
	function (studentQuestId, loginInfo, appConfig, resultMsg) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$author$project$Models$VideoUploadData$decoder,
			resultMsg,
			A2(
				$author$project$Api$AuthedRequest$get,
				'/api/v5/student_quests/' + ($author$project$Models$Ids$toString(studentQuestId) + '/student_video/upload_info'),
				A5($author$project$Api$RequestHelper$createConfig, resultMsg, loginInfo, appConfig, 0, $author$project$Models$VideoUploadData$decoder)));
	});
var $author$project$Helpers$Task$getCurrentTime = function (msg) {
	return A2($elm$core$Task$perform, msg, $elm$time$Time$now);
};
var $author$project$Ports$hideNotification = _Platform_outgoingPort(
	'hideNotification',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Api$AuthedRequest$authHttpHeaders = function (loginInfo) {
	return A2(
		$elm$core$List$map,
		function (_v0) {
			var key = _v0.a;
			var value = _v0.b;
			return A2($elm$http$Http$header, key, value);
		},
		$author$project$Api$AuthedRequest$authHeaders(loginInfo));
};
var $author$project$Models$JoinCodeError$OtherJoinCodeError = function (a) {
	return {$: 5, a: a};
};
var $author$project$Models$JoinCodeError$CodeMissing = {$: 0};
var $author$project$Models$JoinCodeError$KeyMissing = {$: 2};
var $author$project$Models$JoinCodeError$NoInProgress = {$: 3};
var $author$project$Models$JoinCodeError$UnableToSave = {$: 4};
var $author$project$Models$JoinCodeError$UnknownCode = {$: 1};
var $author$project$Api$JoinApi$handleBadStatus = F2(
	function (statusCode, body) {
		return (statusCode === 422) ? (A2($elm$core$String$contains, 'join_code_missing', body) ? $author$project$Models$JoinCodeError$CodeMissing : (A2($elm$core$String$contains, 'unknown_join_code', body) ? $author$project$Models$JoinCodeError$UnknownCode : (A2($elm$core$String$contains, 'key_missing', body) ? $author$project$Models$JoinCodeError$KeyMissing : (A2($elm$core$String$contains, 'no_in_progress_signup', body) ? $author$project$Models$JoinCodeError$NoInProgress : (A2($elm$core$String$contains, 'unable_to_save_student', body) ? $author$project$Models$JoinCodeError$UnableToSave : $author$project$Models$JoinCodeError$OtherJoinCodeError(
			$elm$http$Http$BadStatus(statusCode))))))) : $author$project$Models$JoinCodeError$OtherJoinCodeError(
			$elm$http$Http$BadStatus(statusCode));
	});
var $author$project$Models$LoginResponse$ServerRedirect = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$LoginResponse$StudentData = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $author$project$Models$LoginResponse$serverRedirectDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Models$LoginResponse$ServerRedirect,
	A2($elm$json$Json$Decode$field, 'redirect_on_login_to', $elm$json$Json$Decode$string));
var $author$project$Models$LoggingIn$LoginMethodUnknown = {$: 0};
var $author$project$Models$User$StudentLoginData = F2(
	function (student, auth) {
		return {gl: auth, jb: student};
	});
var $author$project$Models$Student$Student = function (id) {
	return function (name) {
		return function (firstName) {
			return function (lastName) {
				return function (username) {
					return function (studentId) {
						return function (audioEnabled) {
							return function (audioStatusBasedOnAbility) {
								return function (appFlowMode) {
									return function (productVersion) {
										return function (organization) {
											return function (npsSurveyId) {
												return function (recordedMoodToday) {
													return function (productFeatures) {
														return function (newToWebApps) {
															return function (studentAccessories) {
																return function (customizerOpened) {
																	return {f8: appFlowMode, gj: audioEnabled, gk: audioStatusBasedOnAbility, dJ: customizerOpened, g5: firstName, hj: id, hv: lastName, cE: name, hT: newToWebApps, h2: npsSurveyId, ib: organization, iq: productFeatures, eR: productVersion, iz: recordedMoodToday, jc: studentAccessories, b1: studentId, fF: username};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$AppFlow$Choice = function (a) {
	return {$: 1, a: a};
};
var $author$project$AppFlow$Sequential = {$: 0};
var $author$project$AppFlow$Theme = 0;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$AppFlow$decoder = function () {
	var decodeFlowString = function (str) {
		switch (str) {
			case 'Choice-Theme':
				return $elm$json$Json$Decode$succeed(
					$author$project$AppFlow$Choice(0));
			case 'Sequential':
				return $elm$json$Json$Decode$succeed($author$project$AppFlow$Sequential);
			default:
				return $elm$json$Json$Decode$fail(str + ' is not a valid AppFlowMode');
		}
	};
	return A2($elm$json$Json$Decode$andThen, decodeFlowString, $elm$json$Json$Decode$string);
}();
var $author$project$Models$Ids$Id = $elm$core$Basics$identity;
var $author$project$Models$Ids$fromString = $elm$core$Basics$identity;
var $author$project$Models$Ids$fromInt = A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $author$project$Models$Ids$fromString);
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Models$Ids$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$map, $author$project$Models$Ids$fromString, $elm$json$Json$Decode$string),
			A2($elm$json$Json$Decode$map, $author$project$Models$Ids$fromInt, $elm$json$Json$Decode$int)
		]));
var $author$project$Models$Organization$Organization = F2(
	function (id, name) {
		return {hj: id, cE: name};
	});
var $author$project$Models$Organization$decoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Models$Organization$Organization,
	A2($elm$json$Json$Decode$field, 'id', $author$project$Models$Ids$decoder),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
var $author$project$Models$ProductFeature$EnabledFeatures = $elm$core$Basics$identity;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Models$ProductFeature$decoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Basics$identity,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Models$StudentAccessory$StudentAccessory = F2(
	function (id, avatarAccessory) {
		return {gp: avatarAccessory, hj: id};
	});
var $author$project$Models$AvatarAccessory$AvatarAccessory = F6(
	function (id, accessoryType, staticImageUrl, name, assetPack, offset) {
		return {fY: accessoryType, ge: assetPack, hj: id, cE: name, ao: offset, i4: staticImageUrl};
	});
var $author$project$Models$AvatarAccessory$Head = 0;
var $author$project$Models$AvatarAccessory$Legs = 2;
var $author$project$Models$AvatarAccessory$Offset = F2(
	function (x, y) {
		return {jW: x, j4: y};
	});
var $author$project$Models$AvatarAccessory$Torso = 1;
var $author$project$Models$AvatarAccessory$decoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Models$AvatarAccessory$AvatarAccessory,
	A2($elm$json$Json$Decode$field, 'id', $author$project$Models$Ids$decoder),
	A2(
		$elm$json$Json$Decode$andThen,
		function (accessoryType) {
			return $elm$json$Json$Decode$succeed(
				(accessoryType === 'head') ? 0 : ((accessoryType === 'legs') ? 2 : 1));
		},
		A2($elm$json$Json$Decode$field, 'accessory_type', $elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'static_image_url', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'asset_pack',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Models$AvatarAccessory$Offset,
		A2($elm$json$Json$Decode$field, 'offset_x', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'offset_y', $elm$json$Json$Decode$int)));
var $author$project$Models$StudentAccessory$decoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Models$StudentAccessory$StudentAccessory,
	A2($elm$json$Json$Decode$field, 'id', $author$project$Models$Ids$decoder),
	A2($elm$json$Json$Decode$field, 'avatar_accessory', $author$project$Models$AvatarAccessory$decoder));
var $author$project$ProductVersion$V4 = 0;
var $author$project$ProductVersion$V5 = 1;
var $author$project$ProductVersion$decoder = function () {
	var decodeProductVersionString = function (str) {
		switch (str) {
			case '4':
				return $elm$json$Json$Decode$succeed(0);
			case '5':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail(str + ' is not a valid ProductVersion');
		}
	};
	return A2($elm$json$Json$Decode$andThen, decodeProductVersionString, $elm$json$Json$Decode$string);
}();
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Models$Student$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'customizer_opened',
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(false),
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool)),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'student_accessories',
		$elm$json$Json$Decode$list($author$project$Models$StudentAccessory$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'new_to_web_apps',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'product_features',
				$author$project$Models$ProductFeature$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'recorded_mood_today',
					$elm$json$Json$Decode$bool,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'nps_survey_id',
						$elm$json$Json$Decode$maybe($author$project$Models$Ids$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'organization',
							$author$project$Models$Organization$decoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'product_version',
								$author$project$ProductVersion$decoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'app_flow_mode',
									$author$project$AppFlow$decoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'audio_status_based_on_ability',
										$elm$json$Json$Decode$bool,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'audio_enabled',
											$elm$json$Json$Decode$bool,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'student_id',
												$author$project$Models$Ids$decoder,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'username',
													$elm$json$Json$Decode$string,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'last_name',
														$elm$json$Json$Decode$string,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'first_name',
															$elm$json$Json$Decode$string,
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																'name',
																$elm$json$Json$Decode$string,
																A3(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																	'id',
																	$author$project$Models$Ids$decoder,
																	$elm$json$Json$Decode$succeed($author$project$Models$Student$Student))))))))))))))))));
var $author$project$Models$User$LoginInfo = F2(
	function (accessToken, loginMethod) {
		return {fX: accessToken, hB: loginMethod};
	});
var $author$project$Models$LoggingIn$AutoLoginSourceCleverSSO = {$: 1};
var $author$project$Models$LoggingIn$AutoLoginSourceGoogleSSO = {$: 0};
var $author$project$Models$LoggingIn$AutoLoginSourceUnknown = function (a) {
	return {$: 2, a: a};
};
var $author$project$Models$LoggingIn$Autologin = function (a) {
	return {$: 3, a: a};
};
var $author$project$Models$LoggingIn$IosAppLogin = {$: 4};
var $author$project$Models$LoggingIn$ManualUsernamePassword = {$: 1};
var $author$project$Models$LoggingIn$PrepopulatedSourceQRCode = {$: 2};
var $author$project$Models$LoggingIn$PrepopulatedUsernamePassword = function (a) {
	return {$: 2, a: a};
};
var $author$project$Models$LoggingIn$PrepopulatedSourceNoneGiven = {$: 0};
var $author$project$Models$LoggingIn$PrepopulatedSourceUnknown = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$LoggingIn$prepopulatedSourceFromString = function (source) {
	switch (source) {
		case '':
			return $author$project$Models$LoggingIn$PrepopulatedSourceNoneGiven;
		case 'qrcode':
			return $author$project$Models$LoggingIn$PrepopulatedSourceQRCode;
		default:
			var unknownSource = source;
			return $author$project$Models$LoggingIn$PrepopulatedSourceUnknown(unknownSource);
	}
};
var $author$project$Models$LoggingIn$loginMethodFromString = function (loginMethod) {
	var _v0 = A2($elm$core$String$split, ':', loginMethod);
	_v0$8:
	while (true) {
		if (_v0.b) {
			if (_v0.b.b) {
				if (!_v0.b.b.b) {
					switch (_v0.a) {
						case 'prepopulated_username_password':
							var _v1 = _v0.b;
							var source = _v1.a;
							return $author$project$Models$LoggingIn$PrepopulatedUsernamePassword(
								$author$project$Models$LoggingIn$prepopulatedSourceFromString(source));
						case 'autologin':
							var _v2 = _v0.b;
							var source = _v2.a;
							return $author$project$Models$LoggingIn$Autologin(
								$author$project$Models$LoggingIn$AutoLoginSourceUnknown(source));
						default:
							break _v0$8;
					}
				} else {
					break _v0$8;
				}
			} else {
				switch (_v0.a) {
					case 'login_method_unknown':
						return $author$project$Models$LoggingIn$LoginMethodUnknown;
					case 'manual_username_password':
						return $author$project$Models$LoggingIn$ManualUsernamePassword;
					case 'qrcode':
						return $author$project$Models$LoggingIn$PrepopulatedUsernamePassword($author$project$Models$LoggingIn$PrepopulatedSourceQRCode);
					case 'googlesso':
						return $author$project$Models$LoggingIn$Autologin($author$project$Models$LoggingIn$AutoLoginSourceGoogleSSO);
					case 'cleversso':
						return $author$project$Models$LoggingIn$Autologin($author$project$Models$LoggingIn$AutoLoginSourceCleverSSO);
					case 'iosapp':
						return $author$project$Models$LoggingIn$IosAppLogin;
					default:
						break _v0$8;
				}
			}
		} else {
			break _v0$8;
		}
	}
	return $author$project$Models$LoggingIn$LoginMethodUnknown;
};
var $author$project$Models$User$loginInfoDecoder = function (loginMethod) {
	return A3(
		$elm$json$Json$Decode$map2,
		$author$project$Models$User$LoginInfo,
		A2($elm$json$Json$Decode$field, 'access_token', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$field,
					'login_method',
					A2($elm$json$Json$Decode$map, $author$project$Models$LoggingIn$loginMethodFromString, $elm$json$Json$Decode$string)),
					$elm$json$Json$Decode$succeed(loginMethod)
				])));
};
var $author$project$Models$User$studentLoginDataDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Models$User$StudentLoginData,
	A2($elm$json$Json$Decode$field, 'student', $author$project$Models$Student$decoder),
	A2(
		$elm$json$Json$Decode$field,
		'auth',
		$author$project$Models$User$loginInfoDecoder($author$project$Models$LoggingIn$LoginMethodUnknown)));
var $author$project$Models$LoginResponse$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$author$project$Models$LoginResponse$serverRedirectDecoder,
			A2($elm$json$Json$Decode$map, $author$project$Models$LoginResponse$StudentData, $author$project$Models$User$studentLoginDataDecoder)
		]));
var $author$project$Api$JoinApi$handleGoodStatus = function (body) {
	var _v0 = A2($elm$json$Json$Decode$decodeString, $author$project$Models$LoginResponse$decoder, body);
	if (_v0.$ === 1) {
		return $elm$core$Result$Err(
			$author$project$Models$JoinCodeError$OtherJoinCodeError(
				$elm$http$Http$BadBody(body)));
	} else {
		if (!_v0.a.$) {
			var studentData = _v0.a.a;
			return $elm$core$Result$Ok(
				$author$project$Models$LoginResponse$StudentData(studentData));
		} else {
			var redirectUrl = _v0.a.a;
			return $elm$core$Result$Ok(
				$author$project$Models$LoginResponse$ServerRedirect(redirectUrl));
		}
	}
};
var $author$project$Api$JoinApi$handleResponse = function (response) {
	switch (response.$) {
		case 0:
			var url = response.a;
			return $elm$core$Result$Err(
				$author$project$Models$JoinCodeError$OtherJoinCodeError(
					$elm$http$Http$BadUrl(url)));
		case 1:
			return $elm$core$Result$Err(
				$author$project$Models$JoinCodeError$OtherJoinCodeError($elm$http$Http$Timeout));
		case 2:
			return $elm$core$Result$Err(
				$author$project$Models$JoinCodeError$OtherJoinCodeError($elm$http$Http$NetworkError));
		case 3:
			var metadata = response.a;
			var body = response.b;
			return $elm$core$Result$Err(
				A2($author$project$Api$JoinApi$handleBadStatus, metadata.i5, body));
		default:
			var body = response.b;
			return $author$project$Api$JoinApi$handleGoodStatus(body);
	}
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Messages$JoinCodeMsg = function (a) {
	return {$: 16, a: a};
};
var $author$project$JoinCode$Messages$JoinRequestResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$JoinCode$Messages$RedirectOnJoin = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$JoinApi$responseToJoinCodeMsg = function (joinResponse) {
	if (!joinResponse.$) {
		if (!joinResponse.a.$) {
			var studentData = joinResponse.a.a;
			return $author$project$Messages$JoinCodeMsg(
				$author$project$JoinCode$Messages$JoinRequestResponse(
					$elm$core$Result$Ok(studentData)));
		} else {
			var redirectUrl = joinResponse.a.a;
			return $author$project$Messages$JoinCodeMsg(
				$author$project$JoinCode$Messages$RedirectOnJoin(redirectUrl));
		}
	} else {
		var joinError = joinResponse.a;
		return $author$project$Messages$JoinCodeMsg(
			$author$project$JoinCode$Messages$JoinRequestResponse(
				$elm$core$Result$Err(joinError)));
	}
};
var $author$project$Api$JoinApi$joinNewSection = F3(
	function (joinCode, loginInfo, appConfig) {
		var body = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'join_code',
					$elm$json$Json$Encode$string(joinCode))
				]));
		return $author$project$Api$RequestHelper$send(
			{
				gs: $elm$http$Http$jsonBody(body),
				gN: $elm$core$Maybe$Nothing,
				dU: A2($elm$http$Http$expectStringResponse, $author$project$Api$JoinApi$responseToJoinCodeMsg, $author$project$Api$JoinApi$handleResponse),
				d3: $author$project$Api$AuthedRequest$authHttpHeaders(loginInfo),
				ex: 'POST',
				jx: $elm$core$Maybe$Nothing,
				fE: appConfig.fa + '/api/v5/join',
				fT: false
			});
	});
var $author$project$Ports$listenForWebActivityWindowMessage = _Platform_outgoingPort('listenForWebActivityWindowMessage', $elm$json$Json$Encode$string);
var $author$project$Logger$destinationToString = function (logDestination) {
	switch (logDestination) {
		case 1:
			return 'SentryError';
		case 2:
			return 'SentryMessage';
		default:
			return 'Redshift';
	}
};
var $author$project$Ports$logMessage = _Platform_outgoingPort('logMessage', $elm$json$Json$Encode$string);
var $author$project$Logger$log = F2(
	function (model, _v0) {
		var data = _v0.gL;
		var tags = _v0.jp;
		var message = _v0.hK;
		var destinations = _v0.gP;
		var studentQuestId = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			$elm$json$Json$Encode$null,
			$author$project$Models$Ids$encoder,
			$author$project$Routes$studentQuestIdFromRoute(model.eY));
		var studentActivityId = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			$elm$json$Json$Encode$null,
			$author$project$Models$Ids$encoder,
			$author$project$Routes$studentActivityIdFromRoute(model.eY));
		var jsonData = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'message',
					$elm$json$Json$Encode$string(message)),
					_Utils_Tuple2('student_quest_id', studentQuestId),
					_Utils_Tuple2('student_activity_id', studentActivityId),
					_Utils_Tuple2(
					'data',
					$elm$json$Json$Encode$object(
						_Utils_ap(
							_List_fromArray(
								[
									_Utils_Tuple2('student_quest_id', studentQuestId),
									_Utils_Tuple2('student_activity_id', studentActivityId)
								]),
							data))),
					_Utils_Tuple2(
					'tags',
					$elm$json$Json$Encode$object(tags)),
					_Utils_Tuple2(
					'destinations',
					A2(
						$elm$json$Json$Encode$list,
						$elm$json$Json$Encode$string,
						A2($elm$core$List$map, $author$project$Logger$destinationToString, destinations)))
				]));
		return $author$project$Ports$logMessage(
			A2($elm$json$Json$Encode$encode, 0, jsonData));
	});
var $author$project$Ports$logGameActivityBrowserHealth = _Platform_outgoingPort('logGameActivityBrowserHealth', $elm$json$Json$Encode$string);
var $author$project$Api$JoinApi$loginWithJoinCode = F2(
	function (_v0, appConfig) {
		var joinCode = _v0.ht;
		var authKey = _v0.gm;
		var body = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'key',
					$elm$json$Json$Encode$string(authKey)),
					_Utils_Tuple2(
					'join_code',
					$elm$json$Json$Encode$string(joinCode))
				]));
		return $author$project$Api$RequestHelper$send(
			{
				gs: $elm$http$Http$jsonBody(body),
				gN: $elm$core$Maybe$Nothing,
				dU: A2($elm$http$Http$expectStringResponse, $author$project$Api$JoinApi$responseToJoinCodeMsg, $author$project$Api$JoinApi$handleResponse),
				d3: _List_Nil,
				ex: 'POST',
				jx: $elm$core$Maybe$Nothing,
				fE: appConfig.fa + '/api/v5/join',
				fT: false
			});
	});
var $author$project$Models$LoggingIn$OtherError = function (a) {
	return {$: 3, a: a};
};
var $author$project$Models$LoggingIn$PasswordError = {$: 1};
var $author$project$Models$LoggingIn$UsernameError = {$: 0};
var $author$project$Api$LoginApi$handleBadStatus = F2(
	function (statusCode, body) {
		return (statusCode === 403) ? (A2($elm$core$String$contains, 'unknown_user', body) ? $author$project$Models$LoggingIn$UsernameError : (A2($elm$core$String$contains, 'invalid_password', body) ? $author$project$Models$LoggingIn$PasswordError : $author$project$Models$LoggingIn$OtherError(
			$elm$http$Http$BadStatus(statusCode)))) : $author$project$Models$LoggingIn$OtherError(
			$elm$http$Http$BadStatus(statusCode));
	});
var $author$project$Api$LoginApi$handleGoodStatus = function (body) {
	var _v0 = A2($elm$json$Json$Decode$decodeString, $author$project$Models$LoginResponse$decoder, body);
	if (_v0.$ === 1) {
		var err = _v0.a;
		var _v1 = A2($author$project$Helpers$BrowserConsole$debugLog, 'Error decoding student', err);
		return $elm$core$Result$Err(
			$author$project$Models$LoggingIn$OtherError(
				$elm$http$Http$BadBody(body)));
	} else {
		if (!_v0.a.$) {
			var studentData = _v0.a.a;
			return $elm$core$Result$Ok(
				$author$project$Models$LoginResponse$StudentData(studentData));
		} else {
			var redirectUrl = _v0.a.a;
			return $elm$core$Result$Ok(
				$author$project$Models$LoginResponse$ServerRedirect(redirectUrl));
		}
	}
};
var $author$project$Api$LoginApi$handleLoginResponse = function (response) {
	switch (response.$) {
		case 0:
			var url = response.a;
			return $elm$core$Result$Err(
				$author$project$Models$LoggingIn$OtherError(
					$elm$http$Http$BadUrl(url)));
		case 1:
			return $elm$core$Result$Err(
				$author$project$Models$LoggingIn$OtherError($elm$http$Http$Timeout));
		case 2:
			return $elm$core$Result$Err(
				$author$project$Models$LoggingIn$OtherError($elm$http$Http$NetworkError));
		case 3:
			var metadata = response.a;
			var body = response.b;
			return $elm$core$Result$Err(
				A2($author$project$Api$LoginApi$handleBadStatus, metadata.i5, body));
		default:
			var body = response.b;
			return $author$project$Api$LoginApi$handleGoodStatus(body);
	}
};
var $author$project$LoginApp$Messages$PasswordLoginResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$LoginApp$Messages$RedirectOnLogin = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$LoginApi$loginResponseToLoginMsg = function (loginResponse) {
	if (!loginResponse.$) {
		if (!loginResponse.a.$) {
			var studentData = loginResponse.a.a;
			return $author$project$Messages$LoginMessage(
				$author$project$LoginApp$Messages$PasswordLoginResponse(
					$elm$core$Result$Ok(studentData)));
		} else {
			var redirectUrl = loginResponse.a.a;
			return $author$project$Messages$LoginMessage(
				$author$project$LoginApp$Messages$RedirectOnLogin(redirectUrl));
		}
	} else {
		var loginError = loginResponse.a;
		return $author$project$Messages$LoginMessage(
			$author$project$LoginApp$Messages$PasswordLoginResponse(
				$elm$core$Result$Err(loginError)));
	}
};
var $author$project$Api$LoginApi$loginWithPassword = F3(
	function (username, password, appConfig) {
		var body = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'username',
					$elm$json$Json$Encode$string(username)),
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(password))
				]));
		return $author$project$Api$RequestHelper$send(
			{
				gs: $elm$http$Http$jsonBody(body),
				gN: $elm$core$Maybe$Nothing,
				dU: A2($elm$http$Http$expectStringResponse, $author$project$Api$LoginApi$loginResponseToLoginMsg, $author$project$Api$LoginApi$handleLoginResponse),
				d3: _List_Nil,
				ex: 'POST',
				jx: $elm$core$Maybe$Nothing,
				fE: appConfig.fa + '/api/v5/authenticate_student',
				fT: false
			});
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Api$AuthedRequest$EmptyBody = {$: 1};
var $author$project$Models$StudentVideo$StudentVideo = F5(
	function (studentQuestId, uploaded, completed, s3AccessUrl, teacherFeedback) {
		return {aM: completed, iL: s3AccessUrl, a_: studentQuestId, jr: teacherFeedback, jD: uploaded};
	});
var $author$project$Models$Urls$Url = $elm$core$Basics$identity;
var $author$project$Models$Urls$fromString = function (url) {
	return url;
};
var $author$project$Models$Urls$decoder = A2($elm$json$Json$Decode$map, $author$project$Models$Urls$fromString, $elm$json$Json$Decode$string);
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Models$StudentVideo$TeacherFeedback = F3(
	function (starRating, textFeedback, feedbackSeen) {
		return {g3: feedbackSeen, i2: starRating, jv: textFeedback};
	});
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Models$StudentVideo$teacherFeedbackDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Models$StudentVideo$TeacherFeedback,
	A2($elm$json$Json$Decode$field, 'star_rating', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'text_feedback', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'feedback_seen', $elm$json$Json$Decode$bool));
var $author$project$Models$StudentVideo$decoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Models$StudentVideo$StudentVideo,
	A2($elm$json$Json$Decode$field, 'student_quest_id', $author$project$Models$Ids$decoder),
	A2($elm$json$Json$Decode$field, 'uploaded', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'completed', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 's3_access_url', $author$project$Models$Urls$decoder),
	A2(
		$elm$json$Json$Decode$field,
		'teacher_feedback',
		$elm$json$Json$Decode$maybe($author$project$Models$StudentVideo$teacherFeedbackDecoder)));
var $author$project$Api$AuthedRequest$encodeBody = function (body) {
	if (!body.$) {
		var value = body.a;
		return value;
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $lukewestby$elm_http_builder$HttpBuilder$withBody = F2(
	function (body, builder) {
		return _Utils_update(
			builder,
			{gs: body});
	});
var $lukewestby$elm_http_builder$HttpBuilder$withJsonBody = function (value) {
	return $lukewestby$elm_http_builder$HttpBuilder$withBody(
		$elm$http$Http$jsonBody(value));
};
var $author$project$Api$AuthedRequest$buildRequestWithBody = F2(
	function (config, requestBuilder) {
		var body = config.gs;
		return A2(
			$lukewestby$elm_http_builder$HttpBuilder$withJsonBody,
			$author$project$Api$AuthedRequest$encodeBody(body),
			A2($author$project$Api$AuthedRequest$buildRequest, config, requestBuilder));
	});
var $lukewestby$elm_http_builder$HttpBuilder$put = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('PUT');
var $author$project$Api$AuthedRequest$put = F2(
	function (endpoint, config) {
		var baseUrl = config.bx;
		var queryParams = config.bS;
		return $author$project$Api$RequestHelper$requestBuilderToRequest(
			A2(
				$author$project$Api$AuthedRequest$buildRequestWithBody,
				config,
				$lukewestby$elm_http_builder$HttpBuilder$put(
					A2(
						$author$project$Api$RequestHelper$constructUrl,
						_Utils_ap(baseUrl, endpoint),
						queryParams))));
	});
var $author$project$Api$StudentVideoUploadApi$markErrored = F4(
	function (studentQuestId, loginInfo, appConfig, resultMsg) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$author$project$Models$StudentVideo$decoder,
			resultMsg,
			A2(
				$author$project$Api$AuthedRequest$put,
				'/api/v5/student_quests/' + ($author$project$Models$Ids$toString(studentQuestId) + '/student_video/mark_errored'),
				A5($author$project$Api$RequestHelper$createConfig, resultMsg, loginInfo, appConfig, $author$project$Api$AuthedRequest$EmptyBody, $author$project$Models$StudentVideo$decoder)));
	});
var $author$project$Api$StudentVideoUploadApi$markRecorded = F4(
	function (studentQuestId, loginInfo, appConfig, resultMsg) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$author$project$Models$StudentVideo$decoder,
			resultMsg,
			A2(
				$author$project$Api$AuthedRequest$put,
				'/api/v5/student_quests/' + ($author$project$Models$Ids$toString(studentQuestId) + '/student_video/recording_complete'),
				A5($author$project$Api$RequestHelper$createConfig, resultMsg, loginInfo, appConfig, $author$project$Api$AuthedRequest$EmptyBody, $author$project$Models$StudentVideo$decoder)));
	});
var $author$project$Api$StudentVideoUploadApi$markUploaded = F4(
	function (studentQuestId, loginInfo, appConfig, resultMsg) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$author$project$Models$StudentVideo$decoder,
			resultMsg,
			A2(
				$author$project$Api$AuthedRequest$put,
				'/api/v5/student_quests/' + ($author$project$Models$Ids$toString(studentQuestId) + '/student_video/upload_complete'),
				A5($author$project$Api$RequestHelper$createConfig, resultMsg, loginInfo, appConfig, $author$project$Api$AuthedRequest$EmptyBody, $author$project$Models$StudentVideo$decoder)));
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Ports$openExternalWindow = _Platform_outgoingPort('openExternalWindow', $elm$json$Json$Encode$string);
var $author$project$Ports$openWebActivity = _Platform_outgoingPort(
	'openWebActivity',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$Helpers$Task$performWithDelay = F2(
	function (timeInMilliseconds, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(msg),
			$elm$core$Process$sleep(timeInMilliseconds));
	});
var $author$project$Helpers$Task$performNow = function (msg) {
	return A2($author$project$Helpers$Task$performWithDelay, 0, msg);
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Ports$playInteractionSound = _Platform_outgoingPort(
	'playInteractionSound',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$bool(b)
				]));
	});
var $author$project$Ports$provideAuthInfoToChoiceText = _Platform_outgoingPort(
	'provideAuthInfoToChoiceText',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'authToken',
					$elm$json$Json$Encode$string($.gn)),
					_Utils_Tuple2(
					'iframeId',
					$elm$json$Json$Encode$string($.hk)),
					_Utils_Tuple2(
					'origin',
					$elm$json$Json$Encode$string($.id)),
					_Utils_Tuple2(
					'sessionId',
					$elm$json$Json$Encode$string($.i$))
				]));
	});
var $author$project$Ports$provideAuthInfoToPLE = _Platform_outgoingPort(
	'provideAuthInfoToPLE',
	function ($) {
		var a = $.a;
		var b = $.b;
		var c = $.c;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b),
					$elm$core$Basics$identity(c)
				]));
	});
var $author$project$Api$UnauthedRequest$Body = function (a) {
	return {$: 0, a: a};
};
var $author$project$Messages$IgnoreHttpResponse = function (a) {
	return {$: 88, a: a};
};
var $author$project$Api$RequestHelper$createUnauthedConfig = F4(
	function (resolver, _v0, body, decoder) {
		var platform = _v0.ii;
		var serverBaseUrl = _v0.fa;
		var devFlags = _v0.dO;
		return {bx: serverBaseUrl, gs: body, gN: decoder, ii: platform, bS: _List_Nil, iD: resolver, jx: devFlags.iC};
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$EventPublisher$devicePlatform = function (platform) {
	if (!platform.$) {
		return 'non_ios';
	} else {
		return 'ios';
	}
};
var $author$project$EventPublisher$deviceType = function (platform) {
	if (!platform.$) {
		return 'unknown';
	} else {
		var browserType = platform.b;
		if (browserType === 1) {
			return 'chrome';
		} else {
			return 'safari';
		}
	}
};
var $author$project$EventPublisher$eventNameVersion = function (event) {
	switch (event.$) {
		case 0:
			return _Utils_Tuple2('student.clickstream.click_ratings_modal', '1.1.0');
		case 1:
			return _Utils_Tuple2('student.clickstream.close_webapp_activity_tab', '1.1.0');
		case 7:
			return _Utils_Tuple2('student.clickstream.espark_lost_focus', '1.1.0');
		case 2:
			return _Utils_Tuple2('student.clickstream.instruction_bar_next', '1.1.0');
		case 3:
			return _Utils_Tuple2('student.clickstream.launch_webapp_activity', '1.1.0');
		case 8:
			return _Utils_Tuple2('student.clickstream.relaunch_webapp_activity', '1.1.0');
		case 4:
			return _Utils_Tuple2('student.clickstream.change_subject', '0.2.0');
		case 5:
			return _Utils_Tuple2('student.clickstream.select_adaptive_path', '0.2.0');
		case 6:
			return _Utils_Tuple2('student.clickstream.select_assignment', '0.2.0');
		case 9:
			return _Utils_Tuple2('student.clickstream.return_to_espark_tab', '1.1.0');
		case 10:
			return _Utils_Tuple2('student.clickstream.instruction_bar_toggle', '1.1.0');
		case 11:
			return _Utils_Tuple2('student.clickstream.avatar_selected', '0.2.0');
		case 12:
			return _Utils_Tuple2('student.clickstream.click_activity', '0.2.0');
		case 13:
			return _Utils_Tuple2('student.clickstream.start_video_recording', '0.2.0');
		case 14:
			return _Utils_Tuple2('student.clickstream.login', '0.2.0');
		case 15:
			return _Utils_Tuple2('student.clickstream.logout', '0.2.0');
		case 16:
			return _Utils_Tuple2('student.clickstream.mood_selected', '0.2.0');
		case 17:
			return _Utils_Tuple2('student.clickstream.open_character_selection', '0.2.0');
		case 18:
			return _Utils_Tuple2('student.clickstream.view_quest', '0.2.0');
		case 19:
			return _Utils_Tuple2('student.clickstream.launch_character_customizer', '0.2.0');
		case 20:
			return _Utils_Tuple2('student.clickstream.close_character_customizer', '0.2.0');
		default:
			return _Utils_Tuple2('student.clickstream.open_class_library', '0.1.0');
	}
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$Models$StudentActivity$UnavailableStudentActivity = {$: 3};
var $author$project$Models$Activity$Challenge = {$: 6};
var $author$project$Models$Activity$isChallenge = function (_v0) {
	var activityType = _v0.f0;
	return _Utils_eq(activityType, $author$project$Models$Activity$Challenge);
};
var $author$project$Models$Activity$Postquiz = {$: 8};
var $author$project$Models$Activity$isPostquiz = function (_v0) {
	var activityType = _v0.f0;
	return _Utils_eq(activityType, $author$project$Models$Activity$Postquiz);
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$Models$ActiveQuest$availableActivities = function (activeQuest) {
	return A2(
		$elm$core$List$filter,
		function (sa) {
			return !(activeQuest.es && ($author$project$Models$Activity$isPostquiz(sa.f_) || $author$project$Models$Activity$isChallenge(sa.f_)));
		},
		A2(
			$elm$core$List$filter,
			function (sa) {
				return !_Utils_eq(sa.ir, $author$project$Models$StudentActivity$UnavailableStudentActivity);
			},
			activeQuest.jd));
};
var $author$project$Models$Ids$idEquals = function (id) {
	return A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.hj;
		},
		$elm$core$Basics$eq(id));
};
var $author$project$Models$Ids$findById = function (id) {
	return $elm_community$list_extra$List$Extra$find(
		$author$project$Models$Ids$idEquals(id));
};
var $krisajenkins$remotedata$RemoteData$unwrap = F3(
	function (_default, _function, remoteData) {
		if (remoteData.$ === 3) {
			var data = remoteData.a;
			return _function(data);
		} else {
			return _default;
		}
	});
var $author$project$Model$studentActivityForId = F2(
	function (model, id) {
		return A3(
			$krisajenkins$remotedata$RemoteData$unwrap,
			$elm$core$Maybe$Nothing,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Models$ActiveQuest$availableActivities,
				$author$project$Models$Ids$findById(id)),
			model.fZ);
	});
var $author$project$Model$studentActivityForCurrentRoute = function (model) {
	return A2(
		$elm$core$Maybe$andThen,
		$author$project$Model$studentActivityForId(model),
		$author$project$Routes$studentActivityIdFromRoute(model.eY));
};
var $author$project$Models$Ids$toInt = A2($elm$core$Basics$composeR, $author$project$Models$Ids$toString, $elm$core$String$toInt);
var $author$project$EventPublisher$defaultEventData = F5(
	function (event, model, config, timestamp, uuid) {
		var student = function () {
			var _v0 = model.jF;
			switch (_v0.$) {
				case 0:
					return $elm$core$Maybe$Nothing;
				case 1:
					return $elm$core$Maybe$Nothing;
				default:
					var s = _v0.a;
					return $elm$core$Maybe$Just(s);
			}
		}();
		var userId = A2(
			$elm$core$Maybe$andThen,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.hj;
				},
				$author$project$Models$Ids$toInt),
			student);
		var sessionId = model.i$;
		var addEncodedStudentId = function (existingEncodings) {
			return _Utils_ap(
				existingEncodings,
				A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					_List_Nil,
					function (studentId) {
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'student_id',
								$elm$json$Json$Encode$int(studentId))
							]);
					},
					A2(
						$elm$core$Maybe$andThen,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.b1;
							},
							$author$project$Models$Ids$toInt),
						student)));
		};
		var addEncodedStudentActivityId = function (existingEncodings) {
			return _Utils_ap(
				existingEncodings,
				A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					_List_Nil,
					function (studentActivityId) {
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'student_activity_id',
								$elm$json$Json$Encode$int(studentActivityId))
							]);
					},
					A2(
						$elm$core$Maybe$andThen,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.hj;
							},
							$author$project$Models$Ids$toInt),
						$author$project$Model$studentActivityForCurrentRoute(model))));
		};
		var addEncodedClientVersion = function (existingEncodings) {
			return _Utils_ap(
				existingEncodings,
				_List_fromArray(
					[
						_Utils_Tuple2(
						'client_version',
						$elm$json$Json$Encode$string(model.f7.b0))
					]));
		};
		var addEncodedClientHostname = function (existingEncodings) {
			return _Utils_ap(
				existingEncodings,
				function (host) {
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'client_hostname',
							$elm$json$Json$Encode$string(host))
						]);
				}(
					A2(
						$elm$core$Maybe$withDefault,
						'unknown',
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.he;
							},
							$elm$url$Url$fromString(model.f7.bz)))));
		};
		var addEncodedActivityId = function (existingEncodings) {
			return _Utils_ap(
				existingEncodings,
				A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					_List_Nil,
					function (activityId) {
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'activity_id',
								$elm$json$Json$Encode$int(activityId))
							]);
					},
					A2(
						$elm$core$Maybe$andThen,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.f_;
							},
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.hj;
								},
								$author$project$Models$Ids$toInt)),
						$author$project$Model$studentActivityForCurrentRoute(model))));
		};
		var extraData = addEncodedClientVersion(
			addEncodedClientHostname(
				addEncodedStudentActivityId(
					addEncodedActivityId(
						addEncodedStudentId(_List_Nil)))));
		return {
			cj: $author$project$EventPublisher$devicePlatform(config.ii),
			ck: $author$project$EventPublisher$deviceType(config.ii),
			cp: $author$project$EventPublisher$eventNameVersion(event).a,
			cq: $author$project$EventPublisher$eventNameVersion(event).b,
			g: extraData,
			hj: uuid,
			i$: sessionId,
			b1: userId,
			c3: timestamp
		};
	});
var $jweir$elm_iso8601$ISO8601$Extras$After = 1;
var $jweir$elm_iso8601$ISO8601$Extras$Before = 0;
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{B: nodeList, j: nodeListSize, t: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $jweir$elm_iso8601$ISO8601$Extras$calendar = $elm$core$Array$fromList(
	_List_fromArray(
		[
			_Utils_Tuple3('January', 31, 31),
			_Utils_Tuple3('February', 28, 29),
			_Utils_Tuple3('March', 31, 31),
			_Utils_Tuple3('April', 30, 30),
			_Utils_Tuple3('May', 31, 31),
			_Utils_Tuple3('June', 30, 30),
			_Utils_Tuple3('July', 31, 31),
			_Utils_Tuple3('August', 31, 31),
			_Utils_Tuple3('September', 30, 30),
			_Utils_Tuple3('October', 31, 31),
			_Utils_Tuple3('November', 30, 30),
			_Utils_Tuple3('December', 31, 31)
		]));
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $jweir$elm_iso8601$ISO8601$Extras$isLeapYear = function (year) {
	var c = !A2($elm$core$Basics$modBy, 400, year);
	var b = !A2($elm$core$Basics$modBy, 100, year);
	var a = !A2($elm$core$Basics$modBy, 4, year);
	var _v0 = _List_fromArray(
		[a, b, c]);
	_v0$3:
	while (true) {
		if ((_v0.b && _v0.a) && _v0.b.b) {
			if (!_v0.b.a) {
				if (_v0.b.b.b && (!_v0.b.b.b.b)) {
					var _v3 = _v0.b;
					var _v4 = _v3.b;
					return true;
				} else {
					break _v0$3;
				}
			} else {
				if (_v0.b.b.b) {
					if (_v0.b.b.a) {
						if (!_v0.b.b.b.b) {
							var _v1 = _v0.b;
							var _v2 = _v1.b;
							return true;
						} else {
							break _v0$3;
						}
					} else {
						if (!_v0.b.b.b.b) {
							var _v5 = _v0.b;
							var _v6 = _v5.b;
							return false;
						} else {
							break _v0$3;
						}
					}
				} else {
					break _v0$3;
				}
			}
		} else {
			break _v0$3;
		}
	}
	return false;
};
var $jweir$elm_iso8601$ISO8601$Extras$daysInMonth = F2(
	function (year, monthInt) {
		var calMonth = A2($elm$core$Array$get, monthInt - 1, $jweir$elm_iso8601$ISO8601$Extras$calendar);
		if (!calMonth.$) {
			var _v1 = calMonth.a;
			var days = _v1.b;
			var leapDays = _v1.c;
			return $jweir$elm_iso8601$ISO8601$Extras$isLeapYear(year) ? leapDays : days;
		} else {
			return 0;
		}
	});
var $jweir$elm_iso8601$ISO8601$Extras$daysToMonths = F3(
	function (year, startMonth, remainingDays) {
		daysToMonths:
		while (true) {
			var remainingDays_ = remainingDays - A2($jweir$elm_iso8601$ISO8601$Extras$daysInMonth, year, startMonth);
			if (remainingDays_ > 0) {
				var $temp$year = year,
					$temp$startMonth = startMonth + 1,
					$temp$remainingDays = remainingDays_;
				year = $temp$year;
				startMonth = $temp$startMonth;
				remainingDays = $temp$remainingDays;
				continue daysToMonths;
			} else {
				return _Utils_Tuple2(startMonth, remainingDays);
			}
		}
	});
var $jweir$elm_iso8601$ISO8601$Extras$daysInYear = function (year) {
	return $jweir$elm_iso8601$ISO8601$Extras$isLeapYear(year) ? 366 : 365;
};
var $jweir$elm_iso8601$ISO8601$Extras$daysToYears = F3(
	function (rel, startYear, remainingDays) {
		daysToYears:
		while (true) {
			if (rel === 1) {
				var remainingDays_ = remainingDays - $jweir$elm_iso8601$ISO8601$Extras$daysInYear(startYear);
				if (remainingDays_ > 0) {
					var $temp$rel = 1,
						$temp$startYear = startYear + 1,
						$temp$remainingDays = remainingDays_;
					rel = $temp$rel;
					startYear = $temp$startYear;
					remainingDays = $temp$remainingDays;
					continue daysToYears;
				} else {
					if (!remainingDays_) {
						return _Utils_Tuple2(startYear + 1, 0);
					} else {
						return _Utils_Tuple2(startYear, remainingDays);
					}
				}
			} else {
				var remainingDays_ = remainingDays + $jweir$elm_iso8601$ISO8601$Extras$daysInYear(startYear);
				if (remainingDays_ < 0) {
					var $temp$rel = 0,
						$temp$startYear = startYear - 1,
						$temp$remainingDays = remainingDays_;
					rel = $temp$rel;
					startYear = $temp$startYear;
					remainingDays = $temp$remainingDays;
					continue daysToYears;
				} else {
					return _Utils_Tuple2(
						startYear,
						$jweir$elm_iso8601$ISO8601$Extras$daysInYear(startYear) + remainingDays);
				}
			}
		}
	});
var $jweir$elm_iso8601$ISO8601$defaultTime = {dK: 1, W: 0, af: 0, X: 0, eB: 1, ao: 0, ac: 0, fV: 0};
var $jweir$elm_iso8601$ISO8601$ims = 1;
var $jweir$elm_iso8601$ISO8601$isec = $jweir$elm_iso8601$ISO8601$ims * 1000;
var $jweir$elm_iso8601$ISO8601$imin = $jweir$elm_iso8601$ISO8601$isec * 60;
var $jweir$elm_iso8601$ISO8601$ihour = $jweir$elm_iso8601$ISO8601$imin * 60;
var $jweir$elm_iso8601$ISO8601$iday = $jweir$elm_iso8601$ISO8601$ihour * 24;
var $jweir$elm_iso8601$ISO8601$fromTime = function (msFloat) {
	var ms = msFloat;
	var v = (ms >= 0) ? 1 : 0;
	var milliseconds = A2($elm$core$Basics$modBy, $jweir$elm_iso8601$ISO8601$isec, ms);
	if (v === 1) {
		var seconds = A2(
			$elm$core$Basics$modBy,
			60,
			$elm$core$Basics$floor(ms / $jweir$elm_iso8601$ISO8601$isec));
		var minutes = A2($elm$core$Basics$modBy, 60, (ms / $jweir$elm_iso8601$ISO8601$imin) | 0);
		var hours = A2($elm$core$Basics$modBy, 24, (ms / $jweir$elm_iso8601$ISO8601$ihour) | 0);
		var days = (ms / $jweir$elm_iso8601$ISO8601$iday) | 0;
		var _v1 = A3($jweir$elm_iso8601$ISO8601$Extras$daysToYears, 1, 1970, days);
		var years = _v1.a;
		var remainingDays = _v1.b;
		var _v2 = A3($jweir$elm_iso8601$ISO8601$Extras$daysToMonths, years, 1, remainingDays + 1);
		var months = _v2.a;
		var daysInMonth = _v2.b;
		return _Utils_update(
			$jweir$elm_iso8601$ISO8601$defaultTime,
			{dK: daysInMonth, W: hours, af: milliseconds, X: minutes, eB: months, ac: seconds, fV: years});
	} else {
		var totalDays = (ms / $jweir$elm_iso8601$ISO8601$iday) | 0;
		var rem = A2($elm$core$Basics$modBy, $jweir$elm_iso8601$ISO8601$iday, ms);
		var seconds = A2($elm$core$Basics$modBy, 60, (rem / $jweir$elm_iso8601$ISO8601$isec) | 0);
		var minutes = A2($elm$core$Basics$modBy, 60, (rem / $jweir$elm_iso8601$ISO8601$imin) | 0);
		var hours = A2($elm$core$Basics$modBy, 24, (rem / $jweir$elm_iso8601$ISO8601$ihour) | 0);
		var days = (rem / $jweir$elm_iso8601$ISO8601$iday) | 0;
		var _v3 = (!rem) ? A3($jweir$elm_iso8601$ISO8601$Extras$daysToYears, 0, 1969, totalDays + 1) : A3($jweir$elm_iso8601$ISO8601$Extras$daysToYears, 0, 1969, totalDays);
		var years = _v3.a;
		var remainingDays = _v3.b;
		var _v4 = A3($jweir$elm_iso8601$ISO8601$Extras$daysToMonths, years, 1, remainingDays);
		var months = _v4.a;
		var daysInMonth = _v4.b;
		return _Utils_update(
			$jweir$elm_iso8601$ISO8601$defaultTime,
			{dK: daysInMonth, W: hours, af: milliseconds, X: minutes, eB: months, ac: seconds, fV: years});
	}
};
var $jweir$elm_iso8601$ISO8601$fromPosix = function (posix) {
	return $jweir$elm_iso8601$ISO8601$fromTime(
		$elm$time$Time$posixToMillis(posix));
};
var $jweir$elm_iso8601$ISO8601$fmt = function (n) {
	return (n < 10) ? ('0' + $elm$core$String$fromInt(n)) : $elm$core$String$fromInt(n);
};
var $jweir$elm_iso8601$ISO8601$fmtMs = function (n) {
	return (!n) ? '' : ((n < 10) ? ('.00' + $elm$core$String$fromInt(n)) : ((n < 100) ? ('.0' + $elm$core$String$fromInt(n)) : ('.' + $elm$core$String$fromInt(n))));
};
var $jweir$elm_iso8601$ISO8601$fmtOffset = function (minutes) {
	if (!minutes) {
		return 'Z';
	} else {
		var symbol = (minutes >= 0) ? '+' : '-';
		var m = A2($elm$core$Basics$modBy, 60, minutes);
		var h = (minutes / 60) | 0;
		return _Utils_ap(
			symbol,
			_Utils_ap(
				$jweir$elm_iso8601$ISO8601$fmt(
					$elm$core$Basics$abs(h)),
				$jweir$elm_iso8601$ISO8601$fmt(m)));
	}
};
var $jweir$elm_iso8601$ISO8601$fmtYear = function (n) {
	var s = $elm$core$String$fromInt(n);
	return (n < 10) ? ('000' + s) : ((n < 100) ? ('00' + s) : ((n < 1000) ? ('0' + s) : s));
};
var $jweir$elm_iso8601$ISO8601$toString = function (time) {
	return A2(
		$elm$core$String$join,
		'',
		_List_fromArray(
			[
				$jweir$elm_iso8601$ISO8601$fmtYear(time.fV),
				'-',
				$jweir$elm_iso8601$ISO8601$fmt(time.eB),
				'-',
				$jweir$elm_iso8601$ISO8601$fmt(time.dK),
				'T',
				$jweir$elm_iso8601$ISO8601$fmt(time.W),
				':',
				$jweir$elm_iso8601$ISO8601$fmt(time.X),
				':',
				$jweir$elm_iso8601$ISO8601$fmt(time.ac),
				$jweir$elm_iso8601$ISO8601$fmtMs(time.af),
				$jweir$elm_iso8601$ISO8601$fmtOffset(time.ao)
			]));
};
var $author$project$EventPublisher$encodeStudentClickstreamData = function (data) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(data.hj)),
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string(data.cp)),
				_Utils_Tuple2(
				'version',
				$elm$json$Json$Encode$string(data.cq)),
				_Utils_Tuple2(
				'timestamp',
				$elm$json$Json$Encode$string(
					$jweir$elm_iso8601$ISO8601$toString(
						$jweir$elm_iso8601$ISO8601$fromPosix(data.c3)))),
				_Utils_Tuple2(
				'student_id',
				A2($author$project$Json$Encode$Extra$nullable, $elm$json$Json$Encode$int, data.b1)),
				_Utils_Tuple2(
				'session_id',
				$elm$json$Json$Encode$string(data.i$)),
				_Utils_Tuple2(
				'device_platform',
				$elm$json$Json$Encode$string(data.cj)),
				_Utils_Tuple2(
				'device_type',
				$elm$json$Json$Encode$string(data.ck)),
				_Utils_Tuple2(
				'data',
				$elm$json$Json$Encode$object(data.g))
			]));
};
var $author$project$EventPublisher$encodeClickSelectSubjectAreaData = F2(
	function (eventData, subjectArea) {
		return $author$project$EventPublisher$encodeStudentClickstreamData(
			_Utils_update(
				eventData,
				{
					g: A2(
						$elm$core$List$append,
						eventData.g,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'subject_area_id',
								$elm$json$Json$Encode$int(
									$author$project$Models$SubjectArea$toBackendId(subjectArea)))
							]))
				}));
	});
var $author$project$EventPublisher$encodeExitCustomizerData = F2(
	function (eventData, _v0) {
		var exitMethod = _v0.g_;
		return $author$project$EventPublisher$encodeStudentClickstreamData(
			_Utils_update(
				eventData,
				{
					g: A2(
						$elm$core$List$append,
						eventData.g,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'exit_type',
								$elm$json$Json$Encode$string(
									function () {
										if (!exitMethod) {
											return 'close_modal';
										} else {
											return 'accept_changes';
										}
									}()))
							]))
				}));
	});
var $author$project$EventPublisher$encodeStudentClickstreamAvatarSelected = F2(
	function (eventData, data) {
		var enhancedData = A2(
			$elm$core$List$append,
			eventData.g,
			_List_fromArray(
				[
					_Utils_Tuple2(
					'selected_avatar_name',
					$elm$json$Json$Encode$string(data.iY)),
					_Utils_Tuple2(
					'current_avatar_name',
					$elm$json$Json$Encode$string(data.gJ))
				]));
		return $author$project$EventPublisher$encodeStudentClickstreamData(
			_Utils_update(
				eventData,
				{g: enhancedData}));
	});
var $author$project$EventPublisher$encodeStudentClickstreamClickActivity = F2(
	function (eventData, data) {
		var enhancedData = A2(
			$elm$core$List$append,
			eventData.g,
			_List_fromArray(
				[
					_Utils_Tuple2(
					'activity_id',
					$elm$json$Json$Encode$int(data.f2)),
					_Utils_Tuple2(
					'student_activity_id',
					$elm$json$Json$Encode$int(data.jh))
				]));
		return $author$project$EventPublisher$encodeStudentClickstreamData(
			_Utils_update(
				eventData,
				{g: enhancedData}));
	});
var $author$project$EventPublisher$encodeStudentClickstreamMoodSelected = F2(
	function (eventData, data) {
		var enhancedData = A2(
			$elm$core$List$append,
			eventData.g,
			_List_fromArray(
				[
					_Utils_Tuple2(
					'mood_id',
					$elm$json$Json$Encode$string(data.hO))
				]));
		return $author$project$EventPublisher$encodeStudentClickstreamData(
			_Utils_update(
				eventData,
				{g: enhancedData}));
	});
var $author$project$EventPublisher$encodeStudentClickstreamOpenClassLibrary = F2(
	function (eventData, data) {
		return $author$project$EventPublisher$encodeStudentClickstreamData(
			_Utils_update(
				eventData,
				{
					g: _List_fromArray(
						[
							_Utils_Tuple2(
							'section_id',
							$elm$json$Json$Encode$int(
								A2(
									$elm$core$Maybe$withDefault,
									-1,
									$author$project$Models$Ids$toInt(data.iT))))
						])
				}));
	});
var $author$project$Models$StudentActivity$selectionToString = function (selection) {
	if (selection.$ === 1) {
		return 'keep_working';
	} else {
		switch (selection.a) {
			case 0:
				var _v1 = selection.a;
				return 'thumbs_up';
			case 1:
				var _v2 = selection.a;
				return 'thumbs_down';
			case 2:
				var _v3 = selection.a;
				return 'skipped';
			default:
				var _v4 = selection.a;
				return 'rating_not_applicable';
		}
	}
};
var $author$project$EventPublisher$encodeStudentClickstreamRatingsModalData = F2(
	function (eventData, data) {
		var enhancedData = A2(
			$elm$core$List$append,
			eventData.g,
			_List_fromArray(
				[
					_Utils_Tuple2(
					'third_party_content_url',
					$elm$json$Json$Encode$string(data.fs)),
					_Utils_Tuple2(
					'loaded_in_iframe',
					$elm$json$Json$Encode$bool(data.er)),
					_Utils_Tuple2(
					'keep_working_option',
					$elm$json$Json$Encode$bool(data.hu)),
					_Utils_Tuple2(
					'selection',
					$elm$json$Json$Encode$string(
						$author$project$Models$StudentActivity$selectionToString(data.iZ)))
				]));
		return $author$project$EventPublisher$encodeStudentClickstreamData(
			_Utils_update(
				eventData,
				{g: enhancedData}));
	});
var $author$project$EventPublisher$encodeStudentClickstreamReturnToEsparkData = F3(
	function (eventData, activityWindowState, currentTime) {
		var timeLeft = function () {
			switch (activityWindowState.$) {
				case 1:
					var openTime = activityWindowState.b;
					return $elm$time$Time$posixToMillis(currentTime) - $elm$time$Time$posixToMillis(openTime);
				case 2:
					return 0;
				default:
					return 0;
			}
		}();
		var enhancedData = A2(
			$elm$core$List$append,
			eventData.g,
			_List_fromArray(
				[
					_Utils_Tuple2(
					'activity_time_left',
					$elm$json$Json$Encode$int(timeLeft))
				]));
		return $author$project$EventPublisher$encodeStudentClickstreamData(
			_Utils_update(
				eventData,
				{g: enhancedData}));
	});
var $author$project$EventPublisher$encodeStudentClickstreamViewQuest = F2(
	function (eventData, data) {
		var enhancedData = A2(
			$elm$core$List$append,
			eventData.g,
			_List_fromArray(
				[
					_Utils_Tuple2(
					'quest_id',
					$elm$json$Json$Encode$int(data.iu)),
					_Utils_Tuple2(
					'student_quest_id',
					$elm$json$Json$Encode$int(data.ji))
				]));
		return $author$project$EventPublisher$encodeStudentClickstreamData(
			_Utils_update(
				eventData,
				{g: enhancedData}));
	});
var $author$project$EventPublisher$encodeStudentClickstreamWebappData = F2(
	function (eventData, data) {
		var enhancedData = A2(
			$elm$core$List$append,
			eventData.g,
			_List_fromArray(
				[
					_Utils_Tuple2(
					'third_party_content_url',
					$elm$json$Json$Encode$string(data.fs)),
					_Utils_Tuple2(
					'loaded_in_iframe',
					$elm$json$Json$Encode$bool(data.er))
				]));
		return $author$project$EventPublisher$encodeStudentClickstreamData(
			_Utils_update(
				eventData,
				{g: enhancedData}));
	});
var $author$project$EventPublisher$encodeEvent = F5(
	function (event, model, config, timestamp, uuid) {
		var eventData = A5($author$project$EventPublisher$defaultEventData, event, model, config, timestamp, uuid);
		switch (event.$) {
			case 0:
				var data = event.a;
				return A2($author$project$EventPublisher$encodeStudentClickstreamRatingsModalData, eventData, data);
			case 1:
				var data = event.a;
				return A2($author$project$EventPublisher$encodeStudentClickstreamWebappData, eventData, data);
			case 7:
				return $author$project$EventPublisher$encodeStudentClickstreamData(eventData);
			case 2:
				var data = event.a;
				return A2($author$project$EventPublisher$encodeStudentClickstreamWebappData, eventData, data);
			case 3:
				var data = event.a;
				return A2($author$project$EventPublisher$encodeStudentClickstreamWebappData, eventData, data);
			case 4:
				return $author$project$EventPublisher$encodeStudentClickstreamData(eventData);
			case 5:
				var subjectArea = event.a;
				return A2($author$project$EventPublisher$encodeClickSelectSubjectAreaData, eventData, subjectArea);
			case 6:
				var subjectArea = event.a;
				return A2($author$project$EventPublisher$encodeClickSelectSubjectAreaData, eventData, subjectArea);
			case 8:
				var data = event.a;
				return A2($author$project$EventPublisher$encodeStudentClickstreamWebappData, eventData, data);
			case 9:
				return A3($author$project$EventPublisher$encodeStudentClickstreamReturnToEsparkData, eventData, model.f1, timestamp);
			case 10:
				var data = event.a;
				return A2($author$project$EventPublisher$encodeStudentClickstreamWebappData, eventData, data);
			case 11:
				var data = event.a;
				return A2($author$project$EventPublisher$encodeStudentClickstreamAvatarSelected, eventData, data);
			case 12:
				var data = event.a;
				return A2($author$project$EventPublisher$encodeStudentClickstreamClickActivity, eventData, data);
			case 13:
				return $author$project$EventPublisher$encodeStudentClickstreamData(eventData);
			case 14:
				return $author$project$EventPublisher$encodeStudentClickstreamData(eventData);
			case 15:
				return $author$project$EventPublisher$encodeStudentClickstreamData(eventData);
			case 16:
				var data = event.a;
				return A2($author$project$EventPublisher$encodeStudentClickstreamMoodSelected, eventData, data);
			case 17:
				return $author$project$EventPublisher$encodeStudentClickstreamData(eventData);
			case 18:
				var data = event.a;
				return A2($author$project$EventPublisher$encodeStudentClickstreamViewQuest, eventData, data);
			case 19:
				return $author$project$EventPublisher$encodeStudentClickstreamData(eventData);
			case 20:
				var exitCustomizerData = event.a;
				return A2($author$project$EventPublisher$encodeExitCustomizerData, eventData, exitCustomizerData);
			default:
				var openClassLibraryData = event.a;
				return A2($author$project$EventPublisher$encodeStudentClickstreamOpenClassLibrary, eventData, openClassLibraryData);
		}
	});
var $author$project$Api$UnauthedRequest$buildRequest = F2(
	function (_v0, requestBuilder) {
		var timeout = _v0.jx;
		var resolver = _v0.iD;
		var decoder = _v0.gN;
		return A2(
			$lukewestby$elm_http_builder$HttpBuilder$withTimeout,
			timeout,
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				A2($elm$http$Http$expectJson, resolver, decoder),
				requestBuilder));
	});
var $author$project$Api$UnauthedRequest$encodeBody = function (body) {
	if (!body.$) {
		var value = body.a;
		return value;
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $author$project$Api$UnauthedRequest$buildRequestWithBody = F2(
	function (config, requestBuilder) {
		var body = config.gs;
		return A2(
			$lukewestby$elm_http_builder$HttpBuilder$withJsonBody,
			$author$project$Api$UnauthedRequest$encodeBody(body),
			A2($author$project$Api$UnauthedRequest$buildRequest, config, requestBuilder));
	});
var $lukewestby$elm_http_builder$HttpBuilder$post = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('POST');
var $author$project$Api$UnauthedRequest$post = F2(
	function (endpoint, config) {
		var baseUrl = config.bx;
		var queryParams = config.bS;
		return $author$project$Api$RequestHelper$requestBuilderToRequest(
			A2(
				$author$project$Api$UnauthedRequest$buildRequestWithBody,
				config,
				$lukewestby$elm_http_builder$HttpBuilder$post(
					A2(
						$author$project$Api$RequestHelper$constructUrl,
						_Utils_ap(baseUrl, endpoint),
						queryParams))));
	});
var $author$project$EventPublisher$publish = F4(
	function (event, model, timestamp, uuid) {
		var appConfiguration = model.f7;
		var body = A5($author$project$EventPublisher$encodeEvent, event, model, appConfiguration, timestamp, uuid);
		return A3(
			$author$project$Api$HttpRetry$send,
			$elm$json$Json$Decode$succeed(0),
			$author$project$Messages$IgnoreHttpResponse,
			A2(
				$author$project$Api$UnauthedRequest$post,
				'/api/v5/events',
				A4(
					$author$project$Api$RequestHelper$createUnauthedConfig,
					$author$project$Messages$IgnoreHttpResponse,
					appConfiguration,
					$author$project$Api$UnauthedRequest$Body(body),
					$elm$json$Json$Decode$succeed(0))));
	});
var $author$project$LoggedIn$Messages$RecordCustomizerOpenedResponse = function (a) {
	return {$: 96, a: a};
};
var $author$project$Api$StudentCustomizerApi$record = F2(
	function (loginInfo, appConfig) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$elm$json$Json$Decode$succeed($elm$json$Json$Encode$null),
			$author$project$LoggedIn$Messages$RecordCustomizerOpenedResponse,
			A2(
				$author$project$Api$AuthedRequest$put,
				'/api/v5/students/open_customizer_event',
				A5(
					$author$project$Api$RequestHelper$createConfig,
					$author$project$LoggedIn$Messages$RecordCustomizerOpenedResponse,
					loginInfo,
					appConfig,
					$author$project$Api$AuthedRequest$EmptyBody,
					$elm$json$Json$Decode$succeed($elm$json$Json$Encode$null))));
	});
var $author$project$Api$AuthedRequest$Body = function (a) {
	return {$: 0, a: a};
};
var $author$project$LoggedIn$Messages$RecordStudentMoodResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$Models$StudentMood$moodToString = function (mood) {
	switch (mood) {
		case 0:
			return 'bored';
		case 1:
			return 'cool';
		case 2:
			return 'excited';
		case 3:
			return 'happy';
		case 4:
			return 'mad';
		case 5:
			return 'okay';
		case 6:
			return 'proud';
		default:
			return 'sad';
	}
};
var $author$project$Api$AuthedRequest$post = F2(
	function (endpoint, config) {
		var baseUrl = config.bx;
		var queryParams = config.bS;
		return $author$project$Api$RequestHelper$requestBuilderToRequest(
			A2(
				$author$project$Api$AuthedRequest$buildRequestWithBody,
				config,
				$lukewestby$elm_http_builder$HttpBuilder$post(
					A2(
						$author$project$Api$RequestHelper$constructUrl,
						_Utils_ap(baseUrl, endpoint),
						queryParams))));
	});
var $author$project$Api$StudentMoodApi$record = F3(
	function (mood, loginInfo, appConfig) {
		var body = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'value',
					$elm$json$Json$Encode$string(
						$author$project$Models$StudentMood$moodToString(mood)))
				]));
		return A3(
			$author$project$Api$HttpRetry$send,
			$elm$json$Json$Decode$succeed(body),
			$author$project$LoggedIn$Messages$RecordStudentMoodResponse,
			A2(
				$author$project$Api$AuthedRequest$post,
				'/api/v5/student_moods/',
				A5(
					$author$project$Api$RequestHelper$createConfig,
					$author$project$LoggedIn$Messages$RecordStudentMoodResponse,
					loginInfo,
					appConfig,
					$author$project$Api$AuthedRequest$Body(body),
					$elm$json$Json$Decode$succeed(body))));
	});
var $author$project$Ports$removeStoredStudentVideo = _Platform_outgoingPort('removeStoredStudentVideo', $elm$json$Json$Encode$string);
var $author$project$Ports$replayRecordedStudentVideo = _Platform_outgoingPort('replayRecordedStudentVideo', $elm$json$Json$Encode$string);
var $author$project$Ports$resetTTS = _Platform_outgoingPort(
	'resetTTS',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$retrieveStoredStudentVideos = _Platform_outgoingPort(
	'retrieveStoredStudentVideos',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$setHeartbeatData = _Platform_outgoingPort('setHeartbeatData', $elm$core$Basics$identity);
var $author$project$InteractionSound$src = function (_v0) {
	var soundSrc = _v0;
	return $author$project$Assets$path(soundSrc);
};
var $author$project$LoggedIn$Messages$StartStudentActivityResponse = function (a) {
	return {$: 29, a: a};
};
var $author$project$Models$StudentActivity$ActiveStudentActivity = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$StudentActivity$AssessmentActivity = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$StudentActivity$CanCompleteContent = {$: 1};
var $author$project$Models$StudentActivity$CompletedContent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Models$StudentActivity$CompletedStudentActivity = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Models$StudentActivity$ContentActivity = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Models$StudentActivity$DisplaySequence = $elm$core$Basics$identity;
var $author$project$Models$StudentActivity$HasAssessment = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$StudentActivity$NoAssessment = {$: 0};
var $author$project$Models$StudentActivity$None = {$: 0};
var $author$project$Models$AssessmentStatus$NotCurrentlyTakingAssessment = {$: 0};
var $author$project$Models$StudentActivity$RequiredFieldsForDeterminingProgress = F4(
	function (activity, maybeWatchedVideo, maybeContentCompletedAt, isRemedial) {
		return {f_: activity, hs: isRemedial, ev: maybeContentCompletedAt, ew: maybeWatchedVideo};
	});
var $author$project$Models$StudentActivity$StartedContent = {$: 0};
var $author$project$Models$StudentActivity$StudentActivity = F8(
	function (id, studentQuestId, progress, activity, assessmentResult, displaySequence, isRemedial, viewStatus) {
		return {f_: activity, gd: assessmentResult, dP: displaySequence, hj: id, hs: isRemedial, ir: progress, a_: studentQuestId, fL: viewStatus};
	});
var $author$project$Models$StudentActivity$UnstartedStudentActivity = {$: 0};
var $author$project$Models$Activity$Activity = function (id) {
	return function (questId) {
		return function (title) {
			return function (activityType) {
				return function (componentType) {
					return function (instructions) {
						return function (sequence) {
							return function (completionGroup) {
								return function (iconUrl) {
									return function (screenshotUrl) {
										return function (maximumActivityTime) {
											return function (learnosityItemId) {
												return function (allowIframe) {
													return function (platformRestriction) {
														return {f0: activityType, dl: allowIframe, dE: completionGroup, dG: componentType, d6: iconUrl, hj: id, ea: instructions, em: learnosityItemId, hG: maximumActivityTime, ij: platformRestriction, cQ: questId, e5: screenshotUrl, e9: sequence, fx: title};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Models$Activity$ActivitySequence = $elm$core$Basics$identity;
var $author$project$Models$Activity$ChoiceText = {$: 3};
var $author$project$Models$Activity$ChoiceWriting = {$: 4};
var $author$project$Models$Activity$Ebook = function (a) {
	return {$: 2, a: a};
};
var $author$project$Models$Activity$FetchingUrl = 0;
var $author$project$Models$Activity$LiveReading = {$: 5};
var $author$project$Models$Activity$Prequiz = {$: 7};
var $author$project$Models$Activity$VideoUrlNotYetLoaded = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Models$Activity$WebApp = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$YoutubeVideoPlayer$YoutubeData = F3(
	function (youtubeId, clipStart, clipEnd) {
		return {dB: clipEnd, cc: clipStart, fW: youtubeId};
	});
var $author$project$Models$Activity$YoutubeVideo = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $author$project$Models$Activity$activityTypeDecoder = function () {
	var youtubeDataDecoder = A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['youtube_data']),
		A4(
			$elm$json$Json$Decode$map3,
			$author$project$Models$YoutubeVideoPlayer$YoutubeData,
			A2($elm$json$Json$Decode$field, 'youtube_id', $author$project$Models$Ids$decoder),
			A2(
				$elm$json$Json$Decode$field,
				'clip_start',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
			A2(
				$elm$json$Json$Decode$field,
				'clip_end',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string))));
	var activityUrlDecoder = A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string);
	var decoderForActivityType = function (typeString) {
		switch (typeString) {
			case 'V5PreQuiz':
				return $elm$json$Json$Decode$succeed($author$project$Models$Activity$Prequiz);
			case 'V5PostQuiz':
				return $elm$json$Json$Decode$succeed($author$project$Models$Activity$Postquiz);
			case 'V5StandaloneQuestion':
				return $elm$json$Json$Decode$succeed($author$project$Models$Activity$Challenge);
			case 'ChoiceTextApp':
				return $elm$json$Json$Decode$succeed($author$project$Models$Activity$ChoiceText);
			case 'ChoiceWritingApp':
				return $elm$json$Json$Decode$succeed($author$project$Models$Activity$ChoiceWriting);
			case 'LiveReadingApp':
				return $elm$json$Json$Decode$succeed($author$project$Models$Activity$LiveReading);
			case 'YoutubeVideo':
				return $elm$json$Json$Decode$oneOf(
					_List_fromArray(
						[
							A3(
							$elm$json$Json$Decode$map2,
							$author$project$Models$Activity$YoutubeVideo,
							A2(
								$elm$json$Json$Decode$map,
								A2(
									$elm$core$Basics$composeR,
									$author$project$Models$Urls$fromString,
									$author$project$Models$Activity$VideoUrlNotYetLoaded(0)),
								activityUrlDecoder),
							youtubeDataDecoder),
							$elm$json$Json$Decode$fail('Either activity URL or YoutubeId not provided, but it\'s required for: ' + typeString)
						]));
			case 'WebApp':
				return $elm$json$Json$Decode$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$json$Json$Decode$andThen,
							function (url) {
								return A2(
									$elm$core$List$member,
									$elm_community$list_extra$List$Extra$last(
										A2($elm$core$String$split, '.', url)),
									_List_fromArray(
										[
											$elm$core$Maybe$Just('epub'),
											$elm$core$Maybe$Just('opf')
										])) ? $elm$json$Json$Decode$succeed(
									$author$project$Models$Activity$Ebook(
										$author$project$Models$Urls$fromString(url))) : $elm$json$Json$Decode$succeed(
									$author$project$Models$Activity$WebApp(
										$author$project$Models$Urls$fromString(url)));
							},
							activityUrlDecoder),
							$elm$json$Json$Decode$fail('No activity URL provided, but it\'s required for: ' + typeString)
						]));
			default:
				return $elm$json$Json$Decode$fail('Unknown activity type provided: ' + typeString);
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		decoderForActivityType,
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}();
var $author$project$Models$Activity$ActivitiesGroup = 2;
var $author$project$Models$Activity$FramingVideoGroup = 1;
var $author$project$Models$Activity$PostquizGroup = 3;
var $author$project$Models$Activity$PrequizGroup = 0;
var $author$project$Models$Activity$completionGroupDecoder = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (category, activityType) {
			return _Utils_eq(activityType, $author$project$Models$Activity$Prequiz) ? 0 : (_Utils_eq(activityType, $author$project$Models$Activity$Postquiz) ? 3 : (_Utils_eq(
				category,
				$elm$core$Maybe$Just('Intro Video')) ? 1 : 2));
		}),
	A2(
		$elm$json$Json$Decode$field,
		'category',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
	$author$project$Models$Activity$activityTypeDecoder);
var $author$project$Models$Activity$Evaluate = 0;
var $author$project$Models$Activity$EvaluatePreview = 1;
var $author$project$Models$Activity$componentTypeFromString = function (componentTypeString) {
	switch (componentTypeString) {
		case 'evaluate':
			return $elm$core$Maybe$Just(0);
		case 'evaluate_preview':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Activity$componentTypeDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$andThen,
			A2($elm$core$Basics$composeR, $author$project$Models$Activity$componentTypeFromString, $elm$json$Json$Decode$succeed),
			A2($elm$json$Json$Decode$field, 'component_type', $elm$json$Json$Decode$string)),
			$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
		]));
var $author$project$Models$Activity$Instructions = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$Activity$NoInstructions = {$: 0};
var $author$project$Models$Activity$instructionsDecoder = function () {
	var instructionsFromString = function (instructions) {
		return ($elm$core$String$length(instructions) > 0) ? $author$project$Models$Activity$Instructions(instructions) : $author$project$Models$Activity$NoInstructions;
	};
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$field,
				'instructions',
				A2($elm$json$Json$Decode$map, instructionsFromString, $elm$json$Json$Decode$string)),
				$elm$json$Json$Decode$succeed($author$project$Models$Activity$NoInstructions)
			]));
}();
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Models$Activity$NoRestrictions = 2;
var $author$project$Models$Activity$RestrictToIos = 0;
var $author$project$Models$Activity$RestrictToWeb = 1;
var $author$project$Models$Activity$platformRestrictionDecoder = A2(
	$elm$json$Json$Decode$map,
	function (value) {
		if (!value.$) {
			switch (value.a) {
				case 'ipad':
					return 0;
				case 'web':
					return 1;
				default:
					return 2;
			}
		} else {
			return 2;
		}
	},
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string));
var $author$project$Assets$AssetPath = $elm$core$Basics$identity;
var $author$project$Assets$quizCard = require('/usr/src/app/app/images/quiz-card.svg');
var $author$project$Assets$videoActivityCard = require('/usr/src/app/app/images/video-activity-card.svg');
var $author$project$Assets$webAppCard = require('/usr/src/app/app/images/web-app-card.svg');
var $author$project$Models$Activity$screenShotDecoder = function () {
	var activityTypeAssetPath = function (activityType) {
		switch (activityType.$) {
			case 0:
				return $author$project$Assets$path($author$project$Assets$videoActivityCard);
			case 1:
				return $author$project$Assets$path($author$project$Assets$webAppCard);
			case 2:
				return $author$project$Assets$path($author$project$Assets$webAppCard);
			case 3:
				return $author$project$Assets$path($author$project$Assets$webAppCard);
			case 4:
				return $author$project$Assets$path($author$project$Assets$webAppCard);
			case 5:
				return $author$project$Assets$path($author$project$Assets$webAppCard);
			case 7:
				return $author$project$Assets$path($author$project$Assets$quizCard);
			case 6:
				return $author$project$Assets$path($author$project$Assets$quizCard);
			default:
				return $author$project$Assets$path($author$project$Assets$quizCard);
		}
	};
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$Models$Urls$fromString,
		$elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2($elm$json$Json$Decode$field, 'screenshot_url', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$map, activityTypeAssetPath, $author$project$Models$Activity$activityTypeDecoder)
				])));
}();
var $author$project$Models$Activity$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'limit_to_device_type',
	$author$project$Models$Activity$platformRestrictionDecoder,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'allow_iframe',
		$elm$json$Json$Decode$bool,
		false,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'learnosity_item_id',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'maximum_activity_time',
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Basics$mul(60000),
					$elm$json$Json$Decode$float),
				A2(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
					$author$project$Models$Activity$screenShotDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'icon_url',
						$elm$json$Json$Decode$maybe($author$project$Models$Urls$decoder),
						A2(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
							$author$project$Models$Activity$completionGroupDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'sequence',
								A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$int),
								A2(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
									$author$project$Models$Activity$instructionsDecoder,
									A2(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
										$author$project$Models$Activity$componentTypeDecoder,
										A2(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
											$author$project$Models$Activity$activityTypeDecoder,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'title',
												$elm$json$Json$Decode$string,
												'Activity',
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'quest_id',
													$author$project$Models$Ids$decoder,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'id',
														$author$project$Models$Ids$decoder,
														$elm$json$Json$Decode$succeed($author$project$Models$Activity$Activity)))))))))))))));
var $author$project$Models$AssessmentResult$AssessmentResult = F4(
	function (score, maxScore, scores, passed) {
		return {eu: maxScore, $8: passed, e4: score, iN: scores};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$Models$ItemScore$Correct = 3;
var $author$project$Models$ItemScore$Incorrect = 2;
var $author$project$Models$ItemScore$ItemScore = function (score) {
	return function (maxScore) {
		return function (active) {
			return function (revision) {
				return function (scoreStatus) {
					return function (ref) {
						return function (time) {
							return function (responseId) {
								return function (responseValue) {
									return function (questionType) {
										return function (featureTypes) {
											return function (rationale) {
												return function (stimulus) {
													return function (tags) {
														return {ar: active, aP: featureTypes, eu: maxScore, aV: questionType, bi: rationale, iA: ref, aj: responseId, eX: responseValue, iI: revision, e4: score, iM: scoreStatus, aZ: stimulus, jp: tags, a$: time};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded = A2($elm$core$Basics$composeR, $elm$json$Json$Decode$succeed, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom);
var $author$project$Models$ItemScore$quizResultScoresDecoder = function () {
	var decodeScoreStatus = A3(
		$elm$json$Json$Decode$map2,
		F2(
			function (score, maxScore) {
				return _Utils_eq(
					score,
					$elm$core$Maybe$Just(maxScore)) ? 3 : 2;
			}),
		A2(
			$elm$json$Json$Decode$field,
			'score',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
		A2($elm$json$Json$Decode$field, 'max_score', $elm$json$Json$Decode$int));
	return A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
		_List_Nil,
		A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
			'',
			A2(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
				$elm$core$Maybe$Nothing,
				A2(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
					_List_Nil,
					A2(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
						'',
						A2(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
							$elm$core$Maybe$Just(''),
							A2(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
								$elm$core$Maybe$Just(''),
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'time',
									$elm$json$Json$Decode$int,
									0,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'ref',
										$elm$json$Json$Decode$string,
										'',
										A2(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
											decodeScoreStatus,
											A2(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
												A2(
													$elm$json$Json$Decode$map,
													$elm$core$Maybe$withDefault(0),
													$elm$json$Json$Decode$maybe(
														A2($elm$json$Json$Decode$field, 'attempt_count', $elm$json$Json$Decode$int))),
												A2(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
													false,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'max_score',
														$elm$json$Json$Decode$oneOf(
															_List_fromArray(
																[
																	$elm$json$Json$Decode$int,
																	$elm$json$Json$Decode$null(1)
																])),
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'score',
															$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
															$elm$json$Json$Decode$succeed($author$project$Models$ItemScore$ItemScore)))))))))))))));
}();
var $author$project$Models$AssessmentResult$decoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Models$AssessmentResult$AssessmentResult,
	A2($elm$json$Json$Decode$field, 'score', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'max_score', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'scores',
		$elm$json$Json$Decode$list($author$project$Models$ItemScore$quizResultScoresDecoder)),
	A2($elm$json$Json$Decode$field, 'passed', $elm$json$Json$Decode$bool));
var $author$project$Models$Activity$hasActivityAssessment = function (_v0) {
	var learnosityItemId = _v0.em;
	var componentType = _v0.dG;
	return (!_Utils_eq(learnosityItemId, $elm$core$Maybe$Nothing)) || (!_Utils_eq(componentType, $elm$core$Maybe$Nothing));
};
var $author$project$Models$Activity$isPrePostQuiz = function (_v0) {
	var activityType = _v0.f0;
	return _Utils_eq(activityType, $author$project$Models$Activity$Prequiz) || _Utils_eq(activityType, $author$project$Models$Activity$Postquiz);
};
var $author$project$Models$Activity$hasAnAssessment = function (activity) {
	return $author$project$Models$Activity$hasActivityAssessment(activity) || $author$project$Models$Activity$isPrePostQuiz(activity);
};
var $author$project$Models$Activity$hasPracticeContent = function (_v0) {
	var activityType = _v0.f0;
	return !A2(
		$elm$core$List$member,
		activityType,
		_List_fromArray(
			[$author$project$Models$Activity$Prequiz, $author$project$Models$Activity$Postquiz, $author$project$Models$Activity$Challenge]));
};
var $author$project$Models$StudentActivity$decoder = function () {
	var studentActivityProgress = function (progressString) {
		var determineProgress = function (_v1) {
			var activity = _v1.f_;
			var maybeWatchedVideo = _v1.ew;
			var maybeContentCompletedAt = _v1.ev;
			var isRemedial = _v1.hs;
			var calculatedContentCompletionProgress = function () {
				if (!maybeContentCompletedAt.$) {
					var timestamp = maybeContentCompletedAt.a;
					return $author$project$Models$StudentActivity$CompletedContent(timestamp);
				} else {
					return _Utils_eq(
						maybeWatchedVideo,
						$elm$core$Maybe$Just(true)) ? $author$project$Models$StudentActivity$CanCompleteContent : $author$project$Models$StudentActivity$StartedContent;
				}
			}();
			var activityAssessmentProgress = ($author$project$Models$Activity$hasAnAssessment(activity) && isRemedial) ? $author$project$Models$StudentActivity$NoAssessment : ($author$project$Models$Activity$hasAnAssessment(activity) ? $author$project$Models$StudentActivity$HasAssessment($author$project$Models$AssessmentStatus$NotCurrentlyTakingAssessment) : $author$project$Models$StudentActivity$NoAssessment);
			var activeActivityData = $author$project$Models$Activity$hasPracticeContent(activity) ? A2($author$project$Models$StudentActivity$ContentActivity, calculatedContentCompletionProgress, activityAssessmentProgress) : $author$project$Models$StudentActivity$AssessmentActivity($author$project$Models$AssessmentStatus$NotCurrentlyTakingAssessment);
			return (progressString === 'active') ? $elm$json$Json$Decode$succeed(
				$author$project$Models$StudentActivity$ActiveStudentActivity(activeActivityData)) : ((progressString === 'completed') ? $elm$json$Json$Decode$succeed(
				A2($author$project$Models$StudentActivity$CompletedStudentActivity, maybeContentCompletedAt, activityAssessmentProgress)) : $elm$json$Json$Decode$succeed($author$project$Models$StudentActivity$UnstartedStudentActivity));
		};
		return A2(
			$elm$json$Json$Decode$andThen,
			determineProgress,
			A5(
				$elm$json$Json$Decode$map4,
				$author$project$Models$StudentActivity$RequiredFieldsForDeterminingProgress,
				A2($elm$json$Json$Decode$field, 'activity', $author$project$Models$Activity$decoder),
				A2(
					$elm$json$Json$Decode$field,
					'watched_video',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool)),
				A2(
					$elm$json$Json$Decode$field,
					'content_completed_at',
					$elm$json$Json$Decode$maybe(
						A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int))),
				A2($elm$json$Json$Decode$field, 'is_remedial', $elm$json$Json$Decode$bool)));
	};
	var studentActivityProgressDecoder = A2(
		$elm$json$Json$Decode$andThen,
		studentActivityProgress,
		A2($elm$json$Json$Decode$field, 'status', $elm$json$Json$Decode$string));
	return A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
		$author$project$Models$StudentActivity$None,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'is_remedial',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'display_sequence',
				A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$int),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'quiz_result',
					$elm$json$Json$Decode$maybe($author$project$Models$AssessmentResult$decoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'activity',
						$author$project$Models$Activity$decoder,
						A2(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
							studentActivityProgressDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'student_quest_id',
								$author$project$Models$Ids$decoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'id',
									$author$project$Models$Ids$decoder,
									$elm$json$Json$Decode$succeed($author$project$Models$StudentActivity$StudentActivity)))))))));
}();
var $author$project$Api$StudentActivityApi$start = F3(
	function (id, loginInfo, appConfig) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$author$project$Models$StudentActivity$decoder,
			$author$project$LoggedIn$Messages$StartStudentActivityResponse,
			A2(
				$author$project$Api$AuthedRequest$put,
				'/api/v5/student_activities/' + ($author$project$Models$StudentActivity$idToString(id) + '/start'),
				A5($author$project$Api$RequestHelper$createConfig, $author$project$LoggedIn$Messages$StartStudentActivityResponse, loginInfo, appConfig, $author$project$Api$AuthedRequest$EmptyBody, $author$project$Models$StudentActivity$decoder)));
	});
var $author$project$Ports$startQRCodeReader = _Platform_outgoingPort(
	'startQRCodeReader',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$VideoPlayer$defaultPlayerTime = {cf: 0.1, dQ: 100.0};
var $author$project$Models$VideoPlayer$playerTime = function (videoPlayState) {
	switch (videoPlayState.$) {
		case 0:
			var maybeTime = videoPlayState.a;
			return A2($elm$core$Maybe$withDefault, $author$project$Models$VideoPlayer$defaultPlayerTime, maybeTime);
		case 1:
			var time = videoPlayState.a;
			return time;
		case 2:
			var time = videoPlayState.a;
			return time;
		case 3:
			var time = videoPlayState.a;
			return time;
		default:
			var time = videoPlayState.a;
			return time;
	}
};
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$Models$YoutubeVideoPlayer$startTime = F2(
	function (videoPlayState, youtubeData) {
		var storedStartTime = $author$project$Models$VideoPlayer$playerTime(videoPlayState).cf;
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			storedStartTime,
			function (clipStart) {
				return A2(
					$elm$core$Maybe$withDefault,
					storedStartTime,
					$elm$core$List$maximum(
						_List_fromArray(
							[clipStart * 1000, storedStartTime])));
			},
			A2($elm$core$Maybe$andThen, $elm$core$String$toFloat, youtubeData.cc));
	});
var $author$project$Ports$stopInteractionSound = _Platform_outgoingPort('stopInteractionSound', $elm$json$Json$Encode$string);
var $author$project$Ports$stopQRCodeReader = _Platform_outgoingPort(
	'stopQRCodeReader',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$storeAppTimer = _Platform_outgoingPort('storeAppTimer', $elm$core$Basics$identity);
var $author$project$Ports$switchToHtmlAudio = _Platform_outgoingPort(
	'switchToHtmlAudio',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Models$Urls$toString = function (_v0) {
	var url = _v0;
	return url;
};
var $author$project$Ports$updateEsparkIframeWithQuestData = _Platform_outgoingPort(
	'updateEsparkIframeWithQuestData',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$core$Basics$identity(b)
				]));
	});
var $author$project$Ports$updateFavicon = _Platform_outgoingPort('updateFavicon', $elm$core$Basics$identity);
var $author$project$LoggedIn$Messages$RecordStudentUsageResponse = function (a) {
	return {$: 89, a: a};
};
var $author$project$Models$LoggingIn$prepopulatedSourceToString = function (source) {
	switch (source.$) {
		case 0:
			return '';
		case 1:
			var unknownSource = source.a;
			return unknownSource;
		default:
			return 'qrcode';
	}
};
var $author$project$Models$LoggingIn$loginMethodToString = function (loginMethod) {
	switch (loginMethod.$) {
		case 0:
			return 'login_method_unknown';
		case 1:
			return 'manual_username_password';
		case 2:
			if (loginMethod.a.$ === 2) {
				var _v1 = loginMethod.a;
				return 'qrcode';
			} else {
				var source = loginMethod.a;
				return 'prepopulated_username_password:' + $author$project$Models$LoggingIn$prepopulatedSourceToString(source);
			}
		case 4:
			return 'iosapp';
		default:
			switch (loginMethod.a.$) {
				case 0:
					var _v2 = loginMethod.a;
					return 'googlesso';
				case 1:
					var _v3 = loginMethod.a;
					return 'cleversso';
				default:
					var unknownSource = loginMethod.a.a;
					return 'autologin:' + unknownSource;
			}
	}
};
var $author$project$Api$StudentQuestApi$addLoginMethodToQueryParams = function (config) {
	var loginInfo = config.hA;
	return _Utils_update(
		config,
		{
			bS: A2(
				$elm$core$List$cons,
				_Utils_Tuple2(
					'login_method',
					$author$project$Models$LoggingIn$loginMethodToString(config.hA.hB)),
				config.bS)
		});
};
var $author$project$Api$StudentQuestApi$updateStudentUsage = F4(
	function (id, elapsedTime, loginInfo, appConfig) {
		var body = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'elapsed_seconds',
					$elm$json$Json$Encode$int(elapsedTime))
				]));
		return A3(
			$author$project$Api$HttpRetry$send,
			$elm$json$Json$Decode$value,
			$author$project$LoggedIn$Messages$RecordStudentUsageResponse,
			A2(
				$author$project$Api$AuthedRequest$put,
				'/api/v5/student_quests/' + ($author$project$Models$Ids$toString(id) + '/update_student_usage'),
				$author$project$Api$StudentQuestApi$addLoginMethodToQueryParams(
					A5(
						$author$project$Api$RequestHelper$createConfig,
						$author$project$LoggedIn$Messages$RecordStudentUsageResponse,
						loginInfo,
						appConfig,
						$author$project$Api$AuthedRequest$Body(body),
						$elm$json$Json$Decode$value))));
	});
var $author$project$Ports$updateWindowTitle = _Platform_outgoingPort('updateWindowTitle', $elm$json$Json$Encode$string);
var $author$project$Ports$uploadStoredStudentVideo = _Platform_outgoingPort(
	'uploadStoredStudentVideo',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$Effect$Resolution$performEffect = F3(
	function (navigation, model, effect) {
		var appConfiguration = model.f7;
		var resolver = $author$project$Effect$Resolution$constructEffectResolver(navigation);
		switch (effect.$) {
			case 0:
				return $elm$core$Platform$Cmd$none;
			case 1:
				var effects = effect.a;
				return $elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$map,
						A2($author$project$Effect$Resolution$performEffect, navigation, model),
						effects));
			case 2:
				var cmd = effect.a;
				return cmd;
			case 3:
				var url = effect.a;
				return resolver.bH(url);
			case 4:
				var url = effect.a;
				return A2(resolver.c4, appConfiguration, url);
			case 5:
				return resolver.bU;
			case 6:
				return $author$project$Helpers$Task$performNow($author$project$Messages$AppHasInitialized);
			case 7:
				var delayType = effect.a;
				return A2($author$project$NewVersionAvailability$check, delayType, appConfiguration);
			case 8:
				return resolver.bV;
			case 9:
				var _v1 = effect.a;
				var username = _v1.a;
				var password = _v1.b;
				return A3($author$project$Api$LoginApi$loginWithPassword, username, password, appConfiguration);
			case 10:
				var joinCode = effect.a.ht;
				var authKey = effect.a.gm;
				return A2(
					$author$project$Api$JoinApi$loginWithJoinCode,
					{gm: authKey, ht: joinCode},
					appConfiguration);
			case 11:
				var joinCode = effect.a;
				var loginInfo = effect.b;
				return A3($author$project$Api$JoinApi$joinNewSection, joinCode, loginInfo, appConfiguration);
			case 12:
				var loginUrl = effect.a;
				return $author$project$Helpers$Task$performNow(
					$author$project$Messages$LoginMessage(
						$author$project$LoginApp$Messages$LoginFromQRCode(loginUrl)));
			case 13:
				var loginInfo = effect.a;
				var mood = effect.b;
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					A3($author$project$Api$StudentMoodApi$record, mood, loginInfo, appConfiguration));
			case 14:
				var studentQuestId = effect.a;
				var videoUploadData = effect.b;
				return $author$project$Ports$uploadStoredStudentVideo(
					_Utils_Tuple2(
						$author$project$Models$Ids$toString(studentQuestId),
						$author$project$Models$VideoUploadData$encodeForWeb(videoUploadData)));
			case 15:
				var studentQuestId = effect.a;
				var loginInfo = effect.b;
				var responseHandlerMsg = effect.c;
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					A4($author$project$Api$StudentVideoUploadApi$fetchUploadUrl, studentQuestId, loginInfo, appConfiguration, responseHandlerMsg));
			case 16:
				var hasFailedPreviousCameraCheck = effect.a;
				var loginInfo = effect.b;
				return A3(
					$author$project$Effect$Resolution$performEffect,
					navigation,
					model,
					A3(
						$krisajenkins$remotedata$RemoteData$unwrap,
						$author$project$Effect$NoEffect,
						function (activeQuest) {
							return $author$project$Effect$batch(
								_List_fromArray(
									[
										A3($author$project$Effect$FetchVideoUploadUrlEffect, activeQuest.a_, loginInfo, $author$project$LoggedIn$Messages$FetchStudentVideoUploadUrlResponse),
										A2(
										$author$project$Effect$PerformLaterEffect,
										4000,
										$author$project$Messages$LoggedInRequiredMsg(
											$author$project$LoggedIn$Messages$EnsureCameraHasBecomeReady(hasFailedPreviousCameraCheck)))
									]));
						},
						model.fZ));
			case 17:
				var studentQuestId = effect.a;
				var loginInfo = effect.b;
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					A4($author$project$Api$StudentVideoUploadApi$markRecorded, studentQuestId, loginInfo, appConfiguration, $author$project$LoggedIn$Messages$CompleteStudentVideoResponse));
			case 18:
				var studentQuestId = effect.a;
				var loginInfo = effect.b;
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					A4($author$project$Api$StudentVideoUploadApi$markErrored, studentQuestId, loginInfo, appConfiguration, $author$project$LoggedIn$Messages$ErroredStudentVideoResponse));
			case 19:
				var studentQuestId = effect.a;
				return $author$project$Ports$removeStoredStudentVideo(
					$author$project$Models$Ids$toString(studentQuestId));
			case 20:
				var studentQuestId = effect.a;
				var loginInfo = effect.b;
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					A4($author$project$Api$StudentVideoUploadApi$markUploaded, studentQuestId, loginInfo, appConfiguration, $author$project$LoggedIn$Messages$StoredStudentVideoUploadedResponse));
			case 21:
				return $author$project$Ports$retrieveStoredStudentVideos(0);
			case 22:
				var elementId = effect.a;
				return $author$project$Ports$replayRecordedStudentVideo(elementId);
			case 23:
				var url = effect.a;
				return $author$project$Ports$openExternalWindow(
					$author$project$Models$Urls$toString(url));
			case 24:
				var url = effect.a;
				var instructions = effect.b;
				return $author$project$Ports$openWebActivity(
					_Utils_Tuple2(
						$author$project$Models$Urls$toString(url),
						instructions));
			case 25:
				var studentActivityId = effect.a;
				var url = effect.b;
				var maximumActivityTime = effect.c;
				var openTime = effect.d;
				return A2(
					$author$project$Helpers$Task$performWithDelay,
					maximumActivityTime,
					A3($author$project$Messages$EvaluateWebAppForAutoClose, studentActivityId, url, openTime));
			case 26:
				var studentActivityId = effect.a;
				var timeToSound = effect.b;
				var openTime = effect.c;
				return A2(
					$author$project$Helpers$Task$performWithDelay,
					timeToSound,
					A2($author$project$Messages$EvaluateWebAppReturnPrompts, studentActivityId, openTime));
			case 27:
				var url = effect.a;
				return $author$project$Helpers$Task$performNow(
					$author$project$Messages$CloseExternalWindowByTimer(url));
			case 28:
				var url = effect.a;
				return $author$project$Ports$closeExternalWindow(
					$author$project$Models$Urls$toString(url));
			case 29:
				var studentActivityId = effect.a;
				return $author$project$Ports$listenForWebActivityWindowMessage(
					$author$project$Models$Ids$toString(studentActivityId));
			case 30:
				var sound = effect.a;
				return $author$project$Ports$playInteractionSound(
					_Utils_Tuple2(
						$author$project$InteractionSound$src(sound),
						model.ga.jA.jm));
			case 31:
				var sound = effect.a;
				return $author$project$Ports$stopInteractionSound(
					$author$project$InteractionSound$src(sound));
			case 32:
				var videoKey = effect.a;
				return $author$project$Helpers$Task$performNow(
					$author$project$Messages$PlayVideo(videoKey));
			case 33:
				return $author$project$Helpers$Task$performNow($author$project$Messages$PauseVideo);
			case 34:
				var studentActivityId = effect.a;
				var youtubeData = effect.b;
				return $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							$author$project$Ports$connectToEmbeddedYoutubePlayer(
							{
								f$: $author$project$Models$StudentActivity$activityKey(studentActivityId),
								i3: A2($author$project$Models$YoutubeVideoPlayer$startTime, model.jI, youtubeData),
								G: $author$project$Models$Ids$toString(studentActivityId)
							}),
							A2(
							$author$project$Helpers$Task$performWithDelay,
							5000,
							$author$project$Messages$CheckIfYoutubeVideoIsPlaying(studentActivityId))
						]));
			case 35:
				return $author$project$Helpers$Task$performNow($author$project$Messages$PlayEmbeddedYoutubePlayer);
			case 36:
				return $author$project$Helpers$Task$performNow($author$project$Messages$PauseEmbeddedYoutubePlayer);
			case 37:
				var msg = effect.a;
				return $author$project$Helpers$Task$getCurrentTime(msg);
			case 38:
				var faviconAnimation = effect.a;
				return $author$project$Ports$updateFavicon(
					$author$project$Assets$Favicon$animationEncoder(faviconAnimation));
			case 39:
				var title = effect.a;
				return $author$project$Ports$updateWindowTitle(title);
			case 45:
				var delay = effect.a;
				var msg = effect.b;
				return A2($author$project$Helpers$Task$performWithDelay, delay, msg);
			case 46:
				var msg = effect.a;
				return $author$project$Helpers$Task$performNow(msg);
			case 40:
				return $author$project$Ports$startQRCodeReader(0);
			case 41:
				return $author$project$Ports$stopQRCodeReader(0);
			case 60:
				var loginInfo = effect.a;
				var _v2 = effect.b;
				var studentQuestId = _v2.a;
				var elapsedTime = _v2.b;
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					A4($author$project$Api$StudentQuestApi$updateStudentUsage, studentQuestId, elapsedTime, loginInfo, appConfiguration));
			case 42:
				return $author$project$Ports$resetTTS(0);
			case 43:
				return $author$project$Ports$switchToHtmlAudio(0);
			case 44:
				var studentActivityId = effect.a;
				return $author$project$Ports$logGameActivityBrowserHealth(
					$author$project$Models$Ids$toString(studentActivityId));
			case 47:
				var studentActivityId = effect.a;
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					$author$project$Helpers$Task$performNow(
						$author$project$LoggedIn$Messages$SetCanCompleteProgress(studentActivityId)));
			case 48:
				var studentActivityId = effect.a;
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					$author$project$Helpers$Task$performNow(
						$author$project$LoggedIn$Messages$FlashTimerAlert(studentActivityId)));
			case 49:
				return $author$project$Ports$hideNotification(0);
			case 50:
				var studentActivityId = effect.a;
				var returnNotificationDelay = effect.b;
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					A2(
						$author$project$Helpers$Task$performWithDelay,
						returnNotificationDelay,
						$author$project$LoggedIn$Messages$SendReturnNotification(studentActivityId)));
			case 51:
				var studentActivityId = effect.a;
				var loginInfo = effect.b;
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					A3($author$project$Api$StudentActivityApi$start, studentActivityId, loginInfo, appConfiguration));
			case 55:
				var studentActivityId = effect.a;
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					$author$project$Helpers$Task$performNow(
						$author$project$LoggedIn$Messages$StudentActivityTimerComplete(studentActivityId)));
			case 52:
				var appTimerString = effect.a;
				return $author$project$Ports$storeAppTimer(appTimerString);
			case 56:
				var origin = effect.a;
				var jsonData = effect.b;
				return $author$project$Ports$updateEsparkIframeWithQuestData(
					_Utils_Tuple2(origin, jsonData));
			case 57:
				var iframeId = effect.a;
				var origin = effect.b;
				var accessToken = effect.c.fX;
				var sessionId = effect.d;
				return $author$project$Ports$provideAuthInfoToPLE(
					_Utils_Tuple3(
						iframeId,
						origin,
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'authToken',
									$elm$json$Json$Encode$string(accessToken)),
									_Utils_Tuple2(
									'sessionId',
									$elm$json$Json$Encode$string(sessionId))
								]))));
			case 53:
				var appTimer = effect.a;
				var delay = effect.b;
				return $author$project$Helpers$Task$performNow(
					$author$project$Messages$LoggedInRequiredMsg(
						A2($author$project$LoggedIn$Messages$AppTimerCallbackSetup, appTimer, delay)));
			case 54:
				var appTimer = effect.a;
				return $author$project$Helpers$Task$getCurrentTime(
					A2(
						$elm$core$Basics$composeR,
						$author$project$LoggedIn$Messages$AppTimerInitialize(appTimer),
						$author$project$Messages$LoggedInRequiredMsg));
			case 61:
				var data = effect.a;
				return A2($author$project$Logger$log, model, data);
			case 62:
				var message = effect.a;
				var dataString = effect.b;
				return A2(
					$author$project$Logger$log,
					model,
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'error',
								$elm$json$Json$Encode$string(dataString))
							]),
						gP: _List_fromArray(
							[1]),
						hK: message,
						jp: _List_Nil
					});
			case 63:
				var event = effect.a;
				var timeStamp = effect.b;
				var uuid = effect.c;
				return A4($author$project$EventPublisher$publish, event, model, timeStamp, uuid);
			case 64:
				return A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					$elm$core$Platform$Cmd$none,
					$author$project$Ports$setHeartbeatData,
					$author$project$Heartbeat$encodeHeartbeatDataFromModel(model));
			case 58:
				var loginInfo = effect.a;
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					A2($author$project$Api$StudentCustomizerApi$record, loginInfo, appConfiguration));
			default:
				var iframeId = effect.a;
				var origin = effect.b;
				var loginInfo = effect.c;
				var sessionId = effect.d;
				return $author$project$Ports$provideAuthInfoToChoiceText(
					{gn: loginInfo.fX, hk: iframeId, id: origin, i$: sessionId});
		}
	});
var $author$project$Effect$Resolution$convertToCmd = F2(
	function (navigation, _v0) {
		var model = _v0.a;
		var effect = _v0.b;
		return _Utils_Tuple2(
			model,
			A3($author$project$Effect$Resolution$performEffect, navigation, model, effect));
	});
var $author$project$Messages$CheckIfStudentIsStillUnknown = {$: 20};
var $author$project$Models$LocalStorage$LocalStorage = F4(
	function (studentQuestHistory, videoPlayerTime, studentChoices, videoRecordingCounts) {
		return {c$: studentChoices, c0: studentQuestHistory, c8: videoPlayerTime, c9: videoRecordingCounts};
	});
var $author$project$Models$LocalStorage$StudentChoices$StudentChoices = function (character) {
	return {v: character};
};
var $author$project$Models$LocalStorage$StudentChoices$Alien = 0;
var $author$project$Models$LocalStorage$StudentChoices$AlienNew = 8;
var $author$project$Models$LocalStorage$StudentChoices$Axolotl = 9;
var $author$project$Models$LocalStorage$StudentChoices$Dog = 10;
var $author$project$Models$LocalStorage$StudentChoices$Dragon = 11;
var $author$project$Models$LocalStorage$StudentChoices$Fox = 12;
var $author$project$Models$LocalStorage$StudentChoices$Lion = 13;
var $author$project$Models$LocalStorage$StudentChoices$Panda = 14;
var $author$project$Models$LocalStorage$StudentChoices$Pogostick = 1;
var $author$project$Models$LocalStorage$StudentChoices$Robodog = 15;
var $author$project$Models$LocalStorage$StudentChoices$Rocky = 2;
var $author$project$Models$LocalStorage$StudentChoices$Rollerskate = 3;
var $author$project$Models$LocalStorage$StudentChoices$Scooter = 4;
var $author$project$Models$LocalStorage$StudentChoices$Skateboard = 5;
var $author$project$Models$LocalStorage$StudentChoices$Springshoes = 6;
var $author$project$Models$LocalStorage$StudentChoices$Unicorn = 7;
var $author$project$Models$LocalStorage$StudentChoices$characterDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'alien':
				return $elm$json$Json$Decode$succeed(0);
			case 'pogostick':
				return $elm$json$Json$Decode$succeed(1);
			case 'rocky':
				return $elm$json$Json$Decode$succeed(2);
			case 'rollerskate':
				return $elm$json$Json$Decode$succeed(3);
			case 'scooter':
				return $elm$json$Json$Decode$succeed(4);
			case 'skateboard':
				return $elm$json$Json$Decode$succeed(5);
			case 'springshoes':
				return $elm$json$Json$Decode$succeed(6);
			case 'unicorn':
				return $elm$json$Json$Decode$succeed(7);
			case 'alienNew':
				return $elm$json$Json$Decode$succeed(8);
			case 'axolotl':
				return $elm$json$Json$Decode$succeed(9);
			case 'dog':
				return $elm$json$Json$Decode$succeed(10);
			case 'dragon':
				return $elm$json$Json$Decode$succeed(11);
			case 'fox':
				return $elm$json$Json$Decode$succeed(12);
			case 'lion':
				return $elm$json$Json$Decode$succeed(13);
			case 'panda':
				return $elm$json$Json$Decode$succeed(14);
			case 'robodog':
				return $elm$json$Json$Decode$succeed(15);
			default:
				return $elm$json$Json$Decode$fail('cannot parse' + str);
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $author$project$Helpers$DecodingHelper$decodeTuple = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	return A3(
		$elm$json$Json$Decode$map2,
		F2(
			function (aVal, bVal) {
				return _Utils_Tuple2(aVal, bVal);
			}),
		A2($elm$json$Json$Decode$index, 0, a),
		A2($elm$json$Json$Decode$index, 1, b));
};
var $author$project$Models$LocalStorage$TimeTuple$decoder = function (valueDecoder) {
	return $author$project$Helpers$DecodingHelper$decodeTuple(
		_Utils_Tuple2(
			A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
			valueDecoder));
};
var $author$project$Models$LocalStorage$StudentChoices$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'character',
	$elm$json$Json$Decode$maybe(
		$author$project$Models$LocalStorage$TimeTuple$decoder($author$project$Models$LocalStorage$StudentChoices$characterDecoder)),
	$elm$core$Maybe$Nothing,
	$elm$json$Json$Decode$succeed($author$project$Models$LocalStorage$StudentChoices$StudentChoices));
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$Models$LocalStorage$StudentQuestHistory$Entry = function (mostRecentActivityId) {
	return {eC: mostRecentActivityId};
};
var $author$project$Models$LocalStorage$StudentQuestHistory$entryDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Models$LocalStorage$StudentQuestHistory$Entry,
	A2($elm$json$Json$Decode$field, 'mostRecentActivityId', $author$project$Models$Ids$decoder));
var $author$project$Models$LocalStorage$StudentQuestHistory$decoder = $elm$json$Json$Decode$dict($author$project$Models$LocalStorage$StudentQuestHistory$entryDecoder);
var $author$project$Models$LocalStorage$VideoPlayerTime$Entry = F2(
	function (currentTime, duration) {
		return {cf: currentTime, dQ: duration};
	});
var $author$project$Models$LocalStorage$VideoPlayerTime$entryDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'duration',
	$elm$json$Json$Decode$float,
	$author$project$Models$VideoPlayer$defaultPlayerTime.dQ,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'currentTime',
		$elm$json$Json$Decode$float,
		$author$project$Models$VideoPlayer$defaultPlayerTime.cf,
		$elm$json$Json$Decode$succeed($author$project$Models$LocalStorage$VideoPlayerTime$Entry)));
var $author$project$Models$LocalStorage$VideoPlayerTime$decoder = $elm$json$Json$Decode$dict($author$project$Models$LocalStorage$VideoPlayerTime$entryDecoder);
var $author$project$Models$LocalStorage$VideoRecordingCounts$default = $elm$core$Dict$empty;
var $author$project$VideoRecording$RecordingProcess$RecordingCounts = F3(
	function (attemptCount, deletedCount, permissionRetryCount) {
		return {gi: attemptCount, gO: deletedCount, cN: permissionRetryCount};
	});
var $author$project$Models$LocalStorage$VideoRecordingCounts$entryDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'permissionRetryCount',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'deletedCount',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'attemptCount',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$VideoRecording$RecordingProcess$RecordingCounts))));
var $author$project$Models$LocalStorage$VideoRecordingCounts$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$dict($author$project$Models$LocalStorage$VideoRecordingCounts$entryDecoder),
			$elm$json$Json$Decode$succeed($author$project$Models$LocalStorage$VideoRecordingCounts$default)
		]));
var $author$project$Models$LocalStorage$StudentChoices$default = {v: $elm$core$Maybe$Nothing};
var $author$project$Models$LocalStorage$StudentQuestHistory$default = $elm$core$Dict$empty;
var $author$project$Models$LocalStorage$VideoPlayerTime$default = $elm$core$Dict$empty;
var $author$project$Models$LocalStorage$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'v5-video-recording-counts',
	$author$project$Models$LocalStorage$VideoRecordingCounts$decoder,
	$author$project$Models$LocalStorage$VideoRecordingCounts$default,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'v5-student-choices',
		$author$project$Models$LocalStorage$StudentChoices$decoder,
		$author$project$Models$LocalStorage$StudentChoices$default,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'v5-video-player-time-key',
			$author$project$Models$LocalStorage$VideoPlayerTime$decoder,
			$author$project$Models$LocalStorage$VideoPlayerTime$default,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'v5-student-quest-history',
				$author$project$Models$LocalStorage$StudentQuestHistory$decoder,
				$author$project$Models$LocalStorage$StudentQuestHistory$default,
				$elm$json$Json$Decode$succeed($author$project$Models$LocalStorage$LocalStorage)))));
var $author$project$Models$LocalStorage$default = {c$: $author$project$Models$LocalStorage$StudentChoices$default, c0: $author$project$Models$LocalStorage$StudentQuestHistory$default, c8: $author$project$Models$LocalStorage$VideoPlayerTime$default, c9: $author$project$Models$LocalStorage$VideoRecordingCounts$default};
var $author$project$Ports$disableReturnNotification = _Platform_outgoingPort(
	'disableReturnNotification',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Effect$CmdEffect = function (a) {
	return {$: 2, a: a};
};
var $author$project$Effect$PerformNowEffect = function (a) {
	return {$: 46, a: a};
};
var $author$project$Routing$Updates$autoplayPollyAudio = F2(
	function (_v0, _v1) {
		var model = _v1.a;
		var existingEffects = _v1.b;
		return _Utils_Tuple2(
			model,
			$author$project$Effect$batch(
				_List_fromArray(
					[
						existingEffects,
						$author$project$Effect$PerformNowEffect(
						$author$project$Messages$PlayAllAutoplaySpeech($elm$core$Maybe$Nothing))
					])));
	});
var $author$project$Routes$LoginRoute = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$LoginRoutes$QRCodeReader = {$: 3};
var $author$project$Ports$requestMediaCapturePermissions = _Platform_outgoingPort(
	'requestMediaCapturePermissions',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Routing$Updates$checkQrCodePermissionsIfAppropriate = F2(
	function (newRoute, _v0) {
		var model = _v0.a;
		var existingEffects = _v0.b;
		return _Utils_eq(
			newRoute,
			$author$project$Routes$LoginRoute($author$project$Routes$LoginRoutes$QRCodeReader)) ? _Utils_Tuple2(
			model,
			$author$project$Effect$CmdEffect(
				$author$project$Ports$requestMediaCapturePermissions(0))) : _Utils_Tuple2(model, existingEffects);
	});
var $author$project$Messages$NoOp = {$: 0};
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$Routing$Updates$focusOnLoginView = F2(
	function (newRoute, _v0) {
		var model = _v0.a;
		var existingEffects = _v0.b;
		if ((newRoute.$ === 1) && (!newRoute.a.$)) {
			return _Utils_Tuple2(
				model,
				$author$project$Effect$batch(
					_List_fromArray(
						[
							$author$project$Effect$CmdEffect(
							A2(
								$elm$core$Task$attempt,
								function (_v2) {
									return $author$project$Messages$NoOp;
								},
								$elm$browser$Browser$Dom$focus('username'))),
							existingEffects
						])));
		} else {
			return _Utils_Tuple2(model, existingEffects);
		}
	});
var $author$project$Models$LoggingIn$NoFlag = 0;
var $author$project$Routes$NotFoundRoute = {$: 3};
var $author$project$Routes$LoginRoutes$PasswordLoginRoute = function (a) {
	return {$: 0, a: a};
};
var $author$project$Effect$UpdateUrlEffect = function (a) {
	return {$: 4, a: a};
};
var $author$project$Routing$AccessControl$RouteAllowed = 0;
var $author$project$Routing$AccessControl$RouteDeferredDecision = 3;
var $author$project$Routing$AccessControl$RouteRequiresLogin = 1;
var $author$project$Routing$AccessControl$RouteRequiresLogout = 2;
var $author$project$Routing$AccessControl$allowedToAccessRoute = F2(
	function (route, user) {
		switch (user.$) {
			case 0:
				return 3;
			case 1:
				switch (route.$) {
					case 1:
						return 0;
					case 11:
						return 0;
					case 12:
						return 0;
					default:
						return 1;
				}
			default:
				if (route.$ === 1) {
					var loginRoute = route.a;
					if (loginRoute.$ === 1) {
						return 0;
					} else {
						return 2;
					}
				} else {
					return 0;
				}
		}
	});
var $author$project$Routes$HomeRoute = function (a) {
	return {$: 0, a: a};
};
var $author$project$Views$SubjectChoiceView$Model$init = {fb: false, fc: false};
var $author$project$Routes$homeRoute = $author$project$Routes$HomeRoute($author$project$Views$SubjectChoiceView$Model$init);
var $author$project$Routes$ActivityRoute = function (a) {
	return {$: 8, a: a};
};
var $author$project$Routes$ClassLibraryRoute = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Routes$ComponentPreviewRoute = function (a) {
	return {$: 6, a: a};
};
var $author$project$Routes$JoinCodeRedirectRoute = function (a) {
	return {$: 12, a: a};
};
var $author$project$Routes$JoinCodeRoute = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$Routes$LogoutRoute = {$: 2};
var $author$project$Routes$QuestRoute = function (a) {
	return {$: 7, a: a};
};
var $author$project$Routes$QuizQuestionPreviewRoute = function (a) {
	return {$: 5, a: a};
};
var $author$project$Routes$QuizRoute = function (a) {
	return {$: 10, a: a};
};
var $author$project$Routes$ResumeRoute = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Routes$VideoRoute = function (a) {
	return {$: 9, a: a};
};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {av: frag, aC: params, aq: unvisited, b3: value, aG: visited};
	});
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.aG;
			var unvisited = _v0.aq;
			var params = _v0.aC;
			var frag = _v0.av;
			var value = _v0.b3;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $author$project$Routes$ActivityRoutes$customComponentType = A2($elm$url$Url$Parser$custom, 'ComponentType', $author$project$Models$Activity$componentTypeFromString);
var $author$project$Routes$decodeSegmentToTime = A2(
	$elm$url$Url$Parser$custom,
	'RESUME_TIMESTAMP',
	function (segment) {
		return A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$toFloat,
			$elm$core$String$toInt(segment));
	});
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $author$project$JoinCode$Model$init = {gm: $elm$core$Maybe$Nothing, ht: '', hB: $elm$core$Maybe$Nothing, cZ: $krisajenkins$remotedata$RemoteData$NotAsked};
var $author$project$JoinCode$Model$initForNewStudent = F2(
	function (authKey, loginMethod) {
		return _Utils_update(
			$author$project$JoinCode$Model$init,
			{
				gm: $elm$core$Maybe$Just(authKey),
				hB: $elm$core$Maybe$Just(loginMethod)
			});
	});
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.aG;
		var unvisited = _v0.aq;
		var params = _v0.aC;
		var frag = _v0.av;
		var value = _v0.b3;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.aG;
			var unvisited = _v1.aq;
			var params = _v1.aC;
			var frag = _v1.av;
			var value = _v1.b3;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$map = F2(
	function (func, _v0) {
		var a = _v0;
		return function (dict) {
			return func(
				a(dict));
		};
	});
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $elm$url$Url$Parser$Query$map2 = F3(
	function (func, _v0, _v1) {
		var a = _v0;
		var b = _v1;
		return function (dict) {
			return A2(
				func,
				a(dict),
				b(dict));
		};
	});
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $author$project$Models$Ids$parseUrl = A2($elm$url$Url$Parser$map, $author$project$Models$Ids$fromString, $elm$url$Url$Parser$string);
var $author$project$Routes$ActivityRoutes$Assessment = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Routes$ActivityRoutes$Component = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$Routes$ActivityRoutes$Intro = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Routes$ActivityRoutes$Practice = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Learnosity$Model$Available = 0;
var $author$project$Learnosity$Model$Model = $elm$core$Basics$identity;
var $author$project$Learnosity$Model$init = 0;
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.aG;
		var unvisited = _v0.aq;
		var params = _v0.aC;
		var frag = _v0.av;
		var value = _v0.b3;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $author$project$Routes$ActivityRoutes$parsers = _List_fromArray(
	[
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$ActivityRoutes$Practice,
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('activities'),
						A2(
							$elm$url$Url$Parser$slash,
							$author$project$Models$Ids$parseUrl,
							$elm$url$Url$Parser$s('practice'))))))),
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$ActivityRoutes$Assessment($author$project$Learnosity$Model$init),
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('activities'),
						A2(
							$elm$url$Url$Parser$slash,
							$author$project$Models$Ids$parseUrl,
							$elm$url$Url$Parser$s('assessment'))))))),
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$ActivityRoutes$Intro,
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('activities'),
						A2(
							$elm$url$Url$Parser$slash,
							$author$project$Models$Ids$parseUrl,
							$elm$url$Url$Parser$s('intro'))))))),
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$ActivityRoutes$Component,
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('activities'),
						A2($elm$url$Url$Parser$slash, $author$project$Models$Ids$parseUrl, $author$project$Routes$ActivityRoutes$customComponentType)))))),
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$ActivityRoutes$Assessment($author$project$Learnosity$Model$init),
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('activities'),
						A2(
							$elm$url$Url$Parser$slash,
							$author$project$Models$Ids$parseUrl,
							$elm$url$Url$Parser$s('quiz')))))))
	]);
var $author$project$Routes$LoginRoutes$AutoLoginRoute = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $author$project$Models$LoggingIn$AutoLoginToken = $elm$core$Basics$identity;
var $author$project$Models$LoggingIn$CleverSSOError = 2;
var $author$project$Models$LoggingIn$GoogleSSOError = 1;
var $author$project$Routes$LoginRoutes$PasswordLoginRoutePrepopulated = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$LoggingIn$autoLoginSourceFromString = function (source) {
	switch (source) {
		case 'google':
			return $author$project$Models$LoggingIn$AutoLoginSourceGoogleSSO;
		case 'clever':
			return $author$project$Models$LoggingIn$AutoLoginSourceCleverSSO;
		default:
			var unknownSource = source;
			return $author$project$Models$LoggingIn$AutoLoginSourceUnknown(unknownSource);
	}
};
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $author$project$Routes$LoginRoutes$queryStringWithDefaultParser = F2(
	function (defaultValue, key) {
		return A2(
			$elm$url$Url$Parser$Query$map,
			$elm$core$Maybe$withDefault(defaultValue),
			$elm$url$Url$Parser$Query$string(key));
	});
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.aG;
		var unvisited = _v1.aq;
		var params = _v1.aC;
		var frag = _v1.av;
		var value = _v1.b3;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $author$project$Routes$LoginRoutes$parsers = function () {
	var parseFlagString = function (flagString) {
		return (flagString === 'google_sso_error') ? 1 : ((flagString === 'clever_sso_error') ? 2 : 0);
	};
	var loginRouteParser = A2(
		$elm$url$Url$Parser$map,
		F4(
			function (maybeHsn, maybeHafu, maybeFlag, source) {
				var decodedHsn = A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$core$Maybe$Nothing, $elm$url$Url$percentDecode, maybeHsn);
				var decodedHafu = A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$core$Maybe$Nothing, $elm$url$Url$percentDecode, maybeHafu);
				var _v0 = _Utils_Tuple2(decodedHsn, decodedHafu);
				if ((!_v0.a.$) && (!_v0.b.$)) {
					var hsn = _v0.a.a;
					var hafu = _v0.b.a;
					return $author$project$Routes$LoginRoutes$PasswordLoginRoutePrepopulated(
						{
							im: hafu,
							$9: hsn,
							i1: $author$project$Models$LoggingIn$prepopulatedSourceFromString(source)
						});
				} else {
					return $author$project$Routes$LoginRoutes$PasswordLoginRoute(
						A3($elm_community$maybe_extra$Maybe$Extra$unwrap, 0, parseFlagString, maybeFlag));
				}
			}),
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$questionMark,
				A2(
					$elm$url$Url$Parser$questionMark,
					A2(
						$elm$url$Url$Parser$questionMark,
						A2(
							$elm$url$Url$Parser$questionMark,
							$elm$url$Url$Parser$s('login'),
							$elm$url$Url$Parser$Query$string('hsn')),
						$elm$url$Url$Parser$Query$string('hafu')),
					$elm$url$Url$Parser$Query$string('flag')),
				A2($author$project$Routes$LoginRoutes$queryStringWithDefaultParser, '', 'source'))));
	return _List_fromArray(
		[
			loginRouteParser,
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$LoginRoutes$QRCodeReader,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('student'),
				$elm$url$Url$Parser$s('qr'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$LoginRoutes$AutoLoginRoute,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('student'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('autologin'),
						A2(
							$elm$url$Url$Parser$questionMark,
							A2(
								$elm$url$Url$Parser$questionMark,
								A2($elm$url$Url$Parser$map, $elm$core$Basics$identity, $elm$url$Url$Parser$string),
								$elm$url$Url$Parser$Query$string('destination')),
							A2(
								$elm$url$Url$Parser$Query$map,
								$author$project$Models$LoggingIn$autoLoginSourceFromString,
								A2($author$project$Routes$LoginRoutes$queryStringWithDefaultParser, '', 'source')))))))
		]);
}();
var $author$project$Routes$QuestRoutes$Home = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$QuestRoutes$Intro = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$QuestRoutes$Outro = function (a) {
	return {$: 2, a: a};
};
var $author$project$Routes$QuestRoutes$parsers = _List_fromArray(
	[
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$QuestRoutes$Intro,
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					$elm$url$Url$Parser$s('intro'))))),
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$QuestRoutes$Outro,
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					$elm$url$Url$Parser$s('outro'))))),
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$QuestRoutes$Home,
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				$author$project$Models$Ids$parseUrl)))
	]);
var $author$project$Routes$QuizRoutes$Assessment = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Routes$QuizRoutes$Intro = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Routes$QuizRoutes$Outro = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Routes$QuizRoutes$parsers = _List_fromArray(
	[
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$QuizRoutes$Intro,
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('quizzes'),
						A2(
							$elm$url$Url$Parser$slash,
							$author$project$Models$Ids$parseUrl,
							$elm$url$Url$Parser$s('intro'))))))),
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$QuizRoutes$Assessment($author$project$Learnosity$Model$init),
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('quizzes'),
						A2(
							$elm$url$Url$Parser$slash,
							$author$project$Models$Ids$parseUrl,
							$elm$url$Url$Parser$s('assessment'))))))),
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$QuizRoutes$Outro,
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('quizzes'),
						A2(
							$elm$url$Url$Parser$slash,
							$author$project$Models$Ids$parseUrl,
							$elm$url$Url$Parser$s('outro'))))))),
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$QuizRoutes$Assessment($author$project$Learnosity$Model$init),
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('quizzes'),
						A2(
							$elm$url$Url$Parser$slash,
							$author$project$Models$Ids$parseUrl,
							$elm$url$Url$Parser$s('practice')))))))
	]);
var $author$project$Routes$VideoRoutes$Instructions = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$VideoRoutes$Recording = function (a) {
	return {$: 2, a: a};
};
var $author$project$Routes$VideoRoutes$RecordingIntro = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$VideoRoutes$parsers = _List_fromArray(
	[
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$VideoRoutes$RecordingIntro,
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('video'),
						$elm$url$Url$Parser$s('intro')))))),
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$VideoRoutes$Instructions,
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('video'),
						$elm$url$Url$Parser$s('instructions')))))),
		A2(
		$elm$url$Url$Parser$map,
		$author$project$Routes$VideoRoutes$Recording,
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quests'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Models$Ids$parseUrl,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('video'),
						$elm$url$Url$Parser$s('recording'))))))
	]);
var $author$project$Routes$ByChallengeId = function (a) {
	return {$: 2, a: a};
};
var $author$project$Routes$ByItemId = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$ByQuestId = function (a) {
	return {$: 0, a: a};
};
var $author$project$Learnosity$Model$Id = $elm$core$Basics$identity;
var $elm$url$Url$Parser$int = A2($elm$url$Url$Parser$custom, 'NUMBER', $elm$core$String$toInt);
var $author$project$Routes$quizQuestionPreviewRouteParsers = _List_fromArray(
	[
		A2(
		$elm$url$Url$Parser$map,
		A2(
			$elm$core$Basics$composeL,
			A2($elm$core$Basics$composeL, $author$project$Routes$ByQuestId, $author$project$Models$Ids$fromString),
			$elm$core$String$fromInt),
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quiz-question-preview'),
				$elm$url$Url$Parser$int))),
		A2(
		$elm$url$Url$Parser$map,
		A2($elm$core$Basics$composeL, $author$project$Routes$ByChallengeId, $elm$core$Basics$identity),
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quiz-question-preview'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					$elm$url$Url$Parser$s('challenge'))))),
		A2(
		$elm$url$Url$Parser$map,
		A2($elm$core$Basics$composeL, $author$project$Routes$ByItemId, $elm$core$Basics$identity),
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('student'),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('quiz-question-preview'),
				$elm$url$Url$Parser$string)))
	]);
var $author$project$Routes$matchers = function () {
	var nestedRouteParsers = F2(
		function (route, routeParsers) {
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$map(route),
				routeParsers);
		});
	var joinCodeQueryParser = A3(
		$elm$url$Url$Parser$Query$map2,
		F2(
			function (authKey, loginMethod) {
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$JoinCode$Model$init,
					A3($elm$core$Maybe$map2, $author$project$JoinCode$Model$initForNewStudent, authKey, loginMethod));
			}),
		$elm$url$Url$Parser$Query$string('key'),
		A2(
			$elm$url$Url$Parser$Query$map,
			$elm$core$Maybe$map($author$project$Models$LoggingIn$loginMethodFromString),
			$elm$url$Url$Parser$Query$string('source')));
	return $elm$url$Url$Parser$oneOf(
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$url$Url$Parser$map,
					$author$project$Routes$homeRoute,
					$elm$url$Url$Parser$s('student')),
					A2(
					$elm$url$Url$Parser$map,
					$author$project$Routes$LogoutRoute,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('student'),
						$elm$url$Url$Parser$s('logout'))),
					A2(
					$elm$url$Url$Parser$map,
					$author$project$Routes$ResumeRoute,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('student'),
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s('resume'),
							A2($elm$url$Url$Parser$slash, $author$project$Routes$decodeSegmentToTime, $elm$url$Url$Parser$string)))),
					A2(
					$elm$url$Url$Parser$map,
					$author$project$Routes$JoinCodeRoute,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('student'),
						A2(
							$elm$url$Url$Parser$questionMark,
							A2(
								$elm$url$Url$Parser$questionMark,
								$elm$url$Url$Parser$s('join'),
								$elm$url$Url$Parser$Query$string('back')),
							joinCodeQueryParser))),
					A2(
					$elm$url$Url$Parser$map,
					$author$project$Routes$JoinCodeRedirectRoute,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('student'),
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s('join'),
							$elm$url$Url$Parser$string))),
					A2(
					$elm$url$Url$Parser$map,
					$author$project$Routes$ClassLibraryRoute($elm$core$Maybe$Nothing),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('student'),
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s('library'),
							$author$project$Models$Ids$parseUrl))),
					A2(
					$elm$url$Url$Parser$map,
					$author$project$Routes$ComponentPreviewRoute,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('student'),
						$author$project$Routes$ActivityRoutes$customComponentType))
				]),
			_Utils_ap(
				A2(nestedRouteParsers, $author$project$Routes$QuizQuestionPreviewRoute, $author$project$Routes$quizQuestionPreviewRouteParsers),
				_Utils_ap(
					A2(nestedRouteParsers, $author$project$Routes$QuestRoute, $author$project$Routes$QuestRoutes$parsers),
					_Utils_ap(
						A2(nestedRouteParsers, $author$project$Routes$ActivityRoute, $author$project$Routes$ActivityRoutes$parsers),
						_Utils_ap(
							A2(nestedRouteParsers, $author$project$Routes$VideoRoute, $author$project$Routes$VideoRoutes$parsers),
							_Utils_ap(
								A2(nestedRouteParsers, $author$project$Routes$QuizRoute, $author$project$Routes$QuizRoutes$parsers),
								A2(nestedRouteParsers, $author$project$Routes$LoginRoute, $author$project$Routes$LoginRoutes$parsers))))))));
}();
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.aq;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.b3);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.b3);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.ig),
					$elm$url$Url$Parser$prepareQuery(url.is),
					url.d_,
					$elm$core$Basics$identity)));
	});
var $author$project$Routes$parseUrl = function (url) {
	var _v0 = A2($elm$url$Url$Parser$parse, $author$project$Routes$matchers, url);
	if (!_v0.$) {
		var route = _v0.a;
		return route;
	} else {
		return $author$project$Routes$NotFoundRoute;
	}
};
var $author$project$Models$Activity$componentTypeToString = function (componentType) {
	if (!componentType) {
		return 'evaluate';
	} else {
		return 'evaluate_preview';
	}
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Models$LoggingIn$autoLoginSourceToString = function (source) {
	switch (source.$) {
		case 1:
			return 'clever';
		case 0:
			return 'google';
		default:
			var unknownSource = source.a;
			return unknownSource;
	}
};
var $author$project$Routes$LoginRoutes$routeToUrlString = function (loginRoute) {
	switch (loginRoute.$) {
		case 0:
			var loginFlag = loginRoute.a;
			switch (loginFlag) {
				case 0:
					return '/student/login';
				case 1:
					return '/student/login?flag=google_sso_error';
				default:
					return '/student/login?flag=clever_sso_error';
			}
		case 1:
			return '/student/login';
		case 2:
			var userId = loginRoute.a;
			var token = loginRoute.b;
			var redirectUrl = loginRoute.c;
			var source = loginRoute.d;
			return '/student/' + ($author$project$Models$Ids$toString(userId) + ('/autologin/' + (token + ('?destination=' + (A2($elm$core$Maybe$withDefault, '', redirectUrl) + ('&source=' + $author$project$Models$LoggingIn$autoLoginSourceToString(source)))))));
		default:
			return '/student/qr';
	}
};
var $author$project$Routes$QuestRoutes$stem = function (aStudentQuestId) {
	return '/student/quests/' + $author$project$Models$Ids$toString(aStudentQuestId);
};
var $author$project$Routes$ActivityRoutes$toUrlString = F2(
	function (base, route) {
		var stem = function (aStudentActivityId) {
			return base + ('/activities/' + $author$project$Models$Ids$toString(aStudentActivityId));
		};
		switch (route.$) {
			case 0:
				var aStudentActivityId = route.b;
				return stem(aStudentActivityId) + '/intro';
			case 1:
				var aStudentActivityId = route.b;
				return stem(aStudentActivityId) + '/practice';
			case 2:
				var aStudentActivityId = route.c;
				return stem(aStudentActivityId) + '/assessment';
			default:
				var aStudentActivityId = route.b;
				var componentType = route.c;
				return stem(aStudentActivityId) + ('/' + $author$project$Models$Activity$componentTypeToString(componentType));
		}
	});
var $author$project$Routes$QuestRoutes$toUrlString = function (route) {
	switch (route.$) {
		case 0:
			var aStudentQuestId = route.a;
			return $author$project$Routes$QuestRoutes$stem(aStudentQuestId);
		case 1:
			var aStudentQuestId = route.a;
			return $author$project$Routes$QuestRoutes$stem(aStudentQuestId) + '/intro';
		default:
			var aStudentQuestId = route.a;
			return $author$project$Routes$QuestRoutes$stem(aStudentQuestId) + '/outro';
	}
};
var $author$project$Routes$QuizRoutes$toUrlString = F2(
	function (base, route) {
		var stem = function (aStudentActivityId) {
			return base + ('/quizzes/' + $author$project$Models$StudentActivity$idToString(aStudentActivityId));
		};
		switch (route.$) {
			case 0:
				var aStudentActivityId = route.b;
				return stem(aStudentActivityId) + '/intro';
			case 1:
				var aStudentActivityId = route.c;
				return stem(aStudentActivityId) + '/assessment';
			default:
				var aStudentActivityId = route.b;
				return stem(aStudentActivityId) + '/outro';
		}
	});
var $author$project$Routes$VideoRoutes$toUrlString = F2(
	function (base, route) {
		var stem = base + '/video';
		switch (route.$) {
			case 0:
				return stem + '/intro';
			case 1:
				return stem + '/instructions';
			default:
				return stem + '/recording';
		}
	});
var $author$project$Routes$routeToUrlString = function (completeRoute) {
	switch (completeRoute.$) {
		case 7:
			var questRoute = completeRoute.a;
			return $author$project$Routes$QuestRoutes$toUrlString(questRoute);
		case 1:
			var loginRoute = completeRoute.a;
			return $author$project$Routes$LoginRoutes$routeToUrlString(loginRoute);
		case 8:
			var activityRoute = completeRoute.a;
			var basePath = $author$project$Routes$QuestRoutes$stem(
				$author$project$Routes$ActivityRoutes$studentQuestId(activityRoute));
			return A2($author$project$Routes$ActivityRoutes$toUrlString, basePath, activityRoute);
		case 9:
			var videoRoute = completeRoute.a;
			var basePath = $author$project$Routes$QuestRoutes$stem(
				$author$project$Routes$VideoRoutes$studentQuestId(videoRoute));
			return A2($author$project$Routes$VideoRoutes$toUrlString, basePath, videoRoute);
		case 10:
			var quizRoute = completeRoute.a;
			var basePath = $author$project$Routes$QuestRoutes$stem(
				$author$project$Routes$QuizRoutes$studentQuestId(quizRoute));
			return A2($author$project$Routes$QuizRoutes$toUrlString, basePath, quizRoute);
		case 0:
			return '/student';
		case 2:
			return '/student/logout';
		case 6:
			var componentType = completeRoute.a;
			return '/component/' + $author$project$Models$Activity$componentTypeToString(componentType);
		case 5:
			switch (completeRoute.a.$) {
				case 0:
					var questId = completeRoute.a.a;
					return '/student/quiz-question-preview/' + $author$project$Models$Ids$toString(questId);
				case 1:
					var learnosityId = completeRoute.a.a;
					return '/student/quiz-question-preview/' + learnosityId;
				default:
					var learnosityId = completeRoute.a.a;
					return '/student/quiz-question-preview/' + (learnosityId + '/challenge');
			}
		case 3:
			return '/student/404';
		case 4:
			var restoreSetAtTimeAsInt = completeRoute.a;
			var desiredResumeURL = completeRoute.b;
			return '/student/restore/' + ($elm$core$String$fromFloat(restoreSetAtTimeAsInt) + ('/' + desiredResumeURL));
		case 11:
			var backRoute = completeRoute.a;
			if (!backRoute.$) {
				var otherRoute = backRoute.a;
				return '/student/join?back=' + otherRoute;
			} else {
				return '/student/join';
			}
		case 12:
			var joinCode = completeRoute.a;
			return '/student/join/' + joinCode;
		default:
			var sectionId = completeRoute.b;
			return '/student/library/' + $author$project$Models$Ids$toString(sectionId);
	}
};
var $author$project$Routing$AccessControl$ensureAccess = F2(
	function (route, user) {
		var _v0 = A2($author$project$Routing$AccessControl$allowedToAccessRoute, route, user);
		switch (_v0) {
			case 1:
				return {
					bB: $author$project$Effect$UpdateUrlEffect(
						$author$project$Routes$routeToUrlString(
							$author$project$Routes$LoginRoute(
								$author$project$Routes$LoginRoutes$PasswordLoginRoute(0)))),
					ba: false
				};
			case 2:
				var destination = function () {
					if ((route.$ === 1) && (route.a.$ === 2)) {
						var _v2 = route.a;
						var destinationRouteString = _v2.c;
						return function (parsedRoute) {
							return _Utils_eq(parsedRoute, $author$project$Routes$NotFoundRoute) ? $author$project$Routes$homeRoute : parsedRoute;
						}(
							A3(
								$elm_community$maybe_extra$Maybe$Extra$unwrap,
								$author$project$Routes$homeRoute,
								$author$project$Routes$parseUrl,
								A2($elm$core$Maybe$andThen, $elm$url$Url$fromString, destinationRouteString)));
					} else {
						return $author$project$Routes$homeRoute;
					}
				}();
				return {
					bB: $author$project$Effect$UpdateUrlEffect(
						$author$project$Routes$routeToUrlString(destination)),
					ba: false
				};
			case 3:
				return {bB: $author$project$Effect$NoEffect, ba: false};
			default:
				return {bB: $author$project$Effect$NoEffect, ba: true};
		}
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $author$project$Routing$Updates$setNewRoute = F2(
	function (newRoute, _v0) {
		var model = _v0.a;
		var commands = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{eY: newRoute}),
			commands);
	});
var $author$project$Routing$Updates$forRoute = F3(
	function (route, additionalCmds, _v0) {
		var model = _v0.a;
		var existingCommands = _v0.b;
		var routeAccessDecision = A2($author$project$Routing$AccessControl$ensureAccess, route, model.jF);
		var updatedModelCommandTuple = routeAccessDecision.ba ? additionalCmds(
			_Utils_Tuple2(model, existingCommands)) : _Utils_Tuple2(
			model,
			$author$project$Effect$CmdEffect(existingCommands));
		var mergeRouteAccessDecisionEffect = $elm$core$Tuple$mapSecond(
			function (existingEffects) {
				return $author$project$Effect$batch(
					_List_fromArray(
						[routeAccessDecision.bB, existingEffects]));
			});
		return A2(
			$author$project$Routing$Updates$setNewRoute,
			route,
			mergeRouteAccessDecisionEffect(updatedModelCommandTuple));
	});
var $author$project$Models$User$NotLoggedIn = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$User$hasPrepopulatedLoginInfo = function (user) {
	if (user.$ === 1) {
		var loginState = user.a;
		var _v1 = loginState.hB;
		if (_v1.$ === 2) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};
var $author$project$Models$LoggingIn$NotStarted = {$: 0};
var $author$project$Models$LoggingIn$QRCodeScannerUninitialized = 0;
var $author$project$Models$LoggingIn$init = {b8: $elm$core$Maybe$Nothing, dX: 0, bI: $author$project$Models$LoggingIn$NotStarted, hB: $author$project$Models$LoggingIn$LoginMethodUnknown, eI: '', bg: 0, fF: ''};
var $author$project$Routes$LoginRoutes$isPrepopulatedRoute = function (route) {
	if (route.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Routes$isLoggedInRouteWithPrepopulatedLoginRoute = function (route) {
	if (route.$ === 1) {
		var loginRoute = route.a;
		return $author$project$Routes$LoginRoutes$isPrepopulatedRoute(loginRoute);
	} else {
		return false;
	}
};
var $author$project$Models$Modal$Closed = {$: 1};
var $author$project$StudentUsageTracker$Model$Model = $elm$core$Basics$identity;
var $author$project$StudentUsageTracker$Model$init = $elm$core$Maybe$Nothing;
var $author$project$Models$LoggingIn$initWithFlag = function (flag) {
	return _Utils_update(
		$author$project$Models$LoggingIn$init,
		{dX: flag});
};
var $author$project$Models$LoggingIn$Ongoing = {$: 1};
var $author$project$Models$LoggingIn$prepopulatedLoginInfo = F4(
	function (username, password, source, state) {
		return _Utils_update(
			state,
			{
				bI: $author$project$Models$LoggingIn$Ongoing,
				hB: $author$project$Models$LoggingIn$PrepopulatedUsernamePassword(source),
				eI: password,
				fF: username
			});
	});
var $TSFoster$elm_uuid$UUID$Seeds = F4(
	function (seed1, seed2, seed3, seed4) {
		return {iU: seed1, iV: seed2, iW: seed3, iX: seed4};
	});
var $TSFoster$elm_uuid$UUID$UUID = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $TSFoster$elm_uuid$UUID$forceUnsigned = $elm$core$Bitwise$shiftRightZfBy(0);
var $elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _v0.a;
			var hi = _v0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo,
					$elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = $elm$random$Random$peel(seed);
						var seedN = $elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var $elm$random$Random$map = F2(
	function (func, _v0) {
		var genA = _v0;
		return function (seed0) {
			var _v1 = genA(seed0);
			var a = _v1.a;
			var seed1 = _v1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var $elm$random$Random$maxInt = 2147483647;
var $elm$random$Random$minInt = -2147483648;
var $TSFoster$elm_uuid$UUID$randomU32 = A2(
	$elm$random$Random$map,
	$TSFoster$elm_uuid$UUID$forceUnsigned,
	A2($elm$random$Random$int, $elm$random$Random$minInt, $elm$random$Random$maxInt));
var $elm$core$Bitwise$or = _Bitwise_or;
var $TSFoster$elm_uuid$UUID$toVariant1 = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	var c = _v0.c;
	var d = _v0.d;
	return A4(
		$TSFoster$elm_uuid$UUID$UUID,
		a,
		b,
		$TSFoster$elm_uuid$UUID$forceUnsigned(2147483648 | (1073741823 & c)),
		d);
};
var $TSFoster$elm_uuid$UUID$toVersion = F2(
	function (v, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		var c = _v0.c;
		var d = _v0.d;
		return A4(
			$TSFoster$elm_uuid$UUID$UUID,
			a,
			$TSFoster$elm_uuid$UUID$forceUnsigned((v << 12) | (4294905855 & b)),
			c,
			d);
	});
var $TSFoster$elm_uuid$UUID$step = function (s) {
	var _v0 = A2($elm$random$Random$step, $TSFoster$elm_uuid$UUID$randomU32, s.iX);
	var int4 = _v0.a;
	var seed4 = _v0.b;
	var _v1 = A2($elm$random$Random$step, $TSFoster$elm_uuid$UUID$randomU32, s.iW);
	var int3 = _v1.a;
	var seed3 = _v1.b;
	var _v2 = A2($elm$random$Random$step, $TSFoster$elm_uuid$UUID$randomU32, s.iV);
	var int2 = _v2.a;
	var seed2 = _v2.b;
	var _v3 = A2($elm$random$Random$step, $TSFoster$elm_uuid$UUID$randomU32, s.iU);
	var int1 = _v3.a;
	var seed1 = _v3.b;
	var uuid = $TSFoster$elm_uuid$UUID$toVariant1(
		A2(
			$TSFoster$elm_uuid$UUID$toVersion,
			4,
			A4($TSFoster$elm_uuid$UUID$UUID, int1, int2, int3, int4)));
	return _Utils_Tuple2(
		uuid,
		A4($TSFoster$elm_uuid$UUID$Seeds, seed1, seed2, seed3, seed4));
};
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$String$fromList = _String_fromList;
var $TSFoster$elm_uuid$UUID$toHex = F2(
	function (acc, _int) {
		toHex:
		while (true) {
			if (!_int) {
				return $elm$core$String$fromList(acc);
			} else {
				var _char = function () {
					var _v0 = 15 & _int;
					switch (_v0) {
						case 0:
							return '0';
						case 1:
							return '1';
						case 2:
							return '2';
						case 3:
							return '3';
						case 4:
							return '4';
						case 5:
							return '5';
						case 6:
							return '6';
						case 7:
							return '7';
						case 8:
							return '8';
						case 9:
							return '9';
						case 10:
							return 'a';
						case 11:
							return 'b';
						case 12:
							return 'c';
						case 13:
							return 'd';
						case 14:
							return 'e';
						default:
							return 'f';
					}
				}();
				var $temp$acc = A2($elm$core$List$cons, _char, acc),
					$temp$int = _int >>> 4;
				acc = $temp$acc;
				_int = $temp$int;
				continue toHex;
			}
		}
	});
var $TSFoster$elm_uuid$UUID$toStringWith = F2(
	function (sep, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		var c = _v0.c;
		var d = _v0.d;
		return _Utils_ap(
			A3(
				$elm$core$String$padLeft,
				8,
				'0',
				A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, a)),
			_Utils_ap(
				sep,
				_Utils_ap(
					A3(
						$elm$core$String$padLeft,
						4,
						'0',
						A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, b >>> 16)),
					_Utils_ap(
						sep,
						_Utils_ap(
							A3(
								$elm$core$String$padLeft,
								4,
								'0',
								A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, 65535 & b)),
							_Utils_ap(
								sep,
								_Utils_ap(
									A3(
										$elm$core$String$padLeft,
										4,
										'0',
										A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, c >>> 16)),
									_Utils_ap(
										sep,
										_Utils_ap(
											A3(
												$elm$core$String$padLeft,
												4,
												'0',
												A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, 65535 & c)),
											A3(
												$elm$core$String$padLeft,
												8,
												'0',
												A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, d)))))))))));
	});
var $TSFoster$elm_uuid$UUID$toString = $TSFoster$elm_uuid$UUID$toStringWith('-');
var $author$project$RandomNumberGenerator$uuid = function (seeds) {
	return $TSFoster$elm_uuid$UUID$toString(
		$TSFoster$elm_uuid$UUID$step(seeds).a);
};
var $author$project$Model$resetUserState = function (currentModel) {
	var newUserState = function () {
		var _v0 = currentModel.jF;
		switch (_v0.$) {
			case 1:
				var _v1 = currentModel.eY;
				_v1$2:
				while (true) {
					if (_v1.$ === 1) {
						switch (_v1.a.$) {
							case 1:
								var prepopulatedName = _v1.a.a.$9;
								var prepopulatedAuth = _v1.a.a.im;
								var source = _v1.a.a.i1;
								return $author$project$Models$User$NotLoggedIn(
									A4($author$project$Models$LoggingIn$prepopulatedLoginInfo, prepopulatedName, prepopulatedAuth, source, $author$project$Models$LoggingIn$init));
							case 0:
								var loginFlag = _v1.a.a;
								return $author$project$Models$User$NotLoggedIn(
									$author$project$Models$LoggingIn$initWithFlag(loginFlag));
							default:
								break _v1$2;
						}
					} else {
						break _v1$2;
					}
				}
				return $author$project$Models$User$NotLoggedIn($author$project$Models$LoggingIn$init);
			case 0:
				return $author$project$Models$User$NotLoggedIn($author$project$Models$LoggingIn$init);
			default:
				return $author$project$Models$User$NotLoggedIn($author$project$Models$LoggingIn$init);
		}
	}();
	return _Utils_update(
		currentModel,
		{
			fZ: $krisajenkins$remotedata$RemoteData$NotAsked,
			go: $krisajenkins$remotedata$RemoteData$NotAsked,
			hz: $author$project$Models$LocalStorage$default,
			hC: false,
			hM: $author$project$Models$Modal$Closed,
			bW: $elm$core$Maybe$Nothing,
			i$: $author$project$RandomNumberGenerator$uuid(currentModel.cR),
			c6: $author$project$StudentUsageTracker$Model$init,
			jF: newUserState
		});
};
var $author$project$Ports$signOut = _Platform_outgoingPort('signOut', $elm$json$Json$Encode$bool);
var $author$project$Routing$Updates$handleLogoutIfAppropriate = F2(
	function (newRoute, _v0) {
		var model = _v0.a;
		var previousEffects = _v0.b;
		_v1$3:
		while (true) {
			switch (newRoute.$) {
				case 2:
					return A2(
						$elm$core$Tuple$mapSecond,
						$author$project$Effect$CmdEffect,
						_Utils_Tuple2(
							$author$project$Model$resetUserState(model),
							$author$project$Ports$signOut(false)));
				case 1:
					switch (newRoute.a.$) {
						case 1:
							var _v2 = model.jF;
							if (_v2.$ === 2) {
								return A2(
									$elm$core$Tuple$mapSecond,
									$author$project$Effect$CmdEffect,
									_Utils_Tuple2(
										$author$project$Model$resetUserState(model),
										$author$project$Ports$signOut(false)));
							} else {
								return _Utils_Tuple2(model, previousEffects);
							}
						case 0:
							var loginFlag = newRoute.a.a;
							var user = $author$project$Models$User$NotLoggedIn(
								function (state) {
									return _Utils_update(
										state,
										{dX: loginFlag});
								}($author$project$Models$LoggingIn$init));
							var prepopulatedLoginInProgress = $author$project$Routes$isLoggedInRouteWithPrepopulatedLoginRoute(model.eY) || $author$project$Models$User$hasPrepopulatedLoginInfo(model.jF);
							var currentUserWithLoginFlag = function () {
								var _v3 = model.jF;
								if (_v3.$ === 1) {
									var state = _v3.a;
									return $author$project$Models$User$NotLoggedIn(
										_Utils_update(
											state,
											{dX: loginFlag}));
								} else {
									return model.jF;
								}
							}();
							return prepopulatedLoginInProgress ? _Utils_Tuple2(
								_Utils_update(
									model,
									{jF: currentUserWithLoginFlag}),
								previousEffects) : _Utils_Tuple2(
								_Utils_update(
									model,
									{jF: user}),
								previousEffects);
						default:
							break _v1$3;
					}
				default:
					break _v1$3;
			}
		}
		return _Utils_Tuple2(model, previousEffects);
	});
var $author$project$Effect$UserLoginAttemptEffect = function (a) {
	return {$: 9, a: a};
};
var $chelovek0v$bbase64$Base64$Decode$decode = F2(
	function (_v0, input) {
		var decoder = _v0;
		return decoder(input);
	});
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $chelovek0v$bbase64$Base64$Decode$Decoder = $elm$core$Basics$identity;
var $elm$bytes$Bytes$Encode$getWidth = function (builder) {
	switch (builder.$) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 8;
		case 8:
			var w = builder.a;
			return w;
		case 9:
			var w = builder.a;
			return w;
		default:
			var bs = builder.a;
			return _Bytes_width(bs);
	}
};
var $elm$bytes$Bytes$LE = 0;
var $elm$bytes$Bytes$Encode$write = F3(
	function (builder, mb, offset) {
		switch (builder.$) {
			case 0:
				var n = builder.a;
				return A3(_Bytes_write_i8, mb, offset, n);
			case 1:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i16, mb, offset, n, !e);
			case 2:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i32, mb, offset, n, !e);
			case 3:
				var n = builder.a;
				return A3(_Bytes_write_u8, mb, offset, n);
			case 4:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u16, mb, offset, n, !e);
			case 5:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u32, mb, offset, n, !e);
			case 6:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f32, mb, offset, n, !e);
			case 7:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f64, mb, offset, n, !e);
			case 8:
				var bs = builder.b;
				return A3($elm$bytes$Bytes$Encode$writeSequence, bs, mb, offset);
			case 9:
				var s = builder.b;
				return A3(_Bytes_write_string, mb, offset, s);
			default:
				var bs = builder.a;
				return A3(_Bytes_write_bytes, mb, offset, bs);
		}
	});
var $elm$bytes$Bytes$Encode$writeSequence = F3(
	function (builders, mb, offset) {
		writeSequence:
		while (true) {
			if (!builders.b) {
				return offset;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$builders = bs,
					$temp$mb = mb,
					$temp$offset = A3($elm$bytes$Bytes$Encode$write, b, mb, offset);
				builders = $temp$builders;
				mb = $temp$mb;
				offset = $temp$offset;
				continue writeSequence;
			}
		}
	});
var $elm$bytes$Bytes$Encode$encode = _Bytes_encode;
var $elm$bytes$Bytes$Encode$Seq = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$getWidths = F2(
	function (width, builders) {
		getWidths:
		while (true) {
			if (!builders.b) {
				return width;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$width = width + $elm$bytes$Bytes$Encode$getWidth(b),
					$temp$builders = bs;
				width = $temp$width;
				builders = $temp$builders;
				continue getWidths;
			}
		}
	});
var $elm$bytes$Bytes$Encode$sequence = function (builders) {
	return A2(
		$elm$bytes$Bytes$Encode$Seq,
		A2($elm$bytes$Bytes$Encode$getWidths, 0, builders),
		builders);
};
var $chelovek0v$bbase64$Base64$Decode$encodeBytes = function (encoders) {
	return $elm$bytes$Bytes$Encode$encode(
		$elm$bytes$Bytes$Encode$sequence(
			$elm$core$List$reverse(encoders)));
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $chelovek0v$bbase64$Base64$Table$charToCodeMap = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('A', 0),
			_Utils_Tuple2('B', 1),
			_Utils_Tuple2('C', 2),
			_Utils_Tuple2('D', 3),
			_Utils_Tuple2('E', 4),
			_Utils_Tuple2('F', 5),
			_Utils_Tuple2('G', 6),
			_Utils_Tuple2('H', 7),
			_Utils_Tuple2('I', 8),
			_Utils_Tuple2('J', 9),
			_Utils_Tuple2('K', 10),
			_Utils_Tuple2('L', 11),
			_Utils_Tuple2('M', 12),
			_Utils_Tuple2('N', 13),
			_Utils_Tuple2('O', 14),
			_Utils_Tuple2('P', 15),
			_Utils_Tuple2('Q', 16),
			_Utils_Tuple2('R', 17),
			_Utils_Tuple2('S', 18),
			_Utils_Tuple2('T', 19),
			_Utils_Tuple2('U', 20),
			_Utils_Tuple2('V', 21),
			_Utils_Tuple2('W', 22),
			_Utils_Tuple2('X', 23),
			_Utils_Tuple2('Y', 24),
			_Utils_Tuple2('Z', 25),
			_Utils_Tuple2('a', 26),
			_Utils_Tuple2('b', 27),
			_Utils_Tuple2('c', 28),
			_Utils_Tuple2('d', 29),
			_Utils_Tuple2('e', 30),
			_Utils_Tuple2('f', 31),
			_Utils_Tuple2('g', 32),
			_Utils_Tuple2('h', 33),
			_Utils_Tuple2('i', 34),
			_Utils_Tuple2('j', 35),
			_Utils_Tuple2('k', 36),
			_Utils_Tuple2('l', 37),
			_Utils_Tuple2('m', 38),
			_Utils_Tuple2('n', 39),
			_Utils_Tuple2('o', 40),
			_Utils_Tuple2('p', 41),
			_Utils_Tuple2('q', 42),
			_Utils_Tuple2('r', 43),
			_Utils_Tuple2('s', 44),
			_Utils_Tuple2('t', 45),
			_Utils_Tuple2('u', 46),
			_Utils_Tuple2('v', 47),
			_Utils_Tuple2('w', 48),
			_Utils_Tuple2('x', 49),
			_Utils_Tuple2('y', 50),
			_Utils_Tuple2('z', 51),
			_Utils_Tuple2('0', 52),
			_Utils_Tuple2('1', 53),
			_Utils_Tuple2('2', 54),
			_Utils_Tuple2('3', 55),
			_Utils_Tuple2('4', 56),
			_Utils_Tuple2('5', 57),
			_Utils_Tuple2('6', 58),
			_Utils_Tuple2('7', 59),
			_Utils_Tuple2('8', 60),
			_Utils_Tuple2('9', 61),
			_Utils_Tuple2('+', 62),
			_Utils_Tuple2('/', 63)
		]));
var $chelovek0v$bbase64$Base64$Table$decode = function (_char) {
	return A2(
		$elm$core$Dict$get,
		$elm$core$String$fromChar(_char),
		$chelovek0v$bbase64$Base64$Table$charToCodeMap);
};
var $chelovek0v$bbase64$Base64$Shift$Shift2 = 1;
var $chelovek0v$bbase64$Base64$Shift$Shift4 = 2;
var $chelovek0v$bbase64$Base64$Shift$Shift6 = 3;
var $chelovek0v$bbase64$Base64$Shift$Shift0 = 0;
var $chelovek0v$bbase64$Base64$Shift$decodeNext = function (shift) {
	switch (shift) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		default:
			return 0;
	}
};
var $chelovek0v$bbase64$Base64$Shift$toInt = function (shift) {
	switch (shift) {
		case 0:
			return 0;
		case 1:
			return 2;
		case 2:
			return 4;
		default:
			return 6;
	}
};
var $chelovek0v$bbase64$Base64$Shift$shiftRightZfBy = F2(
	function (shift, value) {
		return value >>> $chelovek0v$bbase64$Base64$Shift$toInt(shift);
	});
var $chelovek0v$bbase64$Base64$Decode$finishPartialByte = F3(
	function (shift, sextet, partialByte) {
		return partialByte | A2($chelovek0v$bbase64$Base64$Shift$shiftRightZfBy, shift, sextet);
	});
var $chelovek0v$bbase64$Base64$Shift$shiftLeftBy = F2(
	function (shift, value) {
		return value << $chelovek0v$bbase64$Base64$Shift$toInt(shift);
	});
var $elm$bytes$Bytes$Encode$U8 = function (a) {
	return {$: 3, a: a};
};
var $elm$bytes$Bytes$Encode$unsignedInt8 = $elm$bytes$Bytes$Encode$U8;
var $chelovek0v$bbase64$Base64$Decode$decodeStep = F2(
	function (sextet, _v0) {
		var shift = _v0.a;
		var partialByte = _v0.b;
		var deferredEncoders = _v0.c;
		var nextBlankByte = function () {
			switch (shift) {
				case 0:
					return A2($chelovek0v$bbase64$Base64$Shift$shiftLeftBy, 1, sextet);
				case 1:
					return A2($chelovek0v$bbase64$Base64$Shift$shiftLeftBy, 2, sextet);
				case 2:
					return A2($chelovek0v$bbase64$Base64$Shift$shiftLeftBy, 3, sextet);
				default:
					return 0;
			}
		}();
		var finishedByte = function () {
			switch (shift) {
				case 0:
					return $elm$core$Maybe$Nothing;
				case 1:
					return $elm$core$Maybe$Just(
						A3($chelovek0v$bbase64$Base64$Decode$finishPartialByte, 2, sextet, partialByte));
				case 2:
					return $elm$core$Maybe$Just(
						A3($chelovek0v$bbase64$Base64$Decode$finishPartialByte, 1, sextet, partialByte));
				default:
					return $elm$core$Maybe$Just(partialByte | sextet);
			}
		}();
		var nextDeferredDecoders = function () {
			if (!finishedByte.$) {
				var byte_ = finishedByte.a;
				return A2(
					$elm$core$List$cons,
					$elm$bytes$Bytes$Encode$unsignedInt8(byte_),
					deferredEncoders);
			} else {
				return deferredEncoders;
			}
		}();
		return _Utils_Tuple3(
			$chelovek0v$bbase64$Base64$Shift$decodeNext(shift),
			nextBlankByte,
			nextDeferredDecoders);
	});
var $elm$core$String$foldl = _String_foldl;
var $chelovek0v$bbase64$Base64$Decode$initialState = _Utils_Tuple3(0, 0, _List_Nil);
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$String$endsWith = _String_endsWith;
var $chelovek0v$bbase64$Base64$Decode$strip = function (input) {
	return A2($elm$core$String$endsWith, '==', input) ? $elm$core$Result$Ok(
		A2($elm$core$String$dropRight, 2, input)) : (A2($elm$core$String$endsWith, '=', input) ? $elm$core$Result$Ok(
		A2($elm$core$String$dropRight, 1, input)) : $elm$core$Result$Ok(input));
};
var $chelovek0v$bbase64$Base64$Decode$ValidationError = 0;
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {hm: index, hE: match, h4: number, jk: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{gy: false, hP: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $chelovek0v$bbase64$Base64$Decode$validate = function (input) {
	var regex = A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString('^[A-Za-z0-9\\/+]*$'));
	return A2($elm$regex$Regex$contains, regex, input) ? $elm$core$Result$Ok(input) : $elm$core$Result$Err(0);
};
var $chelovek0v$bbase64$Base64$Decode$tryDecode = function (input) {
	return A2(
		$elm$core$Result$map,
		A2(
			$elm$core$String$foldl,
			F2(
				function (_char, state) {
					return A2(
						$elm$core$Maybe$withDefault,
						state,
						A2(
							$elm$core$Maybe$map,
							function (sextet) {
								return A2($chelovek0v$bbase64$Base64$Decode$decodeStep, sextet, state);
							},
							$chelovek0v$bbase64$Base64$Table$decode(_char)));
				}),
			$chelovek0v$bbase64$Base64$Decode$initialState),
		A2(
			$elm$core$Result$andThen,
			$chelovek0v$bbase64$Base64$Decode$validate,
			$chelovek0v$bbase64$Base64$Decode$strip(input)));
};
var $chelovek0v$bbase64$Base64$Decode$InvalidByteSequence = 1;
var $elm$bytes$Bytes$Decode$decode = F2(
	function (_v0, bs) {
		var decoder = _v0;
		return A2(_Bytes_decode, decoder, bs);
	});
var $elm$bytes$Bytes$Decode$Decoder = $elm$core$Basics$identity;
var $elm$bytes$Bytes$Decode$string = function (n) {
	return _Bytes_read_string(n);
};
var $elm$bytes$Bytes$width = _Bytes_width;
var $chelovek0v$bbase64$Base64$Decode$tryToString = function (input) {
	var _v0 = A2(
		$elm$bytes$Bytes$Decode$decode,
		$elm$bytes$Bytes$Decode$string(
			$elm$bytes$Bytes$width(input)),
		input);
	if (!_v0.$) {
		var str = _v0.a;
		return $elm$core$Result$Ok(str);
	} else {
		return $elm$core$Result$Err(1);
	}
};
var $chelovek0v$bbase64$Base64$Decode$string = function (input) {
	var _v0 = $chelovek0v$bbase64$Base64$Decode$tryDecode(input);
	if (!_v0.$) {
		var _v1 = _v0.a;
		var deferredEncoders = _v1.c;
		return $chelovek0v$bbase64$Base64$Decode$tryToString(
			$chelovek0v$bbase64$Base64$Decode$encodeBytes(deferredEncoders));
	} else {
		var e = _v0.a;
		return $elm$core$Result$Err(e);
	}
};
var $elm$core$String$toUpper = _String_toUpper;
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Routing$Updates$parseLoginMethodFromRoute = F2(
	function (newRoute, _v0) {
		var model = _v0.a;
		var existingEffects = _v0.b;
		var _v1 = model.jF;
		switch (_v1.$) {
			case 0:
				return _Utils_Tuple2(model, existingEffects);
			case 2:
				return _Utils_Tuple2(model, existingEffects);
			default:
				var loginState = _v1.a;
				_v2$2:
				while (true) {
					if (newRoute.$ === 1) {
						switch (newRoute.a.$) {
							case 1:
								var prepopulatedName = newRoute.a.a.$9;
								var prepopulatedAuth = newRoute.a.a.im;
								var source = newRoute.a.a.i1;
								var unscrambledUsername = $elm$core$String$toUpper(
									A2(
										$elm$core$Result$withDefault,
										'',
										A2(
											$chelovek0v$bbase64$Base64$Decode$decode,
											$chelovek0v$bbase64$Base64$Decode$string,
											A3($elm$core$String$replace, '\n', '', prepopulatedName))));
								var unscrambledPassword = $elm$core$String$toUpper(
									A2(
										$elm$core$Result$withDefault,
										'',
										A2(
											$chelovek0v$bbase64$Base64$Decode$decode,
											$chelovek0v$bbase64$Base64$Decode$string,
											A3($elm$core$String$replace, '\n', '', prepopulatedAuth))));
								var submitLoginEffect = $author$project$Effect$UserLoginAttemptEffect(
									_Utils_Tuple2(unscrambledUsername, unscrambledPassword));
								var redirectEffect = $author$project$Effect$UpdateUrlEffect(
									$author$project$Routes$routeToUrlString(
										$author$project$Routes$LoginRoute(
											$author$project$Routes$LoginRoutes$PasswordLoginRoute(0))));
								var prepopulatedSource = function () {
									var _v3 = loginState.hB;
									if ((_v3.$ === 2) && (_v3.a.$ === 2)) {
										var _v4 = _v3.a;
										return $author$project$Models$LoggingIn$PrepopulatedSourceQRCode;
									} else {
										return source;
									}
								}();
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											jF: $author$project$Models$User$NotLoggedIn(
												A4($author$project$Models$LoggingIn$prepopulatedLoginInfo, unscrambledUsername, unscrambledPassword, prepopulatedSource, loginState))
										}),
									$author$project$Effect$batch(
										_List_fromArray(
											[existingEffects, submitLoginEffect, redirectEffect])));
							case 0:
								var flag = newRoute.a.a;
								return $author$project$Models$User$hasPrepopulatedLoginInfo(model.jF) ? _Utils_Tuple2(model, existingEffects) : _Utils_Tuple2(
									_Utils_update(
										model,
										{
											jF: $author$project$Models$User$NotLoggedIn(
												_Utils_update(
													loginState,
													{hB: $author$project$Models$LoggingIn$ManualUsernamePassword}))
										}),
									existingEffects);
							default:
								break _v2$2;
						}
					} else {
						break _v2$2;
					}
				}
				return _Utils_Tuple2(model, existingEffects);
		}
	});
var $author$project$Models$VideoPlayer$NotStarted = function (a) {
	return {$: 0, a: a};
};
var $author$project$LoggedIn$Messages$RetrieveMediaServerUrlForStudentActivity = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Models$LocalStorage$VideoPlayerTime$get = F2(
	function (key, history) {
		return A2($elm$core$Dict$get, key, history);
	});
var $author$project$Models$LocalStorage$Helpers$getVideoPlayerTimeEntry = F2(
	function (key, store) {
		return A2($author$project$Models$LocalStorage$VideoPlayerTime$get, key, store.c8);
	});
var $author$project$Models$Activity$hostedVideoFailedToLoad = function (activity) {
	var _v0 = activity.f0;
	if (((!_v0.$) && (!_v0.a.$)) && (_v0.a.a === 2)) {
		var _v1 = _v0.a;
		var _v2 = _v1.a;
		return true;
	} else {
		return false;
	}
};
var $author$project$Models$Activity$hostedVideoUrlToRetrieve = function (activity) {
	var _v0 = activity.f0;
	if (!_v0.$) {
		var hostedVideoStatus = _v0.a;
		if (!hostedVideoStatus.$) {
			var mediaServerUrl = hostedVideoStatus.b;
			return $elm$core$Maybe$Just(mediaServerUrl);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Routing$Updates$resetVideoPlayState = F2(
	function (newRoute, _v0) {
		var model = _v0.a;
		var effects = _v0.b;
		var updatedPlayerTime = function () {
			switch (newRoute.$) {
				case 8:
					var activityRoute = newRoute.a;
					var studentActivityId = $author$project$Routes$ActivityRoutes$studentActivityId(activityRoute);
					return A2(
						$author$project$Models$LocalStorage$Helpers$getVideoPlayerTimeEntry,
						'activity-practice-' + $author$project$Models$StudentActivity$idToString(studentActivityId),
						model.hz);
				case 10:
					var quizRoute = newRoute.a;
					var studentActivityId = $author$project$Routes$QuizRoutes$studentActivityId(quizRoute);
					return A2(
						$author$project$Models$LocalStorage$Helpers$getVideoPlayerTimeEntry,
						'activity-practice-' + $author$project$Models$StudentActivity$idToString(studentActivityId),
						model.hz);
				default:
					return $elm$core$Maybe$Nothing;
			}
		}();
		var effectsWithHostedVideoRetryIfFailed = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			effects,
			function (studentActivity) {
				return $author$project$Models$Activity$hostedVideoFailedToLoad(studentActivity.f_) ? A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					effects,
					function (url) {
						return $author$project$Effect$batch(
							_List_fromArray(
								[
									effects,
									$author$project$Effect$PerformNowEffect(
									$author$project$Messages$LoggedInRequiredMsg(
										A2($author$project$LoggedIn$Messages$RetrieveMediaServerUrlForStudentActivity, studentActivity.hj, url)))
								]));
					},
					$author$project$Models$Activity$hostedVideoUrlToRetrieve(studentActivity.f_)) : effects;
			},
			A2(
				$elm$core$Maybe$andThen,
				$author$project$Model$studentActivityForId(model),
				$author$project$Routes$studentActivityIdFromRoute(newRoute)));
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					jI: $author$project$Models$VideoPlayer$NotStarted(updatedPlayerTime)
				}),
			effectsWithHostedVideoRetryIfFailed);
	});
var $author$project$VideoRecording$RecordingProcess$NotStarted = {$: 0};
var $author$project$Routing$Updates$resetVideoRecordingState = function (_v0) {
	var model = _v0.a;
	var commands = _v0.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{d: $author$project$VideoRecording$RecordingProcess$NotStarted}),
		commands);
};
var $author$project$Models$LocalStorage$StudentQuestHistory$set = F3(
	function (studentQuestId, entry, history) {
		return A3(
			$elm$core$Dict$insert,
			$author$project$Models$Ids$toString(studentQuestId),
			entry,
			history);
	});
var $author$project$Models$LocalStorage$Helpers$setStudentQuestHistoryEntry = F3(
	function (questId, entry, store) {
		return _Utils_update(
			store,
			{
				c0: A3($author$project$Models$LocalStorage$StudentQuestHistory$set, questId, entry, store.c0)
			});
	});
var $author$project$Routing$Updates$trackMostRecentActivity = F2(
	function (newRoute, _v0) {
		var model = _v0.a;
		var commands = _v0.b;
		var updatedLocalStorage = function () {
			switch (newRoute.$) {
				case 8:
					var activityRoute = newRoute.a;
					return A3(
						$author$project$Models$LocalStorage$Helpers$setStudentQuestHistoryEntry,
						$author$project$Routes$ActivityRoutes$studentQuestId(activityRoute),
						{
							eC: $author$project$Routes$ActivityRoutes$studentActivityId(activityRoute)
						},
						model.hz);
				case 10:
					var quizRoute = newRoute.a;
					return A3(
						$author$project$Models$LocalStorage$Helpers$setStudentQuestHistoryEntry,
						$author$project$Routes$QuizRoutes$studentQuestId(quizRoute),
						{
							eC: $author$project$Routes$QuizRoutes$studentActivityId(quizRoute)
						},
						model.hz);
				default:
					return model.hz;
			}
		}();
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{hz: updatedLocalStorage}),
			commands);
	});
var $author$project$Routing$Updates$forRouteNotLoggedIn = F2(
	function (newRoute, _v0) {
		var model = _v0.a;
		var existingCommands = _v0.b;
		var routeChangeCommands = A2(
			$elm$core$Basics$composeR,
			$elm$core$Tuple$mapSecond($author$project$Effect$CmdEffect),
			A2(
				$elm$core$Basics$composeR,
				$author$project$Routing$Updates$handleLogoutIfAppropriate(newRoute),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Routing$Updates$parseLoginMethodFromRoute(newRoute),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Routing$Updates$resetVideoPlayState(newRoute),
						A2(
							$elm$core$Basics$composeR,
							$author$project$Routing$Updates$resetVideoRecordingState,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Routing$Updates$trackMostRecentActivity(newRoute),
								A2(
									$elm$core$Basics$composeR,
									$author$project$Routing$Updates$checkQrCodePermissionsIfAppropriate(newRoute),
									A2(
										$elm$core$Basics$composeR,
										$author$project$Routing$Updates$autoplayPollyAudio(newRoute),
										$author$project$Routing$Updates$focusOnLoginView(newRoute)))))))));
		return A3(
			$author$project$Routing$Updates$forRoute,
			newRoute,
			routeChangeCommands,
			_Utils_Tuple2(model, existingCommands));
	});
var $author$project$SSOLogin$googleRedirectUrl = F2(
	function (code, appConfiguration) {
		return appConfiguration.fa + ('/student_login/google_redirect?join_code=' + code);
	});
var $author$project$AppConfiguration$Chrome = 1;
var $author$project$AppConfiguration$IosVersion = $elm$core$Basics$identity;
var $author$project$AppConfiguration$NoVideoRecording = 1;
var $author$project$AppConfiguration$RemoteConnectionNotActivated = {$: 0};
var $author$project$AppConfiguration$Safari = 0;
var $author$project$AppConfiguration$SupportsVideoRecording = 0;
var $author$project$AppConfiguration$Web = function (a) {
	return {$: 0, a: a};
};
var $author$project$AppConfiguration$WebOnIos = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$AppConfiguration$iosWebVideoRecordingSupport = function (_v0) {
	var version = _v0;
	return (version >= 15) ? 0 : 1;
};
var $elm$core$String$toLower = _String_toLower;
var $author$project$AppConfiguration$importRawAppConfiguration = function (rawAppConfiguration) {
	var platform = function () {
		var onIos = (rawAppConfiguration.cM.cE === 'iOS') || ((rawAppConfiguration.cM.cE === 'Mac OS') && rawAppConfiguration.ef);
		var iosVersion = A2(
			$elm$core$Maybe$withDefault,
			15,
			A2(
				$elm$core$Maybe$andThen,
				$elm$core$String$toInt,
				$elm$core$List$head(
					A2($elm$core$String$split, '.', rawAppConfiguration.ca.jH))));
		var iosBrowserType = (onIos && A2(
			$elm$core$String$contains,
			'safari',
			$elm$core$String$toLower(rawAppConfiguration.ca.cE))) ? 0 : 1;
		return onIos ? A3(
			$author$project$AppConfiguration$WebOnIos,
			iosVersion,
			iosBrowserType,
			$author$project$AppConfiguration$iosWebVideoRecordingSupport(iosVersion)) : (rawAppConfiguration.fp ? $author$project$AppConfiguration$Web(0) : $author$project$AppConfiguration$Web(1));
	}();
	var isDevelopment = rawAppConfiguration.dS === 'development';
	return {
		bv: $elm$time$Time$millisToPosix(rawAppConfiguration.bv),
		dA: $author$project$AppConfiguration$RemoteConnectionNotActivated,
		gD: rawAppConfiguration.gD,
		bz: rawAppConfiguration.bz,
		dO: rawAppConfiguration.dO,
		cn: $author$project$AppConfiguration$RemoteConnectionNotActivated,
		ed: isDevelopment,
		ee: rawAppConfiguration.d8,
		bK: rawAppConfiguration.bK,
		bM: rawAppConfiguration.bM,
		ii: platform,
		eL: $author$project$AppConfiguration$RemoteConnectionNotActivated,
		bR: rawAppConfiguration.bR,
		fa: rawAppConfiguration.fa,
		b0: rawAppConfiguration.b0,
		bo: $elm$time$Time$millisToPosix(rawAppConfiguration.bo),
		fu: A2($elm$time$Time$customZone, rawAppConfiguration.fv, _List_Nil)
	};
};
var $author$project$Models$Permission$NotYetDetermined = 0;
var $author$project$Models$User$NotYetKnown = {$: 0};
var $author$project$Models$WindowFocus$WindowHasFocus = {$: 0};
var $author$project$Models$ActivityWindowState$NoExternalWindow = {$: 0};
var $author$project$Models$ActivityWindowState$default = $author$project$Models$ActivityWindowState$NoExternalWindow;
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.cY, posixMinutes) < 0) {
					return posixMinutes + era.ao;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		dK: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		eB: month,
		fV: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).dK;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).eB;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).fV;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $author$project$Animations$Types$NotStarted = 0;
var $mdgriffith$elm_style_animation$Animation$Model$Spring = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_style_animation$Animation$spring = function (settings) {
	return $mdgriffith$elm_style_animation$Animation$Model$Spring(settings);
};
var $mdgriffith$elm_style_animation$Animation$Model$Animation = $elm$core$Basics$identity;
var $mdgriffith$elm_style_animation$Animation$initialState = function (current) {
	return {
		cw: _List_Nil,
		bY: false,
		i7: _List_Nil,
		aE: current,
		fw: {
			dI: $elm$time$Time$millisToPosix(0),
			gT: $elm$time$Time$millisToPosix(0)
		}
	};
};
var $mdgriffith$elm_style_animation$Animation$Model$AngleProperty = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $mdgriffith$elm_style_animation$Animation$Model$ColorProperty = F5(
	function (a, b, c, d, e) {
		return {$: 1, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_style_animation$Animation$Model$ExactProperty = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_style_animation$Animation$Model$Path = function (a) {
	return {$: 9, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$Points = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$Property = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_style_animation$Animation$Model$Property2 = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $mdgriffith$elm_style_animation$Animation$Model$Property3 = F4(
	function (a, b, c, d) {
		return {$: 5, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_style_animation$Animation$Model$Property4 = F5(
	function (a, b, c, d, e) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_style_animation$Animation$Model$ShadowProperty = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $mdgriffith$elm_style_animation$Animation$Model$AntiClockwiseArc = function (a) {
	return {$: 17, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$ClockwiseArc = function (a) {
	return {$: 16, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$Close = {$: 18};
var $mdgriffith$elm_style_animation$Animation$Model$Curve = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$CurveTo = function (a) {
	return {$: 9, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$Horizontal = function (a) {
	return {$: 4, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$HorizontalTo = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$Line = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_style_animation$Animation$Model$LineTo = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_style_animation$Animation$Model$Move = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_style_animation$Animation$Model$MoveTo = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_style_animation$Animation$Model$Quadratic = function (a) {
	return {$: 10, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$QuadraticTo = function (a) {
	return {$: 11, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$Smooth = function (a) {
	return {$: 14, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$SmoothQuadratic = function (a) {
	return {$: 12, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$SmoothQuadraticTo = function (a) {
	return {$: 13, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$SmoothTo = function (a) {
	return {$: 15, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$Vertical = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$VerticalTo = function (a) {
	return {$: 7, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$mapPathMotion = F2(
	function (fn, cmd) {
		var mapCoords = function (coords) {
			return A2(
				$elm$core$List$map,
				function (_v1) {
					var x = _v1.a;
					var y = _v1.b;
					return _Utils_Tuple2(
						fn(x),
						fn(y));
				},
				coords);
		};
		switch (cmd.$) {
			case 0:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$mdgriffith$elm_style_animation$Animation$Model$Move,
					fn(m1),
					fn(m2));
			case 1:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$mdgriffith$elm_style_animation$Animation$Model$MoveTo,
					fn(m1),
					fn(m2));
			case 2:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$mdgriffith$elm_style_animation$Animation$Model$Line,
					fn(m1),
					fn(m2));
			case 3:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$mdgriffith$elm_style_animation$Animation$Model$LineTo,
					fn(m1),
					fn(m2));
			case 4:
				var motion = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$Horizontal(
					fn(motion));
			case 5:
				var motion = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$HorizontalTo(
					fn(motion));
			case 6:
				var motion = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$Vertical(
					fn(motion));
			case 7:
				var motion = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$VerticalTo(
					fn(motion));
			case 8:
				var control1 = cmd.a.a5;
				var control2 = cmd.a.a6;
				var point = cmd.a.ab;
				return $mdgriffith$elm_style_animation$Animation$Model$Curve(
					{
						a5: _Utils_Tuple2(
							fn(control1.a),
							fn(control1.b)),
						a6: _Utils_Tuple2(
							fn(control2.a),
							fn(control2.b)),
						ab: _Utils_Tuple2(
							fn(point.a),
							fn(point.b))
					});
			case 9:
				var control1 = cmd.a.a5;
				var control2 = cmd.a.a6;
				var point = cmd.a.ab;
				return $mdgriffith$elm_style_animation$Animation$Model$CurveTo(
					{
						a5: _Utils_Tuple2(
							fn(control1.a),
							fn(control1.b)),
						a6: _Utils_Tuple2(
							fn(control2.a),
							fn(control2.b)),
						ab: _Utils_Tuple2(
							fn(point.a),
							fn(point.b))
					});
			case 10:
				var control = cmd.a.a4;
				var point = cmd.a.ab;
				return $mdgriffith$elm_style_animation$Animation$Model$Quadratic(
					{
						a4: _Utils_Tuple2(
							fn(control.a),
							fn(control.b)),
						ab: _Utils_Tuple2(
							fn(point.a),
							fn(point.b))
					});
			case 11:
				var control = cmd.a.a4;
				var point = cmd.a.ab;
				return $mdgriffith$elm_style_animation$Animation$Model$QuadraticTo(
					{
						a4: _Utils_Tuple2(
							fn(control.a),
							fn(control.b)),
						ab: _Utils_Tuple2(
							fn(point.a),
							fn(point.b))
					});
			case 12:
				var coords = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$SmoothQuadratic(
					mapCoords(coords));
			case 13:
				var coords = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$SmoothQuadraticTo(
					mapCoords(coords));
			case 14:
				var coords = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$Smooth(
					mapCoords(coords));
			case 15:
				var coords = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$SmoothTo(
					mapCoords(coords));
			case 16:
				var arc = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$ClockwiseArc(
					function () {
						var y = arc.j4;
						var x = arc.jW;
						var startAngle = arc.bn;
						var radius = arc.bh;
						var endAngle = arc.a8;
						return _Utils_update(
							arc,
							{
								a8: fn(endAngle),
								bh: fn(radius),
								bn: fn(startAngle),
								jW: fn(x),
								j4: fn(y)
							});
					}());
			case 17:
				var arc = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$AntiClockwiseArc(
					function () {
						var y = arc.j4;
						var x = arc.jW;
						var startAngle = arc.bn;
						var radius = arc.bh;
						var endAngle = arc.a8;
						return _Utils_update(
							arc,
							{
								a8: fn(endAngle),
								bh: fn(radius),
								bn: fn(startAngle),
								jW: fn(x),
								j4: fn(y)
							});
					}());
			default:
				return $mdgriffith$elm_style_animation$Animation$Model$Close;
		}
	});
var $mdgriffith$elm_style_animation$Animation$Model$mapToMotion = F2(
	function (fn, prop) {
		switch (prop.$) {
			case 0:
				var name = prop.a;
				var value = prop.b;
				return A2($mdgriffith$elm_style_animation$Animation$Model$ExactProperty, name, value);
			case 1:
				var name = prop.a;
				var m1 = prop.b;
				var m2 = prop.c;
				var m3 = prop.d;
				var m4 = prop.e;
				return A5(
					$mdgriffith$elm_style_animation$Animation$Model$ColorProperty,
					name,
					fn(m1),
					fn(m2),
					fn(m3),
					fn(m4));
			case 2:
				var name = prop.a;
				var inset = prop.b;
				var shadow = prop.c;
				var size = shadow.fg;
				var red = shadow.cT;
				var offsetY = shadow._;
				var offsetX = shadow.Z;
				var green = shadow.cu;
				var blur = shadow.U;
				var blue = shadow.b9;
				var alpha = shadow.aJ;
				return A3(
					$mdgriffith$elm_style_animation$Animation$Model$ShadowProperty,
					name,
					inset,
					{
						aJ: fn(alpha),
						b9: fn(blue),
						U: fn(blur),
						cu: fn(green),
						Z: fn(offsetX),
						_: fn(offsetY),
						cT: fn(red),
						fg: fn(size)
					});
			case 3:
				var name = prop.a;
				var m1 = prop.b;
				return A2(
					$mdgriffith$elm_style_animation$Animation$Model$Property,
					name,
					fn(m1));
			case 4:
				var name = prop.a;
				var m1 = prop.b;
				var m2 = prop.c;
				return A3(
					$mdgriffith$elm_style_animation$Animation$Model$Property2,
					name,
					fn(m1),
					fn(m2));
			case 5:
				var name = prop.a;
				var m1 = prop.b;
				var m2 = prop.c;
				var m3 = prop.d;
				return A4(
					$mdgriffith$elm_style_animation$Animation$Model$Property3,
					name,
					fn(m1),
					fn(m2),
					fn(m3));
			case 6:
				var name = prop.a;
				var m1 = prop.b;
				var m2 = prop.c;
				var m3 = prop.d;
				var m4 = prop.e;
				return A5(
					$mdgriffith$elm_style_animation$Animation$Model$Property4,
					name,
					fn(m1),
					fn(m2),
					fn(m3),
					fn(m4));
			case 7:
				var name = prop.a;
				var m1 = prop.b;
				return A2(
					$mdgriffith$elm_style_animation$Animation$Model$AngleProperty,
					name,
					fn(m1));
			case 8:
				var ms = prop.a;
				return $mdgriffith$elm_style_animation$Animation$Model$Points(
					A2(
						$elm$core$List$map,
						function (_v1) {
							var x = _v1.a;
							var y = _v1.b;
							return _Utils_Tuple2(
								fn(x),
								fn(y));
						},
						ms));
			default:
				var cmds = prop.a;
				return $mdgriffith$elm_style_animation$Animation$Model$Path(
					A2(
						$elm$core$List$map,
						$mdgriffith$elm_style_animation$Animation$Model$mapPathMotion(fn),
						cmds));
		}
	});
var $mdgriffith$elm_style_animation$Animation$Render$dropWhile = F2(
	function (predicate, list) {
		dropWhile:
		while (true) {
			if (!list.b) {
				return _List_Nil;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					var $temp$predicate = predicate,
						$temp$list = xs;
					predicate = $temp$predicate;
					list = $temp$list;
					continue dropWhile;
				} else {
					return list;
				}
			}
		}
	});
var $mdgriffith$elm_style_animation$Animation$Render$takeWhile = function (predicate) {
	var takeWhileMemo = F2(
		function (memo, list) {
			takeWhileMemo:
			while (true) {
				if (!list.b) {
					return $elm$core$List$reverse(memo);
				} else {
					var x = list.a;
					var xs = list.b;
					if (predicate(x)) {
						var $temp$memo = A2($elm$core$List$cons, x, memo),
							$temp$list = xs;
						memo = $temp$memo;
						list = $temp$list;
						continue takeWhileMemo;
					} else {
						return $elm$core$List$reverse(memo);
					}
				}
			}
		});
	return takeWhileMemo(_List_Nil);
};
var $mdgriffith$elm_style_animation$Animation$Render$span = F2(
	function (p, xs) {
		return _Utils_Tuple2(
			A2($mdgriffith$elm_style_animation$Animation$Render$takeWhile, p, xs),
			A2($mdgriffith$elm_style_animation$Animation$Render$dropWhile, p, xs));
	});
var $mdgriffith$elm_style_animation$Animation$Render$groupWhile = F2(
	function (eq, xs_) {
		if (!xs_.b) {
			return _List_Nil;
		} else {
			var x = xs_.a;
			var xs = xs_.b;
			var _v1 = A2(
				$mdgriffith$elm_style_animation$Animation$Render$span,
				eq(x),
				xs);
			var ys = _v1.a;
			var zs = _v1.b;
			return A2(
				$elm$core$List$cons,
				A2($elm$core$List$cons, x, ys),
				A2($mdgriffith$elm_style_animation$Animation$Render$groupWhile, eq, zs));
		}
	});
var $mdgriffith$elm_style_animation$Animation$Model$propertyName = function (prop) {
	switch (prop.$) {
		case 0:
			var name = prop.a;
			return name;
		case 1:
			var name = prop.a;
			return name;
		case 2:
			var name = prop.a;
			return name;
		case 3:
			var name = prop.a;
			return name;
		case 4:
			var name = prop.a;
			return name;
		case 5:
			var name = prop.a;
			return name;
		case 6:
			var name = prop.a;
			return name;
		case 7:
			var name = prop.a;
			return name;
		case 8:
			return 'points';
		default:
			return 'path';
	}
};
var $mdgriffith$elm_style_animation$Animation$Render$isTransformation = function (prop) {
	return A2(
		$elm$core$List$member,
		$mdgriffith$elm_style_animation$Animation$Model$propertyName(prop),
		_List_fromArray(
			['rotate', 'rotateX', 'rotateY', 'rotateZ', 'rotate3d', 'translate', 'translate3d', 'scale', 'scale3d']));
};
var $elm$core$List$sortBy = _List_sortBy;
var $elm$core$List$sort = function (xs) {
	return A2($elm$core$List$sortBy, $elm$core$Basics$identity, xs);
};
var $mdgriffith$elm_style_animation$Animation$Render$warnForDoubleListedProperties = function (props) {
	var _v0 = A2(
		$elm$core$List$map,
		function (propGroup) {
			var _v1 = $elm$core$List$head(propGroup);
			if (_v1.$ === 1) {
				return '';
			} else {
				var name = _v1.a;
				return ($elm$core$List$length(propGroup) > 1) ? '' : '';
			}
		},
		A2(
			$mdgriffith$elm_style_animation$Animation$Render$groupWhile,
			$elm$core$Basics$eq,
			$elm$core$List$sort(
				A2(
					$elm$core$List$map,
					$mdgriffith$elm_style_animation$Animation$Model$propertyName,
					A2(
						$elm$core$List$filter,
						function (prop) {
							return !$mdgriffith$elm_style_animation$Animation$Render$isTransformation(prop);
						},
						props)))));
	return props;
};
var $mdgriffith$elm_style_animation$Animation$styleWith = F2(
	function (interp, props) {
		return $mdgriffith$elm_style_animation$Animation$initialState(
			A2(
				$elm$core$List$map,
				$mdgriffith$elm_style_animation$Animation$Model$mapToMotion(
					function (m) {
						return _Utils_update(
							m,
							{aR: interp});
					}),
				$mdgriffith$elm_style_animation$Animation$Render$warnForDoubleListedProperties(props)));
	});
var $author$project$Animations$initialAnimationState = function (properties) {
	return {
		cZ: 0,
		aE: A2(
			$mdgriffith$elm_style_animation$Animation$styleWith,
			$mdgriffith$elm_style_animation$Animation$spring(
				{gK: 20, i8: 400}),
			properties)
	};
};
var $mdgriffith$elm_style_animation$Animation$initMotion = F2(
	function (position, unit) {
		return {
			aR: $mdgriffith$elm_style_animation$Animation$Model$Spring(
				{gK: 26, i8: 170}),
			ho: $elm$core$Maybe$Nothing,
			il: position,
			jq: position,
			jB: unit,
			jG: 0
		};
	});
var $mdgriffith$elm_style_animation$Animation$custom = F3(
	function (name, value, unit) {
		return A2(
			$mdgriffith$elm_style_animation$Animation$Model$Property,
			name,
			A2($mdgriffith$elm_style_animation$Animation$initMotion, value, unit));
	});
var $mdgriffith$elm_style_animation$Animation$scale = function (valX) {
	return A3($mdgriffith$elm_style_animation$Animation$custom, 'scale', valX, '');
};
var $author$project$Animations$init = {
	R: $author$project$Animations$initialAnimationState(
		_List_fromArray(
			[
				$mdgriffith$elm_style_animation$Animation$scale(1.0)
			]))
};
var $author$project$HealthChecks$NoMediaAccessError = 0;
var $author$project$HealthChecks$TTS$SpeechPool = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$HealthChecks$TTS$init = function (currentUrl) {
	return {
		k: A2($author$project$HealthChecks$TTS$SpeechPool, currentUrl, $elm$core$Dict$empty),
		jm: false
	};
};
var $author$project$HealthChecks$init = function (currentUrl) {
	return {
		bE: 0,
		jA: $author$project$HealthChecks$TTS$init(currentUrl)
	};
};
var $author$project$RandomNumberGenerator$initialSeeds = function (seed) {
	return {
		iU: $elm$random$Random$initialSeed(seed),
		iV: $elm$random$Random$initialSeed(seed << 2),
		iW: $elm$random$Random$initialSeed(seed >> 3),
		iX: $elm$random$Random$initialSeed(seed >>> 4)
	};
};
var $author$project$Models$YoutubeVideoPlayer$YoutubeStatus = $elm$core$Basics$identity;
var $author$project$Models$YoutubeVideoPlayer$YoutubeStatusUnknown = {$: 0};
var $author$project$Models$YoutubeVideoPlayer$initialYoutubeStatus = $author$project$Models$YoutubeVideoPlayer$YoutubeStatusUnknown;
var $elm$core$Basics$round = _Basics_round;
var $author$project$Model$init = F7(
	function (randomSeed, appConfiguration, storedAppTimer, localStorage, route, urlQueryString, navigation) {
		var randomSeeds = $author$project$RandomNumberGenerator$initialSeeds(
			$elm$time$Time$posixToMillis(appConfiguration.bv) + $elm$core$Basics$round(randomSeed * 1000000));
		return {
			fZ: $krisajenkins$remotedata$RemoteData$NotAsked,
			df: false,
			f1: $author$project$Models$ActivityWindowState$default,
			dm: $author$project$Animations$init,
			f7: appConfiguration,
			f9: false,
			ga: $author$project$HealthChecks$init(
				$author$project$Routes$routeToUrlString(route)),
			dn: A2($justinmimbs$date$Date$fromPosix, appConfiguration.fu, appConfiguration.bv),
			$7: storedAppTimer,
			go: $krisajenkins$remotedata$RemoteData$NotAsked,
			cl: false,
			bC: _List_Nil,
			d1: false,
			d4: _List_Nil,
			hq: false,
			hw: appConfiguration.bv,
			hz: localStorage,
			hC: false,
			hJ: 0,
			hM: $author$project$Models$Modal$Closed,
			hR: navigation,
			cF: false,
			hV: route,
			h1: 0,
			cR: randomSeeds,
			bW: $elm$core$Maybe$Nothing,
			eY: route,
			i$: $author$project$RandomNumberGenerator$uuid(randomSeeds),
			fB: false,
			jE: urlQueryString,
			c6: $author$project$StudentUsageTracker$Model$init,
			jF: $author$project$Models$User$NotYetKnown,
			jI: $author$project$Models$VideoPlayer$NotStarted($elm$core$Maybe$Nothing),
			d: $author$project$VideoRecording$RecordingProcess$NotStarted,
			fS: $author$project$Models$WindowFocus$WindowHasFocus,
			jT: $elm$core$Maybe$Nothing,
			aI: $author$project$Models$YoutubeVideoPlayer$initialYoutubeStatus
		};
	});
var $author$project$Helpers$NavigationHelper$loadUrl = F2(
	function (navigation, path) {
		return navigation.bH(path);
	});
var $author$project$AuthPersistence$loginInfoFromIosHeader = function (tokenString) {
	return {fX: tokenString, hB: $author$project$Models$LoggingIn$IosAppLogin};
};
var $author$project$Messages$AutoLoginCredentialRetrieved = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Api$LoginApi$redeemAutoLoginToken = F4(
	function (userId, autoLoginSource, _v0, appConfig) {
		var tokenString = _v0;
		var body = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'login_token',
					$elm$json$Json$Encode$string(tokenString))
				]));
		return $author$project$Api$RequestHelper$send(
			{
				gs: $elm$http$Http$jsonBody(body),
				gN: $elm$core$Maybe$Nothing,
				dU: A2(
					$elm$http$Http$expectJson,
					$author$project$Messages$AutoLoginCredentialRetrieved(userId),
					$author$project$Models$User$loginInfoDecoder(
						$author$project$Models$LoggingIn$Autologin(autoLoginSource))),
				d3: _List_Nil,
				ex: 'PUT',
				jx: $elm$core$Maybe$Nothing,
				fE: appConfig.fa + ('/api/v5/teachers/view_as_student/' + ($author$project$Models$Ids$toString(userId) + '/redeem')),
				fT: false
			});
	});
var $author$project$Ports$requestStoredUserCredentials = _Platform_outgoingPort(
	'requestStoredUserCredentials',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Models$LoggingIn$autoLoggingIn = F3(
	function (token, source, state) {
		return _Utils_update(
			state,
			{
				b8: $elm$core$Maybe$Just(token),
				hB: $author$project$Models$LoggingIn$Autologin(source)
			});
	});
var $author$project$Model$setAutoLogin = F3(
	function (autoLoginToken, autoLoginSource, model) {
		var signedOutModel = $author$project$Model$resetUserState(model);
		return _Utils_update(
			signedOutModel,
			{
				jF: $author$project$Models$User$NotLoggedIn(
					A3($author$project$Models$LoggingIn$autoLoggingIn, autoLoginToken, autoLoginSource, $author$project$Models$LoggingIn$init))
			});
	});
var $author$project$StoredAppTimer$StoredAppTimer = F5(
	function (active, studentActivityId, timerStartedAt, currentTime, timedMessages) {
		return {ar: active, cf: currentTime, G: studentActivityId, aF: timedMessages, bq: timerStartedAt};
	});
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$StoredAppTimer$storedAppTimerDecoder = function () {
	var decodeTimedMessage = A3(
		$elm$json$Json$Decode$map2,
		$elm$core$Tuple$pair,
		A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$float),
		A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$string));
	return A6(
		$elm$json$Json$Decode$map5,
		$author$project$StoredAppTimer$StoredAppTimer,
		A2($elm$json$Json$Decode$field, 'active', $elm$json$Json$Decode$bool),
		A2($elm$json$Json$Decode$field, 'studentActivityId', $author$project$Models$Ids$decoder),
		A2($elm$json$Json$Decode$field, 'timerStartedAt', $elm$json$Json$Decode$float),
		A2($elm$json$Json$Decode$field, 'currentTime', $elm$json$Json$Decode$float),
		A2(
			$elm$json$Json$Decode$field,
			'timedMessages',
			$elm$json$Json$Decode$list(decodeTimedMessage)));
}();
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Messages$ValidateStudentIdentityResponse = F2(
	function (a, b) {
		return {$: 19, a: a, b: b};
	});
var $author$project$Api$LoginApi$validate = F2(
	function (loginInfo, appConfig) {
		return $author$project$Api$RequestHelper$send(
			A2(
				$author$project$Api$AuthedRequest$get,
				'/api/v5/students/me',
				A5(
					$author$project$Api$RequestHelper$createConfig,
					$author$project$Messages$ValidateStudentIdentityResponse(loginInfo),
					loginInfo,
					appConfig,
					0,
					$author$project$Models$Student$decoder)));
	});
var $author$project$Init$validateResumeUrl = F3(
	function (resumeSetAt, currentTime, decodedResumeURL) {
		return (_Utils_cmp(currentTime - resumeSetAt, (30 * 60) * 1000) < 0) ? A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Result$Err('no decoded resume URL'),
			A2($elm$core$Maybe$map, $elm$core$Result$Ok, decodedResumeURL)) : $elm$core$Result$Err(
			'timer too old: ' + ($elm$core$String$fromFloat(currentTime - resumeSetAt) + ' ms'));
	});
var $author$project$Init$determineInitialState = F3(
	function (rawAppConfiguration, url, navigation) {
		var storedAppTimer = A2(
			$elm$core$Maybe$andThen,
			$elm$core$Result$toMaybe,
			A2(
				$elm$core$Maybe$map,
				$elm$json$Json$Decode$decodeString($author$project$StoredAppTimer$storedAppTimerDecoder),
				rawAppConfiguration.i9));
		var localStorage = A2(
			$elm$core$Result$withDefault,
			$author$project$Models$LocalStorage$default,
			A2($elm$json$Json$Decode$decodeString, $author$project$Models$LocalStorage$decoder, rawAppConfiguration.hz));
		var currentRoute = $author$project$Routes$parseUrl(url);
		var catchStuckStudentsCommand = A2($author$project$Helpers$Task$performWithDelay, 5000, $author$project$Messages$CheckIfStudentIsStillUnknown);
		var appConfiguration = $author$project$AppConfiguration$importRawAppConfiguration(rawAppConfiguration);
		var appTokenCommand = function () {
			var _v4 = rawAppConfiguration.gW;
			if (!_v4.$) {
				var accessToken = _v4.a;
				return A2(
					$author$project$Api$LoginApi$validate,
					$author$project$AuthPersistence$loginInfoFromIosHeader(accessToken),
					appConfiguration);
			} else {
				return $author$project$Ports$requestStoredUserCredentials(0);
			}
		}();
		var model = A7(
			$author$project$Model$init,
			rawAppConfiguration.ix,
			appConfiguration,
			storedAppTimer,
			localStorage,
			currentRoute,
			A2($elm$core$Maybe$withDefault, '', url.is),
			navigation);
		var resumeURLCommandIfPresent = function () {
			if (currentRoute.$ === 4) {
				var resumeSetAt = currentRoute.a;
				var _v3 = A3($author$project$Init$validateResumeUrl, resumeSetAt, rawAppConfiguration.bv, rawAppConfiguration.gM);
				if (!_v3.$) {
					var resumeURL = _v3.a;
					return A3($author$project$Helpers$NavigationHelper$updateUrl, navigation, appConfiguration, resumeURL);
				} else {
					return A3(
						$author$project$Helpers$NavigationHelper$updateUrl,
						navigation,
						appConfiguration,
						$author$project$Routes$routeToUrlString($author$project$Routes$homeRoute));
				}
			} else {
				return $elm$core$Platform$Cmd$none;
			}
		}();
		var initialCommands = _List_fromArray(
			[
				resumeURLCommandIfPresent,
				$author$project$Ports$disableReturnNotification(0)
			]);
		var initialState = function () {
			_v0$2:
			while (true) {
				switch (currentRoute.$) {
					case 1:
						if (currentRoute.a.$ === 2) {
							var _v1 = currentRoute.a;
							var userId = _v1.a;
							var autoLoginToken = _v1.b;
							var source = _v1.d;
							return _Utils_Tuple2(
								A3($author$project$Model$setAutoLogin, autoLoginToken, source, model),
								$elm$core$Platform$Cmd$batch(
									A2(
										$elm$core$List$cons,
										A4($author$project$Api$LoginApi$redeemAutoLoginToken, userId, source, autoLoginToken, model.f7),
										initialCommands)));
						} else {
							break _v0$2;
						}
					case 12:
						var code = currentRoute.a;
						return _Utils_Tuple2(
							model,
							A2(
								$author$project$Helpers$NavigationHelper$loadUrl,
								navigation,
								A2($author$project$SSOLogin$googleRedirectUrl, code, appConfiguration)));
					default:
						break _v0$2;
				}
			}
			return _Utils_Tuple2(
				model,
				$elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$cons,
						appTokenCommand,
						A2($elm$core$List$cons, catchStuckStudentsCommand, initialCommands))));
		}();
		return A2($author$project$Routing$Updates$forRouteNotLoggedIn, model.eY, initialState);
	});
var $author$project$Main$init = F3(
	function (rawAppConfiguration, url, key) {
		var _v0 = A3(
			$author$project$Init$determineInitialState,
			rawAppConfiguration,
			url,
			$author$project$Helpers$NavigationHelper$constructNavigation(key));
		var model = _v0.a;
		var effects = _v0.b;
		var disabledEsparkIosAppInitEffect = model.f7.ee ? A2(
			$author$project$Effect$PerformLaterEffect,
			200,
			$author$project$Messages$PlayAllAutoplaySpeech($elm$core$Maybe$Nothing)) : $author$project$Effect$NoEffect;
		return A2(
			$author$project$Effect$Resolution$convertToCmd,
			model.hR,
			_Utils_Tuple2(
				model,
				$author$project$Effect$batch(
					_List_fromArray(
						[effects, disabledEsparkIosAppInitEffect]))));
	});
var $author$project$LoggedIn$Messages$AccessoriesUpdate = function (a) {
	return {$: 93, a: a};
};
var $author$project$LoggedIn$Messages$ActivityErrored = function (a) {
	return {$: 37, a: a};
};
var $author$project$Messages$AddWebActivityInitiatedFaviconIfOnWebActivity = function (a) {
	return {$: 71, a: a};
};
var $author$project$Messages$AnimationMessage = function (a) {
	return {$: 54, a: a};
};
var $author$project$LoggedIn$Messages$ClassLibraryMsg = function (a) {
	return {$: 99, a: a};
};
var $author$project$Messages$ComponentRemoved = 2;
var $author$project$Messages$EmbeddedYoutubeVideoPaused = {$: 82};
var $author$project$Messages$EmbeddedYoutubeVideoTimeUpdate = function (a) {
	return {$: 81, a: a};
};
var $author$project$LoggedIn$Messages$EsparkIframeCompleteActivity = function (a) {
	return {$: 90, a: a};
};
var $author$project$Messages$EsparkIframeNavigateToActivity = function (a) {
	return {$: 87, a: a};
};
var $author$project$Messages$ExternalWindowOpened = function (a) {
	return {$: 32, a: a};
};
var $author$project$Messages$ExternalWindowSuccessfullyClosed = function (a) {
	return {$: 36, a: a};
};
var $author$project$Messages$GetCurrentTime = function (a) {
	return {$: 31, a: a};
};
var $author$project$Messages$IosBrowserError = {$: 37};
var $author$project$Messages$IosBrowserTimeout = {$: 38};
var $author$project$LoggedIn$Messages$IosCompleteStudentVideo = {$: 69};
var $author$project$Learnosity$Messages$LearnosityAnswersStateChanged = function (a) {
	return {$: 1, a: a};
};
var $author$project$Learnosity$Messages$LearnosityAssessmentSubmittedV2 = {$: 2};
var $author$project$Learnosity$Messages$LearnosityErrorOnResubmission = {$: 3};
var $author$project$Messages$LogIncomingPortDecodeFailure = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Messages$LoginAppCleanup = 1;
var $author$project$Messages$MarkYoutubeVideoAsRestricted = F2(
	function (a, b) {
		return {$: 77, a: a, b: b};
	});
var $author$project$Messages$MediaCapturePermissionsUpdate = function (a) {
	return {$: 66, a: a};
};
var $author$project$Messages$NotificationPermissionsUpdate = function (a) {
	return {$: 65, a: a};
};
var $author$project$LoggedIn$Messages$PLEIsAvailable = function (a) {
	return {$: 92, a: a};
};
var $author$project$Messages$PlayTTSUrl = function (a) {
	return {$: 60, a: a};
};
var $author$project$LoginApp$Messages$QRCodeReadError = function (a) {
	return {$: 9, a: a};
};
var $author$project$LoginApp$Messages$QRCodeReadSuccess = function (a) {
	return {$: 8, a: a};
};
var $author$project$LoginApp$Messages$QRCodeScannerStarted = {$: 12};
var $author$project$Messages$QuizCleanup = 0;
var $author$project$LoggedIn$Messages$ReturnNotificationSent = function (a) {
	return {$: 7, a: a};
};
var $author$project$Messages$RouteCleanupComplete = function (a) {
	return {$: 27, a: a};
};
var $author$project$Messages$StartUpdatingEsparkIframe = function (a) {
	return {$: 86, a: a};
};
var $author$project$LoggedIn$Messages$StoredStudentVideoCouldNotBeRetrieved = function (a) {
	return {$: 86, a: a};
};
var $author$project$LoggedIn$Messages$StoredStudentVideoNotFound = function (a) {
	return {$: 85, a: a};
};
var $author$project$LoggedIn$Messages$StoredStudentVideoPersisted = function (a) {
	return {$: 79, a: a};
};
var $author$project$LoggedIn$Messages$StoredStudentVideoRemoved = function (a) {
	return {$: 84, a: a};
};
var $author$project$LoggedIn$Messages$StoredStudentVideoUploadComplete = function (a) {
	return {$: 82, a: a};
};
var $author$project$LoggedIn$Messages$StoredStudentVideoUploadFailed = F2(
	function (a, b) {
		return {$: 83, a: a, b: b};
	});
var $author$project$LoggedIn$Messages$StoredStudentVideosIdentified = function (a) {
	return {$: 80, a: a};
};
var $author$project$Messages$StoredUserCredentialsRetrieved = function (a) {
	return {$: 18, a: a};
};
var $author$project$LoggedIn$Messages$StudentReturnedViaNotification = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$LoggedIn$Messages$StudentUsageTrackerMsg = function (a) {
	return {$: 88, a: a};
};
var $author$project$Messages$TTSHealthCheckMsg = function (a) {
	return {$: 63, a: a};
};
var $author$project$Messages$TTSSpeakingUpdate = function (a) {
	return {$: 61, a: a};
};
var $author$project$Messages$TTSTrackTimeFromStartToPlay = function (a) {
	return {$: 62, a: a};
};
var $author$project$Messages$TrackTimeToYoutubeQualityMetric = function (a) {
	return {$: 79, a: a};
};
var $author$project$Messages$UserLoggedOut = {$: 22};
var $author$project$LoggedIn$Messages$VideoActivityPlaybackViaEmbeddedYoutubePlayerEnded = function (a) {
	return {$: 32, a: a};
};
var $author$project$Models$YoutubeVideoPlayer$VideoDataFromYoutube = 1;
var $author$project$Messages$VideoPlaybackErrored = function (a) {
	return {$: 52, a: a};
};
var $author$project$LoggedIn$Messages$VideoRecordingStatusUpdate = function (a) {
	return {$: 56, a: a};
};
var $author$project$LoggedIn$Messages$VideoRecordingStatusUpdateDecodeError = F2(
	function (a, b) {
		return {$: 57, a: a, b: b};
	});
var $author$project$LoggedIn$Messages$VideoRecordingUploadCompleted = {$: 64};
var $author$project$LoggedIn$Messages$VideoRecordingUploadFailed = function (a) {
	return {$: 65, a: a};
};
var $author$project$Messages$VideoTimeUpdate = function (a) {
	return {$: 44, a: a};
};
var $author$project$Messages$WindowFocusChanged = F2(
	function (a, b) {
		return {$: 67, a: a, b: b};
	});
var $author$project$Messages$WindowInfoLoaded = function (a) {
	return {$: 11, a: a};
};
var $author$project$Messages$WindowLostFocus = F2(
	function (a, b) {
		return {$: 68, a: a, b: b};
	});
var $author$project$Messages$WindowRegainedFocus = F2(
	function (a, b) {
		return {$: 69, a: a, b: b};
	});
var $author$project$Messages$YoutubeAvailable = {$: 74};
var $author$project$Messages$YoutubeUnavailable = {$: 75};
var $author$project$Ports$accessoriesUpdate = _Platform_incomingPort('accessoriesUpdate', $elm$json$Json$Decode$value);
var $author$project$Ports$activateEsparkIframe = _Platform_incomingPort('activateEsparkIframe', $elm$json$Json$Decode$string);
var $author$project$LoggedIn$Messages$ChoiceTextActivityMsg = function (a) {
	return {$: 47, a: a};
};
var $author$project$LoggedIn$Messages$EbookActivityMsg = function (a) {
	return {$: 46, a: a};
};
var $author$project$LoggedIn$Messages$ReceivedWindowMessage = function (a) {
	return {$: 49, a: a};
};
var $author$project$Helpers$Ports$decodeIncomingPort = F4(
	function (onError, portName, decoder, value) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, decoder, value);
		if (!_v0.$) {
			var decodedMsg = _v0.a;
			return decodedMsg;
		} else {
			var failureMsg = _v0.a;
			return A2(onError, portName, failureMsg);
		}
	});
var $author$project$Models$WindowPostedMessage$ConstructGameMessageData = F3(
	function (webGlMissing, webAssemblyMissing, jsModulesMissing) {
		return {cz: jsModulesMissing, db: webAssemblyMissing, dc: webGlMissing};
	});
var $author$project$Models$WindowPostedMessage$constructGameMessageDataDecoder = A2(
	$elm$json$Json$Decode$field,
	'data',
	A4(
		$elm$json$Json$Decode$map3,
		$author$project$Models$WindowPostedMessage$ConstructGameMessageData,
		A2($elm$json$Json$Decode$field, 'webGlMissing', $elm$json$Json$Decode$bool),
		A2($elm$json$Json$Decode$field, 'webAssemblyMissing', $elm$json$Json$Decode$bool),
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$withDefault(false),
			A2(
				$elm$json$Json$Decode$field,
				'jsModulesMissing',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool)))));
var $author$project$Models$WindowPostedMessage$constructGameOrigin = 'https?:\\/\\/webapps[a-z_-]*\\.esparklearning\\.com(\\/|$)';
var $author$project$Models$WindowPostedMessage$matchesRegex = function (regexMatcher) {
	return $elm$regex$Regex$contains(
		A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			$elm$regex$Regex$fromString(regexMatcher)));
};
var $author$project$Models$WindowPostedMessage$originErrorMessage = 'received origin did not match any expected: ';
var $author$project$Models$WindowPostedMessage$ConstructReportDoesNotMatchCurrentActivity = {$: 2};
var $author$project$Models$WindowPostedMessage$IframedConstructGameMessage = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Models$WindowPostedMessage$SeparateWindowConstructGameMessage = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Models$WindowPostedMessage$webAppOriginDecoder = function (data) {
	var transformToWindowPostedMessage = F2(
		function (fromIframe, studentActivityId) {
			return fromIframe ? A2($author$project$Models$WindowPostedMessage$IframedConstructGameMessage, studentActivityId, data) : A2($author$project$Models$WindowPostedMessage$SeparateWindowConstructGameMessage, studentActivityId, data);
		});
	var decodeAndValidateStudentActivityIds = A2(
		$elm$json$Json$Decode$andThen,
		function (_v0) {
			var javascriptId = _v0.a;
			var constructId = _v0.b;
			return _Utils_eq(javascriptId, constructId) ? $elm$json$Json$Decode$succeed(javascriptId) : $elm$json$Json$Decode$fail('studentActivityId from Construct does not match current activity');
		},
		A3(
			$elm$json$Json$Decode$map2,
			$elm$core$Tuple$pair,
			A2($elm$json$Json$Decode$field, 'studentActivityId', $author$project$Models$Ids$decoder),
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['data', 'studentActivityId']),
				$author$project$Models$Ids$decoder)));
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A3(
				$elm$json$Json$Decode$map2,
				transformToWindowPostedMessage,
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['data', 'fromIframe']),
					$elm$json$Json$Decode$bool),
				decodeAndValidateStudentActivityIds),
				$elm$json$Json$Decode$succeed($author$project$Models$WindowPostedMessage$ConstructReportDoesNotMatchCurrentActivity)
			]));
};
var $author$project$Models$WindowPostedMessage$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (origin) {
		return A2($author$project$Models$WindowPostedMessage$matchesRegex, $author$project$Models$WindowPostedMessage$constructGameOrigin, origin) ? A2($elm$json$Json$Decode$andThen, $author$project$Models$WindowPostedMessage$webAppOriginDecoder, $author$project$Models$WindowPostedMessage$constructGameMessageDataDecoder) : $elm$json$Json$Decode$fail(
			_Utils_ap($author$project$Models$WindowPostedMessage$originErrorMessage, origin));
	},
	A2($elm$json$Json$Decode$field, 'origin', $elm$json$Json$Decode$string));
var $author$project$Models$WindowPostedMessage$logPortDecodeFailureIfRelevant = F4(
	function (messageForIgnoring, messageForLogging, logString, error) {
		if (error.$ === 3) {
			var errorString = error.a;
			return A2($elm$core$String$contains, $author$project$Models$WindowPostedMessage$originErrorMessage, errorString) ? messageForIgnoring : A2(messageForLogging, logString, error);
		} else {
			return A2(messageForLogging, logString, error);
		}
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Ports$processWebActivityWindowMessage = _Platform_incomingPort('processWebActivityWindowMessage', $elm$json$Json$Decode$value);
var $author$project$Activities$ChoiceText$BackToQuestPage = {$: 4};
var $author$project$Activities$ChoiceText$ChoiceTextAvailable = function (a) {
	return {$: 1, a: a};
};
var $author$project$Activities$ChoiceText$ChoiceTextCompleted = {$: 2};
var $author$project$Activities$ChoiceText$ChoiceTextErrored = function (a) {
	return {$: 8, a: a};
};
var $author$project$Activities$ChoiceText$ChoiceWritingErrored = function (a) {
	return {$: 7, a: a};
};
var $author$project$Activities$ChoiceText$PopUpBottomBar = {$: 6};
var $author$project$Activities$ChoiceText$backToQuestPage = _Platform_incomingPort(
	'backToQuestPage',
	$elm$json$Json$Decode$null(0));
var $author$project$Activities$ChoiceText$choiceTextComplete = _Platform_incomingPort(
	'choiceTextComplete',
	$elm$json$Json$Decode$null(0));
var $author$project$Activities$ChoiceText$choiceTextErrored = _Platform_incomingPort('choiceTextErrored', $elm$json$Json$Decode$string);
var $author$project$Activities$ChoiceText$choiceWritingErrored = _Platform_incomingPort('choiceWritingErrored', $elm$json$Json$Decode$string);
var $author$project$Activities$ChoiceText$popUpBottomBar = _Platform_incomingPort(
	'popUpBottomBar',
	$elm$json$Json$Decode$null(0));
var $author$project$Activities$ChoiceText$trackChoiceTextAvailable = _Platform_incomingPort('trackChoiceTextAvailable', $elm$json$Json$Decode$string);
var $author$project$Activities$ChoiceText$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Activities$ChoiceText$trackChoiceTextAvailable($author$project$Activities$ChoiceText$ChoiceTextAvailable),
			$author$project$Activities$ChoiceText$choiceTextComplete(
			$elm$core$Basics$always($author$project$Activities$ChoiceText$ChoiceTextCompleted)),
			$author$project$Activities$ChoiceText$popUpBottomBar(
			$elm$core$Basics$always($author$project$Activities$ChoiceText$PopUpBottomBar)),
			$author$project$Activities$ChoiceText$choiceWritingErrored($author$project$Activities$ChoiceText$ChoiceWritingErrored),
			$author$project$Activities$ChoiceText$choiceTextErrored($author$project$Activities$ChoiceText$ChoiceTextErrored),
			$author$project$Activities$ChoiceText$backToQuestPage(
			$elm$core$Basics$always($author$project$Activities$ChoiceText$BackToQuestPage))
		]));
var $author$project$Activities$Ebook$DisplayChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Activities$Ebook$PageChanged = function (a) {
	return {$: 5, a: a};
};
var $author$project$Activities$Ebook$PageErrored = function (a) {
	return {$: 7, a: a};
};
var $author$project$Activities$Ebook$PageHasRendered = function (a) {
	return {$: 0, a: a};
};
var $author$project$Activities$Ebook$displayChanged = _Platform_incomingPort(
	'displayChanged',
	A2(
		$elm$json$Json$Decode$andThen,
		function (studentActivityId) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (numberOfPagesDisplayed) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (newSectionIndex) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (isLastPage) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (isFirstPage) {
											return $elm$json$Json$Decode$succeed(
												{ad: isFirstPage, ae: isLastPage, aB: newSectionIndex, C: numberOfPagesDisplayed, G: studentActivityId});
										},
										A2($elm$json$Json$Decode$field, 'isFirstPage', $elm$json$Json$Decode$bool));
								},
								A2($elm$json$Json$Decode$field, 'isLastPage', $elm$json$Json$Decode$bool));
						},
						A2($elm$json$Json$Decode$field, 'newSectionIndex', $elm$json$Json$Decode$int));
				},
				A2($elm$json$Json$Decode$field, 'numberOfPagesDisplayed', $elm$json$Json$Decode$int));
		},
		A2($elm$json$Json$Decode$field, 'studentActivityId', $elm$json$Json$Decode$string)));
var $author$project$Activities$Ebook$pageChanged = _Platform_incomingPort(
	'pageChanged',
	A2(
		$elm$json$Json$Decode$andThen,
		function (studentActivityId) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (numberOfPagesDisplayed) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (newSectionIndex) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (isLastPage) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (isFirstPage) {
											return $elm$json$Json$Decode$succeed(
												{ad: isFirstPage, ae: isLastPage, aB: newSectionIndex, C: numberOfPagesDisplayed, G: studentActivityId});
										},
										A2($elm$json$Json$Decode$field, 'isFirstPage', $elm$json$Json$Decode$bool));
								},
								A2($elm$json$Json$Decode$field, 'isLastPage', $elm$json$Json$Decode$bool));
						},
						A2($elm$json$Json$Decode$field, 'newSectionIndex', $elm$json$Json$Decode$int));
				},
				A2($elm$json$Json$Decode$field, 'numberOfPagesDisplayed', $elm$json$Json$Decode$int));
		},
		A2($elm$json$Json$Decode$field, 'studentActivityId', $elm$json$Json$Decode$string)));
var $author$project$Activities$Ebook$pageErrored = _Platform_incomingPort(
	'pageErrored',
	A2(
		$elm$json$Json$Decode$andThen,
		function (studentActivityId) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (numberOfPagesDisplayed) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (newSectionIndex) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (isLastPage) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (isFirstPage) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (isCriticalError) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (error) {
															return $elm$json$Json$Decode$succeed(
																{a9: error, bc: isCriticalError, ad: isFirstPage, ae: isLastPage, aB: newSectionIndex, C: numberOfPagesDisplayed, G: studentActivityId});
														},
														A2($elm$json$Json$Decode$field, 'error', $elm$json$Json$Decode$string));
												},
												A2($elm$json$Json$Decode$field, 'isCriticalError', $elm$json$Json$Decode$bool));
										},
										A2($elm$json$Json$Decode$field, 'isFirstPage', $elm$json$Json$Decode$bool));
								},
								A2($elm$json$Json$Decode$field, 'isLastPage', $elm$json$Json$Decode$bool));
						},
						A2($elm$json$Json$Decode$field, 'newSectionIndex', $elm$json$Json$Decode$int));
				},
				A2($elm$json$Json$Decode$field, 'numberOfPagesDisplayed', $elm$json$Json$Decode$int));
		},
		A2($elm$json$Json$Decode$field, 'studentActivityId', $elm$json$Json$Decode$string)));
var $author$project$Activities$Ebook$pageHasRendered = _Platform_incomingPort('pageHasRendered', $elm$json$Json$Decode$string);
var $author$project$Activities$Ebook$parseErrorData = function (_v0) {
	var newSectionIndex = _v0.aB;
	var isFirstPage = _v0.ad;
	var isLastPage = _v0.ae;
	var studentActivityId = _v0.G;
	var numberOfPagesDisplayed = _v0.C;
	var error = _v0.a9;
	var isCriticalError = _v0.bc;
	return {
		a9: error,
		bc: isCriticalError,
		ad: isFirstPage,
		ae: isLastPage,
		C: numberOfPagesDisplayed,
		eH: newSectionIndex,
		G: $author$project$Models$Ids$fromString(studentActivityId)
	};
};
var $author$project$Activities$Ebook$Model$FirstPage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Activities$Ebook$Model$LastPage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Activities$Ebook$Model$MiddlePage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Activities$Ebook$parsePageUpdateData = function (_v0) {
	var newSectionIndex = _v0.aB;
	var isFirstPage = _v0.ad;
	var isLastPage = _v0.ae;
	var studentActivityId = _v0.G;
	var numberOfPagesDisplayed = _v0.C;
	return {
		C: numberOfPagesDisplayed,
		ai: isFirstPage ? $author$project$Activities$Ebook$Model$FirstPage(newSectionIndex) : (isLastPage ? $author$project$Activities$Ebook$Model$LastPage(newSectionIndex) : $author$project$Activities$Ebook$Model$MiddlePage(newSectionIndex)),
		G: $author$project$Models$Ids$fromString(studentActivityId)
	};
};
var $author$project$Activities$Ebook$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Activities$Ebook$pageHasRendered(
			A2($elm$core$Basics$composeR, $author$project$Models$Ids$fromString, $author$project$Activities$Ebook$PageHasRendered)),
			$author$project$Activities$Ebook$pageChanged(
			A2($elm$core$Basics$composeR, $author$project$Activities$Ebook$parsePageUpdateData, $author$project$Activities$Ebook$PageChanged)),
			$author$project$Activities$Ebook$displayChanged(
			A2($elm$core$Basics$composeR, $author$project$Activities$Ebook$parsePageUpdateData, $author$project$Activities$Ebook$DisplayChanged)),
			$author$project$Activities$Ebook$pageErrored(
			A2($elm$core$Basics$composeR, $author$project$Activities$Ebook$parseErrorData, $author$project$Activities$Ebook$PageErrored))
		]));
var $author$project$Main$activityPracticeSubscriptions = function (model) {
	var _v0 = A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.f_;
			},
			function ($) {
				return $.f0;
			}),
		$author$project$Model$studentActivityForCurrentRoute(model));
	if (!_v0.$) {
		switch (_v0.a.$) {
			case 2:
				return A2(
					$elm$core$Platform$Sub$map,
					A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$EbookActivityMsg, $author$project$Messages$LoggedInRequiredMsg),
					$author$project$Activities$Ebook$subscriptions);
			case 3:
				var _v1 = _v0.a;
				return A2(
					$elm$core$Platform$Sub$map,
					A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$ChoiceTextActivityMsg, $author$project$Messages$LoggedInRequiredMsg),
					$author$project$Activities$ChoiceText$subscriptions);
			case 4:
				var _v2 = _v0.a;
				return A2(
					$elm$core$Platform$Sub$map,
					A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$ChoiceTextActivityMsg, $author$project$Messages$LoggedInRequiredMsg),
					$author$project$Activities$ChoiceText$subscriptions);
			case 5:
				var _v3 = _v0.a;
				return A2(
					$elm$core$Platform$Sub$map,
					A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$ChoiceTextActivityMsg, $author$project$Messages$LoggedInRequiredMsg),
					$author$project$Activities$ChoiceText$subscriptions);
			case 0:
				var _v4 = _v0.a;
				return $elm$core$Platform$Sub$none;
			case 1:
				return $author$project$Ports$processWebActivityWindowMessage(
					A3(
						$author$project$Helpers$Ports$decodeIncomingPort,
						A2($author$project$Models$WindowPostedMessage$logPortDecodeFailureIfRelevant, $author$project$Messages$NoOp, $author$project$Messages$LogIncomingPortDecodeFailure),
						'processWebActivityWindowMessage',
						A2(
							$elm$json$Json$Decode$map,
							A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$ReceivedWindowMessage, $author$project$Messages$LoggedInRequiredMsg),
							$author$project$Models$WindowPostedMessage$decoder)));
			case 6:
				var _v5 = _v0.a;
				return $elm$core$Platform$Sub$none;
			case 7:
				var _v6 = _v0.a;
				return $elm$core$Platform$Sub$none;
			default:
				var _v7 = _v0.a;
				return $elm$core$Platform$Sub$none;
		}
	} else {
		return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Ports$addWebActivityInitiatedFaviconIfOnWebActivity = _Platform_incomingPort('addWebActivityInitiatedFaviconIfOnWebActivity', $elm$json$Json$Decode$bool);
var $author$project$Ports$componentEvent = _Platform_incomingPort('componentEvent', $elm$json$Json$Decode$value);
var $author$project$LoggedIn$Messages$ComponentCompleted = F2(
	function (a, b) {
		return {$: 100, a: a, b: b};
	});
var $author$project$LoggedIn$Messages$ComponentErrored = F4(
	function (a, b, c, d) {
		return {$: 101, a: a, b: b, c: c, d: d};
	});
var $author$project$Main$componentEventDecoder = function () {
	var componentTypeDecoderStrict = function () {
		var decodeProductVersionString = function (str) {
			var _v1 = $author$project$Models$Activity$componentTypeFromString(str);
			if (!_v1.$) {
				if (!_v1.a) {
					var _v2 = _v1.a;
					return $elm$json$Json$Decode$succeed(0);
				} else {
					var _v3 = _v1.a;
					return $elm$json$Json$Decode$succeed(1);
				}
			} else {
				return $elm$json$Json$Decode$fail(str + ' is not a valid ComponentType');
			}
		};
		return A2($elm$json$Json$Decode$andThen, decodeProductVersionString, $elm$json$Json$Decode$string);
	}();
	return A2(
		$elm$json$Json$Decode$andThen,
		function (eventType) {
			switch (eventType) {
				case 'AssessmentCompleted':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Messages$LoggedInRequiredMsg,
						A3(
							$elm$json$Json$Decode$map2,
							$author$project$LoggedIn$Messages$ComponentCompleted,
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['componentType']),
								componentTypeDecoderStrict),
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['studentActivityId']),
								$author$project$Models$Ids$decoder)));
				case 'EvaluateError':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Messages$LoggedInRequiredMsg,
						A5(
							$elm$json$Json$Decode$map4,
							$author$project$LoggedIn$Messages$ComponentErrored,
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['componentType']),
								componentTypeDecoderStrict),
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['studentActivityId']),
								$author$project$Models$Ids$decoder),
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['eventMessage']),
								$elm$json$Json$Decode$string),
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['eventData']),
								$elm$json$Json$Decode$value)));
				default:
					return $elm$json$Json$Decode$fail(eventType + ' is not a valid ComponentEvent');
			}
		},
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['eventType']),
			$elm$json$Json$Decode$string));
}();
var $author$project$Ports$componentRemoved = _Platform_incomingPort(
	'componentRemoved',
	$elm$json$Json$Decode$null(0));
var $author$project$Models$BrowserNotification$Content = F5(
	function (title, body, icon, image, studentActivityId) {
		return {gs: body, hg: icon, d7: image, G: studentActivityId, fx: title};
	});
var $author$project$Models$BrowserNotification$decodeContent = function (contentJSON) {
	var decoder = A6(
		$elm$json$Json$Decode$map5,
		$author$project$Models$BrowserNotification$Content,
		A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
		A2(
			$elm$json$Json$Decode$field,
			'body',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
		A2(
			$elm$json$Json$Decode$field,
			'icon',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
		A2(
			$elm$json$Json$Decode$field,
			'image',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
		A2($elm$json$Json$Decode$field, 'studentActivityId', $author$project$Models$Ids$decoder));
	return $elm$core$Result$toMaybe(
		A2($elm$json$Json$Decode$decodeString, decoder, contentJSON));
};
var $author$project$Ports$embeddedYoutubePlayerPlaybackEnded = _Platform_incomingPort('embeddedYoutubePlayerPlaybackEnded', $elm$json$Json$Decode$value);
var $author$project$Ports$embeddedYoutubePlayerPlaybackPaused = _Platform_incomingPort('embeddedYoutubePlayerPlaybackPaused', $elm$json$Json$Decode$value);
var $author$project$Ports$embeddedYoutubeVideoTimeUpdate = _Platform_incomingPort('embeddedYoutubeVideoTimeUpdate', $elm$json$Json$Decode$value);
var $author$project$Ports$esparkIframeCompleteActivity = _Platform_incomingPort('esparkIframeCompleteActivity', $elm$json$Json$Decode$value);
var $author$project$Ports$esparkIframeNavigateToActivity = _Platform_incomingPort('esparkIframeNavigateToActivity', $elm$json$Json$Decode$value);
var $author$project$Ports$exitQuizComplete = _Platform_incomingPort(
	'exitQuizComplete',
	$elm$json$Json$Decode$null(0));
var $author$project$Ports$externalWindowClosed = _Platform_incomingPort('externalWindowClosed', $elm$json$Json$Decode$string);
var $author$project$Ports$externalWindowOpened = _Platform_incomingPort('externalWindowOpened', $elm$json$Json$Decode$string);
var $author$project$Ports$handleActivityError = _Platform_incomingPort('handleActivityError', $elm$json$Json$Decode$value);
var $author$project$Ports$iosBrowserError = _Platform_incomingPort(
	'iosBrowserError',
	$elm$json$Json$Decode$null(0));
var $author$project$Ports$iosBrowserTimeout = _Platform_incomingPort(
	'iosBrowserTimeout',
	$elm$json$Json$Decode$null(0));
var $author$project$Ports$iosVideoRecordingComplete = _Platform_incomingPort(
	'iosVideoRecordingComplete',
	$elm$json$Json$Decode$null(0));
var $author$project$Ports$learnosityAnswerChosenV2 = _Platform_incomingPort('learnosityAnswerChosenV2', $elm$json$Json$Decode$value);
var $author$project$Ports$learnosityAnswersStateChanged = _Platform_incomingPort('learnosityAnswersStateChanged', $elm$json$Json$Decode$value);
var $author$project$Ports$learnosityAssessmentSubmittedV2 = _Platform_incomingPort(
	'learnosityAssessmentSubmittedV2',
	$elm$json$Json$Decode$null(0));
var $author$project$Ports$learnosityErrorOnResubmissionV2 = _Platform_incomingPort(
	'learnosityErrorOnResubmissionV2',
	$elm$json$Json$Decode$null(0));
var $author$project$Models$ItemScore$ItemInfo = F8(
	function (ref, active, responseId, time, questionType, featureTypes, rationale, stimulus) {
		return {ar: active, aP: featureTypes, aV: questionType, bi: rationale, iA: ref, aj: responseId, aZ: stimulus, a$: time};
	});
var $author$project$Models$ItemScore$NotAnswered = 0;
var $author$project$Models$ItemScore$NotValidated = 1;
var $author$project$Models$ItemScore$ResponseInfo = F4(
	function (responseId, revision, validationStatus, value) {
		return {aj: responseId, iI: revision, b2: validationStatus, b3: value};
	});
var $author$project$Models$ItemScore$TagInfo = F2(
	function (ref, tags) {
		return {iA: ref, jp: tags};
	});
var $author$project$Models$ItemScore$ValidationStateNotAnswered = 0;
var $author$project$Models$ItemScore$infoTransform = F4(
	function (scores, items, responses, tags) {
		var tagForRef = function (ref) {
			return A2(
				$elm$core$Maybe$withDefault,
				A2($author$project$Models$ItemScore$TagInfo, '', _List_Nil),
				A2(
					$elm_community$list_extra$List$Extra$find,
					function (tag) {
						return _Utils_eq(
							ref,
							$elm$core$Maybe$Just(tag.iA));
					},
					tags));
		};
		var responseForRef = function (maybeResponseId) {
			return A2(
				$elm$core$Maybe$withDefault,
				A4($author$project$Models$ItemScore$ResponseInfo, '', 0, 0, $elm$core$Maybe$Nothing),
				A2(
					$elm_community$list_extra$List$Extra$find,
					function (response) {
						return _Utils_eq(
							maybeResponseId,
							$elm$core$Maybe$Just(response.aj));
					},
					responses));
		};
		var itemForRef = function (ref) {
			return A2(
				$elm$core$Maybe$withDefault,
				A8($author$project$Models$ItemScore$ItemInfo, '', false, $elm$core$Maybe$Nothing, 0, '', _List_Nil, $elm$core$Maybe$Nothing, ''),
				A2(
					$elm_community$list_extra$List$Extra$find,
					function (item) {
						return _Utils_eq(item.iA, ref);
					},
					items));
		};
		var constructItemScore = function (score) {
			var item = itemForRef(score.iA);
			var response = responseForRef(item.aj);
			var scoreStatus = function () {
				var _v0 = response.b2;
				switch (_v0) {
					case 0:
						return 0;
					case 1:
						return 1;
					default:
						return _Utils_eq(
							score.e4,
							$elm$core$Maybe$Just(score.eu)) ? 3 : 2;
				}
			}();
			var tag = tagForRef(
				$elm$core$Maybe$Just(item.iA));
			return $author$project$Models$ItemScore$ItemScore(score.e4)(score.eu)(item.ar)(response.iI)(scoreStatus)(score.iA)(item.a$)(item.aj)(response.b3)(item.aV)(item.aP)(item.bi)(item.aZ)(tag.jp);
		};
		return A2($elm$core$List$map, constructItemScore, scores);
	});
var $author$project$Models$ItemScore$emptyStringToNothing = function (maybeString) {
	return _Utils_eq(
		maybeString,
		$elm$core$Maybe$Just('')) ? $elm$core$Maybe$Nothing : maybeString;
};
var $elm$json$Json$Decode$map7 = _Json_map7;
var $author$project$Models$ItemScore$itemListDecoder = function () {
	var itemInfoDecoder = A8(
		$elm$json$Json$Decode$map7,
		F7(
			function (active, responseId, time, questionType, featureTypes, rationale, stimulus) {
				return {
					ar: A2($elm$core$Maybe$withDefault, false, active),
					aP: featureTypes,
					aV: questionType,
					bi: rationale,
					aj: responseId,
					aZ: stimulus,
					a$: time
				};
			}),
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'active', $elm$json$Json$Decode$bool)),
		A2(
			$elm$json$Json$Decode$field,
			'response_ids',
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$List$head,
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string))),
		A2($elm$json$Json$Decode$field, 'time', $elm$json$Json$Decode$int),
		A2(
			$elm$json$Json$Decode$field,
			'questions',
			A2(
				$elm$json$Json$Decode$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$head,
					$elm$core$Maybe$withDefault('unknown')),
				$elm$json$Json$Decode$list(
					A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string)))),
		A2(
			$elm$json$Json$Decode$field,
			'features',
			$elm$json$Json$Decode$list(
				A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string))),
		A2(
			$elm$json$Json$Decode$field,
			'questions',
			A2(
				$elm$json$Json$Decode$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$head,
					$elm$core$Maybe$andThen($author$project$Models$ItemScore$emptyStringToNothing)),
				$elm$json$Json$Decode$list(
					$elm$json$Json$Decode$maybe(
						A2(
							$elm$json$Json$Decode$at,
							_List_fromArray(
								['metadata', 'distractor_rationale']),
							$elm$json$Json$Decode$string))))),
		A2(
			$elm$json$Json$Decode$field,
			'questions',
			A2(
				$elm$json$Json$Decode$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$head,
					$elm$core$Maybe$withDefault('unknown')),
				$elm$json$Json$Decode$list(
					A2($elm$json$Json$Decode$field, 'stimulus', $elm$json$Json$Decode$string)))));
	return A2(
		$elm$json$Json$Decode$map,
		function (keyValues) {
			return A2(
				$elm$core$List$map,
				function (_v0) {
					var ref = _v0.a;
					var data = _v0.b;
					return A8($author$project$Models$ItemScore$ItemInfo, ref, data.ar, data.aj, data.a$, data.aV, data.aP, data.bi, data.aZ);
				},
				keyValues);
		},
		$elm$json$Json$Decode$keyValuePairs(itemInfoDecoder));
}();
var $author$project$Models$ItemScore$ValidationStateNotValidated = 1;
var $author$project$Models$ItemScore$ValidationStateValidated = 2;
var $author$project$Models$ItemScore$validationStatusFromString = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map(
		function (status) {
			switch (status) {
				case 'notvalidated':
					return 1;
				case 'validated':
					return 2;
				case 'unanswered':
					return 0;
				default:
					return 0;
			}
		}),
	$elm$core$Maybe$withDefault(0));
var $author$project$Models$ItemScore$responseListDecoder = function () {
	var valueDecoder = $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$head,
					$elm$core$Maybe$withDefault($elm$core$Maybe$Nothing)),
				$elm$json$Json$Decode$list(
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string))),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
	var responseInfoDecoder = A4(
		$elm$json$Json$Decode$map3,
		F3(
			function (revision, validationStatus, value) {
				return {iI: revision, b2: validationStatus, b3: value};
			}),
		A2($elm$json$Json$Decode$field, 'revision', $elm$json$Json$Decode$int),
		A2(
			$elm$json$Json$Decode$map,
			$author$project$Models$ItemScore$validationStatusFromString,
			$elm$json$Json$Decode$maybe(
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['metadata', 'validationState']),
					$elm$json$Json$Decode$string))),
		A2($elm$json$Json$Decode$field, 'value', valueDecoder));
	return A2(
		$elm$json$Json$Decode$map,
		function (keyValues) {
			return A2(
				$elm$core$List$map,
				function (_v0) {
					var responseId = _v0.a;
					var data = _v0.b;
					return A4($author$project$Models$ItemScore$ResponseInfo, responseId, data.iI, data.b2, data.b3);
				},
				keyValues);
		},
		$elm$json$Json$Decode$keyValuePairs(responseInfoDecoder));
}();
var $author$project$Models$ItemScore$ScoreInfo = F3(
	function (ref, score, maxScore) {
		return {eu: maxScore, iA: ref, e4: score};
	});
var $author$project$Models$ItemScore$scoreListDecoder = function () {
	var itemScoreInfoDecoder = A3(
		$elm$json$Json$Decode$map2,
		F2(
			function (score, maxScore) {
				return {eu: maxScore, e4: score};
			}),
		A2(
			$elm$json$Json$Decode$field,
			'score',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
		A2(
			$elm$json$Json$Decode$field,
			'max_score',
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$null(1)
					]))));
	return A2(
		$elm$json$Json$Decode$map,
		function (keyValues) {
			return A2(
				$elm$core$List$map,
				function (_v0) {
					var ref = _v0.a;
					var data = _v0.b;
					return A3($author$project$Models$ItemScore$ScoreInfo, ref, data.e4, data.eu);
				},
				keyValues);
		},
		$elm$json$Json$Decode$keyValuePairs(itemScoreInfoDecoder));
}();
var $author$project$Models$ItemScore$tagListDecoder = A2(
	$elm$json$Json$Decode$map,
	function (keyValues) {
		return A2(
			$elm$core$List$map,
			function (_v0) {
				var ref = _v0.a;
				var data = _v0.b;
				return A2($author$project$Models$ItemScore$TagInfo, ref, data);
			},
			keyValues);
	},
	$elm$json$Json$Decode$keyValuePairs(
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
var $author$project$Models$ItemScore$learnosityStateDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Models$ItemScore$infoTransform,
	A2($elm$json$Json$Decode$field, 'scores', $author$project$Models$ItemScore$scoreListDecoder),
	A2($elm$json$Json$Decode$field, 'items', $author$project$Models$ItemScore$itemListDecoder),
	A2($elm$json$Json$Decode$field, 'responses', $author$project$Models$ItemScore$responseListDecoder),
	A2($elm$json$Json$Decode$field, 'tags', $author$project$Models$ItemScore$tagListDecoder));
var $author$project$Models$Modal$mapOpenModal = F2(
	function (fn, modalState) {
		if (!modalState.$) {
			var modalIdentifier = modalState.a;
			return $elm$core$Maybe$Just(
				fn(modalIdentifier));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Ports$markYoutubeVideoAsRestrictedByData = _Platform_incomingPort('markYoutubeVideoAsRestrictedByData', $elm$json$Json$Decode$value);
var $author$project$Ports$mediaCapturePermissionUpdate = _Platform_incomingPort('mediaCapturePermissionUpdate', $elm$json$Json$Decode$string);
var $author$project$LoggedIn$Messages$VideoFeedbackMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$AvatarCustomizationModal$subscriptions = $elm$core$Platform$Sub$none;
var $author$project$VideoFeedback$LogIncomingPortDecodeFailure = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$VideoFeedback$VideoTimeUpdate = function (a) {
	return {$: 11, a: a};
};
var $author$project$Ports$videoTimeUpdate = _Platform_incomingPort('videoTimeUpdate', $elm$json$Json$Decode$value);
var $author$project$Models$VideoPlayer$PlayerTime = F2(
	function (currentTime, duration) {
		return {cf: currentTime, dQ: duration};
	});
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $author$project$Helpers$DecodingHelper$decodeFloatWithDefault = function (defaultValue) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (floatValue) {
			return $elm$json$Json$Decode$succeed(
				($elm$core$Basics$isNaN(floatValue) || $elm$core$Basics$isInfinite(floatValue)) ? defaultValue : floatValue);
		},
		$elm$json$Json$Decode$float);
};
var $author$project$Models$VideoPlayer$playerTimeDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Models$VideoPlayer$PlayerTime,
	A2(
		$elm$json$Json$Decode$field,
		'currentTime',
		$author$project$Helpers$DecodingHelper$decodeFloatWithDefault($author$project$Models$VideoPlayer$defaultPlayerTime.cf)),
	A2(
		$elm$json$Json$Decode$field,
		'duration',
		$author$project$Helpers$DecodingHelper$decodeFloatWithDefault($author$project$Models$VideoPlayer$defaultPlayerTime.dQ)));
var $author$project$Helpers$VideoPlayback$videoTimeUpdateDecoder = F3(
	function (portName, failureMsg, successMsg) {
		return A3(
			$author$project$Helpers$Ports$decodeIncomingPort,
			failureMsg,
			portName,
			A2(
				$elm$json$Json$Decode$map,
				successMsg,
				A3(
					$elm$json$Json$Decode$map2,
					$elm$core$Tuple$pair,
					A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$string),
					A2(
						$elm$json$Json$Decode$map,
						$elm$core$Maybe$Just,
						A2($elm$json$Json$Decode$index, 1, $author$project$Models$VideoPlayer$playerTimeDecoder)))));
	});
var $author$project$VideoFeedback$subscriptions = function (model) {
	return $author$project$Ports$videoTimeUpdate(
		A3($author$project$Helpers$VideoPlayback$videoTimeUpdateDecoder, 'videoTimeUpdate', $author$project$VideoFeedback$LogIncomingPortDecodeFailure, $author$project$VideoFeedback$VideoTimeUpdate));
};
var $author$project$Main$modalSubscriptions = function (modalIdentifier) {
	switch (modalIdentifier.$) {
		case 7:
			return $elm$core$Platform$Sub$none;
		case 8:
			return $elm$core$Platform$Sub$none;
		case 9:
			return $elm$core$Platform$Sub$none;
		case 0:
			return $elm$core$Platform$Sub$none;
		case 1:
			return $elm$core$Platform$Sub$none;
		case 10:
			return $author$project$AvatarCustomizationModal$subscriptions;
		case 2:
			return $elm$core$Platform$Sub$none;
		case 3:
			return $elm$core$Platform$Sub$none;
		case 4:
			return $elm$core$Platform$Sub$none;
		case 5:
			return $elm$core$Platform$Sub$none;
		default:
			var videoFeedbackModel = modalIdentifier.a;
			return A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$VideoFeedbackMsg, $author$project$Messages$LoggedInRequiredMsg),
				$author$project$VideoFeedback$subscriptions(videoFeedbackModel));
	}
};
var $author$project$Ports$notificationPermissionUpdate = _Platform_incomingPort('notificationPermissionUpdate', $elm$json$Json$Decode$string);
var $author$project$Models$Permission$Denied = 2;
var $author$project$Models$Permission$Granted = 3;
var $author$project$Models$Permission$NeitherGrantedNorDenied = 4;
var $author$project$Models$Permission$Prompting = 1;
var $author$project$Models$Permission$parsePermission = function (newPermissionString) {
	switch (newPermissionString) {
		case 'prompt':
			return 1;
		case 'denied':
			return 2;
		case 'granted':
			return 3;
		case 'default':
			return 4;
		default:
			return 4;
	}
};
var $author$project$Ports$playTTSUrl = _Platform_incomingPort('playTTSUrl', $elm$json$Json$Decode$string);
var $author$project$Ports$portsInitialized = _Platform_incomingPort(
	'portsInitialized',
	$elm$json$Json$Decode$null(0));
var $author$project$Ports$qrCodeReadError = _Platform_incomingPort('qrCodeReadError', $elm$json$Json$Decode$string);
var $author$project$Ports$qrCodeReadSuccess = _Platform_incomingPort('qrCodeReadSuccess', $elm$json$Json$Decode$string);
var $author$project$Ports$qrCodeReaderStarted = _Platform_incomingPort('qrCodeReaderStarted', $elm$json$Json$Decode$value);
var $author$project$Ports$qrCodeReaderStopped = _Platform_incomingPort('qrCodeReaderStopped', $elm$json$Json$Decode$value);
var $author$project$Ports$returnNotificationSent = _Platform_incomingPort('returnNotificationSent', $elm$json$Json$Decode$string);
var $author$project$VideoRecording$RecordingProcess$CameraNotAvailableStatusUpdate = {$: 1};
var $author$project$VideoRecording$RecordingProcess$ErroredStatusUpdate = function (a) {
	return {$: 2, a: a};
};
var $author$project$VideoRecording$RecordingProcess$NotStartedStatusUpdate = {$: 0};
var $author$project$VideoRecording$RecordingProcess$ReadyStatusUpdate = function (a) {
	return {$: 3, a: a};
};
var $author$project$VideoRecording$RecordingProcess$RecordedStatusUpdate = function (a) {
	return {$: 5, a: a};
};
var $author$project$VideoRecording$RecordingProcess$RecordingStatusUpdate = function (a) {
	return {$: 4, a: a};
};
var $author$project$VideoRecording$RecordingProcess$statusUpdateDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (status) {
		switch (status) {
			case 'loading':
				return $elm$json$Json$Decode$succeed($author$project$VideoRecording$RecordingProcess$NotStartedStatusUpdate);
			case 'camera-not-available':
				return $elm$json$Json$Decode$succeed($author$project$VideoRecording$RecordingProcess$CameraNotAvailableStatusUpdate);
			case 'ready':
				return $elm$json$Json$Decode$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$json$Json$Decode$map,
							$author$project$VideoRecording$RecordingProcess$ReadyStatusUpdate,
							A2(
								$elm$json$Json$Decode$field,
								'value',
								A2($elm$json$Json$Decode$field, 'numberOfCameras', $elm$json$Json$Decode$int))),
							$elm$json$Json$Decode$fail('Ready state missing numberOfCameras value')
						]));
			case 'recording':
				return $elm$json$Json$Decode$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$json$Json$Decode$map,
							$author$project$VideoRecording$RecordingProcess$RecordingStatusUpdate,
							A2(
								$elm$json$Json$Decode$field,
								'value',
								A2($elm$json$Json$Decode$field, 'recordingDuration', $elm$json$Json$Decode$float))),
							$elm$json$Json$Decode$fail('Recording state missing recordingDuration value')
						]));
			case 'recorded':
				return $elm$json$Json$Decode$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$json$Json$Decode$map,
							$author$project$VideoRecording$RecordingProcess$RecordedStatusUpdate,
							A2(
								$elm$json$Json$Decode$field,
								'value',
								A2($elm$json$Json$Decode$field, 'dataUrl', $author$project$Models$Urls$decoder))),
							$elm$json$Json$Decode$fail('Recorded state missing url value')
						]));
			case 'errored':
				return $elm$json$Json$Decode$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$json$Json$Decode$map,
							$author$project$VideoRecording$RecordingProcess$ErroredStatusUpdate,
							A2(
								$elm$json$Json$Decode$field,
								'value',
								A2($elm$json$Json$Decode$field, 'error', $elm$json$Json$Decode$string))),
							$elm$json$Json$Decode$fail('Errored without message')
						]));
			case 'destroyed':
				return $elm$json$Json$Decode$succeed($author$project$VideoRecording$RecordingProcess$NotStartedStatusUpdate);
			default:
				var unknown = status;
				return $elm$json$Json$Decode$fail('did not expect status: ' + unknown);
		}
	},
	A2($elm$json$Json$Decode$field, 'status', $elm$json$Json$Decode$string));
var $author$project$Ports$storedStudentVideoCouldNotBeRetrieved = _Platform_incomingPort('storedStudentVideoCouldNotBeRetrieved', $elm$json$Json$Decode$value);
var $author$project$Ports$storedStudentVideoNotFound = _Platform_incomingPort('storedStudentVideoNotFound', $elm$json$Json$Decode$value);
var $author$project$Ports$storedStudentVideoPersisted = _Platform_incomingPort('storedStudentVideoPersisted', $elm$json$Json$Decode$value);
var $author$project$Ports$storedStudentVideoRemoved = _Platform_incomingPort('storedStudentVideoRemoved', $elm$json$Json$Decode$value);
var $author$project$Ports$storedStudentVideoUploadComplete = _Platform_incomingPort('storedStudentVideoUploadComplete', $elm$json$Json$Decode$value);
var $author$project$Ports$storedStudentVideoUploadFailed = _Platform_incomingPort(
	'storedStudentVideoUploadFailed',
	A2(
		$elm$json$Json$Decode$andThen,
		function (_v0) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (_v1) {
					return $elm$json$Json$Decode$succeed(
						_Utils_Tuple2(_v0, _v1));
				},
				A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$value)));
var $author$project$Ports$storedStudentVideosIdentified = _Platform_incomingPort('storedStudentVideosIdentified', $elm$json$Json$Decode$value);
var $author$project$Ports$storedUserCredentialsRetrieved = _Platform_incomingPort(
	'storedUserCredentialsRetrieved',
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
			])));
var $author$project$Ports$studentReturnedViaNotification = _Platform_incomingPort(
	'studentReturnedViaNotification',
	A2(
		$elm$json$Json$Decode$andThen,
		function (_v0) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (_v1) {
					return $elm$json$Json$Decode$succeed(
						_Utils_Tuple2(_v0, _v1));
				},
				A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$int));
		},
		A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$string)));
var $author$project$Animations$Messages$Animate = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$Tick = $elm$core$Basics$identity;
var $mdgriffith$elm_style_animation$Animation$isRunning = function (_v0) {
	var model = _v0;
	return model.bY;
};
var $elm$browser$Browser$AnimationManager$Time = function (a) {
	return {$: 0, a: a};
};
var $elm$browser$Browser$AnimationManager$State = F3(
	function (subs, request, oldTime) {
		return {cJ: oldTime, eV: request, fo: subs};
	});
var $elm$browser$Browser$AnimationManager$init = $elm$core$Task$succeed(
	A3($elm$browser$Browser$AnimationManager$State, _List_Nil, $elm$core$Maybe$Nothing, 0));
var $elm$browser$Browser$AnimationManager$now = _Browser_now(0);
var $elm$browser$Browser$AnimationManager$rAF = _Browser_rAF(0);
var $elm$browser$Browser$AnimationManager$onEffects = F3(
	function (router, subs, _v0) {
		var request = _v0.eV;
		var oldTime = _v0.cJ;
		var _v1 = _Utils_Tuple2(request, subs);
		if (_v1.a.$ === 1) {
			if (!_v1.b.b) {
				var _v2 = _v1.a;
				return $elm$browser$Browser$AnimationManager$init;
			} else {
				var _v4 = _v1.a;
				return A2(
					$elm$core$Task$andThen,
					function (pid) {
						return A2(
							$elm$core$Task$andThen,
							function (time) {
								return $elm$core$Task$succeed(
									A3(
										$elm$browser$Browser$AnimationManager$State,
										subs,
										$elm$core$Maybe$Just(pid),
										time));
							},
							$elm$browser$Browser$AnimationManager$now);
					},
					$elm$core$Process$spawn(
						A2(
							$elm$core$Task$andThen,
							$elm$core$Platform$sendToSelf(router),
							$elm$browser$Browser$AnimationManager$rAF)));
			}
		} else {
			if (!_v1.b.b) {
				var pid = _v1.a.a;
				return A2(
					$elm$core$Task$andThen,
					function (_v3) {
						return $elm$browser$Browser$AnimationManager$init;
					},
					$elm$core$Process$kill(pid));
			} else {
				return $elm$core$Task$succeed(
					A3($elm$browser$Browser$AnimationManager$State, subs, request, oldTime));
			}
		}
	});
var $elm$browser$Browser$AnimationManager$onSelfMsg = F3(
	function (router, newTime, _v0) {
		var subs = _v0.fo;
		var oldTime = _v0.cJ;
		var send = function (sub) {
			if (!sub.$) {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(
						$elm$time$Time$millisToPosix(newTime)));
			} else {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(newTime - oldTime));
			}
		};
		return A2(
			$elm$core$Task$andThen,
			function (pid) {
				return A2(
					$elm$core$Task$andThen,
					function (_v1) {
						return $elm$core$Task$succeed(
							A3(
								$elm$browser$Browser$AnimationManager$State,
								subs,
								$elm$core$Maybe$Just(pid),
								newTime));
					},
					$elm$core$Task$sequence(
						A2($elm$core$List$map, send, subs)));
			},
			$elm$core$Process$spawn(
				A2(
					$elm$core$Task$andThen,
					$elm$core$Platform$sendToSelf(router),
					$elm$browser$Browser$AnimationManager$rAF)));
	});
var $elm$browser$Browser$AnimationManager$Delta = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$AnimationManager$subMap = F2(
	function (func, sub) {
		if (!sub.$) {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Time(
				A2($elm$core$Basics$composeL, func, tagger));
		} else {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Delta(
				A2($elm$core$Basics$composeL, func, tagger));
		}
	});
_Platform_effectManagers['Browser.AnimationManager'] = _Platform_createManager($elm$browser$Browser$AnimationManager$init, $elm$browser$Browser$AnimationManager$onEffects, $elm$browser$Browser$AnimationManager$onSelfMsg, 0, $elm$browser$Browser$AnimationManager$subMap);
var $elm$browser$Browser$AnimationManager$subscription = _Platform_leaf('Browser.AnimationManager');
var $elm$browser$Browser$AnimationManager$onAnimationFrame = function (tagger) {
	return $elm$browser$Browser$AnimationManager$subscription(
		$elm$browser$Browser$AnimationManager$Time(tagger));
};
var $elm$browser$Browser$Events$onAnimationFrame = $elm$browser$Browser$AnimationManager$onAnimationFrame;
var $mdgriffith$elm_style_animation$Animation$subscription = F2(
	function (msg, states) {
		return A2($elm$core$List$any, $mdgriffith$elm_style_animation$Animation$isRunning, states) ? A2(
			$elm$core$Platform$Sub$map,
			msg,
			$elm$browser$Browser$Events$onAnimationFrame($elm$core$Basics$identity)) : $elm$core$Platform$Sub$none;
	});
var $author$project$Animations$subscriptions = function (model) {
	return A2(
		$mdgriffith$elm_style_animation$Animation$subscription,
		$author$project$Animations$Messages$Animate,
		_List_fromArray(
			[model.R.aE]));
};
var $author$project$HealthChecks$TTS$SpeechEnded = function (a) {
	return {$: 2, a: a};
};
var $author$project$HealthChecks$TTS$SpeechErroredAfterSynthesis = function (a) {
	return {$: 6, a: a};
};
var $author$project$HealthChecks$TTS$SpeechLoadedAfterPlayError = function (a) {
	return {$: 14, a: a};
};
var $author$project$HealthChecks$TTS$SpeechPlaybackErrored = function (a) {
	return {$: 4, a: a};
};
var $author$project$HealthChecks$TTS$SpeechPlaybackErroredLocked = function (a) {
	return {$: 12, a: a};
};
var $author$project$HealthChecks$TTS$SpeechPlaybackSucceedAfterUnlock = function (a) {
	return {$: 13, a: a};
};
var $author$project$HealthChecks$TTS$SpeechPlayed = function (a) {
	return {$: 1, a: a};
};
var $author$project$HealthChecks$TTS$SpeechRequested = function (a) {
	return {$: 0, a: a};
};
var $author$project$HealthChecks$TTS$SpeechStopped = function (a) {
	return {$: 3, a: a};
};
var $author$project$HealthChecks$TTS$SpeechSynthesisErrored = function (a) {
	return {$: 5, a: a};
};
var $author$project$HealthChecks$TTS$SpeechUnlockedAfterAudioErrored = function (a) {
	return {$: 11, a: a};
};
var $author$project$HealthChecks$TTS$EventDataDecodingFailed = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$HealthChecks$TTS$EventData = F5(
	function (speechId, currentTime, duration, error, additionalData) {
		return {dj: additionalData, cf: currentTime, dQ: duration, a9: error, h: speechId};
	});
var $author$project$HealthChecks$TTS$eventDataDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$HealthChecks$TTS$EventData,
	A2($elm$json$Json$Decode$field, 'speechId', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'currentTime',
		A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int)),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'duration', $elm$json$Json$Decode$float)),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'error', $elm$json$Json$Decode$string)),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'additionalData', $elm$json$Json$Decode$value)));
var $author$project$HealthChecks$TTS$decodePortWithEventData = F2(
	function (portName, msg) {
		return A3(
			$author$project$Helpers$Ports$decodeIncomingPort,
			$author$project$HealthChecks$TTS$EventDataDecodingFailed,
			portName,
			A2($elm$json$Json$Decode$map, msg, $author$project$HealthChecks$TTS$eventDataDecoder));
	});
var $author$project$HealthChecks$TTS$ttsAudioErroredAfterSynthesis = _Platform_incomingPort('ttsAudioErroredAfterSynthesis', $elm$json$Json$Decode$value);
var $author$project$HealthChecks$TTS$ttsSpeechEnded = _Platform_incomingPort('ttsSpeechEnded', $elm$json$Json$Decode$value);
var $author$project$HealthChecks$TTS$ttsSpeechLoadedAfterPlayError = _Platform_incomingPort('ttsSpeechLoadedAfterPlayError', $elm$json$Json$Decode$value);
var $author$project$HealthChecks$TTS$ttsSpeechPlaybackErrored = _Platform_incomingPort('ttsSpeechPlaybackErrored', $elm$json$Json$Decode$value);
var $author$project$HealthChecks$TTS$ttsSpeechPlaybackErroredLocked = _Platform_incomingPort('ttsSpeechPlaybackErroredLocked', $elm$json$Json$Decode$value);
var $author$project$HealthChecks$TTS$ttsSpeechPlaybackSucceedAfterUnlock = _Platform_incomingPort('ttsSpeechPlaybackSucceedAfterUnlock', $elm$json$Json$Decode$value);
var $author$project$HealthChecks$TTS$ttsSpeechPlayed = _Platform_incomingPort('ttsSpeechPlayed', $elm$json$Json$Decode$value);
var $author$project$HealthChecks$TTS$ttsSpeechRequested = _Platform_incomingPort('ttsSpeechRequested', $elm$json$Json$Decode$value);
var $author$project$HealthChecks$TTS$ttsSpeechStopped = _Platform_incomingPort('ttsSpeechStopped', $elm$json$Json$Decode$value);
var $author$project$HealthChecks$TTS$ttsSpeechSynthesisErrored = _Platform_incomingPort('ttsSpeechSynthesisErrored', $elm$json$Json$Decode$value);
var $author$project$HealthChecks$TTS$ttsSpeechUnlockedAfterAudioErrored = _Platform_incomingPort('ttsSpeechUnlockedAfterAudioErrored', $elm$json$Json$Decode$value);
var $author$project$HealthChecks$TTS$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$HealthChecks$TTS$ttsSpeechRequested(
			A2($author$project$HealthChecks$TTS$decodePortWithEventData, 'ttsSpeechRequested', $author$project$HealthChecks$TTS$SpeechRequested)),
			$author$project$HealthChecks$TTS$ttsSpeechPlayed(
			A2($author$project$HealthChecks$TTS$decodePortWithEventData, 'ttsSpeechPlayed', $author$project$HealthChecks$TTS$SpeechPlayed)),
			$author$project$HealthChecks$TTS$ttsSpeechEnded(
			A2($author$project$HealthChecks$TTS$decodePortWithEventData, 'ttsSpeechEnded', $author$project$HealthChecks$TTS$SpeechEnded)),
			$author$project$HealthChecks$TTS$ttsSpeechStopped(
			A2($author$project$HealthChecks$TTS$decodePortWithEventData, 'ttsSpeechStopped', $author$project$HealthChecks$TTS$SpeechStopped)),
			$author$project$HealthChecks$TTS$ttsSpeechPlaybackErrored(
			A2($author$project$HealthChecks$TTS$decodePortWithEventData, 'ttsSpeechPlaybackErrored', $author$project$HealthChecks$TTS$SpeechPlaybackErrored)),
			$author$project$HealthChecks$TTS$ttsSpeechSynthesisErrored(
			A2($author$project$HealthChecks$TTS$decodePortWithEventData, 'ttsSpeechSynthesisErrored', $author$project$HealthChecks$TTS$SpeechSynthesisErrored)),
			$author$project$HealthChecks$TTS$ttsAudioErroredAfterSynthesis(
			A2($author$project$HealthChecks$TTS$decodePortWithEventData, 'ttsAudioErroredAfterSynthesis', $author$project$HealthChecks$TTS$SpeechErroredAfterSynthesis)),
			$author$project$HealthChecks$TTS$ttsSpeechUnlockedAfterAudioErrored(
			A2($author$project$HealthChecks$TTS$decodePortWithEventData, 'ttsSpeechUnlockedAfterAudioErrored', $author$project$HealthChecks$TTS$SpeechUnlockedAfterAudioErrored)),
			$author$project$HealthChecks$TTS$ttsSpeechPlaybackErroredLocked(
			A2($author$project$HealthChecks$TTS$decodePortWithEventData, 'ttsSpeechPlaybackErroredLocked', $author$project$HealthChecks$TTS$SpeechPlaybackErroredLocked)),
			$author$project$HealthChecks$TTS$ttsSpeechPlaybackSucceedAfterUnlock(
			A2($author$project$HealthChecks$TTS$decodePortWithEventData, 'ttsSpeechPlaybackSucceedAfterUnlock', $author$project$HealthChecks$TTS$SpeechPlaybackSucceedAfterUnlock)),
			$author$project$HealthChecks$TTS$ttsSpeechLoadedAfterPlayError(
			A2($author$project$HealthChecks$TTS$decodePortWithEventData, 'ttsSpeechLoadedAfterPlayError', $author$project$HealthChecks$TTS$SpeechLoadedAfterPlayError))
		]));
var $author$project$StudentUsageTracker$Messages$Track = $elm$core$Basics$identity;
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {eQ: processes, fq: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.eQ;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.fq);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$map = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var value = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(
					f(value));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			case 0:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
			default:
				var error = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(error);
		}
	});
var $krisajenkins$remotedata$RemoteData$withDefault = F2(
	function (_default, data) {
		if (data.$ === 3) {
			var x = data.a;
			return x;
		} else {
			return _default;
		}
	});
var $author$project$StudentUsageTracker$subscriptions = function (model) {
	return A2(
		$krisajenkins$remotedata$RemoteData$withDefault,
		$elm$core$Platform$Sub$none,
		A2(
			$krisajenkins$remotedata$RemoteData$map,
			$elm$core$Basics$always(
				A2($elm$time$Time$every, 30 * 1000, $elm$core$Basics$identity)),
			model.fZ));
};
var $author$project$Activities$ChoiceText$BackToSubjectChoice = {$: 3};
var $author$project$Activities$ChoiceText$backToSubjectChoice = _Platform_incomingPort(
	'backToSubjectChoice',
	$elm$json$Json$Decode$null(0));
var $author$project$Views$ClassLibrary$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Activities$ChoiceText$trackChoiceTextAvailable($author$project$Activities$ChoiceText$ChoiceTextAvailable),
			$author$project$Activities$ChoiceText$backToSubjectChoice(
			$elm$core$Basics$always($author$project$Activities$ChoiceText$BackToSubjectChoice))
		]));
var $author$project$Ports$trackPLEAvailable = _Platform_incomingPort('trackPLEAvailable', $elm$json$Json$Decode$string);
var $author$project$Ports$trackTimeFromStartToTTSPlay = _Platform_incomingPort('trackTimeFromStartToTTSPlay', $elm$json$Json$Decode$float);
var $author$project$Ports$trackTimeToYoutubeQualityMetric = _Platform_incomingPort('trackTimeToYoutubeQualityMetric', $elm$json$Json$Decode$float);
var $author$project$Ports$ttsSpeakingUpdate = _Platform_incomingPort('ttsSpeakingUpdate', $elm$json$Json$Decode$bool);
var $author$project$Ports$uploadVideoCompleted = _Platform_incomingPort('uploadVideoCompleted', $elm$json$Json$Decode$string);
var $author$project$Ports$uploadVideoFailed = _Platform_incomingPort('uploadVideoFailed', $elm$json$Json$Decode$string);
var $author$project$Ports$userSignedOut = _Platform_incomingPort(
	'userSignedOut',
	$elm$json$Json$Decode$null(0));
var $author$project$Ports$videoPlaybackErrored = _Platform_incomingPort('videoPlaybackErrored', $elm$json$Json$Decode$value);
var $author$project$Helpers$VideoPlayback$PlaybackErrorData = F3(
	function (videoKey, hasNativeVideoPlayerContainer, hasYoutubeVideoPlayer) {
		return {g8: hasNativeVideoPlayerContainer, g9: hasYoutubeVideoPlayer, fJ: videoKey};
	});
var $author$project$Helpers$VideoPlayback$videoPlaybackErroredDecoder = F3(
	function (portName, failureMsg, successMsg) {
		return A3(
			$author$project$Helpers$Ports$decodeIncomingPort,
			failureMsg,
			portName,
			A2(
				$elm$json$Json$Decode$map,
				successMsg,
				A4(
					$elm$json$Json$Decode$map3,
					$author$project$Helpers$VideoPlayback$PlaybackErrorData,
					A2($elm$json$Json$Decode$field, 'videoKey', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'hasNativeVideoPlayerContainer', $elm$json$Json$Decode$bool),
					A2($elm$json$Json$Decode$field, 'hasYoutubeVideoPlayer', $elm$json$Json$Decode$bool))));
	});
var $author$project$Ports$videoRecordingStatusUpdate = _Platform_incomingPort('videoRecordingStatusUpdate', $elm$json$Json$Decode$value);
var $author$project$Ports$windowFocusChanged = _Platform_incomingPort('windowFocusChanged', $elm$json$Json$Decode$bool);
var $author$project$Models$WindowInfo$WindowInfo = F6(
	function (height, viewportHeight, viewportWidth, scrollPosition, scrollTop, navigationWidth) {
		return {hb: height, hS: navigationWidth, iO: scrollPosition, iP: scrollTop, fN: viewportHeight, fO: viewportWidth};
	});
var $author$project$Models$WindowInfo$Bottom = 2;
var $author$project$Models$WindowInfo$Middle = 1;
var $author$project$Models$WindowInfo$Top = 0;
var $author$project$Models$WindowInfo$scrollPositionFromString = function (scrollPositionString) {
	switch (scrollPositionString) {
		case 'top':
			return 0;
		case 'bottom':
			return 2;
		case 'middle':
			return 1;
		default:
			return 1;
	}
};
var $author$project$Models$WindowInfo$scrollPositionDecoder = A2($elm$json$Json$Decode$map, $author$project$Models$WindowInfo$scrollPositionFromString, $elm$json$Json$Decode$string);
var $author$project$Models$WindowInfo$windowInfoDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Models$WindowInfo$WindowInfo,
	A2($elm$json$Json$Decode$field, 'height', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'viewportHeight', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'viewportWidth', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'scrollPosition', $author$project$Models$WindowInfo$scrollPositionDecoder),
	A2($elm$json$Json$Decode$field, 'scrollTop', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'navigationWidth', $elm$json$Json$Decode$float));
var $author$project$Ports$windowInfoLoaded = _Platform_incomingPort('windowInfoLoaded', $elm$json$Json$Decode$value);
var $author$project$Ports$windowLostFocus = _Platform_incomingPort('windowLostFocus', $elm$json$Json$Decode$bool);
var $author$project$Ports$windowRegainedFocus = _Platform_incomingPort('windowRegainedFocus', $elm$json$Json$Decode$bool);
var $author$project$Messages$LearnosityMessage = function (a) {
	return {$: 9, a: a};
};
var $author$project$Messages$wrapLearnosityMessage = F2(
	function (learnosityMsg, data) {
		return $author$project$Messages$LearnosityMessage(
			learnosityMsg(data));
	});
var $author$project$Ports$youtubeAvailable = _Platform_incomingPort('youtubeAvailable', $elm$json$Json$Decode$value);
var $author$project$Ports$youtubeUnavailable = _Platform_incomingPort('youtubeUnavailable', $elm$json$Json$Decode$value);
var $author$project$Main$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Messages$AnimationMessage,
				$author$project$Animations$subscriptions(model.dm)),
				A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$StudentUsageTrackerMsg, $author$project$Messages$LoggedInRequiredMsg),
				$author$project$StudentUsageTracker$subscriptions(model)),
				A2($elm$core$Platform$Sub$map, $author$project$Messages$TTSHealthCheckMsg, $author$project$HealthChecks$TTS$subscriptions),
				$author$project$Ports$userSignedOut(
				$elm$core$Basics$always($author$project$Messages$UserLoggedOut)),
				$author$project$Ports$storedUserCredentialsRetrieved($author$project$Messages$StoredUserCredentialsRetrieved),
				$author$project$Ports$learnosityAnswersStateChanged(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'learnosityAnswersStateChanged',
					A2(
						$elm$json$Json$Decode$map,
						$author$project$Messages$wrapLearnosityMessage($author$project$Learnosity$Messages$LearnosityAnswersStateChanged),
						$author$project$Models$ItemScore$learnosityStateDecoder))),
				$author$project$Ports$learnosityAnswerChosenV2(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'learnosityAnswerChosen',
					A2(
						$elm$json$Json$Decode$map,
						$author$project$Messages$wrapLearnosityMessage($author$project$Learnosity$Messages$LearnosityAnswersStateChanged),
						$author$project$Models$ItemScore$learnosityStateDecoder))),
				$author$project$Ports$learnosityAssessmentSubmittedV2(
				$author$project$Messages$wrapLearnosityMessage(
					$elm$core$Basics$always($author$project$Learnosity$Messages$LearnosityAssessmentSubmittedV2))),
				$author$project$Ports$learnosityErrorOnResubmissionV2(
				$author$project$Messages$wrapLearnosityMessage(
					$elm$core$Basics$always($author$project$Learnosity$Messages$LearnosityErrorOnResubmission))),
				$author$project$Ports$exitQuizComplete(
				$elm$core$Basics$always(
					$author$project$Messages$RouteCleanupComplete(0))),
				$author$project$Ports$componentRemoved(
				$elm$core$Basics$always(
					$author$project$Messages$RouteCleanupComplete(2))),
				$author$project$Ports$componentEvent(
				A3($author$project$Helpers$Ports$decodeIncomingPort, $author$project$Messages$LogIncomingPortDecodeFailure, 'componentEvent', $author$project$Main$componentEventDecoder)),
				$author$project$Ports$videoRecordingStatusUpdate(
				A2(
					$elm$core$Basics$composeR,
					A3(
						$author$project$Helpers$Ports$decodeIncomingPort,
						$author$project$LoggedIn$Messages$VideoRecordingStatusUpdateDecodeError,
						'VideoRecordingStatusUpdateDecodeError',
						A2($elm$json$Json$Decode$map, $author$project$LoggedIn$Messages$VideoRecordingStatusUpdate, $author$project$VideoRecording$RecordingProcess$statusUpdateDecoder)),
					$author$project$Messages$LoggedInRequiredMsg)),
				$author$project$Ports$uploadVideoCompleted(
				$elm$core$Basics$always(
					$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$VideoRecordingUploadCompleted))),
				$author$project$Ports$uploadVideoFailed(
				A2($elm$core$Basics$composeL, $author$project$Messages$LoggedInRequiredMsg, $author$project$LoggedIn$Messages$VideoRecordingUploadFailed)),
				$author$project$Ports$videoTimeUpdate(
				A3($author$project$Helpers$VideoPlayback$videoTimeUpdateDecoder, 'videoTimeUpdate', $author$project$Messages$LogIncomingPortDecodeFailure, $author$project$Messages$VideoTimeUpdate)),
				$author$project$Ports$videoPlaybackErrored(
				A3($author$project$Helpers$VideoPlayback$videoPlaybackErroredDecoder, 'videoPlaybackErrored', $author$project$Messages$LogIncomingPortDecodeFailure, $author$project$Messages$VideoPlaybackErrored)),
				$author$project$Ports$handleActivityError(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'handleActivityError',
					A2(
						$elm$json$Json$Decode$map,
						A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$ActivityErrored, $author$project$Messages$LoggedInRequiredMsg),
						$author$project$Models$Ids$decoder))),
				$author$project$Ports$iosVideoRecordingComplete(
				$elm$core$Basics$always(
					$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$IosCompleteStudentVideo))),
				$author$project$Ports$notificationPermissionUpdate(
				A2($elm$core$Basics$composeR, $author$project$Models$Permission$parsePermission, $author$project$Messages$NotificationPermissionsUpdate)),
				$author$project$Ports$mediaCapturePermissionUpdate(
				A2($elm$core$Basics$composeR, $author$project$Models$Permission$parsePermission, $author$project$Messages$MediaCapturePermissionsUpdate)),
				$author$project$Ports$returnNotificationSent(
				function (contentJSON) {
					return $author$project$Messages$LoggedInRequiredMsg(
						$author$project$LoggedIn$Messages$ReturnNotificationSent(
							$author$project$Models$BrowserNotification$decodeContent(contentJSON)));
				}),
				$author$project$Ports$studentReturnedViaNotification(
				function (_v0) {
					var contentJSON = _v0.a;
					var timeToReturnInMs = _v0.b;
					return $author$project$Messages$LoggedInRequiredMsg(
						A2(
							$author$project$LoggedIn$Messages$StudentReturnedViaNotification,
							$author$project$Models$BrowserNotification$decodeContent(contentJSON),
							timeToReturnInMs));
				}),
				$author$project$Ports$windowFocusChanged(
				A2($elm$core$Basics$composeR, $author$project$Messages$WindowFocusChanged, $author$project$Messages$GetCurrentTime)),
				$author$project$Ports$windowLostFocus(
				A2($elm$core$Basics$composeR, $author$project$Messages$WindowLostFocus, $author$project$Messages$GetCurrentTime)),
				$author$project$Ports$windowRegainedFocus(
				A2($elm$core$Basics$composeR, $author$project$Messages$WindowRegainedFocus, $author$project$Messages$GetCurrentTime)),
				$author$project$Ports$iosBrowserError(
				$elm$core$Basics$always($author$project$Messages$IosBrowserError)),
				$author$project$Ports$iosBrowserTimeout(
				$elm$core$Basics$always($author$project$Messages$IosBrowserTimeout)),
				$author$project$Ports$playTTSUrl($author$project$Messages$PlayTTSUrl),
				$author$project$Ports$ttsSpeakingUpdate($author$project$Messages$TTSSpeakingUpdate),
				$author$project$Ports$trackTimeFromStartToTTSPlay($author$project$Messages$TTSTrackTimeFromStartToPlay),
				$author$project$Ports$storedStudentVideoPersisted(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'storedStudentVideoPersisted',
					A2(
						$elm$json$Json$Decode$map,
						A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$StoredStudentVideoPersisted, $author$project$Messages$LoggedInRequiredMsg),
						$author$project$Models$Ids$decoder))),
				$author$project$Ports$windowInfoLoaded(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'windowInfoLoaded',
					A2($elm$json$Json$Decode$map, $author$project$Messages$WindowInfoLoaded, $author$project$Models$WindowInfo$windowInfoDecoder))),
				$author$project$Ports$storedStudentVideosIdentified(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'identifyingStoredVideos',
					A2(
						$elm$json$Json$Decode$map,
						function (ids) {
							return $author$project$Messages$LoggedInRequiredMsg(
								$author$project$LoggedIn$Messages$StoredStudentVideosIdentified(ids));
						},
						$elm$json$Json$Decode$list($author$project$Models$Ids$decoder)))),
				$author$project$Ports$storedStudentVideoUploadComplete(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'storedVideoUploadComplete',
					A2(
						$elm$json$Json$Decode$map,
						A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$StoredStudentVideoUploadComplete, $author$project$Messages$LoggedInRequiredMsg),
						$author$project$Models$Ids$decoder))),
				$author$project$Ports$storedStudentVideoRemoved(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'storedVideoUploadRemoved',
					A2(
						$elm$json$Json$Decode$map,
						A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$StoredStudentVideoRemoved, $author$project$Messages$LoggedInRequiredMsg),
						$author$project$Models$Ids$decoder))),
				$author$project$Ports$storedStudentVideoNotFound(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'storedVideoUploadNotFound',
					A2(
						$elm$json$Json$Decode$map,
						A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$StoredStudentVideoNotFound, $author$project$Messages$LoggedInRequiredMsg),
						$author$project$Models$Ids$decoder))),
				$author$project$Ports$storedStudentVideoCouldNotBeRetrieved(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'storedVideoUploadCouldNotBeRetrieved',
					A2(
						$elm$json$Json$Decode$map,
						A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$StoredStudentVideoCouldNotBeRetrieved, $author$project$Messages$LoggedInRequiredMsg),
						$author$project$Models$Ids$decoder))),
				$author$project$Ports$storedStudentVideoUploadFailed(
				function (_v1) {
					var encodedStudentQuestId = _v1.a;
					var errorString = _v1.b;
					return A4(
						$author$project$Helpers$Ports$decodeIncomingPort,
						$author$project$Messages$LogIncomingPortDecodeFailure,
						'storedStudentVideoUploadFailed',
						A2(
							$elm$json$Json$Decode$map,
							function (studentQuestId) {
								return $author$project$Messages$LoggedInRequiredMsg(
									A2($author$project$LoggedIn$Messages$StoredStudentVideoUploadFailed, studentQuestId, errorString));
							},
							$author$project$Models$Ids$decoder),
						encodedStudentQuestId);
				}),
				$author$project$Ports$youtubeAvailable(
				$elm$core$Basics$always($author$project$Messages$YoutubeAvailable)),
				$author$project$Ports$youtubeUnavailable(
				$elm$core$Basics$always($author$project$Messages$YoutubeUnavailable)),
				$author$project$Ports$embeddedYoutubePlayerPlaybackEnded(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'embeddedYoutubePlayerPlaybackEnded',
					A2(
						$elm$json$Json$Decode$map,
						A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$VideoActivityPlaybackViaEmbeddedYoutubePlayerEnded, $author$project$Messages$LoggedInRequiredMsg),
						$author$project$Models$Ids$decoder))),
				$author$project$Ports$embeddedYoutubeVideoTimeUpdate(
				A3($author$project$Helpers$VideoPlayback$videoTimeUpdateDecoder, 'embeddedyoutubeVideoTimeUpdate', $author$project$Messages$LogIncomingPortDecodeFailure, $author$project$Messages$EmbeddedYoutubeVideoTimeUpdate)),
				$author$project$Ports$embeddedYoutubePlayerPlaybackPaused(
				$elm$core$Basics$always($author$project$Messages$EmbeddedYoutubeVideoPaused)),
				$author$project$Ports$markYoutubeVideoAsRestrictedByData(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'markYoutubeVideoAsRestrictedByData',
					A2(
						$elm$json$Json$Decode$map,
						$author$project$Messages$MarkYoutubeVideoAsRestricted(1),
						$author$project$Models$Ids$decoder))),
				$author$project$Ports$trackTimeToYoutubeQualityMetric($author$project$Messages$TrackTimeToYoutubeQualityMetric),
				$author$project$Ports$externalWindowOpened(
				$elm$core$Basics$always(
					$author$project$Messages$GetCurrentTime($author$project$Messages$ExternalWindowOpened))),
				$author$project$Ports$externalWindowClosed($author$project$Messages$ExternalWindowSuccessfullyClosed),
				$author$project$Ports$addWebActivityInitiatedFaviconIfOnWebActivity($author$project$Messages$AddWebActivityInitiatedFaviconIfOnWebActivity),
				$author$project$Ports$qrCodeReadSuccess(
				A2($elm$core$Basics$composeR, $author$project$LoginApp$Messages$QRCodeReadSuccess, $author$project$Messages$LoginMessage)),
				$author$project$Ports$qrCodeReadError(
				A2($elm$core$Basics$composeR, $author$project$LoginApp$Messages$QRCodeReadError, $author$project$Messages$LoginMessage)),
				$author$project$Ports$qrCodeReaderStarted(
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Basics$always($author$project$LoginApp$Messages$QRCodeScannerStarted),
					$author$project$Messages$LoginMessage)),
				$author$project$Ports$qrCodeReaderStopped(
				$elm$core$Basics$always(
					$author$project$Messages$RouteCleanupComplete(1))),
				$author$project$Ports$activateEsparkIframe($author$project$Messages$StartUpdatingEsparkIframe),
				$author$project$Ports$esparkIframeCompleteActivity(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'esparkIframeCompleteActivity',
					A2(
						$elm$json$Json$Decode$map,
						A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$EsparkIframeCompleteActivity, $author$project$Messages$LoggedInRequiredMsg),
						$author$project$Models$Ids$decoder))),
				$author$project$Ports$trackPLEAvailable(
				A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$PLEIsAvailable, $author$project$Messages$LoggedInRequiredMsg)),
				$author$project$Ports$esparkIframeNavigateToActivity(
				A3(
					$author$project$Helpers$Ports$decodeIncomingPort,
					$author$project$Messages$LogIncomingPortDecodeFailure,
					'esparkIframeNavigateToActivity',
					A2($elm$json$Json$Decode$map, $author$project$Messages$EsparkIframeNavigateToActivity, $author$project$Models$Ids$decoder))),
				$author$project$Ports$portsInitialized(
				$elm$core$Basics$always($author$project$Messages$AppHasInitialized)),
				$author$project$Main$activityPracticeSubscriptions(model),
				$author$project$Ports$accessoriesUpdate(
				A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$AccessoriesUpdate, $author$project$Messages$LoggedInRequiredMsg)),
				A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Sub$none,
				A2($author$project$Models$Modal$mapOpenModal, $author$project$Main$modalSubscriptions, model.hM)),
				function () {
				var _v2 = model.eY;
				if (_v2.$ === 13) {
					return A2(
						$elm$core$Platform$Sub$map,
						A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$ClassLibraryMsg, $author$project$Messages$LoggedInRequiredMsg),
						$author$project$Views$ClassLibrary$subscriptions);
				} else {
					return $elm$core$Platform$Sub$none;
				}
			}()
			]));
};
var $author$project$Messages$EsparkIosAppDisabledMsg = function (a) {
	return {$: 89, a: a};
};
var $author$project$Model$forLoggedInState = F2(
	function (model, handlers) {
		var _v0 = model.jF;
		switch (_v0.$) {
			case 0:
				return handlers.jR(0);
			case 1:
				var loginState = _v0.a;
				return handlers.jQ(loginState);
			default:
				var student = _v0.a;
				var loginInfo = _v0.b;
				return A2(handlers.jP, student, loginInfo);
		}
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$keyedNodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_keyedNodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $visotype$elm_dom$Dom$Internal$render = function (_v0) {
	var data = _v0;
	var prependStyles = function () {
		var _v8 = data.fm;
		if (!_v8.b) {
			return function (x) {
				return x;
			};
		} else {
			return $elm$core$List$append(
				A2(
					$elm$core$List$map,
					function (_v9) {
						var k = _v9.a;
						var v = _v9.b;
						return A2($elm$virtual_dom$VirtualDom$style, k, v);
					},
					data.fm));
		}
	}();
	var prependListeners = function () {
		var _v6 = data.c;
		if (!_v6.b) {
			return function (x) {
				return x;
			};
		} else {
			return $elm$core$List$append(
				A2(
					$elm$core$List$map,
					function (_v7) {
						var k = _v7.a;
						var v = _v7.b;
						return A2($elm$virtual_dom$VirtualDom$on, k, v);
					},
					data.c));
		}
	}();
	var consTextKeyed = function () {
		var _v5 = data.ju;
		if (_v5 === '') {
			return function (x) {
				return x;
			};
		} else {
			return $elm$core$List$cons(
				A2(
					$elm$core$Tuple$pair,
					'rendered-internal-text',
					$elm$virtual_dom$VirtualDom$text(data.ju)));
		}
	}();
	var consText = function () {
		var _v4 = data.ju;
		if (_v4 === '') {
			return function (x) {
				return x;
			};
		} else {
			return $elm$core$List$cons(
				$elm$virtual_dom$VirtualDom$text(data.ju));
		}
	}();
	var consId = function () {
		var _v3 = data.hj;
		if (_v3 === '') {
			return function (x) {
				return x;
			};
		} else {
			return $elm$core$List$cons(
				A2(
					$elm$virtual_dom$VirtualDom$property,
					'id',
					$elm$json$Json$Encode$string(data.hj)));
		}
	}();
	var consClassName = function () {
		var _v2 = data.am;
		if (!_v2.b) {
			return function (x) {
				return x;
			};
		} else {
			return $elm$core$List$cons(
				A2(
					$elm$virtual_dom$VirtualDom$property,
					'className',
					$elm$json$Json$Encode$string(
						A2($elm$core$String$join, ' ', data.am))));
		}
	}();
	var allAttributes = consId(
		consClassName(
			prependStyles(
				prependListeners(data.aK))));
	var _v1 = _Utils_Tuple2(data.hQ, data.cA);
	if (_v1.a === '') {
		if (!_v1.b.b) {
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				data.c1,
				allAttributes,
				consText(data.gC));
		} else {
			return A3(
				$elm$virtual_dom$VirtualDom$keyedNode,
				data.c1,
				allAttributes,
				consTextKeyed(
					A3($elm$core$List$map2, $elm$core$Tuple$pair, data.cA, data.gC)));
		}
	} else {
		if (!_v1.b.b) {
			return A4(
				$elm$virtual_dom$VirtualDom$nodeNS,
				data.hQ,
				data.c1,
				allAttributes,
				consText(data.gC));
		} else {
			return A4(
				$elm$virtual_dom$VirtualDom$keyedNodeNS,
				data.hQ,
				data.c1,
				allAttributes,
				consTextKeyed(
					A3($elm$core$List$map2, $elm$core$Tuple$pair, data.cA, data.gC)));
		}
	}
};
var $visotype$elm_dom$Dom$render = $visotype$elm_dom$Dom$Internal$render;
var $visotype$elm_dom$Dom$Internal$Element = $elm$core$Basics$identity;
var $visotype$elm_dom$Dom$Internal$modify = F2(
	function (f, _v0) {
		var data = _v0;
		return f(data);
	});
var $visotype$elm_dom$Dom$appendChildList = function (le) {
	var lr = A2($elm$core$List$map, $visotype$elm_dom$Dom$Internal$render, le);
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					gC: A2($elm$core$List$append, n.gC, lr)
				});
		});
};
var $visotype$elm_dom$Dom$appendNode = function (v) {
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					gC: A2(
						$elm$core$List$append,
						n.gC,
						_List_fromArray(
							[v]))
				});
		});
};
var $visotype$elm_dom$Dom$element = function (tag) {
	return {aK: _List_Nil, gC: _List_Nil, am: _List_Nil, hj: '', cA: _List_Nil, c: _List_Nil, hQ: '', fm: _List_Nil, c1: tag, ju: ''};
};
var $author$project$Messages$ChangeSubject = {$: 6};
var $author$project$Messages$CloseLogoutModal = {$: 42};
var $author$project$Messages$StartLogout = {$: 21};
var $visotype$elm_dom$Dom$addClass = function (s) {
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					am: A2(
						$elm$core$List$append,
						n.am,
						_List_fromArray(
							[s]))
				});
		});
};
var $visotype$elm_dom$Dom$addClassList = function (ls) {
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					am: A2($elm$core$List$append, n.am, ls)
				});
		});
};
var $visotype$elm_dom$Dom$addAttribute = function (a) {
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					aK: A2(
						$elm$core$List$append,
						n.aK,
						_List_fromArray(
							[a]))
				});
		});
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $author$project$Dom$Extra$addDataAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return $visotype$elm_dom$Dom$addAttribute(
		A2(
			$elm$html$Html$Attributes$attribute,
			'data-' + $elm$core$String$toLower(name),
			$elm$core$String$toLower(value)));
};
var $author$project$Dom$Extra$addDataCy = function (value) {
	return $author$project$Dom$Extra$addDataAttribute(
		_Utils_Tuple2('cy', value));
};
var $visotype$elm_dom$Dom$addStyle = function (kv) {
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					fm: A2(
						$elm$core$List$append,
						n.fm,
						_List_fromArray(
							[kv]))
				});
		});
};
var $visotype$elm_dom$Dom$addStyleList = function (lkv) {
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					fm: A2($elm$core$List$append, n.fm, lkv)
				});
		});
};
var $elm$core$String$append = _String_append;
var $visotype$elm_dom$Dom$appendText = function (s) {
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					ju: A2($elm$core$String$append, n.ju, s)
				});
		});
};
var $author$project$Component$Text$centered = $visotype$elm_dom$Dom$addClass('has-text-centered');
var $author$project$Component$Icon$CloseCorner = 46;
var $author$project$Component$Icon$Custom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Component$Icon$Icon = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $visotype$elm_dom$Dom$addAction = function (_v0) {
	var event = _v0.a;
	var msg = _v0.b;
	var handler = A2($elm$core$Basics$composeR, $elm$json$Json$Decode$succeed, $elm$virtual_dom$VirtualDom$Normal);
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					c: A2(
						$elm$core$List$append,
						n.c,
						_List_fromArray(
							[
								_Utils_Tuple2(
								event,
								handler(msg))
							]))
				});
		});
};
var $visotype$elm_dom$Dom$appendChild = function (e) {
	var r = $visotype$elm_dom$Dom$Internal$render(e);
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					gC: A2(
						$elm$core$List$append,
						n.gC,
						_List_fromArray(
							[r]))
				});
		});
};
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $avh4$elm_color$Color$toCssString = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	var roundTo = function (x) {
		return $elm$core$Basics$round(x * 1000) / 1000;
	};
	var pct = function (x) {
		return $elm$core$Basics$round(x * 10000) / 100;
	};
	return $elm$core$String$concat(
		_List_fromArray(
			[
				'rgba(',
				$elm$core$String$fromFloat(
				pct(r)),
				'%,',
				$elm$core$String$fromFloat(
				pct(g)),
				'%,',
				$elm$core$String$fromFloat(
				pct(b)),
				'%,',
				$elm$core$String$fromFloat(
				roundTo(a)),
				')'
			]));
};
var $author$project$Component$Text$colored = function (color) {
	return $visotype$elm_dom$Dom$addStyle(
		_Utils_Tuple2(
			'color',
			$avh4$elm_color$Color$toCssString(color)));
};
var $avh4$elm_color$Color$RgbaSpace = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $avh4$elm_color$Color$scaleFrom255 = function (c) {
	return c / 255;
};
var $avh4$elm_color$Color$rgb255 = F3(
	function (r, g, b) {
		return A4(
			$avh4$elm_color$Color$RgbaSpace,
			$avh4$elm_color$Color$scaleFrom255(r),
			$avh4$elm_color$Color$scaleFrom255(g),
			$avh4$elm_color$Color$scaleFrom255(b),
			1.0);
	});
var $author$project$ES$UI$Color$grey = {
	jX: A3($avh4$elm_color$Color$rgb255, 229, 229, 229),
	jY: A3($avh4$elm_color$Color$rgb255, 204, 204, 204),
	jZ: A3($avh4$elm_color$Color$rgb255, 179, 179, 179),
	j_: A3($avh4$elm_color$Color$rgb255, 153, 153, 153),
	j$: A3($avh4$elm_color$Color$rgb255, 128, 128, 128),
	j0: A3($avh4$elm_color$Color$rgb255, 102, 102, 102),
	j1: A3($avh4$elm_color$Color$rgb255, 77, 77, 77),
	j2: A3($avh4$elm_color$Color$rgb255, 51, 51, 51),
	j3: A3($avh4$elm_color$Color$rgb255, 26, 26, 26)
};
var $author$project$Component$Icon$addGlyphClass = function (glyph) {
	switch (glyph) {
		case 0:
			return $visotype$elm_dom$Dom$addClass('es-icon-loading');
		case 1:
			return $visotype$elm_dom$Dom$addClass('es-icon-app-store');
		case 2:
			return $visotype$elm_dom$Dom$addClass('es-icon-arrow-up');
		case 3:
			return $visotype$elm_dom$Dom$addClass('es-icon-arrow-down');
		case 4:
			return $visotype$elm_dom$Dom$addClass('es-icon-arrow-left');
		case 5:
			return $visotype$elm_dom$Dom$addClass('es-icon-arrow-right');
		case 6:
			return $visotype$elm_dom$Dom$addClass('es-icon-award-check');
		case 7:
			return $visotype$elm_dom$Dom$addClass('es-icon-camera');
		case 8:
			return $visotype$elm_dom$Dom$addClass('es-icon-check');
		case 9:
			return $visotype$elm_dom$Dom$addClass('es-icon-download');
		case 10:
			return $visotype$elm_dom$Dom$addClass('es-icon-dropdown');
		case 11:
			return $visotype$elm_dom$Dom$addClass('es-icon-flag');
		case 12:
			return $visotype$elm_dom$Dom$addClass('es-icon-grid');
		case 13:
			return $visotype$elm_dom$Dom$addClass('es-icon-rotate-camera');
		case 14:
			return $visotype$elm_dom$Dom$addClass('es-icon-replay');
		case 15:
			return $visotype$elm_dom$Dom$addClass('es-icon-quiz');
		case 16:
			return $visotype$elm_dom$Dom$addClass('es-icon-question-mark');
		case 17:
			return $visotype$elm_dom$Dom$addClass('es-icon-play');
		case 18:
			return $visotype$elm_dom$Dom$addClass('es-icon-pause');
		case 19:
			return $visotype$elm_dom$Dom$addClass('es-icon-magnifying-glass');
		case 20:
			return $visotype$elm_dom$Dom$addClass('es-icon-logout');
		case 21:
			return $visotype$elm_dom$Dom$addClass('es-icon-lock');
		case 22:
			return $visotype$elm_dom$Dom$addClass('es-icon-history');
		case 23:
			return $visotype$elm_dom$Dom$addClass('es-icon-lightbulb-check');
		case 24:
			return $visotype$elm_dom$Dom$addClass('es-icon-head-gear');
		case 25:
			return $visotype$elm_dom$Dom$addClass('es-icon-skip-ahead');
		case 26:
			return $visotype$elm_dom$Dom$addClass('es-icon-star');
		case 27:
			return $visotype$elm_dom$Dom$addClass('es-icon-star-circle');
		case 28:
			return $visotype$elm_dom$Dom$addClass('es-icon-stay-here');
		case 29:
			return $visotype$elm_dom$Dom$addClass('es-icon-stop-sign');
		case 30:
			return $visotype$elm_dom$Dom$addClass('es-icon-subject-choice-3');
		case 31:
			return $visotype$elm_dom$Dom$addClass('es-icon-subject-choice-k-2');
		case 32:
			return $visotype$elm_dom$Dom$addClass('es-icon-thumbs-down');
		case 33:
			return $visotype$elm_dom$Dom$addClass('es-icon-thumbs-down-cuff');
		case 34:
			return $visotype$elm_dom$Dom$addClass('es-icon-thumbs-up');
		case 35:
			return $visotype$elm_dom$Dom$addClass('es-icon-thumbs-up-cuff');
		case 36:
			return $visotype$elm_dom$Dom$addClass('es-icon-trash');
		case 37:
			return $visotype$elm_dom$Dom$addClass('es-icon-upload');
		case 38:
			return $visotype$elm_dom$Dom$addClass('es-icon-video');
		case 39:
			return $visotype$elm_dom$Dom$addClass('es-icon-video-next-icon');
		case 40:
			return $visotype$elm_dom$Dom$addClass('es-icon-web-app');
		case 43:
			return $visotype$elm_dom$Dom$addClass('es-icon-close');
		case 41:
			return $visotype$elm_dom$Dom$addClass('es-icon-rocket');
		case 42:
			return $visotype$elm_dom$Dom$addClass('es-icon-stopwatch');
		case 44:
			return $visotype$elm_dom$Dom$addClass('es-icon-espark-logo');
		case 45:
			return $visotype$elm_dom$Dom$addClass('es-icon-video-feedback');
		case 46:
			return $visotype$elm_dom$Dom$addClass('es-icon-close-corner');
		case 47:
			return $visotype$elm_dom$Dom$addClass('es-icon-stop');
		case 48:
			return $visotype$elm_dom$Dom$addClass('es-icon-plus');
		default:
			return $visotype$elm_dom$Dom$addClass('es-icon-fast-forward');
	}
};
var $author$project$Component$Icon$addSizeClass = function (size) {
	switch (size.$) {
		case 0:
			return $elm$core$Basics$identity;
		case 1:
			return $visotype$elm_dom$Dom$addClass('es-icon--sm');
		case 2:
			return $visotype$elm_dom$Dom$addClass('es-icon--lg');
		case 3:
			return $visotype$elm_dom$Dom$addClass('es-icon--xl');
		case 4:
			return $visotype$elm_dom$Dom$addClass('es-icon--2x');
		case 5:
			return $visotype$elm_dom$Dom$addClass('es-icon--2x-lg');
		case 6:
			return $visotype$elm_dom$Dom$addClass('es-icon--2x-xl');
		case 7:
			return $visotype$elm_dom$Dom$addClass('es-icon--3x');
		default:
			var sz = size.a;
			return $visotype$elm_dom$Dom$addStyleList(
				_List_fromArray(
					[
						_Utils_Tuple2('font-size', sz),
						_Utils_Tuple2('height', sz),
						_Utils_Tuple2('width', sz)
					]));
	}
};
var $author$project$Component$Icon$render = function (_v0) {
	var glyph = _v0.a;
	var size = _v0.b;
	return A2(
		$author$project$Component$Icon$addSizeClass,
		size,
		A2(
			$author$project$Component$Icon$addGlyphClass,
			glyph,
			A2(
				$visotype$elm_dom$Dom$addClass,
				'es-icon',
				$visotype$elm_dom$Dom$element('i'))));
};
var $author$project$Views$Components$Button$WithGlobalMsg$close = function (message) {
	return A2(
		$visotype$elm_dom$Dom$appendChild,
		A2(
			$author$project$Component$Text$colored,
			$author$project$ES$UI$Color$grey.jY,
			$author$project$Component$Icon$render(
				A2(
					$author$project$Component$Icon$Icon,
					46,
					$author$project$Component$Icon$Custom('40px')))),
		A2(
			$visotype$elm_dom$Dom$addAttribute,
			A2($elm$html$Html$Attributes$attribute, 'aria-label', 'close'),
			A2(
				$visotype$elm_dom$Dom$addAction,
				_Utils_Tuple2('click', message),
				$visotype$elm_dom$Dom$element('button'))));
};
var $author$project$Component$Buttons$Button$NotLoading = 0;
var $author$project$Component$Buttons$Button$OrangeLight = 7;
var $author$project$Component$Icon$XX_XLarge = {$: 6};
var $author$project$Dom$Extra$addClickAction = function (message) {
	return $visotype$elm_dom$Dom$addAction(
		_Utils_Tuple2('click', message));
};
var $author$project$Component$Buttons$Choice$LabeledButton = 2;
var $author$project$Component$Buttons$Button$addColorClass = function (color) {
	return $visotype$elm_dom$Dom$addClass(
		function () {
			switch (color) {
				case 0:
					return 'es-button--teal';
				case 1:
					return 'es-button--teal-light';
				case 2:
					return 'es-button--green';
				case 3:
					return 'es-button--green-light';
				case 4:
					return 'es-button--yellow';
				case 5:
					return 'es-button--yellow-light';
				case 6:
					return 'es-button--orange';
				case 7:
					return 'es-button--orange-light';
				case 8:
					return 'es-button--red';
				case 9:
					return 'es-button--red-light';
				case 10:
					return 'es-button--purple';
				case 11:
					return 'es-button--purple-light';
				case 12:
					return 'es-button--blue';
				case 13:
					return 'es-button--blue-light';
				case 14:
					return 'es-button--grey';
				default:
					return 'es-button--grey-light';
			}
		}());
};
var $author$project$Component$Buttons$Button$addLoadingClass = function (loadingState) {
	if (!loadingState) {
		return $elm$core$Basics$identity;
	} else {
		return $visotype$elm_dom$Dom$addClass('es-button--loading');
	}
};
var $author$project$Component$Buttons$Choice$addTypeClass = function (choiceButton) {
	return $visotype$elm_dom$Dom$addClass(
		function () {
			switch (choiceButton) {
				case 0:
					return 'es-choice-button--image-only';
				case 1:
					return 'es-choice-button--icon-only';
				default:
					return 'es-choice-button--labeled';
			}
		}());
};
var $author$project$Component$Buttons$Button$applyBase = $visotype$elm_dom$Dom$addClass('es-button');
var $author$project$Component$Buttons$Button$loadingLayers = _List_fromArray(
	[
		A2(
		$visotype$elm_dom$Dom$addClass,
		'es-button__loading-overlay',
		$visotype$elm_dom$Dom$element('div')),
		A2(
		$visotype$elm_dom$Dom$addClass,
		'es-button__loading-indicator',
		$visotype$elm_dom$Dom$element('div'))
	]);
var $author$project$Component$Buttons$Choice$build = F2(
	function (choiceButton, loadingState) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Component$Buttons$Button$applyBase,
			A2(
				$elm$core$Basics$composeR,
				$visotype$elm_dom$Dom$addClass('es-choice-button'),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Component$Buttons$Button$addLoadingClass(loadingState),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Component$Buttons$Choice$addTypeClass(choiceButton),
						$visotype$elm_dom$Dom$appendChildList($author$project$Component$Buttons$Button$loadingLayers)))));
	});
var $visotype$elm_dom$Dom$prependChild = function (e) {
	var r = $visotype$elm_dom$Dom$Internal$render(e);
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					gC: A2($elm$core$List$cons, r, n.gC)
				});
		});
};
var $author$project$Component$Buttons$Choice$labeled = F2(
	function (loadingState, _v0) {
		var color = _v0.a;
		var text = _v0.b;
		var icon = _v0.c;
		return A2(
			$elm$core$Basics$composeR,
			A2($author$project$Component$Buttons$Choice$build, 2, loadingState),
			A2(
				$elm$core$Basics$composeR,
				$author$project$Component$Buttons$Button$addColorClass(color),
				A2(
					$elm$core$Basics$composeR,
					$visotype$elm_dom$Dom$appendText(text),
					$visotype$elm_dom$Dom$prependChild(
						$author$project$Component$Icon$render(icon)))));
	});
var $author$project$Views$Components$Button$WithGlobalMsg$exitModalChangeSubject = F2(
	function (glyph, message) {
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'change-subject-button',
			A2(
				$author$project$Dom$Extra$addClickAction,
				message,
				A3(
					$author$project$Component$Buttons$Choice$labeled,
					0,
					_Utils_Tuple3(
						7,
						'Change Subject',
						A2($author$project$Component$Icon$Icon, glyph, $author$project$Component$Icon$XX_XLarge)),
					$visotype$elm_dom$Dom$element('button'))));
	});
var $author$project$Component$Icon$Logout = 20;
var $author$project$Views$Components$Button$WithGlobalMsg$exitModalLogout = function (message) {
	return A2(
		$author$project$Dom$Extra$addDataCy,
		'logout-button',
		A2(
			$author$project$Dom$Extra$addClickAction,
			message,
			A3(
				$author$project$Component$Buttons$Choice$labeled,
				0,
				_Utils_Tuple3(
					7,
					'Logout',
					A2($author$project$Component$Icon$Icon, 20, $author$project$Component$Icon$XX_XLarge)),
				$visotype$elm_dom$Dom$element('button'))));
};
var $author$project$Styles$filled = function (color) {
	return $visotype$elm_dom$Dom$addStyle(
		_Utils_Tuple2(
			'background-color',
			$avh4$elm_color$Color$toCssString(color)));
};
var $author$project$Styles$onTop = function (index) {
	return $visotype$elm_dom$Dom$addStyle(
		_Utils_Tuple2(
			'z-index',
			$elm$core$String$fromInt(index)));
};
var $author$project$Styles$radius = function (elementSize) {
	return $visotype$elm_dom$Dom$addStyle(
		_Utils_Tuple2(
			'border-radius',
			$elm$core$String$fromInt(elementSize) + 'px'));
};
var $author$project$Styles$size = function (args) {
	return $visotype$elm_dom$Dom$addStyleList(
		_List_fromArray(
			[
				_Utils_Tuple2('width', args.jS),
				_Utils_Tuple2('height', args.hb)
			]));
};
var $author$project$Component$Icon$SubjectChoice3 = 30;
var $author$project$Component$Icon$SubjectChoiceK2 = 31;
var $author$project$Views$ExitModal$subjectChoiceIconGlyph = function (appFlowMode) {
	if (!appFlowMode.$) {
		return 31;
	} else {
		return 30;
	}
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$Component$Link$to = F2(
	function (route, elements) {
		return A2(
			$visotype$elm_dom$Dom$appendChildList,
			elements,
			A2(
				$visotype$elm_dom$Dom$addAttribute,
				$elm$html$Html$Attributes$href(
					$author$project$Routes$routeToUrlString(route)),
				$visotype$elm_dom$Dom$element('a')));
	});
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {dK: d, eB: m, fV: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		cK: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		fV: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.fV, 0, date.cK);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.dK;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.eB;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.cK;
	});
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		fQ: 1 + (((rd - week1Day1) / 7) | 0),
		fR: wy,
		jO: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.fQ;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.fR;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.bL(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.cD(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.bL(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.ch(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.aH(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.aH(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.aH(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.dd(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.aH(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.aH(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {dD: col, gI: contextStack, eO: problem, eZ: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.eZ, s.dD, x, s.b));
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.ao, s.eZ, s.dD, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{dD: newCol, b: s.b, e: s.e, ao: newOffset, eZ: newRow, a: s.a});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.ao, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{dD: 1, b: s.b, e: s.e, ao: s.ao + 1, eZ: s.eZ + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{dD: s.dD + 1, b: s.b, e: s.e, ao: newOffset, eZ: s.eZ, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.ao, offset) < 0,
					0,
					{dD: col, b: s0.b, e: s0.e, ao: offset, eZ: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.ao, s.eZ, s.dD, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.ao, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.ao, s1.ao, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.ao) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {dD: col, eO: problem, eZ: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.eZ, p.dD, p.eO);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{dD: 1, b: _List_Nil, e: 1, ao: 0, eZ: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	ch: $justinmimbs$date$Date$withOrdinalSuffix,
	cD: $justinmimbs$date$Date$monthToName,
	bL: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	dd: $justinmimbs$date$Date$weekdayToName,
	aH: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$AppConfiguration$versionText = function (_v0) {
	var studentAppBuildTimestamp = _v0.bo;
	return _Utils_ap(
		A2(
			$justinmimbs$date$Date$format,
			'yyyy.MM.dd.',
			A2($justinmimbs$date$Date$fromPosix, $elm$time$Time$utc, studentAppBuildTimestamp)),
		_Utils_ap(
			A3(
				$elm$core$String$padLeft,
				2,
				'0',
				$elm$core$String$fromInt(
					A2($elm$time$Time$toHour, $elm$time$Time$utc, studentAppBuildTimestamp))),
			A3(
				$elm$core$String$padLeft,
				2,
				'0',
				$elm$core$String$fromInt(
					A2($elm$time$Time$toMinute, $elm$time$Time$utc, studentAppBuildTimestamp)))));
};
var $avh4$elm_color$Color$rgb = F3(
	function (r, g, b) {
		return A4($avh4$elm_color$Color$RgbaSpace, r, g, b, 1.0);
	});
var $author$project$ES$UI$Color$white = A3($avh4$elm_color$Color$rgb, 1.0, 1.0, 1.0);
var $author$project$Component$Text$withSize = function (size) {
	return $visotype$elm_dom$Dom$addClass(
		'is-size-' + $elm$core$String$fromInt(size));
};
var $author$project$Views$ExitModal$render = F2(
	function (_v0, appFlowMode) {
		var route = _v0.eY;
		var logoutModalOpen = _v0.hC;
		var appConfiguration = _v0.f7;
		var studentAppVersionText = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('bottom', '10px'),
					_Utils_Tuple2('left', '10px'),
					_Utils_Tuple2('background', 'transparent'),
					_Utils_Tuple2('position', 'absolute'),
					_Utils_Tuple2('font-style', 'normal'),
					_Utils_Tuple2('font-weight', 'normal'),
					_Utils_Tuple2('font-size', '14px'),
					_Utils_Tuple2('line-height', '17px'),
					_Utils_Tuple2('color', '#B3B3B3')
				]),
			A2(
				$visotype$elm_dom$Dom$appendText,
				'Version: ' + $author$project$AppConfiguration$versionText(appConfiguration),
				$visotype$elm_dom$Dom$element('div')));
		var logoutTileButton = A2(
			$author$project$Styles$radius,
			5,
			A2(
				$author$project$Styles$size,
				{hb: '270px', jS: '266px'},
				$author$project$Views$Components$Button$WithGlobalMsg$exitModalLogout($author$project$Messages$StartLogout)));
		var joinCodeLink = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('position', 'absolute'),
					_Utils_Tuple2('bottom', '47px'),
					_Utils_Tuple2('width', 'calc(100% - 90px)'),
					_Utils_Tuple2('color', '#4D4D4D'),
					_Utils_Tuple2('text-decoration', 'underline')
				]),
			A2(
				$author$project$Component$Text$withSize,
				5,
				$author$project$Component$Text$centered(
					A2(
						$author$project$Component$Link$to,
						A2(
							$author$project$Routes$JoinCodeRoute,
							$elm$core$Maybe$Just(
								$author$project$Routes$routeToUrlString(route)),
							$author$project$JoinCode$Model$init),
						_List_fromArray(
							[
								A2(
								$visotype$elm_dom$Dom$appendText,
								'Enter join code',
								$visotype$elm_dom$Dom$element('div'))
							])))));
		var closeButton = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('padding', '0'),
					_Utils_Tuple2('margin', '0'),
					_Utils_Tuple2('position', 'absolute'),
					_Utils_Tuple2('top', '0'),
					_Utils_Tuple2('right', '0'),
					_Utils_Tuple2('background', 'transparent')
				]),
			A2(
				$author$project$Dom$Extra$addDataCy,
				'close-modal-button',
				$author$project$Views$Components$Button$WithGlobalMsg$close($author$project$Messages$CloseLogoutModal)));
		var changeSubjectTileButton = A2(
			$author$project$Styles$radius,
			5,
			A2(
				$author$project$Styles$size,
				{hb: '270px', jS: '266px'},
				A2(
					$author$project$Views$Components$Button$WithGlobalMsg$exitModalChangeSubject,
					$author$project$Views$ExitModal$subjectChoiceIconGlyph(appFlowMode),
					$author$project$Messages$ChangeSubject)));
		var columns = _List_fromArray(
			[logoutTileButton, changeSubjectTileButton]);
		var content = A2(
			$visotype$elm_dom$Dom$addClass,
			'modal-content',
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('padding', '45px 45px 65px 45px'),
				A2(
					$author$project$Styles$filled,
					$author$project$ES$UI$Color$white,
					A2(
						$author$project$Styles$radius,
						5,
						A2(
							$author$project$Styles$size,
							{hb: '500px', jS: '645px'},
							A2(
								$visotype$elm_dom$Dom$addStyleList,
								_List_fromArray(
									[
										_Utils_Tuple2('display', 'flex'),
										_Utils_Tuple2('justify-content', 'space-between'),
										_Utils_Tuple2('align-content', 'center'),
										_Utils_Tuple2('align-self', 'center'),
										_Utils_Tuple2('align-items', 'center')
									]),
								A2(
									$visotype$elm_dom$Dom$appendChildList,
									_Utils_ap(
										columns,
										_List_fromArray(
											[joinCodeLink, closeButton, studentAppVersionText])),
									$visotype$elm_dom$Dom$element('div'))))))));
		var background = A2(
			$visotype$elm_dom$Dom$addClass,
			'modal-background',
			$visotype$elm_dom$Dom$element('div'));
		return logoutModalOpen ? A2(
			$author$project$Dom$Extra$addDataCy,
			'exit-modal',
			A2(
				$author$project$Styles$onTop,
				301,
				A2(
					$visotype$elm_dom$Dom$addClassList,
					_List_fromArray(
						['modal', 'is-active']),
					A2(
						$visotype$elm_dom$Dom$appendChildList,
						_List_fromArray(
							[background, content]),
						$visotype$elm_dom$Dom$element('div'))))) : $visotype$elm_dom$Dom$element('span');
	});
var $author$project$Messages$TrackPageInteraction = {$: 72};
var $visotype$elm_dom$Dom$addActionConditional = F2(
	function (kv, test) {
		if (test) {
			return $visotype$elm_dom$Dom$addAction(kv);
		} else {
			return function (x) {
				return x;
			};
		}
	});
var $author$project$Screen$renderContainerView = F3(
	function (loggedInClass, hasInteractedWithPage, content) {
		return A3(
			$visotype$elm_dom$Dom$addActionConditional,
			_Utils_Tuple2('keydown', $author$project$Messages$TrackPageInteraction),
			!hasInteractedWithPage,
			A3(
				$visotype$elm_dom$Dom$addActionConditional,
				_Utils_Tuple2('touchend', $author$project$Messages$TrackPageInteraction),
				!hasInteractedWithPage,
				A3(
					$visotype$elm_dom$Dom$addActionConditional,
					_Utils_Tuple2('touchstart', $author$project$Messages$TrackPageInteraction),
					!hasInteractedWithPage,
					A3(
						$visotype$elm_dom$Dom$addActionConditional,
						_Utils_Tuple2('click', $author$project$Messages$TrackPageInteraction),
						!hasInteractedWithPage,
						A2(
							$visotype$elm_dom$Dom$addClassList,
							_List_fromArray(
								['main', loggedInClass, 'lrn_noMath']),
							A2(
								$visotype$elm_dom$Dom$appendChildList,
								content,
								$visotype$elm_dom$Dom$element('div')))))));
	});
var $author$project$Messages$OpenLogoutModal = {$: 41};
var $author$project$Styles$fullWidth = $visotype$elm_dom$Dom$addStyle(
	_Utils_Tuple2('width', '100vw'));
var $author$project$Component$Layout$levelItem = $visotype$elm_dom$Dom$addClass('level-item');
var $author$project$Component$Layout$level = function (args) {
	var right = A2(
		$visotype$elm_dom$Dom$appendChildList,
		A2($elm$core$List$map, $author$project$Component$Layout$levelItem, args.iJ),
		A2(
			$visotype$elm_dom$Dom$addClass,
			'level-right',
			$visotype$elm_dom$Dom$element('div')));
	var left = A2(
		$visotype$elm_dom$Dom$addStyle,
		_Utils_Tuple2('display', 'flex'),
		A2(
			$visotype$elm_dom$Dom$appendChildList,
			A2($elm$core$List$map, $author$project$Component$Layout$levelItem, args.hx),
			A2(
				$visotype$elm_dom$Dom$addClass,
				'level-left',
				$visotype$elm_dom$Dom$element('div'))));
	var centered = A2($elm$core$List$map, $author$project$Component$Layout$levelItem, args.gz);
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		A2(
			$elm$core$List$cons,
			left,
			A2($elm$core$List$cons, right, centered)),
		A2(
			$visotype$elm_dom$Dom$addClass,
			'level',
			$visotype$elm_dom$Dom$element('div')));
};
var $visotype$elm_dom$Dom$setId = function (s) {
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{hj: s});
		});
};
var $author$project$Styles$shadow = $visotype$elm_dom$Dom$addStyle(
	_Utils_Tuple2('box-shadow', '3px 3px 10px 0 rgba(0,0,0,0.50)'));
var $author$project$Styles$square = function (elementSize) {
	return $visotype$elm_dom$Dom$addStyleList(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'width',
				$elm$core$String$fromInt(elementSize) + 'px'),
				_Utils_Tuple2(
				'height',
				$elm$core$String$fromInt(elementSize) + 'px')
			]));
};
var $avh4$elm_color$Color$rgba = F4(
	function (r, g, b, a) {
		return A4($avh4$elm_color$Color$RgbaSpace, r, g, b, a);
	});
var $author$project$ES$UI$Color$transparent = A4($avh4$elm_color$Color$rgba, 0.0, 0.0, 0.0, 0.0);
var $author$project$Component$Header$view = F2(
	function (_v0, maybeTitle) {
		var firstName = _v0.g5;
		var lastName = _v0.hv;
		var titleElement = function (title) {
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('position', 'relative'),
						_Utils_Tuple2('top', '-2px'),
						_Utils_Tuple2('padding-left', '15px'),
						_Utils_Tuple2('max-width', '650px')
					]),
				A2(
					$author$project$Component$Text$withSize,
					4,
					A2(
						$visotype$elm_dom$Dom$appendText,
						title,
						$visotype$elm_dom$Dom$element('div'))));
		};
		var titleContent = function () {
			if (!maybeTitle.$) {
				var title = maybeTitle.a;
				return $author$project$Styles$shadow(
					A2(
						$author$project$Styles$filled,
						$author$project$ES$UI$Color$white,
						A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2('min-height', '40px'),
									_Utils_Tuple2('border-bottom-right-radius', '40px'),
									_Utils_Tuple2('padding-right', '30px')
								]),
							A2(
								$visotype$elm_dom$Dom$addClassList,
								_List_fromArray(
									['has-text-grey-light', 'is-marginless']),
								A2(
									$visotype$elm_dom$Dom$appendChild,
									titleElement(title),
									$visotype$elm_dom$Dom$element('div'))))));
			} else {
				return $visotype$elm_dom$Dom$element('span');
			}
		}();
		var studentName = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('position', 'relative'),
					_Utils_Tuple2('top', '-2px'),
					_Utils_Tuple2('padding-right', '15px')
				]),
			A2(
				$author$project$Component$Text$withSize,
				4,
				A2(
					$visotype$elm_dom$Dom$appendText,
					firstName + (' ' + (A2($elm$core$String$left, 1, lastName) + '.')),
					$visotype$elm_dom$Dom$element('div'))));
		var exitButton = A2(
			$author$project$Dom$Extra$addDataCy,
			'open-logout-modal-button',
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('position', 'relative'),
						_Utils_Tuple2('left', '0')
					]),
				A2(
					$visotype$elm_dom$Dom$addClassList,
					_List_fromArray(
						['is-paddingless', 'has-pointer-events-enabled']),
					A2(
						$author$project$Styles$filled,
						$author$project$ES$UI$Color$transparent,
						A2(
							$author$project$Styles$square,
							40,
							$author$project$Views$Components$Button$WithGlobalMsg$close($author$project$Messages$OpenLogoutModal))))));
		var userContent = A2(
			$visotype$elm_dom$Dom$setId,
			'navigation-student-name',
			$author$project$Styles$shadow(
				A2(
					$author$project$Styles$filled,
					$author$project$ES$UI$Color$white,
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('border-bottom-left-radius', '40px'),
								_Utils_Tuple2('padding-left', '30px'),
								_Utils_Tuple2('min-height', '40px')
							]),
						A2(
							$visotype$elm_dom$Dom$addClassList,
							_List_fromArray(
								['has-text-grey-light', 'is-marginless']),
							A2(
								$visotype$elm_dom$Dom$appendChildList,
								_List_fromArray(
									[studentName, exitButton]),
								$visotype$elm_dom$Dom$element('div')))))));
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'top-bar-student-name',
			A2(
				$author$project$Styles$onTop,
				1001,
				A2(
					$author$project$Styles$size,
					{hb: '40px', jS: 'max-content'},
					A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('top', '0'),
						A2(
							$visotype$elm_dom$Dom$addClassList,
							_List_fromArray(
								['is-absolute', 'has-pointer-events-disabled', 'es-top-bar-student-name']),
							A2(
								$visotype$elm_dom$Dom$appendChild,
								$author$project$Styles$fullWidth(
									A2(
										$visotype$elm_dom$Dom$addClass,
										'is-mobile',
										$author$project$Component$Layout$level(
											{
												gz: _List_Nil,
												hx: _List_fromArray(
													[titleContent]),
												iJ: _List_fromArray(
													[userContent])
											}))),
								$visotype$elm_dom$Dom$element('div')))))));
	});
var $author$project$JoinCode$Messages$ExitJoinScreen = {$: 4};
var $author$project$Component$Buttons$Button$Green = 2;
var $author$project$Component$Buttons$Contained$Right = 1;
var $author$project$JoinCode$Messages$SubmitJoinRequest = {$: 1};
var $author$project$JoinCode$Messages$UpdateLoginJoinCode = function (a) {
	return {$: 0, a: a};
};
var $author$project$Styles$activityButton = $visotype$elm_dom$Dom$addStyleList(
	_List_fromArray(
		[
			_Utils_Tuple2('position', 'absolute'),
			_Utils_Tuple2('bottom', '15px')
		]));
var $visotype$elm_dom$Dom$addAttributeList = function (la) {
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					aK: A2($elm$core$List$append, n.aK, la)
				});
		});
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $visotype$elm_dom$Dom$Internal$captureStopPropagation = F2(
	function (_v0, token) {
		var field = _v0.a;
		var decoder = _v0.b;
		return $elm$virtual_dom$VirtualDom$MayStopPropagation(
			A2(
				$elm$json$Json$Decode$map,
				function (x) {
					return _Utils_Tuple2(x, true);
				},
				A2(
					$elm$json$Json$Decode$map,
					token,
					A2(
						$elm$json$Json$Decode$at,
						_List_fromArray(
							['target', field]),
						decoder))));
	});
var $visotype$elm_dom$Dom$addInputHandler = function (token) {
	var handler = $visotype$elm_dom$Dom$Internal$captureStopPropagation(
		_Utils_Tuple2('value', $elm$json$Json$Decode$string));
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					c: A2(
						$elm$core$List$append,
						n.c,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'input',
								handler(token))
							]))
				});
		});
};
var $visotype$elm_dom$Dom$addStyleConditional = F2(
	function (kv, test) {
		if (test) {
			return $visotype$elm_dom$Dom$addStyle(kv);
		} else {
			return function (x) {
				return x;
			};
		}
	});
var $author$project$Component$Buttons$Contained$Left = 0;
var $author$project$Component$Icon$renderLeft = function (_v0) {
	var glyph = _v0.a;
	var size = _v0.b;
	return A2(
		$author$project$Component$Icon$addSizeClass,
		size,
		A2(
			$author$project$Component$Icon$addGlyphClass,
			glyph,
			A2(
				$visotype$elm_dom$Dom$addClassList,
				_List_fromArray(
					['es-icon', 'es-icon-left']),
				$visotype$elm_dom$Dom$element('i'))));
};
var $author$project$Component$Buttons$Contained$addOptionalIcon = F3(
	function (maybeIcon, iconPosition, displayPosition) {
		var _v0 = _Utils_Tuple2(
			maybeIcon,
			_Utils_eq(iconPosition, displayPosition));
		if ((!_v0.a.$) && _v0.b) {
			var icon = _v0.a.a;
			return (!iconPosition) ? $visotype$elm_dom$Dom$appendChild(
				$author$project$Component$Icon$renderLeft(icon)) : $visotype$elm_dom$Dom$appendChild(
				$author$project$Component$Icon$render(icon));
		} else {
			return $elm$core$Basics$identity;
		}
	});
var $author$project$Component$Buttons$Contained$build = F2(
	function (loadingState, _v0) {
		var color = _v0.gG;
		var text = _v0.ju;
		var maybeIcon = _v0.hI;
		var iconPosition = _v0.hi;
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Component$Buttons$Button$applyBase,
			A2(
				$elm$core$Basics$composeR,
				$visotype$elm_dom$Dom$addClass('es-contained-button'),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Component$Buttons$Button$addColorClass(color),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Component$Buttons$Button$addLoadingClass(loadingState),
						A2(
							$elm$core$Basics$composeR,
							$visotype$elm_dom$Dom$appendChildList($author$project$Component$Buttons$Button$loadingLayers),
							A2(
								$elm$core$Basics$composeR,
								A3($author$project$Component$Buttons$Contained$addOptionalIcon, maybeIcon, iconPosition, 0),
								A2(
									$elm$core$Basics$composeR,
									$visotype$elm_dom$Dom$appendChild(
										A2(
											$visotype$elm_dom$Dom$appendText,
											text,
											$visotype$elm_dom$Dom$element('span'))),
									A3($author$project$Component$Buttons$Contained$addOptionalIcon, maybeIcon, iconPosition, 1))))))));
	});
var $author$project$Component$Buttons$Contained$button = F2(
	function (loadingState, props) {
		return A3(
			$author$project$Component$Buttons$Contained$build,
			loadingState,
			props,
			$visotype$elm_dom$Dom$element('button'));
	});
var $author$project$JoinCode$errorNotification = function (text) {
	return A2(
		$visotype$elm_dom$Dom$addClassList,
		_List_fromArray(
			['notification', 'login--error']),
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('background', '#FFD054'),
					_Utils_Tuple2('font-size', '24px'),
					_Utils_Tuple2('line-height', '36px'),
					_Utils_Tuple2('padding', '15px 25px')
				]),
			A2(
				$visotype$elm_dom$Dom$appendText,
				text,
				$visotype$elm_dom$Dom$element('div'))));
};
var $author$project$JoinCode$errorToString = function (error) {
	switch (error.$) {
		case 0:
			return 'Oops! You forgot to enter a code. Try again or ask your teacher for help.';
		case 1:
			return 'Oops! That code doesn\'t seem to be valid. Try again or ask your teacher for help.';
		case 2:
			return 'Oops! We had a problem logging you in. Please go back to the login page and try again.';
		case 3:
			return 'Oops! We didn\'t find your login information. Please go back to the login page and try again';
		default:
			return 'Oops! Something went wrong. Try again or ask your teacher for help.';
	}
};
var $krisajenkins$remotedata$RemoteData$isLoading = function (data) {
	if (data.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $krisajenkins$remotedata$RemoteData$isNotAsked = function (data) {
	if (!data.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Component$Buttons$Button$Loading = 1;
var $author$project$Component$Buttons$Button$loadingStateFromDisabledState = function (buttonsDisabled) {
	return buttonsDisabled ? 1 : 0;
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$Component$Icon$ArrowLeft = 4;
var $author$project$Component$Icon$Large = {$: 2};
var $author$project$Component$Buttons$Action$Small = 0;
var $author$project$Component$Buttons$Button$Teal = 0;
var $author$project$Component$Buttons$Action$addSizeClass = function (size) {
	if (!size) {
		return $elm$core$Basics$identity;
	} else {
		return $visotype$elm_dom$Dom$addClass('es-action-button--lg');
	}
};
var $author$project$Component$Text$light = $visotype$elm_dom$Dom$addClass('has-text-light');
var $author$project$Component$Buttons$Action$build = F2(
	function (loadingState, _v0) {
		var size = _v0.a;
		var color = _v0.b;
		var icon = _v0.c;
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Component$Buttons$Button$applyBase,
			A2(
				$elm$core$Basics$composeR,
				$visotype$elm_dom$Dom$addClass('es-action-button'),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Component$Buttons$Action$addSizeClass(size),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Component$Buttons$Button$addColorClass(color),
						A2(
							$elm$core$Basics$composeR,
							$author$project$Component$Text$light,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Component$Buttons$Button$addLoadingClass(loadingState),
								$visotype$elm_dom$Dom$appendChildList(
									_Utils_ap(
										$author$project$Component$Buttons$Button$loadingLayers,
										_List_fromArray(
											[
												$author$project$Component$Icon$render(icon)
											])))))))));
	});
var $author$project$Component$Buttons$Action$button = F2(
	function (loadingState, details) {
		return A3(
			$author$project$Component$Buttons$Action$build,
			loadingState,
			details,
			$visotype$elm_dom$Dom$element('button'));
	});
var $author$project$Views$Components$Button$WithGlobalMsg$previous = function (message) {
	return A2(
		$visotype$elm_dom$Dom$addStyle,
		_Utils_Tuple2('transform', 'scaleX(0.625) scaleY(0.625)'),
		A2(
			$author$project$Dom$Extra$addClickAction,
			message,
			A2(
				$author$project$Component$Buttons$Action$button,
				0,
				_Utils_Tuple3(
					0,
					0,
					A2($author$project$Component$Icon$Icon, 4, $author$project$Component$Icon$Large)))));
};
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$JoinCode$form = function (model) {
	var previousButton = A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('left', '20px'),
				_Utils_Tuple2('z-index', '1002')
			]),
		$author$project$Styles$activityButton(
			A2(
				$visotype$elm_dom$Dom$addClass,
				'back-button',
				$author$project$Views$Components$Button$WithGlobalMsg$previous($author$project$JoinCode$Messages$ExitJoinScreen))));
	var loginButtonTitle = function () {
		var _v1 = model.cZ;
		if (_v1.$ === 1) {
			return 'Joining Class...';
		} else {
			return 'Join Class';
		}
	}();
	var joinCodeSubmitDisabled = $krisajenkins$remotedata$RemoteData$isNotAsked(model.cZ) && ($elm$core$String$length(model.ht) < 5);
	var submitButton = A2(
		$visotype$elm_dom$Dom$setId,
		'submit-joincode',
		A3(
			$visotype$elm_dom$Dom$addStyleConditional,
			_Utils_Tuple2('visibility', 'hidden'),
			joinCodeSubmitDisabled,
			A2(
				$visotype$elm_dom$Dom$addAction,
				_Utils_Tuple2('click', $author$project$JoinCode$Messages$SubmitJoinRequest),
				A2(
					$author$project$Component$Buttons$Contained$button,
					$author$project$Component$Buttons$Button$loadingStateFromDisabledState(
						$krisajenkins$remotedata$RemoteData$isLoading(model.cZ)),
					{gG: 2, hi: 1, hI: $elm$core$Maybe$Nothing, ju: loginButtonTitle}))));
	var inputField = A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('height', '80px'),
				_Utils_Tuple2('font-size', '36px'),
				_Utils_Tuple2('line-height', '48px')
			]),
		A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['input', 'is-medium', 'login-input']),
			$visotype$elm_dom$Dom$element('input')));
	var formInstructions = A2(
		$author$project$Component$Text$withSize,
		4,
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('text-transform', 'uppercase'),
					_Utils_Tuple2('text-align', 'left'),
					_Utils_Tuple2('color', 'white'),
					_Utils_Tuple2('margin-bottom', '15px')
				]),
			A2(
				$visotype$elm_dom$Dom$appendText,
				'Enter Your Join Code',
				$visotype$elm_dom$Dom$element('div'))));
	var field = function (input) {
		return A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['field', 'is-centered']),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				input,
				$visotype$elm_dom$Dom$element('div')));
	};
	var addErrorIfPresent = F2(
		function (errorType, element) {
			var _v0 = model.cZ;
			if (_v0.$ === 2) {
				var error = _v0.a;
				return _Utils_eq(error, errorType) ? A2(
					$visotype$elm_dom$Dom$appendChildList,
					_List_fromArray(
						[
							element,
							$author$project$JoinCode$errorNotification(
							$author$project$JoinCode$errorToString(error))
						]),
					$visotype$elm_dom$Dom$element('div')) : element;
			} else {
				return element;
			}
		});
	var joinCodeInput = function () {
		var authKeycodeForEnter = 13;
		var ifEnterPressed = function (authKeycode) {
			return _Utils_eq(authKeycode, authKeycodeForEnter) ? $elm$json$Json$Decode$succeed($author$project$JoinCode$Messages$SubmitJoinRequest) : $elm$json$Json$Decode$fail('not an enter');
		};
		return A2(
			addErrorIfPresent,
			$author$project$Models$JoinCodeError$UnableToSave,
			A2(
				addErrorIfPresent,
				$author$project$Models$JoinCodeError$NoInProgress,
				A2(
					addErrorIfPresent,
					$author$project$Models$JoinCodeError$KeyMissing,
					A2(
						addErrorIfPresent,
						$author$project$Models$JoinCodeError$UnknownCode,
						A2(
							addErrorIfPresent,
							$author$project$Models$JoinCodeError$CodeMissing,
							A2(
								$visotype$elm_dom$Dom$setId,
								'joinCode',
								A2(
									$visotype$elm_dom$Dom$addInputHandler,
									$author$project$JoinCode$Messages$UpdateLoginJoinCode,
									A2(
										$visotype$elm_dom$Dom$addAttributeList,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$placeholder('JOINCODE'),
												$elm$html$Html$Attributes$value(model.ht),
												A2(
												$elm$html$Html$Events$on,
												'keydown',
												A2($elm$json$Json$Decode$andThen, ifEnterPressed, $elm$html$Html$Events$keyCode)),
												A2($elm$html$Html$Attributes$attribute, 'aria-label', 'Join Code')
											]),
										inputField))))))));
	}();
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[
				formInstructions,
				field(joinCodeInput),
				field(submitButton),
				previousButton
			]),
		$visotype$elm_dom$Dom$element('div'));
};
var $author$project$Assets$loginBackground = require('/usr/src/app/app/images/login-background.png');
var $author$project$Assets$esparkLogoHorizontal = require('/usr/src/app/app/images/logo-horizontal.svg');
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$Attributes$height = function (n) {
	return A2(
		_VirtualDom_attribute,
		'height',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$html$Html$Attributes$width = function (n) {
	return A2(
		_VirtualDom_attribute,
		'width',
		$elm$core$String$fromInt(n));
};
var $author$project$Component$Image$render = function (args) {
	return A2(
		$visotype$elm_dom$Dom$addAttributeList,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$src(args.ig),
				$elm$html$Html$Attributes$alt(args.f5),
				$elm$html$Html$Attributes$width(args.jS),
				$elm$html$Html$Attributes$height(args.hb)
			]),
		$visotype$elm_dom$Dom$element('img'));
};
var $author$project$JoinCode$logo = function () {
	var eSparkLogo = $author$project$Component$Image$render(
		{
			f5: 'eSpark Logo',
			hb: 120,
			ig: $author$project$Assets$path($author$project$Assets$esparkLogoHorizontal),
			jS: 316
		});
	return A2(
		$visotype$elm_dom$Dom$addStyle,
		_Utils_Tuple2('margin-bottom', '40px'),
		A2(
			$visotype$elm_dom$Dom$appendChild,
			eSparkLogo,
			$visotype$elm_dom$Dom$element('div')));
}();
var $author$project$JoinCode$view = function (model) {
	var content = A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('width', '540px'),
				_Utils_Tuple2('text-align', 'center'),
				_Utils_Tuple2('margin', '0 auto')
			]),
		A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					$author$project$JoinCode$logo,
					$author$project$JoinCode$form(model)
				]),
			$visotype$elm_dom$Dom$element('div')));
	return $visotype$elm_dom$Dom$render(
		A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['hero', 'is-fullheight']),
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2(
						'background',
						'url(\'' + ($author$project$Assets$path($author$project$Assets$loginBackground) + '\')')),
						_Utils_Tuple2('padding-top', '10vh')
					]),
				A2(
					$visotype$elm_dom$Dom$appendChild,
					content,
					$visotype$elm_dom$Dom$element('section')))));
};
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Activities$ChoiceText$Model$choiceTextMode = function (model) {
	if (model.$ === 1) {
		var mode = model.a;
		return mode;
	} else {
		var mode = model.a;
		return mode;
	}
};
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$iframe = _VirtualDom_node('iframe');
var $author$project$Activities$ChoiceText$Model$modeToIdentifier = function (model) {
	var _v0 = $author$project$Activities$ChoiceText$Model$choiceTextMode(model);
	if (!_v0.$) {
		var studentActivityId = _v0.a;
		return $author$project$Models$Ids$toString(studentActivityId);
	} else {
		var sectionId = _v0.a;
		return $author$project$Models$Ids$toString(sectionId);
	}
};
var $author$project$Activities$ChoiceText$iframeId = function (model) {
	return 'choice-text-' + $author$project$Activities$ChoiceText$Model$modeToIdentifier(model);
};
var $author$project$Activities$ChoiceText$Model$libraryPath = function (sectionId) {
	return '/library/' + $author$project$Models$Ids$toString(sectionId);
};
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Activities$ChoiceText$View$viewLibrary = F2(
	function (model, choiceTextBaseUrl) {
		var _v0 = $author$project$Activities$ChoiceText$Model$choiceTextMode(model);
		if (!_v0.$) {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		} else {
			var sectionId = _v0.a;
			return A2(
				$elm$html$Html$iframe,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'width', '100vw'),
						A2($elm$html$Html$Attributes$style, 'height', '100vh'),
						$elm$html$Html$Attributes$src(
						_Utils_ap(
							choiceTextBaseUrl,
							$author$project$Activities$ChoiceText$Model$libraryPath(sectionId))),
						$elm$html$Html$Attributes$id(
						$author$project$Activities$ChoiceText$iframeId(model))
					]),
				_List_Nil);
		}
	});
var $author$project$Views$ClassLibrary$view = F2(
	function (model, choiceTextBaseUrl) {
		return A2(
			$elm$html$Html$section,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$Activities$ChoiceText$View$viewLibrary, model, choiceTextBaseUrl)
				]));
	});
var $author$project$Views$NotFoundView$notFoundMessage = A2(
	$visotype$elm_dom$Dom$addStyle,
	_Utils_Tuple2('padding', '20px'),
	A2(
		$visotype$elm_dom$Dom$addClass,
		'not-found',
		A2(
			$visotype$elm_dom$Dom$appendText,
			'Oops! Looks like you\'re in the wrong place.',
			$visotype$elm_dom$Dom$element('div'))));
var $author$project$Views$NotFoundView$view = A2(
	$visotype$elm_dom$Dom$appendChild,
	$author$project$Views$NotFoundView$notFoundMessage,
	$visotype$elm_dom$Dom$element('div'));
var $author$project$Models$AssessmentStatus$AssessmentPreviewMode = 0;
var $author$project$Models$AssessmentStatus$AssessmentRegularMode = 1;
var $author$project$LoggedIn$Messages$CharacterSelectionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$LoggedIn$Messages$NpsSurveyMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Helpers$RecordFinders$findStudentActivityFromActiveQuest = function (studentActivityId) {
	return A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.jd;
		},
		$author$project$Models$Ids$findById(studentActivityId));
};
var $author$project$Screen$activityView = F4(
	function (activeQuest, studentActivityId, viewData, viewFunction) {
		var _v0 = A2($author$project$Helpers$RecordFinders$findStudentActivityFromActiveQuest, studentActivityId, activeQuest);
		if (_v0.$ === 1) {
			return A2(
				$visotype$elm_dom$Dom$appendText,
				'no activity found! Replace me with the not found view!',
				$visotype$elm_dom$Dom$element('span'));
		} else {
			var aStudentActivity = _v0.a;
			return A3(viewFunction, aStudentActivity, activeQuest, viewData);
		}
	});
var $author$project$Dom$Extra$addClassIfElse = F2(
	function (_v0, condition) {
		var classIfTrue = _v0.a;
		var classIfFalse = _v0.b;
		return condition ? $visotype$elm_dom$Dom$addClass(classIfTrue) : $visotype$elm_dom$Dom$addClass(classIfFalse);
	});
var $author$project$Models$SeasonalTheme$Normal = 0;
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$isBetween = F3(
	function (_v0, _v1, _v2) {
		var a = _v0;
		var b = _v1;
		var x = _v2;
		return A3($justinmimbs$date$Date$isBetweenInt, a, b, x);
	});
var $author$project$Models$SeasonalTheme$Fall = 1;
var $author$project$Models$SeasonalTheme$Halloween = 2;
var $author$project$Models$SeasonalTheme$Winter = 3;
var $author$project$Models$SeasonalTheme$seasonalRanges = _List_fromArray(
	[
		_Utils_Tuple3(
		_Utils_Tuple2(0, 1),
		_Utils_Tuple2(2, 20),
		3),
		_Utils_Tuple3(
		_Utils_Tuple2(8, 21),
		_Utils_Tuple2(9, 15),
		1),
		_Utils_Tuple3(
		_Utils_Tuple2(9, 16),
		_Utils_Tuple2(9, 31),
		2),
		_Utils_Tuple3(
		_Utils_Tuple2(10, 1),
		_Utils_Tuple2(11, 20),
		1),
		_Utils_Tuple3(
		_Utils_Tuple2(11, 21),
		_Utils_Tuple2(11, 31),
		3)
	]);
var $author$project$Models$SeasonalTheme$findMatchingTheme = function (date) {
	var year = $justinmimbs$date$Date$year(date);
	var isBetween = function (_v1) {
		var _v2 = _v1.a;
		var startMonth = _v2.a;
		var startDay = _v2.b;
		var _v3 = _v1.b;
		var endMonth = _v3.a;
		var endDay = _v3.b;
		return A3(
			$justinmimbs$date$Date$isBetween,
			A3($justinmimbs$date$Date$fromCalendarDate, year, startMonth, startDay),
			A3($justinmimbs$date$Date$fromCalendarDate, year, endMonth, endDay),
			date);
	};
	return A2(
		$elm$core$Maybe$map,
		function (_v0) {
			var theme = _v0.c;
			return theme;
		},
		A2($elm_community$list_extra$List$Extra$find, isBetween, $author$project$Models$SeasonalTheme$seasonalRanges));
};
var $author$project$Models$SeasonalTheme$fromDate = A2(
	$elm$core$Basics$composeL,
	$elm$core$Maybe$withDefault(0),
	$author$project$Models$SeasonalTheme$findMatchingTheme);
var $author$project$Assets$AvatarTheme$alien = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Nothing,
			E: function () {
				var normal = require('/usr/src/app/app/images/avatar-themes/alien/cool.svg');
				switch (seasonalTheme) {
					case 0:
						return normal;
					case 1:
						return normal;
					case 2:
						return normal;
					default:
						return require('/usr/src/app/app/images/avatar-themes/alien/winter.svg');
				}
			}()
		},
		v: 0,
		w: function () {
			var normal = require('/usr/src/app/app/images/avatar-themes/alien/character-choice.svg');
			switch (seasonalTheme) {
				case 0:
					return normal;
				case 1:
					return normal;
				case 2:
					return normal;
				default:
					return require('/usr/src/app/app/images/avatar-themes/alien/character-choice-winter.svg');
			}
		}(),
		x: require('/usr/src/app/app/images/avatar-themes/alien/corner.svg'),
		y: require('/usr/src/app/app/images/avatar-themes/alien/handshake.svg'),
		A: require('/usr/src/app/app/images/avatar-themes/alien/high-five.svg'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/alien/mood-bored.png'), m: require('/usr/src/app/app/images/avatar-themes/alien/mood-cool.png'), n: require('/usr/src/app/app/images/avatar-themes/alien/mood-excited.png'), o: require('/usr/src/app/app/images/avatar-themes/alien/mood-happy.png'), p: require('/usr/src/app/app/images/avatar-themes/alien/mood-mad.png'), q: require('/usr/src/app/app/images/avatar-themes/alien/mood-okay.png'), r: require('/usr/src/app/app/images/avatar-themes/alien/mood-proud.png'), s: require('/usr/src/app/app/images/avatar-themes/alien/mood-sad.png')}
	};
};
var $author$project$Assets$AvatarTheme$aliennew = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Just(
				_Utils_Tuple2(require('/usr/src/app/app/images/avatar-themes/alien2/station-head.png'), require('/usr/src/app/app/images/avatar-themes/alien2/station-body.png'))),
			E: require('/usr/src/app/app/images/avatar-themes/alien2/station-min.png')
		},
		v: 8,
		w: require('/usr/src/app/app/images/avatar-themes/alien2/avatar-min.png'),
		x: require('/usr/src/app/app/images/avatar-themes/alien2/peek-min.png'),
		y: require('/usr/src/app/app/images/avatar-themes/alien2/avatar-min.png'),
		A: require('/usr/src/app/app/images/avatar-themes/alien2/avatar-min.png'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/alien2/bored-min.png'), m: require('/usr/src/app/app/images/avatar-themes/alien2/cool-min.png'), n: require('/usr/src/app/app/images/avatar-themes/alien2/excited-min.png'), o: require('/usr/src/app/app/images/avatar-themes/alien2/happy-min.png'), p: require('/usr/src/app/app/images/avatar-themes/alien2/mad-min.png'), q: require('/usr/src/app/app/images/avatar-themes/alien2/okay-min.png'), r: require('/usr/src/app/app/images/avatar-themes/alien2/proud-min.png'), s: require('/usr/src/app/app/images/avatar-themes/alien2/sad-min.png')}
	};
};
var $author$project$Assets$AvatarTheme$axolotl = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Just(
				_Utils_Tuple2(require('/usr/src/app/app/images/avatar-themes/axolotl/station-head.png'), require('/usr/src/app/app/images/avatar-themes/axolotl/station-body.png'))),
			E: require('/usr/src/app/app/images/avatar-themes/axolotl/station-min.png')
		},
		v: 9,
		w: require('/usr/src/app/app/images/avatar-themes/axolotl/avatar-min.png'),
		x: require('/usr/src/app/app/images/avatar-themes/axolotl/peek-min.png'),
		y: require('/usr/src/app/app/images/avatar-themes/axolotl/avatar-min.png'),
		A: require('/usr/src/app/app/images/avatar-themes/axolotl/avatar-min.png'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/axolotl/bored-min.png'), m: require('/usr/src/app/app/images/avatar-themes/axolotl/cool-min.png'), n: require('/usr/src/app/app/images/avatar-themes/axolotl/excited-min.png'), o: require('/usr/src/app/app/images/avatar-themes/axolotl/happy-min.png'), p: require('/usr/src/app/app/images/avatar-themes/axolotl/mad-min.png'), q: require('/usr/src/app/app/images/avatar-themes/axolotl/okay-min.png'), r: require('/usr/src/app/app/images/avatar-themes/axolotl/proud-min.png'), s: require('/usr/src/app/app/images/avatar-themes/axolotl/sad-min.png')}
	};
};
var $author$project$Assets$AvatarTheme$dog = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Just(
				_Utils_Tuple2(require('/usr/src/app/app/images/avatar-themes/dog/station-head.png'), require('/usr/src/app/app/images/avatar-themes/dog/station-body.png'))),
			E: require('/usr/src/app/app/images/avatar-themes/dog/station-min.png')
		},
		v: 10,
		w: require('/usr/src/app/app/images/avatar-themes/dog/avatar-min.png'),
		x: require('/usr/src/app/app/images/avatar-themes/dog/peek-min.png'),
		y: require('/usr/src/app/app/images/avatar-themes/dog/avatar-min.png'),
		A: require('/usr/src/app/app/images/avatar-themes/dog/avatar-min.png'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/dog/bored-min.png'), m: require('/usr/src/app/app/images/avatar-themes/dog/cool-min.png'), n: require('/usr/src/app/app/images/avatar-themes/dog/excited-min.png'), o: require('/usr/src/app/app/images/avatar-themes/dog/happy-min.png'), p: require('/usr/src/app/app/images/avatar-themes/dog/mad-min.png'), q: require('/usr/src/app/app/images/avatar-themes/dog/okay-min.png'), r: require('/usr/src/app/app/images/avatar-themes/dog/proud-min.png'), s: require('/usr/src/app/app/images/avatar-themes/dog/sad-min.png')}
	};
};
var $author$project$Assets$AvatarTheme$dragon = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Just(
				_Utils_Tuple2(require('/usr/src/app/app/images/avatar-themes/dragon/station-head.png'), require('/usr/src/app/app/images/avatar-themes/dragon/station-body.png'))),
			E: require('/usr/src/app/app/images/avatar-themes/dragon/station-min.png')
		},
		v: 11,
		w: require('/usr/src/app/app/images/avatar-themes/dragon/avatar-min.png'),
		x: require('/usr/src/app/app/images/avatar-themes/dragon/peek-min.png'),
		y: require('/usr/src/app/app/images/avatar-themes/dragon/avatar-min.png'),
		A: require('/usr/src/app/app/images/avatar-themes/dragon/avatar-min.png'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/dragon/bored-min.png'), m: require('/usr/src/app/app/images/avatar-themes/dragon/cool-min.png'), n: require('/usr/src/app/app/images/avatar-themes/dragon/excited-min.png'), o: require('/usr/src/app/app/images/avatar-themes/dragon/happy-min.png'), p: require('/usr/src/app/app/images/avatar-themes/dragon/mad-min.png'), q: require('/usr/src/app/app/images/avatar-themes/dragon/okay-min.png'), r: require('/usr/src/app/app/images/avatar-themes/dragon/proud-min.png'), s: require('/usr/src/app/app/images/avatar-themes/dragon/sad-min.png')}
	};
};
var $author$project$Assets$AvatarTheme$fox = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Just(
				_Utils_Tuple2(require('/usr/src/app/app/images/avatar-themes/fox/station-head.png'), require('/usr/src/app/app/images/avatar-themes/fox/station-body.png'))),
			E: require('/usr/src/app/app/images/avatar-themes/fox/station-min.png')
		},
		v: 12,
		w: require('/usr/src/app/app/images/avatar-themes/fox/avatar-min.png'),
		x: require('/usr/src/app/app/images/avatar-themes/fox/peek-min.png'),
		y: require('/usr/src/app/app/images/avatar-themes/fox/avatar-min.png'),
		A: require('/usr/src/app/app/images/avatar-themes/fox/avatar-min.png'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/fox/bored-min.png'), m: require('/usr/src/app/app/images/avatar-themes/fox/cool-min.png'), n: require('/usr/src/app/app/images/avatar-themes/fox/excited-min.png'), o: require('/usr/src/app/app/images/avatar-themes/fox/happy-min.png'), p: require('/usr/src/app/app/images/avatar-themes/fox/mad-min.png'), q: require('/usr/src/app/app/images/avatar-themes/fox/okay-min.png'), r: require('/usr/src/app/app/images/avatar-themes/fox/proud-min.png'), s: require('/usr/src/app/app/images/avatar-themes/fox/sad-min.png')}
	};
};
var $author$project$Assets$AvatarTheme$lion = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Just(
				_Utils_Tuple2(require('/usr/src/app/app/images/avatar-themes/lion/station-head.png'), require('/usr/src/app/app/images/avatar-themes/lion/station-body.png'))),
			E: require('/usr/src/app/app/images/avatar-themes/lion/station-min.png')
		},
		v: 13,
		w: require('/usr/src/app/app/images/avatar-themes/lion/avatar-min.png'),
		x: require('/usr/src/app/app/images/avatar-themes/lion/peek-min.png'),
		y: require('/usr/src/app/app/images/avatar-themes/lion/avatar-min.png'),
		A: require('/usr/src/app/app/images/avatar-themes/lion/avatar-min.png'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/lion/bored-min.png'), m: require('/usr/src/app/app/images/avatar-themes/lion/cool-min.png'), n: require('/usr/src/app/app/images/avatar-themes/lion/excited-min.png'), o: require('/usr/src/app/app/images/avatar-themes/lion/happy-min.png'), p: require('/usr/src/app/app/images/avatar-themes/lion/mad-min.png'), q: require('/usr/src/app/app/images/avatar-themes/lion/okay-min.png'), r: require('/usr/src/app/app/images/avatar-themes/lion/proud-min.png'), s: require('/usr/src/app/app/images/avatar-themes/lion/sad-min.png')}
	};
};
var $author$project$Assets$AvatarTheme$panda = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Just(
				_Utils_Tuple2(require('/usr/src/app/app/images/avatar-themes/panda/station-head.png'), require('/usr/src/app/app/images/avatar-themes/panda/station-body.png'))),
			E: require('/usr/src/app/app/images/avatar-themes/panda/station-min.png')
		},
		v: 14,
		w: require('/usr/src/app/app/images/avatar-themes/panda/avatar-min.png'),
		x: require('/usr/src/app/app/images/avatar-themes/panda/peek-min.png'),
		y: require('/usr/src/app/app/images/avatar-themes/panda/avatar-min.png'),
		A: require('/usr/src/app/app/images/avatar-themes/panda/avatar-min.png'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/panda/bored-min.png'), m: require('/usr/src/app/app/images/avatar-themes/panda/cool-min.png'), n: require('/usr/src/app/app/images/avatar-themes/panda/excited-min.png'), o: require('/usr/src/app/app/images/avatar-themes/panda/happy-min.png'), p: require('/usr/src/app/app/images/avatar-themes/panda/mad-min.png'), q: require('/usr/src/app/app/images/avatar-themes/panda/okay-min.png'), r: require('/usr/src/app/app/images/avatar-themes/panda/proud-min.png'), s: require('/usr/src/app/app/images/avatar-themes/panda/sad-min.png')}
	};
};
var $author$project$Assets$AvatarTheme$pogostick = function (seasonalTheme) {
	return {
		u: function () {
			var normal = require('/usr/src/app/app/images/avatar-themes/pogostick/cool.svg');
			return {
				z: $elm$core$Maybe$Nothing,
				E: function () {
					switch (seasonalTheme) {
						case 0:
							return normal;
						case 1:
							return normal;
						case 2:
							return normal;
						default:
							return require('/usr/src/app/app/images/avatar-themes/pogostick/winter.svg');
					}
				}()
			};
		}(),
		v: 1,
		w: function () {
			var normal = require('/usr/src/app/app/images/avatar-themes/pogostick/character-choice.svg');
			switch (seasonalTheme) {
				case 0:
					return normal;
				case 1:
					return normal;
				case 2:
					return normal;
				default:
					return require('/usr/src/app/app/images/avatar-themes/pogostick/character-choice-winter.svg');
			}
		}(),
		x: require('/usr/src/app/app/images/avatar-themes/pogostick/corner.svg'),
		y: require('/usr/src/app/app/images/avatar-themes/pogostick/handshake.svg'),
		A: require('/usr/src/app/app/images/avatar-themes/pogostick/high-five.svg'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/pogostick/mood-bored.png'), m: require('/usr/src/app/app/images/avatar-themes/pogostick/mood-cool.png'), n: require('/usr/src/app/app/images/avatar-themes/pogostick/mood-excited.png'), o: require('/usr/src/app/app/images/avatar-themes/pogostick/mood-happy.png'), p: require('/usr/src/app/app/images/avatar-themes/pogostick/mood-mad.png'), q: require('/usr/src/app/app/images/avatar-themes/pogostick/mood-okay.png'), r: require('/usr/src/app/app/images/avatar-themes/pogostick/mood-proud.png'), s: require('/usr/src/app/app/images/avatar-themes/pogostick/mood-sad.png')}
	};
};
var $author$project$Assets$AvatarTheme$robodog = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Just(
				_Utils_Tuple2(require('/usr/src/app/app/images/avatar-themes/robodog/station-head.png'), require('/usr/src/app/app/images/avatar-themes/robodog/station-body.png'))),
			E: require('/usr/src/app/app/images/avatar-themes/robodog/station-min.png')
		},
		v: 15,
		w: require('/usr/src/app/app/images/avatar-themes/robodog/avatar-min.png'),
		x: require('/usr/src/app/app/images/avatar-themes/robodog/peek-min.png'),
		y: require('/usr/src/app/app/images/avatar-themes/robodog/avatar-min.png'),
		A: require('/usr/src/app/app/images/avatar-themes/robodog/avatar-min.png'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/robodog/bored-min.png'), m: require('/usr/src/app/app/images/avatar-themes/robodog/cool-min.png'), n: require('/usr/src/app/app/images/avatar-themes/robodog/excited-min.png'), o: require('/usr/src/app/app/images/avatar-themes/robodog/happy-min.png'), p: require('/usr/src/app/app/images/avatar-themes/robodog/mad-min.png'), q: require('/usr/src/app/app/images/avatar-themes/robodog/okay-min.png'), r: require('/usr/src/app/app/images/avatar-themes/robodog/proud-min.png'), s: require('/usr/src/app/app/images/avatar-themes/robodog/sad-min.png')}
	};
};
var $author$project$Assets$AvatarTheme$rocky = function (seasonalTheme) {
	return {
		u: function () {
			var normal = require('/usr/src/app/app/images/avatar-themes/rocky/cool.svg');
			return {
				z: $elm$core$Maybe$Nothing,
				E: function () {
					switch (seasonalTheme) {
						case 0:
							return normal;
						case 1:
							return normal;
						case 2:
							return normal;
						default:
							return require('/usr/src/app/app/images/avatar-themes/rocky/winter.svg');
					}
				}()
			};
		}(),
		v: 2,
		w: function () {
			var normal = require('/usr/src/app/app/images/avatar-themes/rocky/character-choice.svg');
			switch (seasonalTheme) {
				case 0:
					return normal;
				case 1:
					return normal;
				case 2:
					return normal;
				default:
					return require('/usr/src/app/app/images/avatar-themes/rocky/character-choice-winter.svg');
			}
		}(),
		x: require('/usr/src/app/app/images/avatar-themes/rocky/corner.svg'),
		y: require('/usr/src/app/app/images/avatar-themes/rocky/handshake.svg'),
		A: require('/usr/src/app/app/images/avatar-themes/rocky/high-five.svg'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/rocky/mood-bored.png'), m: require('/usr/src/app/app/images/avatar-themes/rocky/mood-cool.png'), n: require('/usr/src/app/app/images/avatar-themes/rocky/mood-excited.png'), o: require('/usr/src/app/app/images/avatar-themes/rocky/mood-happy.png'), p: require('/usr/src/app/app/images/avatar-themes/rocky/mood-mad.png'), q: require('/usr/src/app/app/images/avatar-themes/rocky/mood-okay.png'), r: require('/usr/src/app/app/images/avatar-themes/rocky/mood-proud.png'), s: require('/usr/src/app/app/images/avatar-themes/rocky/mood-sad.png')}
	};
};
var $author$project$Assets$AvatarTheme$rollerskate = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Nothing,
			E: function () {
				var normal = require('/usr/src/app/app/images/avatar-themes/rollerskate/cool.svg');
				switch (seasonalTheme) {
					case 0:
						return normal;
					case 1:
						return normal;
					case 2:
						return normal;
					default:
						return require('/usr/src/app/app/images/avatar-themes/rollerskate/winter.svg');
				}
			}()
		},
		v: 3,
		w: function () {
			var normal = require('/usr/src/app/app/images/avatar-themes/rollerskate/character-choice.svg');
			switch (seasonalTheme) {
				case 0:
					return normal;
				case 1:
					return normal;
				case 2:
					return normal;
				default:
					return require('/usr/src/app/app/images/avatar-themes/rollerskate/character-choice-winter.svg');
			}
		}(),
		x: require('/usr/src/app/app/images/avatar-themes/rollerskate/corner.svg'),
		y: require('/usr/src/app/app/images/avatar-themes/rollerskate/handshake.svg'),
		A: require('/usr/src/app/app/images/avatar-themes/rollerskate/high-five.svg'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/rollerskate/mood-bored.png'), m: require('/usr/src/app/app/images/avatar-themes/rollerskate/mood-cool.png'), n: require('/usr/src/app/app/images/avatar-themes/rollerskate/mood-excited.png'), o: require('/usr/src/app/app/images/avatar-themes/rollerskate/mood-happy.png'), p: require('/usr/src/app/app/images/avatar-themes/rollerskate/mood-mad.png'), q: require('/usr/src/app/app/images/avatar-themes/rollerskate/mood-okay.png'), r: require('/usr/src/app/app/images/avatar-themes/rollerskate/mood-proud.png'), s: require('/usr/src/app/app/images/avatar-themes/rollerskate/mood-sad.png')}
	};
};
var $author$project$Assets$AvatarTheme$scooter = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Nothing,
			E: function () {
				var normal = require('/usr/src/app/app/images/avatar-themes/scooter/cool.svg');
				switch (seasonalTheme) {
					case 0:
						return normal;
					case 1:
						return normal;
					case 2:
						return normal;
					default:
						return require('/usr/src/app/app/images/avatar-themes/scooter/winter.svg');
				}
			}()
		},
		v: 4,
		w: function () {
			var normal = require('/usr/src/app/app/images/avatar-themes/scooter/character-choice.svg');
			switch (seasonalTheme) {
				case 0:
					return normal;
				case 1:
					return normal;
				case 2:
					return normal;
				default:
					return require('/usr/src/app/app/images/avatar-themes/scooter/character-choice-winter.svg');
			}
		}(),
		x: require('/usr/src/app/app/images/avatar-themes/scooter/corner.svg'),
		y: require('/usr/src/app/app/images/avatar-themes/scooter/handshake.svg'),
		A: require('/usr/src/app/app/images/avatar-themes/scooter/high-five.svg'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/scooter/mood-bored.png'), m: require('/usr/src/app/app/images/avatar-themes/scooter/mood-cool.png'), n: require('/usr/src/app/app/images/avatar-themes/scooter/mood-excited.png'), o: require('/usr/src/app/app/images/avatar-themes/scooter/mood-happy.png'), p: require('/usr/src/app/app/images/avatar-themes/scooter/mood-mad.png'), q: require('/usr/src/app/app/images/avatar-themes/scooter/mood-okay.png'), r: require('/usr/src/app/app/images/avatar-themes/scooter/mood-proud.png'), s: require('/usr/src/app/app/images/avatar-themes/scooter/mood-sad.png')}
	};
};
var $author$project$Assets$AvatarTheme$skateboard = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Nothing,
			E: function () {
				var normal = require('/usr/src/app/app/images/avatar-themes/skateboard/cool.svg');
				switch (seasonalTheme) {
					case 0:
						return normal;
					case 1:
						return normal;
					case 2:
						return normal;
					default:
						return require('/usr/src/app/app/images/avatar-themes/skateboard/winter.svg');
				}
			}()
		},
		v: 5,
		w: function () {
			var normal = require('/usr/src/app/app/images/avatar-themes/skateboard/character-choice.svg');
			switch (seasonalTheme) {
				case 0:
					return normal;
				case 1:
					return normal;
				case 2:
					return normal;
				default:
					return require('/usr/src/app/app/images/avatar-themes/skateboard/character-choice-winter.svg');
			}
		}(),
		x: require('/usr/src/app/app/images/avatar-themes/pogostick/corner.svg'),
		y: require('/usr/src/app/app/images/avatar-themes/skateboard/handshake.svg'),
		A: require('/usr/src/app/app/images/avatar-themes/skateboard/high-five.svg'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/skateboard/mood-bored.png'), m: require('/usr/src/app/app/images/avatar-themes/skateboard/mood-cool.png'), n: require('/usr/src/app/app/images/avatar-themes/skateboard/mood-excited.png'), o: require('/usr/src/app/app/images/avatar-themes/skateboard/mood-happy.png'), p: require('/usr/src/app/app/images/avatar-themes/skateboard/mood-mad.png'), q: require('/usr/src/app/app/images/avatar-themes/skateboard/mood-okay.png'), r: require('/usr/src/app/app/images/avatar-themes/skateboard/mood-proud.png'), s: require('/usr/src/app/app/images/avatar-themes/skateboard/mood-sad.png')}
	};
};
var $author$project$Assets$AvatarTheme$springshoes = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Nothing,
			E: function () {
				var normal = require('/usr/src/app/app/images/avatar-themes/springshoes/cool.svg');
				switch (seasonalTheme) {
					case 0:
						return normal;
					case 1:
						return normal;
					case 2:
						return normal;
					default:
						return require('/usr/src/app/app/images/avatar-themes/springshoes/winter.svg');
				}
			}()
		},
		v: 6,
		w: function () {
			var normal = require('/usr/src/app/app/images/avatar-themes/springshoes/character-choice.svg');
			switch (seasonalTheme) {
				case 0:
					return normal;
				case 1:
					return normal;
				case 2:
					return normal;
				default:
					return require('/usr/src/app/app/images/avatar-themes/springshoes/character-choice-winter.svg');
			}
		}(),
		x: require('/usr/src/app/app/images/avatar-themes/springshoes/corner.svg'),
		y: require('/usr/src/app/app/images/avatar-themes/springshoes/handshake.svg'),
		A: require('/usr/src/app/app/images/avatar-themes/springshoes/high-five.svg'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/springshoes/mood-bored.png'), m: require('/usr/src/app/app/images/avatar-themes/springshoes/mood-cool.png'), n: require('/usr/src/app/app/images/avatar-themes/springshoes/mood-excited.png'), o: require('/usr/src/app/app/images/avatar-themes/springshoes/mood-happy.png'), p: require('/usr/src/app/app/images/avatar-themes/springshoes/mood-mad.png'), q: require('/usr/src/app/app/images/avatar-themes/springshoes/mood-okay.png'), r: require('/usr/src/app/app/images/avatar-themes/springshoes/mood-proud.png'), s: require('/usr/src/app/app/images/avatar-themes/springshoes/mood-sad.png')}
	};
};
var $author$project$Assets$AvatarTheme$unicorn = function (seasonalTheme) {
	return {
		u: {
			z: $elm$core$Maybe$Nothing,
			E: function () {
				var normal = require('/usr/src/app/app/images/avatar-themes/unicorn/cool.svg');
				switch (seasonalTheme) {
					case 0:
						return normal;
					case 1:
						return normal;
					case 2:
						return normal;
					default:
						return require('/usr/src/app/app/images/avatar-themes/unicorn/winter.svg');
				}
			}()
		},
		v: 7,
		w: function () {
			var normal = require('/usr/src/app/app/images/avatar-themes/unicorn/character-choice.svg');
			switch (seasonalTheme) {
				case 0:
					return normal;
				case 1:
					return normal;
				case 2:
					return normal;
				default:
					return require('/usr/src/app/app/images/avatar-themes/unicorn/character-choice-winter.svg');
			}
		}(),
		x: require('/usr/src/app/app/images/avatar-themes/unicorn/corner.svg'),
		y: require('/usr/src/app/app/images/avatar-themes/unicorn/handshake.svg'),
		A: require('/usr/src/app/app/images/avatar-themes/unicorn/high-five.svg'),
		D: {l: require('/usr/src/app/app/images/avatar-themes/unicorn/mood-bored.png'), m: require('/usr/src/app/app/images/avatar-themes/unicorn/mood-cool.png'), n: require('/usr/src/app/app/images/avatar-themes/unicorn/mood-excited.png'), o: require('/usr/src/app/app/images/avatar-themes/unicorn/mood-happy.png'), p: require('/usr/src/app/app/images/avatar-themes/unicorn/mood-mad.png'), q: require('/usr/src/app/app/images/avatar-themes/unicorn/mood-okay.png'), r: require('/usr/src/app/app/images/avatar-themes/unicorn/mood-proud.png'), s: require('/usr/src/app/app/images/avatar-themes/unicorn/mood-sad.png')}
	};
};
var $author$project$Assets$AvatarTheme$characterTheme = F2(
	function (seasonalTheme, character) {
		return function () {
			switch (character) {
				case 0:
					return $author$project$Assets$AvatarTheme$alien;
				case 2:
					return $author$project$Assets$AvatarTheme$rocky;
				case 1:
					return $author$project$Assets$AvatarTheme$pogostick;
				case 3:
					return $author$project$Assets$AvatarTheme$rollerskate;
				case 4:
					return $author$project$Assets$AvatarTheme$scooter;
				case 5:
					return $author$project$Assets$AvatarTheme$skateboard;
				case 6:
					return $author$project$Assets$AvatarTheme$springshoes;
				case 7:
					return $author$project$Assets$AvatarTheme$unicorn;
				case 8:
					return $author$project$Assets$AvatarTheme$aliennew;
				case 9:
					return $author$project$Assets$AvatarTheme$axolotl;
				case 10:
					return $author$project$Assets$AvatarTheme$dog;
				case 11:
					return $author$project$Assets$AvatarTheme$dragon;
				case 12:
					return $author$project$Assets$AvatarTheme$fox;
				case 13:
					return $author$project$Assets$AvatarTheme$lion;
				case 14:
					return $author$project$Assets$AvatarTheme$panda;
				default:
					return $author$project$Assets$AvatarTheme$robodog;
			}
		}()(seasonalTheme);
	});
var $elm_community$maybe_extra$Maybe$Extra$filter = F2(
	function (f, m) {
		var _v0 = A2($elm$core$Maybe$map, f, m);
		if ((!_v0.$) && _v0.a) {
			return m;
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $ianmackenzie$elm_units$Quantity$Quantity = $elm$core$Basics$identity;
var $ianmackenzie$elm_units$Duration$seconds = function (numSeconds) {
	return numSeconds;
};
var $ianmackenzie$elm_units$Duration$milliseconds = function (numMilliseconds) {
	return $ianmackenzie$elm_units$Duration$seconds(0.001 * numMilliseconds);
};
var $ianmackenzie$elm_units$Duration$from = F2(
	function (startTime, endTime) {
		var numMilliseconds = $elm$time$Time$posixToMillis(endTime) - $elm$time$Time$posixToMillis(startTime);
		return $ianmackenzie$elm_units$Duration$milliseconds(numMilliseconds);
	});
var $ianmackenzie$elm_units$Constants$second = 1;
var $ianmackenzie$elm_units$Constants$minute = 60 * $ianmackenzie$elm_units$Constants$second;
var $ianmackenzie$elm_units$Constants$hour = 60 * $ianmackenzie$elm_units$Constants$minute;
var $ianmackenzie$elm_units$Duration$hours = function (numHours) {
	return $ianmackenzie$elm_units$Duration$seconds($ianmackenzie$elm_units$Constants$hour * numHours);
};
var $author$project$Models$LocalStorage$StudentChoices$hoursForCharacterSetToBeConsideredRecent = 10.0;
var $ianmackenzie$elm_units$Quantity$lessThan = F2(
	function (_v0, _v1) {
		var y = _v0;
		var x = _v1;
		return _Utils_cmp(x, y) < 0;
	});
var $author$project$Models$LocalStorage$StudentChoices$getRecentCharacter = F2(
	function (currentTime, _v0) {
		var character = _v0.v;
		var isWithinTenHours = function (storedAt) {
			return A2(
				$ianmackenzie$elm_units$Quantity$lessThan,
				$ianmackenzie$elm_units$Duration$hours($author$project$Models$LocalStorage$StudentChoices$hoursForCharacterSetToBeConsideredRecent),
				A2($ianmackenzie$elm_units$Duration$from, storedAt, currentTime));
		};
		return A2(
			$elm$core$Maybe$map,
			$elm$core$Tuple$second,
			A2(
				$elm_community$maybe_extra$Maybe$Extra$filter,
				A2($elm$core$Basics$composeR, $elm$core$Tuple$first, isWithinTenHours),
				character));
	});
var $author$project$Models$LocalStorage$Helpers$getStudentRecentCharacter = F2(
	function (time, store) {
		return A2($author$project$Models$LocalStorage$StudentChoices$getRecentCharacter, time, store.c$);
	});
var $author$project$Models$LocalStorage$Helpers$getStudentCharacterTheme = F3(
	function (seasonalTheme, time, store) {
		return A2(
			$author$project$Assets$AvatarTheme$characterTheme,
			seasonalTheme,
			A2(
				$elm$core$Maybe$withDefault,
				2,
				A2($author$project$Models$LocalStorage$Helpers$getStudentRecentCharacter, time, store)));
	});
var $author$project$Models$Modal$isOverlay = function (modalIdentifier) {
	switch (modalIdentifier.$) {
		case 7:
			return true;
		case 8:
			return true;
		case 9:
			return true;
		case 0:
			return false;
		case 1:
			return false;
		case 2:
			return false;
		case 3:
			return false;
		case 4:
			return false;
		case 5:
			return false;
		case 6:
			return false;
		default:
			return false;
	}
};
var $author$project$Models$Modal$isOverlayOpen = function (modalState) {
	if (!modalState.$) {
		var modalIdentifier = modalState.a;
		return $author$project$Models$Modal$isOverlay(modalIdentifier);
	} else {
		return false;
	}
};
var $author$project$Component$Icon$ArrowRight = 5;
var $author$project$Views$Components$Button$baseNextButton = function (buttonsDisabled) {
	return A2(
		$author$project$Component$Buttons$Action$button,
		$author$project$Component$Buttons$Button$loadingStateFromDisabledState(buttonsDisabled),
		_Utils_Tuple3(
			0,
			0,
			A2($author$project$Component$Icon$Icon, 5, $author$project$Component$Icon$Large)));
};
var $author$project$Views$Components$Button$inactiveNextButton = $author$project$Views$Components$Button$baseNextButton(true);
var $author$project$Views$Components$LoadingIndicator$render = F2(
	function (_class, text) {
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('width', '100vw'),
					_Utils_Tuple2('height', '100vh'),
					_Utils_Tuple2('justify-content', 'center'),
					_Utils_Tuple2('align-content', 'center'),
					_Utils_Tuple2('align-items', 'center'),
					_Utils_Tuple2('flex-direction', 'column')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						$author$project$Views$Components$Button$inactiveNextButton,
						A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('font-size', '24px'),
								_Utils_Tuple2('margin-top', '20px')
							]),
						A2(
							$visotype$elm_dom$Dom$appendText,
							text,
							$visotype$elm_dom$Dom$element('h2')))
					]),
				A2(
					$visotype$elm_dom$Dom$addClass,
					_class,
					$visotype$elm_dom$Dom$element('div'))));
	});
var $author$project$Animations$CssAnimations$PulsateInfinitely = 5;
var $author$project$LoggedIn$Messages$RecordStudentMood = function (a) {
	return {$: 4, a: a};
};
var $author$project$Messages$ToggleMoodAndPlayTTS = F2(
	function (a, b) {
		return {$: 70, a: a, b: b};
	});
var $visotype$elm_dom$Dom$addClassConditional = F2(
	function (s, test) {
		if (test) {
			return $visotype$elm_dom$Dom$addClass(s);
		} else {
			return function (x) {
				return x;
			};
		}
	});
var $author$project$Animations$CssAnimations$addDirectionModifier = F2(
	function (direction, classString) {
		switch (direction) {
			case 0:
				return classString;
			case 1:
				return classString + 'In';
			default:
				return classString + 'Out';
		}
	});
var $author$project$Animations$CssAnimations$delayClass = function (delay) {
	if (!delay.$) {
		return '';
	} else {
		var modifier = delay.a;
		return 'delay-' + modifier;
	}
};
var $author$project$Animations$CssAnimations$iterationClass = function (iteractionCount) {
	switch (iteractionCount.$) {
		case 1:
			return '';
		case 0:
			return 'infinite';
		default:
			var modifier = iteractionCount.a;
			return 'iteration-' + modifier;
	}
};
var $author$project$Animations$CssAnimations$speedClass = function (speed) {
	if (!speed.$) {
		return '';
	} else {
		var modifier = speed.a;
		return modifier;
	}
};
var $author$project$Animations$CssAnimations$buildAnimationClassesString = function (style) {
	return A2(
		$elm$core$String$join,
		' ',
		function () {
			switch (style.$) {
				case 0:
					var speed = style.a;
					var delay = style.b;
					var iterationCount = style.c;
					return _List_fromArray(
						[
							'animated',
							$author$project$Animations$CssAnimations$speedClass(speed),
							$author$project$Animations$CssAnimations$delayClass(delay),
							$author$project$Animations$CssAnimations$iterationClass(iterationCount),
							'bounce'
						]);
				case 1:
					var speed = style.a;
					var delay = style.b;
					var iterationCount = style.c;
					return _List_fromArray(
						[
							'animated',
							$author$project$Animations$CssAnimations$speedClass(speed),
							$author$project$Animations$CssAnimations$delayClass(delay),
							$author$project$Animations$CssAnimations$iterationClass(iterationCount),
							'tada'
						]);
				case 2:
					var speed = style.a;
					var delay = style.b;
					var iterationCount = style.c;
					return _List_fromArray(
						[
							'animated',
							$author$project$Animations$CssAnimations$speedClass(speed),
							$author$project$Animations$CssAnimations$delayClass(delay),
							$author$project$Animations$CssAnimations$iterationClass(iterationCount),
							'fade'
						]);
				case 3:
					var speed = style.a;
					var delay = style.b;
					var iterationCount = style.c;
					return _List_fromArray(
						[
							'animated',
							$author$project$Animations$CssAnimations$speedClass(speed),
							$author$project$Animations$CssAnimations$delayClass(delay),
							$author$project$Animations$CssAnimations$iterationClass(iterationCount),
							'pulse-lg'
						]);
				case 5:
					var speed = style.a;
					var delay = style.b;
					var iterationCount = style.c;
					return _List_fromArray(
						[
							'animated',
							$author$project$Animations$CssAnimations$speedClass(speed),
							$author$project$Animations$CssAnimations$delayClass(delay),
							$author$project$Animations$CssAnimations$iterationClass(iterationCount),
							'pulse-lg-centered'
						]);
				default:
					var speed = style.a;
					var delay = style.b;
					var iterationCount = style.c;
					return _List_fromArray(
						[
							'animated',
							$author$project$Animations$CssAnimations$speedClass(speed),
							$author$project$Animations$CssAnimations$delayClass(delay),
							$author$project$Animations$CssAnimations$iterationClass(iterationCount),
							'es-delay-pulsing-element-visibility'
						]);
			}
		}());
};
var $author$project$Animations$CssAnimations$applyConditional = F2(
	function (animationType, shouldApply) {
		switch (animationType.$) {
			case 0:
				return $elm$core$Basics$identity;
			case 1:
				return $visotype$elm_dom$Dom$addClass('is-hidden');
			default:
				var direction = animationType.a;
				var style = animationType.b;
				return A2(
					$visotype$elm_dom$Dom$addClassConditional,
					A2(
						$author$project$Animations$CssAnimations$addDirectionModifier,
						direction,
						$author$project$Animations$CssAnimations$buildAnimationClassesString(style)),
					shouldApply);
		}
	});
var $author$project$Animations$CssAnimations$apply = function (animationType) {
	return A2($author$project$Animations$CssAnimations$applyConditional, animationType, true);
};
var $author$project$Animations$CssAnimations$AnimateElement = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Animations$CssAnimations$AttentionSeeker = 0;
var $author$project$Animations$CssAnimations$Bounce = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Animations$CssAnimations$DefaultDelay = {$: 0};
var $author$project$Animations$CssAnimations$DefaultIterationCount = {$: 1};
var $author$project$Animations$CssAnimations$EnterAnimation = 1;
var $author$project$Animations$CssAnimations$ExitAnimation = 2;
var $author$project$Animations$CssAnimations$Fade = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Animations$CssAnimations$Infinite = {$: 0};
var $author$project$Animations$CssAnimations$NoCssAnimation = {$: 0};
var $author$project$Animations$CssAnimations$NormalSpeed = {$: 0};
var $author$project$Animations$CssAnimations$PulseLg = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$Animations$CssAnimations$PulseLgCentered = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$Animations$CssAnimations$PulseLgFadeInWithDelay = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Animations$CssAnimations$Tada = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Animations$CssAnimations$WithDelayModifier = function (a) {
	return {$: 1, a: a};
};
var $author$project$Animations$CssAnimations$WithSpeedModifier = function (a) {
	return {$: 1, a: a};
};
var $author$project$Animations$CssAnimations$preset = function (presetType) {
	switch (presetType) {
		case 9:
			return $author$project$Animations$CssAnimations$NoCssAnimation;
		case 0:
			return A2(
				$author$project$Animations$CssAnimations$AnimateElement,
				1,
				A3($author$project$Animations$CssAnimations$Bounce, $author$project$Animations$CssAnimations$NormalSpeed, $author$project$Animations$CssAnimations$DefaultDelay, $author$project$Animations$CssAnimations$DefaultIterationCount));
		case 1:
			return A2(
				$author$project$Animations$CssAnimations$AnimateElement,
				1,
				A3(
					$author$project$Animations$CssAnimations$Bounce,
					$author$project$Animations$CssAnimations$NormalSpeed,
					$author$project$Animations$CssAnimations$WithDelayModifier('3s'),
					$author$project$Animations$CssAnimations$DefaultIterationCount));
		case 2:
			return A2(
				$author$project$Animations$CssAnimations$AnimateElement,
				2,
				A3(
					$author$project$Animations$CssAnimations$Fade,
					$author$project$Animations$CssAnimations$WithSpeedModifier('faster'),
					$author$project$Animations$CssAnimations$DefaultDelay,
					$author$project$Animations$CssAnimations$DefaultIterationCount));
		case 3:
			return A2(
				$author$project$Animations$CssAnimations$AnimateElement,
				2,
				A3(
					$author$project$Animations$CssAnimations$Fade,
					$author$project$Animations$CssAnimations$NormalSpeed,
					$author$project$Animations$CssAnimations$WithDelayModifier('2s'),
					$author$project$Animations$CssAnimations$DefaultIterationCount));
		case 4:
			return A2(
				$author$project$Animations$CssAnimations$AnimateElement,
				0,
				A3(
					$author$project$Animations$CssAnimations$Tada,
					$author$project$Animations$CssAnimations$NormalSpeed,
					$author$project$Animations$CssAnimations$WithDelayModifier('2s'),
					$author$project$Animations$CssAnimations$DefaultIterationCount));
		case 5:
			return A2(
				$author$project$Animations$CssAnimations$AnimateElement,
				0,
				A3($author$project$Animations$CssAnimations$PulseLg, $author$project$Animations$CssAnimations$NormalSpeed, $author$project$Animations$CssAnimations$DefaultDelay, $author$project$Animations$CssAnimations$Infinite));
		case 6:
			return A2(
				$author$project$Animations$CssAnimations$AnimateElement,
				0,
				A3($author$project$Animations$CssAnimations$PulseLgCentered, $author$project$Animations$CssAnimations$NormalSpeed, $author$project$Animations$CssAnimations$DefaultDelay, $author$project$Animations$CssAnimations$Infinite));
		case 7:
			return A2(
				$author$project$Animations$CssAnimations$AnimateElement,
				0,
				A3(
					$author$project$Animations$CssAnimations$PulseLg,
					$author$project$Animations$CssAnimations$NormalSpeed,
					$author$project$Animations$CssAnimations$WithDelayModifier('3s'),
					$author$project$Animations$CssAnimations$Infinite));
		default:
			return A2(
				$author$project$Animations$CssAnimations$AnimateElement,
				0,
				A3($author$project$Animations$CssAnimations$PulseLgFadeInWithDelay, $author$project$Animations$CssAnimations$NormalSpeed, $author$project$Animations$CssAnimations$DefaultDelay, $author$project$Animations$CssAnimations$DefaultIterationCount));
	}
};
var $author$project$Animations$CssAnimations$applyWithAnimationPreset = function (animationPreset) {
	return $author$project$Animations$CssAnimations$apply(
		$author$project$Animations$CssAnimations$preset(animationPreset));
};
var $author$project$Component$FixedItemGrid$container = function (items) {
	var wrapItem = function (item) {
		return A2(
			$visotype$elm_dom$Dom$appendChild,
			item,
			A2(
				$visotype$elm_dom$Dom$addClassList,
				_List_fromArray(
					['column', 'is-narrow']),
				$visotype$elm_dom$Dom$element('li')));
	};
	return A2(
		$visotype$elm_dom$Dom$appendChild,
		A2(
			$visotype$elm_dom$Dom$appendChildList,
			A2($elm$core$List$map, wrapItem, items),
			A2(
				$visotype$elm_dom$Dom$addClassList,
				_List_fromArray(
					['columns', 'is-mobile', 'is-centered', 'is-multiline', 'is-variable', 'is-5']),
				$visotype$elm_dom$Dom$element('ul'))),
		A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['container', 'is-mobile']),
			$visotype$elm_dom$Dom$element('div')));
};
var $author$project$Component$BottomBar$customBar = function (args) {
	var previousButton = A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('position', 'relative'),
				_Utils_Tuple2('left', '-10px')
			]),
		args.io);
	var applyBgColor = function (color) {
		return _Utils_eq(color, $author$project$ES$UI$Color$transparent) ? $visotype$elm_dom$Dom$addStyle(
			_Utils_Tuple2('background', 'none')) : $author$project$Styles$filled(color);
	};
	return A2(
		applyBgColor,
		args.gq,
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('height', '110px'),
					_Utils_Tuple2('padding', '30px 20px 30px 30px'),
					_Utils_Tuple2('bottom', '0'),
					_Utils_Tuple2('right', '0'),
					_Utils_Tuple2('left', '0'),
					_Utils_Tuple2('margin-bottom', '0'),
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('position', 'absolute')
				]),
			$author$project$Component$Layout$level(
				{
					gz: _List_Nil,
					hx: _List_fromArray(
						[
							previousButton,
							A2($author$project$Dom$Extra$addDataCy, 'bottom-bar-content', args.gH)
						]),
					iJ: _List_fromArray(
						[args.R])
				})));
};
var $author$project$Models$Voice$Matthew = 5;
var $author$project$Models$Voice$default = 5;
var $author$project$Models$StudentMood$Bored = 0;
var $author$project$Models$StudentMood$Cool = 1;
var $author$project$Models$StudentMood$Excited = 2;
var $author$project$Models$StudentMood$Happy = 3;
var $author$project$Models$StudentMood$Mad = 4;
var $author$project$Models$StudentMood$Okay = 5;
var $author$project$Models$StudentMood$Proud = 6;
var $author$project$Models$StudentMood$Sad = 7;
var $author$project$Models$StudentMood$moodList = _List_fromArray(
	[2, 6, 5, 1, 4, 0, 7, 3]);
var $author$project$Assets$AvatarTheme$moodLookup = F2(
	function (mood, lookupable) {
		switch (mood) {
			case 0:
				return lookupable.l;
			case 1:
				return lookupable.m;
			case 2:
				return lookupable.n;
			case 3:
				return lookupable.o;
			case 4:
				return lookupable.p;
			case 5:
				return lookupable.q;
			case 6:
				return lookupable.r;
			default:
				return lookupable.s;
		}
	});
var $author$project$Messages$DisableOnClick = function (a) {
	return {$: 4, a: a};
};
var $author$project$Dom$AddClickOnceAction$addClickOnceAction = function (message) {
	return $visotype$elm_dom$Dom$addAction(
		_Utils_Tuple2(
			'click',
			$author$project$Messages$DisableOnClick(message)));
};
var $author$project$Views$Components$Button$WithGlobalMsg$next = F2(
	function (buttonsDisabled, message) {
		return A2(
			$author$project$Dom$AddClickOnceAction$addClickOnceAction,
			message,
			A2(
				$author$project$Component$Buttons$Action$button,
				$author$project$Component$Buttons$Button$loadingStateFromDisabledState(buttonsDisabled),
				_Utils_Tuple3(
					0,
					0,
					A2($author$project$Component$Icon$Icon, 5, $author$project$Component$Icon$Large))));
	});
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $author$project$Component$FixedItemGrid$padItems = F2(
	function (count, width) {
		return A2(
			$elm$core$List$repeat,
			count,
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('width', width),
						_Utils_Tuple2('height', '0')
					]),
				$visotype$elm_dom$Dom$element('div')));
	});
var $author$project$Styles$url = function (assetPath) {
	return 'url(\"' + ($author$project$Assets$path(assetPath) + '\")');
};
var $author$project$Styles$background = F2(
	function (backgroundAsset, element) {
		return A2(
			$author$project$Styles$onTop,
			1,
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('padding', '50px'),
						_Utils_Tuple2('min-height', '100vh'),
						_Utils_Tuple2(
						'background-image',
						$author$project$Styles$url(backgroundAsset)),
						_Utils_Tuple2('background-size', 'cover'),
						_Utils_Tuple2('background-attachment', 'fixed')
					]),
				element));
	});
var $author$project$Assets$selectionScreenBackground = require('/usr/src/app/app/images/backgrounds/selectionScreenBackground.jpg');
var $author$project$Styles$selectionScreenBackground = function (element) {
	return A2(
		$visotype$elm_dom$Dom$addStyle,
		_Utils_Tuple2('background-position', 'center'),
		A2($author$project$Styles$background, $author$project$Assets$selectionScreenBackground, element));
};
var $author$project$Messages$PlayTTSSpeechFromNode = function (a) {
	return {$: 58, a: a};
};
var $visotype$elm_dom$Dom$addAttributeConditional = F2(
	function (a, test) {
		if (test) {
			return $visotype$elm_dom$Dom$addAttribute(a);
		} else {
			return function (x) {
				return x;
			};
		}
	});
var $visotype$elm_dom$Dom$addListener = function (_v0) {
	var event = _v0.a;
	var decoder = _v0.b;
	var handler = $elm$virtual_dom$VirtualDom$Normal;
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					c: A2(
						$elm$core$List$append,
						n.c,
						_List_fromArray(
							[
								_Utils_Tuple2(
								event,
								handler(decoder))
							]))
				});
		});
};
var $author$project$TextToSpeech$pronounciationFixes = A2($elm$core$String$replace, 'espark', 'eSpark');
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $elm$core$List$unzip = function (pairs) {
	var step = F2(
		function (_v0, _v1) {
			var x = _v0.a;
			var y = _v0.b;
			var xs = _v1.a;
			var ys = _v1.b;
			return _Utils_Tuple2(
				A2($elm$core$List$cons, x, xs),
				A2($elm$core$List$cons, y, ys));
		});
	return A3(
		$elm$core$List$foldr,
		step,
		_Utils_Tuple2(_List_Nil, _List_Nil),
		pairs);
};
var $visotype$elm_dom$Dom$setChildListWithKeys = function (lkv) {
	var _v0 = $elm$core$List$unzip(lkv);
	var ls = _v0.a;
	var le = _v0.b;
	var lr = A2($elm$core$List$map, $visotype$elm_dom$Dom$Internal$render, le);
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{gC: lr, cA: ls});
		});
};
var $author$project$Models$Voice$toString = function (voice) {
	switch (voice) {
		case 0:
			return 'Salli';
		case 1:
			return 'Joanna';
		case 2:
			return 'Ivy';
		case 3:
			return 'Kendra';
		case 4:
			return 'Kimberly';
		case 5:
			return 'Matthew';
		case 6:
			return 'Justin';
		case 7:
			return 'Joey';
		default:
			return 'Kevin';
	}
};
var $elm$core$String$trim = _String_trim;
var $author$project$TextToSpeech$ttsEventDecoder = function (msg) {
	return A2(
		$elm$json$Json$Decode$map,
		msg,
		A2($elm$json$Json$Decode$field, 'target', $elm$json$Json$Decode$value));
};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$updateAt = F3(
	function (index, fn, list) {
		if (index < 0) {
			return list;
		} else {
			var tail = A2($elm$core$List$drop, index, list);
			var head = A2($elm$core$List$take, index, list);
			if (tail.b) {
				var x = tail.a;
				var xs = tail.b;
				return _Utils_ap(
					head,
					A2(
						$elm$core$List$cons,
						fn(x),
						xs));
			} else {
				return list;
			}
		}
	});
var $author$project$TextToSpeech$speakable = F5(
	function (playTTSMsg, _v0, isPlayOnLoad, textContent, voice) {
		var audioEnabled = _v0.gj;
		var textContentWithPronounciationFixes = $author$project$TextToSpeech$pronounciationFixes(textContent);
		var matcher = A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			$elm$regex$Regex$fromString('.*?[.!?]\\s?'));
		var decoder = $author$project$TextToSpeech$ttsEventDecoder(playTTSMsg);
		var wrapSentence = function (sentence) {
			return A2(
				$visotype$elm_dom$Dom$addListener,
				_Utils_Tuple2('audioAutoplay', decoder),
				A2(
					$visotype$elm_dom$Dom$addListener,
					_Utils_Tuple2('click', decoder),
					A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('z-index', '1000'),
						A2(
							$visotype$elm_dom$Dom$appendText,
							sentence,
							$visotype$elm_dom$Dom$element('ttspan')))));
		};
		var keyedTuple = function (sentence) {
			return _Utils_Tuple2(
				sentence,
				wrapSentence(sentence));
		};
		var addDivider = function (str) {
			return str + '***';
		};
		var withDividers = A3(
			$elm$regex$Regex$replace,
			matcher,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.hE;
				},
				addDivider),
			textContentWithPronounciationFixes);
		var sentences = A2($elm$core$String$split, '***', withDividers);
		var sentenceCount = $elm$core$List$length(sentences);
		var trimmedSentences = ($elm$core$List$length(sentences) > 1) ? A3(
			$elm_community$list_extra$List$Extra$updateAt,
			sentenceCount - 1,
			$elm$core$String$trim,
			A2(
				$elm$core$List$filter,
				$elm$core$Basics$neq(''),
				sentences)) : sentences;
		var keyedSentenceNodes = A2($elm$core$List$map, keyedTuple, trimmedSentences);
		return audioEnabled ? A2(
			$visotype$elm_dom$Dom$addAttribute,
			A2(
				$elm$html$Html$Attributes$attribute,
				'data-tts-voice',
				$author$project$Models$Voice$toString(voice)),
			A2(
				$visotype$elm_dom$Dom$setChildListWithKeys,
				keyedSentenceNodes,
				A3(
					$visotype$elm_dom$Dom$addAttributeConditional,
					A2($elm$html$Html$Attributes$attribute, 'data-audio-auto-play', 'true'),
					isPlayOnLoad,
					$visotype$elm_dom$Dom$element('ttgspan')))) : A2(
			$visotype$elm_dom$Dom$appendText,
			textContentWithPronounciationFixes,
			$visotype$elm_dom$Dom$element('span'));
	});
var $author$project$TextToSpeech$WithGlobalMsg$speakable = $author$project$TextToSpeech$speakable($author$project$Messages$PlayTTSSpeechFromNode);
var $author$project$Views$MoodRecorder$render = function (viewData) {
	var modalState = viewData.hM;
	var characterTheme = viewData.gB;
	var buttonsDisabled = viewData.gv;
	var productFeatures = viewData.iq;
	var title = function () {
		var promptText = 'How do you feel right now?';
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('padding-bottom', '16px'),
					_Utils_Tuple2('margin-top', '-6px')
				]),
			$author$project$Component$Text$centered(
				A2(
					$author$project$Component$Text$withSize,
					1,
					A2(
						$visotype$elm_dom$Dom$appendChild,
						A4($author$project$TextToSpeech$WithGlobalMsg$speakable, viewData, true, promptText, $author$project$Models$Voice$default),
						$visotype$elm_dom$Dom$element('div')))));
	}();
	var maybeSelectedMood = function () {
		if (!modalState.$) {
			if (modalState.a.$ === 7) {
				var maybeMood = modalState.a.a;
				return maybeMood;
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	var moodButton = function (mood) {
		var moodText = A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['is-capitalized', 'has-text-centered', 'is-size-3']),
			A2(
				$visotype$elm_dom$Dom$appendText,
				$author$project$Models$StudentMood$moodToString(mood),
				$visotype$elm_dom$Dom$element('div')));
		var moodImage = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('width', '200px'),
					_Utils_Tuple2('height', '215px'),
					_Utils_Tuple2('position', 'relative')
				]),
			$author$project$Component$Image$render(
				{
					f5: '',
					hb: 215,
					ig: $author$project$Assets$path(
						A2($author$project$Assets$AvatarTheme$moodLookup, mood, characterTheme.D)),
					jS: 200
				}));
		var isSelected = _Utils_eq(
			maybeSelectedMood,
			$elm$core$Maybe$Just(mood));
		var moodSelectedBorder = isSelected ? A2(
			$author$project$Styles$filled,
			$author$project$ES$UI$Color$white,
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('position', 'absolute'),
						_Utils_Tuple2('width', '210px'),
						_Utils_Tuple2('height', '210px'),
						_Utils_Tuple2('top', '20px'),
						_Utils_Tuple2('left', '-5px'),
						_Utils_Tuple2('border-radius', '100%')
					]),
				$visotype$elm_dom$Dom$element('div'))) : $visotype$elm_dom$Dom$element('span');
		var toggleMood = isSelected ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(mood);
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('cursor', 'pointer'),
					_Utils_Tuple2('position', 'relative')
				]),
			A3(
				$visotype$elm_dom$Dom$addClassConditional,
				'selected',
				isSelected,
				A2(
					$visotype$elm_dom$Dom$addAction,
					_Utils_Tuple2(
						'click',
						A2(
							$author$project$Messages$ToggleMoodAndPlayTTS,
							$author$project$Models$StudentMood$moodToString(mood),
							toggleMood)),
					A2(
						$author$project$Dom$Extra$addDataCy,
						'mood-recorder-button',
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[moodSelectedBorder, moodImage, moodText]),
							$visotype$elm_dom$Dom$element('div'))))));
	};
	var moodButtons = A2(
		$visotype$elm_dom$Dom$addStyle,
		_Utils_Tuple2('max-width', '924px'),
		$author$project$Component$FixedItemGrid$container(
			_Utils_ap(
				A2($elm$core$List$map, moodButton, $author$project$Models$StudentMood$moodList),
				A2($author$project$Component$FixedItemGrid$padItems, 4, '200px'))));
	var nextButton = function () {
		if (!maybeSelectedMood.$) {
			var selectedMood = maybeSelectedMood.a;
			return A2(
				$author$project$Animations$CssAnimations$applyWithAnimationPreset,
				5,
				A2(
					$author$project$Dom$Extra$addDataCy,
					'mood-recorder-next-button',
					A2(
						$author$project$Views$Components$Button$WithGlobalMsg$next,
						buttonsDisabled,
						$author$project$Messages$LoggedInRequiredMsg(
							$author$project$LoggedIn$Messages$RecordStudentMood(selectedMood)))));
		} else {
			return $visotype$elm_dom$Dom$element('span');
		}
	}();
	var isOpen = function () {
		if (!modalState.$) {
			if (modalState.a.$ === 7) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}();
	var bottomBar = A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('position', 'fixed'),
				_Utils_Tuple2('left', 'auto')
			]),
		$author$project$Component$BottomBar$customBar(
			{
				gq: $author$project$ES$UI$Color$transparent,
				gH: $visotype$elm_dom$Dom$element('span'),
				R: nextButton,
				io: $visotype$elm_dom$Dom$element('span')
			}));
	var style = A2(
		$author$project$Styles$onTop,
		1000,
		$author$project$Styles$selectionScreenBackground(
			A2(
				$author$project$Dom$Extra$addDataCy,
				'mood-recorder',
				A2(
					$visotype$elm_dom$Dom$addClassList,
					_List_fromArray(
						['mood-recorder', 'overlay']),
					A2(
						$visotype$elm_dom$Dom$appendChildList,
						_List_fromArray(
							[title, moodButtons, bottomBar]),
						$visotype$elm_dom$Dom$element('div'))))));
	return isOpen ? style : $visotype$elm_dom$Dom$element('span');
};
var $author$project$Helpers$QuizPreviewDummyData$activity = {
	f0: $author$project$Models$Activity$Prequiz,
	dl: false,
	dE: 0,
	dG: $elm$core$Maybe$Nothing,
	d6: $elm$core$Maybe$Nothing,
	hj: $author$project$Models$Ids$fromInt(-1),
	ea: $author$project$Models$Activity$NoInstructions,
	em: $elm$core$Maybe$Nothing,
	hG: 7,
	ij: 2,
	cQ: $author$project$Models$Ids$fromInt(-1),
	e5: $author$project$Models$Urls$fromString('fooabr'),
	e9: 1,
	fx: 'Quiz Preview Activity'
};
var $author$project$Helpers$QuizPreviewDummyData$studentActivity = {
	f_: $author$project$Helpers$QuizPreviewDummyData$activity,
	gd: $elm$core$Maybe$Nothing,
	dP: 1,
	hj: $author$project$Models$Ids$fromInt(-1),
	hs: false,
	ir: $author$project$Models$StudentActivity$ActiveStudentActivity(
		$author$project$Models$StudentActivity$AssessmentActivity($author$project$Models$AssessmentStatus$NotCurrentlyTakingAssessment)),
	a_: $author$project$Models$Ids$fromInt(-1),
	fL: $author$project$Models$StudentActivity$None
};
var $author$project$AppFlow$toString = function (mode) {
	if (!mode.$) {
		return 'sequential';
	} else {
		return 'choice';
	}
};
var $author$project$Models$ProductFeature$WebVideoRecordingInIos = 6;
var $author$project$Models$ProductFeature$AlwaysPlayFromMediaCachingServer = 1;
var $author$project$Models$ProductFeature$AlwaysShowNextButtonOnVideoActivity = 4;
var $author$project$Models$ProductFeature$ForceOpenInIosSafari = 3;
var $author$project$Models$ProductFeature$HideClassLibrary = 11;
var $author$project$Models$ProductFeature$HotjarStudentTracking = 5;
var $author$project$Models$ProductFeature$PLEPhase2 = 8;
var $author$project$Models$ProductFeature$PerformStudentDeviceCheck = 2;
var $author$project$Models$ProductFeature$ShowClassLibraryInSubjectChoice = 10;
var $author$project$Models$ProductFeature$SilenceLogger = 0;
var $author$project$Models$ProductFeature$UseCDNForMediaCaching = 9;
var $author$project$Models$ProductFeature$UseHtml5AudioForTTS = 7;
var $author$project$Models$ProductFeature$activeFeaturesDict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Silence Message Logger', 0),
			_Utils_Tuple2('Always use media caching server', 1),
			_Utils_Tuple2('Force Open In Ios Safari', 3),
			_Utils_Tuple2('Perform Student Device Check', 2),
			_Utils_Tuple2('Always Show Next Button On Video Activity', 4),
			_Utils_Tuple2('Hotjar Tracking for Student', 5),
			_Utils_Tuple2('Web Video Recording In Ios', 6),
			_Utils_Tuple2('Use Html5 Audio For TTS', 7),
			_Utils_Tuple2('PLE Phase 2', 8),
			_Utils_Tuple2('Use CDN for Media Caching', 9),
			_Utils_Tuple2('Show Class Library in Subject Choice', 10),
			_Utils_Tuple2('Hide Class Library', 11)
		]));
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Models$ProductFeature$enabledActiveFeaturesDict = function (enabledFeaturesList) {
	return A2(
		$elm$core$Dict$filter,
		F2(
			function (stringValue, _v0) {
				return A2($elm$core$List$member, stringValue, enabledFeaturesList);
			}),
		$author$project$Models$ProductFeature$activeFeaturesDict);
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Models$ProductFeature$isEnabled = F2(
	function (activeFeature, _v0) {
		var enabledFeaturesList = _v0;
		return A2(
			$elm$core$List$member,
			activeFeature,
			$elm$core$Dict$values(
				$author$project$Models$ProductFeature$enabledActiveFeaturesDict(enabledFeaturesList)));
	});
var $author$project$Helpers$VideoRecordingPermission$videoRecordingPermitted = F2(
	function (platform, enabledFeatures) {
		if (!platform.$) {
			if (!platform.a) {
				var _v1 = platform.a;
				return true;
			} else {
				var _v2 = platform.a;
				return false;
			}
		} else {
			if (!platform.c) {
				var _v3 = platform.c;
				return A2($author$project$Models$ProductFeature$isEnabled, 6, enabledFeatures);
			} else {
				var _v4 = platform.c;
				return false;
			}
		}
	});
var $author$project$CharacterSelection$ConfirmChoice = function (a) {
	return {$: 1, a: a};
};
var $author$project$CharacterSelection$SelectCharacter = function (a) {
	return {$: 0, a: a};
};
var $author$project$CharacterSelection$Selected = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$LocalStorage$StudentChoices$characterName = function (character) {
	switch (character) {
		case 0:
			return 'alien';
		case 1:
			return 'pogostick';
		case 2:
			return 'rocky';
		case 3:
			return 'rollerskate';
		case 4:
			return 'scooter';
		case 5:
			return 'skateboard';
		case 6:
			return 'springshoes';
		case 7:
			return 'unicorn';
		case 8:
			return 'aliennew';
		case 9:
			return 'axolotl';
		case 10:
			return 'dog';
		case 11:
			return 'dragon';
		case 12:
			return 'fox';
		case 13:
			return 'lion';
		case 14:
			return 'panda';
		default:
			return 'robodog';
	}
};
var $author$project$Views$Components$Button$next = F2(
	function (buttonsDisabled, message) {
		return A2(
			$visotype$elm_dom$Dom$addAction,
			_Utils_Tuple2('click', message),
			$author$project$Views$Components$Button$baseNextButton(buttonsDisabled));
	});
var $author$project$CharacterSelection$view = F2(
	function (_v0, model) {
		var buttonsDisabled = _v0.gv;
		var seasonalTheme = _v0.iQ;
		var productFeatures = _v0.iq;
		var title = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('padding-bottom', '20px')
				]),
			$author$project$Component$Text$centered(
				A2(
					$author$project$Component$Text$withSize,
					1,
					A2(
						$visotype$elm_dom$Dom$appendText,
						'Who would you like to be today?',
						$visotype$elm_dom$Dom$element('div')))));
		var nextButton = function () {
			var _v1 = model.v;
			if (_v1.$ === 1) {
				var selectedCharacter = _v1.a;
				return A2(
					$author$project$Animations$CssAnimations$applyWithAnimationPreset,
					5,
					A2(
						$author$project$Dom$Extra$addDataCy,
						'character-selector-next-button',
						A2(
							$author$project$Views$Components$Button$next,
							buttonsDisabled,
							$author$project$CharacterSelection$ConfirmChoice(selectedCharacter))));
			} else {
				return $visotype$elm_dom$Dom$element('span');
			}
		}();
		var characterButton = function (character) {
			var isSelected = _Utils_eq(
				model.v,
				$author$project$CharacterSelection$Selected(character));
			var characterSelectedBorder = isSelected ? A2(
				$author$project$Styles$filled,
				$author$project$ES$UI$Color$white,
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('position', 'absolute'),
							_Utils_Tuple2('width', '180px'),
							_Utils_Tuple2('height', '180px'),
							_Utils_Tuple2('top', '45px'),
							_Utils_Tuple2('margin-left', '10px'),
							_Utils_Tuple2('border-radius', '100%')
						]),
					$visotype$elm_dom$Dom$element('div'))) : $visotype$elm_dom$Dom$element('span');
			var characterImage = A2(
				$author$project$Dom$Extra$addDataAttribute,
				_Utils_Tuple2(
					'character',
					$author$project$Models$LocalStorage$StudentChoices$characterName(character)),
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('width', '200px'),
							_Utils_Tuple2('height', '215px'),
							_Utils_Tuple2('position', 'relative')
						]),
					$author$project$Component$Image$render(
						{
							f5: $author$project$Models$LocalStorage$StudentChoices$characterName(character),
							hb: 204,
							ig: $author$project$Assets$path(
								A2(
									$author$project$Assets$AvatarTheme$moodLookup,
									5,
									A2($author$project$Assets$AvatarTheme$characterTheme, seasonalTheme, character).D)),
							jS: 190
						})));
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('cursor', 'pointer'),
						_Utils_Tuple2('position', 'relative')
					]),
				A3(
					$visotype$elm_dom$Dom$addClassConditional,
					'selected',
					isSelected,
					A3(
						$visotype$elm_dom$Dom$addActionConditional,
						_Utils_Tuple2(
							'click',
							$author$project$CharacterSelection$SelectCharacter(character)),
						!isSelected,
						A2(
							$author$project$Dom$Extra$addDataCy,
							'character-selector-button',
							A2(
								$visotype$elm_dom$Dom$appendChildList,
								_List_fromArray(
									[characterSelectedBorder, characterImage]),
								$visotype$elm_dom$Dom$element('div'))))));
		};
		var characterButtons = A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('max-width', '924px'),
			$author$project$Component$FixedItemGrid$container(
				_Utils_ap(
					_List_fromArray(
						[
							characterButton(14),
							characterButton(9),
							characterButton(10),
							characterButton(11),
							characterButton(12),
							characterButton(13),
							characterButton(8),
							characterButton(15)
						]),
					A2($author$project$Component$FixedItemGrid$padItems, 4, '100px'))));
		var bottomBar = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('position', 'fixed'),
					_Utils_Tuple2('left', 'auto')
				]),
			$author$project$Component$BottomBar$customBar(
				{
					gq: $author$project$ES$UI$Color$transparent,
					gH: $visotype$elm_dom$Dom$element('span'),
					R: nextButton,
					io: $visotype$elm_dom$Dom$element('span')
				}));
		return A2(
			$author$project$Styles$onTop,
			1000,
			$author$project$Styles$selectionScreenBackground(
				A2(
					$author$project$Dom$Extra$addDataCy,
					'character-selector',
					A2(
						$visotype$elm_dom$Dom$addClassList,
						_List_fromArray(
							['character-selector', 'overlay']),
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[title, characterButtons, bottomBar]),
							$visotype$elm_dom$Dom$element('div'))))));
	});
var $author$project$NpsSurvey$NpsSurveyUpdated = function (a) {
	return {$: 1, a: a};
};
var $author$project$NpsSurvey$PlayTTS = function (a) {
	return {$: 6, a: a};
};
var $author$project$NpsSurvey$SkipNps = {$: 2};
var $author$project$NpsSurvey$SubmitNps = function (a) {
	return {$: 4, a: a};
};
var $author$project$Views$Components$Button$close = function (message) {
	return A2(
		$visotype$elm_dom$Dom$appendChild,
		A2(
			$author$project$Component$Text$colored,
			$author$project$ES$UI$Color$grey.jY,
			$author$project$Component$Icon$render(
				A2(
					$author$project$Component$Icon$Icon,
					46,
					$author$project$Component$Icon$Custom('40px')))),
		A2(
			$visotype$elm_dom$Dom$addAttribute,
			A2($elm$html$Html$Attributes$attribute, 'aria-label', 'close'),
			A2(
				$visotype$elm_dom$Dom$addAction,
				_Utils_Tuple2('click', message),
				$visotype$elm_dom$Dom$element('button'))));
};
var $author$project$Component$Layout$container = function (elements) {
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		elements,
		A2(
			$visotype$elm_dom$Dom$addClass,
			'container',
			$visotype$elm_dom$Dom$element('div')));
};
var $author$project$Assets$overlayBackground = require('/usr/src/app/app/images/backgrounds/mood-screen-background.svg');
var $author$project$Assets$overlayBackgroundFall = require('/usr/src/app/app/images/backgrounds/fall-2019.svg');
var $author$project$Assets$overlayBackgroundWinter = require('/usr/src/app/app/images/backgrounds/winter-2019.svg');
var $author$project$Styles$overlayBackground = F2(
	function (seasonalTheme, element) {
		var backgroundAsset = function () {
			switch (seasonalTheme) {
				case 0:
					return $author$project$Assets$overlayBackground;
				case 1:
					return $author$project$Assets$overlayBackgroundFall;
				case 2:
					return $author$project$Assets$overlayBackgroundFall;
				default:
					return $author$project$Assets$overlayBackgroundWinter;
			}
		}();
		return A2($author$project$Styles$background, backgroundAsset, element);
	});
var $author$project$NpsSurvey$NPSUpdateSurveyAndPlayTTS = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Dom$Extra$addDataCyConditional = F2(
	function (value, bool) {
		return bool ? $author$project$Dom$Extra$addDataAttribute(
			_Utils_Tuple2('cy', value)) : $elm$core$Basics$identity;
	});
var $author$project$Styles$borderColor = function (color) {
	return $visotype$elm_dom$Dom$addStyle(
		_Utils_Tuple2(
			'border-color',
			$avh4$elm_color$Color$toCssString(color)));
};
var $author$project$Styles$border = function (_v0) {
	var width = _v0.a;
	var style = _v0.b;
	var color = _v0.c;
	return A2(
		$elm$core$Basics$composeR,
		$visotype$elm_dom$Dom$addStyleList(
			_List_fromArray(
				[
					_Utils_Tuple2('border-width', width),
					_Utils_Tuple2('border-style', style)
				])),
		$author$project$Styles$borderColor(color));
};
var $author$project$Styles$conditional = function (_v0) {
	var style = _v0.a;
	var bool = _v0.b;
	return bool ? style : $visotype$elm_dom$Dom$addStyleList(_List_Nil);
};
var $author$project$Models$NpsSurveyResult$Bad = 2;
var $author$project$Models$NpsSurveyResult$Great = 5;
var $author$project$Models$NpsSurveyResult$Okay = 4;
var $author$project$Models$NpsSurveyResult$Passable = 3;
var $author$project$Models$NpsSurveyResult$Worst = 1;
var $author$project$NpsSurvey$ratingButtonsAttributes = _List_fromArray(
	[
		{gG: '#d54434', hj: 'worst', e4: 1, ju: 'I really do not like it!'},
		{gG: '#ec6925', hj: 'bad', e4: 2, ju: 'I do not like it'},
		{gG: '#f3b212', hj: 'passable', e4: 3, ju: 'It\'s okay'},
		{gG: '#a1b100', hj: 'okay', e4: 4, ju: 'I like it!'},
		{gG: '#56a734', hj: 'great', e4: 5, ju: 'I really like it!'}
	]);
var $author$project$ES$UI$Color$teal = {
	at: A3($avh4$elm_color$Color$rgb255, 20, 161, 174),
	au: A3($avh4$elm_color$Color$rgb255, 16, 128, 139),
	ay: A3($avh4$elm_color$Color$rgb255, 0, 195, 201),
	az: A3($avh4$elm_color$Color$rgb255, 102, 219, 223),
	aA: A3($avh4$elm_color$Color$rgb255, 218, 245, 247)
};
var $author$project$NpsSurvey$ratingButtons = function (currentResult) {
	var toButton = function (_v0) {
		var score = _v0.e4;
		var text = _v0.ju;
		var color = _v0.gG;
		var id = _v0.hj;
		return A2(
			$visotype$elm_dom$Dom$addAction,
			_Utils_Tuple2(
				'click',
				A2(
					$author$project$NpsSurvey$NPSUpdateSurveyAndPlayTTS,
					text,
					_Utils_update(
						currentResult,
						{e4: score}))),
			$author$project$Styles$shadow(
				A2(
					$visotype$elm_dom$Dom$setId,
					id,
					A3(
						$author$project$Dom$Extra$addDataCyConditional,
						'selected-nps-score',
						_Utils_eq(score, currentResult.e4),
						A2(
							$author$project$Styles$conditional,
							_Utils_Tuple2(
								$author$project$Styles$border(
									_Utils_Tuple3('5px', 'solid', $author$project$ES$UI$Color$teal.at)),
								_Utils_eq(score, currentResult.e4)),
							A2(
								$author$project$Styles$size,
								{hb: '110px', jS: '175px'},
								A2(
									$visotype$elm_dom$Dom$addStyleList,
									_List_fromArray(
										[
											_Utils_Tuple2('background-color', color),
											_Utils_Tuple2('margin-right', '12px'),
											_Utils_Tuple2('border-radius', '5px'),
											_Utils_Tuple2('font-size', '30px'),
											_Utils_Tuple2('vertical-align', 'top')
										]),
									A2(
										$author$project$Component$Text$colored,
										$author$project$ES$UI$Color$white,
										A2(
											$visotype$elm_dom$Dom$appendChild,
											A2(
												$visotype$elm_dom$Dom$addStyleList,
												_List_fromArray(
													[
														_Utils_Tuple2('width', '130px'),
														_Utils_Tuple2('display', 'inline-block')
													]),
												A2(
													$visotype$elm_dom$Dom$appendText,
													text,
													$visotype$elm_dom$Dom$element('span'))),
											$visotype$elm_dom$Dom$element('button'))))))))));
	};
	return A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('margin', '50px 0'),
				_Utils_Tuple2('text-align', 'center')
			]),
		$author$project$Component$Layout$container(
			A2($elm$core$List$map, toButton, $author$project$NpsSurvey$ratingButtonsAttributes)));
};
var $author$project$Component$Text$title = F2(
	function (level, text) {
		return A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				[
					'title',
					'is-size-' + $elm$core$String$fromInt(level)
				]),
			A2(
				$visotype$elm_dom$Dom$appendText,
				text,
				$visotype$elm_dom$Dom$element(
					'h' + $elm$core$String$fromInt(level))));
	});
var $author$project$NpsSurvey$view = F2(
	function (viewData, model) {
		var npsSurveyResult = model.h3;
		var title = A2(
			$visotype$elm_dom$Dom$setId,
			'tts-nps-header',
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('text-align', 'center'),
				A2(
					$visotype$elm_dom$Dom$appendChild,
					A2(
						$visotype$elm_dom$Dom$appendChild,
						A5($author$project$TextToSpeech$speakable, $author$project$NpsSurvey$PlayTTS, viewData, true, 'How much do you like eSpark?', $author$project$Models$Voice$default),
						A2($author$project$Component$Text$title, 2, '')),
					$visotype$elm_dom$Dom$element('div'))));
		var textField = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('padding', '12px 24px'),
					_Utils_Tuple2('font-size', '24px'),
					_Utils_Tuple2('width', '100%')
				]),
			A2(
				$visotype$elm_dom$Dom$addInputHandler,
				function (newString) {
					return $author$project$NpsSurvey$NpsSurveyUpdated(
						_Utils_update(
							npsSurveyResult,
							{cV: newString}));
				},
				A2(
					$visotype$elm_dom$Dom$addAttributeList,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$placeholder('(optional)'),
							$elm$html$Html$Attributes$value(npsSurveyResult.cV)
						]),
					$visotype$elm_dom$Dom$element('textarea'))));
		var textInputSection = A2(
			$author$project$Styles$size,
			{hb: '130px', jS: '500px'},
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('text-align', 'center'),
				A2(
					$visotype$elm_dom$Dom$addClass,
					'container',
					$author$project$Component$Layout$container(
						_List_fromArray(
							[
								A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('text-align', 'left'),
								A2(
									$visotype$elm_dom$Dom$appendChild,
									A5($author$project$TextToSpeech$speakable, $author$project$NpsSurvey$PlayTTS, viewData, false, 'Why?', $author$project$Models$Voice$default),
									A2($author$project$Component$Text$title, 3, ''))),
								textField
							])))));
		var sendButton = function () {
			var _v0 = npsSurveyResult.e4;
			if (!_v0) {
				return $visotype$elm_dom$Dom$element('span');
			} else {
				return A2(
					$author$project$Dom$Extra$addDataCy,
					'submit-nps-button',
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('position', 'absolute'),
								_Utils_Tuple2('bottom', '24px'),
								_Utils_Tuple2('right', '20px')
							]),
						A2(
							$author$project$Views$Components$Button$next,
							$krisajenkins$remotedata$RemoteData$isLoading(model.ak),
							$author$project$NpsSurvey$SubmitNps(npsSurveyResult))));
			}
		}();
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('padding-top', '110px'),
					_Utils_Tuple2('position', 'fixed'),
					_Utils_Tuple2('z-index', '1040'),
					_Utils_Tuple2('top', '0'),
					_Utils_Tuple2('bottom', '0'),
					_Utils_Tuple2('left', '0'),
					_Utils_Tuple2('right', '0')
				]),
			A2(
				$author$project$Styles$overlayBackground,
				viewData.iQ,
				A2(
					$visotype$elm_dom$Dom$addClass,
					'overlay',
					A2(
						$author$project$Dom$Extra$addDataCy,
						'nps-survey',
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[
									$author$project$Component$Layout$container(
									_List_fromArray(
										[
											title,
											$author$project$NpsSurvey$ratingButtons(npsSurveyResult),
											textInputSection
										])),
									sendButton,
									A2(
									$visotype$elm_dom$Dom$addStyleList,
									_List_fromArray(
										[
											_Utils_Tuple2('position', 'absolute'),
											_Utils_Tuple2('top', '0'),
											_Utils_Tuple2('right', '0'),
											_Utils_Tuple2('background-color', 'inherit'),
											_Utils_Tuple2('padding', '0')
										]),
									A2(
										$author$project$Dom$Extra$addDataCy,
										'skip-nps-button',
										$author$project$Views$Components$Button$close($author$project$NpsSurvey$SkipNps)))
								]),
							$visotype$elm_dom$Dom$element('div'))))));
	});
var $author$project$Component$Lightbulbs$ActiveLightbulb = function (a) {
	return {$: 1, a: a};
};
var $author$project$Messages$NavigateTo = function (a) {
	return {$: 28, a: a};
};
var $author$project$Messages$NavigateToActivity = function (a) {
	return {$: 29, a: a};
};
var $author$project$AppFlow$PreviousSequentialActivity = function (a) {
	return {$: 4, a: a};
};
var $author$project$Routes$activityAssessmentRoute = function (studentActivity) {
	var _v0 = studentActivity.f_.dG;
	if (!_v0.$) {
		var componentType = _v0.a;
		return $author$project$Routes$ActivityRoute(
			A3($author$project$Routes$ActivityRoutes$Component, studentActivity.a_, studentActivity.hj, componentType));
	} else {
		return $author$project$Routes$ActivityRoute(
			A3($author$project$Routes$ActivityRoutes$Assessment, $author$project$Learnosity$Model$init, studentActivity.a_, studentActivity.hj));
	}
};
var $author$project$Models$StudentActivity$canStartCheckForUnderstanding = function (_v0) {
	var activity = _v0.f_;
	var progress = _v0.ir;
	_v1$2:
	while (true) {
		if ((progress.$ === 1) && (!progress.a.$)) {
			if (!progress.a.b.$) {
				var _v2 = progress.a;
				var _v3 = _v2.b;
				return false;
			} else {
				if (progress.a.a.$ === 2) {
					var _v4 = progress.a;
					return $author$project$Models$Activity$hasActivityAssessment(activity);
				} else {
					break _v1$2;
				}
			}
		} else {
			break _v1$2;
		}
	}
	return false;
};
var $author$project$Routes$activityPracticeRoute = function (studentActivity) {
	var activity = studentActivity.f_;
	var studentQuestId = studentActivity.a_;
	var quizAssessmentRoute = function () {
		var _v1 = studentActivity.f_.dG;
		if (!_v1.$) {
			var componentType = _v1.a;
			return $author$project$Routes$ActivityRoute(
				A3($author$project$Routes$ActivityRoutes$Component, studentQuestId, studentActivity.hj, componentType));
		} else {
			return $author$project$Routes$QuizRoute(
				A3($author$project$Routes$QuizRoutes$Assessment, $author$project$Learnosity$Model$init, studentQuestId, studentActivity.hj));
		}
	}();
	if ($author$project$Models$StudentActivity$canStartCheckForUnderstanding(studentActivity)) {
		return $author$project$Routes$activityAssessmentRoute(studentActivity);
	} else {
		var _v0 = activity.f0;
		switch (_v0.$) {
			case 7:
				return quizAssessmentRoute;
			case 8:
				return quizAssessmentRoute;
			case 6:
				return $author$project$Routes$activityAssessmentRoute(studentActivity);
			default:
				return $author$project$Routes$ActivityRoute(
					A2($author$project$Routes$ActivityRoutes$Practice, studentQuestId, studentActivity.hj));
		}
	}
};
var $author$project$Component$Monster$defaultDialog = {as: 'Let\'s learn about {0} together!', aw: 'Hey there!'};
var $author$project$Component$Monster$dialogs = _List_fromArray(
	[
		$author$project$Component$Monster$defaultDialog,
		{as: 'Let\'s learn about {0}!', aw: 'Howdy!'},
		{as: 'I\'d like to tell you about {0}.', aw: 'Oh, hello!'},
		{as: 'I want to share {0} with you.', aw: 'Hi, friend!'},
		{as: 'I learned about {0} and want to share it with you!', aw: 'Aloha!'},
		{as: 'I want to share this activity about {0} with you!', aw: 'Jambo!'}
	]);
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $author$project$Component$Monster$indexToDialog = function (_int) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Component$Monster$defaultDialog,
		A2(
			$elm_community$list_extra$List$Extra$getAt,
			A2(
				$elm$core$Basics$modBy,
				$elm$core$List$length($author$project$Component$Monster$dialogs),
				_int),
			$author$project$Component$Monster$dialogs));
};
var $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation = F2(
	function (replacements, _v0) {
		var match = _v0.hE;
		var ordinalString = A2(
			$elm$core$Basics$composeL,
			$elm$core$String$dropLeft(1),
			$elm$core$String$dropRight(1))(match);
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				function (value) {
					return A2($elm$core$Array$get, value, replacements);
				},
				$elm$core$String$toInt(ordinalString)));
	});
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\{\\d+\\}'));
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolate = F2(
	function (string, args) {
		var asArray = $elm$core$Array$fromList(args);
		return A3(
			$elm$regex$Regex$replace,
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex,
			$lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation(asArray),
			string);
	});
var $author$project$Models$Activity$sequence = function (activity) {
	var _v0 = activity.e9;
	var activitySequence = _v0;
	return activitySequence;
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $author$project$Views$ActivityIntroView$nonQuizBannerText = F2(
	function (quest, activity) {
		var dialog = $author$project$Component$Monster$indexToDialog(
			$author$project$Models$Activity$sequence(activity));
		var activityIntroMessage = A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			dialog.as,
			$elm$core$List$singleton(
				$elm$core$String$toLower(quest.i0)));
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[dialog.aw, activityIntroMessage]));
	});
var $author$project$Views$ActivityIntroView$quizBannerText = F2(
	function (quest, activity) {
		var questionCountAsWords = $elm$core$Dict$fromList(
			_List_fromArray(
				[
					_Utils_Tuple2(0, 'a question'),
					_Utils_Tuple2(1, 'a question'),
					_Utils_Tuple2(2, 'all two questions'),
					_Utils_Tuple2(3, 'all three questions'),
					_Utils_Tuple2(4, 'all four questions'),
					_Utils_Tuple2(5, 'all five questions'),
					_Utils_Tuple2(6, 'all six questions'),
					_Utils_Tuple2(7, 'all seven questions'),
					_Utils_Tuple2(8, 'all eight questions'),
					_Utils_Tuple2(9, 'all nine questions'),
					_Utils_Tuple2(10, 'all ten questions')
				]));
		var questionCount = function (count) {
			return A2(
				$elm$core$Maybe$withDefault,
				'all ' + ($elm$core$String$fromInt(count) + ' questions'),
				A2($elm$core$Dict$get, count, questionCountAsWords));
		};
		var promptStem = function () {
			var _v0 = activity.f0;
			if (_v0.$ === 8) {
				return ' Let’s see how much you’ve learned about ';
			} else {
				return ' Let’s see what you know about ';
			}
		}();
		var greeting = $author$project$Component$Monster$indexToDialog(
			$author$project$Models$Activity$sequence(activity)).aw;
		return A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					greeting,
					promptStem,
					$elm$core$String$toLower(quest.i0),
					'. Try to get ',
					questionCount(quest.iv),
					' right!'
				]));
	});
var $author$project$Component$Lightbulbs$VideoChallengeLightbulb = {$: 2};
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$Models$ActiveQuest$failedStudentQuest = function (activeQuest) {
	var postquizzes = A2(
		$elm$core$List$filter,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.f_;
			},
			$author$project$Models$Activity$isPostquiz),
		activeQuest.jd);
	var minPostquizzesForQuest = activeQuest.dq ? 1 : 2;
	var allPostquizzesFailed = A2(
		$elm$core$List$all,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.gd;
			},
			A2(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				false,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.$8;
					},
					$elm$core$Basics$not))),
		postquizzes);
	return (_Utils_cmp(
		$elm$core$List$length(postquizzes),
		minPostquizzesForQuest) > -1) && allPostquizzesFailed;
};
var $author$project$Models$ActiveQuest$videoRecordingActivityForDisplay = F2(
	function (videoRecordingPermitted, activeQuest) {
		return videoRecordingPermitted ? activeQuest.jg : $elm$core$Maybe$Nothing;
	});
var $author$project$Component$Lightbulbs$activitiesForLightbulbs = F2(
	function (activeQuest, videoRecordingPermitted) {
		var videoActivityForDisplay = A2($author$project$Models$ActiveQuest$videoRecordingActivityForDisplay, videoRecordingPermitted, activeQuest);
		var appendStudentVideoIfPresent = function (items) {
			return ((!_Utils_eq(videoActivityForDisplay, $elm$core$Maybe$Nothing)) && (!$author$project$Models$ActiveQuest$failedStudentQuest(activeQuest))) ? _Utils_ap(
				items,
				_List_fromArray(
					[$author$project$Component$Lightbulbs$VideoChallengeLightbulb])) : items;
		};
		return appendStudentVideoIfPresent(
			A2(
				$elm$core$List$map,
				$author$project$Component$Lightbulbs$ActiveLightbulb,
				A2(
					$elm$core$List$filter,
					function (sa) {
						return !_Utils_eq(sa.ir, $author$project$Models$StudentActivity$UnavailableStudentActivity);
					},
					activeQuest.jd)));
	});
var $author$project$Assets$lightbulbComplete = require('/usr/src/app/app/images/lightbulbs/complete.svg');
var $author$project$Assets$lightbulbCompleteCurrent = require('/usr/src/app/app/images/lightbulbs/complete-current.svg');
var $author$project$Assets$lightbulbIncomplete = require('/usr/src/app/app/images/lightbulbs/incomplete.svg');
var $author$project$Assets$lightbulbIncompleteCurrent = require('/usr/src/app/app/images/lightbulbs/incomplete-current.svg');
var $author$project$Assets$lightbulbRibbonComplete = require('/usr/src/app/app/images/lightbulbs/ribbon-complete.svg');
var $author$project$Assets$lightbulbRibbonCompleteCurrent = require('/usr/src/app/app/images/lightbulbs/ribbon-complete-current.svg');
var $author$project$Component$Lightbulbs$renderIndividualBulb = F3(
	function (activeQuest, currentLightbulb, activityLightbulb) {
		var passedCheckForUnderstanding = function () {
			if (activityLightbulb.$ === 1) {
				var activity = activityLightbulb.a.f_;
				var assessmentResult = activityLightbulb.a.gd;
				var _v6 = _Utils_Tuple2(
					$author$project$Models$Activity$hasPracticeContent(activity),
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.$8;
						},
						assessmentResult));
				if (_v6.a && (!_v6.b.$)) {
					var passedQuiz = _v6.b.a;
					return passedQuiz;
				} else {
					return false;
				}
			} else {
				return false;
			}
		}();
		var isCurrent = _Utils_eq(currentLightbulb, activityLightbulb);
		var isCompleted = function () {
			switch (activityLightbulb.$) {
				case 1:
					var studentActivity = activityLightbulb.a;
					var _v3 = studentActivity.ir;
					if (_v3.$ === 2) {
						return true;
					} else {
						return false;
					}
				case 2:
					var _v4 = activeQuest.jg;
					if (!_v4.$) {
						var studentVideo = _v4.a;
						return studentVideo.aM;
					} else {
						return true;
					}
				default:
					return true;
			}
		}();
		var idForBulb = function () {
			switch (activityLightbulb.$) {
				case 1:
					var id = activityLightbulb.a.hj;
					return 'lightbulb-' + $author$project$Models$StudentActivity$idToString(id);
				case 2:
					return 'lightbulb-video-challenge';
				default:
					return 'lightbulb-unknown';
			}
		}();
		var _v0 = (isCompleted && isCurrent) ? (passedCheckForUnderstanding ? _Utils_Tuple3(
			$author$project$Assets$lightbulbRibbonCompleteCurrent,
			'Current, completed activity with passed test',
			_List_fromArray(
				['current-lightbulb', 'lightbulb-ribbon'])) : _Utils_Tuple3(
			$author$project$Assets$lightbulbCompleteCurrent,
			'Current, completed activity',
			_List_fromArray(
				['completed-lightbulb', 'current-lightbulb']))) : (isCompleted ? (passedCheckForUnderstanding ? _Utils_Tuple3(
			$author$project$Assets$lightbulbRibbonComplete,
			'Completed activity with passed test',
			_List_fromArray(
				['lightbulb-ribbon'])) : _Utils_Tuple3(
			$author$project$Assets$lightbulbComplete,
			'Completed activity',
			_List_fromArray(
				['completed-lightbulb']))) : (isCurrent ? _Utils_Tuple3(
			$author$project$Assets$lightbulbIncompleteCurrent,
			'Current, incomplete activity',
			_List_fromArray(
				['incomplete-lightbulb', 'current-lightbulb'])) : _Utils_Tuple3(
			$author$project$Assets$lightbulbIncomplete,
			'Incomplete activity',
			_List_fromArray(
				['incomplete-lightbulb']))));
		var bulbAsset = _v0.a;
		var bulbAltText = _v0.b;
		var bulbClasses = _v0.c;
		var bulb = A2(
			$visotype$elm_dom$Dom$addClassList,
			A2($elm$core$List$cons, 'lightbulb', bulbClasses),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('width', '40px'),
							_Utils_Tuple2('height', '61px')
						]),
					A2(
						$visotype$elm_dom$Dom$addAttributeList,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(
								$author$project$Assets$path(bulbAsset)),
								$elm$html$Html$Attributes$alt(bulbAltText)
							]),
						$visotype$elm_dom$Dom$element('img'))),
				$visotype$elm_dom$Dom$element('span')));
		return A2(
			$visotype$elm_dom$Dom$setId,
			idForBulb,
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('font-size', '50px'),
				bulb));
	});
var $author$project$Component$Lightbulbs$render = F3(
	function (activeQuest, currentLightbulb, _v0) {
		var appFlowMode = _v0.f8;
		var videoRecordingPermitted = _v0.jM;
		if (!appFlowMode.$) {
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('position', 'absolute'),
						_Utils_Tuple2('bottom', '61px'),
						_Utils_Tuple2('z-index', '100'),
						_Utils_Tuple2('display', 'flex'),
						_Utils_Tuple2('justify-content', 'center'),
						_Utils_Tuple2('width', '100%'),
						_Utils_Tuple2('pointer-events', 'none'),
						_Utils_Tuple2('height', '1px')
					]),
				A2(
					$visotype$elm_dom$Dom$appendChildList,
					A2(
						$elm$core$List$map,
						A2($author$project$Component$Lightbulbs$renderIndividualBulb, activeQuest, currentLightbulb),
						A2($author$project$Component$Lightbulbs$activitiesForLightbulbs, activeQuest, videoRecordingPermitted)),
					A2(
						$author$project$Dom$Extra$addDataCy,
						'lightbulbs',
						$visotype$elm_dom$Dom$element('div'))));
		} else {
			return A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('display', 'none'),
				$visotype$elm_dom$Dom$element('span'));
		}
	});
var $author$project$Component$Monster$FacingLeft = 0;
var $author$project$Component$Monster$FacingRight = 1;
var $author$project$Component$Types$OffsetRight = 0;
var $author$project$Animations$CssAnimations$PulsateInfinitelyWithDelay3 = 7;
var $author$project$Component$Monster$Rocky = 6;
var $author$project$Component$Monster$addBanner = F2(
	function (banner, monsters) {
		var wrapMonsterWithBanner = function (wrapper) {
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('height', 'calc(83vh)'),
						_Utils_Tuple2('position', 'relative'),
						_Utils_Tuple2('display', 'flex'),
						_Utils_Tuple2('flex-direction', 'column'),
						_Utils_Tuple2('flex-wrap', 'nowrap'),
						_Utils_Tuple2('justify-content', 'space-between'),
						_Utils_Tuple2('align-items', 'stretch'),
						_Utils_Tuple2('align-content', 'stretch')
					]),
				wrapper);
		};
		var scaleMonsterWithWrapper = function (monster) {
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('height', '90%'),
						_Utils_Tuple2('bottom', '0'),
						_Utils_Tuple2('max-height', '330px')
					]),
				monster);
		};
		var positionMonsterContainer = function (monsterContainer) {
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('height', 'auto'),
						_Utils_Tuple2('position', 'relative'),
						_Utils_Tuple2('flex-shrink', '1'),
						_Utils_Tuple2('flex-grow', '1')
					]),
				monsterContainer);
		};
		var positionBanner = function (monsterBanner) {
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('position', 'relative'),
						_Utils_Tuple2('flex-shrink', '1'),
						_Utils_Tuple2('flex-grow', '0')
					]),
				monsterBanner);
		};
		return wrapMonsterWithBanner(
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						positionBanner(banner),
						positionMonsterContainer(
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							A2($elm$core$List$map, scaleMonsterWithWrapper, monsters),
							A2(
								$visotype$elm_dom$Dom$addClass,
								'monster-container',
								$visotype$elm_dom$Dom$element('div'))))
					]),
				A2(
					$visotype$elm_dom$Dom$addClass,
					'monster-with-banner-wrapper',
					$visotype$elm_dom$Dom$element('div'))));
	});
var $author$project$Component$Monster$Ant = 3;
var $author$project$Component$Monster$Bunny = 1;
var $author$project$Component$Monster$Cat = 0;
var $author$project$Component$Monster$ManyEyes = 2;
var $author$project$Component$Monster$Pineapple = 5;
var $author$project$Component$Monster$Rainbow = 4;
var $author$project$Component$Monster$availableMonsters = _List_fromArray(
	[3, 1, 0, 2, 5, 4]);
var $author$project$Component$Monster$defaultMonsterName = 3;
var $author$project$Component$Monster$indexToName = function (i) {
	var mod = A2(
		$elm$core$Basics$modBy,
		$elm$core$List$length($author$project$Component$Monster$availableMonsters),
		i);
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Component$Monster$defaultMonsterName,
		A2($elm_community$list_extra$List$Extra$getAt, mod, $author$project$Component$Monster$availableMonsters));
};
var $author$project$Styles$monsterOnPath = $visotype$elm_dom$Dom$addStyleList(
	_List_fromArray(
		[
			_Utils_Tuple2('position', 'absolute'),
			_Utils_Tuple2('bottom', '17%')
		]));
var $author$project$Models$StudentActivity$progressToString = function (progress) {
	switch (progress.$) {
		case 0:
			return 'unstarted';
		case 1:
			return 'active';
		case 2:
			return 'completed';
		case 3:
			return 'unavailable';
		default:
			return 'errored';
	}
};
var $author$project$Assets$monsterAnt = require('/usr/src/app/app/images/monsters/ant/normal.svg');
var $author$project$Assets$monsterAntHalloween = require('/usr/src/app/app/images/monsters/ant/halloween2019.svg');
var $author$project$Assets$monsterAntWinter = require('/usr/src/app/app/images/monsters/ant/winter2019.svg');
var $author$project$Assets$monsterBunny = require('/usr/src/app/app/images/monsters/bunny/normal.svg');
var $author$project$Assets$monsterBunnyHalloween = require('/usr/src/app/app/images/monsters/bunny/halloween2019.svg');
var $author$project$Assets$monsterBunnyWinter = require('/usr/src/app/app/images/monsters/bunny/winter2019.svg');
var $author$project$Assets$monsterCat = require('/usr/src/app/app/images/monsters/cat/normal.svg');
var $author$project$Assets$monsterCatHalloween = require('/usr/src/app/app/images/monsters/cat/halloween2019.svg');
var $author$project$Assets$monsterCatWinter = require('/usr/src/app/app/images/monsters/cat/winter2019.svg');
var $author$project$Assets$monsterManyEyes = require('/usr/src/app/app/images/monsters/many-eyes/normal.svg');
var $author$project$Assets$monsterManyEyesHalloween = require('/usr/src/app/app/images/monsters/many-eyes/halloween2019.svg');
var $author$project$Assets$monsterManyEyesWinter = require('/usr/src/app/app/images/monsters/many-eyes/winter2019.svg');
var $author$project$Assets$monsterPineapple = require('/usr/src/app/app/images/monsters/pineapple/normal.svg');
var $author$project$Assets$monsterPineappleHalloween = require('/usr/src/app/app/images/monsters/pineapple/halloween2019.svg');
var $author$project$Assets$monsterPineappleWinter = require('/usr/src/app/app/images/monsters/pineapple/winter2019.svg');
var $author$project$Assets$monsterRainbow = require('/usr/src/app/app/images/monsters/rainbow/normal.svg');
var $author$project$Assets$monsterRainbowHalloween = require('/usr/src/app/app/images/monsters/rainbow/halloween2019.svg');
var $author$project$Assets$monsterRainbowWinter = require('/usr/src/app/app/images/monsters/rainbow/winter2019.svg');
var $author$project$Assets$rockyFacingForward = require('/usr/src/app/app/images/monsters/rocky/normal.svg');
var $author$project$Assets$rockyFacingForwardHalloween = require('/usr/src/app/app/images/monsters/rocky/halloween2019.svg');
var $author$project$Assets$rockyFacingForwardWinter = require('/usr/src/app/app/images/monsters/rocky/winter2019.svg');
var $author$project$Assets$rockyWaveAtCamera = require('/usr/src/app/app/images/monsters/rocky/wave-at-camera.svg');
var $author$project$Assets$rockyWaveAtCameraWinter = require('/usr/src/app/app/images/monsters/rocky/wave-at-camera-winter2019.svg');
var $author$project$Component$Monster$monsterSvgAsset = F2(
	function (seasonalTheme, name) {
		var _v0 = _Utils_Tuple2(name, seasonalTheme);
		switch (_v0.b) {
			case 0:
				switch (_v0.a) {
					case 3:
						var _v1 = _v0.a;
						var _v2 = _v0.b;
						return $author$project$Assets$monsterAnt;
					case 1:
						var _v9 = _v0.a;
						var _v10 = _v0.b;
						return $author$project$Assets$monsterBunny;
					case 0:
						var _v17 = _v0.a;
						var _v18 = _v0.b;
						return $author$project$Assets$monsterCat;
					case 2:
						var _v25 = _v0.a;
						var _v26 = _v0.b;
						return $author$project$Assets$monsterManyEyes;
					case 5:
						var _v33 = _v0.a;
						var _v34 = _v0.b;
						return $author$project$Assets$monsterPineapple;
					case 4:
						var _v41 = _v0.a;
						var _v42 = _v0.b;
						return $author$project$Assets$monsterRainbow;
					case 6:
						var _v49 = _v0.a;
						var _v50 = _v0.b;
						return $author$project$Assets$rockyFacingForward;
					default:
						var _v57 = _v0.a;
						var _v58 = _v0.b;
						return $author$project$Assets$rockyWaveAtCamera;
				}
			case 1:
				switch (_v0.a) {
					case 3:
						var _v3 = _v0.a;
						var _v4 = _v0.b;
						return $author$project$Assets$monsterAnt;
					case 1:
						var _v11 = _v0.a;
						var _v12 = _v0.b;
						return $author$project$Assets$monsterBunny;
					case 0:
						var _v19 = _v0.a;
						var _v20 = _v0.b;
						return $author$project$Assets$monsterCat;
					case 2:
						var _v27 = _v0.a;
						var _v28 = _v0.b;
						return $author$project$Assets$monsterManyEyes;
					case 5:
						var _v35 = _v0.a;
						var _v36 = _v0.b;
						return $author$project$Assets$monsterPineapple;
					case 4:
						var _v43 = _v0.a;
						var _v44 = _v0.b;
						return $author$project$Assets$monsterRainbow;
					case 6:
						var _v51 = _v0.a;
						var _v52 = _v0.b;
						return $author$project$Assets$rockyFacingForward;
					default:
						var _v59 = _v0.a;
						var _v60 = _v0.b;
						return $author$project$Assets$rockyWaveAtCamera;
				}
			case 3:
				switch (_v0.a) {
					case 3:
						var _v5 = _v0.a;
						var _v6 = _v0.b;
						return $author$project$Assets$monsterAntWinter;
					case 1:
						var _v13 = _v0.a;
						var _v14 = _v0.b;
						return $author$project$Assets$monsterBunnyWinter;
					case 0:
						var _v21 = _v0.a;
						var _v22 = _v0.b;
						return $author$project$Assets$monsterCatWinter;
					case 2:
						var _v29 = _v0.a;
						var _v30 = _v0.b;
						return $author$project$Assets$monsterManyEyesWinter;
					case 5:
						var _v37 = _v0.a;
						var _v38 = _v0.b;
						return $author$project$Assets$monsterPineappleWinter;
					case 4:
						var _v45 = _v0.a;
						var _v46 = _v0.b;
						return $author$project$Assets$monsterRainbowWinter;
					case 6:
						var _v53 = _v0.a;
						var _v54 = _v0.b;
						return $author$project$Assets$rockyFacingForwardWinter;
					default:
						var _v61 = _v0.a;
						var _v62 = _v0.b;
						return $author$project$Assets$rockyWaveAtCameraWinter;
				}
			default:
				switch (_v0.a) {
					case 3:
						var _v7 = _v0.a;
						var _v8 = _v0.b;
						return $author$project$Assets$monsterAntHalloween;
					case 1:
						var _v15 = _v0.a;
						var _v16 = _v0.b;
						return $author$project$Assets$monsterBunnyHalloween;
					case 0:
						var _v23 = _v0.a;
						var _v24 = _v0.b;
						return $author$project$Assets$monsterCatHalloween;
					case 2:
						var _v31 = _v0.a;
						var _v32 = _v0.b;
						return $author$project$Assets$monsterManyEyesHalloween;
					case 5:
						var _v39 = _v0.a;
						var _v40 = _v0.b;
						return $author$project$Assets$monsterPineappleHalloween;
					case 4:
						var _v47 = _v0.a;
						var _v48 = _v0.b;
						return $author$project$Assets$monsterRainbowHalloween;
					case 6:
						var _v55 = _v0.a;
						var _v56 = _v0.b;
						return $author$project$Assets$rockyFacingForwardHalloween;
					default:
						var _v63 = _v0.a;
						var _v64 = _v0.b;
						return $author$project$Assets$rockyWaveAtCamera;
				}
		}
	});
var $author$project$Component$Monster$renderSvg = F3(
	function (seasonalTheme, name, orientation) {
		var monster = A3(
			$visotype$elm_dom$Dom$addClassConditional,
			'rocky',
			name === 6,
			A3(
				$visotype$elm_dom$Dom$addClassConditional,
				'is-reflected-horizontal',
				!orientation,
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('pointer-events', 'none'),
							_Utils_Tuple2('position', 'relative'),
							_Utils_Tuple2('height', '90%'),
							_Utils_Tuple2('width', 'auto')
						]),
					$author$project$Component$Image$render(
						{
							f5: 'Monster Friend',
							hb: 350,
							ig: $author$project$Assets$path(
								A2($author$project$Component$Monster$monsterSvgAsset, seasonalTheme, name)),
							jS: 300
						}))));
		var monsterShadow = A2(
			$visotype$elm_dom$Dom$addAttribute,
			$elm$html$Html$Attributes$alt(''),
			A2(
				$visotype$elm_dom$Dom$addClass,
				'monster-shadow',
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('position', 'absolute'),
							_Utils_Tuple2('opacity', '0.5'),
							_Utils_Tuple2('filter', 'blur(12px) grayscale(1) brightness(0)')
						]),
					monster)));
		return A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					monsterShadow,
					A2($visotype$elm_dom$Dom$addClass, 'monster', monster)
				]),
			$visotype$elm_dom$Dom$element('div'));
	});
var $author$project$Component$Types$SideRight = 1;
var $author$project$Assets$speechBubbleTailLeft = require('/usr/src/app/app/images/speech-bubble/speech-bubble-tail-left@2x.png');
var $author$project$Component$SpeechBubbleBanner$banner = function (args) {
	var tailStyles = function () {
		var sideStyles = function () {
			var _v1 = args.bm;
			if (!_v1) {
				return _Utils_Tuple2('transform', 'none');
			} else {
				return _Utils_Tuple2('transform', 'scaleX(-1)');
			}
		}();
		var offsetStyles = function () {
			var _v0 = args.ao;
			switch (_v0) {
				case 1:
					return _Utils_Tuple2('left', '30%');
				case 0:
					return _Utils_Tuple2('left', '60%');
				default:
					return _Utils_Tuple2('left', 'calc(50% - 70.5px)');
			}
		}();
		return _List_fromArray(
			[
				offsetStyles,
				sideStyles,
				_Utils_Tuple2('position', 'absolute'),
				_Utils_Tuple2('max-width', '141px'),
				_Utils_Tuple2('top', '-8px')
			]);
	}();
	var tail = A2(
		$visotype$elm_dom$Dom$appendChild,
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			tailStyles,
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A2(
					$visotype$elm_dom$Dom$addAttribute,
					$elm$html$Html$Attributes$src(
						$author$project$Assets$path($author$project$Assets$speechBubbleTailLeft)),
					$visotype$elm_dom$Dom$element('img')),
				$visotype$elm_dom$Dom$element('div'))),
		A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('position', 'relative'),
			$visotype$elm_dom$Dom$element('div')));
	var bubble = A2(
		$author$project$Styles$filled,
		$author$project$ES$UI$Color$white,
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('padding', '50px 50px 40px'),
					_Utils_Tuple2('box-shadow', '3px 3px 10px rgba(0, 0, 0, .5)')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				args.gH,
				$visotype$elm_dom$Dom$element('div'))));
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[bubble, tail]),
		$visotype$elm_dom$Dom$element('div'));
};
var $author$project$Component$SpeechBubbleBanner$formatBubbleMessage = function (_v0) {
	var text = _v0.ju;
	var colorModifier = _v0.aL;
	var viewData = _v0.a1;
	var voice = _v0.fP;
	return A2(
		$visotype$elm_dom$Dom$addClassList,
		_List_fromArray(
			['is-size-2', colorModifier]),
		A2(
			$author$project$Dom$Extra$addDataCy,
			'banner-text',
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A4($author$project$TextToSpeech$WithGlobalMsg$speakable, viewData, viewData.gj, text, voice),
				$visotype$elm_dom$Dom$element('span'))));
};
var $author$project$Component$SpeechBubbleBanner$right = function (args) {
	return $author$project$Component$SpeechBubbleBanner$banner(
		{
			gH: $author$project$Component$SpeechBubbleBanner$formatBubbleMessage(
				{aL: args.aL, ju: args.ju, a1: args.a1, fP: args.fP}),
			ao: args.ao,
			bm: 1
		});
};
var $author$project$Models$Activity$typeToString = function (activityType) {
	switch (activityType.$) {
		case 0:
			return 'youtubevideo';
		case 7:
			return 'prequiz';
		case 8:
			return 'postquiz';
		case 6:
			return 'critical-thinking-challenge';
		case 1:
			return 'webapp';
		case 2:
			return 'ebook';
		case 3:
			return 'choice-text';
		case 4:
			return 'choice-writing';
		default:
			return 'live-reading';
	}
};
var $author$project$Models$Voice$Ivy = 2;
var $author$project$Models$Voice$Joanna = 1;
var $author$project$Models$Voice$Joey = 7;
var $author$project$Models$Voice$Justin = 6;
var $author$project$Models$Voice$Kevin = 8;
var $author$project$Models$Voice$Salli = 0;
var $author$project$Component$Monster$voice = function (name) {
	switch (name) {
		case 0:
			return 8;
		case 1:
			return 1;
		case 2:
			return 6;
		case 3:
			return 2;
		case 4:
			return 0;
		case 5:
			return 7;
		case 6:
			return $author$project$Models$Voice$default;
		default:
			return $author$project$Models$Voice$default;
	}
};
var $author$project$Views$RockyMeetsMonster$view = F2(
	function (viewData, _v0) {
		var studentActivity = _v0.je;
		var bannerText = _v0.gr;
		var previousButtonAction = _v0.ip;
		var nextButtonAction = _v0.hU;
		var _class = _v0.gE;
		var rocky = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('left', '16%')
				]),
			$author$project$Styles$monsterOnPath(
				A3($author$project$Component$Monster$renderSvg, viewData.iQ, 6, 1)));
		var renderPreviousButton = function (msg) {
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('left', '20px'),
						_Utils_Tuple2('bottom', '15px')
					]),
				A2(
					$author$project$Styles$onTop,
					101,
					$author$project$Styles$activityButton(
						A2(
							$visotype$elm_dom$Dom$addClass,
							'back-button',
							$author$project$Views$Components$Button$WithGlobalMsg$previous(msg)))));
		};
		var nextButton = A2(
			$author$project$Animations$CssAnimations$applyWithAnimationPreset,
			7,
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('right', '20px'),
				A2(
					$author$project$Styles$onTop,
					101,
					$author$project$Styles$activityButton(
						A2(
							$visotype$elm_dom$Dom$addClass,
							'activity-practice-button',
							A2($author$project$Views$Components$Button$WithGlobalMsg$next, viewData.gv, nextButtonAction))))));
		var activity = studentActivity.f_;
		var _v1 = activity.e9;
		var activitySequence = _v1;
		var monsterName = $author$project$Component$Monster$indexToName(activitySequence);
		var activityMonster = A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('right', '10%'),
			$author$project$Styles$monsterOnPath(
				A3($author$project$Component$Monster$renderSvg, viewData.iQ, monsterName, 0)));
		var banner = $author$project$Component$SpeechBubbleBanner$right(
			{
				aL: 'has-text-monster-' + $elm$core$String$fromInt(activitySequence),
				ao: 0,
				ju: bannerText,
				a1: viewData,
				fP: $author$project$Component$Monster$voice(monsterName)
			});
		return A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				[
					_class,
					$author$project$Models$Activity$typeToString(activity.f0),
					$author$project$Models$StudentActivity$progressToString(studentActivity.ir) + '-activity'
				]),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						A2(
						$author$project$Component$Monster$addBanner,
						banner,
						_List_fromArray(
							[rocky, activityMonster])),
						nextButton,
						A2(
						$elm$core$Maybe$withDefault,
						$visotype$elm_dom$Dom$element('span'),
						A2($elm$core$Maybe$map, renderPreviousButton, previousButtonAction))
					]),
				$visotype$elm_dom$Dom$element('div')));
	});
var $author$project$Views$ActivityIntroView$view = F3(
	function (studentActivity, activeQuest, viewData) {
		var activity = studentActivity.f_;
		var quest = activeQuest.it;
		var appFlowMode = viewData.f8;
		var previousButtonAction = function () {
			if (!appFlowMode.$) {
				return $author$project$Messages$NavigateToActivity(
					$author$project$AppFlow$PreviousSequentialActivity(studentActivity.hj));
			} else {
				return $author$project$Messages$NavigateTo(
					$author$project$Routes$QuestRoute(
						$author$project$Routes$QuestRoutes$Home(studentActivity.a_)));
			}
		}();
		var nextButtonAction = $author$project$Messages$NavigateTo(
			$author$project$Routes$activityPracticeRoute(studentActivity));
		var lightbulbs = A3(
			$author$project$Component$Lightbulbs$render,
			activeQuest,
			$author$project$Component$Lightbulbs$ActiveLightbulb(studentActivity),
			viewData);
		var _class = $author$project$Models$Activity$isPrePostQuiz(activity) ? 'is-activity-intro quiz-intro' : 'is-activity-intro';
		var bannerText = $author$project$Models$Activity$isPrePostQuiz(activity) ? A2($author$project$Views$ActivityIntroView$quizBannerText, quest, activity) : A2($author$project$Views$ActivityIntroView$nonQuizBannerText, quest, activity);
		var rockyMeetsMonster = A2(
			$author$project$Views$RockyMeetsMonster$view,
			viewData,
			{
				fZ: activeQuest,
				gr: bannerText,
				gE: _class,
				hU: nextButtonAction,
				ip: $elm$core$Maybe$Just(previousButtonAction),
				je: studentActivity,
				a1: viewData
			});
		return A2($visotype$elm_dom$Dom$appendChild, lightbulbs, rockyMeetsMonster);
	});
var $author$project$Models$StudentActivity$IframeActivityFooterHidden = 1;
var $author$project$Models$StudentActivity$IframeActivityFooterVisible = 0;
var $author$project$Models$Activity$isChoiceText = function (_v0) {
	var activityType = _v0.f0;
	return _Utils_eq(activityType, $author$project$Models$Activity$ChoiceText) || (_Utils_eq(activityType, $author$project$Models$Activity$ChoiceWriting) || _Utils_eq(activityType, $author$project$Models$Activity$LiveReading));
};
var $author$project$Models$Activity$ebookUrl = function (_v0) {
	var activityType = _v0.f0;
	if (activityType.$ === 2) {
		var url = activityType.a;
		return $elm$core$Maybe$Just(url);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Models$Activity$isEbook = function (activity) {
	return $elm_community$maybe_extra$Maybe$Extra$isJust(
		$author$project$Models$Activity$ebookUrl(activity));
};
var $author$project$Models$StudentActivity$iframeActivityFooterVisibility = F2(
	function (activity, viewStatus) {
		switch (viewStatus.$) {
			case 1:
				var footerVisibility = viewStatus.b;
				return footerVisibility;
			case 2:
				return 0;
			case 3:
				var footerVisibility = viewStatus.b;
				return footerVisibility;
			case 4:
				var footerVisibility = viewStatus.b;
				return footerVisibility;
			default:
				return ($author$project$Models$Activity$isEbook(activity) || $author$project$Models$Activity$isChoiceText(activity)) ? 1 : 0;
		}
	});
var $author$project$Views$ActivityPracticeView$footerHeight = function (studentActivity) {
	var _v0 = A2($author$project$Models$StudentActivity$iframeActivityFooterVisibility, studentActivity.f_, studentActivity.fL);
	if (_v0 === 1) {
		return '10px';
	} else {
		return '110px';
	}
};
var $author$project$Models$StudentActivity$hasCompletedContentForActiveActivity = function (_v0) {
	var progress = _v0.ir;
	if (((progress.$ === 1) && (!progress.a.$)) && (progress.a.a.$ === 2)) {
		var _v2 = progress.a;
		return true;
	} else {
		return false;
	}
};
var $author$project$Models$StudentActivity$isCompleted = function (_v0) {
	var progress = _v0.ir;
	if (progress.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Models$StudentActivity$isContentComplete = function (studentActivity) {
	return $author$project$Models$StudentActivity$isCompleted(studentActivity) || $author$project$Models$StudentActivity$hasCompletedContentForActiveActivity(studentActivity);
};
var $author$project$Models$StudentActivity$hasBeenRatedOnce = function (studentActivity) {
	return $author$project$Models$StudentActivity$isContentComplete(studentActivity) || studentActivity.hs;
};
var $author$project$Models$Activity$webAppUrl = function (_v0) {
	var activityType = _v0.f0;
	if (activityType.$ === 1) {
		var url = activityType.a;
		return $elm$core$Maybe$Just(url);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Activity$isWebApp = function (activity) {
	return !_Utils_eq(
		$author$project$Models$Activity$webAppUrl(activity),
		$elm$core$Maybe$Nothing);
};
var $author$project$Models$Activity$isExternalContent = function (activity) {
	return $author$project$Models$Activity$isWebApp(activity) || ($author$project$Models$Activity$isChoiceText(activity) || $author$project$Models$Activity$isEbook(activity));
};
var $author$project$ES$UI$Color$purple = {
	at: A3($avh4$elm_color$Color$rgb255, 145, 78, 184),
	au: A3($avh4$elm_color$Color$rgb255, 116, 62, 147),
	ay: A3($avh4$elm_color$Color$rgb255, 204, 126, 238),
	az: A3($avh4$elm_color$Color$rgb255, 224, 178, 245),
	aA: A3($avh4$elm_color$Color$rgb255, 248, 237, 255)
};
var $author$project$Messages$RestartEmbeddedYoutubeVideo = {$: 85};
var $author$project$Messages$RestartVideo = function (a) {
	return {$: 49, a: a};
};
var $author$project$Messages$RightClickOnVideoBlocked = {$: 53};
var $author$project$LoggedIn$Messages$VideoActivityPlaybackEnded = function (a) {
	return {$: 31, a: a};
};
var $author$project$Models$YoutubeVideoPlayer$canUseEmbeddedPlayer = F2(
	function (youtubeStatus, youtubeId) {
		switch (youtubeStatus.$) {
			case 1:
				var restrictedIds = youtubeStatus.a;
				return !A2($elm$core$List$member, youtubeId, restrictedIds);
			case 2:
				var _v1 = youtubeStatus;
				return false;
			default:
				var _v2 = youtubeStatus;
				return false;
		}
	});
var $author$project$Views$Error$configView = function (text) {
	return {b5: text, gj: false, bO: 'Oops, something went wrong!'};
};
var $author$project$Component$Icon$Play = 17;
var $author$project$Component$Buttons$Button$Yellow = 4;
var $author$project$Component$Buttons$Action$Large = 1;
var $author$project$Component$Icon$XX = {$: 4};
var $author$project$Views$Components$Button$buildVideoWatching = F3(
	function (iconGlyph, color, message) {
		return A2(
			$visotype$elm_dom$Dom$addAction,
			_Utils_Tuple2('click', message),
			A2(
				$author$project$Component$Buttons$Action$button,
				0,
				_Utils_Tuple3(
					1,
					color,
					A2($author$project$Component$Icon$Icon, iconGlyph, $author$project$Component$Icon$XX))));
	});
var $author$project$Views$Components$Button$videoWatchingPlay = A2($author$project$Views$Components$Button$buildVideoWatching, 17, 4);
var $author$project$Component$Icon$Replay = 14;
var $author$project$Component$Icon$XLarge = {$: 3};
var $author$project$Views$Components$Button$videoWatchingReplay = function (message) {
	return A2(
		$visotype$elm_dom$Dom$addAction,
		_Utils_Tuple2('click', message),
		A2(
			$author$project$Component$Buttons$Action$button,
			0,
			_Utils_Tuple3(
				0,
				0,
				A2($author$project$Component$Icon$Icon, 14, $author$project$Component$Icon$XLarge))));
};
var $author$project$Component$Video$controls = function (_v0) {
	var videoPlayState = _v0.jI;
	var videoKey = _v0.fJ;
	var overridePlayMsg = _v0.ie;
	var playMsg = _v0.ik;
	var restartMsg = _v0.iE;
	var replayButton = A2(
		$author$project$Dom$Extra$addDataCy,
		'video-replay-button',
		A2(
			$author$project$Styles$onTop,
			100,
			$author$project$Views$Components$Button$videoWatchingReplay(
				restartMsg(videoKey))));
	var playButton = A2(
		$author$project$Animations$CssAnimations$applyWithAnimationPreset,
		5,
		A2(
			$author$project$Dom$Extra$addDataCy,
			'video-play-button',
			A2(
				$author$project$Styles$onTop,
				100,
				$author$project$Views$Components$Button$videoWatchingPlay(
					A2(
						$elm$core$Maybe$withDefault,
						playMsg(videoKey),
						overridePlayMsg)))));
	switch (videoPlayState.$) {
		case 0:
			return playButton;
		case 3:
			return playButton;
		case 4:
			return replayButton;
		case 1:
			return $visotype$elm_dom$Dom$element('span');
		default:
			return $visotype$elm_dom$Dom$element('span');
	}
};
var $author$project$Views$Error$customOopsMessage = F2(
	function (text, config) {
		return _Utils_update(
			config,
			{bO: text});
	});
var $author$project$Views$Error$enableAudio = F2(
	function (audioEnabled, config) {
		return _Utils_update(
			config,
			{gj: audioEnabled});
	});
var $author$project$Views$Components$Spinner$foldingCube = A2(
	$visotype$elm_dom$Dom$appendChildList,
	_List_fromArray(
		[
			A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['sk-cube1', 'sk-cube']),
			$visotype$elm_dom$Dom$element('div')),
			A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['sk-cube2', 'sk-cube']),
			$visotype$elm_dom$Dom$element('div')),
			A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['sk-cube4', 'sk-cube']),
			$visotype$elm_dom$Dom$element('div')),
			A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['sk-cube3', 'sk-cube']),
			$visotype$elm_dom$Dom$element('div'))
		]),
	A2(
		$visotype$elm_dom$Dom$addClass,
		'sk-folding-cube',
		$visotype$elm_dom$Dom$element('div')));
var $author$project$Models$VideoPlayer$isEnded = function (videoPlayState) {
	if (videoPlayState.$ === 4) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Models$StudentActivity$ErroredStudentActivity = {$: 4};
var $author$project$Models$StudentActivity$isErrored = function (studentActivity) {
	return _Utils_eq(studentActivity.ir, $author$project$Models$StudentActivity$ErroredStudentActivity);
};
var $author$project$Models$StudentActivity$isNoLongerActive = function (studentActivity) {
	return $author$project$Models$StudentActivity$isCompleted(studentActivity) || $author$project$Models$StudentActivity$isErrored(studentActivity);
};
var $author$project$Models$Modal$isOpen = function (modalState) {
	if (!modalState.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Models$VideoPlayer$isPaused = function (videoPlayState) {
	if (videoPlayState.$ === 3) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Models$VideoPlayer$isPlaying = function (videoPlayState) {
	if (videoPlayState.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Models$YoutubeVideoPlayer$isYoutubeStatusKnown = $elm$core$Basics$neq($author$project$Models$YoutubeVideoPlayer$YoutubeStatusUnknown);
var $author$project$ES$UI$Color$black = A3($avh4$elm_color$Color$rgb, 0.0, 0.0, 0.0);
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $visotype$elm_dom$Dom$addActionPreventDefault = function (_v0) {
	var event = _v0.a;
	var msg = _v0.b;
	var handler = A2(
		$elm$core$Basics$composeR,
		$elm$json$Json$Decode$succeed,
		A2(
			$elm$core$Basics$composeR,
			$elm$json$Json$Decode$map(
				function (x) {
					return _Utils_Tuple2(x, true);
				}),
			$elm$virtual_dom$VirtualDom$MayPreventDefault));
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					c: A2(
						$elm$core$List$append,
						n.c,
						_List_fromArray(
							[
								_Utils_Tuple2(
								event,
								handler(msg))
							]))
				});
		});
};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$controls = $elm$html$Html$Attributes$boolProperty('controls');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Component$Video$render = function (args) {
	var toSourceNode = function (_v1) {
		var path = _v1.a;
		var filetype = _v1.b;
		return A2(
			$visotype$elm_dom$Dom$addAction,
			_Utils_Tuple2('error', args.co),
			A2(
				$visotype$elm_dom$Dom$addAttributeList,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(path),
						$elm$html$Html$Attributes$type_(filetype)
					]),
				$visotype$elm_dom$Dom$element('source')));
	};
	var toErrorMessage = function (_v0) {
		var path = _v0.a;
		var filetype = _v0.b;
		return A2(
			$visotype$elm_dom$Dom$appendText,
			$elm$core$String$concat(
				_List_fromArray(
					['Video file \'', path, '\' cannot be loaded. ', 'Verify that your browser is up to date and can play \'', filetype, '\' files.'])),
			$visotype$elm_dom$Dom$element('p'));
	};
	return A2(
		$visotype$elm_dom$Dom$appendChild,
		A2(
			$visotype$elm_dom$Dom$appendChildList,
			_Utils_ap(
				A2($elm$core$List$map, toSourceNode, args.cX),
				A2($elm$core$List$map, toErrorMessage, args.cX)),
			((!args.b_) ? $visotype$elm_dom$Dom$addActionPreventDefault(
				_Utils_Tuple2('contextmenu', args.da)) : $elm$core$Basics$identity)(
				A2(
					$visotype$elm_dom$Dom$addAttributeList,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$controls(args.b_),
							A2($elm$html$Html$Attributes$attribute, 'role', 'application')
						]),
					args.dk(
						$visotype$elm_dom$Dom$element('video'))))),
		A2(
			$visotype$elm_dom$Dom$addClass,
			'video',
			$visotype$elm_dom$Dom$element('figure')));
};
var $author$project$Component$Video$player = function (_v0) {
	var url = _v0.fE;
	var videoKey = _v0.fJ;
	var showNativeVideoControls = _v0.b_;
	var endedMsg = _v0.gV;
	var errorMsg = _v0.co;
	var videoRightClickMsg = _v0.da;
	var pauseMsg = _v0.ih;
	var videoCompletionHandler = $visotype$elm_dom$Dom$addAction(
		_Utils_Tuple2('ended', endedMsg));
	return A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('position', 'absolute'),
				_Utils_Tuple2('top', '0'),
				_Utils_Tuple2('left', '0'),
				_Utils_Tuple2('width', '100%')
			]),
		A2(
			$author$project$Styles$filled,
			$author$project$ES$UI$Color$black,
			A2(
				$visotype$elm_dom$Dom$addClass,
				'video-player',
				A2(
					$visotype$elm_dom$Dom$setId,
					videoKey,
					A2(
						$visotype$elm_dom$Dom$addAction,
						_Utils_Tuple2('click', pauseMsg),
						$author$project$Component$Video$render(
							{
								dk: videoCompletionHandler,
								co: errorMsg,
								b_: showNativeVideoControls,
								cX: _List_fromArray(
									[
										_Utils_Tuple2(url, 'video/mp4')
									]),
								da: videoRightClickMsg
							}))))));
};
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $author$project$Styles$setAbsolutePosition = function (_v0) {
	var top = _v0.jy;
	var right = _v0.iJ;
	var bottom = _v0.gt;
	var left = _v0.hx;
	return $visotype$elm_dom$Dom$addStyleList(
		_List_fromArray(
			[
				_Utils_Tuple2('position', 'absolute'),
				_Utils_Tuple2('top', top),
				_Utils_Tuple2('right', right),
				_Utils_Tuple2('bottom', bottom),
				_Utils_Tuple2('left', left)
			]));
};
var $author$project$Component$Video$progressBar = function (videoPlayState) {
	var videoPlayerTime = $author$project$Models$VideoPlayer$playerTime(videoPlayState);
	return (videoPlayerTime.dQ > 0) ? A2(
		$author$project$Dom$Extra$addDataCy,
		'video-progress-bar',
		A2(
			$author$project$Styles$onTop,
			1,
			A2(
				$author$project$Styles$setAbsolutePosition,
				{gt: '110px', hx: '', iJ: '', jy: ''},
				A2(
					$visotype$elm_dom$Dom$addAttributeList,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value(
							$elm$core$String$fromFloat(videoPlayerTime.cf)),
							$elm$html$Html$Attributes$max(
							$elm$core$String$fromFloat(videoPlayerTime.dQ))
						]),
					A2(
						$visotype$elm_dom$Dom$addClassList,
						_List_fromArray(
							['progress', 'is-small']),
						$visotype$elm_dom$Dom$element('progress')))))) : $visotype$elm_dom$Dom$element('span');
};
var $author$project$Styles$centerContent = $visotype$elm_dom$Dom$addStyleList(
	_List_fromArray(
		[
			_Utils_Tuple2('display', 'flex'),
			_Utils_Tuple2('align-items', 'center'),
			_Utils_Tuple2('justify-content', 'center')
		]));
var $author$project$Assets$rockyOops = require('/usr/src/app/app/images/monsters/rocky-oops.svg');
var $author$project$ES$UI$Color$yellow = {
	at: A3($avh4$elm_color$Color$rgb255, 242, 179, 11),
	au: A3($avh4$elm_color$Color$rgb255, 193, 143, 8),
	ay: A3($avh4$elm_color$Color$rgb255, 255, 208, 84),
	az: A3($avh4$elm_color$Color$rgb255, 255, 227, 152),
	aA: A3($avh4$elm_color$Color$rgb255, 255, 241, 205)
};
var $author$project$Views$Error$render = F2(
	function (playTTSMsg, config) {
		var errorMessage = A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					A2(
					$visotype$elm_dom$Dom$appendChild,
					A5(
						$author$project$TextToSpeech$speakable,
						playTTSMsg,
						{gj: config.gj},
						config.gj,
						config.bO,
						$author$project$Models$Voice$default),
					A2(
						$author$project$Component$Text$withSize,
						2,
						$visotype$elm_dom$Dom$element('div'))),
					A2(
					$visotype$elm_dom$Dom$appendChild,
					A5(
						$author$project$TextToSpeech$speakable,
						playTTSMsg,
						{gj: config.gj},
						config.gj,
						config.b5,
						$author$project$Models$Voice$default),
					A2(
						$author$project$Component$Text$withSize,
						2,
						A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2('max-width', '80%'),
									_Utils_Tuple2('display', 'inline-flex')
								]),
							$visotype$elm_dom$Dom$element('div'))))
				]),
			$visotype$elm_dom$Dom$element('div'));
		var errorImage = A2(
			$author$project$Styles$square,
			240,
			A2(
				$visotype$elm_dom$Dom$addAttribute,
				$elm$html$Html$Attributes$src(
					$author$project$Assets$path($author$project$Assets$rockyOops)),
				$visotype$elm_dom$Dom$element('img')));
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('flex-direction', 'column'),
					_Utils_Tuple2('position', 'fixed'),
					_Utils_Tuple2('top', '0'),
					_Utils_Tuple2('left', '0'),
					_Utils_Tuple2('right', '0'),
					_Utils_Tuple2('bottom', '0')
				]),
			A2(
				$author$project$Styles$filled,
				$author$project$ES$UI$Color$white,
				$author$project$Styles$centerContent(
					$author$project$Component$Text$centered(
						A2(
							$author$project$Component$Text$colored,
							$author$project$ES$UI$Color$yellow.at,
							A2(
								$visotype$elm_dom$Dom$appendChildList,
								_List_fromArray(
									[errorImage, errorMessage]),
								$visotype$elm_dom$Dom$element('div')))))));
	});
var $author$project$Component$Buttons$Button$Grey = 14;
var $author$project$Views$Components$Button$videoWatchingPlayUnavailable = A2($author$project$Views$Components$Button$buildVideoWatching, 17, 14);
var $author$project$Models$YoutubeVideoPlayer$youtubeVideoUrl = function (_v0) {
	var youtubeData = _v0.j5;
	var videoPlaybackControls = _v0.jJ;
	var origin = _v0.id;
	return A2(
		$elm$core$String$join,
		'&',
		_List_fromArray(
			[
				'https://www.youtube.com/embed/' + ($author$project$Models$Ids$toString(youtubeData.fW) + '?enablejsapi=1'),
				'modestbranding=1',
				'controls=' + (videoPlaybackControls ? '1' : '0'),
				'disablekb=1',
				'fs=0',
				'iv_load_policy=3',
				'rel=0',
				'loop=0',
				'origin=' + origin,
				'start=' + A2($elm$core$Maybe$withDefault, '', youtubeData.cc),
				'end=' + A2($elm$core$Maybe$withDefault, '', youtubeData.dB)
			]));
};
var $author$project$Views$ActivityPracticeView$VideoActivityPlayer$render = F5(
	function (studentActivity, hostedVideoData, youtubeData, voice, viewData) {
		var videoPlayState = viewData.jI;
		var devFlags = viewData.dO;
		var appFlowMode = viewData.f8;
		var youtubeStatus = viewData.aI;
		var clientBaseUrl = viewData.bz;
		var productFeatures = viewData.iq;
		var modalState = viewData.hM;
		var youtubeReplayButton = ($author$project$Models$VideoPlayer$isEnded(videoPlayState) && (!$author$project$Models$Modal$isOpen(modalState))) ? _List_fromArray(
			[
				A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('height', 'calc(100% - 110px)'),
						_Utils_Tuple2('width', '100%'),
						_Utils_Tuple2('background-color', 'gray'),
						_Utils_Tuple2('opacity', '0.8'),
						_Utils_Tuple2('position', 'absolute'),
						_Utils_Tuple2('top', '0'),
						_Utils_Tuple2('left', '0')
					]),
				$visotype$elm_dom$Dom$element('div')),
				$author$project$Views$Components$Button$videoWatchingReplay($author$project$Messages$RestartEmbeddedYoutubeVideo)
			]) : _List_fromArray(
			[
				$visotype$elm_dom$Dom$element('span')
			]);
		var youtubeDistractorBlocker = function () {
			if ($author$project$Models$VideoPlayer$isPaused(videoPlayState)) {
				return _List_fromArray(
					[
						A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('height', 'calc(100% - 110px)'),
								_Utils_Tuple2('width', '100%'),
								_Utils_Tuple2('background-color', 'gray'),
								_Utils_Tuple2('opacity', '0.8'),
								_Utils_Tuple2('position', 'absolute'),
								_Utils_Tuple2('top', '0'),
								_Utils_Tuple2('left', '0')
							]),
						$visotype$elm_dom$Dom$element('div')),
						A2(
						$author$project$Animations$CssAnimations$applyWithAnimationPreset,
						5,
						$author$project$Views$Components$Button$videoWatchingPlay($author$project$Messages$PlayEmbeddedYoutubePlayer))
					]);
			} else {
				if ($author$project$Models$VideoPlayer$isPlaying(videoPlayState)) {
					var offsetForDistractorBlockerBottom = devFlags.jJ ? '150' : '110';
					return _List_fromArray(
						[
							A2(
							$visotype$elm_dom$Dom$addAction,
							_Utils_Tuple2('click', $author$project$Messages$PauseEmbeddedYoutubePlayer),
							A2(
								$visotype$elm_dom$Dom$addStyleList,
								_List_fromArray(
									[
										_Utils_Tuple2('height', 'calc(100% - ' + (offsetForDistractorBlockerBottom + 'px)')),
										_Utils_Tuple2('opacity', '0'),
										_Utils_Tuple2('width', '100%'),
										_Utils_Tuple2('position', 'absolute'),
										_Utils_Tuple2('top', '0'),
										_Utils_Tuple2('left', '0')
									]),
								$visotype$elm_dom$Dom$element('div')))
						]);
				} else {
					return _List_fromArray(
						[
							A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2('height', '145px'),
									_Utils_Tuple2('opacity', '0'),
									_Utils_Tuple2('width', '100%'),
									_Utils_Tuple2('position', 'absolute'),
									_Utils_Tuple2('top', '0'),
									_Utils_Tuple2('left', '0')
								]),
							$visotype$elm_dom$Dom$element('div')),
							A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2('height', '90px'),
									_Utils_Tuple2('width', '130px'),
									_Utils_Tuple2('opacity', '0'),
									_Utils_Tuple2('position', 'absolute'),
									_Utils_Tuple2('bottom', '110px'),
									_Utils_Tuple2('videoRightClickMsg right', '0')
								]),
							$visotype$elm_dom$Dom$element('div'))
						]);
				}
			}
		}();
		var withTextToSpeech = F2(
			function (tagName, text) {
				return A2(
					$visotype$elm_dom$Dom$appendChild,
					A4(
						$author$project$TextToSpeech$WithGlobalMsg$speakable,
						viewData,
						!$author$project$Models$StudentActivity$isCompleted(studentActivity),
						text,
						voice),
					$visotype$elm_dom$Dom$element(tagName));
			});
		var shouldShowAsCompleted = $author$project$Models$StudentActivity$isNoLongerActive(studentActivity) || (studentActivity.hs || $author$project$Models$StudentActivity$hasBeenRatedOnce(studentActivity));
		var playVideoWithYoutube = A2($author$project$Models$YoutubeVideoPlayer$canUseEmbeddedPlayer, youtubeStatus, youtubeData.fW) && (!A2($author$project$Models$ProductFeature$isEnabled, 1, productFeatures));
		var message = $author$project$Models$StudentActivity$isCompleted(studentActivity) ? $author$project$Messages$NoOp : $author$project$Messages$LoggedInRequiredMsg(
			$author$project$LoggedIn$Messages$VideoActivityPlaybackEnded(studentActivity.hj));
		var loaderImage = A2(
			$visotype$elm_dom$Dom$appendChild,
			A2(
				$visotype$elm_dom$Dom$appendChild,
				$author$project$Views$Components$Spinner$foldingCube,
				A2(
					$visotype$elm_dom$Dom$addClass,
					'es-loader--quiz',
					$visotype$elm_dom$Dom$element('div'))),
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('position', 'fixed'),
				A2(
					$author$project$Styles$onTop,
					11,
					A2(
						$visotype$elm_dom$Dom$addClass,
						'es-loader__wrapper',
						$visotype$elm_dom$Dom$element('div')))));
		var activityKey = $author$project$Models$StudentActivity$activityKey(studentActivity.hj);
		var controls = $author$project$Component$Video$controls(
			{ie: $elm$core$Maybe$Nothing, ik: $author$project$Messages$PlayVideo, iE: $author$project$Messages$RestartVideo, fJ: activityKey, jI: videoPlayState});
		var nativeVideoComponentsList = function (urlString) {
			return (_Utils_eq(appFlowMode, $author$project$AppFlow$Sequential) && shouldShowAsCompleted) ? _List_fromArray(
				[
					A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('width', 'calc(80% - 100px)')
						]),
					$author$project$Component$Video$progressBar(videoPlayState)),
					controls,
					A2(
					$visotype$elm_dom$Dom$addClass,
					'video-player-completed-sequential',
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('width', 'calc(100% - 100px)'),
								_Utils_Tuple2('height', 'calc(100% - 160px)'),
								_Utils_Tuple2('margin', '50px 50px 0px 50px')
							]),
						$author$project$Component$Video$player(
							{gV: message, co: $author$project$Messages$NoOp, ih: $author$project$Messages$PauseVideo, b_: devFlags.jJ, fE: urlString, fJ: activityKey, da: $author$project$Messages$RightClickOnVideoBlocked})))
				]) : _List_fromArray(
				[
					$author$project$Component$Video$progressBar(videoPlayState),
					controls,
					A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('height', 'calc(100% - 41px)')
						]),
					$author$project$Component$Video$player(
						{gV: message, co: $author$project$Messages$NoOp, ih: $author$project$Messages$PauseVideo, b_: devFlags.jJ, fE: urlString, fJ: activityKey, da: $author$project$Messages$RightClickOnVideoBlocked}))
				]);
		};
		var nativeVideoPlayer = function () {
			if (hostedVideoData.$ === 1) {
				var s3Url = hostedVideoData.a;
				var cdnUrl = hostedVideoData.b;
				return nativeVideoComponentsList(
					A2($author$project$Models$ProductFeature$isEnabled, 9, productFeatures) ? $author$project$Models$Urls$toString(cdnUrl) : $author$project$Models$Urls$toString(s3Url));
			} else {
				switch (hostedVideoData.a) {
					case 0:
						var _v3 = hostedVideoData.a;
						return _List_fromArray(
							[loaderImage]);
					case 1:
						var _v4 = hostedVideoData.a;
						return _List_fromArray(
							[loaderImage]);
					default:
						var _v5 = hostedVideoData.a;
						return _List_fromArray(
							[
								A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('bottom', '110px'),
								A2(
									$author$project$Views$Error$render,
									$author$project$Messages$PlayTTSSpeechFromNode,
									A2(
										$author$project$Views$Error$customOopsMessage,
										'Oops, this is taking a while,',
										A2(
											$author$project$Views$Error$enableAudio,
											viewData.gj,
											$author$project$Views$Error$configView('Let\'s move on!')))))
							]);
				}
			}
		}();
		var youtubeVideoPlayer = A2(
			$visotype$elm_dom$Dom$addClass,
			'video-player',
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A2(
					$visotype$elm_dom$Dom$setId,
					'youtube-player',
					A2(
						$visotype$elm_dom$Dom$addAttribute,
						A2($elm$html$Html$Attributes$attribute, 'allow', 'autoplay'),
						A2(
							$visotype$elm_dom$Dom$addAttribute,
							A2($elm$html$Html$Attributes$attribute, 'data-video-key', activityKey),
							A2(
								$visotype$elm_dom$Dom$addAttribute,
								$elm$html$Html$Attributes$src(
									$author$project$Models$YoutubeVideoPlayer$youtubeVideoUrl(
										{id: clientBaseUrl, jJ: devFlags.jJ, j5: youtubeData})),
								$visotype$elm_dom$Dom$element('iframe'))))),
				$visotype$elm_dom$Dom$element('div')));
		var videoPlayerContent = function () {
			var _v0 = _Utils_Tuple2(
				studentActivity.ir,
				$author$project$Models$Activity$hostedVideoFailedToLoad(studentActivity.f_));
			if ((_v0.a.$ === 4) && (!_v0.b)) {
				var _v1 = _v0.a;
				return _List_fromArray(
					[
						A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('margin-bottom', '20px'),
						A2(
							$author$project$Component$Text$withSize,
							2,
							A2(
								$visotype$elm_dom$Dom$appendChildList,
								_List_fromArray(
									[
										A2(withTextToSpeech, 'span', 'Unavailable'),
										A2(
										$visotype$elm_dom$Dom$appendText,
										' ✔︎',
										$visotype$elm_dom$Dom$element('span'))
									]),
								$visotype$elm_dom$Dom$element('div')))),
						A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('margin-bottom', '20px'),
						$author$project$Views$Components$Button$videoWatchingPlayUnavailable($author$project$Messages$NoOp)),
						A2(
						$author$project$Component$Text$withSize,
						4,
						A2(withTextToSpeech, 'div', 'Don\'t worry - we\'ll find something new for you to learn!'))
					]);
			} else {
				return (!$author$project$Models$YoutubeVideoPlayer$isYoutubeStatusKnown(youtubeStatus)) ? _List_fromArray(
					[loaderImage]) : (playVideoWithYoutube ? _Utils_ap(
					_List_fromArray(
						[
							youtubeVideoPlayer,
							$author$project$Component$Video$progressBar(videoPlayState)
						]),
					_Utils_ap(youtubeReplayButton, youtubeDistractorBlocker)) : nativeVideoPlayer);
			}
		}();
		var videoPlayer = A2(
			$visotype$elm_dom$Dom$appendChildList,
			videoPlayerContent,
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('display', 'flex'),
						_Utils_Tuple2('flex-direction', 'column'),
						_Utils_Tuple2('width', '100%'),
						_Utils_Tuple2('justify-content', 'center'),
						_Utils_Tuple2('align-items', 'center'),
						_Utils_Tuple2('color', 'white')
					]),
				$visotype$elm_dom$Dom$element('div')));
		return A3(
			$visotype$elm_dom$Dom$addClassConditional,
			'video-playing',
			$author$project$Models$VideoPlayer$isPlaying(videoPlayState),
			A2(
				$visotype$elm_dom$Dom$setChildListWithKeys,
				_List_fromArray(
					[
						_Utils_Tuple2(activityKey, videoPlayer)
					]),
				$visotype$elm_dom$Dom$element('div')));
	});
var $author$project$Component$Buttons$Button$GreyLight = 15;
var $author$project$LoggedIn$Messages$ReplayStudentActivityOnExternalActivityLaunch = F2(
	function (a, b) {
		return {$: 26, a: a, b: b};
	});
var $author$project$Component$Icon$Rocket = 41;
var $author$project$LoggedIn$Messages$StartRemedialStudentActivityOnExternalActivityLaunch = function (a) {
	return {$: 25, a: a};
};
var $author$project$LoggedIn$Messages$StartStudentActivityOnExternalActivityLaunch = function (a) {
	return {$: 24, a: a};
};
var $author$project$LoggedIn$Messages$StudentClickedErroredWebActivity = {$: 28};
var $author$project$Component$Icon$XX_Large = {$: 5};
var $visotype$elm_dom$Dom$addStyleListConditional = F2(
	function (lkv, test) {
		if (test) {
			return $visotype$elm_dom$Dom$addStyleList(lkv);
		} else {
			return function (x) {
				return x;
			};
		}
	});
var $elm$html$Html$Attributes$autoplay = $elm$html$Html$Attributes$boolProperty('autoplay');
var $author$project$Component$ResponsiveGrid$column = $visotype$elm_dom$Dom$addClass('column');
var $author$project$Models$StudentActivity$contentCompletedAt = function (_v0) {
	var progress = _v0.ir;
	_v1$2:
	while (true) {
		switch (progress.$) {
			case 2:
				var timestamp = progress.a;
				return timestamp;
			case 1:
				if ((!progress.a.$) && (progress.a.a.$ === 2)) {
					var _v2 = progress.a;
					var timestamp = _v2.a.a;
					return $elm$core$Maybe$Just(timestamp);
				} else {
					break _v1$2;
				}
			default:
				break _v1$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$Models$StudentActivity$hasChoiceTextView = function (studentActivity) {
	var _v0 = studentActivity.fL;
	switch (_v0.$) {
		case 1:
			return false;
		case 2:
			return false;
		case 3:
			return false;
		case 4:
			return true;
		default:
			return false;
	}
};
var $author$project$Helpers$WebAppsHelperNoMsg$iframeHeight = F2(
	function (studentActivity, platform) {
		var _v0 = _Utils_Tuple2(
			A2($author$project$Models$StudentActivity$iframeActivityFooterVisibility, studentActivity.f_, studentActivity.fL),
			platform);
		if (_v0.a === 1) {
			if (_v0.b.$ === 1) {
				if (!_v0.b.b) {
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					var _v3 = _v2.b;
					return 'calc(100vh - 10px)';
				} else {
					var _v4 = _v0.a;
					var _v5 = _v0.b;
					var _v6 = _v5.b;
					return 'calc(100vh - 10px)';
				}
			} else {
				var _v7 = _v0.a;
				return '100vh';
			}
		} else {
			if (_v0.b.$ === 1) {
				if (!_v0.b.b) {
					var _v8 = _v0.a;
					var _v9 = _v0.b;
					var _v10 = _v9.b;
					return 'calc(100vh - 155px)';
				} else {
					var _v11 = _v0.a;
					var _v12 = _v0.b;
					var _v13 = _v12.b;
					return 'calc(100vh - 180px)';
				}
			} else {
				var _v14 = _v0.a;
				return $author$project$Models$StudentActivity$hasChoiceTextView(studentActivity) ? '100%' : 'calc(100vh - 110px)';
			}
		}
	});
var $author$project$Models$StudentActivity$isIframeActivity = function (studentActivity) {
	var _v0 = studentActivity.fL;
	switch (_v0.$) {
		case 1:
			return true;
		case 2:
			return false;
		case 3:
			return true;
		case 4:
			return true;
		default:
			return false;
	}
};
var $author$project$Views$Components$Button$WithGlobalMsg$launchExternalWebApp = F5(
	function (size, color, icon, iconSize, message) {
		return A2(
			$visotype$elm_dom$Dom$addAction,
			_Utils_Tuple2('touchstart', message),
			A2(
				$visotype$elm_dom$Dom$addAction,
				_Utils_Tuple2('mousedown', message),
				A2(
					$author$project$Component$Buttons$Action$button,
					0,
					_Utils_Tuple3(
						size,
						color,
						A2($author$project$Component$Icon$Icon, icon, iconSize)))));
	});
var $author$project$Views$ActivityPracticeView$WebAppPractice$WebInstructions$activityImage = function (studentActivity) {
	return A2(
		$author$project$Dom$Extra$addDataCy,
		'web-app-instructions-image-area',
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('width', '100%'),
					_Utils_Tuple2('height', '300px'),
					_Utils_Tuple2('margin', '24px auto 60px auto'),
					_Utils_Tuple2('border-radius', '5px'),
					_Utils_Tuple2('background-size', 'contain'),
					_Utils_Tuple2('background-repeat', 'no-repeat'),
					_Utils_Tuple2('background-position', 'center center'),
					_Utils_Tuple2(
					'background-image',
					$author$project$Styles$url(
						$author$project$Models$Urls$toString(studentActivity.f_.e5)))
				]),
			A3(
				$visotype$elm_dom$Dom$addStyleListConditional,
				_List_fromArray(
					[
						_Utils_Tuple2('opacity', '.5'),
						_Utils_Tuple2('mix-blend-mode', 'luminosity')
					]),
				!$author$project$Models$StudentActivity$hasBeenRatedOnce(studentActivity),
				A2(
					$visotype$elm_dom$Dom$addClass,
					'web-app-instructions-image-area',
					$visotype$elm_dom$Dom$element('div')))));
};
var $elm_community$string_extra$String$Extra$changeCase = F2(
	function (mutator, word) {
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					var head = _v0.a;
					var tail = _v0.b;
					return A2(
						$elm$core$String$cons,
						mutator(head),
						tail);
				},
				$elm$core$String$uncons(word)));
	});
var $elm$core$Char$toLower = _Char_toLower;
var $elm_community$string_extra$String$Extra$decapitalize = function (word) {
	return A2($elm_community$string_extra$String$Extra$changeCase, $elm$core$Char$toLower, word);
};
var $author$project$Component$Text$inPixels = function (px) {
	return $visotype$elm_dom$Dom$addStyle(
		_Utils_Tuple2(
			'font-size',
			$elm$core$String$fromInt(px) + 'px'));
};
var $author$project$Component$Text$lineHeight = function (height) {
	return $visotype$elm_dom$Dom$addStyle(
		_Utils_Tuple2('line-height', height));
};
var $author$project$Views$ActivityPracticeView$WebAppPractice$WebInstructions$render = F4(
	function (playTTSSpeechFromNodeMsg, studentActivity, voice, viewData) {
		var activity = studentActivity.f_;
		var shouldHaveAudioInstructions = viewData.gj || viewData.gk;
		var withTextToSpeech = function (text) {
			return A2(
				$visotype$elm_dom$Dom$appendChild,
				A5(
					$author$project$TextToSpeech$speakable,
					playTTSSpeechFromNodeMsg,
					_Utils_update(
						viewData,
						{gj: shouldHaveAudioInstructions}),
					!$author$project$Models$StudentActivity$isCompleted(studentActivity),
					text,
					voice),
				$visotype$elm_dom$Dom$element('div'));
		};
		var _v0 = activity;
		var instructions = _v0.ea;
		var title = _v0.fx;
		var instructionsWithPrefix = function () {
			if (!instructions.$) {
				return 'Click the yellow rocket to launch the activity.';
			} else {
				var instructionsString = instructions.a;
				return 'Click the yellow rocket to launch the activity, then ' + $elm_community$string_extra$String$Extra$decapitalize(instructionsString);
			}
		}();
		var activityInstructions = function () {
			var toNodes = function (text) {
				return A2(
					$visotype$elm_dom$Dom$addStyle,
					_Utils_Tuple2('margin-top', '10px'),
					withTextToSpeech(text));
			};
			return A2(
				$author$project$Dom$Extra$addDataCy,
				'web-app-instructions-for-activity',
				$author$project$Component$Text$light(
					A2(
						$author$project$Component$Text$lineHeight,
						'1.3',
						A2(
							$author$project$Component$Text$inPixels,
							28,
							A2(
								$visotype$elm_dom$Dom$appendChildList,
								A2(
									$elm$core$List$map,
									toNodes,
									A2(
										$elm$core$List$concatMap,
										$elm$core$String$split('<br/>'),
										A2($elm$core$String$split, '<br>', instructionsWithPrefix))),
								A2(
									$visotype$elm_dom$Dom$addStyleList,
									_List_fromArray(
										[
											_Utils_Tuple2('max-height', '120px'),
											_Utils_Tuple2('overflow-y', 'scroll'),
											_Utils_Tuple2('padding-right', '10px')
										]),
									A2(
										$visotype$elm_dom$Dom$addClass,
										'web-app-instructions',
										$visotype$elm_dom$Dom$element('div'))))))));
		}();
		var activityTitle = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('margin-bottom', '10px'),
					_Utils_Tuple2('text-transform', 'uppercase')
				]),
			A2(
				$author$project$Dom$Extra$addDataCy,
				'web-app-instructions-title',
				$author$project$Component$Text$light(
					A2(
						$author$project$Component$Text$lineHeight,
						'1.3',
						A2(
							$author$project$Component$Text$inPixels,
							18,
							withTextToSpeech(title))))));
		var content = A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					activityTitle,
					activityInstructions,
					$author$project$Views$ActivityPracticeView$WebAppPractice$WebInstructions$activityImage(studentActivity)
				]),
			$visotype$elm_dom$Dom$element('div'));
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'web-app-instructions',
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('height', '100%'),
						_Utils_Tuple2('margin', '0 auto')
					]),
				A2(
					$visotype$elm_dom$Dom$appendChild,
					content,
					$visotype$elm_dom$Dom$element('div'))));
	});
var $author$project$Component$Layout$section = function (elements) {
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		elements,
		A2(
			$visotype$elm_dom$Dom$addClass,
			'section',
			$visotype$elm_dom$Dom$element('section')));
};
var $author$project$Component$ResponsiveGrid$singleColumn = function (element) {
	return A2(
		$visotype$elm_dom$Dom$appendChild,
		element,
		A2(
			$visotype$elm_dom$Dom$addClass,
			'columns',
			$visotype$elm_dom$Dom$element('div')));
};
var $author$project$Views$ActivityPracticeView$WebAppPractice$render = F4(
	function (studentActivity, activityUrl, voice, viewData) {
		var activity = studentActivity.f_;
		var withTextToSpeech = F2(
			function (tagName, text) {
				return A2(
					$visotype$elm_dom$Dom$appendChild,
					A4(
						$author$project$TextToSpeech$WithGlobalMsg$speakable,
						viewData,
						!$author$project$Models$StudentActivity$isCompleted(studentActivity),
						text,
						voice),
					$visotype$elm_dom$Dom$element(tagName));
			});
		var openInBrowserText = A2(
			$author$project$Dom$Extra$addDataCy,
			'web-app-open-app-text',
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('padding-top', '20px'),
				A2(
					$visotype$elm_dom$Dom$addClass,
					'has-text-centered',
					$author$project$Component$Text$light(
						A2(
							$author$project$Component$Text$withSize,
							3,
							A2(
								$visotype$elm_dom$Dom$appendText,
								'Open in browser',
								$visotype$elm_dom$Dom$element('div')))))));
		var displayAsCompletedActivity = $author$project$Models$StudentActivity$hasBeenRatedOnce(studentActivity);
		var openInBrowserButton = function () {
			var launchExternalWebAppButton = $author$project$Models$StudentActivity$isErrored(studentActivity) ? A5(
				$author$project$Views$Components$Button$WithGlobalMsg$launchExternalWebApp,
				1,
				15,
				41,
				$author$project$Component$Icon$XX_Large,
				$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$StudentClickedErroredWebActivity)) : ($author$project$Models$StudentActivity$isContentComplete(studentActivity) ? A5(
				$author$project$Views$Components$Button$WithGlobalMsg$launchExternalWebApp,
				0,
				0,
				14,
				$author$project$Component$Icon$XLarge,
				$author$project$Messages$LoggedInRequiredMsg(
					A2(
						$author$project$LoggedIn$Messages$ReplayStudentActivityOnExternalActivityLaunch,
						studentActivity.hj,
						$author$project$Models$StudentActivity$contentCompletedAt(studentActivity)))) : (studentActivity.hs ? A5(
				$author$project$Views$Components$Button$WithGlobalMsg$launchExternalWebApp,
				0,
				0,
				14,
				$author$project$Component$Icon$XLarge,
				$author$project$Messages$LoggedInRequiredMsg(
					$author$project$LoggedIn$Messages$StartRemedialStudentActivityOnExternalActivityLaunch(studentActivity.hj))) : A5(
				$author$project$Views$Components$Button$WithGlobalMsg$launchExternalWebApp,
				1,
				4,
				41,
				$author$project$Component$Icon$XX_Large,
				$author$project$Messages$LoggedInRequiredMsg(
					$author$project$LoggedIn$Messages$StartStudentActivityOnExternalActivityLaunch(studentActivity.hj)))));
			var applyButtonAnimation = ($author$project$Models$StudentActivity$isErrored(studentActivity) || displayAsCompletedActivity) ? $elm$core$Basics$identity : $author$project$Animations$CssAnimations$applyWithAnimationPreset(5);
			return $author$project$Component$Text$centered(
				$author$project$Styles$centerContent(
					A2(
						$author$project$Dom$Extra$addDataCy,
						'web-app-open-app-button',
						applyButtonAnimation(
							A3(
								$visotype$elm_dom$Dom$addStyleListConditional,
								_List_fromArray(
									[
										_Utils_Tuple2('pointer-events', 'none'),
										_Utils_Tuple2('box-shadow', 'none')
									]),
								$author$project$Models$StudentActivity$isErrored(studentActivity),
								launchExternalWebAppButton)))));
		}();
		var applyOpenInBrowserButtonStyle = displayAsCompletedActivity ? $visotype$elm_dom$Dom$addStyleList(
			_List_fromArray(
				[
					_Utils_Tuple2('margin', '-250px auto auto auto'),
					_Utils_Tuple2('display', 'block')
				])) : $visotype$elm_dom$Dom$addStyleList(
			_List_fromArray(
				[
					_Utils_Tuple2('margin', '-280px auto auto auto'),
					_Utils_Tuple2('display', 'block')
				]));
		var _v0 = activity;
		var instructions = _v0.ea;
		var title = _v0.fx;
		var showInstructions = (!_Utils_eq(instructions, $author$project$Models$Activity$NoInstructions)) && (!$author$project$Models$StudentActivity$isErrored(studentActivity));
		if (showInstructions) {
			var instructionContent = $author$project$Models$StudentActivity$isIframeActivity(studentActivity) ? A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('width', '100vw'),
						_Utils_Tuple2('height', '100vh')
					]),
				A2(
					$visotype$elm_dom$Dom$appendChild,
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('width', '100%'),
								_Utils_Tuple2(
								'height',
								A2($author$project$Helpers$WebAppsHelperNoMsg$iframeHeight, studentActivity, viewData.ii))
							]),
						A2(
							$visotype$elm_dom$Dom$setId,
							'webApp-iframe',
							A2(
								$visotype$elm_dom$Dom$addAttribute,
								A2($elm$html$Html$Attributes$attribute, 'allow', 'autoplay'),
								A2(
									$visotype$elm_dom$Dom$addAttribute,
									$elm$html$Html$Attributes$autoplay(true),
									A2(
										$visotype$elm_dom$Dom$addAttribute,
										$elm$html$Html$Attributes$src(
											$author$project$Models$Urls$toString(activityUrl)),
										$visotype$elm_dom$Dom$element('iframe')))))),
					$visotype$elm_dom$Dom$element('div'))) : A2(
				$visotype$elm_dom$Dom$addClass,
				'is-centered',
				A2(
					$visotype$elm_dom$Dom$addStyle,
					_Utils_Tuple2('padding', '60px 30px 0 30px'),
					$author$project$Component$ResponsiveGrid$singleColumn(
						A2(
							$visotype$elm_dom$Dom$appendChild,
							applyOpenInBrowserButtonStyle(openInBrowserButton),
							A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('max-width', '600px'),
								A2(
									$visotype$elm_dom$Dom$addClass,
									'is-three-quarters',
									$author$project$Component$ResponsiveGrid$column(
										A4($author$project$Views$ActivityPracticeView$WebAppPractice$WebInstructions$render, $author$project$Messages$PlayTTSSpeechFromNode, studentActivity, voice, viewData))))))));
			return instructionContent;
		} else {
			var titleNodes = $author$project$Models$StudentActivity$isErrored(studentActivity) ? _List_fromArray(
				[
					A2(withTextToSpeech, 'span', 'Unavailable'),
					A2(
					$visotype$elm_dom$Dom$appendText,
					' ✔︎',
					$visotype$elm_dom$Dom$element('span'))
				]) : _List_fromArray(
				[
					A2(withTextToSpeech, 'div', title)
				]);
			var textBelowButton = $author$project$Models$StudentActivity$isErrored(studentActivity) ? A2(
				$author$project$Dom$Extra$addDataCy,
				'web-app-error-text',
				A2(
					$visotype$elm_dom$Dom$addStyle,
					_Utils_Tuple2('padding-top', '20px'),
					A2(
						$visotype$elm_dom$Dom$addClass,
						'has-text-centered',
						$author$project$Component$Text$light(
							A2(
								$author$project$Component$Text$withSize,
								4,
								A2(
									$visotype$elm_dom$Dom$appendChildList,
									_List_fromArray(
										[
											A2(
											$visotype$elm_dom$Dom$appendText,
											activity.fx,
											$visotype$elm_dom$Dom$element('div')),
											A2(withTextToSpeech, 'div', 'Don\'t worry - we\'ll find something new for you to learn!')
										]),
									$visotype$elm_dom$Dom$element('div'))))))) : openInBrowserText;
			var activityTitle = A2(
				$author$project$Dom$Extra$addDataCy,
				'web-app-instructions-title',
				A2(
					$visotype$elm_dom$Dom$addStyle,
					_Utils_Tuple2('padding-bottom', '25px'),
					A2(
						$visotype$elm_dom$Dom$addClass,
						'has-text-centered',
						$author$project$Component$Text$light(
							A2(
								$author$project$Component$Text$withSize,
								2,
								A2(
									$visotype$elm_dom$Dom$appendChildList,
									titleNodes,
									$visotype$elm_dom$Dom$element('div')))))));
			return A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('padding-top', 'calc(40vh - 110px)'),
				$author$project$Component$Layout$section(
					_List_fromArray(
						[
							activityTitle,
							A2(
							$visotype$elm_dom$Dom$addStyle,
							_Utils_Tuple2('margin', '0 auto'),
							openInBrowserButton),
							textBelowButton
						])));
		}
	});
var $author$project$Activities$Ebook$NextPage = {$: 3};
var $author$project$Activities$Ebook$PlayTTS = function (a) {
	return {$: 8, a: a};
};
var $author$project$Activities$Ebook$PreviousPage = {$: 4};
var $author$project$Activities$Ebook$ToggleAudio = {$: 2};
var $elm$html$Html$audio = _VirtualDom_node('audio');
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $author$project$Views$Components$Spinner$foldingCubeHtml = $visotype$elm_dom$Dom$render($author$project$Views$Components$Spinner$foldingCube);
var $author$project$Activities$Ebook$Model$isFirst = function (currentPage) {
	switch (currentPage.$) {
		case 0:
			return true;
		case 1:
			return false;
		default:
			return false;
	}
};
var $author$project$Activities$Ebook$Model$isLast = function (currentPage) {
	switch (currentPage.$) {
		case 0:
			return false;
		case 1:
			return false;
		default:
			return true;
	}
};
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Activities$Ebook$Model$page = function (model) {
	switch (model.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 2:
			var data = model.a;
			return $elm$core$Maybe$Just(data.ai);
		default:
			var data = model.a;
			return $elm$core$Maybe$Just(data.ai);
	}
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Views$Error$view = F2(
	function (playTTSMsg, config) {
		return $visotype$elm_dom$Dom$render(
			A2($author$project$Views$Error$render, playTTSMsg, config));
	});
var $author$project$Activities$Ebook$View$view = F4(
	function (model, studentActivity, _v0, _v1) {
		var activity = studentActivity.f_;
		var toggleAudio = $author$project$Activities$Ebook$ToggleAudio;
		var previousPage = $author$project$Activities$Ebook$PreviousPage;
		var nextPage = $author$project$Activities$Ebook$NextPage;
		var loaderImage = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ereader-loading-cube')
				]),
			_List_fromArray(
				[$author$project$Views$Components$Spinner$foldingCubeHtml]));
		var errorScreen = A2(
			$author$project$Views$Error$view,
			$author$project$Activities$Ebook$PlayTTS,
			A2(
				$author$project$Views$Error$customOopsMessage,
				'Oops, something went wrong.',
				A2(
					$author$project$Views$Error$enableAudio,
					true,
					$author$project$Views$Error$configView('Let\'s move on!'))));
		return $author$project$Models$StudentActivity$isErrored(studentActivity) ? errorScreen : A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ereader-activity')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('ereader-viewer'),
							$elm$html$Html$Attributes$class('ereader-view-container')
						]),
					_List_fromArray(
						[
							function () {
							switch (model.$) {
								case 0:
									return loaderImage;
								case 1:
									return loaderImage;
								default:
									return A2($elm$html$Html$div, _List_Nil, _List_Nil);
							}
						}()
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('ereader-audio-player'),
							A2($elm$html$Html$Attributes$style, 'height', '0')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('ereader-audio-player-button'),
									$elm$html$Html$Attributes$class('es-button es-action-button es-button--yellow has-text-light es-icon es-icon-loading ereader-button'),
									$elm$html$Html$Events$onClick(toggleAudio)
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('audio-container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$audio,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$id('ereader-audio')
										]),
									_List_Nil)
								]))
						])),
					function () {
					switch (model.$) {
						case 0:
							return A2($elm$html$Html$div, _List_Nil, _List_Nil);
						case 1:
							return A2($elm$html$Html$div, _List_Nil, _List_Nil);
						default:
							return A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A3(
										$elm_community$maybe_extra$Maybe$Extra$unwrap,
										false,
										A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Activities$Ebook$Model$isFirst),
										$author$project$Activities$Ebook$Model$page(model)) ? A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$id('ebook-nav-prev'),
												$elm$html$Html$Attributes$class('es-button es-action-button has-text-light es-icon es-icon-arrow-left ereader-button'),
												$elm$html$Html$Events$onClick(previousPage)
											]),
										_List_Nil) : A2($elm$html$Html$span, _List_Nil, _List_Nil),
										A3(
										$elm_community$maybe_extra$Maybe$Extra$unwrap,
										false,
										A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Activities$Ebook$Model$isLast),
										$author$project$Activities$Ebook$Model$page(model)) ? A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$id('ebook-nav-next'),
												$elm$html$Html$Attributes$class('es-button es-action-button has-text-light es-icon es-icon-arrow-right ereader-button'),
												$elm$html$Html$Events$onClick(nextPage)
											]),
										_List_Nil) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
									]));
					}
				}()
				]));
	});
var $author$project$Activities$ChoiceText$PlayTTS = function (a) {
	return {$: 5, a: a};
};
var $author$project$Activities$ChoiceText$Model$activityPath = F3(
	function (studentActivityId, subjectArea, activityType) {
		return '?activityID=' + ($author$project$Models$Ids$toString(studentActivityId) + ('&subjectArea=' + ($author$project$Models$SubjectArea$toString(subjectArea) + ('&activityType=' + $author$project$Models$Activity$typeToString(activityType)))));
	});
var $author$project$Activities$ChoiceText$View$viewActivity = F4(
	function (model, studentActivity, _v0, subjectArea) {
		var platform = _v0.ii;
		var choiceTextBaseUrl = _v0.gD;
		var activityType = studentActivity.f_.f0;
		if ($author$project$Models$StudentActivity$isErrored(studentActivity)) {
			return A2(
				$author$project$Views$Error$view,
				$author$project$Activities$ChoiceText$PlayTTS,
				A2(
					$author$project$Views$Error$customOopsMessage,
					'Oops, something went wrong.',
					A2(
						$author$project$Views$Error$enableAudio,
						true,
						$author$project$Views$Error$configView('Let\'s move on!'))));
		} else {
			var _v1 = $author$project$Activities$ChoiceText$Model$choiceTextMode(model);
			if (!_v1.$) {
				var studentActivityId = _v1.a;
				return A2(
					$elm$html$Html$iframe,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							_Utils_ap(
								choiceTextBaseUrl,
								A3($author$project$Activities$ChoiceText$Model$activityPath, studentActivityId, subjectArea, activityType))),
							$elm$html$Html$Attributes$id(
							$author$project$Activities$ChoiceText$iframeId(model)),
							$elm$html$Html$Attributes$autoplay(true),
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2(
							$elm$html$Html$Attributes$style,
							'height',
							A2($author$project$Helpers$WebAppsHelperNoMsg$iframeHeight, studentActivity, platform)),
							A2($elm$html$Html$Attributes$attribute, 'allow', 'microphone')
						]),
					_List_Nil);
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}
	});
var $author$project$Views$ActivityPracticeView$voice = A2(
	$elm$core$Basics$composeR,
	$author$project$Models$Activity$sequence,
	A2($elm$core$Basics$composeR, $author$project$Component$Monster$indexToName, $author$project$Component$Monster$voice));
var $author$project$Views$ActivityPracticeView$activityContent = F3(
	function (studentActivity, viewData, subjectArea) {
		var activity = studentActivity.f_;
		var choiceActivityView = function () {
			var _v2 = studentActivity.fL;
			if (_v2.$ === 4) {
				var choiceTextModel = _v2.a;
				return A2(
					$visotype$elm_dom$Dom$appendNode,
					A2(
						$elm$html$Html$map,
						A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$ChoiceTextActivityMsg, $author$project$Messages$LoggedInRequiredMsg),
						A4($author$project$Activities$ChoiceText$View$viewActivity, choiceTextModel, studentActivity, viewData, subjectArea)),
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('width', '100%'),
								_Utils_Tuple2('height', '100%')
							]),
						$visotype$elm_dom$Dom$element('span')));
			} else {
				return A2(
					$visotype$elm_dom$Dom$appendText,
					'Oops! Something went wrong loading this activity. Please try again or contact eSpark support.',
					$visotype$elm_dom$Dom$element('span'));
			}
		}();
		var content = function () {
			var _v0 = activity.f0;
			switch (_v0.$) {
				case 7:
					return $visotype$elm_dom$Dom$element('span');
				case 8:
					return $visotype$elm_dom$Dom$element('span');
				case 6:
					return $visotype$elm_dom$Dom$element('span');
				case 0:
					var hostedVideoUrl = _v0.a;
					var youtubeData = _v0.b;
					return A5(
						$author$project$Views$ActivityPracticeView$VideoActivityPlayer$render,
						studentActivity,
						hostedVideoUrl,
						youtubeData,
						$author$project$Views$ActivityPracticeView$voice(activity),
						viewData);
				case 1:
					var activityUrl = _v0.a;
					return A4(
						$author$project$Views$ActivityPracticeView$WebAppPractice$render,
						studentActivity,
						activityUrl,
						$author$project$Views$ActivityPracticeView$voice(activity),
						viewData);
				case 3:
					return choiceActivityView;
				case 4:
					return choiceActivityView;
				case 5:
					return choiceActivityView;
				default:
					var ebookUrl = _v0.a;
					var _v1 = studentActivity.fL;
					if (_v1.$ === 3) {
						var ebookModel = _v1.a;
						return A2(
							$visotype$elm_dom$Dom$appendNode,
							A2(
								$elm$html$Html$map,
								A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$EbookActivityMsg, $author$project$Messages$LoggedInRequiredMsg),
								A4($author$project$Activities$Ebook$View$view, ebookModel, studentActivity, ebookUrl, viewData)),
							A2(
								$visotype$elm_dom$Dom$addStyleList,
								_List_fromArray(
									[
										_Utils_Tuple2('width', '100%'),
										_Utils_Tuple2('height', '100%')
									]),
								$visotype$elm_dom$Dom$element('span')));
					} else {
						return A2(
							$visotype$elm_dom$Dom$appendText,
							'Oops! Something went wrong loading this ebook. Please try again or contact eSpark support.',
							$visotype$elm_dom$Dom$element('span'));
					}
			}
		}();
		var wrappedContent = ($author$project$Models$Activity$isPrePostQuiz(activity) || $author$project$Models$Activity$isWebApp(activity)) ? A2(
			$visotype$elm_dom$Dom$addClass,
			'content',
			A2(
				$visotype$elm_dom$Dom$appendChild,
				content,
				$visotype$elm_dom$Dom$element('div'))) : $author$project$Component$Layout$level(
			{
				gz: _List_fromArray(
					[content]),
				hx: _List_Nil,
				iJ: _List_Nil
			});
		var applyHeight = $author$project$Models$Activity$isChoiceText(activity) ? $visotype$elm_dom$Dom$addStyleList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'height',
					'calc(100vh - ' + ($author$project$Views$ActivityPracticeView$footerHeight(studentActivity) + ')'))
				])) : ($author$project$Models$Activity$isExternalContent(activity) ? $visotype$elm_dom$Dom$addStyleList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'height',
					'calc(100vh - ' + $author$project$Views$ActivityPracticeView$footerHeight(studentActivity)),
					_Utils_Tuple2('position', 'relative')
				])) : $visotype$elm_dom$Dom$addStyle(
			_Utils_Tuple2('height', 'calc(100vh - 110px)')));
		return A2(
			$author$project$Styles$conditional,
			_Utils_Tuple2(
				$author$project$Styles$filled($author$project$ES$UI$Color$purple.at),
				$author$project$Models$StudentActivity$hasBeenRatedOnce(studentActivity)),
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('width', '100%'),
						_Utils_Tuple2('overflow-y', 'auto'),
						_Utils_Tuple2('overflow-x', 'hidden')
					]),
				A3(
					$visotype$elm_dom$Dom$addStyleConditional,
					_Utils_Tuple2('display', 'flex'),
					!$author$project$Models$Activity$isWebApp(studentActivity.f_),
					applyHeight(wrappedContent))));
	});
var $author$project$LoggedIn$Messages$SetIframeActivityFooterVisibility = F2(
	function (a, b) {
		return {$: 45, a: a, b: b};
	});
var $author$project$Models$StudentActivity$assessmentStatusIfPresent = function (activeActivityData) {
	if (!activeActivityData.$) {
		if (activeActivityData.b.$ === 1) {
			var aAssessmentStatus = activeActivityData.b.a;
			return $elm$core$Maybe$Just(aAssessmentStatus);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		var aAssessmentStatus = activeActivityData.a;
		return $elm$core$Maybe$Just(aAssessmentStatus);
	}
};
var $author$project$Views$ActivityPracticeView$currentQuestionStatus = function (studentActivity) {
	var _v0 = studentActivity.ir;
	if (_v0.$ === 1) {
		var activeActivityData = _v0.a;
		var _v1 = $author$project$Models$StudentActivity$assessmentStatusIfPresent(activeActivityData);
		if ((!_v1.$) && (_v1.a.$ === 2)) {
			var _v2 = _v1.a;
			var quizQuestionStatus = _v2.a;
			return $elm$core$Maybe$Just(quizQuestionStatus);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Activity$instructionsToString = function (activityInstructions) {
	if (!activityInstructions.$) {
		return '';
	} else {
		var someInstructions = activityInstructions.a;
		return someInstructions;
	}
};
var $author$project$Messages$ActivityNextButtonClicked = F2(
	function (a, b) {
		return {$: 23, a: a, b: b};
	});
var $author$project$LoggedIn$Messages$CompleteStudentActivity = F3(
	function (a, b, c) {
		return {$: 39, a: a, b: b, c: c};
	});
var $author$project$LoggedIn$Messages$CompleteStudentQuest = F2(
	function (a, b) {
		return {$: 20, a: a, b: b};
	});
var $author$project$AppFlow$GoToNextQuest = 0;
var $author$project$LoggedIn$Messages$HostedVideoUrlUnableToLoad = function (a) {
	return {$: 22, a: a};
};
var $author$project$AppFlow$NextSequentialActivity = function (a) {
	return {$: 1, a: a};
};
var $author$project$Animations$CssAnimations$NoAnimation = 9;
var $author$project$Messages$OpenModal = function (a) {
	return {$: 39, a: a};
};
var $author$project$Models$StudentActivity$RatingNotApplicable = 3;
var $author$project$Models$Modal$RatingsModal = {$: 1};
var $author$project$LoggedIn$Messages$SkipActivity = function (a) {
	return {$: 38, a: a};
};
var $author$project$Models$StudentActivity$canCompleteContent = function (_v0) {
	var progress = _v0.ir;
	if (((progress.$ === 1) && (!progress.a.$)) && (progress.a.a.$ === 1)) {
		var _v2 = progress.a;
		var _v3 = _v2.a;
		return true;
	} else {
		return false;
	}
};
var $author$project$Models$StudentActivity$canCompleteRemedialActivity = function (studentActivity) {
	return studentActivity.hs && ($author$project$Models$StudentActivity$canCompleteContent(studentActivity) || ($author$project$Models$StudentActivity$hasCompletedContentForActiveActivity(studentActivity) || $author$project$Models$StudentActivity$isErrored(studentActivity)));
};
var $author$project$Models$StudentActivity$isAPendingRemedialActivity = function (studentActivity) {
	return studentActivity.hs && (!$author$project$Models$StudentActivity$canCompleteRemedialActivity(studentActivity));
};
var $author$project$Models$StudentActivity$isActive = function (_v0) {
	var progress = _v0.ir;
	if (progress.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Models$StudentActivity$displaySequence = function (studentActivity) {
	var _v0 = studentActivity.dP;
	var studentActivitySequence = _v0;
	return studentActivitySequence;
};
var $author$project$Models$ActiveQuest$isLastActivityBeforePostQuiz = F2(
	function (activeQuest, studentActivity) {
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			false,
			$elm$core$Basics$eq(
				$author$project$Models$StudentActivity$displaySequence(studentActivity)),
			$elm$core$List$maximum(
				A2(
					$elm$core$List$map,
					$author$project$Models$StudentActivity$displaySequence,
					$author$project$Models$ActiveQuest$availableActivities(activeQuest))));
	});
var $author$project$Models$Activity$youtubeData = function (_v0) {
	var activityType = _v0.f0;
	if (!activityType.$) {
		var data = activityType.b;
		return $elm$core$Maybe$Just(data);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Activity$isYoutubeVideo = function (activity) {
	return !_Utils_eq(
		$author$project$Models$Activity$youtubeData(activity),
		$elm$core$Maybe$Nothing);
};
var $author$project$Component$Icon$StopWatch = 42;
var $author$project$Views$Components$Button$WithGlobalMsg$webActivityTimerIndicator = A2(
	$visotype$elm_dom$Dom$addStyle,
	_Utils_Tuple2('box-shadow', 'none'),
	A2(
		$visotype$elm_dom$Dom$addClass,
		'has-pointer-events-disabled',
		A2(
			$author$project$Dom$Extra$addDataAttribute,
			_Utils_Tuple2('disabled', 'true'),
			A2(
				$author$project$Component$Buttons$Action$button,
				0,
				_Utils_Tuple3(
					0,
					15,
					A2($author$project$Component$Icon$Icon, 42, $author$project$Component$Icon$Large))))));
var $author$project$Views$ActivityPracticeView$nextButton = F3(
	function (studentActivity, activeQuest, viewData) {
		var hostedVideoFailedToLoad = $author$project$Models$Activity$hostedVideoFailedToLoad(studentActivity.f_);
		var message = function () {
			if (hostedVideoFailedToLoad) {
				return $author$project$Messages$LoggedInRequiredMsg(
					$author$project$LoggedIn$Messages$HostedVideoUrlUnableToLoad(studentActivity.hj));
			} else {
				if ($author$project$Models$StudentActivity$isErrored(studentActivity)) {
					return $author$project$Messages$LoggedInRequiredMsg(
						$author$project$LoggedIn$Messages$SkipActivity(studentActivity.hj));
				} else {
					if ($author$project$Models$StudentActivity$canCompleteRemedialActivity(studentActivity)) {
						return $author$project$Messages$LoggedInRequiredMsg(
							A3($author$project$LoggedIn$Messages$CompleteStudentActivity, studentActivity.hj, 3, false));
					} else {
						if ($author$project$Models$StudentActivity$canStartCheckForUnderstanding(studentActivity)) {
							return $author$project$Messages$NavigateTo(
								$author$project$Routes$activityAssessmentRoute(studentActivity));
						} else {
							if ($author$project$Models$StudentActivity$isActive(studentActivity)) {
								var _v5 = _Utils_Tuple2(
									$author$project$Models$Activity$isYoutubeVideo(studentActivity.f_),
									viewData.jI);
								if (_v5.a) {
									if (_v5.b.$ === 4) {
										return $author$project$Messages$OpenModal($author$project$Models$Modal$RatingsModal);
									} else {
										return $author$project$Messages$LoggedInRequiredMsg(
											$author$project$LoggedIn$Messages$VideoActivityPlaybackEnded(studentActivity.hj));
									}
								} else {
									return $author$project$Messages$OpenModal($author$project$Models$Modal$RatingsModal);
								}
							} else {
								if (_Utils_eq(viewData.f8, $author$project$AppFlow$Sequential)) {
									return (activeQuest.es && A2($author$project$Models$ActiveQuest$isLastActivityBeforePostQuiz, activeQuest, studentActivity)) ? $author$project$Messages$LoggedInRequiredMsg(
										A2($author$project$LoggedIn$Messages$CompleteStudentQuest, activeQuest.a_, 0)) : $author$project$Messages$NavigateToActivity(
										$author$project$AppFlow$NextSequentialActivity(studentActivity.hj));
								} else {
									return $author$project$Messages$NavigateTo(
										$author$project$Routes$QuestRoute(
											$author$project$Routes$QuestRoutes$Home(studentActivity.a_)));
								}
							}
						}
					}
				}
			}
		}();
		var nextButtonWithMessage = A2(
			$author$project$Styles$onTop,
			1002,
			A2(
				$visotype$elm_dom$Dom$addClass,
				'next-activity-button',
				A2(
					$author$project$Views$Components$Button$WithGlobalMsg$next,
					viewData.gv,
					A2($author$project$Messages$ActivityNextButtonClicked, studentActivity, message))));
		var nextButtonIfAlwaysShowNextButtonOnVideoActivityEnabled = (A2($author$project$Models$ProductFeature$isEnabled, 4, viewData.iq) && $author$project$Models$Activity$isYoutubeVideo(studentActivity.f_)) ? A2($author$project$Animations$CssAnimations$applyWithAnimationPreset, 9, nextButtonWithMessage) : $visotype$elm_dom$Dom$element('span');
		if (hostedVideoFailedToLoad) {
			return A2($author$project$Animations$CssAnimations$applyWithAnimationPreset, 5, nextButtonWithMessage);
		} else {
			if ($author$project$Models$StudentActivity$isAPendingRemedialActivity(studentActivity)) {
				return nextButtonIfAlwaysShowNextButtonOnVideoActivityEnabled;
			} else {
				var _v0 = studentActivity.ir;
				switch (_v0.$) {
					case 1:
						if (!_v0.a.$) {
							var _v1 = _v0.a;
							var progress = _v1.a;
							switch (progress.$) {
								case 0:
									return $author$project$Models$Activity$isWebApp(studentActivity.f_) ? $author$project$Views$Components$Button$WithGlobalMsg$webActivityTimerIndicator : nextButtonIfAlwaysShowNextButtonOnVideoActivityEnabled;
								case 1:
									var _v3 = studentActivity.fL;
									if ((_v3.$ === 1) && (!_v3.a)) {
										var _v4 = _v3.a;
										return nextButtonWithMessage;
									} else {
										return A2($author$project$Animations$CssAnimations$applyWithAnimationPreset, 5, nextButtonWithMessage);
									}
								default:
									return A2($author$project$Animations$CssAnimations$applyWithAnimationPreset, 5, nextButtonWithMessage);
							}
						} else {
							return $visotype$elm_dom$Dom$element('span');
						}
					case 3:
						return A2($author$project$Animations$CssAnimations$applyWithAnimationPreset, 5, nextButtonWithMessage);
					case 2:
						return A2($author$project$Animations$CssAnimations$applyWithAnimationPreset, 5, nextButtonWithMessage);
					case 4:
						return A2($author$project$Animations$CssAnimations$applyWithAnimationPreset, 5, nextButtonWithMessage);
					default:
						return nextButtonIfAlwaysShowNextButtonOnVideoActivityEnabled;
				}
			}
		}
	});
var $author$project$Views$Components$ActivityBackButton$contentRouteAction = F2(
	function (appFlowMode, studentActivity) {
		if (!appFlowMode.$) {
			return $author$project$Models$StudentActivity$isCompleted(studentActivity) ? $author$project$Messages$NavigateToActivity(
				$author$project$AppFlow$PreviousSequentialActivity(studentActivity.hj)) : $author$project$Messages$NavigateTo(
				$author$project$Routes$ActivityRoute(
					A2($author$project$Routes$ActivityRoutes$Intro, studentActivity.a_, studentActivity.hj)));
		} else {
			return $author$project$Messages$NavigateTo(
				$author$project$Routes$QuestRoute(
					$author$project$Routes$QuestRoutes$Home(studentActivity.a_)));
		}
	});
var $author$project$Views$Components$ActivityBackButton$render = F3(
	function (isRemedial, buttonTypeRenderer, routeAction) {
		return isRemedial ? $visotype$elm_dom$Dom$element('span') : A2(
			$visotype$elm_dom$Dom$addClass,
			'back-button',
			buttonTypeRenderer(routeAction));
	});
var $author$project$Views$Components$ActivityBackButton$renderForPracticeView = F2(
	function (appFlowMode, studentActivity) {
		var isRemedial = studentActivity.hs;
		return A3(
			$author$project$Views$Components$ActivityBackButton$render,
			isRemedial,
			$author$project$Views$Components$Button$WithGlobalMsg$previous,
			A2($author$project$Views$Components$ActivityBackButton$contentRouteAction, appFlowMode, studentActivity));
	});
var $author$project$Views$ActivityPracticeView$previousButton = F2(
	function (studentActivity, _v0) {
		var appFlowMode = _v0.f8;
		return A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('z-index', '1002'),
			A2($author$project$Views$Components$ActivityBackButton$renderForPracticeView, appFlowMode, studentActivity));
	});
var $elm_community$string_extra$String$Extra$surround = F2(
	function (wrapper, string) {
		return _Utils_ap(
			wrapper,
			_Utils_ap(string, wrapper));
	});
var $elm_community$string_extra$String$Extra$quote = function (string) {
	return A2($elm_community$string_extra$String$Extra$surround, '\"', string);
};
var $author$project$Views$ActivityPracticeView$removeLineBreaks = function (input) {
	return A2(
		$elm$core$String$join,
		' ',
		A2($elm$core$String$split, '<br/>', input));
};
var $author$project$Views$ActivityPracticeView$textForCurrentQuestion = function (quizQuestionStatus) {
	if (!quizQuestionStatus.$) {
		return 'Play this challenge with me!';
	} else {
		switch (quizQuestionStatus.a) {
			case 0:
				var _v1 = quizQuestionStatus.a;
				return 'Play this challenge with me!';
			case 2:
				var _v2 = quizQuestionStatus.a;
				return 'That\'s right!  Thanks for playing.';
			case 3:
				var _v3 = quizQuestionStatus.a;
				return 'Let’s keep practicing! Thanks for playing!';
			default:
				var _v4 = quizQuestionStatus.a;
				return 'Not yet!  Let\'s try again.';
		}
	}
};
var $author$project$Views$ActivityPracticeView$barAppFlowChoice = F3(
	function (studentActivity, activeQuest, viewData) {
		var activity = studentActivity.f_;
		var quest = activeQuest.it;
		var text = function () {
			var _v0 = activity.f0;
			switch (_v0.$) {
				case 0:
					return 'Watch ' + $elm_community$string_extra$String$Extra$quote(quest.i0);
				case 1:
					return $author$project$Models$StudentActivity$isIframeActivity(studentActivity) ? $author$project$Views$ActivityPracticeView$removeLineBreaks(
						$author$project$Models$Activity$instructionsToString(activity.ea)) : '';
				case 6:
					var _v1 = $author$project$Views$ActivityPracticeView$currentQuestionStatus(studentActivity);
					if (!_v1.$) {
						var quizQuestionStatus = _v1.a;
						return $author$project$Views$ActivityPracticeView$textForCurrentQuestion(quizQuestionStatus);
					} else {
						return '';
					}
				default:
					return '';
			}
		}();
		var bottomBarText = A3(
			$visotype$elm_dom$Dom$addStyleListConditional,
			_List_fromArray(
				[
					_Utils_Tuple2('white-space', 'nowrap'),
					_Utils_Tuple2('overflow', 'hidden'),
					_Utils_Tuple2('text-overflow', 'ellipsis'),
					_Utils_Tuple2('max-width', '80vw')
				]),
			$author$project$Models$StudentActivity$isIframeActivity(studentActivity),
			A4(
				$author$project$TextToSpeech$WithGlobalMsg$speakable,
				viewData,
				false,
				text,
				$author$project$Views$ActivityPracticeView$voice(activity)));
		var content = A2(
			$visotype$elm_dom$Dom$addClass,
			'has-text-monster-' + $elm$core$String$fromInt(
				$author$project$Models$Activity$sequence(activity)),
			A2(
				$author$project$Component$Text$withSize,
				4,
				A2(
					$visotype$elm_dom$Dom$appendChild,
					bottomBarText,
					$visotype$elm_dom$Dom$element('span'))));
		var backgroundColor = ($author$project$Models$Activity$isWebApp(activity) && (!$author$project$Models$StudentActivity$isIframeActivity(studentActivity))) ? $author$project$ES$UI$Color$transparent : $author$project$ES$UI$Color$white;
		return A2(
			$author$project$Styles$onTop,
			1,
			$author$project$Component$BottomBar$customBar(
				{
					gq: backgroundColor,
					gH: content,
					R: A3($author$project$Views$ActivityPracticeView$nextButton, studentActivity, activeQuest, viewData),
					io: A2($author$project$Views$ActivityPracticeView$previousButton, studentActivity, viewData)
				}));
	});
var $author$project$Component$Monster$Cropped = 1;
var $author$project$Component$Monster$LookingDirection = $elm$core$Basics$identity;
var $visotype$elm_dom$Dom$appendChildConditional = F2(
	function (e, test) {
		if (test) {
			return $visotype$elm_dom$Dom$appendChild(e);
		} else {
			return function (x) {
				return x;
			};
		}
	});
var $author$project$Component$Monster$Body = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Component$Monster$Eyes = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Component$Monster$defaultAssetData = {hb: '245.04px', cE: 3, jS: 'auto'};
var $author$project$Component$Monster$assetData = _List_fromArray(
	[
		$author$project$Component$Monster$defaultAssetData,
		{hb: '279.08px', cE: 1, jS: 'auto'},
		{hb: '274.68px', cE: 0, jS: 'auto'},
		{hb: '363.11px', cE: 2, jS: 'auto'},
		{hb: '238.2px', cE: 5, jS: 'auto'},
		{hb: '329.2px', cE: 4, jS: 'auto'}
	]);
var $author$project$Component$Monster$rockyAssetData = {hb: '247.3px', cE: 6, jS: 'auto'};
var $author$project$Component$Monster$build = function (_v0) {
	var name = _v0.cE;
	var lookingDirection = _v0.hD;
	var displaySize = _v0.gR;
	var orientation = _v0.ic;
	var _v1 = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Component$Monster$defaultAssetData,
		A2(
			$elm_community$list_extra$List$Extra$find,
			function (d) {
				return _Utils_eq(d.cE, name);
			},
			A2($elm$core$List$cons, $author$project$Component$Monster$rockyAssetData, $author$project$Component$Monster$assetData)));
	var width = _v1.jS;
	var height = _v1.hb;
	return {
		gs: A2($author$project$Component$Monster$Body, displaySize, orientation),
		cr: A2($author$project$Component$Monster$Eyes, name, lookingDirection),
		hb: height,
		cE: name,
		fP: $author$project$Component$Monster$voice(name),
		jS: width
	};
};
var $author$project$Views$ActivityPracticeView$monsterName = A2($elm$core$Basics$composeR, $author$project$Models$Activity$sequence, $author$project$Component$Monster$indexToName);
var $author$project$Component$Monster$nameToString = function (name) {
	switch (name) {
		case 0:
			return 'cat';
		case 1:
			return 'bunny';
		case 2:
			return 'many-eyes';
		case 3:
			return 'ant';
		case 4:
			return 'rainbow';
		case 5:
			return 'pineapple';
		case 6:
			return 'rocky';
		default:
			return 'rocky';
	}
};
var $author$project$Component$Monster$nameAndDisplayTagToIdString = F2(
	function (name, size) {
		if (!size) {
			return '#monster--' + $author$project$Component$Monster$nameToString(name);
		} else {
			return '#monster--' + ($author$project$Component$Monster$nameToString(name) + '-crop');
		}
	});
var $author$project$Component$Monster$eyesAndDisplaySizeToIdString = F2(
	function (_v0, size) {
		var name = _v0.a;
		var direction = _v0.b;
		return A2($author$project$Component$Monster$nameAndDisplayTagToIdString, name, size) + ('__' + ('eyes-' + direction));
	});
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$use = $elm$svg$Svg$trustedNode('use');
var $elm$svg$Svg$Attributes$xlinkHref = function (value) {
	return A3(
		_VirtualDom_attributeNS,
		'http://www.w3.org/1999/xlink',
		'xlink:href',
		_VirtualDom_noJavaScriptUri(value));
};
var $author$project$Component$Monster$xlink = function (href) {
	return $elm$svg$Svg$Attributes$xlinkHref(href);
};
var $author$project$Component$Monster$eyesAndDisplaySizeToUseTag = F2(
	function (eyes, size) {
		return A2(
			$elm$svg$Svg$use,
			_List_fromArray(
				[
					$author$project$Component$Monster$xlink(
					A2($author$project$Component$Monster$eyesAndDisplaySizeToIdString, eyes, size))
				]),
			_List_Nil);
	});
var $author$project$Component$Monster$nameAndDisplaySizeToUseTag = F2(
	function (name, size) {
		return A2(
			$elm$svg$Svg$use,
			_List_fromArray(
				[
					$author$project$Component$Monster$xlink(
					A2($author$project$Component$Monster$nameAndDisplayTagToIdString, name, size))
				]),
			_List_Nil);
	});
var $visotype$elm_dom$Dom$setNamespace = function (s) {
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{hQ: s});
		});
};
var $author$project$Dom$Extra$svgElement = function (type_) {
	return A2(
		$visotype$elm_dom$Dom$setNamespace,
		'http://www.w3.org/2000/svg',
		$visotype$elm_dom$Dom$element(type_));
};
var $author$project$Component$Monster$render = function (monster) {
	var name = monster.cE;
	var eyes = monster.cr;
	var body = monster.gs;
	var stringName = $author$project$Component$Monster$nameToString(name);
	var _v0 = body;
	var displaySize = _v0.a;
	var orientation = _v0.b;
	var _v1 = function () {
		if (!displaySize) {
			return _Utils_Tuple2(monster.jS, monster.hb);
		} else {
			return _Utils_Tuple2('300px', '120px');
		}
	}();
	var width = _v1.a;
	var height = _v1.b;
	return A2(
		$visotype$elm_dom$Dom$addClass,
		'monster',
		A3(
			$visotype$elm_dom$Dom$addClassConditional,
			'rocky',
			stringName === 'rocky',
			A3(
				$visotype$elm_dom$Dom$addClassConditional,
				'is-reflected-horizontal',
				orientation === 1,
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('height', height),
							_Utils_Tuple2('width', width),
							_Utils_Tuple2('pointer-events', 'none')
						]),
					A2(
						$visotype$elm_dom$Dom$appendChild,
						A2(
							$visotype$elm_dom$Dom$appendNode,
							A2($author$project$Component$Monster$eyesAndDisplaySizeToUseTag, eyes, displaySize),
							A2(
								$visotype$elm_dom$Dom$appendNode,
								A2($author$project$Component$Monster$nameAndDisplaySizeToUseTag, name, displaySize),
								A2(
									$visotype$elm_dom$Dom$addStyleList,
									_List_fromArray(
										[
											_Utils_Tuple2('width', 'auto'),
											_Utils_Tuple2('height', '100%')
										]),
									$author$project$Dom$Extra$svgElement('svg')))),
						$visotype$elm_dom$Dom$element('div'))))));
};
var $author$project$Views$ActivityPracticeView$barAppFlowSequential = F3(
	function (studentActivity, activeQuest, viewData) {
		var activity = studentActivity.f_;
		var quest = activeQuest.it;
		var eyeDirection = $author$project$Models$StudentActivity$isAPendingRemedialActivity(studentActivity) ? 'up' : 'straight';
		if ($author$project$Models$StudentActivity$hasBeenRatedOnce(studentActivity) && (!$author$project$Models$StudentActivity$isIframeActivity(studentActivity))) {
			var monster = A2(
				$visotype$elm_dom$Dom$addAttributeList,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'img'),
						A2($elm$html$Html$Attributes$attribute, 'aria-label', 'monster friend'),
						$elm$html$Html$Attributes$width(300),
						$elm$html$Html$Attributes$height(120)
					]),
				A2(
					$author$project$Styles$onTop,
					2,
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('position', 'absolute'),
								_Utils_Tuple2('left', '20px')
							]),
						A2(
							$visotype$elm_dom$Dom$addClass,
							'is-hidden-mobile',
							$author$project$Component$Monster$render(
								$author$project$Component$Monster$build(
									{
										gR: 1,
										hD: eyeDirection,
										cE: $author$project$Views$ActivityPracticeView$monsterName(activity),
										ic: 1
									}))))));
			return A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('display', 'flex'),
				A2(
					$visotype$elm_dom$Dom$addClass,
					'bottom-bar-activity-complete',
					A2(
						$author$project$Dom$Extra$addDataCy,
						'bottom-bar-activity-complete',
						$author$project$Component$BottomBar$customBar(
							{
								gq: $author$project$ES$UI$Color$purple.at,
								gH: monster,
								R: A3($author$project$Views$ActivityPracticeView$nextButton, studentActivity, activeQuest, viewData),
								io: A2($author$project$Views$ActivityPracticeView$previousButton, studentActivity, viewData)
							}))));
		} else {
			var bottomBarBackgroundColor = ($author$project$Models$Activity$isWebApp(activity) && (!$author$project$Models$StudentActivity$isIframeActivity(studentActivity))) ? $author$project$ES$UI$Color$transparent : $author$project$ES$UI$Color$white;
			var baseActivityMessage = function () {
				var _v0 = $author$project$Views$ActivityPracticeView$currentQuestionStatus(studentActivity);
				if (!_v0.$) {
					var quizQuestionStatus = _v0.a;
					return $author$project$Views$ActivityPracticeView$textForCurrentQuestion(quizQuestionStatus);
				} else {
					return 'Let\'s learn about ' + ($elm$core$String$toLower(quest.i0) + ' together!');
				}
			}();
			var activityMessage = $author$project$Models$StudentActivity$isIframeActivity(studentActivity) ? (($elm$core$String$length(
				$author$project$Models$Activity$instructionsToString(activity.ea)) > 0) ? $author$project$Views$ActivityPracticeView$removeLineBreaks(
				$author$project$Models$Activity$instructionsToString(activity.ea)) : baseActivityMessage) : baseActivityMessage;
			var bottomBarText = A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('white-space', 'wrap'),
						_Utils_Tuple2('overflow', 'scroll'),
						_Utils_Tuple2('text-overflow', 'ellipsis'),
						_Utils_Tuple2('width', 'calc(100vw - 250px)'),
						_Utils_Tuple2('max-height', '3.25em')
					]),
				A4(
					$author$project$TextToSpeech$WithGlobalMsg$speakable,
					viewData,
					false,
					activityMessage,
					$author$project$Views$ActivityPracticeView$voice(activity)));
			var content = function () {
				var displayBottomText = (!$author$project$Models$Activity$isWebApp(activity)) || $author$project$Models$StudentActivity$isIframeActivity(studentActivity);
				return A2(
					$visotype$elm_dom$Dom$addClass,
					'has-text-monster-' + $elm$core$String$fromInt(
						$author$project$Models$Activity$sequence(activity)),
					A2(
						$author$project$Component$Text$withSize,
						4,
						A3(
							$visotype$elm_dom$Dom$appendChildConditional,
							bottomBarText,
							displayBottomText,
							$visotype$elm_dom$Dom$element('div'))));
			}();
			return A2(
				$author$project$Styles$onTop,
				1,
				$author$project$Component$BottomBar$customBar(
					{
						gq: bottomBarBackgroundColor,
						gH: content,
						R: A3($author$project$Views$ActivityPracticeView$nextButton, studentActivity, activeQuest, viewData),
						io: A2($author$project$Views$ActivityPracticeView$previousButton, studentActivity, viewData)
					}));
		}
	});
var $author$project$Component$Icon$ArrowDown = 3;
var $author$project$Component$Buttons$Button$SemiCircleUp = 0;
var $author$project$Component$Icon$Small = {$: 1};
var $author$project$Component$Buttons$Button$addShapeClass = function (shape) {
	return $visotype$elm_dom$Dom$addClass(
		function () {
			if (!shape) {
				return 'es-button--semi-circle-up';
			} else {
				return 'es-button--semi-circle-down';
			}
		}());
};
var $author$project$Views$Components$Button$WithGlobalMsg$footerHideDown = function (message) {
	return A2(
		$author$project$Dom$Extra$addClickAction,
		message,
		A2(
			$author$project$Component$Buttons$Button$addShapeClass,
			0,
			A2(
				$author$project$Component$Buttons$Action$button,
				0,
				_Utils_Tuple3(
					0,
					0,
					A2($author$project$Component$Icon$Icon, 3, $author$project$Component$Icon$Small)))));
};
var $author$project$Component$Icon$ArrowUp = 2;
var $author$project$Views$Components$Button$WithGlobalMsg$footerShowUp = function (message) {
	return A2(
		$author$project$Dom$Extra$addClickAction,
		message,
		A2(
			$author$project$Component$Buttons$Button$addShapeClass,
			0,
			A2(
				$author$project$Component$Buttons$Action$button,
				0,
				_Utils_Tuple3(
					0,
					0,
					A2($author$project$Component$Icon$Icon, 2, $author$project$Component$Icon$Small)))));
};
var $author$project$Models$StudentActivity$isUnstarted = function (_v0) {
	var progress = _v0.ir;
	if (!progress.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $author$project$Views$ActivityPracticeView$bar = F3(
	function (studentActivity, activeQuest, viewData) {
		var renderBar = function (appFlowMode) {
			if (!appFlowMode.$) {
				return A3($author$project$Views$ActivityPracticeView$barAppFlowSequential, studentActivity, activeQuest, viewData);
			} else {
				return A3($author$project$Views$ActivityPracticeView$barAppFlowChoice, studentActivity, activeQuest, viewData);
			}
		};
		if ($author$project$Models$Activity$isPrePostQuiz(studentActivity.f_)) {
			return $visotype$elm_dom$Dom$element('span');
		} else {
			if ($author$project$Models$StudentActivity$isIframeActivity(studentActivity) && $author$project$Models$StudentActivity$isUnstarted(studentActivity)) {
				return $visotype$elm_dom$Dom$element('span');
			} else {
				if ($author$project$Models$StudentActivity$isIframeActivity(studentActivity)) {
					var showButton = A2(
						$visotype$elm_dom$Dom$setId,
						'footer-visibility-button',
						function () {
							var _v1 = A2($author$project$Models$StudentActivity$iframeActivityFooterVisibility, studentActivity.f_, studentActivity.fL);
							if (_v1 === 1) {
								return $author$project$Views$Components$Button$WithGlobalMsg$footerShowUp(
									$author$project$Messages$LoggedInRequiredMsg(
										A2($author$project$LoggedIn$Messages$SetIframeActivityFooterVisibility, studentActivity.hj, 0)));
							} else {
								return $author$project$Views$Components$Button$WithGlobalMsg$footerHideDown(
									$author$project$Messages$LoggedInRequiredMsg(
										A2($author$project$LoggedIn$Messages$SetIframeActivityFooterVisibility, studentActivity.hj, 1)));
							}
						}());
					var animationSettings = function (vendorPrefix) {
						return A2(
							$elm$core$List$map,
							$elm$core$Tuple$mapFirst(
								$elm$core$Basics$append(vendorPrefix)),
							_List_fromArray(
								[
									_Utils_Tuple2('animation-direction', 'normal'),
									_Utils_Tuple2('animation', 'iframe-webapp-footer-show'),
									_Utils_Tuple2('animation-duration', '.5s')
								]));
					};
					var _v0 = A2($author$project$Models$StudentActivity$iframeActivityFooterVisibility, studentActivity.f_, studentActivity.fL);
					if (_v0 === 1) {
						return A2(
							$visotype$elm_dom$Dom$appendChild,
							A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('bottom', '0'),
								showButton),
							A2(
								$visotype$elm_dom$Dom$addStyleList,
								_List_fromArray(
									[
										_Utils_Tuple2('position', 'fixed'),
										_Utils_Tuple2('bottom', '0'),
										_Utils_Tuple2('z-index', '2'),
										_Utils_Tuple2('width', '100vw'),
										_Utils_Tuple2(
										'height',
										$author$project$Views$ActivityPracticeView$footerHeight(studentActivity)),
										_Utils_Tuple2('background-color', 'white')
									]),
								A2(
									$visotype$elm_dom$Dom$setId,
									'footer-closed',
									$visotype$elm_dom$Dom$element('div'))));
					} else {
						return A2(
							$visotype$elm_dom$Dom$addStyleList,
							animationSettings('-moz-'),
							A2(
								$visotype$elm_dom$Dom$addStyleList,
								animationSettings('-o-'),
								A2(
									$visotype$elm_dom$Dom$addStyleList,
									animationSettings('-webkit-'),
									A2(
										$visotype$elm_dom$Dom$addStyleList,
										animationSettings(''),
										A2(
											$visotype$elm_dom$Dom$addClass,
											'animated',
											A2(
												$visotype$elm_dom$Dom$appendChild,
												showButton,
												renderBar(viewData.f8)))))));
					}
				} else {
					return renderBar(viewData.f8);
				}
			}
		}
	});
var $author$project$ES$UI$Color$blue = {
	at: A3($avh4$elm_color$Color$rgb255, 13, 104, 194),
	au: A3($avh4$elm_color$Color$rgb255, 10, 83, 155),
	ay: A3($avh4$elm_color$Color$rgb255, 71, 144, 215),
	az: A3($avh4$elm_color$Color$rgb255, 145, 188, 231),
	aA: A3($avh4$elm_color$Color$rgb255, 224, 240, 255)
};
var $author$project$Styles$fullHeight = $visotype$elm_dom$Dom$addStyle(
	_Utils_Tuple2('height', '100vh'));
var $author$project$Assets$lightbulbQuestProgressCompleteLarge = require('/usr/src/app/app/images/lightbulbs/quest-progress-bulb-complete-large.svg');
var $author$project$Views$ActivityPracticeView$lightbulbIfAnimating = function (_v0) {
	var isAnimationOngoing = _v0.hq;
	return isAnimationOngoing ? A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[
				A2(
				$visotype$elm_dom$Dom$addClass,
				'complete-activity-bulb',
				A2(
					$visotype$elm_dom$Dom$addAttributeList,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$Assets$path($author$project$Assets$lightbulbQuestProgressCompleteLarge))
						]),
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('width', '174px'),
								_Utils_Tuple2('height', '231px')
							]),
						$visotype$elm_dom$Dom$element('img'))))
			]),
		A2(
			$author$project$Styles$onTop,
			101,
			$author$project$Styles$centerContent(
				A2(
					$visotype$elm_dom$Dom$addStyle,
					_Utils_Tuple2('position', 'absolute'),
					$author$project$Styles$fullHeight(
						$author$project$Styles$fullWidth(
							$visotype$elm_dom$Dom$element('div'))))))) : $visotype$elm_dom$Dom$element('span');
};
var $author$project$Views$ActivityPracticeView$lightbulbsIfAppropriate = F3(
	function (studentActivity, activeQuest, viewData) {
		return ($author$project$Models$StudentActivity$hasBeenRatedOnce(studentActivity) && (!$author$project$Models$StudentActivity$isIframeActivity(studentActivity))) ? A3(
			$author$project$Component$Lightbulbs$render,
			activeQuest,
			$author$project$Component$Lightbulbs$ActiveLightbulb(studentActivity),
			viewData) : $visotype$elm_dom$Dom$element('span');
	});
var $author$project$Views$ActivityPracticeView$eyeDirectionForCurrentQuestion = function (quizQuestionStatus) {
	if (!quizQuestionStatus.$) {
		return 'up';
	} else {
		return 'right';
	}
};
var $author$project$Views$ActivityPracticeView$activityMonster = function (studentActivity) {
	var activity = studentActivity.f_;
	var lookingDirection = function () {
		var _v0 = studentActivity.ir;
		if (_v0.$ === 2) {
			return 'right';
		} else {
			var _v1 = $author$project$Views$ActivityPracticeView$currentQuestionStatus(studentActivity);
			if (!_v1.$) {
				var quizQuestionStatus = _v1.a;
				return $author$project$Views$ActivityPracticeView$eyeDirectionForCurrentQuestion(quizQuestionStatus);
			} else {
				return 'up';
			}
		}
	}();
	return A2(
		$visotype$elm_dom$Dom$addAttributeList,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'role', 'img'),
				A2($elm$html$Html$Attributes$attribute, 'aria-label', 'monster friend'),
				$elm$html$Html$Attributes$width(300),
				$elm$html$Html$Attributes$height(120)
			]),
		A2(
			$author$project$Styles$onTop,
			2,
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('position', 'fixed'),
						_Utils_Tuple2('bottom', '-4px'),
						_Utils_Tuple2('right', '40px')
					]),
				A2(
					$visotype$elm_dom$Dom$addClass,
					'is-hidden-mobile',
					$author$project$Component$Monster$render(
						$author$project$Component$Monster$build(
							{
								gR: 1,
								hD: lookingDirection,
								cE: $author$project$Views$ActivityPracticeView$monsterName(activity),
								ic: 0
							}))))));
};
var $author$project$Views$ActivityPracticeView$monsterIfAppropriate = F2(
	function (studentActivity, _v0) {
		var appFlowMode = _v0.f8;
		if ($author$project$Models$Activity$isWebApp(studentActivity.f_)) {
			return $visotype$elm_dom$Dom$element('span');
		} else {
			var _v1 = _Utils_Tuple2(appFlowMode, studentActivity.ir);
			_v1$2:
			while (true) {
				if (!_v1.a.$) {
					switch (_v1.b.$) {
						case 0:
							var _v2 = _v1.a;
							var _v3 = _v1.b;
							return $author$project$Views$ActivityPracticeView$activityMonster(studentActivity);
						case 1:
							var _v4 = _v1.a;
							return (studentActivity.hs || ($author$project$Models$StudentActivity$hasBeenRatedOnce(studentActivity) || $author$project$Models$StudentActivity$isIframeActivity(studentActivity))) ? $visotype$elm_dom$Dom$element('span') : $author$project$Views$ActivityPracticeView$activityMonster(studentActivity);
						default:
							break _v1$2;
					}
				} else {
					break _v1$2;
				}
			}
			return $visotype$elm_dom$Dom$element('span');
		}
	});
var $author$project$LoggedIn$Messages$CloseWebAppIntroModal = function (a) {
	return {$: 42, a: a};
};
var $author$project$Models$SubjectArea$Mathematics = 0;
var $author$project$Messages$VideoPlaybackEnded = {$: 51};
var $author$project$Views$ActivityPracticeView$IntroVideoModal$renderVideo = function (_v0) {
	var viewData = _v0.a1;
	var uniqueId = _v0.fC;
	var videoUrl = _v0.fK;
	var videoEndedMsg = _v0.fH;
	var videoErrorMsg = _v0.fI;
	var subjectArea = _v0.bp;
	var videoKey = function (id) {
		return 'video-player-' + id;
	};
	var playMsg = $author$project$Messages$PlayVideo(
		videoKey(uniqueId));
	var _v1 = viewData;
	var devFlags = _v1.dO;
	var videoPlayState = _v1.jI;
	var player = A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('height', '100%')
			]),
		A2(
			$visotype$elm_dom$Dom$addClass,
			'video-feedback-player',
			$author$project$Component$Video$player(
				{
					gV: videoEndedMsg,
					co: videoErrorMsg,
					ih: $author$project$Messages$PauseVideo,
					b_: devFlags.jJ,
					fE: videoUrl,
					fJ: videoKey(uniqueId),
					da: $author$project$Messages$RightClickOnVideoBlocked
				})));
	var controls = A2(
		$visotype$elm_dom$Dom$addClass,
		'video-controls',
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('position', 'absolute'),
					_Utils_Tuple2('top', '50%'),
					_Utils_Tuple2('bottom', '50%'),
					_Utils_Tuple2('left', '0'),
					_Utils_Tuple2('right', '0'),
					_Utils_Tuple2('margin', 'auto'),
					_Utils_Tuple2('pointer-events', 'all')
				]),
			$author$project$Component$Video$controls(
				{
					ie: $elm$core$Maybe$Just(playMsg),
					ik: $author$project$Messages$PlayVideo,
					iE: $author$project$Messages$RestartVideo,
					fJ: videoKey(uniqueId),
					jI: videoPlayState
				})));
	var progressBar = A2(
		$author$project$Styles$onTop,
		5,
		A2(
			$visotype$elm_dom$Dom$addClass,
			'video-progress-bar',
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('bottom', '0'),
				$author$project$Component$Video$progressBar(videoPlayState))));
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[player, progressBar, controls]),
		$visotype$elm_dom$Dom$element('div'));
};
var $author$project$Views$ActivityPracticeView$IntroVideoModal$render = F2(
	function (studentActivity, viewData) {
		var modalState = viewData.hM;
		var videoEnded = $author$project$Models$VideoPlayer$isEnded(viewData.jI);
		var video = A3(
			$visotype$elm_dom$Dom$addStyleConditional,
			_Utils_Tuple2('pointer-events', 'none'),
			videoEnded,
			$author$project$Views$ActivityPracticeView$IntroVideoModal$renderVideo(
				{
					bp: 0,
					fC: $author$project$Models$Ids$toString(studentActivity.hj),
					fH: $author$project$Messages$VideoPlaybackEnded,
					fI: $author$project$Messages$LoggedInRequiredMsg(
						$author$project$LoggedIn$Messages$CloseWebAppIntroModal(studentActivity)),
					fK: 'https://cdn.esparklearning.com/espark-media/web_activity_intro.mp4',
					a1: viewData
				}));
		var navButton = videoEnded ? A2(
			$author$project$Animations$CssAnimations$applyWithAnimationPreset,
			5,
			A2(
				$author$project$Dom$Extra$addDataCy,
				'close-modal-button',
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('position', 'absolute'),
							_Utils_Tuple2('bottom', '20px'),
							_Utils_Tuple2('right', '20px')
						]),
					A2(
						$author$project$Views$Components$Button$WithGlobalMsg$next,
						false,
						$author$project$Messages$LoggedInRequiredMsg(
							$author$project$LoggedIn$Messages$CloseWebAppIntroModal(studentActivity)))))) : $visotype$elm_dom$Dom$element('span');
		var content = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('position', 'fixed'),
					_Utils_Tuple2('top', '50%'),
					_Utils_Tuple2('left', '50%'),
					_Utils_Tuple2('transform', 'translate(-50%, -50%)'),
					_Utils_Tuple2('width', '80vw'),
					_Utils_Tuple2('height', '80vh'),
					_Utils_Tuple2('max-height', '600px'),
					_Utils_Tuple2('max-width', '800px')
				]),
			A2(
				$visotype$elm_dom$Dom$addClass,
				'modal-content',
				A2(
					$author$project$Styles$radius,
					5,
					A2(
						$visotype$elm_dom$Dom$appendChildList,
						_List_fromArray(
							[video, navButton]),
						$visotype$elm_dom$Dom$element('div')))));
		var background = A2(
			$visotype$elm_dom$Dom$addClass,
			'modal-background',
			$visotype$elm_dom$Dom$element('div'));
		if ((!modalState.$) && (!modalState.a.$)) {
			var _v1 = modalState.a;
			return A2(
				$author$project$Dom$Extra$addDataCy,
				'intro-video-modal',
				A2(
					$author$project$Styles$onTop,
					301,
					A2(
						$visotype$elm_dom$Dom$addClassList,
						_List_fromArray(
							['modal', 'is-active']),
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[background, content]),
							$visotype$elm_dom$Dom$element('div')))));
		} else {
			return $visotype$elm_dom$Dom$element('span');
		}
	});
var $author$project$Messages$CloseModal = {$: 40};
var $author$project$LoggedIn$Messages$KeepWorkingOnActivity = function (a) {
	return {$: 43, a: a};
};
var $author$project$Models$StudentActivity$ThumbsDown = 1;
var $author$project$Models$StudentActivity$ThumbsUp = 0;
var $author$project$Component$ResponsiveGrid$columns = function (elements) {
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		elements,
		A2(
			$visotype$elm_dom$Dom$addClass,
			'columns',
			$visotype$elm_dom$Dom$element('div')));
};
var $author$project$Models$ActivityWindowState$isActivityWindowClosed = F2(
	function (studentActivityId, activityWindowState) {
		switch (activityWindowState.$) {
			case 2:
				var windowStudentActivityId = activityWindowState.a;
				return _Utils_eq(windowStudentActivityId, studentActivityId);
			case 1:
				return false;
			default:
				return false;
		}
	});
var $author$project$Component$Buttons$Button$Red = 8;
var $author$project$Component$Icon$ThumbsDown = 32;
var $author$project$Component$Icon$XXX = {$: 7};
var $author$project$Component$Buttons$Choice$IconOnlyButton = 1;
var $author$project$Component$Buttons$Choice$iconOnly = F2(
	function (loadingState, _v0) {
		var color = _v0.a;
		var icon = _v0.b;
		return A2(
			$elm$core$Basics$composeR,
			A2($author$project$Component$Buttons$Choice$build, 1, loadingState),
			A2(
				$elm$core$Basics$composeR,
				$author$project$Component$Buttons$Button$addColorClass(color),
				$visotype$elm_dom$Dom$prependChild(
					$author$project$Component$Icon$render(icon))));
	});
var $author$project$Views$Components$Button$WithGlobalMsg$ratingsModalThumbsDown = F2(
	function (isLoading, message) {
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'thumbs-down-button',
			A2(
				$author$project$Dom$AddClickOnceAction$addClickOnceAction,
				message,
				A3(
					$author$project$Component$Buttons$Choice$iconOnly,
					$author$project$Component$Buttons$Button$loadingStateFromDisabledState(isLoading),
					_Utils_Tuple2(
						8,
						A2($author$project$Component$Icon$Icon, 32, $author$project$Component$Icon$XXX)),
					$visotype$elm_dom$Dom$element('button'))));
	});
var $author$project$Component$Icon$ThumbsUp = 34;
var $author$project$Views$Components$Button$WithGlobalMsg$ratingsModalThumbsUp = F2(
	function (isLoading, message) {
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'thumbs-up-button',
			A2(
				$author$project$Dom$AddClickOnceAction$addClickOnceAction,
				message,
				A3(
					$author$project$Component$Buttons$Choice$iconOnly,
					$author$project$Component$Buttons$Button$loadingStateFromDisabledState(isLoading),
					_Utils_Tuple2(
						2,
						A2($author$project$Component$Icon$Icon, 34, $author$project$Component$Icon$XXX)),
					$visotype$elm_dom$Dom$element('button'))));
	});
var $author$project$Styles$sizeFill = $visotype$elm_dom$Dom$addStyleList(
	_List_fromArray(
		[
			_Utils_Tuple2('width', '100%'),
			_Utils_Tuple2('height', '100%')
		]));
var $author$project$Models$Modal$Open = function (a) {
	return {$: 0, a: a};
};
var $author$project$Component$Modal$wrapperWithDimensionUnits = F3(
	function (modalIdentifier, state, _v0) {
		var width = _v0.jS;
		var height = _v0.hb;
		var maxWidth = _v0.hF;
		var content = _v0.gH;
		var onClose = _v0.h5;
		var stateModifier = function () {
			if (state.$ === 1) {
				return '';
			} else {
				var currentModalIdentifier = state.a;
				return _Utils_eq(currentModalIdentifier, modalIdentifier) ? 'is-active' : '';
			}
		}();
		var closeButton = function () {
			if (onClose.$ === 1) {
				return $visotype$elm_dom$Dom$element('span');
			} else {
				var msg = onClose.a;
				return A2(
					$author$project$Dom$Extra$addDataCy,
					'close-modal-button',
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('position', 'absolute'),
								_Utils_Tuple2('top', '0'),
								_Utils_Tuple2('right', '-1px'),
								_Utils_Tuple2('padding', '0'),
								_Utils_Tuple2('background', 'transparent')
							]),
						$author$project$Views$Components$Button$WithGlobalMsg$close(msg)));
			}
		}();
		var closeButtonIfAppropriate = _Utils_eq(modalIdentifier, $author$project$Models$Modal$RatingsModal) ? $visotype$elm_dom$Dom$element('span') : closeButton;
		return _Utils_eq(
			state,
			$author$project$Models$Modal$Open(modalIdentifier)) ? A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['modal', stateModifier]),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						A2(
						$visotype$elm_dom$Dom$addClass,
						'modal-background',
						$visotype$elm_dom$Dom$element('div')),
						A2(
						$author$project$Styles$radius,
						5,
						A2(
							$visotype$elm_dom$Dom$addStyle,
							_Utils_Tuple2(
								'maxWidth',
								A2($elm$core$Maybe$withDefault, width, maxWidth)),
							A2(
								$author$project$Styles$size,
								{hb: height, jS: width},
								A2(
									$visotype$elm_dom$Dom$addClass,
									'modal-content',
									A2(
										$visotype$elm_dom$Dom$appendChildList,
										_List_fromArray(
											[content, closeButtonIfAppropriate]),
										$visotype$elm_dom$Dom$element('div'))))))
					]),
				$visotype$elm_dom$Dom$element('div'))) : $visotype$elm_dom$Dom$element('span');
	});
var $author$project$Component$Modal$wrapper = F3(
	function (modalIdentifier, state, _v0) {
		var width = _v0.jS;
		var height = _v0.hb;
		var content = _v0.gH;
		var onClose = _v0.h5;
		return A3(
			$author$project$Component$Modal$wrapperWithDimensionUnits,
			modalIdentifier,
			state,
			{
				gH: content,
				hb: $elm$core$String$fromInt(height) + 'px',
				hF: $elm$core$Maybe$Nothing,
				h5: onClose,
				jS: $elm$core$String$fromInt(width) + 'px'
			});
	});
var $author$project$Views$ActivityPracticeView$RatingsModal$render = F2(
	function (studentActivity, viewData) {
		var productFeatures = viewData.iq;
		var modalState = viewData.hM;
		var activityWindowState = viewData.f1;
		var wrapContentInTextStyle = A2(
			$elm$core$Basics$composeR,
			$author$project$Component$Text$withSize(2),
			$author$project$Styles$centerContent);
		var wrapButtonsInStyle = function (buttonContent) {
			return A2(
				$visotype$elm_dom$Dom$addAttribute,
				A2($elm$html$Html$Attributes$attribute, 'aria-label', 'close'),
				A2(
					$visotype$elm_dom$Dom$appendChild,
					A2(
						$author$project$Styles$square,
						200,
						$author$project$Component$ResponsiveGrid$column(buttonContent)),
					$visotype$elm_dom$Dom$element('div')));
		};
		var withTextToSpeech = function (textString) {
			return A2(
				$visotype$elm_dom$Dom$appendChild,
				A4(
					$author$project$TextToSpeech$WithGlobalMsg$speakable,
					viewData,
					true,
					textString,
					$author$project$Component$Monster$voice(
						$author$project$Component$Monster$indexToName(
							$author$project$Models$Activity$sequence(studentActivity.f_)))),
				$visotype$elm_dom$Dom$element('div'));
		};
		var text = function () {
			var _v3 = studentActivity.f_.f0;
			switch (_v3.$) {
				case 0:
					return A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('padding', '50px 0'),
						wrapContentInTextStyle(
							withTextToSpeech('Did you like this video?')));
				case 1:
					return A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('padding', '50px 0'),
						wrapContentInTextStyle(
							withTextToSpeech('Did you like this activity?')));
				case 6:
					return A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('padding', '50px 0'),
						wrapContentInTextStyle(
							withTextToSpeech('Did you like this activity?')));
				case 2:
					return A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('padding', '50px 0'),
						wrapContentInTextStyle(
							withTextToSpeech('Did you like this activity?')));
				case 3:
					return A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('padding', '50px 0'),
						wrapContentInTextStyle(
							withTextToSpeech('Did you like this activity?')));
				case 4:
					return A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('padding', '50px 0'),
						wrapContentInTextStyle(
							withTextToSpeech('Did you like this activity?')));
				case 5:
					return A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('padding', '50px 0'),
						wrapContentInTextStyle(
							withTextToSpeech('Did you like this activity?')));
				default:
					return A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('padding', '50px 0'),
						$author$project$Styles$centerContent(
							$visotype$elm_dom$Dom$element('span')));
			}
		}();
		var showKeepWorkingButton = function () {
			var _v2 = studentActivity.f_.f0;
			switch (_v2.$) {
				case 0:
					return A2($author$project$Models$ProductFeature$isEnabled, 4, productFeatures);
				case 1:
					return !A2($author$project$Models$ActivityWindowState$isActivityWindowClosed, studentActivity.hj, activityWindowState);
				default:
					return false;
			}
		}();
		var ratingThumbsUpButton = A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('padding', '0 0 0 15px'),
			wrapButtonsInStyle(
				A2(
					$visotype$elm_dom$Dom$addAttribute,
					A2($elm$html$Html$Attributes$attribute, 'aria-label', 'I liked this activity.'),
					A2(
						$author$project$Views$Components$Button$WithGlobalMsg$ratingsModalThumbsUp,
						false,
						$author$project$Messages$LoggedInRequiredMsg(
							A3($author$project$LoggedIn$Messages$CompleteStudentActivity, studentActivity.hj, 0, showKeepWorkingButton))))));
		var ratingThumbsDownButton = A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('padding', '0 15px 0 0'),
			wrapButtonsInStyle(
				A2(
					$visotype$elm_dom$Dom$addAttribute,
					A2($elm$html$Html$Attributes$attribute, 'aria-label', 'I did not like this activity.'),
					A2(
						$author$project$Views$Components$Button$WithGlobalMsg$ratingsModalThumbsDown,
						false,
						$author$project$Messages$LoggedInRequiredMsg(
							A3($author$project$LoggedIn$Messages$CompleteStudentActivity, studentActivity.hj, 1, showKeepWorkingButton))))));
		var ratingButtons = A2(
			$visotype$elm_dom$Dom$appendChild,
			$author$project$Styles$centerContent(
				$author$project$Component$ResponsiveGrid$columns(
					_List_fromArray(
						[ratingThumbsDownButton, ratingThumbsUpButton]))),
			$visotype$elm_dom$Dom$element('div'));
		var keepWorkingButton = function () {
			var button = A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('padding', '50px 0'),
				$author$project$Styles$centerContent(
					A2(
						$visotype$elm_dom$Dom$appendChild,
						A2(
							$visotype$elm_dom$Dom$addAttribute,
							A2($elm$html$Html$Attributes$attribute, 'aria-label', 'close'),
							A2(
								$author$project$Dom$AddClickOnceAction$addClickOnceAction,
								$author$project$Messages$LoggedInRequiredMsg(
									$author$project$LoggedIn$Messages$KeepWorkingOnActivity(studentActivity.hj)),
								A2(
									$author$project$Dom$Extra$addDataCy,
									'keep-working-button',
									A2(
										$visotype$elm_dom$Dom$addClassList,
										_List_fromArray(
											['button', 'is-rounded']),
										A2(
											$author$project$Styles$size,
											{hb: '80px', jS: '280px'},
											$author$project$Styles$centerContent(
												A2(
													$visotype$elm_dom$Dom$appendChild,
													A2(
														$author$project$Component$Text$withSize,
														3,
														A2(
															$visotype$elm_dom$Dom$appendText,
															'I\'m not done yet ',
															$visotype$elm_dom$Dom$element('div'))),
													$visotype$elm_dom$Dom$element('button')))))))),
						$visotype$elm_dom$Dom$element('div'))));
			return showKeepWorkingButton ? button : $visotype$elm_dom$Dom$element('span');
		}();
		var heightBasedOnActivity = showKeepWorkingButton ? 524 : 444;
		var content = A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('position', 'relative'),
			A2(
				$author$project$Styles$filled,
				$author$project$ES$UI$Color$white,
				$author$project$Styles$sizeFill(
					A2(
						$visotype$elm_dom$Dom$appendChildList,
						_List_fromArray(
							[text, ratingButtons, keepWorkingButton]),
						$visotype$elm_dom$Dom$element('div')))));
		if ((!modalState.$) && (modalState.a.$ === 1)) {
			var _v1 = modalState.a;
			return A2(
				$author$project$Dom$Extra$addDataCy,
				'activity-rating-modal',
				A3(
					$author$project$Component$Modal$wrapper,
					$author$project$Models$Modal$RatingsModal,
					modalState,
					{
						gH: content,
						hb: heightBasedOnActivity,
						h5: $elm$core$Maybe$Just($author$project$Messages$CloseModal),
						jS: 675
					}));
		} else {
			return $visotype$elm_dom$Dom$element('span');
		}
	});
var $author$project$Views$ActivityPracticeView$view = F3(
	function (studentActivity, activeQuest, viewData) {
		return A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				[
					'is-activity-practice',
					$author$project$Models$Activity$typeToString(studentActivity.f_.f0),
					$author$project$Models$StudentActivity$progressToString(studentActivity.ir) + '-activity'
				]),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						$author$project$Views$ActivityPracticeView$lightbulbIfAnimating(viewData),
						A2($author$project$Views$ActivityPracticeView$monsterIfAppropriate, studentActivity, viewData),
						A3($author$project$Views$ActivityPracticeView$lightbulbsIfAppropriate, studentActivity, activeQuest, viewData),
						A2($author$project$Views$ActivityPracticeView$RatingsModal$render, studentActivity, viewData),
						A3($author$project$Views$ActivityPracticeView$bar, studentActivity, activeQuest, viewData),
						A3($author$project$Views$ActivityPracticeView$activityContent, studentActivity, viewData, activeQuest.it.bp),
						A2($author$project$Views$ActivityPracticeView$IntroVideoModal$render, studentActivity, viewData)
					]),
				A2(
					$author$project$Styles$filled,
					$author$project$ES$UI$Color$blue.ay,
					$visotype$elm_dom$Dom$element('div'))));
	});
var $author$project$Views$Components$ScrollIndicator$Right = 1;
var $author$project$Messages$ScrollContainerTo = function (a) {
	return {$: 12, a: a};
};
var $visotype$elm_dom$Dom$addAttributeListConditional = F2(
	function (la, test) {
		if (test) {
			return $visotype$elm_dom$Dom$addAttributeList(la);
		} else {
			return function (x) {
				return x;
			};
		}
	});
var $visotype$elm_dom$Dom$addClassListConditional = F2(
	function (ls, test) {
		if (test) {
			return $visotype$elm_dom$Dom$addClassList(ls);
		} else {
			return function (x) {
				return x;
			};
		}
	});
var $visotype$elm_dom$Dom$appendNodeList = function (lv) {
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					gC: A2($elm$core$List$append, n.gC, lv)
				});
		});
};
var $author$project$Views$AssessmentView$questionRationaleText = F2(
	function (itemScore, rationale) {
		var _v0 = itemScore.iM;
		switch (_v0) {
			case 3:
				return $elm$core$Maybe$Just('Great Job! ' + rationale);
			case 2:
				return $elm$core$Maybe$Just('Remember: ' + rationale);
			case 0:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $hecrj$html_parser$Html$Parser$Element = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $hecrj$html_parser$Html$Parser$chompOneOrMore = function (fn) {
	return A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$chompIf(fn),
		$elm$parser$Parser$chompWhile(fn));
};
var $hecrj$html_parser$Html$Parser$isSpaceCharacter = function (c) {
	return (c === ' ') || ((c === '\t') || ((c === '\n') || ((c === '\u000D') || ((c === '\u000C') || (c === '\u00A0')))));
};
var $hecrj$html_parser$Html$Parser$closingTag = function (name) {
	var chompName = A2(
		$elm$parser$Parser$andThen,
		function (closingName) {
			return _Utils_eq(
				$elm$core$String$toLower(closingName),
				name) ? $elm$parser$Parser$succeed(0) : $elm$parser$Parser$problem('closing tag does not match opening tag: ' + name);
		},
		$elm$parser$Parser$getChompedString(
			$hecrj$html_parser$Html$Parser$chompOneOrMore(
				function (c) {
					return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && (c !== '>');
				})));
	return A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<')),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('/'))),
				chompName),
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('>')));
};
var $hecrj$html_parser$Html$Parser$Comment = function (a) {
	return {$: 2, a: a};
};
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.ao, s.eZ, s.dD, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.b)) : A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.ao, newOffset) < 0,
			0,
			{dD: newCol, b: s.b, e: s.e, ao: newOffset, eZ: newRow, a: s.a});
	};
};
var $elm$parser$Parser$chompUntil = function (str) {
	return $elm$parser$Parser$Advanced$chompUntil(
		$elm$parser$Parser$toToken(str));
};
var $hecrj$html_parser$Html$Parser$comment = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($hecrj$html_parser$Html$Parser$Comment),
			$elm$parser$Parser$token('<!')),
		$elm$parser$Parser$token('--')),
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompUntil('-->')),
		$elm$parser$Parser$token('-->')));
var $hecrj$html_parser$Html$Parser$voidElements = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $hecrj$html_parser$Html$Parser$isVoidElement = function (name) {
	return A2($elm$core$List$member, name, $hecrj$html_parser$Html$Parser$voidElements);
};
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $hecrj$html_parser$Html$Parser$many = function (parser_) {
	return A2(
		$elm$parser$Parser$loop,
		_List_Nil,
		function (list) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$map,
						function (_new) {
							return $elm$parser$Parser$Loop(
								A2($elm$core$List$cons, _new, list));
						},
						parser_),
						$elm$parser$Parser$succeed(
						$elm$parser$Parser$Done(
							$elm$core$List$reverse(list)))
					]));
		});
};
var $hecrj$html_parser$Html$Parser$isTagAttributeCharacter = function (c) {
	return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '>') && ((c !== '/') && (c !== '=')))));
};
var $hecrj$html_parser$Html$Parser$tagAttributeName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($hecrj$html_parser$Html$Parser$isTagAttributeCharacter)));
var $hecrj$html_parser$Html$Parser$chompSemicolon = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(';'));
var $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Aacute', 'Á'),
			_Utils_Tuple2('aacute', 'á'),
			_Utils_Tuple2('Abreve', 'Ă'),
			_Utils_Tuple2('abreve', 'ă'),
			_Utils_Tuple2('ac', '∾'),
			_Utils_Tuple2('acd', '∿'),
			_Utils_Tuple2('acE', '∾̳'),
			_Utils_Tuple2('Acirc', 'Â'),
			_Utils_Tuple2('acirc', 'â'),
			_Utils_Tuple2('acute', '´'),
			_Utils_Tuple2('Acy', 'А'),
			_Utils_Tuple2('acy', 'а'),
			_Utils_Tuple2('AElig', 'Æ'),
			_Utils_Tuple2('aelig', 'æ'),
			_Utils_Tuple2('af', '\u2061'),
			_Utils_Tuple2('Afr', '\uD835\uDD04'),
			_Utils_Tuple2('afr', '\uD835\uDD1E'),
			_Utils_Tuple2('Agrave', 'À'),
			_Utils_Tuple2('agrave', 'à'),
			_Utils_Tuple2('alefsym', 'ℵ'),
			_Utils_Tuple2('aleph', 'ℵ'),
			_Utils_Tuple2('Alpha', 'Α'),
			_Utils_Tuple2('alpha', 'α'),
			_Utils_Tuple2('Amacr', 'Ā'),
			_Utils_Tuple2('amacr', 'ā'),
			_Utils_Tuple2('amalg', '⨿'),
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('AMP', '&'),
			_Utils_Tuple2('andand', '⩕'),
			_Utils_Tuple2('And', '⩓'),
			_Utils_Tuple2('and', '∧'),
			_Utils_Tuple2('andd', '⩜'),
			_Utils_Tuple2('andslope', '⩘'),
			_Utils_Tuple2('andv', '⩚'),
			_Utils_Tuple2('ang', '∠'),
			_Utils_Tuple2('ange', '⦤'),
			_Utils_Tuple2('angle', '∠'),
			_Utils_Tuple2('angmsdaa', '⦨'),
			_Utils_Tuple2('angmsdab', '⦩'),
			_Utils_Tuple2('angmsdac', '⦪'),
			_Utils_Tuple2('angmsdad', '⦫'),
			_Utils_Tuple2('angmsdae', '⦬'),
			_Utils_Tuple2('angmsdaf', '⦭'),
			_Utils_Tuple2('angmsdag', '⦮'),
			_Utils_Tuple2('angmsdah', '⦯'),
			_Utils_Tuple2('angmsd', '∡'),
			_Utils_Tuple2('angrt', '∟'),
			_Utils_Tuple2('angrtvb', '⊾'),
			_Utils_Tuple2('angrtvbd', '⦝'),
			_Utils_Tuple2('angsph', '∢'),
			_Utils_Tuple2('angst', 'Å'),
			_Utils_Tuple2('angzarr', '⍼'),
			_Utils_Tuple2('Aogon', 'Ą'),
			_Utils_Tuple2('aogon', 'ą'),
			_Utils_Tuple2('Aopf', '\uD835\uDD38'),
			_Utils_Tuple2('aopf', '\uD835\uDD52'),
			_Utils_Tuple2('apacir', '⩯'),
			_Utils_Tuple2('ap', '≈'),
			_Utils_Tuple2('apE', '⩰'),
			_Utils_Tuple2('ape', '≊'),
			_Utils_Tuple2('apid', '≋'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('ApplyFunction', '\u2061'),
			_Utils_Tuple2('approx', '≈'),
			_Utils_Tuple2('approxeq', '≊'),
			_Utils_Tuple2('Aring', 'Å'),
			_Utils_Tuple2('aring', 'å'),
			_Utils_Tuple2('Ascr', '\uD835\uDC9C'),
			_Utils_Tuple2('ascr', '\uD835\uDCB6'),
			_Utils_Tuple2('Assign', '≔'),
			_Utils_Tuple2('ast', '*'),
			_Utils_Tuple2('asymp', '≈'),
			_Utils_Tuple2('asympeq', '≍'),
			_Utils_Tuple2('Atilde', 'Ã'),
			_Utils_Tuple2('atilde', 'ã'),
			_Utils_Tuple2('Auml', 'Ä'),
			_Utils_Tuple2('auml', 'ä'),
			_Utils_Tuple2('awconint', '∳'),
			_Utils_Tuple2('awint', '⨑'),
			_Utils_Tuple2('backcong', '≌'),
			_Utils_Tuple2('backepsilon', '϶'),
			_Utils_Tuple2('backprime', '‵'),
			_Utils_Tuple2('backsim', '∽'),
			_Utils_Tuple2('backsimeq', '⋍'),
			_Utils_Tuple2('Backslash', '∖'),
			_Utils_Tuple2('Barv', '⫧'),
			_Utils_Tuple2('barvee', '⊽'),
			_Utils_Tuple2('barwed', '⌅'),
			_Utils_Tuple2('Barwed', '⌆'),
			_Utils_Tuple2('barwedge', '⌅'),
			_Utils_Tuple2('bbrk', '⎵'),
			_Utils_Tuple2('bbrktbrk', '⎶'),
			_Utils_Tuple2('bcong', '≌'),
			_Utils_Tuple2('Bcy', 'Б'),
			_Utils_Tuple2('bcy', 'б'),
			_Utils_Tuple2('bdquo', '„'),
			_Utils_Tuple2('becaus', '∵'),
			_Utils_Tuple2('because', '∵'),
			_Utils_Tuple2('Because', '∵'),
			_Utils_Tuple2('bemptyv', '⦰'),
			_Utils_Tuple2('bepsi', '϶'),
			_Utils_Tuple2('bernou', 'ℬ'),
			_Utils_Tuple2('Bernoullis', 'ℬ'),
			_Utils_Tuple2('Beta', 'Β'),
			_Utils_Tuple2('beta', 'β'),
			_Utils_Tuple2('beth', 'ℶ'),
			_Utils_Tuple2('between', '≬'),
			_Utils_Tuple2('Bfr', '\uD835\uDD05'),
			_Utils_Tuple2('bfr', '\uD835\uDD1F'),
			_Utils_Tuple2('bigcap', '⋂'),
			_Utils_Tuple2('bigcirc', '◯'),
			_Utils_Tuple2('bigcup', '⋃'),
			_Utils_Tuple2('bigodot', '⨀'),
			_Utils_Tuple2('bigoplus', '⨁'),
			_Utils_Tuple2('bigotimes', '⨂'),
			_Utils_Tuple2('bigsqcup', '⨆'),
			_Utils_Tuple2('bigstar', '★'),
			_Utils_Tuple2('bigtriangledown', '▽'),
			_Utils_Tuple2('bigtriangleup', '△'),
			_Utils_Tuple2('biguplus', '⨄'),
			_Utils_Tuple2('bigvee', '⋁'),
			_Utils_Tuple2('bigwedge', '⋀'),
			_Utils_Tuple2('bkarow', '⤍'),
			_Utils_Tuple2('blacklozenge', '⧫'),
			_Utils_Tuple2('blacksquare', '▪'),
			_Utils_Tuple2('blacktriangle', '▴'),
			_Utils_Tuple2('blacktriangledown', '▾'),
			_Utils_Tuple2('blacktriangleleft', '◂'),
			_Utils_Tuple2('blacktriangleright', '▸'),
			_Utils_Tuple2('blank', '␣'),
			_Utils_Tuple2('blk12', '▒'),
			_Utils_Tuple2('blk14', '░'),
			_Utils_Tuple2('blk34', '▓'),
			_Utils_Tuple2('block', '█'),
			_Utils_Tuple2('bne', '=⃥'),
			_Utils_Tuple2('bnequiv', '≡⃥'),
			_Utils_Tuple2('bNot', '⫭'),
			_Utils_Tuple2('bnot', '⌐'),
			_Utils_Tuple2('Bopf', '\uD835\uDD39'),
			_Utils_Tuple2('bopf', '\uD835\uDD53'),
			_Utils_Tuple2('bot', '⊥'),
			_Utils_Tuple2('bottom', '⊥'),
			_Utils_Tuple2('bowtie', '⋈'),
			_Utils_Tuple2('boxbox', '⧉'),
			_Utils_Tuple2('boxdl', '┐'),
			_Utils_Tuple2('boxdL', '╕'),
			_Utils_Tuple2('boxDl', '╖'),
			_Utils_Tuple2('boxDL', '╗'),
			_Utils_Tuple2('boxdr', '┌'),
			_Utils_Tuple2('boxdR', '╒'),
			_Utils_Tuple2('boxDr', '╓'),
			_Utils_Tuple2('boxDR', '╔'),
			_Utils_Tuple2('boxh', '─'),
			_Utils_Tuple2('boxH', '═'),
			_Utils_Tuple2('boxhd', '┬'),
			_Utils_Tuple2('boxHd', '╤'),
			_Utils_Tuple2('boxhD', '╥'),
			_Utils_Tuple2('boxHD', '╦'),
			_Utils_Tuple2('boxhu', '┴'),
			_Utils_Tuple2('boxHu', '╧'),
			_Utils_Tuple2('boxhU', '╨'),
			_Utils_Tuple2('boxHU', '╩'),
			_Utils_Tuple2('boxminus', '⊟'),
			_Utils_Tuple2('boxplus', '⊞'),
			_Utils_Tuple2('boxtimes', '⊠'),
			_Utils_Tuple2('boxul', '┘'),
			_Utils_Tuple2('boxuL', '╛'),
			_Utils_Tuple2('boxUl', '╜'),
			_Utils_Tuple2('boxUL', '╝'),
			_Utils_Tuple2('boxur', '└'),
			_Utils_Tuple2('boxuR', '╘'),
			_Utils_Tuple2('boxUr', '╙'),
			_Utils_Tuple2('boxUR', '╚'),
			_Utils_Tuple2('boxv', '│'),
			_Utils_Tuple2('boxV', '║'),
			_Utils_Tuple2('boxvh', '┼'),
			_Utils_Tuple2('boxvH', '╪'),
			_Utils_Tuple2('boxVh', '╫'),
			_Utils_Tuple2('boxVH', '╬'),
			_Utils_Tuple2('boxvl', '┤'),
			_Utils_Tuple2('boxvL', '╡'),
			_Utils_Tuple2('boxVl', '╢'),
			_Utils_Tuple2('boxVL', '╣'),
			_Utils_Tuple2('boxvr', '├'),
			_Utils_Tuple2('boxvR', '╞'),
			_Utils_Tuple2('boxVr', '╟'),
			_Utils_Tuple2('boxVR', '╠'),
			_Utils_Tuple2('bprime', '‵'),
			_Utils_Tuple2('breve', '˘'),
			_Utils_Tuple2('Breve', '˘'),
			_Utils_Tuple2('brvbar', '¦'),
			_Utils_Tuple2('bscr', '\uD835\uDCB7'),
			_Utils_Tuple2('Bscr', 'ℬ'),
			_Utils_Tuple2('bsemi', '⁏'),
			_Utils_Tuple2('bsim', '∽'),
			_Utils_Tuple2('bsime', '⋍'),
			_Utils_Tuple2('bsolb', '⧅'),
			_Utils_Tuple2('bsol', '\\'),
			_Utils_Tuple2('bsolhsub', '⟈'),
			_Utils_Tuple2('bull', '•'),
			_Utils_Tuple2('bullet', '•'),
			_Utils_Tuple2('bump', '≎'),
			_Utils_Tuple2('bumpE', '⪮'),
			_Utils_Tuple2('bumpe', '≏'),
			_Utils_Tuple2('Bumpeq', '≎'),
			_Utils_Tuple2('bumpeq', '≏'),
			_Utils_Tuple2('Cacute', 'Ć'),
			_Utils_Tuple2('cacute', 'ć'),
			_Utils_Tuple2('capand', '⩄'),
			_Utils_Tuple2('capbrcup', '⩉'),
			_Utils_Tuple2('capcap', '⩋'),
			_Utils_Tuple2('cap', '∩'),
			_Utils_Tuple2('Cap', '⋒'),
			_Utils_Tuple2('capcup', '⩇'),
			_Utils_Tuple2('capdot', '⩀'),
			_Utils_Tuple2('CapitalDifferentialD', 'ⅅ'),
			_Utils_Tuple2('caps', '∩︀'),
			_Utils_Tuple2('caret', '⁁'),
			_Utils_Tuple2('caron', 'ˇ'),
			_Utils_Tuple2('Cayleys', 'ℭ'),
			_Utils_Tuple2('ccaps', '⩍'),
			_Utils_Tuple2('Ccaron', 'Č'),
			_Utils_Tuple2('ccaron', 'č'),
			_Utils_Tuple2('Ccedil', 'Ç'),
			_Utils_Tuple2('ccedil', 'ç'),
			_Utils_Tuple2('Ccirc', 'Ĉ'),
			_Utils_Tuple2('ccirc', 'ĉ'),
			_Utils_Tuple2('Cconint', '∰'),
			_Utils_Tuple2('ccups', '⩌'),
			_Utils_Tuple2('ccupssm', '⩐'),
			_Utils_Tuple2('Cdot', 'Ċ'),
			_Utils_Tuple2('cdot', 'ċ'),
			_Utils_Tuple2('cedil', '¸'),
			_Utils_Tuple2('Cedilla', '¸'),
			_Utils_Tuple2('cemptyv', '⦲'),
			_Utils_Tuple2('cent', '¢'),
			_Utils_Tuple2('centerdot', '·'),
			_Utils_Tuple2('CenterDot', '·'),
			_Utils_Tuple2('cfr', '\uD835\uDD20'),
			_Utils_Tuple2('Cfr', 'ℭ'),
			_Utils_Tuple2('CHcy', 'Ч'),
			_Utils_Tuple2('chcy', 'ч'),
			_Utils_Tuple2('check', '✓'),
			_Utils_Tuple2('checkmark', '✓'),
			_Utils_Tuple2('Chi', 'Χ'),
			_Utils_Tuple2('chi', 'χ'),
			_Utils_Tuple2('circ', 'ˆ'),
			_Utils_Tuple2('circeq', '≗'),
			_Utils_Tuple2('circlearrowleft', '↺'),
			_Utils_Tuple2('circlearrowright', '↻'),
			_Utils_Tuple2('circledast', '⊛'),
			_Utils_Tuple2('circledcirc', '⊚'),
			_Utils_Tuple2('circleddash', '⊝'),
			_Utils_Tuple2('CircleDot', '⊙'),
			_Utils_Tuple2('circledR', '®'),
			_Utils_Tuple2('circledS', 'Ⓢ'),
			_Utils_Tuple2('CircleMinus', '⊖'),
			_Utils_Tuple2('CirclePlus', '⊕'),
			_Utils_Tuple2('CircleTimes', '⊗'),
			_Utils_Tuple2('cir', '○'),
			_Utils_Tuple2('cirE', '⧃'),
			_Utils_Tuple2('cire', '≗'),
			_Utils_Tuple2('cirfnint', '⨐'),
			_Utils_Tuple2('cirmid', '⫯'),
			_Utils_Tuple2('cirscir', '⧂'),
			_Utils_Tuple2('ClockwiseContourIntegral', '∲'),
			_Utils_Tuple2('CloseCurlyDoubleQuote', '”'),
			_Utils_Tuple2('CloseCurlyQuote', '’'),
			_Utils_Tuple2('clubs', '♣'),
			_Utils_Tuple2('clubsuit', '♣'),
			_Utils_Tuple2('colon', ':'),
			_Utils_Tuple2('Colon', '∷'),
			_Utils_Tuple2('Colone', '⩴'),
			_Utils_Tuple2('colone', '≔'),
			_Utils_Tuple2('coloneq', '≔'),
			_Utils_Tuple2('comma', ','),
			_Utils_Tuple2('commat', '@'),
			_Utils_Tuple2('comp', '∁'),
			_Utils_Tuple2('compfn', '∘'),
			_Utils_Tuple2('complement', '∁'),
			_Utils_Tuple2('complexes', 'ℂ'),
			_Utils_Tuple2('cong', '≅'),
			_Utils_Tuple2('congdot', '⩭'),
			_Utils_Tuple2('Congruent', '≡'),
			_Utils_Tuple2('conint', '∮'),
			_Utils_Tuple2('Conint', '∯'),
			_Utils_Tuple2('ContourIntegral', '∮'),
			_Utils_Tuple2('copf', '\uD835\uDD54'),
			_Utils_Tuple2('Copf', 'ℂ'),
			_Utils_Tuple2('coprod', '∐'),
			_Utils_Tuple2('Coproduct', '∐'),
			_Utils_Tuple2('copy', '©'),
			_Utils_Tuple2('COPY', '©'),
			_Utils_Tuple2('copysr', '℗'),
			_Utils_Tuple2('CounterClockwiseContourIntegral', '∳'),
			_Utils_Tuple2('crarr', '↵'),
			_Utils_Tuple2('cross', '✗'),
			_Utils_Tuple2('Cross', '⨯'),
			_Utils_Tuple2('Cscr', '\uD835\uDC9E'),
			_Utils_Tuple2('cscr', '\uD835\uDCB8'),
			_Utils_Tuple2('csub', '⫏'),
			_Utils_Tuple2('csube', '⫑'),
			_Utils_Tuple2('csup', '⫐'),
			_Utils_Tuple2('csupe', '⫒'),
			_Utils_Tuple2('ctdot', '⋯'),
			_Utils_Tuple2('cudarrl', '⤸'),
			_Utils_Tuple2('cudarrr', '⤵'),
			_Utils_Tuple2('cuepr', '⋞'),
			_Utils_Tuple2('cuesc', '⋟'),
			_Utils_Tuple2('cularr', '↶'),
			_Utils_Tuple2('cularrp', '⤽'),
			_Utils_Tuple2('cupbrcap', '⩈'),
			_Utils_Tuple2('cupcap', '⩆'),
			_Utils_Tuple2('CupCap', '≍'),
			_Utils_Tuple2('cup', '∪'),
			_Utils_Tuple2('Cup', '⋓'),
			_Utils_Tuple2('cupcup', '⩊'),
			_Utils_Tuple2('cupdot', '⊍'),
			_Utils_Tuple2('cupor', '⩅'),
			_Utils_Tuple2('cups', '∪︀'),
			_Utils_Tuple2('curarr', '↷'),
			_Utils_Tuple2('curarrm', '⤼'),
			_Utils_Tuple2('curlyeqprec', '⋞'),
			_Utils_Tuple2('curlyeqsucc', '⋟'),
			_Utils_Tuple2('curlyvee', '⋎'),
			_Utils_Tuple2('curlywedge', '⋏'),
			_Utils_Tuple2('curren', '¤'),
			_Utils_Tuple2('curvearrowleft', '↶'),
			_Utils_Tuple2('curvearrowright', '↷'),
			_Utils_Tuple2('cuvee', '⋎'),
			_Utils_Tuple2('cuwed', '⋏'),
			_Utils_Tuple2('cwconint', '∲'),
			_Utils_Tuple2('cwint', '∱'),
			_Utils_Tuple2('cylcty', '⌭'),
			_Utils_Tuple2('dagger', '†'),
			_Utils_Tuple2('Dagger', '‡'),
			_Utils_Tuple2('daleth', 'ℸ'),
			_Utils_Tuple2('darr', '↓'),
			_Utils_Tuple2('Darr', '↡'),
			_Utils_Tuple2('dArr', '⇓'),
			_Utils_Tuple2('dash', '‐'),
			_Utils_Tuple2('Dashv', '⫤'),
			_Utils_Tuple2('dashv', '⊣'),
			_Utils_Tuple2('dbkarow', '⤏'),
			_Utils_Tuple2('dblac', '˝'),
			_Utils_Tuple2('Dcaron', 'Ď'),
			_Utils_Tuple2('dcaron', 'ď'),
			_Utils_Tuple2('Dcy', 'Д'),
			_Utils_Tuple2('dcy', 'д'),
			_Utils_Tuple2('ddagger', '‡'),
			_Utils_Tuple2('ddarr', '⇊'),
			_Utils_Tuple2('DD', 'ⅅ'),
			_Utils_Tuple2('dd', 'ⅆ'),
			_Utils_Tuple2('DDotrahd', '⤑'),
			_Utils_Tuple2('ddotseq', '⩷'),
			_Utils_Tuple2('deg', '°'),
			_Utils_Tuple2('Del', '∇'),
			_Utils_Tuple2('Delta', 'Δ'),
			_Utils_Tuple2('delta', 'δ'),
			_Utils_Tuple2('demptyv', '⦱'),
			_Utils_Tuple2('dfisht', '⥿'),
			_Utils_Tuple2('Dfr', '\uD835\uDD07'),
			_Utils_Tuple2('dfr', '\uD835\uDD21'),
			_Utils_Tuple2('dHar', '⥥'),
			_Utils_Tuple2('dharl', '⇃'),
			_Utils_Tuple2('dharr', '⇂'),
			_Utils_Tuple2('DiacriticalAcute', '´'),
			_Utils_Tuple2('DiacriticalDot', '˙'),
			_Utils_Tuple2('DiacriticalDoubleAcute', '˝'),
			_Utils_Tuple2('DiacriticalGrave', '`'),
			_Utils_Tuple2('DiacriticalTilde', '˜'),
			_Utils_Tuple2('diam', '⋄'),
			_Utils_Tuple2('diamond', '⋄'),
			_Utils_Tuple2('Diamond', '⋄'),
			_Utils_Tuple2('diamondsuit', '♦'),
			_Utils_Tuple2('diams', '♦'),
			_Utils_Tuple2('die', '¨'),
			_Utils_Tuple2('DifferentialD', 'ⅆ'),
			_Utils_Tuple2('digamma', 'ϝ'),
			_Utils_Tuple2('disin', '⋲'),
			_Utils_Tuple2('div', '÷'),
			_Utils_Tuple2('divide', '÷'),
			_Utils_Tuple2('divideontimes', '⋇'),
			_Utils_Tuple2('divonx', '⋇'),
			_Utils_Tuple2('DJcy', 'Ђ'),
			_Utils_Tuple2('djcy', 'ђ'),
			_Utils_Tuple2('dlcorn', '⌞'),
			_Utils_Tuple2('dlcrop', '⌍'),
			_Utils_Tuple2('dollar', '$'),
			_Utils_Tuple2('Dopf', '\uD835\uDD3B'),
			_Utils_Tuple2('dopf', '\uD835\uDD55'),
			_Utils_Tuple2('Dot', '¨'),
			_Utils_Tuple2('dot', '˙'),
			_Utils_Tuple2('DotDot', '⃜'),
			_Utils_Tuple2('doteq', '≐'),
			_Utils_Tuple2('doteqdot', '≑'),
			_Utils_Tuple2('DotEqual', '≐'),
			_Utils_Tuple2('dotminus', '∸'),
			_Utils_Tuple2('dotplus', '∔'),
			_Utils_Tuple2('dotsquare', '⊡'),
			_Utils_Tuple2('doublebarwedge', '⌆'),
			_Utils_Tuple2('DoubleContourIntegral', '∯'),
			_Utils_Tuple2('DoubleDot', '¨'),
			_Utils_Tuple2('DoubleDownArrow', '⇓'),
			_Utils_Tuple2('DoubleLeftArrow', '⇐'),
			_Utils_Tuple2('DoubleLeftRightArrow', '⇔'),
			_Utils_Tuple2('DoubleLeftTee', '⫤'),
			_Utils_Tuple2('DoubleLongLeftArrow', '⟸'),
			_Utils_Tuple2('DoubleLongLeftRightArrow', '⟺'),
			_Utils_Tuple2('DoubleLongRightArrow', '⟹'),
			_Utils_Tuple2('DoubleRightArrow', '⇒'),
			_Utils_Tuple2('DoubleRightTee', '⊨'),
			_Utils_Tuple2('DoubleUpArrow', '⇑'),
			_Utils_Tuple2('DoubleUpDownArrow', '⇕'),
			_Utils_Tuple2('DoubleVerticalBar', '∥'),
			_Utils_Tuple2('DownArrowBar', '⤓'),
			_Utils_Tuple2('downarrow', '↓'),
			_Utils_Tuple2('DownArrow', '↓'),
			_Utils_Tuple2('Downarrow', '⇓'),
			_Utils_Tuple2('DownArrowUpArrow', '⇵'),
			_Utils_Tuple2('DownBreve', '̑'),
			_Utils_Tuple2('downdownarrows', '⇊'),
			_Utils_Tuple2('downharpoonleft', '⇃'),
			_Utils_Tuple2('downharpoonright', '⇂'),
			_Utils_Tuple2('DownLeftRightVector', '⥐'),
			_Utils_Tuple2('DownLeftTeeVector', '⥞'),
			_Utils_Tuple2('DownLeftVectorBar', '⥖'),
			_Utils_Tuple2('DownLeftVector', '↽'),
			_Utils_Tuple2('DownRightTeeVector', '⥟'),
			_Utils_Tuple2('DownRightVectorBar', '⥗'),
			_Utils_Tuple2('DownRightVector', '⇁'),
			_Utils_Tuple2('DownTeeArrow', '↧'),
			_Utils_Tuple2('DownTee', '⊤'),
			_Utils_Tuple2('drbkarow', '⤐'),
			_Utils_Tuple2('drcorn', '⌟'),
			_Utils_Tuple2('drcrop', '⌌'),
			_Utils_Tuple2('Dscr', '\uD835\uDC9F'),
			_Utils_Tuple2('dscr', '\uD835\uDCB9'),
			_Utils_Tuple2('DScy', 'Ѕ'),
			_Utils_Tuple2('dscy', 'ѕ'),
			_Utils_Tuple2('dsol', '⧶'),
			_Utils_Tuple2('Dstrok', 'Đ'),
			_Utils_Tuple2('dstrok', 'đ'),
			_Utils_Tuple2('dtdot', '⋱'),
			_Utils_Tuple2('dtri', '▿'),
			_Utils_Tuple2('dtrif', '▾'),
			_Utils_Tuple2('duarr', '⇵'),
			_Utils_Tuple2('duhar', '⥯'),
			_Utils_Tuple2('dwangle', '⦦'),
			_Utils_Tuple2('DZcy', 'Џ'),
			_Utils_Tuple2('dzcy', 'џ'),
			_Utils_Tuple2('dzigrarr', '⟿'),
			_Utils_Tuple2('Eacute', 'É'),
			_Utils_Tuple2('eacute', 'é'),
			_Utils_Tuple2('easter', '⩮'),
			_Utils_Tuple2('Ecaron', 'Ě'),
			_Utils_Tuple2('ecaron', 'ě'),
			_Utils_Tuple2('Ecirc', 'Ê'),
			_Utils_Tuple2('ecirc', 'ê'),
			_Utils_Tuple2('ecir', '≖'),
			_Utils_Tuple2('ecolon', '≕'),
			_Utils_Tuple2('Ecy', 'Э'),
			_Utils_Tuple2('ecy', 'э'),
			_Utils_Tuple2('eDDot', '⩷'),
			_Utils_Tuple2('Edot', 'Ė'),
			_Utils_Tuple2('edot', 'ė'),
			_Utils_Tuple2('eDot', '≑'),
			_Utils_Tuple2('ee', 'ⅇ'),
			_Utils_Tuple2('efDot', '≒'),
			_Utils_Tuple2('Efr', '\uD835\uDD08'),
			_Utils_Tuple2('efr', '\uD835\uDD22'),
			_Utils_Tuple2('eg', '⪚'),
			_Utils_Tuple2('Egrave', 'È'),
			_Utils_Tuple2('egrave', 'è'),
			_Utils_Tuple2('egs', '⪖'),
			_Utils_Tuple2('egsdot', '⪘'),
			_Utils_Tuple2('el', '⪙'),
			_Utils_Tuple2('Element', '∈'),
			_Utils_Tuple2('elinters', '⏧'),
			_Utils_Tuple2('ell', 'ℓ'),
			_Utils_Tuple2('els', '⪕'),
			_Utils_Tuple2('elsdot', '⪗'),
			_Utils_Tuple2('Emacr', 'Ē'),
			_Utils_Tuple2('emacr', 'ē'),
			_Utils_Tuple2('empty', '∅'),
			_Utils_Tuple2('emptyset', '∅'),
			_Utils_Tuple2('EmptySmallSquare', '◻'),
			_Utils_Tuple2('emptyv', '∅'),
			_Utils_Tuple2('EmptyVerySmallSquare', '▫'),
			_Utils_Tuple2('emsp13', '\u2004'),
			_Utils_Tuple2('emsp14', '\u2005'),
			_Utils_Tuple2('emsp', '\u2003'),
			_Utils_Tuple2('ENG', 'Ŋ'),
			_Utils_Tuple2('eng', 'ŋ'),
			_Utils_Tuple2('ensp', '\u2002'),
			_Utils_Tuple2('Eogon', 'Ę'),
			_Utils_Tuple2('eogon', 'ę'),
			_Utils_Tuple2('Eopf', '\uD835\uDD3C'),
			_Utils_Tuple2('eopf', '\uD835\uDD56'),
			_Utils_Tuple2('epar', '⋕'),
			_Utils_Tuple2('eparsl', '⧣'),
			_Utils_Tuple2('eplus', '⩱'),
			_Utils_Tuple2('epsi', 'ε'),
			_Utils_Tuple2('Epsilon', 'Ε'),
			_Utils_Tuple2('epsilon', 'ε'),
			_Utils_Tuple2('epsiv', 'ϵ'),
			_Utils_Tuple2('eqcirc', '≖'),
			_Utils_Tuple2('eqcolon', '≕'),
			_Utils_Tuple2('eqsim', '≂'),
			_Utils_Tuple2('eqslantgtr', '⪖'),
			_Utils_Tuple2('eqslantless', '⪕'),
			_Utils_Tuple2('Equal', '⩵'),
			_Utils_Tuple2('equals', '='),
			_Utils_Tuple2('EqualTilde', '≂'),
			_Utils_Tuple2('equest', '≟'),
			_Utils_Tuple2('Equilibrium', '⇌'),
			_Utils_Tuple2('equiv', '≡'),
			_Utils_Tuple2('equivDD', '⩸'),
			_Utils_Tuple2('eqvparsl', '⧥'),
			_Utils_Tuple2('erarr', '⥱'),
			_Utils_Tuple2('erDot', '≓'),
			_Utils_Tuple2('escr', 'ℯ'),
			_Utils_Tuple2('Escr', 'ℰ'),
			_Utils_Tuple2('esdot', '≐'),
			_Utils_Tuple2('Esim', '⩳'),
			_Utils_Tuple2('esim', '≂'),
			_Utils_Tuple2('Eta', 'Η'),
			_Utils_Tuple2('eta', 'η'),
			_Utils_Tuple2('ETH', 'Ð'),
			_Utils_Tuple2('eth', 'ð'),
			_Utils_Tuple2('Euml', 'Ë'),
			_Utils_Tuple2('euml', 'ë'),
			_Utils_Tuple2('euro', '€'),
			_Utils_Tuple2('excl', '!'),
			_Utils_Tuple2('exist', '∃'),
			_Utils_Tuple2('Exists', '∃'),
			_Utils_Tuple2('expectation', 'ℰ'),
			_Utils_Tuple2('exponentiale', 'ⅇ'),
			_Utils_Tuple2('ExponentialE', 'ⅇ'),
			_Utils_Tuple2('fallingdotseq', '≒'),
			_Utils_Tuple2('Fcy', 'Ф'),
			_Utils_Tuple2('fcy', 'ф'),
			_Utils_Tuple2('female', '♀'),
			_Utils_Tuple2('ffilig', 'ﬃ'),
			_Utils_Tuple2('fflig', 'ﬀ'),
			_Utils_Tuple2('ffllig', 'ﬄ'),
			_Utils_Tuple2('Ffr', '\uD835\uDD09'),
			_Utils_Tuple2('ffr', '\uD835\uDD23'),
			_Utils_Tuple2('filig', 'ﬁ'),
			_Utils_Tuple2('FilledSmallSquare', '◼'),
			_Utils_Tuple2('FilledVerySmallSquare', '▪'),
			_Utils_Tuple2('fjlig', 'fj'),
			_Utils_Tuple2('flat', '♭'),
			_Utils_Tuple2('fllig', 'ﬂ'),
			_Utils_Tuple2('fltns', '▱'),
			_Utils_Tuple2('fnof', 'ƒ'),
			_Utils_Tuple2('Fopf', '\uD835\uDD3D'),
			_Utils_Tuple2('fopf', '\uD835\uDD57'),
			_Utils_Tuple2('forall', '∀'),
			_Utils_Tuple2('ForAll', '∀'),
			_Utils_Tuple2('fork', '⋔'),
			_Utils_Tuple2('forkv', '⫙'),
			_Utils_Tuple2('Fouriertrf', 'ℱ'),
			_Utils_Tuple2('fpartint', '⨍'),
			_Utils_Tuple2('frac12', '½'),
			_Utils_Tuple2('frac13', '⅓'),
			_Utils_Tuple2('frac14', '¼'),
			_Utils_Tuple2('frac15', '⅕'),
			_Utils_Tuple2('frac16', '⅙'),
			_Utils_Tuple2('frac18', '⅛'),
			_Utils_Tuple2('frac23', '⅔'),
			_Utils_Tuple2('frac25', '⅖'),
			_Utils_Tuple2('frac34', '¾'),
			_Utils_Tuple2('frac35', '⅗'),
			_Utils_Tuple2('frac38', '⅜'),
			_Utils_Tuple2('frac45', '⅘'),
			_Utils_Tuple2('frac56', '⅚'),
			_Utils_Tuple2('frac58', '⅝'),
			_Utils_Tuple2('frac78', '⅞'),
			_Utils_Tuple2('frasl', '⁄'),
			_Utils_Tuple2('frown', '⌢'),
			_Utils_Tuple2('fscr', '\uD835\uDCBB'),
			_Utils_Tuple2('Fscr', 'ℱ'),
			_Utils_Tuple2('gacute', 'ǵ'),
			_Utils_Tuple2('Gamma', 'Γ'),
			_Utils_Tuple2('gamma', 'γ'),
			_Utils_Tuple2('Gammad', 'Ϝ'),
			_Utils_Tuple2('gammad', 'ϝ'),
			_Utils_Tuple2('gap', '⪆'),
			_Utils_Tuple2('Gbreve', 'Ğ'),
			_Utils_Tuple2('gbreve', 'ğ'),
			_Utils_Tuple2('Gcedil', 'Ģ'),
			_Utils_Tuple2('Gcirc', 'Ĝ'),
			_Utils_Tuple2('gcirc', 'ĝ'),
			_Utils_Tuple2('Gcy', 'Г'),
			_Utils_Tuple2('gcy', 'г'),
			_Utils_Tuple2('Gdot', 'Ġ'),
			_Utils_Tuple2('gdot', 'ġ'),
			_Utils_Tuple2('ge', '≥'),
			_Utils_Tuple2('gE', '≧'),
			_Utils_Tuple2('gEl', '⪌'),
			_Utils_Tuple2('gel', '⋛'),
			_Utils_Tuple2('geq', '≥'),
			_Utils_Tuple2('geqq', '≧'),
			_Utils_Tuple2('geqslant', '⩾'),
			_Utils_Tuple2('gescc', '⪩'),
			_Utils_Tuple2('ges', '⩾'),
			_Utils_Tuple2('gesdot', '⪀'),
			_Utils_Tuple2('gesdoto', '⪂'),
			_Utils_Tuple2('gesdotol', '⪄'),
			_Utils_Tuple2('gesl', '⋛︀'),
			_Utils_Tuple2('gesles', '⪔'),
			_Utils_Tuple2('Gfr', '\uD835\uDD0A'),
			_Utils_Tuple2('gfr', '\uD835\uDD24'),
			_Utils_Tuple2('gg', '≫'),
			_Utils_Tuple2('Gg', '⋙'),
			_Utils_Tuple2('ggg', '⋙'),
			_Utils_Tuple2('gimel', 'ℷ'),
			_Utils_Tuple2('GJcy', 'Ѓ'),
			_Utils_Tuple2('gjcy', 'ѓ'),
			_Utils_Tuple2('gla', '⪥'),
			_Utils_Tuple2('gl', '≷'),
			_Utils_Tuple2('glE', '⪒'),
			_Utils_Tuple2('glj', '⪤'),
			_Utils_Tuple2('gnap', '⪊'),
			_Utils_Tuple2('gnapprox', '⪊'),
			_Utils_Tuple2('gne', '⪈'),
			_Utils_Tuple2('gnE', '≩'),
			_Utils_Tuple2('gneq', '⪈'),
			_Utils_Tuple2('gneqq', '≩'),
			_Utils_Tuple2('gnsim', '⋧'),
			_Utils_Tuple2('Gopf', '\uD835\uDD3E'),
			_Utils_Tuple2('gopf', '\uD835\uDD58'),
			_Utils_Tuple2('grave', '`'),
			_Utils_Tuple2('GreaterEqual', '≥'),
			_Utils_Tuple2('GreaterEqualLess', '⋛'),
			_Utils_Tuple2('GreaterFullEqual', '≧'),
			_Utils_Tuple2('GreaterGreater', '⪢'),
			_Utils_Tuple2('GreaterLess', '≷'),
			_Utils_Tuple2('GreaterSlantEqual', '⩾'),
			_Utils_Tuple2('GreaterTilde', '≳'),
			_Utils_Tuple2('Gscr', '\uD835\uDCA2'),
			_Utils_Tuple2('gscr', 'ℊ'),
			_Utils_Tuple2('gsim', '≳'),
			_Utils_Tuple2('gsime', '⪎'),
			_Utils_Tuple2('gsiml', '⪐'),
			_Utils_Tuple2('gtcc', '⪧'),
			_Utils_Tuple2('gtcir', '⩺'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('GT', '>'),
			_Utils_Tuple2('Gt', '≫'),
			_Utils_Tuple2('gtdot', '⋗'),
			_Utils_Tuple2('gtlPar', '⦕'),
			_Utils_Tuple2('gtquest', '⩼'),
			_Utils_Tuple2('gtrapprox', '⪆'),
			_Utils_Tuple2('gtrarr', '⥸'),
			_Utils_Tuple2('gtrdot', '⋗'),
			_Utils_Tuple2('gtreqless', '⋛'),
			_Utils_Tuple2('gtreqqless', '⪌'),
			_Utils_Tuple2('gtrless', '≷'),
			_Utils_Tuple2('gtrsim', '≳'),
			_Utils_Tuple2('gvertneqq', '≩︀'),
			_Utils_Tuple2('gvnE', '≩︀'),
			_Utils_Tuple2('Hacek', 'ˇ'),
			_Utils_Tuple2('hairsp', '\u200A'),
			_Utils_Tuple2('half', '½'),
			_Utils_Tuple2('hamilt', 'ℋ'),
			_Utils_Tuple2('HARDcy', 'Ъ'),
			_Utils_Tuple2('hardcy', 'ъ'),
			_Utils_Tuple2('harrcir', '⥈'),
			_Utils_Tuple2('harr', '↔'),
			_Utils_Tuple2('hArr', '⇔'),
			_Utils_Tuple2('harrw', '↭'),
			_Utils_Tuple2('Hat', '^'),
			_Utils_Tuple2('hbar', 'ℏ'),
			_Utils_Tuple2('Hcirc', 'Ĥ'),
			_Utils_Tuple2('hcirc', 'ĥ'),
			_Utils_Tuple2('hearts', '♥'),
			_Utils_Tuple2('heartsuit', '♥'),
			_Utils_Tuple2('hellip', '…'),
			_Utils_Tuple2('hercon', '⊹'),
			_Utils_Tuple2('hfr', '\uD835\uDD25'),
			_Utils_Tuple2('Hfr', 'ℌ'),
			_Utils_Tuple2('HilbertSpace', 'ℋ'),
			_Utils_Tuple2('hksearow', '⤥'),
			_Utils_Tuple2('hkswarow', '⤦'),
			_Utils_Tuple2('hoarr', '⇿'),
			_Utils_Tuple2('homtht', '∻'),
			_Utils_Tuple2('hookleftarrow', '↩'),
			_Utils_Tuple2('hookrightarrow', '↪'),
			_Utils_Tuple2('hopf', '\uD835\uDD59'),
			_Utils_Tuple2('Hopf', 'ℍ'),
			_Utils_Tuple2('horbar', '―'),
			_Utils_Tuple2('HorizontalLine', '─'),
			_Utils_Tuple2('hscr', '\uD835\uDCBD'),
			_Utils_Tuple2('Hscr', 'ℋ'),
			_Utils_Tuple2('hslash', 'ℏ'),
			_Utils_Tuple2('Hstrok', 'Ħ'),
			_Utils_Tuple2('hstrok', 'ħ'),
			_Utils_Tuple2('HumpDownHump', '≎'),
			_Utils_Tuple2('HumpEqual', '≏'),
			_Utils_Tuple2('hybull', '⁃'),
			_Utils_Tuple2('hyphen', '‐'),
			_Utils_Tuple2('Iacute', 'Í'),
			_Utils_Tuple2('iacute', 'í'),
			_Utils_Tuple2('ic', '\u2063'),
			_Utils_Tuple2('Icirc', 'Î'),
			_Utils_Tuple2('icirc', 'î'),
			_Utils_Tuple2('Icy', 'И'),
			_Utils_Tuple2('icy', 'и'),
			_Utils_Tuple2('Idot', 'İ'),
			_Utils_Tuple2('IEcy', 'Е'),
			_Utils_Tuple2('iecy', 'е'),
			_Utils_Tuple2('iexcl', '¡'),
			_Utils_Tuple2('iff', '⇔'),
			_Utils_Tuple2('ifr', '\uD835\uDD26'),
			_Utils_Tuple2('Ifr', 'ℑ'),
			_Utils_Tuple2('Igrave', 'Ì'),
			_Utils_Tuple2('igrave', 'ì'),
			_Utils_Tuple2('ii', 'ⅈ'),
			_Utils_Tuple2('iiiint', '⨌'),
			_Utils_Tuple2('iiint', '∭'),
			_Utils_Tuple2('iinfin', '⧜'),
			_Utils_Tuple2('iiota', '℩'),
			_Utils_Tuple2('IJlig', 'Ĳ'),
			_Utils_Tuple2('ijlig', 'ĳ'),
			_Utils_Tuple2('Imacr', 'Ī'),
			_Utils_Tuple2('imacr', 'ī'),
			_Utils_Tuple2('image', 'ℑ'),
			_Utils_Tuple2('ImaginaryI', 'ⅈ'),
			_Utils_Tuple2('imagline', 'ℐ'),
			_Utils_Tuple2('imagpart', 'ℑ'),
			_Utils_Tuple2('imath', 'ı'),
			_Utils_Tuple2('Im', 'ℑ'),
			_Utils_Tuple2('imof', '⊷'),
			_Utils_Tuple2('imped', 'Ƶ'),
			_Utils_Tuple2('Implies', '⇒'),
			_Utils_Tuple2('incare', '℅'),
			_Utils_Tuple2('in', '∈'),
			_Utils_Tuple2('infin', '∞'),
			_Utils_Tuple2('infintie', '⧝'),
			_Utils_Tuple2('inodot', 'ı'),
			_Utils_Tuple2('intcal', '⊺'),
			_Utils_Tuple2('int', '∫'),
			_Utils_Tuple2('Int', '∬'),
			_Utils_Tuple2('integers', 'ℤ'),
			_Utils_Tuple2('Integral', '∫'),
			_Utils_Tuple2('intercal', '⊺'),
			_Utils_Tuple2('Intersection', '⋂'),
			_Utils_Tuple2('intlarhk', '⨗'),
			_Utils_Tuple2('intprod', '⨼'),
			_Utils_Tuple2('InvisibleComma', '\u2063'),
			_Utils_Tuple2('InvisibleTimes', '\u2062'),
			_Utils_Tuple2('IOcy', 'Ё'),
			_Utils_Tuple2('iocy', 'ё'),
			_Utils_Tuple2('Iogon', 'Į'),
			_Utils_Tuple2('iogon', 'į'),
			_Utils_Tuple2('Iopf', '\uD835\uDD40'),
			_Utils_Tuple2('iopf', '\uD835\uDD5A'),
			_Utils_Tuple2('Iota', 'Ι'),
			_Utils_Tuple2('iota', 'ι'),
			_Utils_Tuple2('iprod', '⨼'),
			_Utils_Tuple2('iquest', '¿'),
			_Utils_Tuple2('iscr', '\uD835\uDCBE'),
			_Utils_Tuple2('Iscr', 'ℐ'),
			_Utils_Tuple2('isin', '∈'),
			_Utils_Tuple2('isindot', '⋵'),
			_Utils_Tuple2('isinE', '⋹'),
			_Utils_Tuple2('isins', '⋴'),
			_Utils_Tuple2('isinsv', '⋳'),
			_Utils_Tuple2('isinv', '∈'),
			_Utils_Tuple2('it', '\u2062'),
			_Utils_Tuple2('Itilde', 'Ĩ'),
			_Utils_Tuple2('itilde', 'ĩ'),
			_Utils_Tuple2('Iukcy', 'І'),
			_Utils_Tuple2('iukcy', 'і'),
			_Utils_Tuple2('Iuml', 'Ï'),
			_Utils_Tuple2('iuml', 'ï'),
			_Utils_Tuple2('Jcirc', 'Ĵ'),
			_Utils_Tuple2('jcirc', 'ĵ'),
			_Utils_Tuple2('Jcy', 'Й'),
			_Utils_Tuple2('jcy', 'й'),
			_Utils_Tuple2('Jfr', '\uD835\uDD0D'),
			_Utils_Tuple2('jfr', '\uD835\uDD27'),
			_Utils_Tuple2('jmath', 'ȷ'),
			_Utils_Tuple2('Jopf', '\uD835\uDD41'),
			_Utils_Tuple2('jopf', '\uD835\uDD5B'),
			_Utils_Tuple2('Jscr', '\uD835\uDCA5'),
			_Utils_Tuple2('jscr', '\uD835\uDCBF'),
			_Utils_Tuple2('Jsercy', 'Ј'),
			_Utils_Tuple2('jsercy', 'ј'),
			_Utils_Tuple2('Jukcy', 'Є'),
			_Utils_Tuple2('jukcy', 'є'),
			_Utils_Tuple2('Kappa', 'Κ'),
			_Utils_Tuple2('kappa', 'κ'),
			_Utils_Tuple2('kappav', 'ϰ'),
			_Utils_Tuple2('Kcedil', 'Ķ'),
			_Utils_Tuple2('kcedil', 'ķ'),
			_Utils_Tuple2('Kcy', 'К'),
			_Utils_Tuple2('kcy', 'к'),
			_Utils_Tuple2('Kfr', '\uD835\uDD0E'),
			_Utils_Tuple2('kfr', '\uD835\uDD28'),
			_Utils_Tuple2('kgreen', 'ĸ'),
			_Utils_Tuple2('KHcy', 'Х'),
			_Utils_Tuple2('khcy', 'х'),
			_Utils_Tuple2('KJcy', 'Ќ'),
			_Utils_Tuple2('kjcy', 'ќ'),
			_Utils_Tuple2('Kopf', '\uD835\uDD42'),
			_Utils_Tuple2('kopf', '\uD835\uDD5C'),
			_Utils_Tuple2('Kscr', '\uD835\uDCA6'),
			_Utils_Tuple2('kscr', '\uD835\uDCC0'),
			_Utils_Tuple2('lAarr', '⇚'),
			_Utils_Tuple2('Lacute', 'Ĺ'),
			_Utils_Tuple2('lacute', 'ĺ'),
			_Utils_Tuple2('laemptyv', '⦴'),
			_Utils_Tuple2('lagran', 'ℒ'),
			_Utils_Tuple2('Lambda', 'Λ'),
			_Utils_Tuple2('lambda', 'λ'),
			_Utils_Tuple2('lang', '⟨'),
			_Utils_Tuple2('Lang', '⟪'),
			_Utils_Tuple2('langd', '⦑'),
			_Utils_Tuple2('langle', '⟨'),
			_Utils_Tuple2('lap', '⪅'),
			_Utils_Tuple2('Laplacetrf', 'ℒ'),
			_Utils_Tuple2('laquo', '«'),
			_Utils_Tuple2('larrb', '⇤'),
			_Utils_Tuple2('larrbfs', '⤟'),
			_Utils_Tuple2('larr', '←'),
			_Utils_Tuple2('Larr', '↞'),
			_Utils_Tuple2('lArr', '⇐'),
			_Utils_Tuple2('larrfs', '⤝'),
			_Utils_Tuple2('larrhk', '↩'),
			_Utils_Tuple2('larrlp', '↫'),
			_Utils_Tuple2('larrpl', '⤹'),
			_Utils_Tuple2('larrsim', '⥳'),
			_Utils_Tuple2('larrtl', '↢'),
			_Utils_Tuple2('latail', '⤙'),
			_Utils_Tuple2('lAtail', '⤛'),
			_Utils_Tuple2('lat', '⪫'),
			_Utils_Tuple2('late', '⪭'),
			_Utils_Tuple2('lates', '⪭︀'),
			_Utils_Tuple2('lbarr', '⤌'),
			_Utils_Tuple2('lBarr', '⤎'),
			_Utils_Tuple2('lbbrk', '❲'),
			_Utils_Tuple2('lbrace', '{'),
			_Utils_Tuple2('lbrack', '['),
			_Utils_Tuple2('lbrke', '⦋'),
			_Utils_Tuple2('lbrksld', '⦏'),
			_Utils_Tuple2('lbrkslu', '⦍'),
			_Utils_Tuple2('Lcaron', 'Ľ'),
			_Utils_Tuple2('lcaron', 'ľ'),
			_Utils_Tuple2('Lcedil', 'Ļ'),
			_Utils_Tuple2('lcedil', 'ļ'),
			_Utils_Tuple2('lceil', '⌈'),
			_Utils_Tuple2('lcub', '{'),
			_Utils_Tuple2('Lcy', 'Л'),
			_Utils_Tuple2('lcy', 'л'),
			_Utils_Tuple2('ldca', '⤶'),
			_Utils_Tuple2('ldquo', '“'),
			_Utils_Tuple2('ldquor', '„'),
			_Utils_Tuple2('ldrdhar', '⥧'),
			_Utils_Tuple2('ldrushar', '⥋'),
			_Utils_Tuple2('ldsh', '↲'),
			_Utils_Tuple2('le', '≤'),
			_Utils_Tuple2('lE', '≦'),
			_Utils_Tuple2('LeftAngleBracket', '⟨'),
			_Utils_Tuple2('LeftArrowBar', '⇤'),
			_Utils_Tuple2('leftarrow', '←'),
			_Utils_Tuple2('LeftArrow', '←'),
			_Utils_Tuple2('Leftarrow', '⇐'),
			_Utils_Tuple2('LeftArrowRightArrow', '⇆'),
			_Utils_Tuple2('leftarrowtail', '↢'),
			_Utils_Tuple2('LeftCeiling', '⌈'),
			_Utils_Tuple2('LeftDoubleBracket', '⟦'),
			_Utils_Tuple2('LeftDownTeeVector', '⥡'),
			_Utils_Tuple2('LeftDownVectorBar', '⥙'),
			_Utils_Tuple2('LeftDownVector', '⇃'),
			_Utils_Tuple2('LeftFloor', '⌊'),
			_Utils_Tuple2('leftharpoondown', '↽'),
			_Utils_Tuple2('leftharpoonup', '↼'),
			_Utils_Tuple2('leftleftarrows', '⇇'),
			_Utils_Tuple2('leftrightarrow', '↔'),
			_Utils_Tuple2('LeftRightArrow', '↔'),
			_Utils_Tuple2('Leftrightarrow', '⇔'),
			_Utils_Tuple2('leftrightarrows', '⇆'),
			_Utils_Tuple2('leftrightharpoons', '⇋'),
			_Utils_Tuple2('leftrightsquigarrow', '↭'),
			_Utils_Tuple2('LeftRightVector', '⥎'),
			_Utils_Tuple2('LeftTeeArrow', '↤'),
			_Utils_Tuple2('LeftTee', '⊣'),
			_Utils_Tuple2('LeftTeeVector', '⥚'),
			_Utils_Tuple2('leftthreetimes', '⋋'),
			_Utils_Tuple2('LeftTriangleBar', '⧏'),
			_Utils_Tuple2('LeftTriangle', '⊲'),
			_Utils_Tuple2('LeftTriangleEqual', '⊴'),
			_Utils_Tuple2('LeftUpDownVector', '⥑'),
			_Utils_Tuple2('LeftUpTeeVector', '⥠'),
			_Utils_Tuple2('LeftUpVectorBar', '⥘'),
			_Utils_Tuple2('LeftUpVector', '↿'),
			_Utils_Tuple2('LeftVectorBar', '⥒'),
			_Utils_Tuple2('LeftVector', '↼'),
			_Utils_Tuple2('lEg', '⪋'),
			_Utils_Tuple2('leg', '⋚'),
			_Utils_Tuple2('leq', '≤'),
			_Utils_Tuple2('leqq', '≦'),
			_Utils_Tuple2('leqslant', '⩽'),
			_Utils_Tuple2('lescc', '⪨'),
			_Utils_Tuple2('les', '⩽'),
			_Utils_Tuple2('lesdot', '⩿'),
			_Utils_Tuple2('lesdoto', '⪁'),
			_Utils_Tuple2('lesdotor', '⪃'),
			_Utils_Tuple2('lesg', '⋚︀'),
			_Utils_Tuple2('lesges', '⪓'),
			_Utils_Tuple2('lessapprox', '⪅'),
			_Utils_Tuple2('lessdot', '⋖'),
			_Utils_Tuple2('lesseqgtr', '⋚'),
			_Utils_Tuple2('lesseqqgtr', '⪋'),
			_Utils_Tuple2('LessEqualGreater', '⋚'),
			_Utils_Tuple2('LessFullEqual', '≦'),
			_Utils_Tuple2('LessGreater', '≶'),
			_Utils_Tuple2('lessgtr', '≶'),
			_Utils_Tuple2('LessLess', '⪡'),
			_Utils_Tuple2('lesssim', '≲'),
			_Utils_Tuple2('LessSlantEqual', '⩽'),
			_Utils_Tuple2('LessTilde', '≲'),
			_Utils_Tuple2('lfisht', '⥼'),
			_Utils_Tuple2('lfloor', '⌊'),
			_Utils_Tuple2('Lfr', '\uD835\uDD0F'),
			_Utils_Tuple2('lfr', '\uD835\uDD29'),
			_Utils_Tuple2('lg', '≶'),
			_Utils_Tuple2('lgE', '⪑'),
			_Utils_Tuple2('lHar', '⥢'),
			_Utils_Tuple2('lhard', '↽'),
			_Utils_Tuple2('lharu', '↼'),
			_Utils_Tuple2('lharul', '⥪'),
			_Utils_Tuple2('lhblk', '▄'),
			_Utils_Tuple2('LJcy', 'Љ'),
			_Utils_Tuple2('ljcy', 'љ'),
			_Utils_Tuple2('llarr', '⇇'),
			_Utils_Tuple2('ll', '≪'),
			_Utils_Tuple2('Ll', '⋘'),
			_Utils_Tuple2('llcorner', '⌞'),
			_Utils_Tuple2('Lleftarrow', '⇚'),
			_Utils_Tuple2('llhard', '⥫'),
			_Utils_Tuple2('lltri', '◺'),
			_Utils_Tuple2('Lmidot', 'Ŀ'),
			_Utils_Tuple2('lmidot', 'ŀ'),
			_Utils_Tuple2('lmoustache', '⎰'),
			_Utils_Tuple2('lmoust', '⎰'),
			_Utils_Tuple2('lnap', '⪉'),
			_Utils_Tuple2('lnapprox', '⪉'),
			_Utils_Tuple2('lne', '⪇'),
			_Utils_Tuple2('lnE', '≨'),
			_Utils_Tuple2('lneq', '⪇'),
			_Utils_Tuple2('lneqq', '≨'),
			_Utils_Tuple2('lnsim', '⋦'),
			_Utils_Tuple2('loang', '⟬'),
			_Utils_Tuple2('loarr', '⇽'),
			_Utils_Tuple2('lobrk', '⟦'),
			_Utils_Tuple2('longleftarrow', '⟵'),
			_Utils_Tuple2('LongLeftArrow', '⟵'),
			_Utils_Tuple2('Longleftarrow', '⟸'),
			_Utils_Tuple2('longleftrightarrow', '⟷'),
			_Utils_Tuple2('LongLeftRightArrow', '⟷'),
			_Utils_Tuple2('Longleftrightarrow', '⟺'),
			_Utils_Tuple2('longmapsto', '⟼'),
			_Utils_Tuple2('longrightarrow', '⟶'),
			_Utils_Tuple2('LongRightArrow', '⟶'),
			_Utils_Tuple2('Longrightarrow', '⟹'),
			_Utils_Tuple2('looparrowleft', '↫'),
			_Utils_Tuple2('looparrowright', '↬'),
			_Utils_Tuple2('lopar', '⦅'),
			_Utils_Tuple2('Lopf', '\uD835\uDD43'),
			_Utils_Tuple2('lopf', '\uD835\uDD5D'),
			_Utils_Tuple2('loplus', '⨭'),
			_Utils_Tuple2('lotimes', '⨴'),
			_Utils_Tuple2('lowast', '∗'),
			_Utils_Tuple2('lowbar', '_'),
			_Utils_Tuple2('LowerLeftArrow', '↙'),
			_Utils_Tuple2('LowerRightArrow', '↘'),
			_Utils_Tuple2('loz', '◊'),
			_Utils_Tuple2('lozenge', '◊'),
			_Utils_Tuple2('lozf', '⧫'),
			_Utils_Tuple2('lpar', '('),
			_Utils_Tuple2('lparlt', '⦓'),
			_Utils_Tuple2('lrarr', '⇆'),
			_Utils_Tuple2('lrcorner', '⌟'),
			_Utils_Tuple2('lrhar', '⇋'),
			_Utils_Tuple2('lrhard', '⥭'),
			_Utils_Tuple2('lrm', '\u200E'),
			_Utils_Tuple2('lrtri', '⊿'),
			_Utils_Tuple2('lsaquo', '‹'),
			_Utils_Tuple2('lscr', '\uD835\uDCC1'),
			_Utils_Tuple2('Lscr', 'ℒ'),
			_Utils_Tuple2('lsh', '↰'),
			_Utils_Tuple2('Lsh', '↰'),
			_Utils_Tuple2('lsim', '≲'),
			_Utils_Tuple2('lsime', '⪍'),
			_Utils_Tuple2('lsimg', '⪏'),
			_Utils_Tuple2('lsqb', '['),
			_Utils_Tuple2('lsquo', '‘'),
			_Utils_Tuple2('lsquor', '‚'),
			_Utils_Tuple2('Lstrok', 'Ł'),
			_Utils_Tuple2('lstrok', 'ł'),
			_Utils_Tuple2('ltcc', '⪦'),
			_Utils_Tuple2('ltcir', '⩹'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('LT', '<'),
			_Utils_Tuple2('Lt', '≪'),
			_Utils_Tuple2('ltdot', '⋖'),
			_Utils_Tuple2('lthree', '⋋'),
			_Utils_Tuple2('ltimes', '⋉'),
			_Utils_Tuple2('ltlarr', '⥶'),
			_Utils_Tuple2('ltquest', '⩻'),
			_Utils_Tuple2('ltri', '◃'),
			_Utils_Tuple2('ltrie', '⊴'),
			_Utils_Tuple2('ltrif', '◂'),
			_Utils_Tuple2('ltrPar', '⦖'),
			_Utils_Tuple2('lurdshar', '⥊'),
			_Utils_Tuple2('luruhar', '⥦'),
			_Utils_Tuple2('lvertneqq', '≨︀'),
			_Utils_Tuple2('lvnE', '≨︀'),
			_Utils_Tuple2('macr', '¯'),
			_Utils_Tuple2('male', '♂'),
			_Utils_Tuple2('malt', '✠'),
			_Utils_Tuple2('maltese', '✠'),
			_Utils_Tuple2('Map', '⤅'),
			_Utils_Tuple2('map', '↦'),
			_Utils_Tuple2('mapsto', '↦'),
			_Utils_Tuple2('mapstodown', '↧'),
			_Utils_Tuple2('mapstoleft', '↤'),
			_Utils_Tuple2('mapstoup', '↥'),
			_Utils_Tuple2('marker', '▮'),
			_Utils_Tuple2('mcomma', '⨩'),
			_Utils_Tuple2('Mcy', 'М'),
			_Utils_Tuple2('mcy', 'м'),
			_Utils_Tuple2('mdash', '—'),
			_Utils_Tuple2('mDDot', '∺'),
			_Utils_Tuple2('measuredangle', '∡'),
			_Utils_Tuple2('MediumSpace', '\u205F'),
			_Utils_Tuple2('Mellintrf', 'ℳ'),
			_Utils_Tuple2('Mfr', '\uD835\uDD10'),
			_Utils_Tuple2('mfr', '\uD835\uDD2A'),
			_Utils_Tuple2('mho', '℧'),
			_Utils_Tuple2('micro', 'µ'),
			_Utils_Tuple2('midast', '*'),
			_Utils_Tuple2('midcir', '⫰'),
			_Utils_Tuple2('mid', '∣'),
			_Utils_Tuple2('middot', '·'),
			_Utils_Tuple2('minusb', '⊟'),
			_Utils_Tuple2('minus', '−'),
			_Utils_Tuple2('minusd', '∸'),
			_Utils_Tuple2('minusdu', '⨪'),
			_Utils_Tuple2('MinusPlus', '∓'),
			_Utils_Tuple2('mlcp', '⫛'),
			_Utils_Tuple2('mldr', '…'),
			_Utils_Tuple2('mnplus', '∓'),
			_Utils_Tuple2('models', '⊧'),
			_Utils_Tuple2('Mopf', '\uD835\uDD44'),
			_Utils_Tuple2('mopf', '\uD835\uDD5E'),
			_Utils_Tuple2('mp', '∓'),
			_Utils_Tuple2('mscr', '\uD835\uDCC2'),
			_Utils_Tuple2('Mscr', 'ℳ'),
			_Utils_Tuple2('mstpos', '∾'),
			_Utils_Tuple2('Mu', 'Μ'),
			_Utils_Tuple2('mu', 'μ'),
			_Utils_Tuple2('multimap', '⊸'),
			_Utils_Tuple2('mumap', '⊸'),
			_Utils_Tuple2('nabla', '∇'),
			_Utils_Tuple2('Nacute', 'Ń'),
			_Utils_Tuple2('nacute', 'ń'),
			_Utils_Tuple2('nang', '∠⃒'),
			_Utils_Tuple2('nap', '≉'),
			_Utils_Tuple2('napE', '⩰̸'),
			_Utils_Tuple2('napid', '≋̸'),
			_Utils_Tuple2('napos', 'ŉ'),
			_Utils_Tuple2('napprox', '≉'),
			_Utils_Tuple2('natural', '♮'),
			_Utils_Tuple2('naturals', 'ℕ'),
			_Utils_Tuple2('natur', '♮'),
			_Utils_Tuple2('nbsp', '\u00A0'),
			_Utils_Tuple2('nbump', '≎̸'),
			_Utils_Tuple2('nbumpe', '≏̸'),
			_Utils_Tuple2('ncap', '⩃'),
			_Utils_Tuple2('Ncaron', 'Ň'),
			_Utils_Tuple2('ncaron', 'ň'),
			_Utils_Tuple2('Ncedil', 'Ņ'),
			_Utils_Tuple2('ncedil', 'ņ'),
			_Utils_Tuple2('ncong', '≇'),
			_Utils_Tuple2('ncongdot', '⩭̸'),
			_Utils_Tuple2('ncup', '⩂'),
			_Utils_Tuple2('Ncy', 'Н'),
			_Utils_Tuple2('ncy', 'н'),
			_Utils_Tuple2('ndash', '–'),
			_Utils_Tuple2('nearhk', '⤤'),
			_Utils_Tuple2('nearr', '↗'),
			_Utils_Tuple2('neArr', '⇗'),
			_Utils_Tuple2('nearrow', '↗'),
			_Utils_Tuple2('ne', '≠'),
			_Utils_Tuple2('nedot', '≐̸'),
			_Utils_Tuple2('NegativeMediumSpace', '\u200B'),
			_Utils_Tuple2('NegativeThickSpace', '\u200B'),
			_Utils_Tuple2('NegativeThinSpace', '\u200B'),
			_Utils_Tuple2('NegativeVeryThinSpace', '\u200B'),
			_Utils_Tuple2('nequiv', '≢'),
			_Utils_Tuple2('nesear', '⤨'),
			_Utils_Tuple2('nesim', '≂̸'),
			_Utils_Tuple2('NestedGreaterGreater', '≫'),
			_Utils_Tuple2('NestedLessLess', '≪'),
			_Utils_Tuple2('NewLine', '\n'),
			_Utils_Tuple2('nexist', '∄'),
			_Utils_Tuple2('nexists', '∄'),
			_Utils_Tuple2('Nfr', '\uD835\uDD11'),
			_Utils_Tuple2('nfr', '\uD835\uDD2B'),
			_Utils_Tuple2('ngE', '≧̸'),
			_Utils_Tuple2('nge', '≱'),
			_Utils_Tuple2('ngeq', '≱'),
			_Utils_Tuple2('ngeqq', '≧̸'),
			_Utils_Tuple2('ngeqslant', '⩾̸'),
			_Utils_Tuple2('nges', '⩾̸'),
			_Utils_Tuple2('nGg', '⋙̸'),
			_Utils_Tuple2('ngsim', '≵'),
			_Utils_Tuple2('nGt', '≫⃒'),
			_Utils_Tuple2('ngt', '≯'),
			_Utils_Tuple2('ngtr', '≯'),
			_Utils_Tuple2('nGtv', '≫̸'),
			_Utils_Tuple2('nharr', '↮'),
			_Utils_Tuple2('nhArr', '⇎'),
			_Utils_Tuple2('nhpar', '⫲'),
			_Utils_Tuple2('ni', '∋'),
			_Utils_Tuple2('nis', '⋼'),
			_Utils_Tuple2('nisd', '⋺'),
			_Utils_Tuple2('niv', '∋'),
			_Utils_Tuple2('NJcy', 'Њ'),
			_Utils_Tuple2('njcy', 'њ'),
			_Utils_Tuple2('nlarr', '↚'),
			_Utils_Tuple2('nlArr', '⇍'),
			_Utils_Tuple2('nldr', '‥'),
			_Utils_Tuple2('nlE', '≦̸'),
			_Utils_Tuple2('nle', '≰'),
			_Utils_Tuple2('nleftarrow', '↚'),
			_Utils_Tuple2('nLeftarrow', '⇍'),
			_Utils_Tuple2('nleftrightarrow', '↮'),
			_Utils_Tuple2('nLeftrightarrow', '⇎'),
			_Utils_Tuple2('nleq', '≰'),
			_Utils_Tuple2('nleqq', '≦̸'),
			_Utils_Tuple2('nleqslant', '⩽̸'),
			_Utils_Tuple2('nles', '⩽̸'),
			_Utils_Tuple2('nless', '≮'),
			_Utils_Tuple2('nLl', '⋘̸'),
			_Utils_Tuple2('nlsim', '≴'),
			_Utils_Tuple2('nLt', '≪⃒'),
			_Utils_Tuple2('nlt', '≮'),
			_Utils_Tuple2('nltri', '⋪'),
			_Utils_Tuple2('nltrie', '⋬'),
			_Utils_Tuple2('nLtv', '≪̸'),
			_Utils_Tuple2('nmid', '∤'),
			_Utils_Tuple2('NoBreak', '\u2060'),
			_Utils_Tuple2('NonBreakingSpace', '\u00A0'),
			_Utils_Tuple2('nopf', '\uD835\uDD5F'),
			_Utils_Tuple2('Nopf', 'ℕ'),
			_Utils_Tuple2('Not', '⫬'),
			_Utils_Tuple2('not', '¬'),
			_Utils_Tuple2('NotCongruent', '≢'),
			_Utils_Tuple2('NotCupCap', '≭'),
			_Utils_Tuple2('NotDoubleVerticalBar', '∦'),
			_Utils_Tuple2('NotElement', '∉'),
			_Utils_Tuple2('NotEqual', '≠'),
			_Utils_Tuple2('NotEqualTilde', '≂̸'),
			_Utils_Tuple2('NotExists', '∄'),
			_Utils_Tuple2('NotGreater', '≯'),
			_Utils_Tuple2('NotGreaterEqual', '≱'),
			_Utils_Tuple2('NotGreaterFullEqual', '≧̸'),
			_Utils_Tuple2('NotGreaterGreater', '≫̸'),
			_Utils_Tuple2('NotGreaterLess', '≹'),
			_Utils_Tuple2('NotGreaterSlantEqual', '⩾̸'),
			_Utils_Tuple2('NotGreaterTilde', '≵'),
			_Utils_Tuple2('NotHumpDownHump', '≎̸'),
			_Utils_Tuple2('NotHumpEqual', '≏̸'),
			_Utils_Tuple2('notin', '∉'),
			_Utils_Tuple2('notindot', '⋵̸'),
			_Utils_Tuple2('notinE', '⋹̸'),
			_Utils_Tuple2('notinva', '∉'),
			_Utils_Tuple2('notinvb', '⋷'),
			_Utils_Tuple2('notinvc', '⋶'),
			_Utils_Tuple2('NotLeftTriangleBar', '⧏̸'),
			_Utils_Tuple2('NotLeftTriangle', '⋪'),
			_Utils_Tuple2('NotLeftTriangleEqual', '⋬'),
			_Utils_Tuple2('NotLess', '≮'),
			_Utils_Tuple2('NotLessEqual', '≰'),
			_Utils_Tuple2('NotLessGreater', '≸'),
			_Utils_Tuple2('NotLessLess', '≪̸'),
			_Utils_Tuple2('NotLessSlantEqual', '⩽̸'),
			_Utils_Tuple2('NotLessTilde', '≴'),
			_Utils_Tuple2('NotNestedGreaterGreater', '⪢̸'),
			_Utils_Tuple2('NotNestedLessLess', '⪡̸'),
			_Utils_Tuple2('notni', '∌'),
			_Utils_Tuple2('notniva', '∌'),
			_Utils_Tuple2('notnivb', '⋾'),
			_Utils_Tuple2('notnivc', '⋽'),
			_Utils_Tuple2('NotPrecedes', '⊀'),
			_Utils_Tuple2('NotPrecedesEqual', '⪯̸'),
			_Utils_Tuple2('NotPrecedesSlantEqual', '⋠'),
			_Utils_Tuple2('NotReverseElement', '∌'),
			_Utils_Tuple2('NotRightTriangleBar', '⧐̸'),
			_Utils_Tuple2('NotRightTriangle', '⋫'),
			_Utils_Tuple2('NotRightTriangleEqual', '⋭'),
			_Utils_Tuple2('NotSquareSubset', '⊏̸'),
			_Utils_Tuple2('NotSquareSubsetEqual', '⋢'),
			_Utils_Tuple2('NotSquareSuperset', '⊐̸'),
			_Utils_Tuple2('NotSquareSupersetEqual', '⋣'),
			_Utils_Tuple2('NotSubset', '⊂⃒'),
			_Utils_Tuple2('NotSubsetEqual', '⊈'),
			_Utils_Tuple2('NotSucceeds', '⊁'),
			_Utils_Tuple2('NotSucceedsEqual', '⪰̸'),
			_Utils_Tuple2('NotSucceedsSlantEqual', '⋡'),
			_Utils_Tuple2('NotSucceedsTilde', '≿̸'),
			_Utils_Tuple2('NotSuperset', '⊃⃒'),
			_Utils_Tuple2('NotSupersetEqual', '⊉'),
			_Utils_Tuple2('NotTilde', '≁'),
			_Utils_Tuple2('NotTildeEqual', '≄'),
			_Utils_Tuple2('NotTildeFullEqual', '≇'),
			_Utils_Tuple2('NotTildeTilde', '≉'),
			_Utils_Tuple2('NotVerticalBar', '∤'),
			_Utils_Tuple2('nparallel', '∦'),
			_Utils_Tuple2('npar', '∦'),
			_Utils_Tuple2('nparsl', '⫽⃥'),
			_Utils_Tuple2('npart', '∂̸'),
			_Utils_Tuple2('npolint', '⨔'),
			_Utils_Tuple2('npr', '⊀'),
			_Utils_Tuple2('nprcue', '⋠'),
			_Utils_Tuple2('nprec', '⊀'),
			_Utils_Tuple2('npreceq', '⪯̸'),
			_Utils_Tuple2('npre', '⪯̸'),
			_Utils_Tuple2('nrarrc', '⤳̸'),
			_Utils_Tuple2('nrarr', '↛'),
			_Utils_Tuple2('nrArr', '⇏'),
			_Utils_Tuple2('nrarrw', '↝̸'),
			_Utils_Tuple2('nrightarrow', '↛'),
			_Utils_Tuple2('nRightarrow', '⇏'),
			_Utils_Tuple2('nrtri', '⋫'),
			_Utils_Tuple2('nrtrie', '⋭'),
			_Utils_Tuple2('nsc', '⊁'),
			_Utils_Tuple2('nsccue', '⋡'),
			_Utils_Tuple2('nsce', '⪰̸'),
			_Utils_Tuple2('Nscr', '\uD835\uDCA9'),
			_Utils_Tuple2('nscr', '\uD835\uDCC3'),
			_Utils_Tuple2('nshortmid', '∤'),
			_Utils_Tuple2('nshortparallel', '∦'),
			_Utils_Tuple2('nsim', '≁'),
			_Utils_Tuple2('nsime', '≄'),
			_Utils_Tuple2('nsimeq', '≄'),
			_Utils_Tuple2('nsmid', '∤'),
			_Utils_Tuple2('nspar', '∦'),
			_Utils_Tuple2('nsqsube', '⋢'),
			_Utils_Tuple2('nsqsupe', '⋣'),
			_Utils_Tuple2('nsub', '⊄'),
			_Utils_Tuple2('nsubE', '⫅̸'),
			_Utils_Tuple2('nsube', '⊈'),
			_Utils_Tuple2('nsubset', '⊂⃒'),
			_Utils_Tuple2('nsubseteq', '⊈'),
			_Utils_Tuple2('nsubseteqq', '⫅̸'),
			_Utils_Tuple2('nsucc', '⊁'),
			_Utils_Tuple2('nsucceq', '⪰̸'),
			_Utils_Tuple2('nsup', '⊅'),
			_Utils_Tuple2('nsupE', '⫆̸'),
			_Utils_Tuple2('nsupe', '⊉'),
			_Utils_Tuple2('nsupset', '⊃⃒'),
			_Utils_Tuple2('nsupseteq', '⊉'),
			_Utils_Tuple2('nsupseteqq', '⫆̸'),
			_Utils_Tuple2('ntgl', '≹'),
			_Utils_Tuple2('Ntilde', 'Ñ'),
			_Utils_Tuple2('ntilde', 'ñ'),
			_Utils_Tuple2('ntlg', '≸'),
			_Utils_Tuple2('ntriangleleft', '⋪'),
			_Utils_Tuple2('ntrianglelefteq', '⋬'),
			_Utils_Tuple2('ntriangleright', '⋫'),
			_Utils_Tuple2('ntrianglerighteq', '⋭'),
			_Utils_Tuple2('Nu', 'Ν'),
			_Utils_Tuple2('nu', 'ν'),
			_Utils_Tuple2('num', '#'),
			_Utils_Tuple2('numero', '№'),
			_Utils_Tuple2('numsp', '\u2007'),
			_Utils_Tuple2('nvap', '≍⃒'),
			_Utils_Tuple2('nvdash', '⊬'),
			_Utils_Tuple2('nvDash', '⊭'),
			_Utils_Tuple2('nVdash', '⊮'),
			_Utils_Tuple2('nVDash', '⊯'),
			_Utils_Tuple2('nvge', '≥⃒'),
			_Utils_Tuple2('nvgt', '>⃒'),
			_Utils_Tuple2('nvHarr', '⤄'),
			_Utils_Tuple2('nvinfin', '⧞'),
			_Utils_Tuple2('nvlArr', '⤂'),
			_Utils_Tuple2('nvle', '≤⃒'),
			_Utils_Tuple2('nvlt', '<⃒'),
			_Utils_Tuple2('nvltrie', '⊴⃒'),
			_Utils_Tuple2('nvrArr', '⤃'),
			_Utils_Tuple2('nvrtrie', '⊵⃒'),
			_Utils_Tuple2('nvsim', '∼⃒'),
			_Utils_Tuple2('nwarhk', '⤣'),
			_Utils_Tuple2('nwarr', '↖'),
			_Utils_Tuple2('nwArr', '⇖'),
			_Utils_Tuple2('nwarrow', '↖'),
			_Utils_Tuple2('nwnear', '⤧'),
			_Utils_Tuple2('Oacute', 'Ó'),
			_Utils_Tuple2('oacute', 'ó'),
			_Utils_Tuple2('oast', '⊛'),
			_Utils_Tuple2('Ocirc', 'Ô'),
			_Utils_Tuple2('ocirc', 'ô'),
			_Utils_Tuple2('ocir', '⊚'),
			_Utils_Tuple2('Ocy', 'О'),
			_Utils_Tuple2('ocy', 'о'),
			_Utils_Tuple2('odash', '⊝'),
			_Utils_Tuple2('Odblac', 'Ő'),
			_Utils_Tuple2('odblac', 'ő'),
			_Utils_Tuple2('odiv', '⨸'),
			_Utils_Tuple2('odot', '⊙'),
			_Utils_Tuple2('odsold', '⦼'),
			_Utils_Tuple2('OElig', 'Œ'),
			_Utils_Tuple2('oelig', 'œ'),
			_Utils_Tuple2('ofcir', '⦿'),
			_Utils_Tuple2('Ofr', '\uD835\uDD12'),
			_Utils_Tuple2('ofr', '\uD835\uDD2C'),
			_Utils_Tuple2('ogon', '˛'),
			_Utils_Tuple2('Ograve', 'Ò'),
			_Utils_Tuple2('ograve', 'ò'),
			_Utils_Tuple2('ogt', '⧁'),
			_Utils_Tuple2('ohbar', '⦵'),
			_Utils_Tuple2('ohm', 'Ω'),
			_Utils_Tuple2('oint', '∮'),
			_Utils_Tuple2('olarr', '↺'),
			_Utils_Tuple2('olcir', '⦾'),
			_Utils_Tuple2('olcross', '⦻'),
			_Utils_Tuple2('oline', '‾'),
			_Utils_Tuple2('olt', '⧀'),
			_Utils_Tuple2('Omacr', 'Ō'),
			_Utils_Tuple2('omacr', 'ō'),
			_Utils_Tuple2('Omega', 'Ω'),
			_Utils_Tuple2('omega', 'ω'),
			_Utils_Tuple2('Omicron', 'Ο'),
			_Utils_Tuple2('omicron', 'ο'),
			_Utils_Tuple2('omid', '⦶'),
			_Utils_Tuple2('ominus', '⊖'),
			_Utils_Tuple2('Oopf', '\uD835\uDD46'),
			_Utils_Tuple2('oopf', '\uD835\uDD60'),
			_Utils_Tuple2('opar', '⦷'),
			_Utils_Tuple2('OpenCurlyDoubleQuote', '“'),
			_Utils_Tuple2('OpenCurlyQuote', '‘'),
			_Utils_Tuple2('operp', '⦹'),
			_Utils_Tuple2('oplus', '⊕'),
			_Utils_Tuple2('orarr', '↻'),
			_Utils_Tuple2('Or', '⩔'),
			_Utils_Tuple2('or', '∨'),
			_Utils_Tuple2('ord', '⩝'),
			_Utils_Tuple2('order', 'ℴ'),
			_Utils_Tuple2('orderof', 'ℴ'),
			_Utils_Tuple2('ordf', 'ª'),
			_Utils_Tuple2('ordm', 'º'),
			_Utils_Tuple2('origof', '⊶'),
			_Utils_Tuple2('oror', '⩖'),
			_Utils_Tuple2('orslope', '⩗'),
			_Utils_Tuple2('orv', '⩛'),
			_Utils_Tuple2('oS', 'Ⓢ'),
			_Utils_Tuple2('Oscr', '\uD835\uDCAA'),
			_Utils_Tuple2('oscr', 'ℴ'),
			_Utils_Tuple2('Oslash', 'Ø'),
			_Utils_Tuple2('oslash', 'ø'),
			_Utils_Tuple2('osol', '⊘'),
			_Utils_Tuple2('Otilde', 'Õ'),
			_Utils_Tuple2('otilde', 'õ'),
			_Utils_Tuple2('otimesas', '⨶'),
			_Utils_Tuple2('Otimes', '⨷'),
			_Utils_Tuple2('otimes', '⊗'),
			_Utils_Tuple2('Ouml', 'Ö'),
			_Utils_Tuple2('ouml', 'ö'),
			_Utils_Tuple2('ovbar', '⌽'),
			_Utils_Tuple2('OverBar', '‾'),
			_Utils_Tuple2('OverBrace', '⏞'),
			_Utils_Tuple2('OverBracket', '⎴'),
			_Utils_Tuple2('OverParenthesis', '⏜'),
			_Utils_Tuple2('para', '¶'),
			_Utils_Tuple2('parallel', '∥'),
			_Utils_Tuple2('par', '∥'),
			_Utils_Tuple2('parsim', '⫳'),
			_Utils_Tuple2('parsl', '⫽'),
			_Utils_Tuple2('part', '∂'),
			_Utils_Tuple2('PartialD', '∂'),
			_Utils_Tuple2('Pcy', 'П'),
			_Utils_Tuple2('pcy', 'п'),
			_Utils_Tuple2('percnt', '%'),
			_Utils_Tuple2('period', '.'),
			_Utils_Tuple2('permil', '‰'),
			_Utils_Tuple2('perp', '⊥'),
			_Utils_Tuple2('pertenk', '‱'),
			_Utils_Tuple2('Pfr', '\uD835\uDD13'),
			_Utils_Tuple2('pfr', '\uD835\uDD2D'),
			_Utils_Tuple2('Phi', 'Φ'),
			_Utils_Tuple2('phi', 'φ'),
			_Utils_Tuple2('phiv', 'ϕ'),
			_Utils_Tuple2('phmmat', 'ℳ'),
			_Utils_Tuple2('phone', '☎'),
			_Utils_Tuple2('Pi', 'Π'),
			_Utils_Tuple2('pi', 'π'),
			_Utils_Tuple2('pitchfork', '⋔'),
			_Utils_Tuple2('piv', 'ϖ'),
			_Utils_Tuple2('planck', 'ℏ'),
			_Utils_Tuple2('planckh', 'ℎ'),
			_Utils_Tuple2('plankv', 'ℏ'),
			_Utils_Tuple2('plusacir', '⨣'),
			_Utils_Tuple2('plusb', '⊞'),
			_Utils_Tuple2('pluscir', '⨢'),
			_Utils_Tuple2('plus', '+'),
			_Utils_Tuple2('plusdo', '∔'),
			_Utils_Tuple2('plusdu', '⨥'),
			_Utils_Tuple2('pluse', '⩲'),
			_Utils_Tuple2('PlusMinus', '±'),
			_Utils_Tuple2('plusmn', '±'),
			_Utils_Tuple2('plussim', '⨦'),
			_Utils_Tuple2('plustwo', '⨧'),
			_Utils_Tuple2('pm', '±'),
			_Utils_Tuple2('Poincareplane', 'ℌ'),
			_Utils_Tuple2('pointint', '⨕'),
			_Utils_Tuple2('popf', '\uD835\uDD61'),
			_Utils_Tuple2('Popf', 'ℙ'),
			_Utils_Tuple2('pound', '£'),
			_Utils_Tuple2('prap', '⪷'),
			_Utils_Tuple2('Pr', '⪻'),
			_Utils_Tuple2('pr', '≺'),
			_Utils_Tuple2('prcue', '≼'),
			_Utils_Tuple2('precapprox', '⪷'),
			_Utils_Tuple2('prec', '≺'),
			_Utils_Tuple2('preccurlyeq', '≼'),
			_Utils_Tuple2('Precedes', '≺'),
			_Utils_Tuple2('PrecedesEqual', '⪯'),
			_Utils_Tuple2('PrecedesSlantEqual', '≼'),
			_Utils_Tuple2('PrecedesTilde', '≾'),
			_Utils_Tuple2('preceq', '⪯'),
			_Utils_Tuple2('precnapprox', '⪹'),
			_Utils_Tuple2('precneqq', '⪵'),
			_Utils_Tuple2('precnsim', '⋨'),
			_Utils_Tuple2('pre', '⪯'),
			_Utils_Tuple2('prE', '⪳'),
			_Utils_Tuple2('precsim', '≾'),
			_Utils_Tuple2('prime', '′'),
			_Utils_Tuple2('Prime', '″'),
			_Utils_Tuple2('primes', 'ℙ'),
			_Utils_Tuple2('prnap', '⪹'),
			_Utils_Tuple2('prnE', '⪵'),
			_Utils_Tuple2('prnsim', '⋨'),
			_Utils_Tuple2('prod', '∏'),
			_Utils_Tuple2('Product', '∏'),
			_Utils_Tuple2('profalar', '⌮'),
			_Utils_Tuple2('profline', '⌒'),
			_Utils_Tuple2('profsurf', '⌓'),
			_Utils_Tuple2('prop', '∝'),
			_Utils_Tuple2('Proportional', '∝'),
			_Utils_Tuple2('Proportion', '∷'),
			_Utils_Tuple2('propto', '∝'),
			_Utils_Tuple2('prsim', '≾'),
			_Utils_Tuple2('prurel', '⊰'),
			_Utils_Tuple2('Pscr', '\uD835\uDCAB'),
			_Utils_Tuple2('pscr', '\uD835\uDCC5'),
			_Utils_Tuple2('Psi', 'Ψ'),
			_Utils_Tuple2('psi', 'ψ'),
			_Utils_Tuple2('puncsp', '\u2008'),
			_Utils_Tuple2('Qfr', '\uD835\uDD14'),
			_Utils_Tuple2('qfr', '\uD835\uDD2E'),
			_Utils_Tuple2('qint', '⨌'),
			_Utils_Tuple2('qopf', '\uD835\uDD62'),
			_Utils_Tuple2('Qopf', 'ℚ'),
			_Utils_Tuple2('qprime', '⁗'),
			_Utils_Tuple2('Qscr', '\uD835\uDCAC'),
			_Utils_Tuple2('qscr', '\uD835\uDCC6'),
			_Utils_Tuple2('quaternions', 'ℍ'),
			_Utils_Tuple2('quatint', '⨖'),
			_Utils_Tuple2('quest', '?'),
			_Utils_Tuple2('questeq', '≟'),
			_Utils_Tuple2('quot', '\"'),
			_Utils_Tuple2('QUOT', '\"'),
			_Utils_Tuple2('rAarr', '⇛'),
			_Utils_Tuple2('race', '∽̱'),
			_Utils_Tuple2('Racute', 'Ŕ'),
			_Utils_Tuple2('racute', 'ŕ'),
			_Utils_Tuple2('radic', '√'),
			_Utils_Tuple2('raemptyv', '⦳'),
			_Utils_Tuple2('rang', '⟩'),
			_Utils_Tuple2('Rang', '⟫'),
			_Utils_Tuple2('rangd', '⦒'),
			_Utils_Tuple2('range', '⦥'),
			_Utils_Tuple2('rangle', '⟩'),
			_Utils_Tuple2('raquo', '»'),
			_Utils_Tuple2('rarrap', '⥵'),
			_Utils_Tuple2('rarrb', '⇥'),
			_Utils_Tuple2('rarrbfs', '⤠'),
			_Utils_Tuple2('rarrc', '⤳'),
			_Utils_Tuple2('rarr', '→'),
			_Utils_Tuple2('Rarr', '↠'),
			_Utils_Tuple2('rArr', '⇒'),
			_Utils_Tuple2('rarrfs', '⤞'),
			_Utils_Tuple2('rarrhk', '↪'),
			_Utils_Tuple2('rarrlp', '↬'),
			_Utils_Tuple2('rarrpl', '⥅'),
			_Utils_Tuple2('rarrsim', '⥴'),
			_Utils_Tuple2('Rarrtl', '⤖'),
			_Utils_Tuple2('rarrtl', '↣'),
			_Utils_Tuple2('rarrw', '↝'),
			_Utils_Tuple2('ratail', '⤚'),
			_Utils_Tuple2('rAtail', '⤜'),
			_Utils_Tuple2('ratio', '∶'),
			_Utils_Tuple2('rationals', 'ℚ'),
			_Utils_Tuple2('rbarr', '⤍'),
			_Utils_Tuple2('rBarr', '⤏'),
			_Utils_Tuple2('RBarr', '⤐'),
			_Utils_Tuple2('rbbrk', '❳'),
			_Utils_Tuple2('rbrace', '}'),
			_Utils_Tuple2('rbrack', ']'),
			_Utils_Tuple2('rbrke', '⦌'),
			_Utils_Tuple2('rbrksld', '⦎'),
			_Utils_Tuple2('rbrkslu', '⦐'),
			_Utils_Tuple2('Rcaron', 'Ř'),
			_Utils_Tuple2('rcaron', 'ř'),
			_Utils_Tuple2('Rcedil', 'Ŗ'),
			_Utils_Tuple2('rcedil', 'ŗ'),
			_Utils_Tuple2('rceil', '⌉'),
			_Utils_Tuple2('rcub', '}'),
			_Utils_Tuple2('Rcy', 'Р'),
			_Utils_Tuple2('rcy', 'р'),
			_Utils_Tuple2('rdca', '⤷'),
			_Utils_Tuple2('rdldhar', '⥩'),
			_Utils_Tuple2('rdquo', '”'),
			_Utils_Tuple2('rdquor', '”'),
			_Utils_Tuple2('rdsh', '↳'),
			_Utils_Tuple2('real', 'ℜ'),
			_Utils_Tuple2('realine', 'ℛ'),
			_Utils_Tuple2('realpart', 'ℜ'),
			_Utils_Tuple2('reals', 'ℝ'),
			_Utils_Tuple2('Re', 'ℜ'),
			_Utils_Tuple2('rect', '▭'),
			_Utils_Tuple2('reg', '®'),
			_Utils_Tuple2('REG', '®'),
			_Utils_Tuple2('ReverseElement', '∋'),
			_Utils_Tuple2('ReverseEquilibrium', '⇋'),
			_Utils_Tuple2('ReverseUpEquilibrium', '⥯'),
			_Utils_Tuple2('rfisht', '⥽'),
			_Utils_Tuple2('rfloor', '⌋'),
			_Utils_Tuple2('rfr', '\uD835\uDD2F'),
			_Utils_Tuple2('Rfr', 'ℜ'),
			_Utils_Tuple2('rHar', '⥤'),
			_Utils_Tuple2('rhard', '⇁'),
			_Utils_Tuple2('rharu', '⇀'),
			_Utils_Tuple2('rharul', '⥬'),
			_Utils_Tuple2('Rho', 'Ρ'),
			_Utils_Tuple2('rho', 'ρ'),
			_Utils_Tuple2('rhov', 'ϱ'),
			_Utils_Tuple2('RightAngleBracket', '⟩'),
			_Utils_Tuple2('RightArrowBar', '⇥'),
			_Utils_Tuple2('rightarrow', '→'),
			_Utils_Tuple2('RightArrow', '→'),
			_Utils_Tuple2('Rightarrow', '⇒'),
			_Utils_Tuple2('RightArrowLeftArrow', '⇄'),
			_Utils_Tuple2('rightarrowtail', '↣'),
			_Utils_Tuple2('RightCeiling', '⌉'),
			_Utils_Tuple2('RightDoubleBracket', '⟧'),
			_Utils_Tuple2('RightDownTeeVector', '⥝'),
			_Utils_Tuple2('RightDownVectorBar', '⥕'),
			_Utils_Tuple2('RightDownVector', '⇂'),
			_Utils_Tuple2('RightFloor', '⌋'),
			_Utils_Tuple2('rightharpoondown', '⇁'),
			_Utils_Tuple2('rightharpoonup', '⇀'),
			_Utils_Tuple2('rightleftarrows', '⇄'),
			_Utils_Tuple2('rightleftharpoons', '⇌'),
			_Utils_Tuple2('rightrightarrows', '⇉'),
			_Utils_Tuple2('rightsquigarrow', '↝'),
			_Utils_Tuple2('RightTeeArrow', '↦'),
			_Utils_Tuple2('RightTee', '⊢'),
			_Utils_Tuple2('RightTeeVector', '⥛'),
			_Utils_Tuple2('rightthreetimes', '⋌'),
			_Utils_Tuple2('RightTriangleBar', '⧐'),
			_Utils_Tuple2('RightTriangle', '⊳'),
			_Utils_Tuple2('RightTriangleEqual', '⊵'),
			_Utils_Tuple2('RightUpDownVector', '⥏'),
			_Utils_Tuple2('RightUpTeeVector', '⥜'),
			_Utils_Tuple2('RightUpVectorBar', '⥔'),
			_Utils_Tuple2('RightUpVector', '↾'),
			_Utils_Tuple2('RightVectorBar', '⥓'),
			_Utils_Tuple2('RightVector', '⇀'),
			_Utils_Tuple2('ring', '˚'),
			_Utils_Tuple2('risingdotseq', '≓'),
			_Utils_Tuple2('rlarr', '⇄'),
			_Utils_Tuple2('rlhar', '⇌'),
			_Utils_Tuple2('rlm', '\u200F'),
			_Utils_Tuple2('rmoustache', '⎱'),
			_Utils_Tuple2('rmoust', '⎱'),
			_Utils_Tuple2('rnmid', '⫮'),
			_Utils_Tuple2('roang', '⟭'),
			_Utils_Tuple2('roarr', '⇾'),
			_Utils_Tuple2('robrk', '⟧'),
			_Utils_Tuple2('ropar', '⦆'),
			_Utils_Tuple2('ropf', '\uD835\uDD63'),
			_Utils_Tuple2('Ropf', 'ℝ'),
			_Utils_Tuple2('roplus', '⨮'),
			_Utils_Tuple2('rotimes', '⨵'),
			_Utils_Tuple2('RoundImplies', '⥰'),
			_Utils_Tuple2('rpar', ')'),
			_Utils_Tuple2('rpargt', '⦔'),
			_Utils_Tuple2('rppolint', '⨒'),
			_Utils_Tuple2('rrarr', '⇉'),
			_Utils_Tuple2('Rrightarrow', '⇛'),
			_Utils_Tuple2('rsaquo', '›'),
			_Utils_Tuple2('rscr', '\uD835\uDCC7'),
			_Utils_Tuple2('Rscr', 'ℛ'),
			_Utils_Tuple2('rsh', '↱'),
			_Utils_Tuple2('Rsh', '↱'),
			_Utils_Tuple2('rsqb', ']'),
			_Utils_Tuple2('rsquo', '’'),
			_Utils_Tuple2('rsquor', '’'),
			_Utils_Tuple2('rthree', '⋌'),
			_Utils_Tuple2('rtimes', '⋊'),
			_Utils_Tuple2('rtri', '▹'),
			_Utils_Tuple2('rtrie', '⊵'),
			_Utils_Tuple2('rtrif', '▸'),
			_Utils_Tuple2('rtriltri', '⧎'),
			_Utils_Tuple2('RuleDelayed', '⧴'),
			_Utils_Tuple2('ruluhar', '⥨'),
			_Utils_Tuple2('rx', '℞'),
			_Utils_Tuple2('Sacute', 'Ś'),
			_Utils_Tuple2('sacute', 'ś'),
			_Utils_Tuple2('sbquo', '‚'),
			_Utils_Tuple2('scap', '⪸'),
			_Utils_Tuple2('Scaron', 'Š'),
			_Utils_Tuple2('scaron', 'š'),
			_Utils_Tuple2('Sc', '⪼'),
			_Utils_Tuple2('sc', '≻'),
			_Utils_Tuple2('sccue', '≽'),
			_Utils_Tuple2('sce', '⪰'),
			_Utils_Tuple2('scE', '⪴'),
			_Utils_Tuple2('Scedil', 'Ş'),
			_Utils_Tuple2('scedil', 'ş'),
			_Utils_Tuple2('Scirc', 'Ŝ'),
			_Utils_Tuple2('scirc', 'ŝ'),
			_Utils_Tuple2('scnap', '⪺'),
			_Utils_Tuple2('scnE', '⪶'),
			_Utils_Tuple2('scnsim', '⋩'),
			_Utils_Tuple2('scpolint', '⨓'),
			_Utils_Tuple2('scsim', '≿'),
			_Utils_Tuple2('Scy', 'С'),
			_Utils_Tuple2('scy', 'с'),
			_Utils_Tuple2('sdotb', '⊡'),
			_Utils_Tuple2('sdot', '⋅'),
			_Utils_Tuple2('sdote', '⩦'),
			_Utils_Tuple2('searhk', '⤥'),
			_Utils_Tuple2('searr', '↘'),
			_Utils_Tuple2('seArr', '⇘'),
			_Utils_Tuple2('searrow', '↘'),
			_Utils_Tuple2('sect', '§'),
			_Utils_Tuple2('semi', ';'),
			_Utils_Tuple2('seswar', '⤩'),
			_Utils_Tuple2('setminus', '∖'),
			_Utils_Tuple2('setmn', '∖'),
			_Utils_Tuple2('sext', '✶'),
			_Utils_Tuple2('Sfr', '\uD835\uDD16'),
			_Utils_Tuple2('sfr', '\uD835\uDD30'),
			_Utils_Tuple2('sfrown', '⌢'),
			_Utils_Tuple2('sharp', '♯'),
			_Utils_Tuple2('SHCHcy', 'Щ'),
			_Utils_Tuple2('shchcy', 'щ'),
			_Utils_Tuple2('SHcy', 'Ш'),
			_Utils_Tuple2('shcy', 'ш'),
			_Utils_Tuple2('ShortDownArrow', '↓'),
			_Utils_Tuple2('ShortLeftArrow', '←'),
			_Utils_Tuple2('shortmid', '∣'),
			_Utils_Tuple2('shortparallel', '∥'),
			_Utils_Tuple2('ShortRightArrow', '→'),
			_Utils_Tuple2('ShortUpArrow', '↑'),
			_Utils_Tuple2('shy', '\u00AD'),
			_Utils_Tuple2('Sigma', 'Σ'),
			_Utils_Tuple2('sigma', 'σ'),
			_Utils_Tuple2('sigmaf', 'ς'),
			_Utils_Tuple2('sigmav', 'ς'),
			_Utils_Tuple2('sim', '∼'),
			_Utils_Tuple2('simdot', '⩪'),
			_Utils_Tuple2('sime', '≃'),
			_Utils_Tuple2('simeq', '≃'),
			_Utils_Tuple2('simg', '⪞'),
			_Utils_Tuple2('simgE', '⪠'),
			_Utils_Tuple2('siml', '⪝'),
			_Utils_Tuple2('simlE', '⪟'),
			_Utils_Tuple2('simne', '≆'),
			_Utils_Tuple2('simplus', '⨤'),
			_Utils_Tuple2('simrarr', '⥲'),
			_Utils_Tuple2('slarr', '←'),
			_Utils_Tuple2('SmallCircle', '∘'),
			_Utils_Tuple2('smallsetminus', '∖'),
			_Utils_Tuple2('smashp', '⨳'),
			_Utils_Tuple2('smeparsl', '⧤'),
			_Utils_Tuple2('smid', '∣'),
			_Utils_Tuple2('smile', '⌣'),
			_Utils_Tuple2('smt', '⪪'),
			_Utils_Tuple2('smte', '⪬'),
			_Utils_Tuple2('smtes', '⪬︀'),
			_Utils_Tuple2('SOFTcy', 'Ь'),
			_Utils_Tuple2('softcy', 'ь'),
			_Utils_Tuple2('solbar', '⌿'),
			_Utils_Tuple2('solb', '⧄'),
			_Utils_Tuple2('sol', '/'),
			_Utils_Tuple2('Sopf', '\uD835\uDD4A'),
			_Utils_Tuple2('sopf', '\uD835\uDD64'),
			_Utils_Tuple2('spades', '♠'),
			_Utils_Tuple2('spadesuit', '♠'),
			_Utils_Tuple2('spar', '∥'),
			_Utils_Tuple2('sqcap', '⊓'),
			_Utils_Tuple2('sqcaps', '⊓︀'),
			_Utils_Tuple2('sqcup', '⊔'),
			_Utils_Tuple2('sqcups', '⊔︀'),
			_Utils_Tuple2('Sqrt', '√'),
			_Utils_Tuple2('sqsub', '⊏'),
			_Utils_Tuple2('sqsube', '⊑'),
			_Utils_Tuple2('sqsubset', '⊏'),
			_Utils_Tuple2('sqsubseteq', '⊑'),
			_Utils_Tuple2('sqsup', '⊐'),
			_Utils_Tuple2('sqsupe', '⊒'),
			_Utils_Tuple2('sqsupset', '⊐'),
			_Utils_Tuple2('sqsupseteq', '⊒'),
			_Utils_Tuple2('square', '□'),
			_Utils_Tuple2('Square', '□'),
			_Utils_Tuple2('SquareIntersection', '⊓'),
			_Utils_Tuple2('SquareSubset', '⊏'),
			_Utils_Tuple2('SquareSubsetEqual', '⊑'),
			_Utils_Tuple2('SquareSuperset', '⊐'),
			_Utils_Tuple2('SquareSupersetEqual', '⊒'),
			_Utils_Tuple2('SquareUnion', '⊔'),
			_Utils_Tuple2('squarf', '▪'),
			_Utils_Tuple2('squ', '□'),
			_Utils_Tuple2('squf', '▪'),
			_Utils_Tuple2('srarr', '→'),
			_Utils_Tuple2('Sscr', '\uD835\uDCAE'),
			_Utils_Tuple2('sscr', '\uD835\uDCC8'),
			_Utils_Tuple2('ssetmn', '∖'),
			_Utils_Tuple2('ssmile', '⌣'),
			_Utils_Tuple2('sstarf', '⋆'),
			_Utils_Tuple2('Star', '⋆'),
			_Utils_Tuple2('star', '☆'),
			_Utils_Tuple2('starf', '★'),
			_Utils_Tuple2('straightepsilon', 'ϵ'),
			_Utils_Tuple2('straightphi', 'ϕ'),
			_Utils_Tuple2('strns', '¯'),
			_Utils_Tuple2('sub', '⊂'),
			_Utils_Tuple2('Sub', '⋐'),
			_Utils_Tuple2('subdot', '⪽'),
			_Utils_Tuple2('subE', '⫅'),
			_Utils_Tuple2('sube', '⊆'),
			_Utils_Tuple2('subedot', '⫃'),
			_Utils_Tuple2('submult', '⫁'),
			_Utils_Tuple2('subnE', '⫋'),
			_Utils_Tuple2('subne', '⊊'),
			_Utils_Tuple2('subplus', '⪿'),
			_Utils_Tuple2('subrarr', '⥹'),
			_Utils_Tuple2('subset', '⊂'),
			_Utils_Tuple2('Subset', '⋐'),
			_Utils_Tuple2('subseteq', '⊆'),
			_Utils_Tuple2('subseteqq', '⫅'),
			_Utils_Tuple2('SubsetEqual', '⊆'),
			_Utils_Tuple2('subsetneq', '⊊'),
			_Utils_Tuple2('subsetneqq', '⫋'),
			_Utils_Tuple2('subsim', '⫇'),
			_Utils_Tuple2('subsub', '⫕'),
			_Utils_Tuple2('subsup', '⫓'),
			_Utils_Tuple2('succapprox', '⪸'),
			_Utils_Tuple2('succ', '≻'),
			_Utils_Tuple2('succcurlyeq', '≽'),
			_Utils_Tuple2('Succeeds', '≻'),
			_Utils_Tuple2('SucceedsEqual', '⪰'),
			_Utils_Tuple2('SucceedsSlantEqual', '≽'),
			_Utils_Tuple2('SucceedsTilde', '≿'),
			_Utils_Tuple2('succeq', '⪰'),
			_Utils_Tuple2('succnapprox', '⪺'),
			_Utils_Tuple2('succneqq', '⪶'),
			_Utils_Tuple2('succnsim', '⋩'),
			_Utils_Tuple2('succsim', '≿'),
			_Utils_Tuple2('SuchThat', '∋'),
			_Utils_Tuple2('sum', '∑'),
			_Utils_Tuple2('Sum', '∑'),
			_Utils_Tuple2('sung', '♪'),
			_Utils_Tuple2('sup1', '¹'),
			_Utils_Tuple2('sup2', '²'),
			_Utils_Tuple2('sup3', '³'),
			_Utils_Tuple2('sup', '⊃'),
			_Utils_Tuple2('Sup', '⋑'),
			_Utils_Tuple2('supdot', '⪾'),
			_Utils_Tuple2('supdsub', '⫘'),
			_Utils_Tuple2('supE', '⫆'),
			_Utils_Tuple2('supe', '⊇'),
			_Utils_Tuple2('supedot', '⫄'),
			_Utils_Tuple2('Superset', '⊃'),
			_Utils_Tuple2('SupersetEqual', '⊇'),
			_Utils_Tuple2('suphsol', '⟉'),
			_Utils_Tuple2('suphsub', '⫗'),
			_Utils_Tuple2('suplarr', '⥻'),
			_Utils_Tuple2('supmult', '⫂'),
			_Utils_Tuple2('supnE', '⫌'),
			_Utils_Tuple2('supne', '⊋'),
			_Utils_Tuple2('supplus', '⫀'),
			_Utils_Tuple2('supset', '⊃'),
			_Utils_Tuple2('Supset', '⋑'),
			_Utils_Tuple2('supseteq', '⊇'),
			_Utils_Tuple2('supseteqq', '⫆'),
			_Utils_Tuple2('supsetneq', '⊋'),
			_Utils_Tuple2('supsetneqq', '⫌'),
			_Utils_Tuple2('supsim', '⫈'),
			_Utils_Tuple2('supsub', '⫔'),
			_Utils_Tuple2('supsup', '⫖'),
			_Utils_Tuple2('swarhk', '⤦'),
			_Utils_Tuple2('swarr', '↙'),
			_Utils_Tuple2('swArr', '⇙'),
			_Utils_Tuple2('swarrow', '↙'),
			_Utils_Tuple2('swnwar', '⤪'),
			_Utils_Tuple2('szlig', 'ß'),
			_Utils_Tuple2('Tab', '\t'),
			_Utils_Tuple2('target', '⌖'),
			_Utils_Tuple2('Tau', 'Τ'),
			_Utils_Tuple2('tau', 'τ'),
			_Utils_Tuple2('tbrk', '⎴'),
			_Utils_Tuple2('Tcaron', 'Ť'),
			_Utils_Tuple2('tcaron', 'ť'),
			_Utils_Tuple2('Tcedil', 'Ţ'),
			_Utils_Tuple2('tcedil', 'ţ'),
			_Utils_Tuple2('Tcy', 'Т'),
			_Utils_Tuple2('tcy', 'т'),
			_Utils_Tuple2('tdot', '⃛'),
			_Utils_Tuple2('telrec', '⌕'),
			_Utils_Tuple2('Tfr', '\uD835\uDD17'),
			_Utils_Tuple2('tfr', '\uD835\uDD31'),
			_Utils_Tuple2('there4', '∴'),
			_Utils_Tuple2('therefore', '∴'),
			_Utils_Tuple2('Therefore', '∴'),
			_Utils_Tuple2('Theta', 'Θ'),
			_Utils_Tuple2('theta', 'θ'),
			_Utils_Tuple2('thetasym', 'ϑ'),
			_Utils_Tuple2('thetav', 'ϑ'),
			_Utils_Tuple2('thickapprox', '≈'),
			_Utils_Tuple2('thicksim', '∼'),
			_Utils_Tuple2('ThickSpace', '\u205F\u200A'),
			_Utils_Tuple2('ThinSpace', '\u2009'),
			_Utils_Tuple2('thinsp', '\u2009'),
			_Utils_Tuple2('thkap', '≈'),
			_Utils_Tuple2('thksim', '∼'),
			_Utils_Tuple2('THORN', 'Þ'),
			_Utils_Tuple2('thorn', 'þ'),
			_Utils_Tuple2('tilde', '˜'),
			_Utils_Tuple2('Tilde', '∼'),
			_Utils_Tuple2('TildeEqual', '≃'),
			_Utils_Tuple2('TildeFullEqual', '≅'),
			_Utils_Tuple2('TildeTilde', '≈'),
			_Utils_Tuple2('timesbar', '⨱'),
			_Utils_Tuple2('timesb', '⊠'),
			_Utils_Tuple2('times', '×'),
			_Utils_Tuple2('timesd', '⨰'),
			_Utils_Tuple2('tint', '∭'),
			_Utils_Tuple2('toea', '⤨'),
			_Utils_Tuple2('topbot', '⌶'),
			_Utils_Tuple2('topcir', '⫱'),
			_Utils_Tuple2('top', '⊤'),
			_Utils_Tuple2('Topf', '\uD835\uDD4B'),
			_Utils_Tuple2('topf', '\uD835\uDD65'),
			_Utils_Tuple2('topfork', '⫚'),
			_Utils_Tuple2('tosa', '⤩'),
			_Utils_Tuple2('tprime', '‴'),
			_Utils_Tuple2('trade', '™'),
			_Utils_Tuple2('TRADE', '™'),
			_Utils_Tuple2('triangle', '▵'),
			_Utils_Tuple2('triangledown', '▿'),
			_Utils_Tuple2('triangleleft', '◃'),
			_Utils_Tuple2('trianglelefteq', '⊴'),
			_Utils_Tuple2('triangleq', '≜'),
			_Utils_Tuple2('triangleright', '▹'),
			_Utils_Tuple2('trianglerighteq', '⊵'),
			_Utils_Tuple2('tridot', '◬'),
			_Utils_Tuple2('trie', '≜'),
			_Utils_Tuple2('triminus', '⨺'),
			_Utils_Tuple2('TripleDot', '⃛'),
			_Utils_Tuple2('triplus', '⨹'),
			_Utils_Tuple2('trisb', '⧍'),
			_Utils_Tuple2('tritime', '⨻'),
			_Utils_Tuple2('trpezium', '⏢'),
			_Utils_Tuple2('Tscr', '\uD835\uDCAF'),
			_Utils_Tuple2('tscr', '\uD835\uDCC9'),
			_Utils_Tuple2('TScy', 'Ц'),
			_Utils_Tuple2('tscy', 'ц'),
			_Utils_Tuple2('TSHcy', 'Ћ'),
			_Utils_Tuple2('tshcy', 'ћ'),
			_Utils_Tuple2('Tstrok', 'Ŧ'),
			_Utils_Tuple2('tstrok', 'ŧ'),
			_Utils_Tuple2('twixt', '≬'),
			_Utils_Tuple2('twoheadleftarrow', '↞'),
			_Utils_Tuple2('twoheadrightarrow', '↠'),
			_Utils_Tuple2('Uacute', 'Ú'),
			_Utils_Tuple2('uacute', 'ú'),
			_Utils_Tuple2('uarr', '↑'),
			_Utils_Tuple2('Uarr', '↟'),
			_Utils_Tuple2('uArr', '⇑'),
			_Utils_Tuple2('Uarrocir', '⥉'),
			_Utils_Tuple2('Ubrcy', 'Ў'),
			_Utils_Tuple2('ubrcy', 'ў'),
			_Utils_Tuple2('Ubreve', 'Ŭ'),
			_Utils_Tuple2('ubreve', 'ŭ'),
			_Utils_Tuple2('Ucirc', 'Û'),
			_Utils_Tuple2('ucirc', 'û'),
			_Utils_Tuple2('Ucy', 'У'),
			_Utils_Tuple2('ucy', 'у'),
			_Utils_Tuple2('udarr', '⇅'),
			_Utils_Tuple2('Udblac', 'Ű'),
			_Utils_Tuple2('udblac', 'ű'),
			_Utils_Tuple2('udhar', '⥮'),
			_Utils_Tuple2('ufisht', '⥾'),
			_Utils_Tuple2('Ufr', '\uD835\uDD18'),
			_Utils_Tuple2('ufr', '\uD835\uDD32'),
			_Utils_Tuple2('Ugrave', 'Ù'),
			_Utils_Tuple2('ugrave', 'ù'),
			_Utils_Tuple2('uHar', '⥣'),
			_Utils_Tuple2('uharl', '↿'),
			_Utils_Tuple2('uharr', '↾'),
			_Utils_Tuple2('uhblk', '▀'),
			_Utils_Tuple2('ulcorn', '⌜'),
			_Utils_Tuple2('ulcorner', '⌜'),
			_Utils_Tuple2('ulcrop', '⌏'),
			_Utils_Tuple2('ultri', '◸'),
			_Utils_Tuple2('Umacr', 'Ū'),
			_Utils_Tuple2('umacr', 'ū'),
			_Utils_Tuple2('uml', '¨'),
			_Utils_Tuple2('UnderBar', '_'),
			_Utils_Tuple2('UnderBrace', '⏟'),
			_Utils_Tuple2('UnderBracket', '⎵'),
			_Utils_Tuple2('UnderParenthesis', '⏝'),
			_Utils_Tuple2('Union', '⋃'),
			_Utils_Tuple2('UnionPlus', '⊎'),
			_Utils_Tuple2('Uogon', 'Ų'),
			_Utils_Tuple2('uogon', 'ų'),
			_Utils_Tuple2('Uopf', '\uD835\uDD4C'),
			_Utils_Tuple2('uopf', '\uD835\uDD66'),
			_Utils_Tuple2('UpArrowBar', '⤒'),
			_Utils_Tuple2('uparrow', '↑'),
			_Utils_Tuple2('UpArrow', '↑'),
			_Utils_Tuple2('Uparrow', '⇑'),
			_Utils_Tuple2('UpArrowDownArrow', '⇅'),
			_Utils_Tuple2('updownarrow', '↕'),
			_Utils_Tuple2('UpDownArrow', '↕'),
			_Utils_Tuple2('Updownarrow', '⇕'),
			_Utils_Tuple2('UpEquilibrium', '⥮'),
			_Utils_Tuple2('upharpoonleft', '↿'),
			_Utils_Tuple2('upharpoonright', '↾'),
			_Utils_Tuple2('uplus', '⊎'),
			_Utils_Tuple2('UpperLeftArrow', '↖'),
			_Utils_Tuple2('UpperRightArrow', '↗'),
			_Utils_Tuple2('upsi', 'υ'),
			_Utils_Tuple2('Upsi', 'ϒ'),
			_Utils_Tuple2('upsih', 'ϒ'),
			_Utils_Tuple2('Upsilon', 'Υ'),
			_Utils_Tuple2('upsilon', 'υ'),
			_Utils_Tuple2('UpTeeArrow', '↥'),
			_Utils_Tuple2('UpTee', '⊥'),
			_Utils_Tuple2('upuparrows', '⇈'),
			_Utils_Tuple2('urcorn', '⌝'),
			_Utils_Tuple2('urcorner', '⌝'),
			_Utils_Tuple2('urcrop', '⌎'),
			_Utils_Tuple2('Uring', 'Ů'),
			_Utils_Tuple2('uring', 'ů'),
			_Utils_Tuple2('urtri', '◹'),
			_Utils_Tuple2('Uscr', '\uD835\uDCB0'),
			_Utils_Tuple2('uscr', '\uD835\uDCCA'),
			_Utils_Tuple2('utdot', '⋰'),
			_Utils_Tuple2('Utilde', 'Ũ'),
			_Utils_Tuple2('utilde', 'ũ'),
			_Utils_Tuple2('utri', '▵'),
			_Utils_Tuple2('utrif', '▴'),
			_Utils_Tuple2('uuarr', '⇈'),
			_Utils_Tuple2('Uuml', 'Ü'),
			_Utils_Tuple2('uuml', 'ü'),
			_Utils_Tuple2('uwangle', '⦧'),
			_Utils_Tuple2('vangrt', '⦜'),
			_Utils_Tuple2('varepsilon', 'ϵ'),
			_Utils_Tuple2('varkappa', 'ϰ'),
			_Utils_Tuple2('varnothing', '∅'),
			_Utils_Tuple2('varphi', 'ϕ'),
			_Utils_Tuple2('varpi', 'ϖ'),
			_Utils_Tuple2('varpropto', '∝'),
			_Utils_Tuple2('varr', '↕'),
			_Utils_Tuple2('vArr', '⇕'),
			_Utils_Tuple2('varrho', 'ϱ'),
			_Utils_Tuple2('varsigma', 'ς'),
			_Utils_Tuple2('varsubsetneq', '⊊︀'),
			_Utils_Tuple2('varsubsetneqq', '⫋︀'),
			_Utils_Tuple2('varsupsetneq', '⊋︀'),
			_Utils_Tuple2('varsupsetneqq', '⫌︀'),
			_Utils_Tuple2('vartheta', 'ϑ'),
			_Utils_Tuple2('vartriangleleft', '⊲'),
			_Utils_Tuple2('vartriangleright', '⊳'),
			_Utils_Tuple2('vBar', '⫨'),
			_Utils_Tuple2('Vbar', '⫫'),
			_Utils_Tuple2('vBarv', '⫩'),
			_Utils_Tuple2('Vcy', 'В'),
			_Utils_Tuple2('vcy', 'в'),
			_Utils_Tuple2('vdash', '⊢'),
			_Utils_Tuple2('vDash', '⊨'),
			_Utils_Tuple2('Vdash', '⊩'),
			_Utils_Tuple2('VDash', '⊫'),
			_Utils_Tuple2('Vdashl', '⫦'),
			_Utils_Tuple2('veebar', '⊻'),
			_Utils_Tuple2('vee', '∨'),
			_Utils_Tuple2('Vee', '⋁'),
			_Utils_Tuple2('veeeq', '≚'),
			_Utils_Tuple2('vellip', '⋮'),
			_Utils_Tuple2('verbar', '|'),
			_Utils_Tuple2('Verbar', '‖'),
			_Utils_Tuple2('vert', '|'),
			_Utils_Tuple2('Vert', '‖'),
			_Utils_Tuple2('VerticalBar', '∣'),
			_Utils_Tuple2('VerticalLine', '|'),
			_Utils_Tuple2('VerticalSeparator', '❘'),
			_Utils_Tuple2('VerticalTilde', '≀'),
			_Utils_Tuple2('VeryThinSpace', '\u200A'),
			_Utils_Tuple2('Vfr', '\uD835\uDD19'),
			_Utils_Tuple2('vfr', '\uD835\uDD33'),
			_Utils_Tuple2('vltri', '⊲'),
			_Utils_Tuple2('vnsub', '⊂⃒'),
			_Utils_Tuple2('vnsup', '⊃⃒'),
			_Utils_Tuple2('Vopf', '\uD835\uDD4D'),
			_Utils_Tuple2('vopf', '\uD835\uDD67'),
			_Utils_Tuple2('vprop', '∝'),
			_Utils_Tuple2('vrtri', '⊳'),
			_Utils_Tuple2('Vscr', '\uD835\uDCB1'),
			_Utils_Tuple2('vscr', '\uD835\uDCCB'),
			_Utils_Tuple2('vsubnE', '⫋︀'),
			_Utils_Tuple2('vsubne', '⊊︀'),
			_Utils_Tuple2('vsupnE', '⫌︀'),
			_Utils_Tuple2('vsupne', '⊋︀'),
			_Utils_Tuple2('Vvdash', '⊪'),
			_Utils_Tuple2('vzigzag', '⦚'),
			_Utils_Tuple2('Wcirc', 'Ŵ'),
			_Utils_Tuple2('wcirc', 'ŵ'),
			_Utils_Tuple2('wedbar', '⩟'),
			_Utils_Tuple2('wedge', '∧'),
			_Utils_Tuple2('Wedge', '⋀'),
			_Utils_Tuple2('wedgeq', '≙'),
			_Utils_Tuple2('weierp', '℘'),
			_Utils_Tuple2('Wfr', '\uD835\uDD1A'),
			_Utils_Tuple2('wfr', '\uD835\uDD34'),
			_Utils_Tuple2('Wopf', '\uD835\uDD4E'),
			_Utils_Tuple2('wopf', '\uD835\uDD68'),
			_Utils_Tuple2('wp', '℘'),
			_Utils_Tuple2('wr', '≀'),
			_Utils_Tuple2('wreath', '≀'),
			_Utils_Tuple2('Wscr', '\uD835\uDCB2'),
			_Utils_Tuple2('wscr', '\uD835\uDCCC'),
			_Utils_Tuple2('xcap', '⋂'),
			_Utils_Tuple2('xcirc', '◯'),
			_Utils_Tuple2('xcup', '⋃'),
			_Utils_Tuple2('xdtri', '▽'),
			_Utils_Tuple2('Xfr', '\uD835\uDD1B'),
			_Utils_Tuple2('xfr', '\uD835\uDD35'),
			_Utils_Tuple2('xharr', '⟷'),
			_Utils_Tuple2('xhArr', '⟺'),
			_Utils_Tuple2('Xi', 'Ξ'),
			_Utils_Tuple2('xi', 'ξ'),
			_Utils_Tuple2('xlarr', '⟵'),
			_Utils_Tuple2('xlArr', '⟸'),
			_Utils_Tuple2('xmap', '⟼'),
			_Utils_Tuple2('xnis', '⋻'),
			_Utils_Tuple2('xodot', '⨀'),
			_Utils_Tuple2('Xopf', '\uD835\uDD4F'),
			_Utils_Tuple2('xopf', '\uD835\uDD69'),
			_Utils_Tuple2('xoplus', '⨁'),
			_Utils_Tuple2('xotime', '⨂'),
			_Utils_Tuple2('xrarr', '⟶'),
			_Utils_Tuple2('xrArr', '⟹'),
			_Utils_Tuple2('Xscr', '\uD835\uDCB3'),
			_Utils_Tuple2('xscr', '\uD835\uDCCD'),
			_Utils_Tuple2('xsqcup', '⨆'),
			_Utils_Tuple2('xuplus', '⨄'),
			_Utils_Tuple2('xutri', '△'),
			_Utils_Tuple2('xvee', '⋁'),
			_Utils_Tuple2('xwedge', '⋀'),
			_Utils_Tuple2('Yacute', 'Ý'),
			_Utils_Tuple2('yacute', 'ý'),
			_Utils_Tuple2('YAcy', 'Я'),
			_Utils_Tuple2('yacy', 'я'),
			_Utils_Tuple2('Ycirc', 'Ŷ'),
			_Utils_Tuple2('ycirc', 'ŷ'),
			_Utils_Tuple2('Ycy', 'Ы'),
			_Utils_Tuple2('ycy', 'ы'),
			_Utils_Tuple2('yen', '¥'),
			_Utils_Tuple2('Yfr', '\uD835\uDD1C'),
			_Utils_Tuple2('yfr', '\uD835\uDD36'),
			_Utils_Tuple2('YIcy', 'Ї'),
			_Utils_Tuple2('yicy', 'ї'),
			_Utils_Tuple2('Yopf', '\uD835\uDD50'),
			_Utils_Tuple2('yopf', '\uD835\uDD6A'),
			_Utils_Tuple2('Yscr', '\uD835\uDCB4'),
			_Utils_Tuple2('yscr', '\uD835\uDCCE'),
			_Utils_Tuple2('YUcy', 'Ю'),
			_Utils_Tuple2('yucy', 'ю'),
			_Utils_Tuple2('yuml', 'ÿ'),
			_Utils_Tuple2('Yuml', 'Ÿ'),
			_Utils_Tuple2('Zacute', 'Ź'),
			_Utils_Tuple2('zacute', 'ź'),
			_Utils_Tuple2('Zcaron', 'Ž'),
			_Utils_Tuple2('zcaron', 'ž'),
			_Utils_Tuple2('Zcy', 'З'),
			_Utils_Tuple2('zcy', 'з'),
			_Utils_Tuple2('Zdot', 'Ż'),
			_Utils_Tuple2('zdot', 'ż'),
			_Utils_Tuple2('zeetrf', 'ℨ'),
			_Utils_Tuple2('ZeroWidthSpace', '\u200B'),
			_Utils_Tuple2('Zeta', 'Ζ'),
			_Utils_Tuple2('zeta', 'ζ'),
			_Utils_Tuple2('zfr', '\uD835\uDD37'),
			_Utils_Tuple2('Zfr', 'ℨ'),
			_Utils_Tuple2('ZHcy', 'Ж'),
			_Utils_Tuple2('zhcy', 'ж'),
			_Utils_Tuple2('zigrarr', '⇝'),
			_Utils_Tuple2('zopf', '\uD835\uDD6B'),
			_Utils_Tuple2('Zopf', 'ℤ'),
			_Utils_Tuple2('Zscr', '\uD835\uDCB5'),
			_Utils_Tuple2('zscr', '\uD835\uDCCF'),
			_Utils_Tuple2('zwj', '\u200D'),
			_Utils_Tuple2('zwnj', '\u200C')
		]));
var $hecrj$html_parser$Html$Parser$namedCharacterReference = A2(
	$elm$parser$Parser$map,
	function (reference) {
		return A2(
			$elm$core$Maybe$withDefault,
			'&' + (reference + ';'),
			A2($elm$core$Dict$get, reference, $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict));
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isAlpha)));
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $elm$core$Char$isHexDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return ((48 <= code) && (code <= 57)) || (((65 <= code) && (code <= 70)) || ((97 <= code) && (code <= 102)));
};
var $hecrj$html_parser$Html$Parser$hexadecimal = A2(
	$elm$parser$Parser$andThen,
	function (hex) {
		var _v0 = $rtfeldman$elm_hex$Hex$fromString(
			$elm$core$String$toLower(hex));
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		} else {
			var error = _v0.a;
			return $elm$parser$Parser$problem(error);
		}
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isHexDigit)));
var $elm$parser$Parser$ExpectingInt = {$: 1};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {dD: s.dD + (newOffset - s.ao), b: s.b, e: s.e, ao: newOffset, eZ: s.eZ, a: s.a};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 1) {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.ao, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.a);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.eZ, s.dD - (floatOffset + s.ao), invalid, s.b));
		} else {
			if (_Utils_eq(s.ao, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.ao, intPair, s);
				} else {
					if (floatSettings.$ === 1) {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.ao, floatOffset, s.a));
						if (_v1.$ === 1) {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return function (s) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.ao, s.a)) {
			var zeroOffset = s.ao + 1;
			var baseOffset = zeroOffset + 1;
			return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.hp,
				c.hc,
				baseOffset,
				A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.hp,
				c.eE,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.hp,
				c.dv,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.a),
				s) : A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.hp,
				c.dV,
				c.eb,
				c.dY,
				_Utils_Tuple2(zeroOffset, 0),
				s)));
		} else {
			return A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.hp,
				c.dV,
				c.eb,
				c.dY,
				A3($elm$parser$Parser$Advanced$consumeBase, 10, s.ao, s.a),
				s);
		}
	};
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				dv: $elm$core$Result$Err(invalid),
				dV: expecting,
				dY: $elm$core$Result$Err(invalid),
				hc: $elm$core$Result$Err(invalid),
				eb: $elm$core$Result$Ok($elm$core$Basics$identity),
				hp: invalid,
				eE: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $hecrj$html_parser$Html$Parser$numericCharacterReference = function () {
	var codepoint = $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (c) {
							return (c === 'x') || (c === 'X');
						})),
				$hecrj$html_parser$Html$Parser$hexadecimal),
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq('0'))),
				$elm$parser$Parser$int)
			]));
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq('#'))),
		A2(
			$elm$parser$Parser$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			codepoint));
}();
var $hecrj$html_parser$Html$Parser$characterReference = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('&'))),
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$namedCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$numericCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				$elm$parser$Parser$succeed('&')
			])));
var $hecrj$html_parser$Html$Parser$tagAttributeQuotedValue = function (quote) {
	var isQuotedValueChar = function (c) {
		return (!_Utils_eq(c, quote)) && (c !== '&');
	};
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$map,
				$elm$core$String$join(''),
				$hecrj$html_parser$Html$Parser$many(
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$getChompedString(
								$hecrj$html_parser$Html$Parser$chompOneOrMore(isQuotedValueChar)),
								$hecrj$html_parser$Html$Parser$characterReference
							])))),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))));
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $hecrj$html_parser$Html$Parser$oneOrMore = F2(
	function (type_, parser_) {
		return A2(
			$elm$parser$Parser$loop,
			_List_Nil,
			function (list) {
				return $elm$parser$Parser$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$map,
							function (_new) {
								return $elm$parser$Parser$Loop(
									A2($elm$core$List$cons, _new, list));
							},
							parser_),
							$elm$core$List$isEmpty(list) ? $elm$parser$Parser$problem('expecting at least one ' + type_) : $elm$parser$Parser$succeed(
							$elm$parser$Parser$Done(
								$elm$core$List$reverse(list)))
						]));
			});
	});
var $hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue = function () {
	var isUnquotedValueChar = function (c) {
		return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '=') && ((c !== '<') && ((c !== '>') && ((c !== '`') && (c !== '&')))))));
	};
	return A2(
		$elm$parser$Parser$map,
		$elm$core$String$join(''),
		A2(
			$hecrj$html_parser$Html$Parser$oneOrMore,
			'attribute value',
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$getChompedString(
						$hecrj$html_parser$Html$Parser$chompOneOrMore(isUnquotedValueChar)),
						$hecrj$html_parser$Html$Parser$characterReference
					]))));
}();
var $hecrj$html_parser$Html$Parser$tagAttributeValue = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('='))),
				$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue,
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\"'),
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\'')
					]))),
			$elm$parser$Parser$succeed('')
		]));
var $hecrj$html_parser$Html$Parser$tagAttribute = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$ignorer,
			$hecrj$html_parser$Html$Parser$tagAttributeName,
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
	A2(
		$elm$parser$Parser$ignorer,
		$hecrj$html_parser$Html$Parser$tagAttributeValue,
		$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)));
var $hecrj$html_parser$Html$Parser$tagAttributes = $hecrj$html_parser$Html$Parser$many($hecrj$html_parser$Html$Parser$tagAttribute);
var $hecrj$html_parser$Html$Parser$tagName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$chompIf($elm$core$Char$isAlphaNum),
			$elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || (c === '-');
				}))));
var $hecrj$html_parser$Html$Parser$Text = function (a) {
	return {$: 0, a: a};
};
var $hecrj$html_parser$Html$Parser$text = A2(
	$elm$parser$Parser$map,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$join(''),
		$hecrj$html_parser$Html$Parser$Text),
	A2(
		$hecrj$html_parser$Html$Parser$oneOrMore,
		'text element',
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$getChompedString(
					$hecrj$html_parser$Html$Parser$chompOneOrMore(
						function (c) {
							return (c !== '<') && (c !== '&');
						})),
					$hecrj$html_parser$Html$Parser$characterReference
				]))));
function $hecrj$html_parser$Html$Parser$cyclic$node() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				$hecrj$html_parser$Html$Parser$text,
				$hecrj$html_parser$Html$Parser$comment,
				$hecrj$html_parser$Html$Parser$cyclic$element()
			]));
}
function $hecrj$html_parser$Html$Parser$cyclic$element() {
	return A2(
		$elm$parser$Parser$andThen,
		function (_v0) {
			var name = _v0.a;
			var attributes = _v0.b;
			return $hecrj$html_parser$Html$Parser$isVoidElement(name) ? A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A3($hecrj$html_parser$Html$Parser$Element, name, attributes, _List_Nil)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$chompIf(
								$elm$core$Basics$eq('/')),
								$elm$parser$Parser$succeed(0)
							]))),
				$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('>'))) : A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A2($hecrj$html_parser$Html$Parser$Element, name, attributes)),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('>'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$many(
						$elm$parser$Parser$backtrackable(
							$hecrj$html_parser$Html$Parser$cyclic$node())),
					$hecrj$html_parser$Html$Parser$closingTag(name)));
		},
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Tuple$pair),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$tagName,
					$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
			$hecrj$html_parser$Html$Parser$tagAttributes));
}
var $hecrj$html_parser$Html$Parser$node = $hecrj$html_parser$Html$Parser$cyclic$node();
$hecrj$html_parser$Html$Parser$cyclic$node = function () {
	return $hecrj$html_parser$Html$Parser$node;
};
var $hecrj$html_parser$Html$Parser$element = $hecrj$html_parser$Html$Parser$cyclic$element();
$hecrj$html_parser$Html$Parser$cyclic$element = function () {
	return $hecrj$html_parser$Html$Parser$element;
};
var $hecrj$html_parser$Html$Parser$run = function (str) {
	return $elm$core$String$isEmpty(str) ? $elm$core$Result$Ok(_List_Nil) : A2(
		$elm$parser$Parser$run,
		A2($hecrj$html_parser$Html$Parser$oneOrMore, 'node', $hecrj$html_parser$Html$Parser$node),
		str);
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $hecrj$html_parser$Html$Parser$Util$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, name, value);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDom = function (nodes) {
	return A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach, nodes);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach = function (node) {
	switch (node.$) {
		case 1:
			var name = node.a;
			var attrs = node.b;
			var children = node.c;
			return A3(
				$elm$html$Html$node,
				name,
				A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toAttribute, attrs),
				$hecrj$html_parser$Html$Parser$Util$toVirtualDom(children));
		case 0:
			var s = node.a;
			return $elm$html$Html$text(s);
		default:
			return $elm$html$Html$text('');
	}
};
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$convert = F2(
	function (convertChars, string) {
		return $elm$core$String$concat(
			A2(
				$elm$core$List$map,
				$elm$core$String$fromChar,
				convertChars(
					$elm$core$String$toList(string))));
	});
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$convertCode = F5(
	function (mayber, lister, pre, post, list) {
		var string = $elm$core$String$fromList(list);
		var maybe = mayber(string);
		if (maybe.$ === 1) {
			return $elm$core$List$concat(
				_List_fromArray(
					[pre, list, post]));
		} else {
			var something = maybe.a;
			return lister(something);
		}
	});
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$friendlyConverterDictionary = $elm$core$Dict$fromList(
	A2(
		$elm$core$List$map,
		function (_v0) {
			var a = _v0.a;
			var b = _v0.b;
			return _Utils_Tuple2(
				a,
				$elm$core$Char$fromCode(b));
		},
		_List_fromArray(
			[
				_Utils_Tuple2('quot', 34),
				_Utils_Tuple2('amp', 38),
				_Utils_Tuple2('lt', 60),
				_Utils_Tuple2('gt', 62),
				_Utils_Tuple2('nbsp', 160),
				_Utils_Tuple2('iexcl', 161),
				_Utils_Tuple2('cent', 162),
				_Utils_Tuple2('pound', 163),
				_Utils_Tuple2('curren', 164),
				_Utils_Tuple2('yen', 165),
				_Utils_Tuple2('brvbar', 166),
				_Utils_Tuple2('sect', 167),
				_Utils_Tuple2('uml', 168),
				_Utils_Tuple2('copy', 169),
				_Utils_Tuple2('ordf', 170),
				_Utils_Tuple2('laquo', 171),
				_Utils_Tuple2('not', 172),
				_Utils_Tuple2('shy', 173),
				_Utils_Tuple2('reg', 174),
				_Utils_Tuple2('macr', 175),
				_Utils_Tuple2('deg', 176),
				_Utils_Tuple2('plusmn', 177),
				_Utils_Tuple2('sup2', 178),
				_Utils_Tuple2('sup3', 179),
				_Utils_Tuple2('acute', 180),
				_Utils_Tuple2('micro', 181),
				_Utils_Tuple2('para', 182),
				_Utils_Tuple2('middot', 183),
				_Utils_Tuple2('cedil', 184),
				_Utils_Tuple2('sup1', 185),
				_Utils_Tuple2('ordm', 186),
				_Utils_Tuple2('raquo', 187),
				_Utils_Tuple2('frac14', 188),
				_Utils_Tuple2('frac12', 189),
				_Utils_Tuple2('frac34', 190),
				_Utils_Tuple2('iquest', 191),
				_Utils_Tuple2('Agrave', 192),
				_Utils_Tuple2('Aacute', 193),
				_Utils_Tuple2('Acirc', 194),
				_Utils_Tuple2('Atilde', 195),
				_Utils_Tuple2('Auml', 196),
				_Utils_Tuple2('Aring', 197),
				_Utils_Tuple2('AElig', 198),
				_Utils_Tuple2('Ccedil', 199),
				_Utils_Tuple2('Egrave', 200),
				_Utils_Tuple2('Eacute', 201),
				_Utils_Tuple2('Ecirc', 202),
				_Utils_Tuple2('Euml', 203),
				_Utils_Tuple2('Igrave', 204),
				_Utils_Tuple2('Iacute', 205),
				_Utils_Tuple2('Icirc', 206),
				_Utils_Tuple2('Iuml', 207),
				_Utils_Tuple2('ETH', 208),
				_Utils_Tuple2('Ntilde', 209),
				_Utils_Tuple2('Ograve', 210),
				_Utils_Tuple2('Oacute', 211),
				_Utils_Tuple2('Ocirc', 212),
				_Utils_Tuple2('Otilde', 213),
				_Utils_Tuple2('Ouml', 214),
				_Utils_Tuple2('times', 215),
				_Utils_Tuple2('Oslash', 216),
				_Utils_Tuple2('Ugrave', 217),
				_Utils_Tuple2('Uacute', 218),
				_Utils_Tuple2('Ucirc', 219),
				_Utils_Tuple2('Uuml', 220),
				_Utils_Tuple2('Yacute', 221),
				_Utils_Tuple2('THORN', 222),
				_Utils_Tuple2('szlig', 223),
				_Utils_Tuple2('agrave', 224),
				_Utils_Tuple2('aacute', 225),
				_Utils_Tuple2('acirc', 226),
				_Utils_Tuple2('atilde', 227),
				_Utils_Tuple2('auml', 228),
				_Utils_Tuple2('aring', 229),
				_Utils_Tuple2('aelig', 230),
				_Utils_Tuple2('ccedil', 231),
				_Utils_Tuple2('egrave', 232),
				_Utils_Tuple2('eacute', 233),
				_Utils_Tuple2('ecirc', 234),
				_Utils_Tuple2('euml', 235),
				_Utils_Tuple2('igrave', 236),
				_Utils_Tuple2('iacute', 237),
				_Utils_Tuple2('icirc', 238),
				_Utils_Tuple2('iuml', 239),
				_Utils_Tuple2('eth', 240),
				_Utils_Tuple2('ntilde', 241),
				_Utils_Tuple2('ograve', 242),
				_Utils_Tuple2('oacute', 243),
				_Utils_Tuple2('ocirc', 244),
				_Utils_Tuple2('otilde', 245),
				_Utils_Tuple2('ouml', 246),
				_Utils_Tuple2('divide', 247),
				_Utils_Tuple2('oslash', 248),
				_Utils_Tuple2('ugrave', 249),
				_Utils_Tuple2('uacute', 250),
				_Utils_Tuple2('ucirc', 251),
				_Utils_Tuple2('uuml', 252),
				_Utils_Tuple2('yacute', 253),
				_Utils_Tuple2('thorn', 254),
				_Utils_Tuple2('yuml', 255),
				_Utils_Tuple2('Amacr', 256),
				_Utils_Tuple2('amacr', 257),
				_Utils_Tuple2('Abreve', 258),
				_Utils_Tuple2('abreve', 259),
				_Utils_Tuple2('Aogon', 260),
				_Utils_Tuple2('aogon', 261),
				_Utils_Tuple2('Cacute', 262),
				_Utils_Tuple2('cacute', 263),
				_Utils_Tuple2('Ccirc', 264),
				_Utils_Tuple2('ccirc', 265),
				_Utils_Tuple2('Cdod', 266),
				_Utils_Tuple2('cdot', 267),
				_Utils_Tuple2('Ccaron', 268),
				_Utils_Tuple2('ccaron', 269),
				_Utils_Tuple2('Dcaron', 270),
				_Utils_Tuple2('dcaron', 271),
				_Utils_Tuple2('Dstork', 272),
				_Utils_Tuple2('dstork', 273),
				_Utils_Tuple2('Emacr', 274),
				_Utils_Tuple2('emacr', 275),
				_Utils_Tuple2('Edot', 278),
				_Utils_Tuple2('edot', 279),
				_Utils_Tuple2('Eogon', 280),
				_Utils_Tuple2('eogon', 281),
				_Utils_Tuple2('Ecaron', 282),
				_Utils_Tuple2('ecaron', 283),
				_Utils_Tuple2('Gcirc', 284),
				_Utils_Tuple2('gcirc', 285),
				_Utils_Tuple2('Gbreve', 286),
				_Utils_Tuple2('gbreve', 287),
				_Utils_Tuple2('Gdot', 288),
				_Utils_Tuple2('gdot', 289),
				_Utils_Tuple2('Gcedil', 290),
				_Utils_Tuple2('gcedil', 291),
				_Utils_Tuple2('Hcirc', 292),
				_Utils_Tuple2('hcirc', 293),
				_Utils_Tuple2('Hstork', 294),
				_Utils_Tuple2('hstork', 295),
				_Utils_Tuple2('Itilde', 296),
				_Utils_Tuple2('itilde', 297),
				_Utils_Tuple2('Imacr', 298),
				_Utils_Tuple2('imacr', 299),
				_Utils_Tuple2('Iogon', 302),
				_Utils_Tuple2('iogon', 303),
				_Utils_Tuple2('Idot', 304),
				_Utils_Tuple2('inodot', 305),
				_Utils_Tuple2('IJlog', 306),
				_Utils_Tuple2('ijlig', 307),
				_Utils_Tuple2('Jcirc', 308),
				_Utils_Tuple2('jcirc', 309),
				_Utils_Tuple2('Kcedil', 310),
				_Utils_Tuple2('kcedil', 311),
				_Utils_Tuple2('kgreen', 312),
				_Utils_Tuple2('Lacute', 313),
				_Utils_Tuple2('lacute', 314),
				_Utils_Tuple2('Lcedil', 315),
				_Utils_Tuple2('lcedil', 316),
				_Utils_Tuple2('Lcaron', 317),
				_Utils_Tuple2('lcaron', 318),
				_Utils_Tuple2('Lmodot', 319),
				_Utils_Tuple2('lmidot', 320),
				_Utils_Tuple2('Lstork', 321),
				_Utils_Tuple2('lstork', 322),
				_Utils_Tuple2('Nacute', 323),
				_Utils_Tuple2('nacute', 324),
				_Utils_Tuple2('Ncedil', 325),
				_Utils_Tuple2('ncedil', 326),
				_Utils_Tuple2('Ncaron', 327),
				_Utils_Tuple2('ncaron', 328),
				_Utils_Tuple2('napos', 329),
				_Utils_Tuple2('ENG', 330),
				_Utils_Tuple2('eng', 331),
				_Utils_Tuple2('Omacr', 332),
				_Utils_Tuple2('omacr', 333),
				_Utils_Tuple2('Odblac', 336),
				_Utils_Tuple2('odblac', 337),
				_Utils_Tuple2('OEling', 338),
				_Utils_Tuple2('oelig', 339),
				_Utils_Tuple2('Racute', 340),
				_Utils_Tuple2('racute', 341),
				_Utils_Tuple2('Rcedil', 342),
				_Utils_Tuple2('rcedil', 343),
				_Utils_Tuple2('Rcaron', 344),
				_Utils_Tuple2('rcaron', 345),
				_Utils_Tuple2('Sacute', 346),
				_Utils_Tuple2('sacute', 347),
				_Utils_Tuple2('Scirc', 348),
				_Utils_Tuple2('scirc', 349),
				_Utils_Tuple2('Scedil', 350),
				_Utils_Tuple2('scedil', 351),
				_Utils_Tuple2('Scaron', 352),
				_Utils_Tuple2('scaron', 353),
				_Utils_Tuple2('Tcedil', 354),
				_Utils_Tuple2('tcedil', 355),
				_Utils_Tuple2('Tcaron', 356),
				_Utils_Tuple2('tcaron', 357),
				_Utils_Tuple2('Tstork', 358),
				_Utils_Tuple2('tstork', 359),
				_Utils_Tuple2('Utilde', 360),
				_Utils_Tuple2('utilde', 361),
				_Utils_Tuple2('Umacr', 362),
				_Utils_Tuple2('umacr', 363),
				_Utils_Tuple2('Ubreve', 364),
				_Utils_Tuple2('ubreve', 365),
				_Utils_Tuple2('Uring', 366),
				_Utils_Tuple2('uring', 367),
				_Utils_Tuple2('Udblac', 368),
				_Utils_Tuple2('udblac', 369),
				_Utils_Tuple2('Uogon', 370),
				_Utils_Tuple2('uogon', 371),
				_Utils_Tuple2('Wcirc', 372),
				_Utils_Tuple2('wcirc', 373),
				_Utils_Tuple2('Ycirc', 374),
				_Utils_Tuple2('ycirc', 375),
				_Utils_Tuple2('Yuml', 376),
				_Utils_Tuple2('Zacute', 377),
				_Utils_Tuple2('zacute', 378),
				_Utils_Tuple2('Zdot', 379),
				_Utils_Tuple2('zdot', 380),
				_Utils_Tuple2('Zcaron', 381),
				_Utils_Tuple2('zcaron', 382),
				_Utils_Tuple2('fnof', 402),
				_Utils_Tuple2('imped', 437),
				_Utils_Tuple2('gacute', 501),
				_Utils_Tuple2('jmath', 567),
				_Utils_Tuple2('circ', 710),
				_Utils_Tuple2('tilde', 732),
				_Utils_Tuple2('Alpha', 913),
				_Utils_Tuple2('Beta', 914),
				_Utils_Tuple2('Gamma', 915),
				_Utils_Tuple2('Delta', 916),
				_Utils_Tuple2('Epsilon', 917),
				_Utils_Tuple2('Zeta', 918),
				_Utils_Tuple2('Eta', 919),
				_Utils_Tuple2('Theta', 920),
				_Utils_Tuple2('Iota', 921),
				_Utils_Tuple2('Kappa', 922),
				_Utils_Tuple2('Lambda', 923),
				_Utils_Tuple2('Mu', 924),
				_Utils_Tuple2('Nu', 925),
				_Utils_Tuple2('Xi', 926),
				_Utils_Tuple2('Omicron', 927),
				_Utils_Tuple2('Pi', 928),
				_Utils_Tuple2('Rho', 929),
				_Utils_Tuple2('Sigma', 931),
				_Utils_Tuple2('Tau', 932),
				_Utils_Tuple2('Upsilon', 933),
				_Utils_Tuple2('Phi', 934),
				_Utils_Tuple2('Chi', 935),
				_Utils_Tuple2('Psi', 936),
				_Utils_Tuple2('Omega', 937),
				_Utils_Tuple2('alpha', 945),
				_Utils_Tuple2('beta', 946),
				_Utils_Tuple2('gamma', 947),
				_Utils_Tuple2('delta', 948),
				_Utils_Tuple2('epsilon', 949),
				_Utils_Tuple2('zeta', 950),
				_Utils_Tuple2('eta', 951),
				_Utils_Tuple2('theta', 952),
				_Utils_Tuple2('iota', 953),
				_Utils_Tuple2('kappa', 954),
				_Utils_Tuple2('lambda', 955),
				_Utils_Tuple2('mu', 956),
				_Utils_Tuple2('nu', 957),
				_Utils_Tuple2('xi', 958),
				_Utils_Tuple2('omicron', 959),
				_Utils_Tuple2('pi', 960),
				_Utils_Tuple2('rho', 961),
				_Utils_Tuple2('sigmaf', 962),
				_Utils_Tuple2('sigma', 963),
				_Utils_Tuple2('tau', 934),
				_Utils_Tuple2('upsilon', 965),
				_Utils_Tuple2('phi', 966),
				_Utils_Tuple2('chi', 967),
				_Utils_Tuple2('psi', 968),
				_Utils_Tuple2('omega', 969),
				_Utils_Tuple2('thetasym', 977),
				_Utils_Tuple2('upsih', 978),
				_Utils_Tuple2('straightphi', 981),
				_Utils_Tuple2('piv', 982),
				_Utils_Tuple2('Gammad', 988),
				_Utils_Tuple2('gammad', 989),
				_Utils_Tuple2('varkappa', 1008),
				_Utils_Tuple2('varrho', 1009),
				_Utils_Tuple2('straightepsilon', 1013),
				_Utils_Tuple2('backepsilon', 1014),
				_Utils_Tuple2('ensp', 8194),
				_Utils_Tuple2('emsp', 8195),
				_Utils_Tuple2('thinsp', 8201),
				_Utils_Tuple2('zwnj', 8204),
				_Utils_Tuple2('zwj', 8205),
				_Utils_Tuple2('lrm', 8206),
				_Utils_Tuple2('rlm', 8207),
				_Utils_Tuple2('ndash', 8211),
				_Utils_Tuple2('mdash', 8212),
				_Utils_Tuple2('lsquo', 8216),
				_Utils_Tuple2('rsquo', 8217),
				_Utils_Tuple2('sbquo', 8218),
				_Utils_Tuple2('ldquo', 8220),
				_Utils_Tuple2('rdquo', 8221),
				_Utils_Tuple2('bdquo', 8222),
				_Utils_Tuple2('dagger', 8224),
				_Utils_Tuple2('Dagger', 8225),
				_Utils_Tuple2('bull', 8226),
				_Utils_Tuple2('hellip', 8230),
				_Utils_Tuple2('permil', 8240),
				_Utils_Tuple2('prime', 8242),
				_Utils_Tuple2('Prime', 8243),
				_Utils_Tuple2('lsaquo', 8249),
				_Utils_Tuple2('rsaquo', 8250),
				_Utils_Tuple2('oline', 8254),
				_Utils_Tuple2('frasl', 8260),
				_Utils_Tuple2('sigma', 963),
				_Utils_Tuple2('euro', 8364),
				_Utils_Tuple2('image', 8465),
				_Utils_Tuple2('weierp', 8472),
				_Utils_Tuple2('real', 8476),
				_Utils_Tuple2('trade', 8482),
				_Utils_Tuple2('alefsym', 8501),
				_Utils_Tuple2('larr', 8592),
				_Utils_Tuple2('uarr', 8593),
				_Utils_Tuple2('rarr', 8594),
				_Utils_Tuple2('darr', 8595),
				_Utils_Tuple2('harr', 8596),
				_Utils_Tuple2('crarr', 8629),
				_Utils_Tuple2('lArr', 8656),
				_Utils_Tuple2('uArr', 8657),
				_Utils_Tuple2('rArr', 8658),
				_Utils_Tuple2('dArr', 8659),
				_Utils_Tuple2('hArr', 8660),
				_Utils_Tuple2('forall', 8704),
				_Utils_Tuple2('part', 8706),
				_Utils_Tuple2('exist', 8707),
				_Utils_Tuple2('empty', 8709),
				_Utils_Tuple2('nabla', 8711),
				_Utils_Tuple2('isin', 8712),
				_Utils_Tuple2('notin', 8713),
				_Utils_Tuple2('ni', 8715),
				_Utils_Tuple2('prod', 8719),
				_Utils_Tuple2('sum', 8721),
				_Utils_Tuple2('minus', 8722),
				_Utils_Tuple2('lowast', 8727),
				_Utils_Tuple2('radic', 8730),
				_Utils_Tuple2('prop', 8733),
				_Utils_Tuple2('infin', 8734),
				_Utils_Tuple2('ang', 8736),
				_Utils_Tuple2('and', 8743),
				_Utils_Tuple2('or', 8744),
				_Utils_Tuple2('cap', 8745),
				_Utils_Tuple2('cup', 8746),
				_Utils_Tuple2('int', 8747),
				_Utils_Tuple2('there4', 8756),
				_Utils_Tuple2('sim', 8764),
				_Utils_Tuple2('cong', 8773),
				_Utils_Tuple2('asymp', 8776),
				_Utils_Tuple2('ne', 8800),
				_Utils_Tuple2('equiv', 8801),
				_Utils_Tuple2('le', 8804),
				_Utils_Tuple2('ge', 8805),
				_Utils_Tuple2('sub', 8834),
				_Utils_Tuple2('sup', 8835),
				_Utils_Tuple2('nsub', 8836),
				_Utils_Tuple2('sube', 8838),
				_Utils_Tuple2('supe', 8839),
				_Utils_Tuple2('oplus', 8853),
				_Utils_Tuple2('otimes', 8855),
				_Utils_Tuple2('perp', 8869),
				_Utils_Tuple2('sdot', 8901),
				_Utils_Tuple2('loz', 9674),
				_Utils_Tuple2('spades', 9824),
				_Utils_Tuple2('clubs', 9827),
				_Utils_Tuple2('hearts', 9829),
				_Utils_Tuple2('diams', 9830)
			])));
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$convertFriendlyCodeToChar = function (string) {
	return A2($elm$core$Dict$get, string, $marcosh$elm_html_to_unicode$ElmEscapeHtml$friendlyConverterDictionary);
};
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$convertFriendlyCode = A2(
	$marcosh$elm_html_to_unicode$ElmEscapeHtml$convertCode,
	$marcosh$elm_html_to_unicode$ElmEscapeHtml$convertFriendlyCodeToChar,
	function (_char) {
		return _List_fromArray(
			[_char]);
	});
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$convertDecimalCode = A2(
	$marcosh$elm_html_to_unicode$ElmEscapeHtml$convertCode,
	$elm$core$String$toInt,
	function (_int) {
		return _List_fromArray(
			[
				$elm$core$Char$fromCode(_int)
			]);
	});
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$charOffset = F2(
	function (basis, c) {
		return $elm$core$Char$toCode(c) - $elm$core$Char$toCode(basis);
	});
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$isBetween = F3(
	function (lower, upper, c) {
		var ci = $elm$core$Char$toCode(c);
		return (_Utils_cmp(
			$elm$core$Char$toCode(lower),
			ci) < 1) && (_Utils_cmp(
			ci,
			$elm$core$Char$toCode(upper)) < 1);
	});
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$intFromChar = function (c) {
	var validInt = function (i) {
		return (i < 16) ? $elm$core$Maybe$Just(i) : $elm$core$Maybe$Nothing;
	};
	var toInt = A3($marcosh$elm_html_to_unicode$ElmEscapeHtml$isBetween, '0', '9', c) ? $elm$core$Maybe$Just(
		A2($marcosh$elm_html_to_unicode$ElmEscapeHtml$charOffset, '0', c)) : (A3($marcosh$elm_html_to_unicode$ElmEscapeHtml$isBetween, 'a', 'z', c) ? $elm$core$Maybe$Just(
		10 + A2($marcosh$elm_html_to_unicode$ElmEscapeHtml$charOffset, 'a', c)) : (A3($marcosh$elm_html_to_unicode$ElmEscapeHtml$isBetween, 'A', 'Z', c) ? $elm$core$Maybe$Just(
		10 + A2($marcosh$elm_html_to_unicode$ElmEscapeHtml$charOffset, 'A', c)) : $elm$core$Maybe$Nothing));
	return A2($elm$core$Maybe$andThen, validInt, toInt);
};
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$parseIntR = function (string) {
	var _v0 = $elm$core$String$uncons(string);
	if (_v0.$ === 1) {
		return $elm$core$Maybe$Just(0);
	} else {
		var _v1 = _v0.a;
		var c = _v1.a;
		var tail = _v1.b;
		return A2(
			$elm$core$Maybe$andThen,
			function (ci) {
				return A2(
					$elm$core$Maybe$andThen,
					function (ri) {
						return $elm$core$Maybe$Just(ci + (ri * 16));
					},
					$marcosh$elm_html_to_unicode$ElmEscapeHtml$parseIntR(tail));
			},
			$marcosh$elm_html_to_unicode$ElmEscapeHtml$intFromChar(c));
	}
};
var $elm$core$String$reverse = _String_reverse;
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$parseIntHex = function (string) {
	return $marcosh$elm_html_to_unicode$ElmEscapeHtml$parseIntR(
		$elm$core$String$reverse(string));
};
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$convertHexadecimalCode = A2(
	$marcosh$elm_html_to_unicode$ElmEscapeHtml$convertCode,
	$marcosh$elm_html_to_unicode$ElmEscapeHtml$parseIntHex,
	function (_int) {
		return _List_fromArray(
			[
				$elm$core$Char$fromCode(_int)
			]);
	});
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$convertNumericalCode = F3(
	function (pre, post, list) {
		if (!list.b) {
			return $elm$core$List$concat(
				_List_fromArray(
					[pre, post]));
		} else {
			if ('x' === list.a) {
				var tail = list.b;
				return A3(
					$marcosh$elm_html_to_unicode$ElmEscapeHtml$convertHexadecimalCode,
					A2(
						$elm$core$List$append,
						pre,
						_List_fromArray(
							['x'])),
					post,
					tail);
			} else {
				var anyOtherList = list;
				return A3($marcosh$elm_html_to_unicode$ElmEscapeHtml$convertDecimalCode, pre, post, anyOtherList);
			}
		}
	});
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$noAmpUnicodeConverter = F3(
	function (pre, post, list) {
		if (!list.b) {
			return _List_fromArray(
				[pre, post]);
		} else {
			if ('#' === list.a) {
				var tail = list.b;
				return A3(
					$marcosh$elm_html_to_unicode$ElmEscapeHtml$convertNumericalCode,
					_List_fromArray(
						[pre, '#']),
					_List_fromArray(
						[post]),
					tail);
			} else {
				var head = list.a;
				var tail = list.b;
				return A3(
					$marcosh$elm_html_to_unicode$ElmEscapeHtml$convertFriendlyCode,
					_List_fromArray(
						[pre]),
					_List_fromArray(
						[post]),
					A2($elm$core$List$cons, head, tail));
			}
		}
	});
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$unicodeConverter = F2(
	function (post, list) {
		if (!list.b) {
			return _List_fromArray(
				[post]);
		} else {
			var head = list.a;
			var tail = list.b;
			return A3($marcosh$elm_html_to_unicode$ElmEscapeHtml$noAmpUnicodeConverter, head, post, tail);
		}
	});
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$parser = F3(
	function (charsToBeParsed, charsOnParsing, charsParsed) {
		parser:
		while (true) {
			if (!charsToBeParsed.b) {
				return charsParsed;
			} else {
				var head = charsToBeParsed.a;
				var tail = charsToBeParsed.b;
				if (head === '&') {
					var $temp$charsToBeParsed = tail,
						$temp$charsOnParsing = _List_fromArray(
						[head]),
						$temp$charsParsed = charsParsed;
					charsToBeParsed = $temp$charsToBeParsed;
					charsOnParsing = $temp$charsOnParsing;
					charsParsed = $temp$charsParsed;
					continue parser;
				} else {
					if (head === ';') {
						var $temp$charsToBeParsed = tail,
							$temp$charsOnParsing = _List_Nil,
							$temp$charsParsed = A2(
							$elm$core$List$append,
							charsParsed,
							A2($marcosh$elm_html_to_unicode$ElmEscapeHtml$unicodeConverter, head, charsOnParsing));
						charsToBeParsed = $temp$charsToBeParsed;
						charsOnParsing = $temp$charsOnParsing;
						charsParsed = $temp$charsParsed;
						continue parser;
					} else {
						if (!$elm$core$List$isEmpty(charsOnParsing)) {
							var $temp$charsToBeParsed = tail,
								$temp$charsOnParsing = A2(
								$elm$core$List$append,
								charsOnParsing,
								_List_fromArray(
									[head])),
								$temp$charsParsed = charsParsed;
							charsToBeParsed = $temp$charsToBeParsed;
							charsOnParsing = $temp$charsOnParsing;
							charsParsed = $temp$charsParsed;
							continue parser;
						} else {
							var $temp$charsToBeParsed = tail,
								$temp$charsOnParsing = _List_Nil,
								$temp$charsParsed = A2(
								$elm$core$List$append,
								charsParsed,
								_List_fromArray(
									[head]));
							charsToBeParsed = $temp$charsToBeParsed;
							charsOnParsing = $temp$charsOnParsing;
							charsParsed = $temp$charsParsed;
							continue parser;
						}
					}
				}
			}
		}
	});
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$unescapeChars = function (list) {
	return A3($marcosh$elm_html_to_unicode$ElmEscapeHtml$parser, list, _List_Nil, _List_Nil);
};
var $marcosh$elm_html_to_unicode$ElmEscapeHtml$unescape = $marcosh$elm_html_to_unicode$ElmEscapeHtml$convert($marcosh$elm_html_to_unicode$ElmEscapeHtml$unescapeChars);
var $author$project$Views$AssessmentView$appendRationale = F3(
	function (isValidated, currentQuestion, viewData) {
		var rationaleText = A2(
			$elm$core$Maybe$andThen,
			function (itemScore) {
				return A2(
					$elm$core$Maybe$andThen,
					$author$project$Views$AssessmentView$questionRationaleText(itemScore),
					itemScore.bi);
			},
			currentQuestion);
		var parsedRationale = function (rationale) {
			return A2(
				$elm$core$Result$withDefault,
				_List_fromArray(
					[
						$visotype$elm_dom$Dom$render(
						A2(
							$visotype$elm_dom$Dom$appendText,
							$marcosh$elm_html_to_unicode$ElmEscapeHtml$unescape(rationale),
							$visotype$elm_dom$Dom$element('span')))
					]),
				A2(
					$elm$core$Result$map,
					$hecrj$html_parser$Html$Parser$Util$toVirtualDom,
					$hecrj$html_parser$Html$Parser$run(
						A3(
							$elm$core$String$replace,
							' < ',
							' &lt; ',
							$marcosh$elm_html_to_unicode$ElmEscapeHtml$unescape(rationale)))));
		};
		var audioStatus = viewData.gj ? 'on' : 'off';
		var content = function () {
			if (!rationaleText.$) {
				var rationale = rationaleText.a;
				return isValidated ? A2(
					$visotype$elm_dom$Dom$appendChild,
					A2(
						$visotype$elm_dom$Dom$appendChild,
						A2(
							$visotype$elm_dom$Dom$appendNodeList,
							parsedRationale(rationale),
							$visotype$elm_dom$Dom$element('span')),
						A2(
							$visotype$elm_dom$Dom$addClass,
							'distractor-rationale--text',
							$visotype$elm_dom$Dom$element('div'))),
					A2(
						$visotype$elm_dom$Dom$addClassList,
						_List_fromArray(
							['distractor-rationale']),
						A2(
							$visotype$elm_dom$Dom$addAttribute,
							A2($elm$html$Html$Attributes$attribute, 'data-audio-status', audioStatus),
							A2(
								$visotype$elm_dom$Dom$setId,
								'distractor-rationale-v2',
								$visotype$elm_dom$Dom$element('div'))))) : $visotype$elm_dom$Dom$element('span');
			} else {
				return $visotype$elm_dom$Dom$element('span');
			}
		}();
		return $visotype$elm_dom$Dom$render(content);
	});
var $author$project$Models$AssessmentStatus$assessmentScoresForStatus = function (assessmentStatus) {
	switch (assessmentStatus.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var assessmentScores = assessmentStatus.a;
			return $elm$core$Maybe$Just(assessmentScores);
		default:
			var assessmentScores = assessmentStatus.b;
			return $elm$core$Maybe$Just(assessmentScores);
	}
};
var $author$project$Models$StudentActivity$assessmentStatus = function (studentActivity) {
	var _v0 = studentActivity.ir;
	switch (_v0.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			if (_v0.a.$ === 1) {
				var aAssessmentStatus = _v0.a.a;
				return $elm$core$Maybe$Just(aAssessmentStatus);
			} else {
				if (_v0.a.b.$ === 1) {
					var _v1 = _v0.a;
					var aAssessmentStatus = _v1.b.a;
					return $elm$core$Maybe$Just(aAssessmentStatus);
				} else {
					var _v2 = _v0.a;
					var _v3 = _v2.b;
					return $elm$core$Maybe$Nothing;
				}
			}
		case 2:
			if (!_v0.b.$) {
				var _v4 = _v0.b;
				return $elm$core$Maybe$Nothing;
			} else {
				var aAssessmentStatus = _v0.b.a;
				return $elm$core$Maybe$Just(aAssessmentStatus);
			}
		case 4:
			return $elm$core$Maybe$Nothing;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$StudentActivity$assessmentScores = function (studentActivity) {
	return A2(
		$elm$core$Maybe$andThen,
		$author$project$Models$AssessmentStatus$assessmentScoresForStatus,
		$author$project$Models$StudentActivity$assessmentStatus(studentActivity));
};
var $author$project$Models$AssessmentStatus$currentQuestion = $elm_community$list_extra$List$Extra$find(
	function ($) {
		return $.ar;
	});
var $author$project$Models$StudentActivity$itemScores = function (_v0) {
	var progress = _v0.ir;
	var assessmentResult = _v0.gd;
	switch (progress.$) {
		case 1:
			var activeActivityData = progress.a;
			var _v2 = $author$project$Models$StudentActivity$assessmentStatusIfPresent(activeActivityData);
			if (!_v2.$) {
				switch (_v2.a.$) {
					case 2:
						var _v3 = _v2.a;
						var scores = _v3.b;
						return scores;
					case 1:
						var scores = _v2.a.a;
						return scores;
					default:
						var _v4 = _v2.a;
						return A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.iN;
								},
								assessmentResult));
				}
			} else {
				return _List_Nil;
			}
		case 2:
			var activityAssessmentProgress = progress.b;
			if (activityAssessmentProgress.$ === 1) {
				switch (activityAssessmentProgress.a.$) {
					case 2:
						var _v6 = activityAssessmentProgress.a;
						var scores = _v6.b;
						return scores;
					case 1:
						var scores = activityAssessmentProgress.a.a;
						return scores;
					default:
						var _v7 = activityAssessmentProgress.a;
						return A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.iN;
								},
								assessmentResult));
				}
			} else {
				return _List_Nil;
			}
		case 0:
			return _List_Nil;
		case 3:
			return _List_Nil;
		default:
			return _List_Nil;
	}
};
var $author$project$Models$StudentActivity$currentQuestion = A2($elm$core$Basics$composeR, $author$project$Models$StudentActivity$itemScores, $author$project$Models$AssessmentStatus$currentQuestion);
var $author$project$Models$AssessmentStatus$QuestionUnanswered = {$: 0};
var $author$project$Models$AssessmentStatus$AnswerSelected = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$AssessmentStatus$Failed = 3;
var $author$project$Models$AssessmentStatus$NotValidated = 0;
var $author$project$Models$AssessmentStatus$Passed = 2;
var $author$project$Models$AssessmentStatus$ShouldRetry = 1;
var $author$project$Models$AssessmentStatus$maxMultipleAttemptCount = 3;
var $author$project$Models$AssessmentStatus$assessmentQuestionStatus = F2(
	function (activityType, itemScore) {
		var _v0 = itemScore.iM;
		switch (_v0) {
			case 0:
				return $author$project$Models$AssessmentStatus$QuestionUnanswered;
			case 1:
				return $author$project$Models$AssessmentStatus$AnswerSelected(0);
			case 2:
				return ((_Utils_cmp(itemScore.iI, $author$project$Models$AssessmentStatus$maxMultipleAttemptCount) < 0) && _Utils_eq(activityType, $author$project$Models$Activity$Challenge)) ? $author$project$Models$AssessmentStatus$AnswerSelected(1) : $author$project$Models$AssessmentStatus$AnswerSelected(3);
			default:
				return $author$project$Models$AssessmentStatus$AnswerSelected(2);
		}
	});
var $author$project$Models$AssessmentStatus$assessmentCurrentQuestionStatus = F2(
	function (activityType, assessmentScores) {
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			$author$project$Models$AssessmentStatus$QuestionUnanswered,
			$author$project$Models$AssessmentStatus$assessmentQuestionStatus(activityType),
			$author$project$Models$AssessmentStatus$currentQuestion(assessmentScores));
	});
var $author$project$Models$StudentActivity$getCurrentQuestionStatus = function (studentActivity) {
	return $author$project$Models$AssessmentStatus$assessmentCurrentQuestionStatus(studentActivity.f_.f0);
};
var $author$project$Models$StudentActivity$currentQuestionStatus = function (studentActivity) {
	return A2(
		$elm$core$Maybe$map,
		$author$project$Models$StudentActivity$getCurrentQuestionStatus(studentActivity),
		$author$project$Models$StudentActivity$assessmentScores(studentActivity));
};
var $author$project$Models$ItemScore$allowedLearnosityCategoryTags = _List_fromArray(
	['feature', 'style']);
var $author$project$Models$ItemScore$filterByAllowedLearnosityTags = function (tagsList) {
	return A2(
		$elm$core$List$filter,
		function (tag) {
			return A2(
				$elm$core$List$any,
				function (allowedTag) {
					return A2($elm$core$String$contains, allowedTag + ':', tag);
				},
				$author$project$Models$ItemScore$allowedLearnosityCategoryTags);
		},
		tagsList);
};
var $author$project$Models$WindowInfo$hasOverflow = function (widowInfo) {
	return _Utils_cmp(widowInfo.hb, widowInfo.fN) > 0;
};
var $author$project$Component$QuizProgressDots$Small = 0;
var $author$project$Models$Activity$assessmentTitle = function (activity) {
	var _v0 = activity.f0;
	switch (_v0.$) {
		case 7:
			return 'Pre-Quiz';
		case 8:
			return 'Post-Quiz';
		case 6:
			return 'Challenge Question';
		case 1:
			return 'Check for Understanding';
		case 2:
			return 'Check for Understanding';
		case 0:
			return 'Check for Understanding';
		case 3:
			return 'Check for Understanding';
		case 4:
			return 'Check for Understanding';
		default:
			return 'Check for Understanding';
	}
};
var $author$project$ES$UI$Color$green = {
	at: A3($avh4$elm_color$Color$rgb255, 104, 174, 0),
	au: A3($avh4$elm_color$Color$rgb255, 83, 139, 0),
	ay: A3($avh4$elm_color$Color$rgb255, 147, 213, 49),
	az: A3($avh4$elm_color$Color$rgb255, 190, 230, 131),
	aA: A3($avh4$elm_color$Color$rgb255, 221, 240, 193)
};
var $author$project$Styles$correct = A2(
	$elm$core$Basics$composeR,
	$visotype$elm_dom$Dom$addStyle(
		_Utils_Tuple2('border', '0px')),
	$author$project$Styles$filled($author$project$ES$UI$Color$green.at));
var $elm_community$string_extra$String$Extra$regexFromString = A2(
	$elm$core$Basics$composeR,
	$elm$regex$Regex$fromString,
	$elm$core$Maybe$withDefault($elm$regex$Regex$never));
var $elm_community$string_extra$String$Extra$dasherize = function (string) {
	return $elm$core$String$toLower(
		A3(
			$elm$regex$Regex$replace,
			$elm_community$string_extra$String$Extra$regexFromString('[_-\\s]+'),
			$elm$core$Basics$always('-'),
			A3(
				$elm$regex$Regex$replace,
				$elm_community$string_extra$String$Extra$regexFromString('([A-Z])'),
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.hE;
					},
					$elm$core$String$append('-')),
				$elm$core$String$trim(string))));
};
var $author$project$Styles$dot = function (elementSize) {
	return $visotype$elm_dom$Dom$addStyleList(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'height',
				$elm$core$String$fromInt(elementSize) + 'px'),
				_Utils_Tuple2(
				'width',
				$elm$core$String$fromInt(elementSize) + 'px'),
				_Utils_Tuple2(
				'border-radius',
				$elm$core$String$fromInt(elementSize) + 'px'),
				_Utils_Tuple2('border', '2px solid rgba(0, 0, 0, 0.1)'),
				_Utils_Tuple2('display', 'inline-block'),
				_Utils_Tuple2('z-index', '10000'),
				_Utils_Tuple2('margin', '0 10px 0 5px')
			]));
};
var $author$project$Conversions$String$fromBool = function (_boolean) {
	return _boolean ? 'True' : 'False';
};
var $author$project$Styles$incorrect = $visotype$elm_dom$Dom$addStyleList(
	_List_fromArray(
		[
			_Utils_Tuple2('background-color', 'rgba(0, 0, 0, 0.1)'),
			_Utils_Tuple2('border', '0px')
		]));
var $author$project$Models$ItemScore$isCorrect = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.iM;
	},
	$elm$core$Basics$eq(3));
var $author$project$Models$ItemScore$isNotCorrect = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.iM;
	},
	$elm$core$Basics$eq(2));
var $author$project$Models$ItemScore$scoreStatusToString = function (scoreStatus) {
	switch (scoreStatus) {
		case 0:
			return 'NotAnswered';
		case 1:
			return 'NotValidated';
		case 2:
			return 'Incorrect';
		default:
			return 'Correct';
	}
};
var $elm_community$string_extra$String$Extra$underscored = function (string) {
	return $elm$core$String$toLower(
		A3(
			$elm$regex$Regex$replace,
			$elm_community$string_extra$String$Extra$regexFromString('[_-\\s]+'),
			$elm$core$Basics$always('_'),
			A3(
				$elm$regex$Regex$replace,
				$elm_community$string_extra$String$Extra$regexFromString('([a-z\\d])([A-Z]+)'),
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.jk;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$filterMap($elm$core$Basics$identity),
						$elm$core$String$join('_'))),
				$elm$core$String$trim(string))));
};
var $author$project$Component$QuizProgressDots$dots = F2(
	function (itemScores, dotSize) {
		var dot = function (itemScore) {
			var active = itemScore.ar;
			var scoreStatus = itemScore.iM;
			var sizeInPixels = function () {
				var _v0 = _Utils_Tuple2(dotSize, active);
				if (_v0.a === 1) {
					if (!_v0.b) {
						var _v1 = _v0.a;
						return 30;
					} else {
						var _v2 = _v0.a;
						return 40;
					}
				} else {
					if (!_v0.b) {
						var _v3 = _v0.a;
						return 25;
					} else {
						var _v4 = _v0.a;
						return 30;
					}
				}
			}();
			return A2(
				$author$project$Dom$Extra$addDataAttribute,
				_Utils_Tuple2(
					'quiz-progress-item-score',
					$elm_community$string_extra$String$Extra$dasherize(
						$elm_community$string_extra$String$Extra$underscored(
							$author$project$Models$ItemScore$scoreStatusToString(scoreStatus)))),
				A2(
					$author$project$Dom$Extra$addDataAttribute,
					_Utils_Tuple2(
						'quiz-progress-item-active',
						$author$project$Conversions$String$fromBool(active)),
					A2(
						$author$project$Dom$Extra$addDataAttribute,
						_Utils_Tuple2('quiz-progress-item', ''),
						A2(
							$author$project$Styles$conditional,
							_Utils_Tuple2(
								$author$project$Styles$incorrect,
								$author$project$Models$ItemScore$isNotCorrect(itemScore)),
							A2(
								$author$project$Styles$conditional,
								_Utils_Tuple2(
									$author$project$Styles$correct,
									$author$project$Models$ItemScore$isCorrect(itemScore)),
								A2(
									$author$project$Styles$conditional,
									_Utils_Tuple2(
										$visotype$elm_dom$Dom$addStyle(
											_Utils_Tuple2('margin-bottom', '2px')),
										!active),
									A2(
										$author$project$Styles$dot,
										sizeInPixels,
										$visotype$elm_dom$Dom$element('span'))))))));
		};
		return A2(
			$visotype$elm_dom$Dom$appendChildList,
			A2($elm$core$List$map, dot, itemScores),
			$visotype$elm_dom$Dom$element('div'));
	});
var $author$project$Views$Components$Button$WithGlobalMsg$quizPrevious = function (message) {
	return A2(
		$author$project$Dom$Extra$addDataCy,
		'previous-button',
		A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['is-paddingless', 'has-pointer-events-enabled']),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('margin-right', '11px'),
							_Utils_Tuple2('margin-bottom', '7px')
						]),
					A2(
						$author$project$Component$Text$colored,
						$author$project$ES$UI$Color$white,
						$author$project$Component$Icon$render(
							A2(
								$author$project$Component$Icon$Icon,
								4,
								$author$project$Component$Icon$Custom('29px'))))),
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('background-color', '#14A1AE'),
							_Utils_Tuple2('position', 'relative'),
							_Utils_Tuple2('left', '0'),
							_Utils_Tuple2('border-bottom-right-radius', '48px')
						]),
					A2(
						$author$project$Styles$square,
						48,
						A2(
							$visotype$elm_dom$Dom$addAttribute,
							A2($elm$html$Html$Attributes$attribute, 'aria-label', 'close'),
							A2(
								$visotype$elm_dom$Dom$addAction,
								_Utils_Tuple2('click', message),
								$visotype$elm_dom$Dom$element('button'))))))));
};
var $author$project$Views$Components$ActivityBackButton$quizRouteAction = F2(
	function (appFlowMode, studentActivity) {
		return $author$project$Models$Activity$hasPracticeContent(studentActivity.f_) ? $author$project$Messages$NavigateTo(
			$author$project$Routes$ActivityRoute(
				A2($author$project$Routes$ActivityRoutes$Practice, studentActivity.a_, studentActivity.hj))) : A2($author$project$Views$Components$ActivityBackButton$contentRouteAction, appFlowMode, studentActivity);
	});
var $author$project$Views$Components$ActivityBackButton$renderForProgressBar = F2(
	function (appFlowMode, studentActivity) {
		var isRemedial = studentActivity.hs;
		return A3(
			$author$project$Views$Components$ActivityBackButton$render,
			isRemedial,
			$author$project$Views$Components$Button$WithGlobalMsg$quizPrevious,
			A2($author$project$Views$Components$ActivityBackButton$quizRouteAction, appFlowMode, studentActivity));
	});
var $author$project$Styles$setFixedPosition = function (_v0) {
	var top = _v0.jy;
	var right = _v0.iJ;
	var bottom = _v0.gt;
	var left = _v0.hx;
	return $visotype$elm_dom$Dom$addStyleList(
		_List_fromArray(
			[
				_Utils_Tuple2('position', 'fixed'),
				_Utils_Tuple2('top', top),
				_Utils_Tuple2('right', right),
				_Utils_Tuple2('bottom', bottom),
				_Utils_Tuple2('left', left)
			]));
};
var $author$project$Component$QuizProgressDots$header = F2(
	function (appFlowMode, studentActivity) {
		var progressDots = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('display', 'inline-block'),
					_Utils_Tuple2('margin-top', '3px'),
					_Utils_Tuple2('padding-right', '10px')
				]),
			A2(
				$author$project$Component$QuizProgressDots$dots,
				$author$project$Models$StudentActivity$itemScores(studentActivity),
				0));
		var previousButton = $author$project$Models$Activity$isPrePostQuiz(studentActivity.f_) ? $visotype$elm_dom$Dom$element('span') : A2($author$project$Views$Components$ActivityBackButton$renderForProgressBar, appFlowMode, studentActivity);
		var activityName = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('position', 'relative'),
					_Utils_Tuple2('top', '-6px'),
					_Utils_Tuple2('margin', ' 6px 8px 0px 8px'),
					_Utils_Tuple2('display', 'inline-block')
				]),
			A2(
				$author$project$Component$Text$withSize,
				4,
				A2(
					$author$project$Dom$Extra$addDataCy,
					'quiz-type',
					A2(
						$visotype$elm_dom$Dom$appendText,
						$author$project$Models$Activity$assessmentTitle(studentActivity.f_),
						$visotype$elm_dom$Dom$element('div')))));
		var headerContent = $author$project$Models$Activity$isPrePostQuiz(studentActivity.f_) ? _List_fromArray(
			[previousButton, activityName, progressDots]) : _List_fromArray(
			[previousButton, activityName]);
		return A2(
			$author$project$Dom$Extra$addDataAttribute,
			_Utils_Tuple2('quiz-progress', 'true'),
			A2(
				$author$project$Styles$onTop,
				300,
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('border-bottom-right-radius', '48px'),
							_Utils_Tuple2('height', '48px'),
							_Utils_Tuple2('margin', 'auto'),
							_Utils_Tuple2('padding', '0 10px 0 0')
						]),
					A2(
						$visotype$elm_dom$Dom$addClassList,
						_List_fromArray(
							['has-text-grey-light', 'is-marginless']),
						$author$project$Styles$shadow(
							A2(
								$author$project$Styles$filled,
								$author$project$ES$UI$Color$white,
								A2(
									$author$project$Styles$setFixedPosition,
									{gt: '', hx: '0px', iJ: '', jy: '0px'},
									A2(
										$visotype$elm_dom$Dom$appendChildList,
										headerContent,
										$visotype$elm_dom$Dom$element('div')))))))));
	});
var $author$project$Models$AssessmentStatus$isValidated = function (maybeQuestionStatus) {
	if (!maybeQuestionStatus.$) {
		if (maybeQuestionStatus.a.$ === 1) {
			switch (maybeQuestionStatus.a.a) {
				case 2:
					var _v1 = maybeQuestionStatus.a.a;
					return true;
				case 3:
					var _v2 = maybeQuestionStatus.a.a;
					return true;
				case 1:
					var _v3 = maybeQuestionStatus.a.a;
					return true;
				default:
					var _v4 = maybeQuestionStatus.a.a;
					return false;
			}
		} else {
			var _v5 = maybeQuestionStatus.a;
			return false;
		}
	} else {
		return false;
	}
};
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $elm$html$Html$Lazy$lazy3 = $elm$virtual_dom$VirtualDom$lazy3;
var $author$project$Models$AssessmentStatus$questionAnsweredAndNoRetriesRemain = function (questionStatus) {
	if (questionStatus.$ === 1) {
		switch (questionStatus.a) {
			case 2:
				var _v1 = questionStatus.a;
				return true;
			case 3:
				var _v2 = questionStatus.a;
				return true;
			case 0:
				var _v3 = questionStatus.a;
				return false;
			default:
				var _v4 = questionStatus.a;
				return false;
		}
	} else {
		return false;
	}
};
var $author$project$Learnosity$Messages$AdvanceToNextLearnosityQuestionV2 = {$: 5};
var $author$project$Component$Buttons$Button$Blue = 12;
var $author$project$Component$Icon$Check = 8;
var $author$project$Learnosity$Messages$EndLearnosityAssessmentReviewV2 = {$: 6};
var $author$project$Learnosity$Messages$RetryLearnosityQuestionV2 = {$: 9};
var $author$project$Learnosity$Messages$SubmitLearnosityAssessmentV2 = {$: 8};
var $author$project$Learnosity$Messages$ValidateLearnosityAnswerV2 = {$: 4};
var $author$project$Views$AssessmentView$actionButtonCssAnimationPreset = function (message) {
	_v0$4:
	while (true) {
		if (message.$ === 9) {
			switch (message.a.$) {
				case 4:
					var _v1 = message.a;
					return 5;
				case 9:
					var _v2 = message.a;
					return 5;
				case 8:
					var _v3 = message.a;
					return 5;
				case 5:
					var _v4 = message.a;
					return 5;
				default:
					break _v0$4;
			}
		} else {
			break _v0$4;
		}
	}
	return 9;
};
var $author$project$Models$AssessmentStatus$isLastQuestion = function (itemScores) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.ar;
			},
			$elm_community$list_extra$List$Extra$last(itemScores)));
};
var $author$project$Styles$placement = function (edges) {
	var toRule = function (_v0) {
		var property = _v0.a;
		var func = _v0.b;
		return A2(
			$elm$core$Maybe$map,
			function (val) {
				return _Utils_Tuple2(property, val);
			},
			func(edges));
	};
	return $visotype$elm_dom$Dom$addStyleList(
		A2(
			$elm$core$List$filterMap,
			toRule,
			_List_fromArray(
				[
					_Utils_Tuple2(
					'top',
					function ($) {
						return $.jy;
					}),
					_Utils_Tuple2(
					'right',
					function ($) {
						return $.iJ;
					}),
					_Utils_Tuple2(
					'bottom',
					function ($) {
						return $.gt;
					}),
					_Utils_Tuple2(
					'left',
					function ($) {
						return $.hx;
					})
				])));
};
var $author$project$Views$AssessmentView$quizActionButton = function (maybeAssessmentStatus) {
	var _v0 = function () {
		_v1$2:
		while (true) {
			if (!maybeAssessmentStatus.$) {
				switch (maybeAssessmentStatus.a.$) {
					case 1:
						var itemScores = maybeAssessmentStatus.a.a;
						return $author$project$Models$AssessmentStatus$isLastQuestion(itemScores) ? _Utils_Tuple3(
							$author$project$Messages$LearnosityMessage($author$project$Learnosity$Messages$EndLearnosityAssessmentReviewV2),
							0,
							5) : _Utils_Tuple3(
							$author$project$Messages$LearnosityMessage($author$project$Learnosity$Messages$AdvanceToNextLearnosityQuestionV2),
							0,
							5);
					case 2:
						var _v2 = maybeAssessmentStatus.a;
						var assessmentQuestionStatus = _v2.a;
						var itemScores = _v2.b;
						if (assessmentQuestionStatus.$ === 1) {
							switch (assessmentQuestionStatus.a) {
								case 0:
									var _v4 = assessmentQuestionStatus.a;
									return _Utils_Tuple3(
										$author$project$Messages$LearnosityMessage($author$project$Learnosity$Messages$ValidateLearnosityAnswerV2),
										12,
										8);
								case 1:
									var _v5 = assessmentQuestionStatus.a;
									return _Utils_Tuple3(
										$author$project$Messages$LearnosityMessage($author$project$Learnosity$Messages$RetryLearnosityQuestionV2),
										4,
										8);
								default:
									return $author$project$Models$AssessmentStatus$isLastQuestion(itemScores) ? _Utils_Tuple3(
										$author$project$Messages$LearnosityMessage($author$project$Learnosity$Messages$SubmitLearnosityAssessmentV2),
										0,
										5) : _Utils_Tuple3(
										$author$project$Messages$LearnosityMessage($author$project$Learnosity$Messages$AdvanceToNextLearnosityQuestionV2),
										0,
										5);
							}
						} else {
							return _Utils_Tuple3($author$project$Messages$NoOp, 15, 8);
						}
					default:
						break _v1$2;
				}
			} else {
				break _v1$2;
			}
		}
		return _Utils_Tuple3($author$project$Messages$NoOp, 15, 8);
	}();
	var message = _v0.a;
	var color = _v0.b;
	var icon = _v0.c;
	return A2(
		$author$project$Animations$CssAnimations$applyWithAnimationPreset,
		$author$project$Views$AssessmentView$actionButtonCssAnimationPreset(message),
		A2(
			$author$project$Styles$placement,
			{
				gt: $elm$core$Maybe$Just('16px'),
				hx: $elm$core$Maybe$Nothing,
				iJ: $elm$core$Maybe$Just('16px'),
				jy: $elm$core$Maybe$Nothing
			},
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('position', 'absolute'),
				A2(
					$author$project$Styles$onTop,
					10,
					A2(
						$visotype$elm_dom$Dom$addClassList,
						_List_fromArray(
							['es-lrn-button']),
						A2(
							$author$project$Dom$Extra$addClickAction,
							message,
							A2(
								$author$project$Component$Buttons$Action$button,
								0,
								_Utils_Tuple3(
									0,
									color,
									A2($author$project$Component$Icon$Icon, icon, $author$project$Component$Icon$Large)))))))));
};
var $author$project$Assets$learnosityLogo = require('/usr/src/app/app/images/learnosity-link.svg');
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$Component$Link$out = F2(
	function (_v0, elements) {
		var href = _v0.hf;
		var target = _v0.jq;
		return A2(
			$visotype$elm_dom$Dom$addAttribute,
			$elm$html$Html$Attributes$target(
				A2($elm$core$Maybe$withDefault, '_self', target)),
			A2(
				$visotype$elm_dom$Dom$addAttribute,
				$elm$html$Html$Attributes$href(href),
				A2(
					$visotype$elm_dom$Dom$appendChildList,
					elements,
					$visotype$elm_dom$Dom$element('a'))));
	});
var $author$project$Views$AssessmentView$quizPreviewLink = F2(
	function (studentActivity, _v0) {
		var isDevelopment = _v0.ed;
		var learnosityOrgId = isDevelopment ? '457' : '456';
		var learnosityAuthorUrl = function (ref) {
			return 'https://author.learnosity.com/org/' + (learnosityOrgId + ('/item/editornew/?ref=' + ref));
		};
		var buttonContent = function (_v1) {
			var ref = _v1.iA;
			return A2(
				$author$project$Dom$Extra$addDataCy,
				'view-on-learnosity',
				A2(
					$author$project$Styles$placement,
					{
						gt: $elm$core$Maybe$Just('30px'),
						hx: $elm$core$Maybe$Nothing,
						iJ: $elm$core$Maybe$Just('300px'),
						jy: $elm$core$Maybe$Nothing
					},
					A2(
						$visotype$elm_dom$Dom$addClassList,
						_List_fromArray(
							['view-on-learnosity']),
						A2(
							$author$project$Component$Link$out,
							{
								hf: learnosityAuthorUrl(ref),
								jq: $elm$core$Maybe$Just('_blank')
							},
							$elm$core$List$singleton(
								$author$project$Component$Image$render(
									{
										f5: 'view on learnosity',
										hb: 50,
										ig: $author$project$Assets$path($author$project$Assets$learnosityLogo),
										jS: 50
									}))))));
		};
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('position', 'fixed'),
					_Utils_Tuple2('right', '130px'),
					_Utils_Tuple2('bottom', '30px'),
					_Utils_Tuple2('z-index', '150'),
					_Utils_Tuple2('height', '50px'),
					_Utils_Tuple2('width', '50px')
				]),
			A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				$visotype$elm_dom$Dom$element('span'),
				buttonContent,
				A2(
					$elm_community$list_extra$List$Extra$find,
					function ($) {
						return $.ar;
					},
					$author$project$Models$StudentActivity$itemScores(studentActivity))));
	});
var $author$project$Views$Components$LearnosityAssessPlayer$render = function (_v0) {
	return A2(
		$visotype$elm_dom$Dom$appendChild,
		A2(
			$visotype$elm_dom$Dom$setId,
			'learnosity_assess',
			$visotype$elm_dom$Dom$element('div')),
		A2(
			$visotype$elm_dom$Dom$addClass,
			'learnosity-assess-container',
			$visotype$elm_dom$Dom$element('div')));
};
var $visotype$elm_dom$Dom$appendChildListConditional = F2(
	function (le, test) {
		if (test) {
			return $visotype$elm_dom$Dom$appendChildList(le);
		} else {
			return function (x) {
				return x;
			};
		}
	});
var $author$project$Views$Components$ScrollIndicator$Down = 1;
var $author$project$Views$Components$ScrollIndicator$Up = 0;
var $author$project$Models$WindowInfo$Mobile = 2;
var $author$project$Models$WindowInfo$Desktop = 0;
var $author$project$Models$WindowInfo$Tablet = 1;
var $author$project$Models$WindowInfo$windowDeviceSize = function (windowInfo) {
	var windowWidth = windowInfo.fO;
	return (windowWidth > 1024) ? 0 : ((windowWidth > 640) ? 1 : 2);
};
var $author$project$Models$WindowInfo$isNotMobileDeviceSize = function (windowInfo) {
	return $author$project$Models$WindowInfo$windowDeviceSize(windowInfo) !== 2;
};
var $author$project$Views$Components$Button$quizScrollDown = function (message) {
	return A2(
		$visotype$elm_dom$Dom$addAction,
		_Utils_Tuple2('click', message),
		A2(
			$author$project$Component$Buttons$Button$addShapeClass,
			0,
			A2(
				$author$project$Component$Buttons$Action$button,
				0,
				_Utils_Tuple3(
					0,
					0,
					A2($author$project$Component$Icon$Icon, 3, $author$project$Component$Icon$Small)))));
};
var $author$project$Component$Buttons$Button$SemiCircleDown = 1;
var $author$project$Views$Components$Button$quizScrollUp = function (message) {
	return A2(
		$visotype$elm_dom$Dom$addAction,
		_Utils_Tuple2('click', message),
		A2(
			$author$project$Component$Buttons$Button$addShapeClass,
			1,
			A2(
				$author$project$Component$Buttons$Action$button,
				0,
				_Utils_Tuple3(
					0,
					0,
					A2($author$project$Component$Icon$Icon, 2, $author$project$Component$Icon$Small)))));
};
var $author$project$Views$Components$ScrollIndicator$scrollContainerYPos = F2(
	function (windowInfo, direction) {
		var scrollDelta = 200.0;
		if (!direction) {
			return windowInfo.iP - scrollDelta;
		} else {
			return windowInfo.iP + scrollDelta;
		}
	});
var $author$project$Views$Components$ScrollIndicator$scrollButton = F4(
	function (scrollMsg, position, windowInfo, scrollDirection) {
		var positionStyles = function () {
			if (position === 1) {
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'right',
						$elm$core$String$fromFloat(windowInfo.hS + 30) + 'px')
					]);
			} else {
				return _List_fromArray(
					[
						_Utils_Tuple2('right', '50%'),
						_Utils_Tuple2('transform', 'translate(50%, 0)')
					]);
			}
		}();
		if (!scrollDirection) {
			return A3(
				$visotype$elm_dom$Dom$addStyleListConditional,
				positionStyles,
				$author$project$Models$WindowInfo$isNotMobileDeviceSize(windowInfo),
				$author$project$Views$Components$Button$quizScrollUp(
					scrollMsg(
						A2($author$project$Views$Components$ScrollIndicator$scrollContainerYPos, windowInfo, 0))));
		} else {
			return A3(
				$visotype$elm_dom$Dom$addStyleListConditional,
				positionStyles,
				$author$project$Models$WindowInfo$isNotMobileDeviceSize(windowInfo),
				$author$project$Views$Components$Button$quizScrollDown(
					scrollMsg(
						A2($author$project$Views$Components$ScrollIndicator$scrollContainerYPos, windowInfo, 1))));
		}
	});
var $author$project$Views$Components$ScrollIndicator$scrollButtons = F3(
	function (scrollMsg, position, windowInfo) {
		var _v0 = windowInfo.iO;
		switch (_v0) {
			case 0:
				return _List_fromArray(
					[
						A4($author$project$Views$Components$ScrollIndicator$scrollButton, scrollMsg, position, windowInfo, 1)
					]);
			case 2:
				return _List_fromArray(
					[
						A4($author$project$Views$Components$ScrollIndicator$scrollButton, scrollMsg, position, windowInfo, 0)
					]);
			default:
				return _List_fromArray(
					[
						A4($author$project$Views$Components$ScrollIndicator$scrollButton, scrollMsg, position, windowInfo, 0),
						A4($author$project$Views$Components$ScrollIndicator$scrollButton, scrollMsg, position, windowInfo, 1)
					]);
		}
	});
var $author$project$Views$Components$ScrollIndicator$view = F3(
	function (scrollMsg, position, windowInfo) {
		return A3(
			$visotype$elm_dom$Dom$appendChildListConditional,
			A3($author$project$Views$Components$ScrollIndicator$scrollButtons, scrollMsg, position, windowInfo),
			$author$project$Models$WindowInfo$hasOverflow(windowInfo),
			$visotype$elm_dom$Dom$element('div'));
	});
var $author$project$Views$AssessmentView$view = F4(
	function (assessmentMode, studentActivity, _v0, viewData) {
		var appFlowMode = viewData.f8;
		var productFeatures = viewData.iq;
		var windowHasOverflow = A3($elm_community$maybe_extra$Maybe$Extra$unwrap, false, $author$project$Models$WindowInfo$hasOverflow, viewData.jT);
		var quizFeatureTypes = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			_List_Nil,
			function ($) {
				return $.aP;
			},
			$author$project$Models$StudentActivity$currentQuestion(studentActivity));
		var quizFeatureTypesClass = A2(
			$elm$core$List$map,
			function (feature) {
				return 'lrn-es-feature-type-' + feature;
			},
			quizFeatureTypes);
		var quizFeatureTypeAttributes = A2(
			$elm$core$List$map,
			function (feature) {
				return A2($elm$html$Html$Attributes$attribute, 'data-lrn-es-feature-type-' + feature, 'true');
			},
			quizFeatureTypes);
		var questionTagClasses = A2(
			$elm$core$List$map,
			function (tag) {
				return 'lrn-es-' + A3($elm$core$String$replace, ':', '-', tag);
			},
			$author$project$Models$ItemScore$filterByAllowedLearnosityTags(
				A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					_List_Nil,
					function ($) {
						return $.jp;
					},
					$author$project$Models$StudentActivity$currentQuestion(studentActivity))));
		var preLink = (!assessmentMode) ? A2($author$project$Views$AssessmentView$quizPreviewLink, studentActivity, viewData) : $visotype$elm_dom$Dom$element('span');
		var quizContent = A2(
			$visotype$elm_dom$Dom$appendChild,
			A2(
				$visotype$elm_dom$Dom$setChildListWithKeys,
				_List_fromArray(
					[
						_Utils_Tuple2('prelink', preLink),
						_Utils_Tuple2(
						'qa-button',
						$author$project$Views$AssessmentView$quizActionButton(
							$author$project$Models$StudentActivity$assessmentStatus(studentActivity))),
						_Utils_Tuple2(
						'rationale',
						A2(
							$visotype$elm_dom$Dom$appendNode,
							A4(
								$elm$html$Html$Lazy$lazy3,
								$author$project$Views$AssessmentView$appendRationale,
								$author$project$Models$AssessmentStatus$isValidated(
									$author$project$Models$StudentActivity$currentQuestionStatus(studentActivity)),
								A2(
									$elm$core$Maybe$andThen,
									$author$project$Models$AssessmentStatus$currentQuestion,
									$author$project$Models$StudentActivity$assessmentScores(studentActivity)),
								viewData),
							$visotype$elm_dom$Dom$element('div'))),
						_Utils_Tuple2(
						'scroll-buttons',
						A3(
							$elm_community$maybe_extra$Maybe$Extra$unwrap,
							$visotype$elm_dom$Dom$element('div'),
							A2($author$project$Views$Components$ScrollIndicator$view, $author$project$Messages$ScrollContainerTo, 1),
							viewData.jT))
					]),
				$visotype$elm_dom$Dom$element('div')),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						A2($author$project$Component$QuizProgressDots$header, appFlowMode, studentActivity),
						$author$project$Views$Components$LearnosityAssessPlayer$render(productFeatures),
						A2($author$project$Views$ActivityPracticeView$RatingsModal$render, studentActivity, viewData)
					]),
				A2(
					$author$project$Styles$onTop,
					12,
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('position', 'relative'),
								_Utils_Tuple2('overflow', 'hidden')
							]),
						$visotype$elm_dom$Dom$element('div')))));
		var loaderImage = A2(
			$visotype$elm_dom$Dom$appendChild,
			A2(
				$visotype$elm_dom$Dom$appendChild,
				$author$project$Views$Components$Spinner$foldingCube,
				A2(
					$visotype$elm_dom$Dom$addClass,
					'es-loader--quiz',
					$visotype$elm_dom$Dom$element('div'))),
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('position', 'fixed'),
				A2(
					$author$project$Styles$onTop,
					11,
					A2(
						$visotype$elm_dom$Dom$addClass,
						'es-loader__wrapper',
						$visotype$elm_dom$Dom$element('div')))));
		var currentQuestionHasBeenAnsweredAndNoRetries = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			false,
			$author$project$Models$AssessmentStatus$questionAnsweredAndNoRetriesRemain,
			$author$project$Models$StudentActivity$currentQuestionStatus(studentActivity));
		var activityHasFeatures = !$elm$core$List$isEmpty(quizFeatureTypeAttributes);
		return A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[loaderImage, quizContent]),
			A2(
				$visotype$elm_dom$Dom$addClassList,
				questionTagClasses,
				A3(
					$visotype$elm_dom$Dom$addClassListConditional,
					quizFeatureTypesClass,
					activityHasFeatures,
					A3(
						$visotype$elm_dom$Dom$addAttributeListConditional,
						quizFeatureTypeAttributes,
						activityHasFeatures,
						A3(
							$visotype$elm_dom$Dom$addClassConditional,
							'lrn-es-question-answered',
							currentQuestionHasBeenAnsweredAndNoRetries,
							A3(
								$visotype$elm_dom$Dom$addClassConditional,
								'lrn-es-has-overflow',
								windowHasOverflow,
								A2(
									$visotype$elm_dom$Dom$addClass,
									$author$project$AppFlow$toString(viewData.f8),
									A2(
										$visotype$elm_dom$Dom$setId,
										'learnosity-content-v2',
										$visotype$elm_dom$Dom$element('div')))))))));
	});
var $author$project$Views$Components$ScrollIndicator$Center = 0;
var $author$project$Views$ComponentView$nextButton = F2(
	function (studentActivity, viewData) {
		var message = $author$project$Messages$LoggedInRequiredMsg(
			$author$project$LoggedIn$Messages$SkipActivity(studentActivity.hj));
		return A2(
			$author$project$Animations$CssAnimations$applyWithAnimationPreset,
			5,
			A2(
				$visotype$elm_dom$Dom$addClass,
				'next-activity-button',
				A2(
					$author$project$Views$Components$Button$WithGlobalMsg$next,
					viewData.gv,
					A2($author$project$Messages$ActivityNextButtonClicked, studentActivity, message))));
	});
var $author$project$Views$ComponentView$bottomBar = F2(
	function (studentActivity, viewData) {
		return A2(
			$author$project$Styles$onTop,
			3,
			$author$project$Component$BottomBar$customBar(
				{
					gq: $author$project$ES$UI$Color$teal.aA,
					gH: $visotype$elm_dom$Dom$element('span'),
					R: A2($author$project$Views$ComponentView$nextButton, studentActivity, viewData),
					io: $visotype$elm_dom$Dom$element('span')
				}));
	});
var $author$project$Views$ComponentView$view = F4(
	function (_v0, studentActivity, _v1, viewData) {
		return $author$project$Models$StudentActivity$isErrored(studentActivity) ? A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					A2(
					$author$project$Views$Error$render,
					$author$project$Messages$PlayTTSSpeechFromNode,
					A2(
						$author$project$Views$Error$customOopsMessage,
						'Oops, something went wrong.',
						$author$project$Views$Error$configView('Let\'s move on!'))),
					A2($author$project$Views$ComponentView$bottomBar, studentActivity, viewData)
				]),
			$visotype$elm_dom$Dom$element('div')) : A2(
			$visotype$elm_dom$Dom$setChildListWithKeys,
			_List_fromArray(
				[
					_Utils_Tuple2(
					'component-container',
					A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('min-height', '100vh'),
						A2(
							$visotype$elm_dom$Dom$addClass,
							'component-container',
							$visotype$elm_dom$Dom$element('div')))),
					_Utils_Tuple2(
					'scroll-buttons',
					A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						$visotype$elm_dom$Dom$element('div'),
						A2($author$project$Views$Components$ScrollIndicator$view, $author$project$Messages$ScrollContainerTo, 0),
						viewData.jT))
				]),
			$visotype$elm_dom$Dom$element('div'));
	});
var $author$project$Views$QuestHomeView$noLoadedQuestView = A2($author$project$Views$Components$LoadingIndicator$render, 'loading-student-quest', 'Hold tight while we load your quest!');
var $author$project$Assets$QuestTheme$SeaA = 2;
var $author$project$Assets$QuestTheme$SeaB = 3;
var $author$project$Assets$QuestTheme$SkyA = 0;
var $author$project$Assets$QuestTheme$SkyB = 1;
var $author$project$Animations$CssAnimations$BounceIn = 0;
var $author$project$Animations$CssAnimations$TadaWithDelay2 = 4;
var $author$project$Assets$QuestTheme$RandomAssetSet = F2(
	function (pool, _default) {
		return {dL: _default, eM: pool};
	});
var $author$project$Assets$QuestTheme$celebrationAnimationClasses = A2(
	$author$project$Assets$QuestTheme$RandomAssetSet,
	_List_fromArray(
		['celebration-sparkle-01', 'celebration-sparkle-02', 'celebration-sparkle-03']),
	'celebration-sparkle-01');
var $author$project$Assets$QuestTheme$fakeRandomAsset = F2(
	function (path, seed) {
		var _v0 = A2($elm$core$Basics$modBy, seed, 1);
		return path;
	});
var $author$project$Assets$QuestTheme$getRandomAsset = F2(
	function (_v0, seed) {
		var pool = _v0.eM;
		var _default = _v0.dL;
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A2(
				$elm_community$list_extra$List$Extra$getAt,
				A2(
					$elm$core$Basics$modBy,
					$elm$core$List$length(pool),
					seed),
				pool));
	});
var $author$project$Assets$QuestTheme$planetActivityAssets = function (theme) {
	var skyB = A2(
		$author$project$Assets$QuestTheme$RandomAssetSet,
		_List_fromArray(
			[
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_stars_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_stars_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_stars_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_candy_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_candy_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_candy_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_garden_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_garden_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_garden_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_snail_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_snail_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_snail_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_ship_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_ship_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_ship_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_rainbow_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_rainbow_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_rainbow_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_dragon_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_dragon_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_dragon_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_starsgold_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_starsgold_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_starsgold_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_cat_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_cat_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_cat_inactive.png')
			}
			]),
		{
			ar: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_cat_active.png'),
			aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_cat_completed.png')),
			ax: require('/usr/src/app/app/images/quest-themes/sky_B/stations/sky_B_cat_inactive.png')
		});
	var skyA = A2(
		$author$project$Assets$QuestTheme$RandomAssetSet,
		_List_fromArray(
			[
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_crystal_red_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_crystal_red_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_crystal_red_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_carrot_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_carrot_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_carrot_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_mushrooms2_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_mushrooms2_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_mushrooms2_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_trio_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_trio_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_trio_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_crystal_blue_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_crystal_blue_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_crystal_blue_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_mushrooms1_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_mushrooms1_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_mushrooms1_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_tree_orange_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_tree_orange_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_tree_orange_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_tree_blue_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_tree_blue_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_tree_blue_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_castle_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_castle_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_castle_inactive.png')
			}
			]),
		{
			ar: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_castle_active.png'),
			aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_castle_completed.png')),
			ax: require('/usr/src/app/app/images/quest-themes/sky_A/stations/sky_A_castle_inactive.png')
		});
	var seaB = A2(
		$author$project$Assets$QuestTheme$RandomAssetSet,
		_List_fromArray(
			[
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_hut_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_hut_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_hut_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_chests2_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_chests2_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_chests2_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_ferns_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_ferns_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_ferns_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_snake_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_snake_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_snake_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_tiki_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_tiki_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_tiki_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_chests3_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_chests3_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_chests3_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_chests1_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_chests1_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_chests1_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_coconuts_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_coconuts_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_coconuts_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_bamboo_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_bamboo_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_bamboo_inactive.png')
			}
			]),
		{
			ar: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_bamboo_active.png'),
			aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_bamboo_completed.png')),
			ax: require('/usr/src/app/app/images/quest-themes/sea_B/stations/sea_B_bamboo_inactive.png')
		});
	var seaA = A2(
		$author$project$Assets$QuestTheme$RandomAssetSet,
		_List_fromArray(
			[
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_monster_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_monster_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_monster_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_iceberg_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_iceberg_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_iceberg_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_turtle_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_turtle_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_turtle_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_ruins_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_ruins_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_ruins_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_sub_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_sub_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_sub_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_bottle_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_bottle_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_bottle_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_whale_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_whale_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_whale_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_shell_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_shell_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_shell_inactive.png')
			},
				{
				ar: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_raft_active.png'),
				aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_raft_completed.png')),
				ax: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_raft_inactive.png')
			}
			]),
		{
			ar: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_monster_active.png'),
			aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_monster_completed.png')),
			ax: require('/usr/src/app/app/images/quest-themes/sea_A/stations/sea_A_monster_inactive.png')
		});
	switch (theme) {
		case 0:
			return skyA;
		case 1:
			return skyB;
		case 2:
			return seaA;
		default:
			return seaB;
	}
};
var $author$project$Assets$QuestTheme$planetStation = function (theme) {
	return {
		f_: $author$project$Assets$QuestTheme$getRandomAsset(
			$author$project$Assets$QuestTheme$planetActivityAssets(theme)),
		dm: {
			ar: {
				bB: $author$project$Animations$CssAnimations$preset(4)
			},
			dy: {
				bB: $author$project$Animations$CssAnimations$preset(0),
				eA: $author$project$Assets$QuestTheme$getRandomAsset($author$project$Assets$QuestTheme$celebrationAnimationClasses)
			}
		},
		fG: {
			ar: require('/usr/src/app/app/images/quest-themes/planet/sky_video_active.png'),
			aM: $author$project$Assets$QuestTheme$fakeRandomAsset(require('/usr/src/app/app/images/quest-themes/planet/sky_video_active.png')),
			ax: require('/usr/src/app/app/images/quest-themes/planet/sky_video_inactive.png')
		}
	};
};
var $author$project$Assets$QuestTheme$planet = function (studentQuestId) {
	var skyBAssets = function (theme) {
		return {
			ds: {ff: require('/usr/src/app/app/images/quest-themes/sky_B/sky-bg.png'), ft: require('/usr/src/app/app/images/quest-themes/sky_B/sky-endless.png')},
			dZ: require('/usr/src/app/app/images/quest-themes/sky_B/sky-bg.png'),
			fk: $author$project$Assets$QuestTheme$planetStation(theme)
		};
	};
	var skyAAssets = function (theme) {
		return {
			ds: {ff: require('/usr/src/app/app/images/quest-themes/sky_A/sky-bg.png'), ft: require('/usr/src/app/app/images/quest-themes/sky_A/sky-endless.png')},
			dZ: require('/usr/src/app/app/images/quest-themes/sky_A/sky-bg.png'),
			fk: $author$project$Assets$QuestTheme$planetStation(theme)
		};
	};
	var seaBAssets = function (theme) {
		return {
			ds: {ff: require('/usr/src/app/app/images/quest-themes/sea_B/sea-bg.png'), ft: require('/usr/src/app/app/images/quest-themes/sea_B/sea-endless.png')},
			dZ: require('/usr/src/app/app/images/quest-themes/sea_B/sea-bg.png'),
			fk: $author$project$Assets$QuestTheme$planetStation(theme)
		};
	};
	var seaAAssets = function (theme) {
		return {
			ds: {ff: require('/usr/src/app/app/images/quest-themes/sea_A/sea-bg.png'), ft: require('/usr/src/app/app/images/quest-themes/sea_A/sea-endless.png')},
			dZ: require('/usr/src/app/app/images/quest-themes/sea_A/sea-bg.png'),
			fk: $author$project$Assets$QuestTheme$planetStation(theme)
		};
	};
	var _v0 = A2(
		$elm$core$Basics$modBy,
		4,
		A2(
			$elm$core$Maybe$withDefault,
			0,
			$author$project$Models$Ids$toInt(studentQuestId)));
	switch (_v0) {
		case 0:
			return seaAAssets(2);
		case 1:
			return skyAAssets(0);
		case 2:
			return skyBAssets(1);
		default:
			return seaBAssets(3);
	}
};
var $author$project$LoggedIn$Messages$ClickQuestHomeNextButton = function (a) {
	return {$: 19, a: a};
};
var $author$project$LoggedIn$Messages$CloseCustomizer = {$: 94};
var $author$project$Messages$HoverableMsg = function (a) {
	return {$: 64, a: a};
};
var $author$project$LoggedIn$Messages$OpenCustomizer = {$: 91};
var $author$project$Models$ActiveQuest$QuestCompleted = 2;
var $author$project$Models$LocalStorage$StudentQuestHistory$get = F2(
	function (studentQuestId, history) {
		return A2(
			$elm$core$Dict$get,
			$author$project$Models$Ids$toString(studentQuestId),
			history);
	});
var $author$project$Models$LocalStorage$Helpers$getStudentQuestHistoryEntry = F2(
	function (questId, store) {
		return A2($author$project$Models$LocalStorage$StudentQuestHistory$get, questId, store.c0);
	});
var $author$project$Models$LocalStorage$StudentChoices$isPLE = function (character) {
	switch (character) {
		case 0:
			return false;
		case 1:
			return false;
		case 2:
			return false;
		case 3:
			return false;
		case 4:
			return false;
		case 5:
			return false;
		case 6:
			return false;
		case 7:
			return false;
		case 8:
			return true;
		case 9:
			return true;
		case 10:
			return true;
		case 11:
			return true;
		case 12:
			return true;
		case 13:
			return true;
		case 14:
			return true;
		default:
			return true;
	}
};
var $elm_community$maybe_extra$Maybe$Extra$orElse = F2(
	function (ma, mb) {
		if (mb.$ === 1) {
			return ma;
		} else {
			return mb;
		}
	});
var $author$project$Models$ActiveQuest$ActivitiesCompleted = 1;
var $author$project$Models$ActiveQuest$ActivitiesIncomplete = 0;
var $author$project$Models$ActiveQuest$activitiesComplete = function (activeQuest) {
	return A2(
		$elm$core$List$all,
		$author$project$Models$StudentActivity$isCompleted,
		$author$project$Models$ActiveQuest$availableActivities(activeQuest));
};
var $author$project$Models$ActiveQuest$videoRecordingIncomplete = F2(
	function (includeVideoRecording, activeQuest) {
		var _v0 = activeQuest.jg;
		if (!_v0.$) {
			var studentVideo = _v0.a;
			return includeVideoRecording && (!studentVideo.aM);
		} else {
			return false;
		}
	});
var $author$project$Models$ActiveQuest$questStatus = F2(
	function (includeVideoRecording, activeQuest) {
		return $author$project$Models$ActiveQuest$activitiesComplete(activeQuest) ? (A2($author$project$Models$ActiveQuest$videoRecordingIncomplete, includeVideoRecording, activeQuest) ? 1 : 2) : 0;
	});
var $author$project$Models$Modal$AvatarCustomizationModal = {$: 10};
var $author$project$AvatarCustomizationModal$pleIframeId = 'ple-iframe';
var $author$project$AvatarCustomizationModal$render = F2(
	function (_v0, onClose) {
		var modalState = _v0.hM;
		var pleBaseUrl = _v0.bR;
		var studentAppBuildHash = _v0.b0;
		var characterTheme = _v0.gB;
		var avatarName = $author$project$Models$LocalStorage$StudentChoices$characterName(characterTheme.v);
		var content = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('width', '100%'),
					_Utils_Tuple2('height', '100%')
				]),
			A2(
				$visotype$elm_dom$Dom$addAttribute,
				$elm$html$Html$Attributes$src(pleBaseUrl + ('?version=' + (studentAppBuildHash + ('&scene=customizer&avatar=' + avatarName)))),
				A2(
					$visotype$elm_dom$Dom$setId,
					$author$project$AvatarCustomizationModal$pleIframeId,
					$visotype$elm_dom$Dom$element('iframe'))));
		if ((!modalState.$) && (modalState.a.$ === 10)) {
			var _v2 = modalState.a;
			return A2(
				$author$project$Dom$Extra$addDataCy,
				'avatar-customization-modal',
				A3(
					$author$project$Component$Modal$wrapperWithDimensionUnits,
					$author$project$Models$Modal$AvatarCustomizationModal,
					modalState,
					{
						gH: content,
						hb: 'calc(57vw)',
						hF: $elm$core$Maybe$Just('calc((100vh - 40px) * 1.59)'),
						h5: $elm$core$Maybe$Just(onClose),
						jS: '91vw'
					}));
		} else {
			return $visotype$elm_dom$Dom$element('span');
		}
	});
var $elm$core$Array$repeat = F2(
	function (n, e) {
		return A2(
			$elm$core$Array$initialize,
			n,
			function (_v0) {
				return e;
			});
	});
var $truqu$elm_md5$MD5$emptyWords = A2($elm$core$Array$repeat, 16, 0);
var $truqu$elm_md5$MD5$addUnsigned = F2(
	function (x, y) {
		return 4294967295 & (x + y);
	});
var $truqu$elm_md5$MD5$rotateLeft = F2(
	function (bits, input) {
		return (input << bits) | (input >>> (32 - bits));
	});
var $truqu$elm_md5$MD5$cmn = F8(
	function (fun, a, b, c, d, x, s, ac) {
		return A2(
			$truqu$elm_md5$MD5$addUnsigned,
			b,
			A2(
				$truqu$elm_md5$MD5$rotateLeft,
				s,
				A2(
					$truqu$elm_md5$MD5$addUnsigned,
					a,
					A2(
						$truqu$elm_md5$MD5$addUnsigned,
						ac,
						A2(
							$truqu$elm_md5$MD5$addUnsigned,
							A3(fun, b, c, d),
							x)))));
	});
var $truqu$elm_md5$MD5$f = F3(
	function (x, y, z) {
		return z ^ (x & (y ^ z));
	});
var $truqu$elm_md5$MD5$ff = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$f, a, b, c, d, x, s, ac);
	});
var $truqu$elm_md5$MD5$g = F3(
	function (x, y, z) {
		return y ^ (z & (x ^ y));
	});
var $truqu$elm_md5$MD5$gg = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$g, a, b, c, d, x, s, ac);
	});
var $truqu$elm_md5$MD5$h = F3(
	function (x, y, z) {
		return z ^ (x ^ y);
	});
var $truqu$elm_md5$MD5$hh = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$h, a, b, c, d, x, s, ac);
	});
var $elm$core$Bitwise$complement = _Bitwise_complement;
var $truqu$elm_md5$MD5$i = F3(
	function (x, y, z) {
		return y ^ (x | (~z));
	});
var $truqu$elm_md5$MD5$ii = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$i, a, b, c, d, x, s, ac);
	});
var $truqu$elm_md5$MD5$hex_ = F2(
	function (xs, acc) {
		var a = acc.bu;
		var b = acc.bw;
		var c = acc.by;
		var d = acc.bA;
		if ((((((((((((((((xs.b && xs.b.b) && xs.b.b.b) && xs.b.b.b.b) && xs.b.b.b.b.b) && xs.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && (!xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b)) {
			var x0 = xs.a;
			var _v1 = xs.b;
			var x1 = _v1.a;
			var _v2 = _v1.b;
			var x2 = _v2.a;
			var _v3 = _v2.b;
			var x3 = _v3.a;
			var _v4 = _v3.b;
			var x4 = _v4.a;
			var _v5 = _v4.b;
			var x5 = _v5.a;
			var _v6 = _v5.b;
			var x6 = _v6.a;
			var _v7 = _v6.b;
			var x7 = _v7.a;
			var _v8 = _v7.b;
			var x8 = _v8.a;
			var _v9 = _v8.b;
			var x9 = _v9.a;
			var _v10 = _v9.b;
			var x10 = _v10.a;
			var _v11 = _v10.b;
			var x11 = _v11.a;
			var _v12 = _v11.b;
			var x12 = _v12.a;
			var _v13 = _v12.b;
			var x13 = _v13.a;
			var _v14 = _v13.b;
			var x14 = _v14.a;
			var _v15 = _v14.b;
			var x15 = _v15.a;
			var s44 = 21;
			var s43 = 15;
			var s42 = 10;
			var s41 = 6;
			var s34 = 23;
			var s33 = 16;
			var s32 = 11;
			var s31 = 4;
			var s24 = 20;
			var s23 = 14;
			var s22 = 9;
			var s21 = 5;
			var s14 = 22;
			var s13 = 17;
			var s12 = 12;
			var s11 = 7;
			var d00 = d;
			var c00 = c;
			var b00 = b;
			var a00 = a;
			var a01 = A7($truqu$elm_md5$MD5$ff, a00, b00, c00, d00, x0, s11, 3614090360);
			var d01 = A7($truqu$elm_md5$MD5$ff, d00, a01, b00, c00, x1, s12, 3905402710);
			var c01 = A7($truqu$elm_md5$MD5$ff, c00, d01, a01, b00, x2, s13, 606105819);
			var b01 = A7($truqu$elm_md5$MD5$ff, b00, c01, d01, a01, x3, s14, 3250441966);
			var a02 = A7($truqu$elm_md5$MD5$ff, a01, b01, c01, d01, x4, s11, 4118548399);
			var d02 = A7($truqu$elm_md5$MD5$ff, d01, a02, b01, c01, x5, s12, 1200080426);
			var c02 = A7($truqu$elm_md5$MD5$ff, c01, d02, a02, b01, x6, s13, 2821735955);
			var b02 = A7($truqu$elm_md5$MD5$ff, b01, c02, d02, a02, x7, s14, 4249261313);
			var a03 = A7($truqu$elm_md5$MD5$ff, a02, b02, c02, d02, x8, s11, 1770035416);
			var d03 = A7($truqu$elm_md5$MD5$ff, d02, a03, b02, c02, x9, s12, 2336552879);
			var c03 = A7($truqu$elm_md5$MD5$ff, c02, d03, a03, b02, x10, s13, 4294925233);
			var b03 = A7($truqu$elm_md5$MD5$ff, b02, c03, d03, a03, x11, s14, 2304563134);
			var a04 = A7($truqu$elm_md5$MD5$ff, a03, b03, c03, d03, x12, s11, 1804603682);
			var d04 = A7($truqu$elm_md5$MD5$ff, d03, a04, b03, c03, x13, s12, 4254626195);
			var c04 = A7($truqu$elm_md5$MD5$ff, c03, d04, a04, b03, x14, s13, 2792965006);
			var b04 = A7($truqu$elm_md5$MD5$ff, b03, c04, d04, a04, x15, s14, 1236535329);
			var a05 = A7($truqu$elm_md5$MD5$gg, a04, b04, c04, d04, x1, s21, 4129170786);
			var d05 = A7($truqu$elm_md5$MD5$gg, d04, a05, b04, c04, x6, s22, 3225465664);
			var c05 = A7($truqu$elm_md5$MD5$gg, c04, d05, a05, b04, x11, s23, 643717713);
			var b05 = A7($truqu$elm_md5$MD5$gg, b04, c05, d05, a05, x0, s24, 3921069994);
			var a06 = A7($truqu$elm_md5$MD5$gg, a05, b05, c05, d05, x5, s21, 3593408605);
			var d06 = A7($truqu$elm_md5$MD5$gg, d05, a06, b05, c05, x10, s22, 38016083);
			var c06 = A7($truqu$elm_md5$MD5$gg, c05, d06, a06, b05, x15, s23, 3634488961);
			var b06 = A7($truqu$elm_md5$MD5$gg, b05, c06, d06, a06, x4, s24, 3889429448);
			var a07 = A7($truqu$elm_md5$MD5$gg, a06, b06, c06, d06, x9, s21, 568446438);
			var d07 = A7($truqu$elm_md5$MD5$gg, d06, a07, b06, c06, x14, s22, 3275163606);
			var c07 = A7($truqu$elm_md5$MD5$gg, c06, d07, a07, b06, x3, s23, 4107603335);
			var b07 = A7($truqu$elm_md5$MD5$gg, b06, c07, d07, a07, x8, s24, 1163531501);
			var a08 = A7($truqu$elm_md5$MD5$gg, a07, b07, c07, d07, x13, s21, 2850285829);
			var d08 = A7($truqu$elm_md5$MD5$gg, d07, a08, b07, c07, x2, s22, 4243563512);
			var c08 = A7($truqu$elm_md5$MD5$gg, c07, d08, a08, b07, x7, s23, 1735328473);
			var b08 = A7($truqu$elm_md5$MD5$gg, b07, c08, d08, a08, x12, s24, 2368359562);
			var a09 = A7($truqu$elm_md5$MD5$hh, a08, b08, c08, d08, x5, s31, 4294588738);
			var d09 = A7($truqu$elm_md5$MD5$hh, d08, a09, b08, c08, x8, s32, 2272392833);
			var c09 = A7($truqu$elm_md5$MD5$hh, c08, d09, a09, b08, x11, s33, 1839030562);
			var b09 = A7($truqu$elm_md5$MD5$hh, b08, c09, d09, a09, x14, s34, 4259657740);
			var a10 = A7($truqu$elm_md5$MD5$hh, a09, b09, c09, d09, x1, s31, 2763975236);
			var d10 = A7($truqu$elm_md5$MD5$hh, d09, a10, b09, c09, x4, s32, 1272893353);
			var c10 = A7($truqu$elm_md5$MD5$hh, c09, d10, a10, b09, x7, s33, 4139469664);
			var b10 = A7($truqu$elm_md5$MD5$hh, b09, c10, d10, a10, x10, s34, 3200236656);
			var a11 = A7($truqu$elm_md5$MD5$hh, a10, b10, c10, d10, x13, s31, 681279174);
			var d11 = A7($truqu$elm_md5$MD5$hh, d10, a11, b10, c10, x0, s32, 3936430074);
			var c11 = A7($truqu$elm_md5$MD5$hh, c10, d11, a11, b10, x3, s33, 3572445317);
			var b11 = A7($truqu$elm_md5$MD5$hh, b10, c11, d11, a11, x6, s34, 76029189);
			var a12 = A7($truqu$elm_md5$MD5$hh, a11, b11, c11, d11, x9, s31, 3654602809);
			var d12 = A7($truqu$elm_md5$MD5$hh, d11, a12, b11, c11, x12, s32, 3873151461);
			var c12 = A7($truqu$elm_md5$MD5$hh, c11, d12, a12, b11, x15, s33, 530742520);
			var b12 = A7($truqu$elm_md5$MD5$hh, b11, c12, d12, a12, x2, s34, 3299628645);
			var a13 = A7($truqu$elm_md5$MD5$ii, a12, b12, c12, d12, x0, s41, 4096336452);
			var d13 = A7($truqu$elm_md5$MD5$ii, d12, a13, b12, c12, x7, s42, 1126891415);
			var c13 = A7($truqu$elm_md5$MD5$ii, c12, d13, a13, b12, x14, s43, 2878612391);
			var b13 = A7($truqu$elm_md5$MD5$ii, b12, c13, d13, a13, x5, s44, 4237533241);
			var a14 = A7($truqu$elm_md5$MD5$ii, a13, b13, c13, d13, x12, s41, 1700485571);
			var d14 = A7($truqu$elm_md5$MD5$ii, d13, a14, b13, c13, x3, s42, 2399980690);
			var c14 = A7($truqu$elm_md5$MD5$ii, c13, d14, a14, b13, x10, s43, 4293915773);
			var b14 = A7($truqu$elm_md5$MD5$ii, b13, c14, d14, a14, x1, s44, 2240044497);
			var a15 = A7($truqu$elm_md5$MD5$ii, a14, b14, c14, d14, x8, s41, 1873313359);
			var d15 = A7($truqu$elm_md5$MD5$ii, d14, a15, b14, c14, x15, s42, 4264355552);
			var c15 = A7($truqu$elm_md5$MD5$ii, c14, d15, a15, b14, x6, s43, 2734768916);
			var b15 = A7($truqu$elm_md5$MD5$ii, b14, c15, d15, a15, x13, s44, 1309151649);
			var a16 = A7($truqu$elm_md5$MD5$ii, a15, b15, c15, d15, x4, s41, 4149444226);
			var d16 = A7($truqu$elm_md5$MD5$ii, d15, a16, b15, c15, x11, s42, 3174756917);
			var c16 = A7($truqu$elm_md5$MD5$ii, c15, d16, a16, b15, x2, s43, 718787259);
			var b16 = A7($truqu$elm_md5$MD5$ii, b15, c16, d16, a16, x9, s44, 3951481745);
			var b17 = A2($truqu$elm_md5$MD5$addUnsigned, b00, b16);
			var c17 = A2($truqu$elm_md5$MD5$addUnsigned, c00, c16);
			var d17 = A2($truqu$elm_md5$MD5$addUnsigned, d00, d16);
			var a17 = A2($truqu$elm_md5$MD5$addUnsigned, a00, a16);
			return {bu: a17, bw: b17, by: c17, bA: d17};
		} else {
			return acc;
		}
	});
var $truqu$elm_md5$MD5$iget = F2(
	function (index, array) {
		return A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Array$get, index, array));
	});
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $truqu$elm_md5$MD5$consume = F2(
	function (_char, _v0) {
		var hashState = _v0.a;
		var _v1 = _v0.b;
		var byteCount = _v1.a;
		var words = _v1.b;
		var totalByteCount = _v0.c;
		var wordCount = (byteCount / 4) | 0;
		var oldWord = A2($truqu$elm_md5$MD5$iget, wordCount, words);
		var bytePosition = 8 * (byteCount % 4);
		var code = _char << bytePosition;
		var newWord = oldWord | code;
		var newWords = A3($elm$core$Array$set, wordCount, newWord, words);
		return (byteCount === 63) ? _Utils_Tuple3(
			A2(
				$truqu$elm_md5$MD5$hex_,
				$elm$core$Array$toList(newWords),
				hashState),
			_Utils_Tuple2(0, $truqu$elm_md5$MD5$emptyWords),
			totalByteCount + 1) : _Utils_Tuple3(
			hashState,
			_Utils_Tuple2(byteCount + 1, newWords),
			totalByteCount + 1);
	});
var $truqu$elm_md5$MD5$finishUp = function (_v0) {
	var hashState = _v0.a;
	var _v1 = _v0.b;
	var byteCount = _v1.a;
	var words = _v1.b;
	var totalByteCount = _v0.c;
	var wordCount = (byteCount / 4) | 0;
	var oldWord = A2($truqu$elm_md5$MD5$iget, wordCount, words);
	var bytePosition = 8 * (byteCount % 4);
	var code = 128 << bytePosition;
	var newWord = oldWord | code;
	var newWords = A3($elm$core$Array$set, wordCount, newWord, words);
	return (wordCount < 14) ? function (x) {
		return A2($truqu$elm_md5$MD5$hex_, x, hashState);
	}(
		$elm$core$Array$toList(
			A3(
				$elm$core$Array$set,
				15,
				totalByteCount >>> 29,
				A3($elm$core$Array$set, 14, totalByteCount << 3, newWords)))) : function (x) {
		return A2(
			$truqu$elm_md5$MD5$hex_,
			x,
			A2(
				$truqu$elm_md5$MD5$hex_,
				$elm$core$Array$toList(newWords),
				hashState));
	}(
		$elm$core$Array$toList(
			A3(
				$elm$core$Array$set,
				15,
				totalByteCount >>> 29,
				A3($elm$core$Array$set, 14, totalByteCount << 3, $truqu$elm_md5$MD5$emptyWords))));
};
var $zwilias$elm_utf_tools$String$UTF8$utf32ToUtf8 = F3(
	function (add, _char, acc) {
		return (_char < 128) ? A2(add, _char, acc) : ((_char < 2048) ? A2(
			add,
			128 | (63 & _char),
			A2(add, 192 | (_char >>> 6), acc)) : ((_char < 65536) ? A2(
			add,
			128 | (63 & _char),
			A2(
				add,
				128 | (63 & (_char >>> 6)),
				A2(add, 224 | (_char >>> 12), acc))) : A2(
			add,
			128 | (63 & _char),
			A2(
				add,
				128 | (63 & (_char >>> 6)),
				A2(
					add,
					128 | (63 & (_char >>> 12)),
					A2(add, 240 | (_char >>> 18), acc))))));
	});
var $zwilias$elm_utf_tools$String$UTF8$foldl = F3(
	function (op, initialAcc, input) {
		return A3(
			$elm$core$String$foldl,
			F2(
				function (_char, acc) {
					return A3(
						$zwilias$elm_utf_tools$String$UTF8$utf32ToUtf8,
						op,
						$elm$core$Char$toCode(_char),
						acc);
				}),
			initialAcc,
			input);
	});
var $truqu$elm_md5$MD5$State = F4(
	function (a, b, c, d) {
		return {bu: a, bw: b, by: c, bA: d};
	});
var $truqu$elm_md5$MD5$initialHashState = A4($truqu$elm_md5$MD5$State, 1732584193, 4023233417, 2562383102, 271733878);
var $truqu$elm_md5$MD5$hash = function (input) {
	return $truqu$elm_md5$MD5$finishUp(
		A3(
			$zwilias$elm_utf_tools$String$UTF8$foldl,
			$truqu$elm_md5$MD5$consume,
			_Utils_Tuple3(
				$truqu$elm_md5$MD5$initialHashState,
				_Utils_Tuple2(0, $truqu$elm_md5$MD5$emptyWords),
				0),
			input));
};
var $truqu$elm_md5$MD5$bytes = function (string) {
	var _v0 = $truqu$elm_md5$MD5$hash(string);
	var a = _v0.bu;
	var b = _v0.bw;
	var c = _v0.by;
	var d = _v0.bA;
	return _List_fromArray(
		[a & 255, (a >>> 8) & 255, (a >>> 16) & 255, (a >>> 24) & 255, b & 255, (b >>> 8) & 255, (b >>> 16) & 255, (b >>> 24) & 255, c & 255, (c >>> 8) & 255, (c >>> 16) & 255, (c >>> 24) & 255, d & 255, (d >>> 8) & 255, (d >>> 16) & 255, (d >>> 24) & 255]);
};
var $truqu$elm_md5$MD5$toHex = function (_byte) {
	switch (_byte) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return 'a';
		case 11:
			return 'b';
		case 12:
			return 'c';
		case 13:
			return 'd';
		case 14:
			return 'e';
		case 15:
			return 'f';
		default:
			return _Utils_ap(
				$truqu$elm_md5$MD5$toHex((_byte / 16) | 0),
				$truqu$elm_md5$MD5$toHex(_byte % 16));
	}
};
var $truqu$elm_md5$MD5$hex = function (s) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (b, acc) {
				return _Utils_ap(
					acc,
					A3(
						$elm$core$String$padLeft,
						2,
						'0',
						$truqu$elm_md5$MD5$toHex(b)));
			}),
		'',
		$truqu$elm_md5$MD5$bytes(s));
};
var $elm$core$Tuple$mapBoth = F3(
	function (funcA, funcB, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			funcA(x),
			funcB(y));
	});
var $author$project$Component$NewFixedItemGrid$column = function (keyRoot) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Tuple$pair(keyRoot),
		A2(
			$elm$core$Tuple$mapBoth,
			$truqu$elm_md5$MD5$hex,
			$visotype$elm_dom$Dom$addClass('FixedItemGrid__column')));
};
var $author$project$Component$NewFixedItemGrid$columns = function (items) {
	return A2(
		$visotype$elm_dom$Dom$setChildListWithKeys,
		items,
		A2(
			$visotype$elm_dom$Dom$addClass,
			'FixedItemGrid__columns',
			$visotype$elm_dom$Dom$element('div')));
};
var $author$project$Component$NewFixedItemGrid$container = function (cols) {
	return A2(
		$visotype$elm_dom$Dom$appendChild,
		cols,
		A2(
			$visotype$elm_dom$Dom$addClass,
			'FixedItemGrid__container',
			$visotype$elm_dom$Dom$element('div')));
};
var $author$project$Component$NewFixedItemGrid$render = function (keyRoot) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(
			$author$project$Component$NewFixedItemGrid$column(keyRoot)),
		A2($elm$core$Basics$composeR, $author$project$Component$NewFixedItemGrid$columns, $author$project$Component$NewFixedItemGrid$container));
};
var $author$project$Helpers$Hoverable$Avatar = 0;
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $visotype$elm_dom$Dom$addActionStopAndPrevent = function (_v0) {
	var event = _v0.a;
	var msg = _v0.b;
	var handler = A2(
		$elm$core$Basics$composeR,
		$elm$json$Json$Decode$succeed,
		A2(
			$elm$core$Basics$composeR,
			$elm$json$Json$Decode$map(
				function (x) {
					return {hK: x, cO: true, c_: true};
				}),
			$elm$virtual_dom$VirtualDom$Custom));
	return $visotype$elm_dom$Dom$Internal$modify(
		function (n) {
			return _Utils_update(
				n,
				{
					c: A2(
						$elm$core$List$append,
						n.c,
						_List_fromArray(
							[
								_Utils_Tuple2(
								event,
								handler(msg))
							]))
				});
		});
};
var $author$project$Views$QuestWorldView$CharacterAvatar$addClick = function (onClick) {
	if (!onClick.$) {
		var clickMsg = onClick.a;
		return function (element) {
			return A2(
				$visotype$elm_dom$Dom$addActionStopAndPrevent,
				_Utils_Tuple2('click', clickMsg),
				A2(
					$visotype$elm_dom$Dom$addStyle,
					_Utils_Tuple2('cursor', 'pointer'),
					element));
		};
	} else {
		return function (element) {
			return element;
		};
	}
};
var $author$project$Views$QuestWorldView$CharacterAvatar$altText = 'Tap me!';
var $elm$html$Html$Attributes$coords = $elm$html$Html$Attributes$stringProperty('coords');
var $author$project$Assets$customizerHelperTail = require('/usr/src/app/app/images/speech-bubble/customizer-helper-tail.svg');
var $author$project$Helpers$Hoverable$isOver = F2(
	function (hoverState, hoverStates) {
		return A2(
			$elm$core$List$any,
			function (state) {
				return _Utils_eq(state, hoverState);
			},
			hoverStates);
	});
var $author$project$Helpers$Hoverable$isOut = F2(
	function (hoverState, hoverStates) {
		return !A2($author$project$Helpers$Hoverable$isOver, hoverState, hoverStates);
	});
var $author$project$Views$QuestWorldView$CharacterAvatar$layers = {gs: '1', ha: '4', d2: '5', en: '2', fy: '3'};
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $author$project$Helpers$Hoverable$MouseEnter = function (a) {
	return {$: 0, a: a};
};
var $author$project$Helpers$Hoverable$MouseLeave = function (a) {
	return {$: 1, a: a};
};
var $author$project$Helpers$Hoverable$onHover = F2(
	function (messageMap, hoverState) {
		return A2(
			$elm$core$Basics$composeR,
			$visotype$elm_dom$Dom$addAction(
				_Utils_Tuple2(
					'mouseenter',
					messageMap(
						$author$project$Helpers$Hoverable$MouseEnter(hoverState)))),
			$visotype$elm_dom$Dom$addAction(
				_Utils_Tuple2(
					'mouseleave',
					messageMap(
						$author$project$Helpers$Hoverable$MouseLeave(hoverState)))));
	});
var $author$project$Views$QuestWorldView$CharacterAvatar$positionsDict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2(
			'headAccessory',
			_Utils_Tuple3('25%', '0px', '0px')),
			_Utils_Tuple2(
			'legsAccessory',
			_Utils_Tuple3('15%', '110px', '120px')),
			_Utils_Tuple2(
			'torsoAccessory',
			_Utils_Tuple3('25%', '97px', '80px'))
		]));
var $author$project$Component$Image$renderBasic = function (args) {
	return A2(
		$visotype$elm_dom$Dom$addAttributeList,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$src(args.ig),
				$elm$html$Html$Attributes$alt(args.f5)
			]),
		$visotype$elm_dom$Dom$element('img'));
};
var $author$project$Views$QuestWorldView$CharacterAvatar$renderAccessory = function (_v0) {
	var avatarAccessory = _v0.gp;
	var zIndex = function () {
		var _v3 = avatarAccessory.fY;
		switch (_v3) {
			case 0:
				return $author$project$Views$QuestWorldView$CharacterAvatar$layers.d2;
			case 2:
				return $author$project$Views$QuestWorldView$CharacterAvatar$layers.en;
			default:
				return $author$project$Views$QuestWorldView$CharacterAvatar$layers.fy;
		}
	}();
	var _v1 = A2(
		$elm$core$Maybe$withDefault,
		_Utils_Tuple3('25%', '0px', '0px'),
		function () {
			var _v2 = avatarAccessory.fY;
			switch (_v2) {
				case 0:
					return A2($elm$core$Dict$get, 'headAccessory', $author$project$Views$QuestWorldView$CharacterAvatar$positionsDict);
				case 2:
					return A2($elm$core$Dict$get, 'legsAccessory', $author$project$Views$QuestWorldView$CharacterAvatar$positionsDict);
				default:
					return A2($elm$core$Dict$get, 'torsoAccessory', $author$project$Views$QuestWorldView$CharacterAvatar$positionsDict);
			}
		}());
	var width = _v1.a;
	var offsetX = _v1.b;
	var offsetY = _v1.c;
	return A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('position', 'absolute'),
				_Utils_Tuple2('top', offsetY),
				_Utils_Tuple2('left', offsetX),
				_Utils_Tuple2('z-index', zIndex),
				_Utils_Tuple2('width', width)
			]),
		$author$project$Component$Image$renderBasic(
			{f5: $author$project$Views$QuestWorldView$CharacterAvatar$altText, ig: avatarAccessory.i4}));
};
var $elm$html$Html$Attributes$shape = $elm$html$Html$Attributes$stringProperty('shape');
var $elm$html$Html$Attributes$usemap = $elm$html$Html$Attributes$stringProperty('useMap');
var $author$project$Views$QuestWorldView$CharacterAvatar$render = F5(
	function (onClick, showOnClickHelper, viewData, hoverableMsgMap, avatarAssets) {
		var singleImageAvatar = A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					A2(
					$visotype$elm_dom$Dom$addAttribute,
					$elm$html$Html$Attributes$usemap('#avatarSingleMap'),
					A2(
						$author$project$Dom$Extra$addDataCy,
						'character-avatar',
						$author$project$Component$Image$render(
							{
								f5: $author$project$Views$QuestWorldView$CharacterAvatar$altText,
								hb: 250,
								ig: $author$project$Assets$path(avatarAssets.E),
								jS: 260
							}))),
					A2(
					$visotype$elm_dom$Dom$appendChild,
					A2(
						$author$project$Views$QuestWorldView$CharacterAvatar$addClick,
						onClick,
						A2(
							$visotype$elm_dom$Dom$addAttribute,
							$elm$html$Html$Attributes$href('javascript: return void;'),
							A2(
								$visotype$elm_dom$Dom$addAttribute,
								$elm$html$Html$Attributes$coords('70, 100, 190, 250'),
								A2(
									$visotype$elm_dom$Dom$addAttribute,
									$elm$html$Html$Attributes$shape('rect'),
									$visotype$elm_dom$Dom$element('area'))))),
					A2(
						$visotype$elm_dom$Dom$addAttribute,
						$elm$html$Html$Attributes$name('avatarSingleMap'),
						$visotype$elm_dom$Dom$element('map')))
				]),
			$visotype$elm_dom$Dom$element('div'));
		var onClickHelperText = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('padding-left', '10px'),
					_Utils_Tuple2('flex', '1 1 auto'),
					_Utils_Tuple2('line-height', '1.2'),
					_Utils_Tuple2('max-height', '100%'),
					_Utils_Tuple2('overflow', 'hidden'),
					_Utils_Tuple2('display', '-webkit-box'),
					_Utils_Tuple2('-webkit-box-orient', 'vertical'),
					_Utils_Tuple2('-webkit-line-clamp', '2'),
					_Utils_Tuple2('color', '#FFF')
				]),
			A2(
				$visotype$elm_dom$Dom$appendText,
				'Tap me!',
				$visotype$elm_dom$Dom$element('div')));
		var onClickHelperArrow = A2(
			$author$project$Styles$setAbsolutePosition,
			{gt: '', hx: '40%', iJ: '40%', jy: '40px'},
			A2(
				$visotype$elm_dom$Dom$addAttributeList,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(
						$author$project$Assets$path($author$project$Assets$customizerHelperTail))
					]),
				$visotype$elm_dom$Dom$element('img')));
		var onClickHelperPopup = showOnClickHelper ? A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('align-items', 'center'),
					_Utils_Tuple2('height', '42px'),
					_Utils_Tuple2('border-radius', '10px'),
					_Utils_Tuple2('padding', '10px'),
					_Utils_Tuple2('background-color', '#000')
				]),
			A2(
				$author$project$Styles$setAbsolutePosition,
				{gt: '', hx: '30%', iJ: '30%', jy: '-20px'},
				A2(
					$visotype$elm_dom$Dom$appendChild,
					onClickHelperArrow,
					A2(
						$visotype$elm_dom$Dom$appendChild,
						onClickHelperText,
						$visotype$elm_dom$Dom$element('div'))))) : $visotype$elm_dom$Dom$element('div');
		var _v0 = viewData;
		var studentAccessories = _v0.jc;
		var hoverStates = _v0.d4;
		var avatar = function () {
			if ($elm$core$List$isEmpty(studentAccessories)) {
				return $elm$core$List$singleton(singleImageAvatar);
			} else {
				var _v1 = avatarAssets.z;
				if (_v1.$ === 1) {
					return $elm$core$List$singleton(singleImageAvatar);
				} else {
					var _v2 = _v1.a;
					var head = _v2.a;
					var body = _v2.b;
					return _List_fromArray(
						[
							A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2('opacity', '30%'),
									_Utils_Tuple2('backgroundColor', '#fff'),
									_Utils_Tuple2('position', 'absolute'),
									_Utils_Tuple2('top', '15px'),
									_Utils_Tuple2('left', '50px'),
									_Utils_Tuple2('bottom', '85px'),
									_Utils_Tuple2('right', '50px'),
									_Utils_Tuple2('border-radius', '130px')
								]),
							A3(
								$visotype$elm_dom$Dom$addStyleConditional,
								_Utils_Tuple2('display', 'none'),
								A2($author$project$Helpers$Hoverable$isOut, 0, hoverStates),
								A2(
									$visotype$elm_dom$Dom$addAttributeList,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$width(260),
											$elm$html$Html$Attributes$height(210)
										]),
									$visotype$elm_dom$Dom$element('div')))),
							A2(
							$visotype$elm_dom$Dom$addAttribute,
							$elm$html$Html$Attributes$usemap('#avatarMap'),
							A2(
								$visotype$elm_dom$Dom$addStyleList,
								_List_fromArray(
									[
										_Utils_Tuple2('position', 'absolute'),
										_Utils_Tuple2('z-index', $author$project$Views$QuestWorldView$CharacterAvatar$layers.ha)
									]),
								$author$project$Component$Image$render(
									{
										f5: $author$project$Views$QuestWorldView$CharacterAvatar$altText,
										hb: 40,
										ig: $author$project$Assets$path(head),
										jS: 260
									}))),
							A2(
							$author$project$Dom$Extra$addDataCy,
							'character-avatar',
							A2(
								$visotype$elm_dom$Dom$addStyleList,
								_List_fromArray(
									[
										_Utils_Tuple2('position', 'absolute'),
										_Utils_Tuple2('z-index', $author$project$Views$QuestWorldView$CharacterAvatar$layers.gs)
									]),
								$author$project$Component$Image$render(
									{
										f5: $author$project$Views$QuestWorldView$CharacterAvatar$altText,
										hb: 210,
										ig: $author$project$Assets$path(body),
										jS: 260
									}))),
							A2(
							$visotype$elm_dom$Dom$appendChild,
							A3(
								$author$project$Helpers$Hoverable$onHover,
								hoverableMsgMap,
								0,
								A2(
									$author$project$Views$QuestWorldView$CharacterAvatar$addClick,
									onClick,
									A2(
										$visotype$elm_dom$Dom$addAttribute,
										$elm$html$Html$Attributes$href('javascript: return void;'),
										A2(
											$visotype$elm_dom$Dom$addAttribute,
											$elm$html$Html$Attributes$coords('90,30,170,160'),
											A2(
												$visotype$elm_dom$Dom$addAttribute,
												$elm$html$Html$Attributes$shape('rect'),
												$visotype$elm_dom$Dom$element('area')))))),
							A2(
								$visotype$elm_dom$Dom$addAttribute,
								$elm$html$Html$Attributes$name('avatarMap'),
								$visotype$elm_dom$Dom$element('map')))
						]);
				}
			}
		}();
		return A2(
			$visotype$elm_dom$Dom$appendChild,
			onClickHelperPopup,
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_Utils_ap(
					avatar,
					A2($elm$core$List$map, $author$project$Views$QuestWorldView$CharacterAvatar$renderAccessory, studentAccessories)),
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('position', 'relative !important'),
							_Utils_Tuple2('top', '-50px !important')
						]),
					$visotype$elm_dom$Dom$element('div'))));
	});
var $author$project$Assets$ActivityIcon$choiceText = {ar: require('/usr/src/app/app/images/activities/icons/choicetext-active.svg'), ax: require('/usr/src/app/app/images/activities/icons/choicetext-inactive.svg')};
var $author$project$Assets$ActivityIcon$ebook = {ar: require('/usr/src/app/app/images/activities/icons/book-active.svg'), ax: require('/usr/src/app/app/images/activities/icons/book-inactive.svg')};
var $author$project$Models$StudentActivity$isUnavailable = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.ir;
	},
	$elm$core$Basics$eq($author$project$Models$StudentActivity$UnavailableStudentActivity));
var $author$project$Models$StudentActivity$isUnlocked = F2(
	function (studentActivities, studentActivity) {
		var thisActivityCompletionGroup = studentActivity.f_.dE;
		var mustBeCompletedToUnlockThisStudentActivity = function (otherActivityCompletionGroup) {
			var _v0 = _Utils_Tuple2(otherActivityCompletionGroup, thisActivityCompletionGroup);
			switch (_v0.a) {
				case 0:
					switch (_v0.b) {
						case 0:
							var _v1 = _v0.a;
							var _v2 = _v0.b;
							return false;
						case 1:
							var _v3 = _v0.a;
							var _v4 = _v0.b;
							return true;
						case 2:
							var _v5 = _v0.a;
							var _v6 = _v0.b;
							return true;
						default:
							var _v7 = _v0.a;
							var _v8 = _v0.b;
							return true;
					}
				case 1:
					switch (_v0.b) {
						case 0:
							var _v9 = _v0.a;
							var _v10 = _v0.b;
							return false;
						case 1:
							var _v11 = _v0.a;
							var _v12 = _v0.b;
							return false;
						case 2:
							var _v13 = _v0.a;
							var _v14 = _v0.b;
							return true;
						default:
							var _v15 = _v0.a;
							var _v16 = _v0.b;
							return true;
					}
				case 2:
					switch (_v0.b) {
						case 0:
							var _v17 = _v0.a;
							var _v18 = _v0.b;
							return false;
						case 1:
							var _v19 = _v0.a;
							var _v20 = _v0.b;
							return false;
						case 2:
							var _v21 = _v0.a;
							var _v22 = _v0.b;
							return false;
						default:
							var _v23 = _v0.a;
							var _v24 = _v0.b;
							return true;
					}
				default:
					switch (_v0.b) {
						case 0:
							var _v25 = _v0.a;
							var _v26 = _v0.b;
							return false;
						case 1:
							var _v27 = _v0.a;
							var _v28 = _v0.b;
							return false;
						case 2:
							var _v29 = _v0.a;
							var _v30 = _v0.b;
							return false;
						default:
							var _v31 = _v0.a;
							var _v32 = _v0.b;
							return false;
					}
			}
		};
		return A2(
			$elm$core$List$all,
			$author$project$Models$StudentActivity$isCompleted,
			A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Models$StudentActivity$isUnavailable),
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.f_;
						},
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.dE;
							},
							mustBeCompletedToUnlockThisStudentActivity)),
					studentActivities)));
	});
var $author$project$ES$UI$Color$orange = {
	at: A3($avh4$elm_color$Color$rgb255, 243, 103, 0),
	au: A3($avh4$elm_color$Color$rgb255, 194, 82, 0),
	ay: A3($avh4$elm_color$Color$rgb255, 255, 143, 60),
	az: A3($avh4$elm_color$Color$rgb255, 255, 188, 138),
	aA: A3($avh4$elm_color$Color$rgb255, 255, 232, 214)
};
var $author$project$Assets$ActivityIcon$quiz = {ar: require('/usr/src/app/app/images/activities/icons/quiz-active.svg'), ax: require('/usr/src/app/app/images/activities/icons/quiz-inactive.svg')};
var $author$project$Views$QuestWorldView$randomizeActivity = F2(
	function (seed, stationTheme) {
		return stationTheme.f_(seed);
	});
var $author$project$Messages$LogActivityClick = F2(
	function (a, b) {
		return {$: 91, a: a, b: b};
	});
var $author$project$Views$QuestWorldView$ActivityStation$addStationAnimation = F2(
	function (_v0, shouldApply) {
		var effect = _v0.bB;
		return A2(
			$elm$core$Basics$composeR,
			A2($visotype$elm_dom$Dom$addClassConditional, 'ActivityStation__animation', shouldApply),
			A2($author$project$Animations$CssAnimations$applyConditional, effect, shouldApply));
	});
var $author$project$Assets$checkmark = require('/usr/src/app/app/images/check-circle.svg');
var $author$project$Views$QuestWorldView$ActivityStation$randomizeCompletedAsset = F2(
	function (seed, stationAssets) {
		return stationAssets.aM(seed);
	});
var $author$project$Views$QuestWorldView$ActivityStation$render = function (_v0) {
	var assets = _v0.dp;
	var animations = _v0.dm;
	var isUnlocked = _v0.eg;
	var isComplete = _v0.ec;
	var seed = _v0.e7;
	var linkTo = _v0.ep;
	var labelText = _v0.ek;
	var activeColor = _v0.de;
	var activityTypeString = _v0.dh;
	var activityIcon = _v0.dg;
	var activityId = _v0.b6;
	var studentActivityId = _v0.G;
	var text = (!isUnlocked) ? A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('padding-left', '10px'),
				_Utils_Tuple2('flex', '1 1 auto'),
				_Utils_Tuple2('line-height', '1.2'),
				_Utils_Tuple2('max-height', '100%'),
				_Utils_Tuple2('overflow', 'hidden'),
				_Utils_Tuple2('display', '-webkit-box'),
				_Utils_Tuple2('-webkit-box-orient', 'vertical'),
				_Utils_Tuple2('-webkit-line-clamp', '2'),
				_Utils_Tuple2('color', '#6C6E70')
			]),
		A2(
			$visotype$elm_dom$Dom$appendText,
			labelText,
			$visotype$elm_dom$Dom$element('div'))) : A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('padding-left', '10px'),
				_Utils_Tuple2('flex', '1 1 auto'),
				_Utils_Tuple2('line-height', '1.2'),
				_Utils_Tuple2('max-height', '100%'),
				_Utils_Tuple2('overflow', 'hidden'),
				_Utils_Tuple2('display', '-webkit-box'),
				_Utils_Tuple2('-webkit-box-orient', 'vertical'),
				_Utils_Tuple2('-webkit-line-clamp', '2')
			]),
		A2(
			$visotype$elm_dom$Dom$appendText,
			labelText,
			$visotype$elm_dom$Dom$element('div')));
	var icon = A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('width', '50px'),
				_Utils_Tuple2('height', '50px'),
				_Utils_Tuple2('margin', '-7px'),
				_Utils_Tuple2('padding', '8px')
			]),
		$author$project$Component$Image$render(
			{
				f5: 'activity icon ' + activityTypeString,
				hb: 50,
				ig: $author$project$Assets$path(
					isUnlocked ? activityIcon.ar : activityIcon.ax),
				jS: 50
			}));
	var color = isUnlocked ? activeColor : $author$project$ES$UI$Color$grey.jZ;
	var label = A2(
		$author$project$Styles$border,
		_Utils_Tuple3('0px', 'solid', color),
		(isUnlocked ? $author$project$Styles$filled($author$project$ES$UI$Color$white) : $author$project$Styles$filled($author$project$ES$UI$Color$grey.jY))(
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('display', 'flex'),
						_Utils_Tuple2('align-items', 'center'),
						_Utils_Tuple2('height', '50px'),
						_Utils_Tuple2('border-radius', '18px'),
						_Utils_Tuple2('padding', '0 5px')
					]),
				A2(
					$author$project$Styles$setAbsolutePosition,
					{gt: '-20px', hx: '0', iJ: '0', jy: ''},
					A2(
						$visotype$elm_dom$Dom$appendChildList,
						_List_fromArray(
							[icon, text]),
						A2(
							$author$project$Dom$Extra$addDataCy,
							'label-' + activityTypeString,
							$visotype$elm_dom$Dom$element('div')))))));
	var classicBackgroundAsset = isComplete ? A2($author$project$Views$QuestWorldView$ActivityStation$randomizeCompletedAsset, seed, assets) : (isUnlocked ? assets.ar : assets.ax);
	var checkmark = A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('width', '35px'),
				_Utils_Tuple2('height', '35px'),
				_Utils_Tuple2('filter', 'drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.3))')
			]),
		A2(
			$author$project$Styles$setAbsolutePosition,
			{gt: '20px', hx: '115px', iJ: '0', jy: ''},
			$author$project$Component$Image$render(
				{
					f5: 'checkmark',
					hb: 50,
					ig: $author$project$Assets$path($author$project$Assets$checkmark),
					jS: 50
				})));
	var wrapper = isComplete ? A2(
		$author$project$Component$Link$to,
		linkTo,
		_List_fromArray(
			[label, checkmark])) : (isUnlocked ? A2(
		$author$project$Component$Link$to,
		linkTo,
		_List_fromArray(
			[label])) : A2(
		$visotype$elm_dom$Dom$addStyle,
		_Utils_Tuple2('cursor', 'not-allowed'),
		A2(
			$visotype$elm_dom$Dom$appendChild,
			label,
			$visotype$elm_dom$Dom$element('div'))));
	var backgroundAsset = isComplete ? A2($author$project$Views$QuestWorldView$ActivityStation$randomizeCompletedAsset, seed, assets) : (isUnlocked ? assets.ar : assets.ax);
	var style = _List_fromArray(
		[
			_Utils_Tuple2('width', '260px'),
			_Utils_Tuple2('height', '260px'),
			_Utils_Tuple2(
			'background-image',
			$author$project$Styles$url(backgroundAsset)),
			_Utils_Tuple2('background-size', 'contain'),
			_Utils_Tuple2('background-position', 'top'),
			_Utils_Tuple2('background-repeat', 'no-repeat'),
			_Utils_Tuple2('display', 'block'),
			_Utils_Tuple2('position', 'relative')
		]);
	var _v1 = isComplete ? _Utils_Tuple2('completed', 'unlocked') : (isUnlocked ? _Utils_Tuple2('incomplete', 'unlocked') : _Utils_Tuple2('incomplete', 'locked'));
	var completedStatus = _v1.a;
	var lockedStatus = _v1.b;
	return A2(
		$visotype$elm_dom$Dom$addAction,
		_Utils_Tuple2(
			'click',
			A2($author$project$Messages$LogActivityClick, activityId, studentActivityId)),
		A3(
			$author$project$Views$QuestWorldView$ActivityStation$addStationAnimation,
			animations.ar,
			(!isComplete) && isUnlocked,
			A2(
				$visotype$elm_dom$Dom$setId,
				'activity-' + $elm$core$String$fromInt(seed),
				A2(
					$visotype$elm_dom$Dom$addClassList,
					_List_fromArray(
						['activity-card', activityTypeString]),
					A2(
						$author$project$Dom$Extra$addDataAttribute,
						_Utils_Tuple2('station-locked-status', lockedStatus),
						A2(
							$author$project$Dom$Extra$addDataAttribute,
							_Utils_Tuple2('station-completed-status', completedStatus),
							A2($visotype$elm_dom$Dom$addStyleList, style, wrapper)))))));
};
var $author$project$Assets$ActivityIcon$videoPlay = {ar: require('/usr/src/app/app/images/activities/icons/video-active.svg'), ax: require('/usr/src/app/app/images/activities/icons/video-inactive.svg')};
var $author$project$Assets$ActivityIcon$webApp = {ar: require('/usr/src/app/app/images/activities/icons/webapp-active.svg'), ax: require('/usr/src/app/app/images/activities/icons/webapp-inactive.svg')};
var $author$project$Views$QuestWorldView$studentActivityStation = F6(
	function (theme, characterAvatar, studentActivities, avatarLocation, isPlacement, studentActivity) {
		var activity = studentActivity.f_;
		var seed = A2(
			$elm$core$Maybe$withDefault,
			0,
			$author$project$Models$Ids$toInt(studentActivity.hj));
		var labelText = function () {
			var _v2 = activity.f0;
			switch (_v2.$) {
				case 7:
					return 'Show what you know!';
				case 8:
					return isPlacement ? 'Show what you know!' : 'Show what you\'ve learned!';
				case 0:
					return activity.fx;
				case 6:
					return 'Challenge';
				default:
					return activity.fx;
			}
		}();
		var _v0 = function () {
			var _v1 = studentActivity.f_.f0;
			switch (_v1.$) {
				case 0:
					return _Utils_Tuple2($author$project$Assets$ActivityIcon$videoPlay, $author$project$ES$UI$Color$yellow.at);
				case 1:
					return _Utils_Tuple2($author$project$Assets$ActivityIcon$webApp, $author$project$ES$UI$Color$blue.at);
				case 3:
					return _Utils_Tuple2($author$project$Assets$ActivityIcon$choiceText, $author$project$ES$UI$Color$purple.at);
				case 2:
					return _Utils_Tuple2($author$project$Assets$ActivityIcon$ebook, $author$project$ES$UI$Color$purple.at);
				default:
					return _Utils_Tuple2($author$project$Assets$ActivityIcon$quiz, $author$project$ES$UI$Color$orange.at);
			}
		}();
		var activityIcon = _v0.a;
		var color = _v0.b;
		return A3(
			$visotype$elm_dom$Dom$appendChildConditional,
			characterAvatar,
			_Utils_eq(
				avatarLocation,
				$elm$core$Maybe$Just(studentActivity.hj)),
			$author$project$Views$QuestWorldView$ActivityStation$render(
				{
					de: color,
					dg: activityIcon,
					b6: A2(
						$elm$core$Maybe$withDefault,
						-1,
						$author$project$Models$Ids$toInt(activity.hj)),
					dh: $author$project$Models$Activity$typeToString(activity.f0),
					dm: theme.dm,
					dp: A2($author$project$Views$QuestWorldView$randomizeActivity, seed, theme),
					ec: $author$project$Models$StudentActivity$isCompleted(studentActivity),
					eg: A2($author$project$Models$StudentActivity$isUnlocked, studentActivities, studentActivity),
					ek: labelText,
					ep: $author$project$Routes$activityPracticeRoute(studentActivity),
					e7: seed,
					G: A2(
						$elm$core$Maybe$withDefault,
						-1,
						$author$project$Models$Ids$toInt(studentActivity.hj))
				}));
	});
var $author$project$Assets$ActivityIcon$videoRecord = {ar: require('/usr/src/app/app/images/activities/icons/video-record-active.svg'), ax: require('/usr/src/app/app/images/activities/icons/video-record-inactive.svg')};
var $author$project$Views$QuestWorldView$studentVideoStation = F3(
	function (theme, activeQuest, studentVideo) {
		var isUnlocked = $author$project$Models$ActiveQuest$activitiesComplete(activeQuest);
		return $author$project$Views$QuestWorldView$ActivityStation$render(
			{
				de: $author$project$ES$UI$Color$orange.at,
				dg: $author$project$Assets$ActivityIcon$videoRecord,
				b6: 0,
				dh: 'record-video',
				dm: theme.dm,
				dp: theme.fG,
				ec: studentVideo.aM,
				eg: isUnlocked,
				ek: 'Create a Video',
				ep: $author$project$Routes$VideoRoute(
					$author$project$Routes$VideoRoutes$Instructions(activeQuest.a_)),
				e7: 1,
				G: 0
			});
	});
var $author$project$Views$QuestWorldView$render = F3(
	function (activeQuest, assets, viewData) {
		var localStorage = viewData.hz;
		var characterTheme = viewData.gB;
		var buttonsDisabled = viewData.gv;
		var videoRecordingPermitted = viewData.jM;
		var productFeatures = viewData.iq;
		var syntheticStations = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$map,
					A2($author$project$Views$QuestWorldView$studentVideoStation, assets.fk, activeQuest),
					A2($author$project$Models$ActiveQuest$videoRecordingActivityForDisplay, videoRecordingPermitted, activeQuest))
				]));
		var style = _List_fromArray(
			[
				_Utils_Tuple2(
				'background-image',
				A2(
					$elm$core$String$join,
					',',
					A2(
						$elm$core$List$map,
						$author$project$Styles$url,
						_List_fromArray(
							[assets.dZ, assets.ds.ft])))),
				_Utils_Tuple2('background-position', 'top'),
				_Utils_Tuple2('background-repeat', 'repeat-x, repeat'),
				_Utils_Tuple2('background-size', 'contain, cover')
			]);
		var shouldRenderPLEModal = $author$project$Models$LocalStorage$StudentChoices$isPLE(characterTheme.v);
		var showOnClickHelper = shouldRenderPLEModal && (!viewData.dJ);
		var nextButton = A2(
			$author$project$Dom$Extra$addDataCy,
			'quest-world-complete-next',
			A2(
				$author$project$Views$Components$Button$WithGlobalMsg$next,
				buttonsDisabled,
				$author$project$Messages$LoggedInRequiredMsg(
					$author$project$LoggedIn$Messages$ClickQuestHomeNextButton(activeQuest.a_))));
		var disabledOverlay = ((A2($author$project$Models$ActiveQuest$questStatus, videoRecordingPermitted, activeQuest) === 2) && (!activeQuest.es)) ? A2(
			$author$project$Dom$Extra$addDataCy,
			'quest-world-disabled-overlay',
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('opacity', '0.4'),
				A2(
					$author$project$Styles$filled,
					$author$project$ES$UI$Color$black,
					A2(
						$author$project$Styles$setFixedPosition,
						{gt: '0', hx: '0', iJ: '0', jy: '0'},
						$visotype$elm_dom$Dom$element('span'))))) : $visotype$elm_dom$Dom$element('span');
		var characterOnClick = shouldRenderPLEModal ? $elm$core$Maybe$Just(
			$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$OpenCustomizer)) : $elm$core$Maybe$Nothing;
		var characterAvatar = A5($author$project$Views$QuestWorldView$CharacterAvatar$render, characterOnClick, showOnClickHelper, viewData, $author$project$Messages$HoverableMsg, characterTheme.u);
		var bottomBar = (A2($author$project$Models$ActiveQuest$questStatus, videoRecordingPermitted, activeQuest) === 2) ? A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('position', 'fixed'),
			$author$project$Component$BottomBar$customBar(
				{
					gq: $author$project$ES$UI$Color$white,
					gH: A2($author$project$Component$Text$title, 3, 'All done! Let\'s learn something new!'),
					R: nextButton,
					io: $visotype$elm_dom$Dom$element('span')
				})) : $visotype$elm_dom$Dom$element('span');
		var availableActivities = $author$project$Models$ActiveQuest$availableActivities(activeQuest);
		var avatarLocation = A2(
			$elm_community$maybe_extra$Maybe$Extra$orElse,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.hj;
				},
				$elm$core$List$head(availableActivities)),
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.eC;
				},
				A2($author$project$Models$LocalStorage$Helpers$getStudentQuestHistoryEntry, activeQuest.a_, localStorage)));
		var studentActivityStations = A2(
			$elm$core$List$map,
			A5($author$project$Views$QuestWorldView$studentActivityStation, assets.fk, characterAvatar, availableActivities, avatarLocation, activeQuest.it.hr),
			availableActivities);
		var stationsWrapper = A2(
			$visotype$elm_dom$Dom$appendChild,
			A2(
				$author$project$Component$NewFixedItemGrid$render,
				'QuestWorldGrid',
				A2(
					$elm$core$List$map,
					function (el) {
						return A2(
							$visotype$elm_dom$Dom$appendChild,
							el,
							$visotype$elm_dom$Dom$element('div'));
					},
					_Utils_ap(studentActivityStations, syntheticStations))),
			A2(
				$visotype$elm_dom$Dom$addClass,
				'ActivityStations',
				$visotype$elm_dom$Dom$element('div')));
		return A3(
			$visotype$elm_dom$Dom$addClassConditional,
			'quest-complete',
			A2($author$project$Models$ActiveQuest$questStatus, videoRecordingPermitted, activeQuest) === 2,
			A2(
				$author$project$Dom$Extra$addDataCy,
				'quest-world-view',
				A2(
					$visotype$elm_dom$Dom$appendChildList,
					_List_fromArray(
						[stationsWrapper, disabledOverlay, bottomBar]),
					A3(
						$visotype$elm_dom$Dom$appendChildConditional,
						A2(
							$author$project$AvatarCustomizationModal$render,
							viewData,
							$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$CloseCustomizer)),
						shouldRenderPLEModal,
						A2(
							$visotype$elm_dom$Dom$addStyleList,
							style,
							A2(
								$visotype$elm_dom$Dom$addClassList,
								_List_fromArray(
									['quest-world', 'activity-list']),
								$visotype$elm_dom$Dom$element('div')))))));
	});
var $krisajenkins$remotedata$RemoteData$unpack = F3(
	function (defaultFunction, _function, remoteData) {
		if (remoteData.$ === 3) {
			var data = remoteData.a;
			return _function(data);
		} else {
			return defaultFunction(0);
		}
	});
var $author$project$Views$QuestHomeView$view = F2(
	function (loadableActiveQuest, viewData) {
		return A3(
			$krisajenkins$remotedata$RemoteData$unpack,
			function (_v0) {
				return $author$project$Views$QuestHomeView$noLoadedQuestView;
			},
			function (activeQuest) {
				return A3(
					$author$project$Views$QuestWorldView$render,
					activeQuest,
					$author$project$Assets$QuestTheme$planet(activeQuest.a_),
					viewData);
			},
			loadableActiveQuest);
	});
var $author$project$AppFlow$NextIncompleteActivity = {$: 0};
var $author$project$Component$Lightbulbs$NoLightbulb = {$: 0};
var $author$project$Component$Types$SideLeft = 0;
var $author$project$Component$SpeechBubbleBanner$left = function (args) {
	return $author$project$Component$SpeechBubbleBanner$banner(
		{
			gH: $author$project$Component$SpeechBubbleBanner$formatBubbleMessage(
				{aL: args.aL, ju: args.ju, a1: args.a1, fP: args.fP}),
			ao: args.ao,
			bm: 0
		});
};
var $author$project$Animations$Messages$AnimateNextButton = function (a) {
	return {$: 2, a: a};
};
var $author$project$Component$Monster$RockyWavingAtCamera = 7;
var $mdgriffith$elm_style_animation$Animation$Render$iePrefix = '-ms-';
var $mdgriffith$elm_style_animation$Animation$Render$webkitPrefix = '-webkit-';
var $mdgriffith$elm_style_animation$Animation$Render$prefix = function (stylePair) {
	var propValue = stylePair.b;
	var propName = stylePair.a;
	switch (propName) {
		case 'transform':
			return _List_fromArray(
				[
					stylePair,
					_Utils_Tuple2(
					_Utils_ap($mdgriffith$elm_style_animation$Animation$Render$iePrefix, propName),
					propValue),
					_Utils_Tuple2(
					_Utils_ap($mdgriffith$elm_style_animation$Animation$Render$webkitPrefix, propName),
					propValue)
				]);
		case 'transform-origin':
			return _List_fromArray(
				[
					stylePair,
					_Utils_Tuple2(
					_Utils_ap($mdgriffith$elm_style_animation$Animation$Render$iePrefix, propName),
					propValue),
					_Utils_Tuple2(
					_Utils_ap($mdgriffith$elm_style_animation$Animation$Render$webkitPrefix, propName),
					propValue)
				]);
		case 'filter':
			return _List_fromArray(
				[
					stylePair,
					_Utils_Tuple2(
					_Utils_ap($mdgriffith$elm_style_animation$Animation$Render$iePrefix, propName),
					propValue),
					_Utils_Tuple2(
					_Utils_ap($mdgriffith$elm_style_animation$Animation$Render$webkitPrefix, propName),
					propValue)
				]);
		default:
			return _List_fromArray(
				[stylePair]);
	}
};
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$offset = _VirtualDom_attribute('offset');
var $elm$svg$Svg$Attributes$points = _VirtualDom_attribute('points');
var $elm$core$Basics$cos = _Basics_cos;
var $elm$core$Basics$pi = _Basics_pi;
var $elm$core$Basics$degrees = function (angleInDegrees) {
	return (angleInDegrees * $elm$core$Basics$pi) / 180;
};
var $elm$core$Basics$sin = _Basics_sin;
var $mdgriffith$elm_style_animation$Animation$Render$pathCmdValue = function (cmd) {
	var renderPoints = function (coords) {
		return A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				function (_v11) {
					var x = _v11.a;
					var y = _v11.b;
					return $elm$core$String$fromFloat(x.il) + (',' + $elm$core$String$fromFloat(y.il));
				},
				coords));
	};
	switch (cmd.$) {
		case 0:
			var x = cmd.a;
			var y = cmd.b;
			return 'm ' + ($elm$core$String$fromFloat(x.il) + (',' + $elm$core$String$fromFloat(y.il)));
		case 1:
			var x = cmd.a;
			var y = cmd.b;
			return 'M ' + ($elm$core$String$fromFloat(x.il) + (',' + $elm$core$String$fromFloat(y.il)));
		case 2:
			var x = cmd.a;
			var y = cmd.b;
			return 'l ' + ($elm$core$String$fromFloat(x.il) + (',' + $elm$core$String$fromFloat(y.il)));
		case 3:
			var x = cmd.a;
			var y = cmd.b;
			return 'L ' + ($elm$core$String$fromFloat(x.il) + (',' + $elm$core$String$fromFloat(y.il)));
		case 4:
			var a = cmd.a;
			return 'h ' + $elm$core$String$fromFloat(a.il);
		case 5:
			var a = cmd.a;
			return 'H ' + $elm$core$String$fromFloat(a.il);
		case 6:
			var a = cmd.a;
			return 'v ' + $elm$core$String$fromFloat(a.il);
		case 7:
			var a = cmd.a;
			return 'V ' + $elm$core$String$fromFloat(a.il);
		case 8:
			var control1 = cmd.a.a5;
			var control2 = cmd.a.a6;
			var point = cmd.a.ab;
			var _v1 = point;
			var p1x = _v1.a;
			var p1y = _v1.b;
			var _v2 = control2;
			var c2x = _v2.a;
			var c2y = _v2.b;
			var _v3 = control1;
			var c1x = _v3.a;
			var c1y = _v3.b;
			return 'c ' + ($elm$core$String$fromFloat(c1x.il) + (' ' + ($elm$core$String$fromFloat(c1y.il) + (', ' + ($elm$core$String$fromFloat(c2x.il) + (' ' + ($elm$core$String$fromFloat(c2y.il) + (', ' + ($elm$core$String$fromFloat(p1x.il) + (' ' + $elm$core$String$fromFloat(p1y.il)))))))))));
		case 9:
			var control1 = cmd.a.a5;
			var control2 = cmd.a.a6;
			var point = cmd.a.ab;
			var _v4 = point;
			var p1x = _v4.a;
			var p1y = _v4.b;
			var _v5 = control2;
			var c2x = _v5.a;
			var c2y = _v5.b;
			var _v6 = control1;
			var c1x = _v6.a;
			var c1y = _v6.b;
			return 'C ' + ($elm$core$String$fromFloat(c1x.il) + (' ' + ($elm$core$String$fromFloat(c1y.il) + (', ' + ($elm$core$String$fromFloat(c2x.il) + (' ' + ($elm$core$String$fromFloat(c2y.il) + (', ' + ($elm$core$String$fromFloat(p1x.il) + (' ' + $elm$core$String$fromFloat(p1y.il)))))))))));
		case 10:
			var control = cmd.a.a4;
			var point = cmd.a.ab;
			var _v7 = point;
			var p1x = _v7.a;
			var p1y = _v7.b;
			var _v8 = control;
			var c1x = _v8.a;
			var c1y = _v8.b;
			return 'q ' + ($elm$core$String$fromFloat(c1x.il) + (' ' + ($elm$core$String$fromFloat(c1y.il) + (', ' + ($elm$core$String$fromFloat(p1x.il) + (' ' + $elm$core$String$fromFloat(p1y.il)))))));
		case 11:
			var control = cmd.a.a4;
			var point = cmd.a.ab;
			var _v9 = point;
			var p1x = _v9.a;
			var p1y = _v9.b;
			var _v10 = control;
			var c1x = _v10.a;
			var c1y = _v10.b;
			return 'Q ' + ($elm$core$String$fromFloat(c1x.il) + (' ' + ($elm$core$String$fromFloat(c1y.il) + (', ' + ($elm$core$String$fromFloat(p1x.il) + (' ' + $elm$core$String$fromFloat(p1y.il)))))));
		case 12:
			var points = cmd.a;
			return 't ' + renderPoints(points);
		case 13:
			var points = cmd.a;
			return 'T ' + renderPoints(points);
		case 14:
			var points = cmd.a;
			return 's ' + renderPoints(points);
		case 15:
			var points = cmd.a;
			return 'S ' + renderPoints(points);
		case 16:
			var arc = cmd.a;
			var deltaAngle = arc.a8.il - arc.bn.il;
			if (_Utils_cmp(deltaAngle, 360 - 1.0e-6) > 0) {
				var dy = arc.bh.il * $elm$core$Basics$sin(
					$elm$core$Basics$degrees(arc.bn.il));
				var dx = arc.bh.il * $elm$core$Basics$cos(
					$elm$core$Basics$degrees(arc.bn.il));
				return 'A ' + ($elm$core$String$fromFloat(arc.bh.il) + (',' + ($elm$core$String$fromFloat(arc.bh.il) + (',0,1,1,' + ($elm$core$String$fromFloat(arc.jW.il - dx) + (',' + ($elm$core$String$fromFloat(arc.j4.il - dy) + (' A ' + ($elm$core$String$fromFloat(arc.bh.il) + (',' + ($elm$core$String$fromFloat(arc.bh.il) + (',0,1,1,' + ($elm$core$String$fromFloat(arc.jW.il + dx) + (',' + $elm$core$String$fromFloat(arc.j4.il + dy)))))))))))))));
			} else {
				return 'A ' + ($elm$core$String$fromFloat(arc.bh.il) + (',' + ($elm$core$String$fromFloat(arc.bh.il) + (' 0 ' + (((deltaAngle >= 180) ? '1' : '0') + (' ' + ('1' + (' ' + ($elm$core$String$fromFloat(
					arc.jW.il + (arc.bh.il * $elm$core$Basics$cos(
						$elm$core$Basics$degrees(arc.a8.il)))) + (',' + $elm$core$String$fromFloat(
					arc.j4.il + (arc.bh.il * $elm$core$Basics$sin(
						$elm$core$Basics$degrees(arc.a8.il))))))))))))));
			}
		case 17:
			var arc = cmd.a;
			var deltaAngle = arc.a8.il - arc.bn.il;
			if (_Utils_cmp(deltaAngle, 360 - 1.0e-6) > 0) {
				var dy = arc.bh.il * $elm$core$Basics$sin(
					$elm$core$Basics$degrees(arc.bn.il));
				var dx = arc.bh.il * $elm$core$Basics$cos(
					$elm$core$Basics$degrees(arc.bn.il));
				return 'A ' + ($elm$core$String$fromFloat(arc.bh.il) + (',' + ($elm$core$String$fromFloat(arc.bh.il) + (',0,1,0,' + ($elm$core$String$fromFloat(arc.jW.il - dx) + (',' + ($elm$core$String$fromFloat(arc.j4.il - dy) + (' A ' + ($elm$core$String$fromFloat(arc.bh.il) + (',' + ($elm$core$String$fromFloat(arc.bh.il) + (',0,1,1,' + ($elm$core$String$fromFloat(arc.jW.il + dx) + (',' + $elm$core$String$fromFloat(arc.j4.il + dy)))))))))))))));
			} else {
				return 'A ' + ($elm$core$String$fromFloat(arc.bh.il) + (',' + ($elm$core$String$fromFloat(arc.bh.il) + (' 0 ' + ((((arc.bn.il - arc.a8.il) >= 180) ? '1' : '0') + (' ' + ('0' + (' ' + ($elm$core$String$fromFloat(
					arc.jW.il + (arc.bh.il * $elm$core$Basics$cos(arc.a8.il))) + (',' + $elm$core$String$fromFloat(
					arc.j4.il + (arc.bh.il * $elm$core$Basics$sin(arc.a8.il)))))))))))));
			}
		default:
			return 'z';
	}
};
var $mdgriffith$elm_style_animation$Animation$Render$propertyValue = F2(
	function (prop, delim) {
		switch (prop.$) {
			case 0:
				var value = prop.b;
				return value;
			case 1:
				var r = prop.b;
				var g = prop.c;
				var b = prop.d;
				var a = prop.e;
				return 'rgba(' + ($elm$core$String$fromInt(
					$elm$core$Basics$round(r.il)) + (',' + ($elm$core$String$fromInt(
					$elm$core$Basics$round(g.il)) + (',' + ($elm$core$String$fromInt(
					$elm$core$Basics$round(b.il)) + (',' + ($elm$core$String$fromFloat(a.il) + ')')))))));
			case 2:
				var name = prop.a;
				var inset = prop.b;
				var shadow = prop.c;
				return (inset ? 'inset ' : '') + ($elm$core$String$fromFloat(shadow.Z.il) + ('px' + (' ' + ($elm$core$String$fromFloat(shadow._.il) + ('px' + (' ' + ($elm$core$String$fromFloat(shadow.U.il) + ('px' + (' ' + ((((name === 'text-shadow') || (name === 'drop-shadow')) ? '' : ($elm$core$String$fromFloat(shadow.fg.il) + ('px' + ' '))) + ('rgba(' + ($elm$core$String$fromInt(
					$elm$core$Basics$round(shadow.cT.il)) + (', ' + ($elm$core$String$fromInt(
					$elm$core$Basics$round(shadow.cu.il)) + (', ' + ($elm$core$String$fromInt(
					$elm$core$Basics$round(shadow.b9.il)) + (', ' + ($elm$core$String$fromFloat(shadow.aJ.il) + ')'))))))))))))))))));
			case 3:
				var x = prop.b;
				return _Utils_ap(
					$elm$core$String$fromFloat(x.il),
					x.jB);
			case 4:
				var x = prop.b;
				var y = prop.c;
				return _Utils_ap(
					$elm$core$String$fromFloat(x.il),
					_Utils_ap(
						x.jB,
						_Utils_ap(
							delim,
							_Utils_ap(
								$elm$core$String$fromFloat(y.il),
								y.jB))));
			case 5:
				var x = prop.b;
				var y = prop.c;
				var z = prop.d;
				return _Utils_ap(
					$elm$core$String$fromFloat(x.il),
					_Utils_ap(
						x.jB,
						_Utils_ap(
							delim,
							_Utils_ap(
								$elm$core$String$fromFloat(y.il),
								_Utils_ap(
									y.jB,
									_Utils_ap(
										delim,
										_Utils_ap(
											$elm$core$String$fromFloat(z.il),
											z.jB)))))));
			case 6:
				var w = prop.b;
				var x = prop.c;
				var y = prop.d;
				var z = prop.e;
				return _Utils_ap(
					$elm$core$String$fromFloat(w.il),
					_Utils_ap(
						w.jB,
						_Utils_ap(
							delim,
							_Utils_ap(
								$elm$core$String$fromFloat(x.il),
								_Utils_ap(
									x.jB,
									_Utils_ap(
										delim,
										_Utils_ap(
											$elm$core$String$fromFloat(y.il),
											_Utils_ap(
												y.jB,
												_Utils_ap(
													delim,
													_Utils_ap(
														$elm$core$String$fromFloat(z.il),
														z.jB))))))))));
			case 7:
				var x = prop.b;
				return _Utils_ap(
					$elm$core$String$fromFloat(x.il),
					x.jB);
			case 8:
				var coords = prop.a;
				return A2(
					$elm$core$String$join,
					' ',
					A2(
						$elm$core$List$map,
						function (_v1) {
							var x = _v1.a;
							var y = _v1.b;
							return $elm$core$String$fromFloat(x.il) + (',' + $elm$core$String$fromFloat(y.il));
						},
						coords));
			default:
				var cmds = prop.a;
				return A2(
					$elm$core$String$join,
					' ',
					A2($elm$core$List$map, $mdgriffith$elm_style_animation$Animation$Render$pathCmdValue, cmds));
		}
	});
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $elm$svg$Svg$Attributes$rx = _VirtualDom_attribute('rx');
var $elm$svg$Svg$Attributes$ry = _VirtualDom_attribute('ry');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $mdgriffith$elm_style_animation$Animation$Render$renderAttrs = function (prop) {
	if (A2(
		$elm$core$String$startsWith,
		'attr:',
		$mdgriffith$elm_style_animation$Animation$Model$propertyName(prop))) {
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$Attributes$attribute,
				A2(
					$elm$core$String$dropLeft,
					5,
					$mdgriffith$elm_style_animation$Animation$Model$propertyName(prop)),
				A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' ')));
	} else {
		switch (prop.$) {
			case 8:
				var pts = prop.a;
				return $elm$core$Maybe$Just(
					$elm$svg$Svg$Attributes$points(
						A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' ')));
			case 9:
				var cmds = prop.a;
				return $elm$core$Maybe$Just(
					$elm$svg$Svg$Attributes$d(
						A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' ')));
			case 3:
				var name = prop.a;
				var m1 = prop.b;
				switch (name) {
					case 'x':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$x(
								A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' ')));
					case 'y':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$y(
								A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' ')));
					case 'cx':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$cx(
								A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' ')));
					case 'cy':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$cy(
								A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' ')));
					case 'rx':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$rx(
								A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' ')));
					case 'ry':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$ry(
								A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' ')));
					case 'r':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$r(
								A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' ')));
					case 'offset':
						return $elm$core$Maybe$Just(
							$elm$svg$Svg$Attributes$offset(
								A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' ')));
					default:
						return $elm$core$Maybe$Nothing;
				}
			case 6:
				var name = prop.a;
				var m1 = prop.b;
				var m2 = prop.c;
				var m3 = prop.d;
				var m4 = prop.e;
				return (name === 'viewBox') ? $elm$core$Maybe$Just(
					$elm$svg$Svg$Attributes$viewBox(
						A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' '))) : $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Nothing;
		}
	}
};
var $mdgriffith$elm_style_animation$Animation$Render$isAttr = function (prop) {
	return A2(
		$elm$core$String$startsWith,
		'attr:',
		$mdgriffith$elm_style_animation$Animation$Model$propertyName(prop)) || function () {
		switch (prop.$) {
			case 8:
				return true;
			case 9:
				return true;
			case 3:
				var name = prop.a;
				return (name === 'cx') || ((name === 'cy') || ((name === 'x') || ((name === 'y') || ((name === 'rx') || ((name === 'ry') || ((name === 'r') || (name === 'offset')))))));
			case 6:
				var name = prop.a;
				return name === 'viewBox';
			default:
				return false;
		}
	}();
};
var $mdgriffith$elm_style_animation$Animation$Render$isFilter = function (prop) {
	return A2(
		$elm$core$List$member,
		$mdgriffith$elm_style_animation$Animation$Model$propertyName(prop),
		_List_fromArray(
			['filter-url', 'blur', 'brightness', 'contrast', 'grayscale', 'hue-rotate', 'invert', 'saturate', 'sepia', 'drop-shadow']));
};
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $mdgriffith$elm_style_animation$Animation$Render$render3dRotation = function (prop) {
	if (prop.$ === 5) {
		var x = prop.b;
		var y = prop.c;
		var z = prop.d;
		return 'rotateX(' + ($elm$core$String$fromFloat(x.il) + (x.jB + (') rotateY(' + ($elm$core$String$fromFloat(y.il) + (y.jB + (') rotateZ(' + ($elm$core$String$fromFloat(z.il) + (z.jB + ')'))))))));
	} else {
		return '';
	}
};
var $mdgriffith$elm_style_animation$Animation$Render$renderValues = function (_v0) {
	var model = _v0;
	var _v1 = A2($elm$core$List$partition, $mdgriffith$elm_style_animation$Animation$Render$isAttr, model.aE);
	var attrProps = _v1.a;
	var styleProps = _v1.b;
	var _v2 = A3(
		$elm$core$List$foldl,
		F2(
			function (prop, _v3) {
				var myStyle = _v3.a;
				var myTransforms = _v3.b;
				var myFilters = _v3.c;
				return $mdgriffith$elm_style_animation$Animation$Render$isTransformation(prop) ? _Utils_Tuple3(
					myStyle,
					_Utils_ap(
						myTransforms,
						_List_fromArray(
							[prop])),
					myFilters) : ($mdgriffith$elm_style_animation$Animation$Render$isFilter(prop) ? _Utils_Tuple3(
					myStyle,
					myTransforms,
					_Utils_ap(
						myFilters,
						_List_fromArray(
							[prop]))) : _Utils_Tuple3(
					_Utils_ap(
						myStyle,
						_List_fromArray(
							[prop])),
					myTransforms,
					myFilters));
			}),
		_Utils_Tuple3(_List_Nil, _List_Nil, _List_Nil),
		styleProps);
	var style = _v2.a;
	var transforms = _v2.b;
	var filters = _v2.c;
	var renderedFilters = $elm$core$List$isEmpty(filters) ? _List_Nil : _List_fromArray(
		[
			_Utils_Tuple2(
			'filter',
			A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					function (prop) {
						var name = $mdgriffith$elm_style_animation$Animation$Model$propertyName(prop);
						return (name === 'filter-url') ? ('url(\"' + (A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ', ') + '\")')) : ($mdgriffith$elm_style_animation$Animation$Model$propertyName(prop) + ('(' + (A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ', ') + ')')));
					},
					filters)))
		]);
	var renderedStyle = A2(
		$elm$core$List$map,
		function (prop) {
			return _Utils_Tuple2(
				$mdgriffith$elm_style_animation$Animation$Model$propertyName(prop),
				A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ' '));
		},
		style);
	var renderedTransforms = $elm$core$List$isEmpty(transforms) ? _List_Nil : _List_fromArray(
		[
			_Utils_Tuple2(
			'transform',
			A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					function (prop) {
						return ($mdgriffith$elm_style_animation$Animation$Model$propertyName(prop) === 'rotate3d') ? $mdgriffith$elm_style_animation$Animation$Render$render3dRotation(prop) : ($mdgriffith$elm_style_animation$Animation$Model$propertyName(prop) + ('(' + (A2($mdgriffith$elm_style_animation$Animation$Render$propertyValue, prop, ', ') + ')')));
					},
					transforms)))
		]);
	return _Utils_Tuple2(
		_Utils_ap(
			renderedTransforms,
			_Utils_ap(renderedFilters, renderedStyle)),
		attrProps);
};
var $mdgriffith$elm_style_animation$Animation$Render$render = function (animation) {
	var _v0 = $mdgriffith$elm_style_animation$Animation$Render$renderValues(animation);
	var style = _v0.a;
	var attrProps = _v0.b;
	var otherAttrs = A2($elm$core$List$filterMap, $mdgriffith$elm_style_animation$Animation$Render$renderAttrs, attrProps);
	var styleAttr = A2(
		$elm$core$List$map,
		function (_v1) {
			var prop = _v1.a;
			var val = _v1.b;
			return A2($elm$html$Html$Attributes$style, prop, val);
		},
		A2($elm$core$List$concatMap, $mdgriffith$elm_style_animation$Animation$Render$prefix, style));
	return _Utils_ap(styleAttr, otherAttrs);
};
var $mdgriffith$elm_style_animation$Animation$render = $mdgriffith$elm_style_animation$Animation$Render$render;
var $author$project$Animations$renderAnimation = function (animationState) {
	return $mdgriffith$elm_style_animation$Animation$render(animationState.aE);
};
var $author$project$Views$RockySpeaks$renderWithCustomBanner = F2(
	function (_v0, _v1) {
		var seasonalTheme = _v0.iQ;
		var isAnimationOngoing = _v0.hq;
		var animations = _v0.dm;
		var buttonsDisabled = _v0.gv;
		var banner = _v1.dt;
		var nextButtonAction = _v1.hU;
		var monsterStyling = _v1.hN;
		var _class = _v1.gE;
		var safeNextButtonAction = function () {
			var _v2 = _Utils_Tuple2(isAnimationOngoing, animations.R.cZ);
			if (!_v2.a) {
				return nextButtonAction;
			} else {
				if (_v2.b === 1) {
					var _v3 = _v2.b;
					return $author$project$Messages$NoOp;
				} else {
					return $author$project$Messages$AnimationMessage(
						$author$project$Animations$Messages$AnimateNextButton(nextButtonAction));
				}
			}
		}();
		var rocky = monsterStyling(
			$author$project$Styles$monsterOnPath(
				A3($author$project$Component$Monster$renderSvg, seasonalTheme, 7, 1)));
		var nextButtonAnimationStyles = $author$project$Animations$renderAnimation(animations.R);
		var nextButton = $visotype$elm_dom$Dom$render(
			A2(
				$author$project$Animations$CssAnimations$applyWithAnimationPreset,
				7,
				A2(
					$visotype$elm_dom$Dom$addStyle,
					_Utils_Tuple2('position', 'initial'),
					$author$project$Styles$activityButton(
						A2(
							$visotype$elm_dom$Dom$addClass,
							'next-activity-button',
							A2($author$project$Views$Components$Button$WithGlobalMsg$next, buttonsDisabled, safeNextButtonAction))))));
		var buttonWithAnimation = A2(
			$visotype$elm_dom$Dom$appendNode,
			A2(
				$elm$html$Html$div,
				_Utils_ap(
					nextButtonAnimationStyles,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
							A2($elm$html$Html$Attributes$style, 'right', '20px'),
							A2($elm$html$Html$Attributes$style, 'bottom', '15px'),
							A2($elm$html$Html$Attributes$style, 'height', '80px'),
							A2($elm$html$Html$Attributes$style, 'width', '80px')
						])),
				_List_fromArray(
					[nextButton])),
			$visotype$elm_dom$Dom$element('div'));
		return A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					A2(
					$author$project$Component$Monster$addBanner,
					banner,
					_List_fromArray(
						[rocky])),
					$author$project$Component$BottomBar$customBar(
					{
						gq: $author$project$ES$UI$Color$transparent,
						gH: $visotype$elm_dom$Dom$element('div'),
						R: buttonWithAnimation,
						io: $visotype$elm_dom$Dom$element('div')
					})
				]),
			A2(
				$visotype$elm_dom$Dom$addClass,
				_class,
				$visotype$elm_dom$Dom$element('div')));
	});
var $author$project$Views$RockySpeaks$render = F2(
	function (viewData, _v0) {
		var bannerText = _v0.gr;
		var nextButtonAction = _v0.hU;
		var monsterStyling = _v0.hN;
		var _class = _v0.gE;
		var rockyAssetPath = _v0.iK;
		var banner = $author$project$Component$SpeechBubbleBanner$left(
			{aL: 'has-text-rocky', ao: 0, ju: bannerText, a1: viewData, fP: $author$project$Models$Voice$default});
		return A2(
			$author$project$Views$RockySpeaks$renderWithCustomBanner,
			viewData,
			{dt: banner, gE: _class, hN: monsterStyling, hU: nextButtonAction, iK: rockyAssetPath});
	});
var $author$project$Assets$rockyStandingForward = require('/usr/src/app/app/images/monsters/rocky/standing.svg');
var $author$project$Helpers$Sample$randomInt = function (_v0) {
	var min = _v0.ey;
	var max = _v0.et;
	var seed = _v0.e7;
	return A2(
		$elm$random$Random$step,
		A2($elm$random$Random$int, min, max),
		$elm$random$Random$initialSeed(seed)).a;
};
var $author$project$Helpers$Sample$sample = F2(
	function (items, seed) {
		var index = $author$project$Helpers$Sample$randomInt(
			{
				et: $elm$core$List$length(items) - 1,
				ey: 0,
				e7: seed
			});
		return A2($elm_community$list_extra$List$Extra$getAt, index, items);
	});
var $author$project$Views$QuestIntroView$view = F3(
	function (activeQuest, appTimer, viewData) {
		var quest = activeQuest.it;
		var studentQuestId = activeQuest.a_;
		var seed = A2(
			$elm$core$Maybe$withDefault,
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$author$project$Models$Ids$toInt(studentQuestId)),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.cf;
					},
					$elm$core$Basics$floor),
				appTimer));
		var randomGreeting = function () {
			var defaultGreeting = 'Welcome! Let\'s learn about {0} together!';
			var greetings = _List_fromArray(
				[defaultGreeting, 'Welcome to your quest about {0}! Are you ready to learn with me?', 'Welcome! Let\'s learn about {0} together!', 'Hello! Let\'s start your quest about {0}!', 'Hi! Are you ready to learn about {0} with me?', 'Hi, friend! What’s going on? I’m excited to learn about {0} with you!', 'Hey! Nice to see you. Let’s learn about {0} together.', 'Howdy! Let’s learn about {0} together!']);
			return A2(
				$elm$core$Maybe$withDefault,
				defaultGreeting,
				A2($author$project$Helpers$Sample$sample, greetings, seed));
		}();
		var monsterStyling = function (element) {
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('left', '40%')
					]),
				$author$project$Styles$monsterOnPath(element));
		};
		var lightbulbs = A3($author$project$Component$Lightbulbs$render, activeQuest, $author$project$Component$Lightbulbs$NoLightbulb, viewData);
		var greeting = activeQuest.it.hr ? 'Welcome! Let’s find the perfect quest for you!' : A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			randomGreeting,
			_List_fromArray(
				[quest.i0]));
		var rockySpeaks = A2(
			$author$project$Views$RockySpeaks$render,
			viewData,
			{
				gr: greeting,
				gE: 'is-quest-intro',
				hN: monsterStyling,
				hU: $author$project$Messages$NavigateToActivity($author$project$AppFlow$NextIncompleteActivity),
				iK: $author$project$Assets$rockyStandingForward
			});
		return A2($visotype$elm_dom$Dom$appendChild, lightbulbs, rockySpeaks);
	});
var $author$project$AppFlow$GoToSubjectChoice = 1;
var $author$project$Views$QuestOutroView$questEndingBehavior = function (_v0) {
	var quest = _v0.it;
	return quest.hr ? 0 : 1;
};
var $author$project$Views$QuestOutroView$completeQuestButton = F2(
	function (activeQuest, viewData) {
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'high-five-outro',
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('right', '20px'),
				$author$project$Styles$activityButton(
					A2(
						$visotype$elm_dom$Dom$addClass,
						'complete-student-quest-button',
						A2(
							$author$project$Views$Components$Button$WithGlobalMsg$next,
							viewData.gv,
							$author$project$Messages$LoggedInRequiredMsg(
								A2(
									$author$project$LoggedIn$Messages$CompleteStudentQuest,
									activeQuest.a_,
									$author$project$Views$QuestOutroView$questEndingBehavior(activeQuest))))))));
	});
var $author$project$Views$QuestOutroView$bar = F2(
	function (activeQuest, viewData) {
		var content = $visotype$elm_dom$Dom$element('div');
		return A2(
			$author$project$Styles$onTop,
			1,
			A2(
				$author$project$Dom$Extra$addDataCy,
				'banner-text',
				$author$project$Component$BottomBar$customBar(
					{
						gq: $author$project$ES$UI$Color$transparent,
						gH: content,
						R: A2($author$project$Views$QuestOutroView$completeQuestButton, activeQuest, viewData),
						io: $visotype$elm_dom$Dom$element('span')
					})));
	});
var $author$project$Views$QuestOutroView$handHitTarget = function (hitPath) {
	return A2(
		$author$project$Dom$Extra$addDataCy,
		'rocky-hand-target',
		A2(
			$visotype$elm_dom$Dom$addAttributeList,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('hand-container')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A2(
					$visotype$elm_dom$Dom$addAttributeList,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$Assets$path(hitPath)),
							$elm$html$Html$Attributes$id('hand-hit')
						]),
					$visotype$elm_dom$Dom$element('img')),
				$visotype$elm_dom$Dom$element('div'))));
};
var $author$project$LoggedIn$Messages$ClickQuestOutroHandInteraction = function (a) {
	return {$: 17, a: a};
};
var $author$project$Views$QuestOutroView$handTarget = function (_v0) {
	var sound = _v0.aY;
	var handPath = _v0.cv;
	return A2(
		$author$project$Dom$Extra$addClickAction,
		$author$project$Messages$LoggedInRequiredMsg(
			$author$project$LoggedIn$Messages$ClickQuestOutroHandInteraction(sound)),
		A2(
			$visotype$elm_dom$Dom$addAttributeList,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('hand-container')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A2(
					$visotype$elm_dom$Dom$addAttributeList,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$Assets$path(handPath)),
							$elm$html$Html$Attributes$id('hand')
						]),
					$visotype$elm_dom$Dom$element('img')),
				$visotype$elm_dom$Dom$element('div'))));
};
var $author$project$InteractionSound$InteractionSound = $elm$core$Basics$identity;
var $author$project$InteractionSound$handshake = require('/usr/src/app/app/sounds/Nice_Work_Flow_1.mp3');
var $author$project$Assets$highFiveFist = require('/usr/src/app/app/images/high-five/fist.png');
var $author$project$Assets$highFiveFistHit = require('/usr/src/app/app/images/high-five/fist_hit.png');
var $author$project$Assets$niceWorkMark = require('/usr/src/app/app/images/high-five/nicework-mark-frame-900x600.svg');
var $author$project$Assets$highFiveBody = require('/usr/src/app/app/images/high-five/rocky.png');
var $author$project$Assets$highFiveSpinner = require('/usr/src/app/app/images/high-five/rays.png');
var $author$project$Assets$rocketFire = require('/usr/src/app/app/images/high-five/fire.png');
var $author$project$Views$QuestOutroView$rockyCelebration = F3(
	function (hand, handHit, highFivePath) {
		var spinner = A2(
			$visotype$elm_dom$Dom$addAttributeList,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('spinner-container')
				]),
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2(
						'background-image',
						$author$project$Styles$url($author$project$Assets$highFiveSpinner))
					]),
				A2(
					$visotype$elm_dom$Dom$appendChild,
					A2(
						$visotype$elm_dom$Dom$addAttributeList,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id('hf-mark')
							]),
						A2(
							$visotype$elm_dom$Dom$appendChild,
							A2(
								$visotype$elm_dom$Dom$addAttributeList,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										$author$project$Assets$path(highFivePath))
									]),
								$visotype$elm_dom$Dom$element('img')),
							$visotype$elm_dom$Dom$element('div'))),
					$visotype$elm_dom$Dom$element('div'))));
		var fire = A2(
			$visotype$elm_dom$Dom$addAttributeList,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fire-container')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						A2(
						$visotype$elm_dom$Dom$addAttributeList,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(
								$author$project$Assets$path($author$project$Assets$rocketFire)),
								$elm$html$Html$Attributes$class('fire'),
								$elm$html$Html$Attributes$id('fire1')
							]),
						$visotype$elm_dom$Dom$element('img')),
						A2(
						$visotype$elm_dom$Dom$addAttributeList,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(
								$author$project$Assets$path($author$project$Assets$rocketFire)),
								$elm$html$Html$Attributes$class('fire'),
								$elm$html$Html$Attributes$id('fire2')
							]),
						$visotype$elm_dom$Dom$element('img'))
					]),
				$visotype$elm_dom$Dom$element('div')));
		var confetti = A2(
			$visotype$elm_dom$Dom$addAttributeList,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('confetti-parent-container js-container')
				]),
			$visotype$elm_dom$Dom$element('div'));
		var body = A2(
			$visotype$elm_dom$Dom$addAttributeList,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('rocky-image-container')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A2(
					$visotype$elm_dom$Dom$addAttributeList,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(
							$author$project$Assets$path($author$project$Assets$highFiveBody)),
							$elm$html$Html$Attributes$class('rocky-image')
						]),
					$visotype$elm_dom$Dom$element('img')),
				$visotype$elm_dom$Dom$element('div')));
		var rocky = A2(
			$visotype$elm_dom$Dom$addAttributeList,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('hovering'),
					$elm$html$Html$Attributes$id('rocky')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[fire, body, hand, handHit]),
				$visotype$elm_dom$Dom$element('div')));
		var rockyZoomIn = A2(
			$visotype$elm_dom$Dom$addAttributeList,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('rocky-jetpack')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				rocky,
				$visotype$elm_dom$Dom$element('div')));
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'rocky-celebration',
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[confetti, spinner, rockyZoomIn]),
				$visotype$elm_dom$Dom$element('div')));
	});
var $author$project$Views$QuestOutroView$rockyHandshakeConsolation = function () {
	var highFivePath = $author$project$Assets$niceWorkMark;
	var handHit = $author$project$Views$QuestOutroView$handHitTarget($author$project$Assets$highFiveFistHit);
	var hand = $author$project$Views$QuestOutroView$handTarget(
		{cv: $author$project$Assets$highFiveFist, aY: $author$project$InteractionSound$handshake});
	return A3($author$project$Views$QuestOutroView$rockyCelebration, hand, handHit, highFivePath);
}();
var $author$project$InteractionSound$highFive = require('/usr/src/app/app/sounds/High_Five_Flow_2.mp3');
var $author$project$Assets$highFiveHand = require('/usr/src/app/app/images/high-five/hand.png');
var $author$project$Assets$highFiveHandHit = require('/usr/src/app/app/images/high-five/hand_hit.png');
var $author$project$Assets$highFiveMark = require('/usr/src/app/app/images/high-five/highfive-mark-frame-900x600.svg');
var $author$project$Views$QuestOutroView$rockyHighFiveCelebration = function () {
	var highFivePath = $author$project$Assets$highFiveMark;
	var handHit = $author$project$Views$QuestOutroView$handHitTarget($author$project$Assets$highFiveHandHit);
	var hand = $author$project$Views$QuestOutroView$handTarget(
		{cv: $author$project$Assets$highFiveHand, aY: $author$project$InteractionSound$highFive});
	return A3($author$project$Views$QuestOutroView$rockyCelebration, hand, handHit, highFivePath);
}();
var $author$project$Views$QuestOutroView$withThemedBackground = F2(
	function (studentQuestId, mode) {
		if (mode.$ === 1) {
			return $visotype$elm_dom$Dom$addStyleList(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'background-image',
						$author$project$Styles$url(
							$author$project$Assets$QuestTheme$planet(studentQuestId).ds.ff)),
						_Utils_Tuple2('background-size', 'auto 100%'),
						_Utils_Tuple2('background-repeat', 'repeat-x'),
						_Utils_Tuple2('background-position', 'center')
					]));
		} else {
			return $visotype$elm_dom$Dom$addClass('is-quest-outro');
		}
	});
var $author$project$Views$QuestOutroView$view = F2(
	function (activeQuest, viewData) {
		var celebration = A2(
			$visotype$elm_dom$Dom$appendChild,
			((!activeQuest.it.hr) && $author$project$Models$ActiveQuest$failedStudentQuest(activeQuest)) ? $author$project$Views$QuestOutroView$rockyHandshakeConsolation : $author$project$Views$QuestOutroView$rockyHighFiveCelebration,
			A2(
				$author$project$Styles$setAbsolutePosition,
				{gt: '110px', hx: '0', iJ: '0', jy: '0'},
				$visotype$elm_dom$Dom$element('div')));
		return A3(
			$author$project$Views$QuestOutroView$withThemedBackground,
			activeQuest.a_,
			viewData.f8,
			A2(
				$visotype$elm_dom$Dom$addClassList,
				_List_fromArray(
					['quest-completed-celebration', 'is-quest-outro']),
				A2(
					$visotype$elm_dom$Dom$appendChildList,
					_List_fromArray(
						[
							celebration,
							A2($author$project$Views$QuestOutroView$bar, activeQuest, viewData)
						]),
					$visotype$elm_dom$Dom$element('div'))));
	});
var $author$project$Models$StudentVideo$isComplete = function (maybeStudentVideo) {
	return A2(
		$elm$core$Maybe$withDefault,
		true,
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.aM;
			},
			maybeStudentVideo));
};
var $author$project$Component$Layout$title = function (elements) {
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		elements,
		A2(
			$visotype$elm_dom$Dom$addClass,
			'title',
			$visotype$elm_dom$Dom$element('h1')));
};
var $author$project$Component$HigherGradeInstructions$render = F4(
	function (header, content, maybeNextAction, viewData) {
		var nextActionButton = function () {
			if (!maybeNextAction.$) {
				var nextAction = maybeNextAction.a;
				return A2(
					$author$project$Animations$CssAnimations$applyWithAnimationPreset,
					5,
					A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('right', '20px'),
						$author$project$Styles$activityButton(
							A2(
								$visotype$elm_dom$Dom$addClass,
								'next-activity-button',
								A2($author$project$Views$Components$Button$WithGlobalMsg$next, viewData.gv, nextAction)))));
			} else {
				return $visotype$elm_dom$Dom$element('span');
			}
		}();
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('height', '100vh'),
					_Utils_Tuple2('flex-direction', 'column'),
					_Utils_Tuple2('justify-content', 'center')
				]),
			A2(
				$visotype$elm_dom$Dom$addClass,
				'has-background-orange-pale',
				A2(
					$visotype$elm_dom$Dom$appendChildList,
					_List_fromArray(
						[
							A2(
							$author$project$Component$Text$colored,
							$author$project$ES$UI$Color$white,
							A2(
								$visotype$elm_dom$Dom$addStyleList,
								_List_fromArray(
									[
										_Utils_Tuple2('padding', '5px 40px'),
										_Utils_Tuple2('margin-bottom', '0'),
										_Utils_Tuple2('height', '48px')
									]),
								A2(
									$visotype$elm_dom$Dom$addClassList,
									_List_fromArray(
										['has-background-orange']),
									$author$project$Component$Layout$title(
										$elm$core$List$singleton(
											A2(
												$visotype$elm_dom$Dom$appendText,
												header,
												$visotype$elm_dom$Dom$element('div'))))))),
							A2(
							$author$project$Styles$filled,
							$author$project$ES$UI$Color$white,
							A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('padding', '40px'),
								content)),
							nextActionButton
						]),
					$visotype$elm_dom$Dom$element('div'))));
	});
var $author$project$Models$Modal$PrequizMasteryModal = {$: 2};
var $author$project$Component$Icon$Default = {$: 0};
var $author$project$Component$Icon$SkipAhead = 25;
var $author$project$Views$Components$Button$WithGlobalMsg$prequizMasterySkipAhead = F2(
	function (isLoading, message) {
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'prequiz-mastery-skip-ahead-button',
			A2(
				$author$project$Dom$AddClickOnceAction$addClickOnceAction,
				message,
				A2(
					$author$project$Component$Buttons$Contained$button,
					$author$project$Component$Buttons$Button$loadingStateFromDisabledState(isLoading),
					{
						gG: 4,
						hi: 1,
						hI: $elm$core$Maybe$Just(
							A2($author$project$Component$Icon$Icon, 25, $author$project$Component$Icon$Default)),
						ju: 'Skip Ahead'
					})));
	});
var $author$project$Component$Icon$StayHere = 28;
var $author$project$Views$Components$Button$WithGlobalMsg$prequizMasteryStayHere = function (message) {
	return A2(
		$author$project$Dom$Extra$addDataCy,
		'prequiz-mastery-stay-here-button',
		A2(
			$author$project$Dom$Extra$addClickAction,
			message,
			A2(
				$author$project$Component$Buttons$Contained$button,
				0,
				{
					gG: 4,
					hi: 1,
					hI: $elm$core$Maybe$Just(
						A2($author$project$Component$Icon$Icon, 28, $author$project$Component$Icon$Default)),
					ju: 'Stay Here'
				})));
};
var $author$project$Views$PrequizMasteryModal$render = F4(
	function (activeQuest, _v0, viewData, openState) {
		var topTextContent = A2(
			$author$project$Dom$Extra$addDataCy,
			'prequiz-mastery-top-text',
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('margin-bottom', '50px'),
				A2(
					$author$project$Component$Text$colored,
					$author$project$ES$UI$Color$teal.ay,
					A2(
						$author$project$Component$Text$inPixels,
						36,
						A2(
							$visotype$elm_dom$Dom$appendChild,
							A4($author$project$TextToSpeech$WithGlobalMsg$speakable, viewData, viewData.gj, 'Nice Work! It looks like you already know this!', $author$project$Models$Voice$default),
							$visotype$elm_dom$Dom$element('div'))))));
		var stayHereButton = A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('width', 'calc(50% - 10px)'),
			A2(
				$visotype$elm_dom$Dom$addAttribute,
				A2($elm$html$Html$Attributes$attribute, 'aria-label', 'close'),
				$author$project$Views$Components$Button$WithGlobalMsg$prequizMasteryStayHere($author$project$Messages$CloseModal)));
		var skipAheadButton = A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('width', 'calc(50% - 10px)'),
			A2(
				$author$project$Views$Components$Button$WithGlobalMsg$prequizMasterySkipAhead,
				viewData.gv,
				$author$project$Messages$LoggedInRequiredMsg(
					A2($author$project$LoggedIn$Messages$CompleteStudentQuest, activeQuest.a_, 0))));
		var monster = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('position', 'absolute'),
					_Utils_Tuple2('top', '0')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('position', 'relative'),
							_Utils_Tuple2('z-index', '5'),
							_Utils_Tuple2('margin-left', '435px'),
							_Utils_Tuple2('top', '140px'),
							_Utils_Tuple2('transform', 'scaleX(-1)')
						]),
					A2(
						$author$project$Dom$Extra$addDataCy,
						'prequiz-mastery-monster',
						A2(
							$author$project$Styles$square,
							240,
							A3($author$project$Component$Monster$renderSvg, viewData.iQ, 5, 1)))),
				$visotype$elm_dom$Dom$element('div')));
		var monsterIfSequentialMode = _Utils_eq(viewData.f8, $author$project$AppFlow$Sequential) ? monster : $visotype$elm_dom$Dom$element('span');
		var buttonContent = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('box-shadow', '0px -4px 3px rgba(30, 30, 30, 0.35)'),
					_Utils_Tuple2('z-index', '15'),
					_Utils_Tuple2('padding', '20px'),
					_Utils_Tuple2('place-content', 'center space-between')
				]),
			A2(
				$author$project$Styles$filled,
				$author$project$ES$UI$Color$white,
				$author$project$Styles$centerContent(
					A2(
						$visotype$elm_dom$Dom$appendChildList,
						_List_fromArray(
							[stayHereButton, skipAheadButton]),
						$visotype$elm_dom$Dom$element('div')))));
		var bottomTextContent = A2(
			$author$project$Dom$Extra$addDataCy,
			'prequiz-mastery-bottom-text',
			A2(
				$author$project$Component$Text$colored,
				$author$project$ES$UI$Color$teal.ay,
				A2(
					$author$project$Component$Text$inPixels,
					36,
					A2(
						$visotype$elm_dom$Dom$appendChild,
						A4($author$project$TextToSpeech$WithGlobalMsg$speakable, viewData, viewData.gj, 'Stay here, or skip ahead to the next quest?', $author$project$Models$Voice$default),
						$visotype$elm_dom$Dom$element('div')))));
		var textContent = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('margin', '0 165px 80px 40px'),
					_Utils_Tuple2('padding-top', '50px')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[topTextContent, bottomTextContent]),
				$visotype$elm_dom$Dom$element('div')));
		var content = A2(
			$author$project$Styles$filled,
			$author$project$ES$UI$Color$white,
			$author$project$Styles$sizeFill(
				A2(
					$visotype$elm_dom$Dom$appendChildList,
					_List_fromArray(
						[textContent, monsterIfSequentialMode, buttonContent]),
					$visotype$elm_dom$Dom$element('div'))));
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'prequiz-mastery-modal',
			A3(
				$author$project$Component$Modal$wrapper,
				$author$project$Models$Modal$PrequizMasteryModal,
				openState,
				{
					gH: content,
					hb: 530,
					h5: $elm$core$Maybe$Just($author$project$Messages$CloseModal),
					jS: 660
				}));
	});
var $author$project$Routes$activityIntroRoute = function (studentActivity) {
	var activity = studentActivity.f_;
	var routeType = function () {
		var _v0 = activity.f0;
		switch (_v0.$) {
			case 7:
				return F2(
					function (questId, activityId) {
						return $author$project$Routes$QuizRoute(
							A2($author$project$Routes$QuizRoutes$Intro, questId, activityId));
					});
			case 8:
				return F2(
					function (questId, activityId) {
						return $author$project$Routes$QuizRoute(
							A2($author$project$Routes$QuizRoutes$Intro, questId, activityId));
					});
			default:
				return F2(
					function (questId, activityId) {
						return $author$project$Routes$ActivityRoute(
							A2($author$project$Routes$ActivityRoutes$Intro, questId, activityId));
					});
		}
	}();
	return A2(routeType, studentActivity.a_, studentActivity.hj);
};
var $elm_community$list_extra$List$Extra$findIndexHelp = F3(
	function (index, predicate, list) {
		findIndexHelp:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					return $elm$core$Maybe$Just(index);
				} else {
					var $temp$index = index + 1,
						$temp$predicate = predicate,
						$temp$list = xs;
					index = $temp$index;
					predicate = $temp$predicate;
					list = $temp$list;
					continue findIndexHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$findIndex = $elm_community$list_extra$List$Extra$findIndexHelp(0);
var $elm_community$list_extra$List$Extra$elemIndex = function (x) {
	return $elm_community$list_extra$List$Extra$findIndex(
		$elm$core$Basics$eq(x));
};
var $author$project$AppFlow$Navigation$routeForNextActivity = F3(
	function (includeVideoRecording, currentQuest, navigationDestination) {
		routeForNextActivity:
		while (true) {
			var studentActivities = $author$project$Models$ActiveQuest$availableActivities(currentQuest);
			var studentActivityIds = A2(
				$elm$core$List$map,
				function ($) {
					return $.hj;
				},
				studentActivities);
			var studentActivityAtRelativeIndex = F2(
				function (currentStudentActivityId, offset) {
					return A2(
						$elm$core$Maybe$andThen,
						function (index) {
							return A2($elm_community$list_extra$List$Extra$getAt, index + offset, studentActivities);
						},
						A2($elm_community$list_extra$List$Extra$elemIndex, currentStudentActivityId, studentActivityIds));
				});
			var remainingStudentActivities = A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Models$StudentActivity$isCompleted),
				studentActivities);
			var nextActivityPracticeRoute = function (maybeStudentActivity) {
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Routes$QuestRoute(
						$author$project$Routes$QuestRoutes$Home(currentQuest.a_)),
					A2($elm$core$Maybe$map, $author$project$Routes$activityPracticeRoute, maybeStudentActivity));
			};
			var nextActivityIntroRoute = function (maybeStudentActivity) {
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Routes$QuestRoute(
						$author$project$Routes$QuestRoutes$Home(currentQuest.a_)),
					A2($elm$core$Maybe$map, $author$project$Routes$activityIntroRoute, maybeStudentActivity));
			};
			var nextActivityRoute = function (maybeStudentActivity) {
				var isRemedialActivity = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					false,
					function ($) {
						return $.hs;
					},
					maybeStudentActivity);
				return isRemedialActivity ? nextActivityPracticeRoute(maybeStudentActivity) : (currentQuest.it.hr ? nextActivityPracticeRoute(maybeStudentActivity) : nextActivityIntroRoute(maybeStudentActivity));
			};
			var routeForActivityToDoNext = function (maybeStudentActivity) {
				var _v8 = _Utils_Tuple2(
					maybeStudentActivity,
					A2($author$project$Models$ActiveQuest$videoRecordingIncomplete, includeVideoRecording, currentQuest));
				if (_v8.a.$ === 1) {
					if (_v8.b) {
						var _v9 = _v8.a;
						return $author$project$Routes$VideoRoute(
							$author$project$Routes$VideoRoutes$RecordingIntro(currentQuest.a_));
					} else {
						var _v10 = _v8.a;
						return $author$project$Routes$QuestRoute(
							$author$project$Routes$QuestRoutes$Outro(currentQuest.a_));
					}
				} else {
					return nextActivityRoute(maybeStudentActivity);
				}
			};
			switch (navigationDestination.$) {
				case 0:
					return routeForActivityToDoNext(
						$elm$core$List$head(remainingStudentActivities));
				case 2:
					return nextActivityIntroRoute(
						$elm$core$List$head(studentActivities));
				case 3:
					return nextActivityPracticeRoute(
						A2(
							$elm_community$list_extra$List$Extra$find,
							function (sa) {
								return (!_Utils_eq(sa.f_.f0, $author$project$Models$Activity$Prequiz)) && (!_Utils_eq(sa.f_.f0, $author$project$Models$Activity$Postquiz));
							},
							$elm$core$List$reverse(studentActivities)));
				case 1:
					var currentStudentActivityId = navigationDestination.a;
					var _v1 = A2(studentActivityAtRelativeIndex, currentStudentActivityId, 1);
					if (_v1.$ === 1) {
						return routeForActivityToDoNext(
							$elm$core$List$head(remainingStudentActivities));
					} else {
						var aStudentActivity = _v1.a;
						var _v2 = _Utils_Tuple2(aStudentActivity.f_.f0, aStudentActivity.ir);
						if (_v2.b.$ === 2) {
							if (_v2.a.$ === 8) {
								var _v3 = _v2.a;
								var _v4 = _v2.b;
								var $temp$includeVideoRecording = includeVideoRecording,
									$temp$currentQuest = currentQuest,
									$temp$navigationDestination = $author$project$AppFlow$NextSequentialActivity(aStudentActivity.hj);
								includeVideoRecording = $temp$includeVideoRecording;
								currentQuest = $temp$currentQuest;
								navigationDestination = $temp$navigationDestination;
								continue routeForNextActivity;
							} else {
								var _v5 = _v2.b;
								return nextActivityPracticeRoute(
									$elm$core$Maybe$Just(aStudentActivity));
							}
						} else {
							return nextActivityIntroRoute(
								$elm$core$Maybe$Just(aStudentActivity));
						}
					}
				case 4:
					var currentStudentActivityId = navigationDestination.a;
					var _v6 = A2(studentActivityAtRelativeIndex, currentStudentActivityId, -1);
					if (_v6.$ === 1) {
						return $author$project$Routes$QuestRoute(
							$author$project$Routes$QuestRoutes$Intro(currentQuest.a_));
					} else {
						var aStudentActivity = _v6.a;
						var _v7 = aStudentActivity.f_.f0;
						switch (_v7.$) {
							case 7:
								return $author$project$Routes$QuestRoute(
									$author$project$Routes$QuestRoutes$Intro(currentQuest.a_));
							case 8:
								var $temp$includeVideoRecording = includeVideoRecording,
									$temp$currentQuest = currentQuest,
									$temp$navigationDestination = $author$project$AppFlow$PreviousSequentialActivity(aStudentActivity.hj);
								includeVideoRecording = $temp$includeVideoRecording;
								currentQuest = $temp$currentQuest;
								navigationDestination = $temp$navigationDestination;
								continue routeForNextActivity;
							default:
								return $author$project$Routes$activityPracticeRoute(aStudentActivity);
						}
					}
				default:
					var studentActivityId = navigationDestination.a;
					return nextActivityPracticeRoute(
						A2($author$project$Models$Ids$findById, studentActivityId, studentActivities));
			}
		}
	});
var $author$project$Views$QuizOutroView$view = F4(
	function (videoRecordingPermitted, studentActivity, activeQuest, viewData) {
		var activity = studentActivity.f_;
		var quest = activeQuest.it;
		var studentQuestId = activeQuest.a_;
		var studentVideo = activeQuest.jg;
		var scoreDescription = function (_v5) {
			var score = _v5.e4;
			var maxScore = _v5.eu;
			var passed = _v5.$8;
			var punctuation = passed ? '!' : '.';
			var _v4 = viewData.f8;
			if (!_v4.$) {
				return A2(
					$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
					'You got {0} out of {1}{2}',
					_List_fromArray(
						[
							$elm$core$String$fromInt(score),
							$elm$core$String$fromInt(maxScore),
							punctuation
						]));
			} else {
				var percentage = ((score * 100) / maxScore) | 0;
				return A2(
					$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
					'You got {0}%{1}',
					_List_fromArray(
						[
							$elm$core$String$fromInt(percentage),
							punctuation
						]));
			}
		};
		var nextButtonAction = function () {
			var _v3 = viewData.f8;
			if (!_v3.$) {
				var isFailedQuiz = A2(
					$elm$core$Maybe$withDefault,
					false,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Basics$not,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.$8;
							},
							studentActivity.gd)));
				return (_Utils_eq(activity.f0, $author$project$Models$Activity$Postquiz) && isFailedQuiz) ? $author$project$Messages$NavigateTo(
					A3($author$project$AppFlow$Navigation$routeForNextActivity, videoRecordingPermitted, activeQuest, $author$project$AppFlow$NextIncompleteActivity)) : $author$project$Messages$NavigateToActivity(
					$author$project$AppFlow$NextSequentialActivity(studentActivity.hj));
			} else {
				return $author$project$Models$ActiveQuest$failedStudentQuest(activeQuest) ? $author$project$Messages$NavigateTo(
					$author$project$Routes$QuestRoute(
						$author$project$Routes$QuestRoutes$Outro(activeQuest.a_))) : $author$project$Messages$NavigateTo(
					$author$project$Routes$QuestRoute(
						$author$project$Routes$QuestRoutes$Home(studentQuestId)));
			}
		}();
		var assessmentResults = function () {
			var _v1 = studentActivity.gd;
			if (_v1.$ === 1) {
				return _List_fromArray(
					['Thanks for taking the quiz! Let\'s move on!']);
			} else {
				var assessmentResult = _v1.a;
				var resultDescription = function () {
					var _v2 = activity.f0;
					switch (_v2.$) {
						case 8:
							return assessmentResult.$8 ? 'You know a lot about {0}!' : ($author$project$Models$StudentVideo$isComplete(studentVideo) ? 'Nice work on {0}! Let\'s keep learning!' : 'Let’s watch a video and then try again.');
						case 7:
							return assessmentResult.$8 ? 'You know a lot about {0}! Let\'s start our quest!' : 'Let\'s start our quest!';
						default:
							return assessmentResult.$8 ? 'You know a lot about {0}!' : 'Let\'s watch a video about {0}!';
					}
				}();
				return _List_fromArray(
					[
						scoreDescription(assessmentResult),
						A2(
						$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
						resultDescription,
						_List_fromArray(
							[
								$elm$core$String$toLower(quest.i0)
							]))
					]);
			}
		}();
		var pageContent = function () {
			var _v0 = viewData.f8;
			if (!_v0.$) {
				return A2(
					$visotype$elm_dom$Dom$appendChildList,
					_List_fromArray(
						[
							A4($author$project$Views$PrequizMasteryModal$render, activeQuest, studentActivity, viewData, viewData.hM),
							A2(
							$author$project$Views$RockyMeetsMonster$view,
							viewData,
							{
								fZ: activeQuest,
								gr: A2($elm$core$String$join, ' ', assessmentResults),
								gE: 'quiz-outro',
								hU: nextButtonAction,
								ip: $elm$core$Maybe$Nothing,
								je: studentActivity,
								a1: viewData
							})
						]),
					$visotype$elm_dom$Dom$element('div'));
			} else {
				var infoText = A2(
					$visotype$elm_dom$Dom$appendChildList,
					A2(
						$elm$core$List$map,
						function (string) {
							return A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('text-align', 'center'),
								A2(
									$visotype$elm_dom$Dom$appendChild,
									A2(
										$author$project$Component$Text$colored,
										$author$project$ES$UI$Color$grey.j1,
										A2(
											$visotype$elm_dom$Dom$addStyleList,
											_List_fromArray(
												[
													_Utils_Tuple2('font-size', '44px'),
													_Utils_Tuple2('line-height', '58px')
												]),
											A2(
												$visotype$elm_dom$Dom$appendText,
												string,
												$visotype$elm_dom$Dom$element('span')))),
									$visotype$elm_dom$Dom$element('div')));
						},
						assessmentResults),
					$visotype$elm_dom$Dom$element('div'));
				var infoContent = A2(
					$visotype$elm_dom$Dom$addStyle,
					_Utils_Tuple2('position', 'relative'),
					A2(
						$visotype$elm_dom$Dom$appendChildList,
						_List_fromArray(
							[
								A4($author$project$Views$PrequizMasteryModal$render, activeQuest, studentActivity, viewData, viewData.hM),
								A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('margin-bottom', '50px'),
								infoText)
							]),
						$visotype$elm_dom$Dom$element('div')));
				return A2(
					$visotype$elm_dom$Dom$addClass,
					'quiz-outro',
					A4(
						$author$project$Component$HigherGradeInstructions$render,
						'Quiz',
						infoContent,
						$elm$core$Maybe$Just(nextButtonAction),
						viewData));
			}
		}();
		return A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					pageContent,
					A3(
					$author$project$Component$Lightbulbs$render,
					activeQuest,
					$author$project$Component$Lightbulbs$ActiveLightbulb(studentActivity),
					viewData)
				]),
			$visotype$elm_dom$Dom$element('div'));
	});
var $author$project$Dom$Extra$appendChildMaybe = F2(
	function (f, maybeChild) {
		if (!maybeChild.$) {
			var child = maybeChild.a;
			return $visotype$elm_dom$Dom$appendChild(
				f(child));
		} else {
			return $elm$core$Basics$identity;
		}
	});
var $author$project$Models$SubjectArea$Reading = 1;
var $author$project$Views$SubjectChoiceView$Messages$ShowAllAssignments = 0;
var $author$project$Messages$SubjectChoiceViewMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$LoggedIn$Messages$OpenClassLibrarySection = function (a) {
	return {$: 97, a: a};
};
var $author$project$Views$SubjectChoiceView$Messages$ShowAllClassLibraries = 1;
var $author$project$Views$SubjectChoiceView$sectionHeader = function (title) {
	return A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('padding', '14px'),
				_Utils_Tuple2('font-size', '42px'),
				_Utils_Tuple2('border-radius', '15px'),
				_Utils_Tuple2('margin-bottom', '40px')
			]),
		A2(
			$visotype$elm_dom$Dom$addClass,
			'has-text-centered',
			A2(
				$visotype$elm_dom$Dom$appendText,
				title,
				$visotype$elm_dom$Dom$element('h2'))));
};
var $author$project$Views$SubjectChoiceView$ClassLibraryCard$height = '300px';
var $author$project$Assets$classLibraryImage = require('/usr/src/app/app/images/class-library-card-image.png');
var $author$project$Assets$subjectIconReading = require('/usr/src/app/app/images/subject-icon-reading.svg');
var $author$project$Views$SubjectChoiceView$ClassLibraryCard$styledCardImageContainer = function () {
	var subjectIcon = A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('position', 'absolute'),
				_Utils_Tuple2('width', '65px'),
				_Utils_Tuple2('height', '46px'),
				_Utils_Tuple2('right', '3px'),
				_Utils_Tuple2('bottom', '-6px')
			]),
		A2(
			$visotype$elm_dom$Dom$addAttribute,
			$elm$html$Html$Attributes$src(
				$author$project$Assets$path($author$project$Assets$subjectIconReading)),
			$visotype$elm_dom$Dom$element('img')));
	var cardImage = function () {
		var imagePath = $author$project$Assets$path($author$project$Assets$classLibraryImage);
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('background', 'url(' + (imagePath + ')')),
					_Utils_Tuple2('background-repeat', 'no-repeat'),
					_Utils_Tuple2('background-size', 'cover'),
					_Utils_Tuple2('background-position', 'center'),
					_Utils_Tuple2('border-radius', '15px'),
					_Utils_Tuple2('flex', 'none'),
					_Utils_Tuple2('order', '0'),
					_Utils_Tuple2('align-self', 'stretch'),
					_Utils_Tuple2('flex-grow', '1'),
					_Utils_Tuple2('margin', '0px')
				]),
			$visotype$elm_dom$Dom$element('div'));
	}();
	return A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('position', 'relative'),
				_Utils_Tuple2('flex', 'none'),
				_Utils_Tuple2('order', '0'),
				_Utils_Tuple2('align-self', 'stretch'),
				_Utils_Tuple2('flex-grow', '1'),
				_Utils_Tuple2('margin', '0px 0px'),
				_Utils_Tuple2('display', 'flex'),
				_Utils_Tuple2('flex-direction', 'column'),
				_Utils_Tuple2('align-items', 'flex-start'),
				_Utils_Tuple2('padding', '0px'),
				_Utils_Tuple2('width', '100%')
			]),
		A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[cardImage, subjectIcon]),
			$visotype$elm_dom$Dom$element('div')));
}();
var $author$project$Views$SubjectChoiceView$ClassLibraryCard$styledTitleContainer = function (cardTitle) {
	var _v0 = _Utils_Tuple2('40px', '52px');
	var fontSize = _v0.a;
	var lineHeight = _v0.b;
	return A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('margin', '10px 0'),
				_Utils_Tuple2('font-family', 'Didact Gothic'),
				_Utils_Tuple2('font-style', 'normal'),
				_Utils_Tuple2('font-weight', 'normal'),
				_Utils_Tuple2('font-size', fontSize),
				_Utils_Tuple2('line-height', lineHeight),
				_Utils_Tuple2('text-align', 'center'),
				_Utils_Tuple2(
				'color',
				$avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$black)),
				_Utils_Tuple2('flex', 'none'),
				_Utils_Tuple2('order', '0'),
				_Utils_Tuple2('flex-grow', '0'),
				_Utils_Tuple2('width', '100%')
			]),
		A2(
			$visotype$elm_dom$Dom$appendText,
			'Class Library',
			$visotype$elm_dom$Dom$element('span')));
};
var $author$project$Assets$defaultTeacherImage = require('/usr/src/app/app/images/generic-teacher-avatar.svg');
var $author$project$Views$SubjectChoiceView$ClassLibraryCard$teacherDetailView = function (_v0) {
	var teacherImageUrl = _v0.js;
	var teacherName = _v0.fr;
	var sectionName = _v0.iS;
	var teacherImage = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Assets$path($author$project$Assets$defaultTeacherImage),
		teacherImageUrl);
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[
				A2(
				$visotype$elm_dom$Dom$appendChild,
				A2(
					$visotype$elm_dom$Dom$addStyle,
					_Utils_Tuple2('border-radius', '75px'),
					$author$project$Component$Image$render(
						{f5: 'Your teacher', hb: 30, ig: teacherImage, jS: 30})),
				$visotype$elm_dom$Dom$element('div')),
				A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						A2(
						$visotype$elm_dom$Dom$appendText,
						teacherName,
						$visotype$elm_dom$Dom$element('div')),
						A2(
						$visotype$elm_dom$Dom$appendText,
						sectionName,
						$visotype$elm_dom$Dom$element('div'))
					]),
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('text-align', 'left'),
							_Utils_Tuple2('margin-left', '10px'),
							_Utils_Tuple2('font-size', '14px')
						]),
					$visotype$elm_dom$Dom$element('div')))
			]),
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2(
					'color',
					$avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$black)),
					_Utils_Tuple2('margin-bottom', '10px')
				]),
			$visotype$elm_dom$Dom$element('div')));
};
var $author$project$Views$SubjectChoiceView$ClassLibraryCard$width = '395px';
var $author$project$Views$SubjectChoiceView$ClassLibraryCard$view = F2(
	function (openLibraryMsg, classLibrary) {
		var titleContainer = $author$project$Views$SubjectChoiceView$ClassLibraryCard$styledTitleContainer(classLibrary.iS);
		var cardImageContainer = $author$project$Views$SubjectChoiceView$ClassLibraryCard$styledCardImageContainer;
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('min-width', $author$project$Views$SubjectChoiceView$ClassLibraryCard$width),
					_Utils_Tuple2('width', $author$project$Views$SubjectChoiceView$ClassLibraryCard$width),
					_Utils_Tuple2('height', $author$project$Views$SubjectChoiceView$ClassLibraryCard$height),
					_Utils_Tuple2('min-height', $author$project$Views$SubjectChoiceView$ClassLibraryCard$height),
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('flex-direction', 'column'),
					_Utils_Tuple2('align-items', 'flex-start'),
					_Utils_Tuple2('padding', '10px'),
					_Utils_Tuple2('box-shadow', '0px 5px 10px rgba(0, 0, 0, 0.2)'),
					_Utils_Tuple2('border-radius', '15px'),
					_Utils_Tuple2(
					'background',
					$avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$yellow.az))
				]),
			A2(
				$visotype$elm_dom$Dom$addAction,
				_Utils_Tuple2(
					'click',
					openLibraryMsg(classLibrary.iR)),
				A2(
					$author$project$Dom$Extra$addDataCy,
					'class-library-card',
					A2(
						$visotype$elm_dom$Dom$addClass,
						'class-library-card',
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[
									$author$project$Views$SubjectChoiceView$ClassLibraryCard$teacherDetailView(classLibrary),
									cardImageContainer,
									titleContainer
								]),
							$visotype$elm_dom$Dom$element('button'))))));
	});
var $author$project$Views$SubjectChoiceView$viewShowMoreButton = F3(
	function (msg, moreText, countOfHiddenItems) {
		return A2(
			$visotype$elm_dom$Dom$appendText,
			'Show ' + ($elm$core$String$fromInt(countOfHiddenItems) + (' more ' + moreText)),
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('padding', '10px 20px'),
						_Utils_Tuple2('font-size', '18px'),
						_Utils_Tuple2(
						'background',
						$avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$white)),
						_Utils_Tuple2('border-radius', '10px')
					]),
				A2(
					$visotype$elm_dom$Dom$addAction,
					_Utils_Tuple2('click', msg),
					$visotype$elm_dom$Dom$element('button'))));
	});
var $author$project$Views$SubjectChoiceView$classLibrarySection = F2(
	function (classLibraries, showAllClassLibraries) {
		var moreText = ($elm$core$List$length(classLibraries) <= 2) ? 'Class Library' : 'Class Libraries';
		return A3(
			$visotype$elm_dom$Dom$appendChildConditional,
			A2(
				$visotype$elm_dom$Dom$appendNode,
				A2(
					$elm$html$Html$map,
					$author$project$Messages$SubjectChoiceViewMsg,
					$visotype$elm_dom$Dom$render(
						A3(
							$author$project$Views$SubjectChoiceView$viewShowMoreButton,
							1,
							moreText,
							$elm$core$List$length(classLibraries) - 1))),
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('text-align', 'right')
						]),
					$visotype$elm_dom$Dom$element('div'))),
			($elm$core$List$length(classLibraries) > 1) && (!showAllClassLibraries),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A2(
					$visotype$elm_dom$Dom$appendChildList,
					A2(
						$elm$core$List$map,
						function (classLibrary) {
							return A2(
								$visotype$elm_dom$Dom$appendChild,
								A2(
									$author$project$Views$SubjectChoiceView$ClassLibraryCard$view,
									A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$OpenClassLibrarySection, $author$project$Messages$LoggedInRequiredMsg),
									classLibrary),
								A2(
									$visotype$elm_dom$Dom$addStyle,
									_Utils_Tuple2('justify-content', 'center'),
									A2(
										$visotype$elm_dom$Dom$addClassList,
										_List_fromArray(
											['is-half', 'is-flex']),
										$author$project$Component$ResponsiveGrid$column(
											$visotype$elm_dom$Dom$element('div')))));
						},
						showAllClassLibraries ? classLibraries : A2($elm$core$List$take, 1, classLibraries)),
					A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('margin-bottom', '40px'),
						A2(
							$visotype$elm_dom$Dom$addClassList,
							_List_fromArray(
								['columns', 'is-centered', 'is-multiline']),
							$visotype$elm_dom$Dom$element('div')))),
				A2(
					$visotype$elm_dom$Dom$appendChild,
					$author$project$Views$SubjectChoiceView$sectionHeader('Class Libraries'),
					$visotype$elm_dom$Dom$element('section'))));
	});
var $author$project$LoggedIn$Messages$SelectQuestToWorkOn = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$Views$SubjectChoiceView$StudentQuestCard$SubjectCard = 1;
var $author$project$Views$SubjectChoiceView$StudentQuestCard$AssignmentCard = 0;
var $author$project$Views$SubjectChoiceView$StudentQuestCard$height = '300px';
var $author$project$Assets$math = require('/usr/src/app/app/images/tiles/math-tile.svg');
var $author$project$Assets$reading = require('/usr/src/app/app/images/tiles/reading-tile.svg');
var $author$project$Assets$subjectIconMath = require('/usr/src/app/app/images/subject-icon-math.svg');
var $author$project$Assets$subjectIconWriting = require('/usr/src/app/app/images/subject-icon-writing.svg');
var $author$project$Views$SubjectChoiceView$StudentQuestCard$styledCardImageContainer = F2(
	function (subjectArea, thumbnailUrl) {
		var subjectIcon = function () {
			var icon = function () {
				switch (subjectArea) {
					case 1:
						return $author$project$Assets$subjectIconReading;
					case 0:
						return $author$project$Assets$subjectIconMath;
					default:
						return $author$project$Assets$subjectIconWriting;
				}
			}();
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('position', 'absolute'),
						_Utils_Tuple2('width', '65px'),
						_Utils_Tuple2('height', '46px'),
						_Utils_Tuple2('right', '3px'),
						_Utils_Tuple2('bottom', '-6px')
					]),
				A2(
					$visotype$elm_dom$Dom$addAttribute,
					$elm$html$Html$Attributes$src(
						$author$project$Assets$path(icon)),
					$visotype$elm_dom$Dom$element('img')));
		}();
		var cardImage = function () {
			var defaultImagePath = function () {
				switch (subjectArea) {
					case 1:
						return $author$project$Assets$reading;
					case 0:
						return $author$project$Assets$math;
					default:
						return $author$project$Assets$reading;
				}
			}();
			var imagePath = A2(
				$elm$core$Maybe$withDefault,
				$author$project$Assets$path(defaultImagePath),
				thumbnailUrl);
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('background', 'url(' + (imagePath + ')')),
						_Utils_Tuple2('background-repeat', 'no-repeat'),
						_Utils_Tuple2('background-size', 'cover'),
						_Utils_Tuple2('background-position', 'center'),
						_Utils_Tuple2('border-radius', '15px'),
						_Utils_Tuple2('flex', 'none'),
						_Utils_Tuple2('order', '0'),
						_Utils_Tuple2('align-self', 'stretch'),
						_Utils_Tuple2('flex-grow', '1'),
						_Utils_Tuple2('margin', '0px')
					]),
				$visotype$elm_dom$Dom$element('div'));
		}();
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('position', 'relative'),
					_Utils_Tuple2('flex', 'none'),
					_Utils_Tuple2('order', '0'),
					_Utils_Tuple2('align-self', 'stretch'),
					_Utils_Tuple2('flex-grow', '1'),
					_Utils_Tuple2('margin', '0px 0px'),
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('flex-direction', 'column'),
					_Utils_Tuple2('align-items', 'flex-start'),
					_Utils_Tuple2('padding', '0px'),
					_Utils_Tuple2('width', '100%')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[cardImage, subjectIcon]),
				$visotype$elm_dom$Dom$element('div')));
	});
var $author$project$Views$SubjectChoiceView$StudentQuestCard$styledTitleContainer = F2(
	function (cardTitle, cardType) {
		var _v0 = (!cardType) ? _Utils_Tuple2('28px', '37px') : _Utils_Tuple2('40px', '52px');
		var fontSize = _v0.a;
		var lineHeight = _v0.b;
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('margin', '10px 0'),
					_Utils_Tuple2('font-family', 'Didact Gothic'),
					_Utils_Tuple2('font-style', 'normal'),
					_Utils_Tuple2('font-weight', 'normal'),
					_Utils_Tuple2('font-size', fontSize),
					_Utils_Tuple2('line-height', lineHeight),
					_Utils_Tuple2('text-align', 'center'),
					_Utils_Tuple2(
					'color',
					$avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$white)),
					_Utils_Tuple2('flex', 'none'),
					_Utils_Tuple2('order', '0'),
					_Utils_Tuple2('flex-grow', '0'),
					_Utils_Tuple2('width', '100%')
				]),
			A2(
				$visotype$elm_dom$Dom$appendText,
				cardTitle,
				$visotype$elm_dom$Dom$element('span')));
	});
var $author$project$Views$SubjectChoiceView$StudentQuestCard$width = '395px';
var $author$project$Views$SubjectChoiceView$StudentQuestCard$renderStudentQuestCard = function (_v0) {
	var cardType = _v0.gx;
	var subjectArea = _v0.bp;
	var cardTitle = _v0.gw;
	var thumbnailUrl = _v0.c2;
	var maybeHeader = _v0.hH;
	var titleContainer = A2($author$project$Views$SubjectChoiceView$StudentQuestCard$styledTitleContainer, cardTitle, cardType);
	var subjectString = $author$project$Models$SubjectArea$toString(subjectArea);
	var cardTypeString = (!cardType) ? 'assignment' : 'subject';
	var cardImageContainer = A2($author$project$Views$SubjectChoiceView$StudentQuestCard$styledCardImageContainer, subjectArea, thumbnailUrl);
	var cardElements = A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		_List_fromArray(
			[cardImageContainer, titleContainer]),
		function (header) {
			return _List_fromArray(
				[header, cardImageContainer, titleContainer]);
		},
		maybeHeader);
	var cardColor = function () {
		switch (subjectArea) {
			case 1:
				return $avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$blue.ay);
			case 0:
				return $avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$purple.ay);
			default:
				return $avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$teal.ay);
		}
	}();
	var cardClass = $elm$core$String$toLower(
		A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[cardTypeString, subjectString, 'card'])));
	return A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('width', $author$project$Views$SubjectChoiceView$StudentQuestCard$width),
				_Utils_Tuple2('height', $author$project$Views$SubjectChoiceView$StudentQuestCard$height),
				_Utils_Tuple2('display', 'flex'),
				_Utils_Tuple2('flex-direction', 'column'),
				_Utils_Tuple2('align-items', 'flex-start'),
				_Utils_Tuple2('padding', '10px'),
				_Utils_Tuple2('box-shadow', '0px 5px 10px rgba(0, 0, 0, 0.2)'),
				_Utils_Tuple2('border-radius', '15px'),
				_Utils_Tuple2('background', cardColor)
			]),
		A2(
			$author$project$Dom$Extra$addDataCy,
			cardClass,
			A2(
				$visotype$elm_dom$Dom$addClass,
				cardClass,
				A2(
					$visotype$elm_dom$Dom$appendChildList,
					cardElements,
					$visotype$elm_dom$Dom$element('button')))));
};
var $author$project$Views$SubjectChoiceView$AdaptivePathQuestCard$renderStudentQuest = function (availableQuest) {
	var subjectTitle = (!availableQuest.bp) ? 'Math' : 'Reading';
	return $author$project$Views$SubjectChoiceView$StudentQuestCard$renderStudentQuestCard(
		{gw: subjectTitle, gx: 1, hH: $elm$core$Maybe$Nothing, bp: availableQuest.bp, c2: availableQuest.c2});
};
var $author$project$Models$Modal$VideoFeedbackModal = function (a) {
	return {$: 6, a: a};
};
var $author$project$Views$SubjectChoiceView$AdaptivePathQuestCard$height = '300px';
var $author$project$VideoFeedback$init = F2(
	function (subjectArea, videoFeedbackReviewed) {
		return {
			bp: subjectArea,
			b4: videoFeedbackReviewed,
			jI: $author$project$Models$VideoPlayer$NotStarted($elm$core$Maybe$Nothing)
		};
	});
var $author$project$Component$Icon$font = F2(
	function (iconName, modifiers) {
		return A2(
			$visotype$elm_dom$Dom$addClassList,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					['es-icon', 'es-icon-' + iconName]),
				modifiers),
			$visotype$elm_dom$Dom$element('i'));
	});
var $author$project$Component$Icon$quarterCircle = function (_v0) {
	var iconName = _v0.hh;
	var textInPixels = _v0.jw;
	var filledColor = _v0.g4;
	return A2(
		$visotype$elm_dom$Dom$appendChild,
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('margin-top', '10px'),
					_Utils_Tuple2('margin-left', '-15px')
				]),
			A2(
				$author$project$Component$Text$colored,
				$author$project$ES$UI$Color$white,
				A2(
					$author$project$Component$Text$inPixels,
					textInPixels,
					$author$project$Styles$centerContent(
						A2($author$project$Component$Icon$font, iconName, _List_Nil))))),
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('height', '100px'),
					_Utils_Tuple2('width', '100px'),
					_Utils_Tuple2('border-radius', '0px 0px 100px 0px')
				]),
			A2(
				$author$project$Styles$filled,
				filledColor,
				$visotype$elm_dom$Dom$element('div'))));
};
var $author$project$Component$Icon$VideoFeedback = 45;
var $author$project$Views$Components$Button$WithGlobalMsg$videoFeedback = function (message) {
	return A2(
		$author$project$Dom$Extra$addClickAction,
		message,
		A2(
			$author$project$Component$Buttons$Action$button,
			0,
			_Utils_Tuple3(
				0,
				0,
				A2($author$project$Component$Icon$Icon, 45, $author$project$Component$Icon$Large))));
};
var $author$project$VideoFeedback$CancelReview = {$: 0};
var $author$project$VideoFeedback$VideoPlaybackEnded = {$: 1};
var $author$project$VideoFeedback$VideoPlaybackErrored = {$: 2};
var $author$project$VideoFeedback$PauseVideo = {$: 8};
var $author$project$VideoFeedback$PlayVideo = function (a) {
	return {$: 6, a: a};
};
var $author$project$VideoFeedback$RestartVideo = function (a) {
	return {$: 7, a: a};
};
var $author$project$VideoFeedback$ReviewVideoWithFeedback = function (a) {
	return {$: 3, a: a};
};
var $author$project$VideoFeedback$VideoRightClick = {$: 9};
var $author$project$Assets$monstersAtMovies = require('/usr/src/app/app/images/monsters/monsters-at-movies@2x.png');
var $author$project$VideoFeedback$videoKey = function (uniqueId) {
	return 'video-player-' + uniqueId;
};
var $author$project$VideoFeedback$videoPlayer = F2(
	function (model, _v0) {
		var viewData = _v0.a1;
		var uniqueId = _v0.fC;
		var videoUrl = _v0.fK;
		var videoEndedMsg = _v0.fH;
		var videoErrorMsg = _v0.fI;
		var showMonsters = _v0.fd;
		var subjectArea = _v0.bp;
		var progressBar = A2(
			$author$project$Styles$onTop,
			5,
			A2(
				$visotype$elm_dom$Dom$addClass,
				'video-progress-bar',
				A2(
					$visotype$elm_dom$Dom$addStyle,
					_Utils_Tuple2('bottom', '0'),
					$author$project$Component$Video$progressBar(model.jI))));
		var playMsg = $author$project$VideoFeedback$ReviewVideoWithFeedback(
			$author$project$VideoFeedback$videoKey(uniqueId));
		var monstersAtMovies = function () {
			var action = function () {
				var _v2 = model.jI;
				if (_v2.$ === 2) {
					return $author$project$VideoFeedback$PauseVideo;
				} else {
					return playMsg;
				}
			}();
			return showMonsters ? A2(
				$visotype$elm_dom$Dom$addAction,
				_Utils_Tuple2('click', action),
				A2(
					$author$project$Dom$Extra$addDataCy,
					'monsters-at-movies',
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('bottom', '0'),
								_Utils_Tuple2('position', 'absolute'),
								_Utils_Tuple2('left', '0'),
								_Utils_Tuple2('right', '0'),
								_Utils_Tuple2('margin', 'auto'),
								_Utils_Tuple2('width', '545px')
							]),
						A2(
							$author$project$Styles$onTop,
							0,
							$author$project$Component$Image$render(
								{
									f5: '',
									hb: 142,
									ig: $author$project$Assets$path($author$project$Assets$monstersAtMovies),
									jS: 545
								}))))) : $visotype$elm_dom$Dom$element('span');
		}();
		var controls = A2(
			$visotype$elm_dom$Dom$addClass,
			'video-controls',
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('position', 'absolute'),
						_Utils_Tuple2('top', '50%'),
						_Utils_Tuple2('bottom', '50%'),
						_Utils_Tuple2('left', '0'),
						_Utils_Tuple2('right', '0'),
						_Utils_Tuple2('margin', 'auto'),
						_Utils_Tuple2('pointer-events', 'all')
					]),
				$author$project$Component$Video$controls(
					{
						ie: $elm$core$Maybe$Just(playMsg),
						ik: $author$project$VideoFeedback$PlayVideo,
						iE: $author$project$VideoFeedback$RestartVideo,
						fJ: $author$project$VideoFeedback$videoKey(uniqueId),
						jI: model.jI
					})));
		var _v1 = viewData;
		var devFlags = _v1.dO;
		var player = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('height', '100%')
				]),
			A2(
				$visotype$elm_dom$Dom$addClass,
				'video-feedback-player',
				$author$project$Component$Video$player(
					{
						gV: videoEndedMsg,
						co: videoErrorMsg,
						ih: $author$project$VideoFeedback$PauseVideo,
						b_: devFlags.jJ,
						fE: videoUrl,
						fJ: $author$project$VideoFeedback$videoKey(uniqueId),
						da: $author$project$VideoFeedback$VideoRightClick
					})));
		return A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[player, monstersAtMovies, progressBar, controls]),
			$visotype$elm_dom$Dom$element('div'));
	});
var $author$project$VideoFeedback$FinishReviewing = function (a) {
	return {$: 12, a: a};
};
var $author$project$VideoFeedback$PlayTTS = function (a) {
	return {$: 10, a: a};
};
var $author$project$VideoFeedback$VideoFeedbackReviewed = function (a) {
	return {$: 4, a: a};
};
var $author$project$Component$Icon$Star = 26;
var $author$project$VideoFeedback$starFeedback = function (starRating) {
	var stars = A2(
		$elm$core$List$repeat,
		starRating,
		A2(
			$author$project$Component$Text$inPixels,
			30,
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('margin-right', '10px')
					]),
				A2(
					$author$project$Dom$Extra$addDataCy,
					'video-feedback-star',
					A2(
						$author$project$Component$Text$colored,
						$author$project$ES$UI$Color$yellow.ay,
						$author$project$Component$Icon$render(
							A2(
								$author$project$Component$Icon$Icon,
								26,
								$author$project$Component$Icon$Custom('30px'))))))));
	var starTextDict = $elm$core$Dict$fromList(
		_List_fromArray(
			[
				_Utils_Tuple2(1, 'You can do better!'),
				_Utils_Tuple2(2, 'Nice try!'),
				_Utils_Tuple2(3, 'Good job!'),
				_Utils_Tuple2(4, 'Amazing work!')
			]));
	var text = A2(
		$author$project$Component$Text$inPixels,
		28,
		A2(
			$visotype$elm_dom$Dom$appendText,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2($elm$core$Dict$get, starRating, starTextDict)),
			$visotype$elm_dom$Dom$element('span')));
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		$elm$core$List$reverse(
			A2($elm$core$List$cons, text, stars)),
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('height', '100px'),
					_Utils_Tuple2('width', '100%'),
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('place-content', 'center'),
					_Utils_Tuple2('align-items', 'center')
				]),
			$visotype$elm_dom$Dom$element('div')));
};
var $author$project$Views$Components$Button$videoFeedbackMarkSeen = function (message) {
	return A2(
		$author$project$Dom$Extra$addDataCy,
		'video-feedback-mark-seen-button',
		A2(
			$visotype$elm_dom$Dom$addAction,
			_Utils_Tuple2('click', message),
			A2(
				$author$project$Component$Buttons$Action$button,
				0,
				_Utils_Tuple3(
					0,
					2,
					A2($author$project$Component$Icon$Icon, 8, $author$project$Component$Icon$Large)))));
};
var $author$project$VideoFeedback$viewFooter = F2(
	function (model, _v0) {
		var viewData = _v0.a1;
		var videoPromptText = _v0.jL;
		var videoPromptAudioOverride = _v0.jK;
		var feedbackStudentQuestId = _v0.dW;
		var teacherFeedback = _v0.jr;
		var nextQuest = _v0.cH;
		var videoPromptContent = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('height', '190px'),
					_Utils_Tuple2('padding', '50px')
				]),
			A2(
				$author$project$Component$Text$inPixels,
				28,
				A2(
					$visotype$elm_dom$Dom$appendChild,
					A3(
						$visotype$elm_dom$Dom$addAttributeConditional,
						A2($elm$html$Html$Attributes$attribute, 'alias', videoPromptAudioOverride),
						!$elm$core$String$isEmpty(videoPromptAudioOverride),
						A5($author$project$TextToSpeech$speakable, $author$project$VideoFeedback$PlayTTS, viewData, false, videoPromptText, $author$project$Models$Voice$default)),
					$visotype$elm_dom$Dom$element('div'))));
		var styleNextButton = $visotype$elm_dom$Dom$addStyleList(
			_List_fromArray(
				[
					_Utils_Tuple2('display', 'inline-block'),
					_Utils_Tuple2('position', 'absolute'),
					_Utils_Tuple2('right', '20px'),
					_Utils_Tuple2('bottom', '20px')
				]));
		var videoFeedbackReviewedButton = styleNextButton(
			$author$project$Views$Components$Button$videoFeedbackMarkSeen(
				$author$project$VideoFeedback$VideoFeedbackReviewed(feedbackStudentQuestId)));
		var quarterCircleIcon = function (color) {
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('top', '0'),
						_Utils_Tuple2('left', '0'),
						_Utils_Tuple2('position', 'absolute'),
						_Utils_Tuple2('z-index', '10')
					]),
				$author$project$Component$Icon$quarterCircle(
					{g4: color, hh: 'video-feedback', jw: 64}));
		};
		var nextButton = A2(
			$author$project$Dom$Extra$addDataCy,
			'video-feedback-next-quest-button',
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('z-index', '22'),
				styleNextButton(
					A2(
						$author$project$Views$Components$Button$next,
						false,
						$author$project$VideoFeedback$FinishReviewing(nextQuest)))));
		var _v1 = teacherFeedback;
		var starRating = _v1.i2;
		var textFeedback = _v1.jv;
		var feedbackSeen = _v1.g3;
		var button = feedbackSeen ? nextButton : (model.b4 ? videoFeedbackReviewedButton : $visotype$elm_dom$Dom$element('span'));
		var textFeedbackContent = A2(
			$author$project$Component$Text$inPixels,
			28,
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A5($author$project$TextToSpeech$speakable, $author$project$VideoFeedback$PlayTTS, viewData, false, textFeedback, $author$project$Models$Voice$default),
				$visotype$elm_dom$Dom$element('div')));
		var scrollingContent = A2(
			$visotype$elm_dom$Dom$appendChild,
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						$author$project$VideoFeedback$starFeedback(starRating),
						A2(
						$visotype$elm_dom$Dom$appendChild,
						textFeedbackContent,
						A2(
							$visotype$elm_dom$Dom$addStyle,
							_Utils_Tuple2('margin', '10px 100px 40px 50px'),
							$visotype$elm_dom$Dom$element('div')))
					]),
				$visotype$elm_dom$Dom$element('div')),
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('height', '100%'),
						_Utils_Tuple2('width', 'calc(100% - 10px)'),
						_Utils_Tuple2('overflow-y', 'scroll'),
						_Utils_Tuple2('margin', 'auto')
					]),
				$visotype$elm_dom$Dom$element('div')));
		var teacherFeedbackContent = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('height', '270px'),
					_Utils_Tuple2(
					'box-shadow',
					'inset 0px 0px 0 6px ' + $avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$yellow.at))
				]),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						quarterCircleIcon($author$project$ES$UI$Color$yellow.at),
						scrollingContent
					]),
				$visotype$elm_dom$Dom$element('div')));
		var content = function () {
			var _v2 = _Utils_Tuple2(model.jI, feedbackSeen);
			switch (_v2.a.$) {
				case 2:
					var _v3 = _v2.a;
					return videoPromptContent;
				case 1:
					if (!_v2.b) {
						return videoPromptContent;
					} else {
						return teacherFeedbackContent;
					}
				case 3:
					return videoPromptContent;
				case 0:
					if (!_v2.b) {
						var rockyText = A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2('padding', '0 0 0 100px'),
									_Utils_Tuple2('margin', '-10px 0')
								]),
							A2(
								$author$project$Component$Text$inPixels,
								36,
								A2(
									$visotype$elm_dom$Dom$addClass,
									'has-text-rocky',
									A2(
										$visotype$elm_dom$Dom$appendChild,
										A5($author$project$TextToSpeech$speakable, $author$project$VideoFeedback$PlayTTS, viewData, viewData.gj, 'Your teacher reviewed your video! Let\'s watch it again together.', $author$project$Models$Voice$default),
										$visotype$elm_dom$Dom$element('div')))));
						return A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2('height', '190px'),
									_Utils_Tuple2('padding', '50px')
								]),
							A2(
								$visotype$elm_dom$Dom$appendChild,
								rockyText,
								$visotype$elm_dom$Dom$element('div')));
					} else {
						return A2(
							$visotype$elm_dom$Dom$addStyle,
							_Utils_Tuple2('height', '270px'),
							A2(
								$visotype$elm_dom$Dom$appendChildList,
								_List_fromArray(
									[
										nextButton,
										quarterCircleIcon($author$project$ES$UI$Color$teal.at),
										scrollingContent
									]),
								$visotype$elm_dom$Dom$element('div')));
					}
				default:
					return teacherFeedbackContent;
			}
		}();
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'video-feedback-footer',
			A2(
				$author$project$Styles$filled,
				$author$project$ES$UI$Color$white,
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('position', 'absolute'),
							_Utils_Tuple2('bottom', '0'),
							_Utils_Tuple2('width', '100%'),
							_Utils_Tuple2('text-align', 'left'),
							_Utils_Tuple2('z-index', '6')
						]),
					A2($visotype$elm_dom$Dom$appendChild, button, content))));
	});
var $author$project$VideoFeedback$render = F2(
	function (model, _v0) {
		var viewData = _v0.a1;
		var studentQuestId = _v0.a_;
		var studentVideo = _v0.jg;
		var subjectArea = _v0.bp;
		var videoPromptText = _v0.jL;
		var videoPromptAudioOverride = _v0.jK;
		var teacherFeedback = _v0.jr;
		var nextQuest = _v0.cH;
		var video = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('position', 'absolute'),
					_Utils_Tuple2('bottom', '190px'),
					_Utils_Tuple2('left', '0'),
					_Utils_Tuple2('top', '0'),
					_Utils_Tuple2('right', '0')
				]),
			A2(
				$author$project$VideoFeedback$videoPlayer,
				model,
				{
					fd: true,
					bp: subjectArea,
					fC: $author$project$Models$Ids$toString(studentQuestId),
					fH: $author$project$VideoFeedback$VideoPlaybackEnded,
					fI: $author$project$VideoFeedback$VideoPlaybackErrored,
					fK: $author$project$Models$Urls$toString(studentVideo.iL),
					a1: viewData
				}));
		var rocky = function () {
			var _v1 = _Utils_Tuple2(model.jI, teacherFeedback.g3);
			if ((!_v1.a.$) && (!_v1.b)) {
				return A2(
					$author$project$Styles$onTop,
					6,
					A2(
						$author$project$Dom$Extra$addDataCy,
						'video-feedback-rocky',
						A2(
							$visotype$elm_dom$Dom$addClass,
							'video-feedback-rocky',
							A2(
								$visotype$elm_dom$Dom$addStyleList,
								_List_fromArray(
									[
										_Utils_Tuple2('position', 'absolute'),
										_Utils_Tuple2('bottom', '0'),
										_Utils_Tuple2('left', '-30px'),
										_Utils_Tuple2('height', '200px'),
										_Utils_Tuple2('width', '155px')
									]),
								A3($author$project$Component$Monster$renderSvg, viewData.iQ, 6, 1)))));
			} else {
				return $visotype$elm_dom$Dom$element('span');
			}
		}();
		var footer = A2(
			$author$project$VideoFeedback$viewFooter,
			model,
			{dW: studentQuestId, cH: nextQuest, bp: subjectArea, jr: teacherFeedback, jK: videoPromptAudioOverride, jL: videoPromptText, a1: viewData});
		var closeButton = A2(
			$author$project$Dom$Extra$addDataCy,
			'close-modal-button',
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('position', 'absolute'),
						_Utils_Tuple2('top', '0'),
						_Utils_Tuple2('right', '-6px'),
						_Utils_Tuple2('background', 'transparent')
					]),
				$author$project$Views$Components$Button$close($author$project$VideoFeedback$CancelReview)));
		var content = A2(
			$visotype$elm_dom$Dom$addClass,
			'modal-content',
			A2(
				$author$project$Styles$radius,
				5,
				A2(
					$visotype$elm_dom$Dom$addStyle,
					_Utils_Tuple2('position', 'relative'),
					A2(
						$author$project$Styles$size,
						{hb: '600px', jS: '740px'},
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[video, footer, rocky, closeButton]),
							$visotype$elm_dom$Dom$element('div'))))));
		var background = A2(
			$visotype$elm_dom$Dom$addClass,
			'modal-background',
			$visotype$elm_dom$Dom$element('div'));
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'video-feedback-modal',
			A2(
				$author$project$Styles$onTop,
				301,
				A2(
					$visotype$elm_dom$Dom$addClassList,
					_List_fromArray(
						['modal', 'is-active']),
					A2(
						$visotype$elm_dom$Dom$appendChildList,
						_List_fromArray(
							[background, content]),
						$visotype$elm_dom$Dom$element('div')))));
	});
var $author$project$Views$SubjectChoiceView$AdaptivePathQuestCard$videoFeedbackModal = function (_v0) {
	var viewData = _v0.a1;
	var nextQuest = _v0.cH;
	var questWithFeedback = _v0.aU;
	var studentVideo = _v0.jg;
	var teacherFeedback = _v0.jr;
	var _v1 = viewData.hM;
	if ((!_v1.$) && (_v1.a.$ === 6)) {
		var videoFeedbackModel = _v1.a.a;
		return _Utils_eq(videoFeedbackModel.bp, questWithFeedback.bp) ? A2(
			$visotype$elm_dom$Dom$appendNode,
			A2(
				$elm$html$Html$map,
				A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$VideoFeedbackMsg, $author$project$Messages$LoggedInRequiredMsg),
				$visotype$elm_dom$Dom$render(
					A2(
						$author$project$VideoFeedback$render,
						videoFeedbackModel,
						{cH: nextQuest, a_: questWithFeedback.a_, jg: studentVideo, bp: questWithFeedback.bp, jr: teacherFeedback, jK: questWithFeedback.it.jK, jL: questWithFeedback.it.jL, a1: viewData}))),
			$visotype$elm_dom$Dom$element('div')) : A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('display', 'none'),
			$visotype$elm_dom$Dom$element('span'));
	} else {
		return A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('display', 'none'),
			$visotype$elm_dom$Dom$element('span'));
	}
};
var $author$project$Views$SubjectChoiceView$AdaptivePathQuestCard$width = '395px';
var $author$project$Views$SubjectChoiceView$AdaptivePathQuestCard$renderWithFeedback = function (_v0) {
	var viewData = _v0.a1;
	var nextQuest = _v0.cH;
	var subjectCard = _v0.fn;
	var questWithFeedback = _v0.aU;
	var studentVideo = _v0.jg;
	var teacherFeedback = _v0.jr;
	var subjectCardwithFeedback = teacherFeedback.g3 ? A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[
				A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('position', 'absolute'),
						_Utils_Tuple2('margin-left', '-24px'),
						_Utils_Tuple2('margin-top', '-17px')
					]),
				A2(
					$author$project$Dom$Extra$addDataCy,
					'video-feedback-icon-' + $author$project$Models$SubjectArea$toString(questWithFeedback.bp),
					A2(
						$author$project$Styles$onTop,
						100,
						$author$project$Views$Components$Button$WithGlobalMsg$videoFeedback(
							$author$project$Messages$OpenModal(
								$author$project$Models$Modal$VideoFeedbackModal(
									A2($author$project$VideoFeedback$init, questWithFeedback.bp, false))))))),
				A2(
				$author$project$Dom$Extra$addClickAction,
				$author$project$Messages$LoggedInRequiredMsg(
					A3($author$project$LoggedIn$Messages$SelectQuestToWorkOn, nextQuest.a_, nextQuest.bp, false)),
				subjectCard)
			]),
		$visotype$elm_dom$Dom$element('div')) : A2(
		$author$project$Dom$Extra$addDataCy,
		'subject-card-video-feedback-' + $author$project$Models$SubjectArea$toString(questWithFeedback.bp),
		A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('position', 'absolute'),
							_Utils_Tuple2('border-radius', '15px 0px 100px 0px'),
							_Utils_Tuple2('pointer-events', 'none')
						]),
					A2(
						$author$project$Styles$onTop,
						100,
						$author$project$Component$Icon$quarterCircle(
							{g4: $author$project$ES$UI$Color$yellow.at, hh: 'video-feedback', jw: 64}))),
					A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('position', 'absolute'),
							_Utils_Tuple2('height', $author$project$Views$SubjectChoiceView$AdaptivePathQuestCard$height),
							_Utils_Tuple2('width', $author$project$Views$SubjectChoiceView$AdaptivePathQuestCard$width),
							_Utils_Tuple2('border-radius', '15px'),
							_Utils_Tuple2('pointer-events', 'none'),
							_Utils_Tuple2(
							'border',
							'10px solid ' + $avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$yellow.at)),
							_Utils_Tuple2('z-index', '1')
						]),
					$visotype$elm_dom$Dom$element('div')),
					A2(
					$author$project$Dom$Extra$addClickAction,
					$author$project$Messages$OpenModal(
						$author$project$Models$Modal$VideoFeedbackModal(
							A2($author$project$VideoFeedback$init, questWithFeedback.bp, false))),
					subjectCard)
				]),
			$visotype$elm_dom$Dom$element('div')));
	var feedbackModal = $author$project$Views$SubjectChoiceView$AdaptivePathQuestCard$videoFeedbackModal(
		{cH: nextQuest, aU: questWithFeedback, jg: studentVideo, jr: teacherFeedback, a1: viewData});
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[subjectCardwithFeedback, feedbackModal]),
		$visotype$elm_dom$Dom$element('div'));
};
var $author$project$Views$SubjectChoiceView$AdaptivePathQuestCard$render = F2(
	function (viewData, adaptivePathQuest) {
		var subjectCard = $author$project$Views$SubjectChoiceView$AdaptivePathQuestCard$renderStudentQuest(adaptivePathQuest.jf);
		var feedbackInfo = A2(
			$elm$core$Maybe$andThen,
			function (questWithFeedback) {
				return A2(
					$elm$core$Maybe$andThen,
					function (studentVideo) {
						return A2(
							$elm$core$Maybe$andThen,
							function (teacherFeedback) {
								return $elm$core$Maybe$Just(
									{aU: questWithFeedback, jg: studentVideo, jr: teacherFeedback});
							},
							studentVideo.jr);
					},
					questWithFeedback.jg);
			},
			adaptivePathQuest.cs);
		if (!feedbackInfo.$) {
			var feedback = feedbackInfo.a;
			return $author$project$Views$SubjectChoiceView$AdaptivePathQuestCard$renderWithFeedback(
				{cH: adaptivePathQuest.jf, aU: feedback.aU, jg: feedback.jg, fn: subjectCard, jr: feedback.jr, a1: viewData});
		} else {
			return A2(
				$author$project$Dom$Extra$addClickAction,
				$author$project$Messages$LoggedInRequiredMsg(
					A3($author$project$LoggedIn$Messages$SelectQuestToWorkOn, adaptivePathQuest.jf.a_, adaptivePathQuest.jf.bp, false)),
				subjectCard);
		}
	});
var $author$project$Views$SubjectChoiceView$viewAdaptivePathQuest = F2(
	function (viewData, adaptivePathQuest) {
		return A2(
			$visotype$elm_dom$Dom$appendChild,
			A2($author$project$Views$SubjectChoiceView$AdaptivePathQuestCard$render, viewData, adaptivePathQuest),
			A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('justify-content', 'center'),
				A2(
					$visotype$elm_dom$Dom$addClassList,
					_List_fromArray(
						['is-half', 'is-flex']),
					$author$project$Component$ResponsiveGrid$column(
						$visotype$elm_dom$Dom$element('div')))));
	});
var $author$project$Views$SubjectChoiceView$myQuestsSection = F4(
	function (viewData, readingQuest, mathQuest, hasAnyAssignments) {
		return A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					$author$project$Views$SubjectChoiceView$sectionHeader(
					hasAnyAssignments ? 'My Quests' : 'What would you like to learn?'),
					A3(
					$author$project$Dom$Extra$appendChildMaybe,
					$author$project$Views$SubjectChoiceView$viewAdaptivePathQuest(viewData),
					mathQuest,
					A3(
						$author$project$Dom$Extra$appendChildMaybe,
						$author$project$Views$SubjectChoiceView$viewAdaptivePathQuest(viewData),
						readingQuest,
						A2(
							$visotype$elm_dom$Dom$addClassList,
							_List_fromArray(
								['columns', 'is-centered', 'is-multiline']),
							A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('margin-bottom', '120px'),
								$visotype$elm_dom$Dom$element('div')))))
				]),
			$visotype$elm_dom$Dom$element('section'));
	});
var $gingko$time_distance$Time$Distance$I18n$en = F3(
	function (_v0, tense, distanceId) {
		var withAffix = _v0.jU;
		var toStr = $elm$core$String$fromInt;
		var maybeAffix = function (str) {
			var _v2 = _Utils_Tuple2(withAffix, tense);
			if (_v2.a) {
				if (!_v2.b) {
					var _v3 = _v2.b;
					return str + ' ago';
				} else {
					var _v4 = _v2.b;
					return 'in ' + str;
				}
			} else {
				return str;
			}
		};
		return maybeAffix(
			function () {
				switch (distanceId.$) {
					case 0:
						var i = distanceId.a;
						return (i === 1) ? 'less than 1 second' : ('less than ' + (toStr(i) + ' seconds'));
					case 1:
						return 'half a minute';
					case 2:
						var i = distanceId.a;
						return (i === 1) ? 'less than a minute' : ('less than ' + (toStr(i) + ' minutes'));
					case 3:
						var i = distanceId.a;
						return (i === 1) ? '1 minute' : (toStr(i) + ' minutes');
					case 4:
						var i = distanceId.a;
						return (i === 1) ? 'about 1 hour' : ('about ' + (toStr(i) + ' hours'));
					case 5:
						var i = distanceId.a;
						return (i === 1) ? '1 day' : (toStr(i) + ' days');
					case 6:
						var i = distanceId.a;
						return (i === 1) ? 'about 1 month' : ('about ' + (toStr(i) + ' months'));
					case 7:
						var i = distanceId.a;
						return (i === 1) ? '1 month' : (toStr(i) + ' months');
					case 8:
						var i = distanceId.a;
						return (i === 1) ? 'about 1 year' : ('about ' + (toStr(i) + ' years'));
					case 9:
						var i = distanceId.a;
						return (i === 1) ? 'over 1 year' : ('over ' + (toStr(i) + ' years'));
					default:
						var i = distanceId.a;
						return (i === 1) ? 'almost 1 year' : ('almost ' + (toStr(i) + ' years'));
				}
			}());
	});
var $gingko$time_distance$Time$Distance$Types$Future = 1;
var $gingko$time_distance$Time$Distance$Types$Past = 0;
var $gingko$time_distance$Time$Distance$Types$AboutXHours = function (a) {
	return {$: 4, a: a};
};
var $gingko$time_distance$Time$Distance$Types$LessThanXMinutes = function (a) {
	return {$: 2, a: a};
};
var $gingko$time_distance$Time$Distance$Types$XDays = function (a) {
	return {$: 5, a: a};
};
var $gingko$time_distance$Time$Distance$Types$XMinutes = function (a) {
	return {$: 3, a: a};
};
var $gingko$time_distance$Time$Distance$minutes_in_almost_two_days = 2520;
var $gingko$time_distance$Time$Distance$minutes_in_day = 1440;
var $gingko$time_distance$Time$Distance$minutes_in_month = 43200;
var $gingko$time_distance$Time$Distance$minutes_in_two_months = 86400;
var $gingko$time_distance$Time$Distance$Types$AboutXYears = function (a) {
	return {$: 8, a: a};
};
var $gingko$time_distance$Time$Distance$Types$AlmostXYears = function (a) {
	return {$: 10, a: a};
};
var $gingko$time_distance$Time$Distance$Types$OverXYears = function (a) {
	return {$: 9, a: a};
};
var $gingko$time_distance$Time$Distance$Types$XMonths = function (a) {
	return {$: 7, a: a};
};
var $gingko$time_distance$Time$Distance$upToOneYear = function (minutes) {
	var nearestMonth = $elm$core$Basics$round(minutes / $gingko$time_distance$Time$Distance$minutes_in_month);
	return $gingko$time_distance$Time$Distance$Types$XMonths(nearestMonth);
};
var $gingko$time_distance$Time$Distance$moreThanTwoMonths = function (minutes) {
	var months = (minutes / $gingko$time_distance$Time$Distance$minutes_in_month) | 0;
	if (months < 12) {
		return $gingko$time_distance$Time$Distance$upToOneYear(minutes);
	} else {
		var years = $elm$core$Basics$floor(months / 12);
		var monthsSinceStartOfYear = A2($elm$core$Basics$modBy, 12, months);
		return (monthsSinceStartOfYear < 3) ? $gingko$time_distance$Time$Distance$Types$AboutXYears(years) : ((monthsSinceStartOfYear < 9) ? $gingko$time_distance$Time$Distance$Types$OverXYears(years) : $gingko$time_distance$Time$Distance$Types$AlmostXYears(years + 1));
	}
};
var $gingko$time_distance$Time$Distance$upToOneDay = function (minutes) {
	var hours = $elm$core$Basics$round(minutes / 60);
	return $gingko$time_distance$Time$Distance$Types$AboutXHours(hours);
};
var $gingko$time_distance$Time$Distance$Types$HalfAMinute = {$: 1};
var $gingko$time_distance$Time$Distance$Types$LessThanXSeconds = function (a) {
	return {$: 0, a: a};
};
var $gingko$time_distance$Time$Distance$upToOneMinute = function (seconds) {
	return (seconds < 5) ? $gingko$time_distance$Time$Distance$Types$LessThanXSeconds(5) : ((seconds < 10) ? $gingko$time_distance$Time$Distance$Types$LessThanXSeconds(10) : ((seconds < 20) ? $gingko$time_distance$Time$Distance$Types$LessThanXSeconds(20) : ((seconds < 40) ? $gingko$time_distance$Time$Distance$Types$HalfAMinute : ((seconds < 60) ? $gingko$time_distance$Time$Distance$Types$LessThanXMinutes(1) : $gingko$time_distance$Time$Distance$Types$XMinutes(1)))));
};
var $gingko$time_distance$Time$Distance$upToOneMonth = function (minutes) {
	var days = $elm$core$Basics$round(minutes / $gingko$time_distance$Time$Distance$minutes_in_day);
	return $gingko$time_distance$Time$Distance$Types$XDays(days);
};
var $gingko$time_distance$Time$Distance$Types$AboutXMonths = function (a) {
	return {$: 6, a: a};
};
var $gingko$time_distance$Time$Distance$upToTwoMonths = function (minutes) {
	var months = $elm$core$Basics$round(minutes / $gingko$time_distance$Time$Distance$minutes_in_month);
	return $gingko$time_distance$Time$Distance$Types$AboutXMonths(months);
};
var $gingko$time_distance$Time$Distance$calculateDistance = F3(
	function (includeSeconds, s1, s2) {
		var seconds = ($elm$core$Basics$abs(s2 - s1) / 1000) | 0;
		var minutes = $elm$core$Basics$round(seconds / 60);
		return (includeSeconds && (minutes < 2)) ? $gingko$time_distance$Time$Distance$upToOneMinute(seconds) : ((!minutes) ? $gingko$time_distance$Time$Distance$Types$LessThanXMinutes(1) : ((minutes < 2) ? $gingko$time_distance$Time$Distance$Types$XMinutes(minutes) : ((minutes < 45) ? $gingko$time_distance$Time$Distance$Types$XMinutes(minutes) : ((minutes < 90) ? $gingko$time_distance$Time$Distance$Types$AboutXHours(1) : ((_Utils_cmp(minutes, $gingko$time_distance$Time$Distance$minutes_in_day) < 0) ? $gingko$time_distance$Time$Distance$upToOneDay(minutes) : ((_Utils_cmp(minutes, $gingko$time_distance$Time$Distance$minutes_in_almost_two_days) < 0) ? $gingko$time_distance$Time$Distance$Types$XDays(1) : ((_Utils_cmp(minutes, $gingko$time_distance$Time$Distance$minutes_in_month) < 0) ? $gingko$time_distance$Time$Distance$upToOneMonth(minutes) : ((_Utils_cmp(minutes, $gingko$time_distance$Time$Distance$minutes_in_two_months) < 0) ? $gingko$time_distance$Time$Distance$upToTwoMonths(minutes) : $gingko$time_distance$Time$Distance$moreThanTwoMonths(minutes)))))))));
	});
var $gingko$time_distance$Time$Distance$inWordsWithConfig = F4(
	function (config, locale, otherTime, currentTime) {
		var otherSeconds = $elm$time$Time$posixToMillis(otherTime);
		var currentSeconds = $elm$time$Time$posixToMillis(currentTime);
		var distance = A3($gingko$time_distance$Time$Distance$calculateDistance, true, otherSeconds, currentSeconds);
		var tense = function () {
			var _v0 = A2($elm$core$Basics$compare, currentSeconds, otherSeconds);
			if (_v0 === 2) {
				return 0;
			} else {
				return 1;
			}
		}();
		return A3(locale, config, tense, distance);
	});
var $gingko$time_distance$Time$Distance$inWords = F2(
	function (otherTime, currentTime) {
		return A4(
			$gingko$time_distance$Time$Distance$inWordsWithConfig,
			{jU: true},
			$gingko$time_distance$Time$Distance$I18n$en,
			otherTime,
			currentTime);
	});
var $author$project$Views$SubjectChoiceView$AssignmentCard$styledHeader = F3(
	function (profileImage, viewData, availableAssignment) {
		var styleHeaderTextContainer = $visotype$elm_dom$Dom$addStyleList(
			_List_fromArray(
				[
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('flex-direction', 'column'),
					_Utils_Tuple2('align-items', 'flex-start'),
					_Utils_Tuple2('padding-left', '10px'),
					_Utils_Tuple2('flex-grow', '0'),
					_Utils_Tuple2('margin', '10px 0px')
				]));
		var styleHeaderText = $visotype$elm_dom$Dom$addStyleList(
			_List_fromArray(
				[
					_Utils_Tuple2('font-family', 'Didact Gothic'),
					_Utils_Tuple2('font-style', 'normal'),
					_Utils_Tuple2('font-weight', '500'),
					_Utils_Tuple2('font-size', '14px'),
					_Utils_Tuple2('line-height', '14px'),
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('align-items', 'center'),
					_Utils_Tuple2(
					'color',
					$avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$white))
				]));
		var styleHeader = $visotype$elm_dom$Dom$addStyleList(
			_List_fromArray(
				[
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('flex-direction', 'row'),
					_Utils_Tuple2('align-items', 'center'),
					_Utils_Tuple2('padding', '0px 0px 10px'),
					_Utils_Tuple2('position', 'static'),
					_Utils_Tuple2('width', '375px'),
					_Utils_Tuple2('height', '43px'),
					_Utils_Tuple2('right', '10px'),
					_Utils_Tuple2('top', '10px'),
					_Utils_Tuple2('align-self', 'stretch'),
					_Utils_Tuple2('flex-grow', '0'),
					_Utils_Tuple2('margin', '0px 0px')
				]));
		var howLongAgo = A2($gingko$time_distance$Time$Distance$inWords, availableAssignment.gf, viewData.hw);
		var headerText = styleHeaderTextContainer(
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('margin', '0px'),
						styleHeaderText(
							A2(
								$visotype$elm_dom$Dom$appendText,
								availableAssignment.fr,
								$visotype$elm_dom$Dom$element('div')))),
						A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('margin', '5px 0px'),
						styleHeaderText(
							A2(
								$visotype$elm_dom$Dom$appendText,
								'Assigned ' + howLongAgo,
								$visotype$elm_dom$Dom$element('div'))))
					]),
				$visotype$elm_dom$Dom$element('div')));
		return styleHeader(
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[profileImage, headerText]),
				$visotype$elm_dom$Dom$element('div')));
	});
var $author$project$Assets$avatarDefault = require('/usr/src/app/app/images/avatar-default.svg');
var $author$project$Views$SubjectChoiceView$AssignmentCard$styledProfileImage = function (teacherProfileUrl) {
	var styleProfileContainer = $visotype$elm_dom$Dom$addStyleList(
		_List_fromArray(
			[
				_Utils_Tuple2('position', 'relative'),
				_Utils_Tuple2('width', '30px'),
				_Utils_Tuple2('height', '30px'),
				_Utils_Tuple2('left', 'calc(50% - 30px/2 - 172.5px)'),
				_Utils_Tuple2('top', 'calc(50% - 30px/2 - 5px)'),
				_Utils_Tuple2('border-radius', '75px'),
				_Utils_Tuple2('flex-grow', '0'),
				_Utils_Tuple2('margin', '10px 0px')
			]));
	var imagePath = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Assets$path($author$project$Assets$avatarDefault),
		teacherProfileUrl);
	var styleProfileImage = $visotype$elm_dom$Dom$addStyleList(
		_List_fromArray(
			[
				_Utils_Tuple2('background', 'url(\"' + (imagePath + '\")')),
				_Utils_Tuple2('background-repeat', 'no-repeat'),
				_Utils_Tuple2('background-size', 'cover'),
				_Utils_Tuple2('background-position', 'center'),
				_Utils_Tuple2('position', 'absolute'),
				_Utils_Tuple2('width', '30px'),
				_Utils_Tuple2('height', '30px'),
				_Utils_Tuple2('left', 'calc(50% - 30px/2)'),
				_Utils_Tuple2('top', 'calc(50% - 30px/2)'),
				_Utils_Tuple2('border-radius', '75px')
			]));
	return styleProfileContainer(
		A2(
			$visotype$elm_dom$Dom$appendChild,
			styleProfileImage(
				$visotype$elm_dom$Dom$element('div')),
			$visotype$elm_dom$Dom$element('div')));
};
var $author$project$Views$SubjectChoiceView$AssignmentCard$render = F2(
	function (viewData, availableAssignment) {
		var profileImage = $author$project$Views$SubjectChoiceView$AssignmentCard$styledProfileImage(availableAssignment.jt);
		var nextAction = $author$project$Messages$LoggedInRequiredMsg(
			A3($author$project$LoggedIn$Messages$SelectQuestToWorkOn, availableAssignment.a_, availableAssignment.bp, true));
		var header = A3($author$project$Views$SubjectChoiceView$AssignmentCard$styledHeader, profileImage, viewData, availableAssignment);
		return A2(
			$author$project$Dom$Extra$addClickAction,
			nextAction,
			$author$project$Views$SubjectChoiceView$StudentQuestCard$renderStudentQuestCard(
				{
					gw: availableAssignment.it.fx,
					gx: 0,
					hH: $elm$core$Maybe$Just(header),
					bp: availableAssignment.it.bp,
					c2: availableAssignment.c2
				}));
	});
var $author$project$Views$SubjectChoiceView$viewAssignmentQuests = F2(
	function (viewData, availableAssignments) {
		var viewCard = F2(
			function (index, availableAssignment) {
				var isLastCard = _Utils_eq(
					index,
					$elm$core$List$length(availableAssignments) - 1);
				return A2(
					$visotype$elm_dom$Dom$appendChild,
					A2($author$project$Views$SubjectChoiceView$AssignmentCard$render, viewData, availableAssignment),
					A3(
						$visotype$elm_dom$Dom$addStyleConditional,
						_Utils_Tuple2('margin-bottom', '40px'),
						!isLastCard,
						$visotype$elm_dom$Dom$element('div')));
			});
		return A2(
			$visotype$elm_dom$Dom$appendChildList,
			A2($elm$core$List$indexedMap, viewCard, availableAssignments),
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('display', 'flex'),
						_Utils_Tuple2('flex-direction', 'column'),
						_Utils_Tuple2('align-items', 'center')
					]),
				$author$project$Component$ResponsiveGrid$column(
					$visotype$elm_dom$Dom$element('div'))));
	});
var $author$project$Views$SubjectChoiceView$viewContent = F3(
	function (model, viewData, _v0) {
		var mathQuest = _v0.cB;
		var readingQuest = _v0.cS;
		var assignments = _v0.gh;
		var classLibraries = _v0.gF;
		var takeAssignmentsToShow = model.fb ? $elm$core$Basics$identity : $elm$core$List$take(1);
		var hasAnyAssignments = !$elm$core$List$isEmpty(assignments);
		var classLibrariesWithStories = A2(
			$elm$core$List$filter,
			function (library) {
				return library.ja > 0;
			},
			classLibraries);
		var assignmentsBySubject = {
			aS: A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.bp;
					},
					$elm$core$Basics$eq(0)),
				assignments),
			aW: A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.bp;
					},
					$elm$core$Basics$eq(1)),
				assignments)
		};
		var assignmentsBySubjectToShow = {
			aS: takeAssignmentsToShow(assignmentsBySubject.aS),
			aW: takeAssignmentsToShow(assignmentsBySubject.aW)
		};
		var countOfAssignmentsHidden = ($elm$core$List$length(assignmentsBySubject.aS) - $elm$core$List$length(assignmentsBySubjectToShow.aS)) + ($elm$core$List$length(assignmentsBySubject.aW) - $elm$core$List$length(assignmentsBySubjectToShow.aW));
		var showExpandAssignmentsButton = countOfAssignmentsHidden > 0;
		return A3(
			$visotype$elm_dom$Dom$appendChildConditional,
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A2($author$project$Views$SubjectChoiceView$classLibrarySection, classLibrariesWithStories, model.fc),
				$visotype$elm_dom$Dom$element('section')),
			A2($author$project$Models$ProductFeature$isEnabled, 10, viewData.iq) && ((!A2($author$project$Models$ProductFeature$isEnabled, 11, viewData.iq)) && ($elm$core$List$length(classLibrariesWithStories) > 0)),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A4($author$project$Views$SubjectChoiceView$myQuestsSection, viewData, readingQuest, mathQuest, hasAnyAssignments),
				A3(
					$visotype$elm_dom$Dom$appendChildConditional,
					A3(
						$visotype$elm_dom$Dom$appendChildConditional,
						A2(
							$visotype$elm_dom$Dom$appendNode,
							A2(
								$elm$html$Html$map,
								$author$project$Messages$SubjectChoiceViewMsg,
								$visotype$elm_dom$Dom$render(
									A3($author$project$Views$SubjectChoiceView$viewShowMoreButton, 0, 'from my teacher', countOfAssignmentsHidden))),
							A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('justify-content', 'flex-end'),
								A2(
									$visotype$elm_dom$Dom$addClassList,
									_List_fromArray(
										['is-flex']),
									$visotype$elm_dom$Dom$element('div')))),
						showExpandAssignmentsButton,
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[
									$author$project$Views$SubjectChoiceView$sectionHeader('From My Teacher'),
									A2(
									$visotype$elm_dom$Dom$appendChildList,
									A2(
										$elm$core$List$map,
										$author$project$Views$SubjectChoiceView$viewAssignmentQuests(viewData),
										_List_fromArray(
											[assignmentsBySubjectToShow.aW, assignmentsBySubjectToShow.aS])),
									A2(
										$visotype$elm_dom$Dom$addClassList,
										_List_fromArray(
											['columns', 'is-centered']),
										$visotype$elm_dom$Dom$element('div')))
								]),
							A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('margin-bottom', '120px'),
								$visotype$elm_dom$Dom$element('section')))),
					hasAnyAssignments,
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('max-width', '830px'),
								_Utils_Tuple2('margin-top', '55px')
							]),
						A2(
							$visotype$elm_dom$Dom$addClassList,
							_List_fromArray(
								['container', 'choose-subject-choice-quest-world']),
							A2(
								$author$project$Dom$Extra$addDataAttribute,
								_Utils_Tuple2('intercom-tour', 'subject-selection'),
								$visotype$elm_dom$Dom$element('div')))))));
	});
var $author$project$Assets$k2Background = require('/usr/src/app/app/images/backgrounds/k2-background.svg');
var $author$project$Assets$k2BackgroundAutumn = require('/usr/src/app/app/images/backgrounds/k2-autumn2019.svg');
var $author$project$Assets$k2BackgroundWinter = require('/usr/src/app/app/images/backgrounds/k2-winter2019.svg');
var $author$project$Assets$rockySubjectChoice = require('/usr/src/app/app/images/monsters/rocky/welcome.svg');
var $author$project$Assets$rockySubjectChoiceHalloween = require('/usr/src/app/app/images/monsters/rocky/welcome-halloween2019.svg');
var $author$project$Assets$rockySubjectChoiceWinter = require('/usr/src/app/app/images/monsters/rocky/welcome-winter2019.svg');
var $author$project$Views$SubjectChoiceView$rockyBackground = F2(
	function (seasonalTheme, content) {
		var assetInfo = function () {
			switch (seasonalTheme) {
				case 0:
					return {ds: $author$project$Assets$k2Background, bb: '#97ECFF', bl: $author$project$Assets$rockySubjectChoice};
				case 1:
					return {ds: $author$project$Assets$k2BackgroundAutumn, bb: '#B9ECF7', bl: $author$project$Assets$rockySubjectChoice};
				case 2:
					return {ds: $author$project$Assets$k2BackgroundAutumn, bb: '#B9ECF7', bl: $author$project$Assets$rockySubjectChoiceHalloween};
				default:
					return {ds: $author$project$Assets$k2BackgroundWinter, bb: '#B9ECF7', bl: $author$project$Assets$rockySubjectChoiceWinter};
			}
		}();
		return A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					content,
					A2(
					$visotype$elm_dom$Dom$appendChild,
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('position', 'absolute'),
								_Utils_Tuple2('left', '50%'),
								_Utils_Tuple2('transform', 'translate(-50%, 15%)'),
								_Utils_Tuple2('bottom', '-25px')
							]),
						A2(
							$visotype$elm_dom$Dom$addAttributeList,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									$author$project$Assets$path(assetInfo.bl)),
									$elm$html$Html$Attributes$alt('Rocky welcomes you!')
								]),
							$visotype$elm_dom$Dom$element('img'))),
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'background-image',
								'url(\"' + ($author$project$Assets$path(assetInfo.ds) + '\")')),
								_Utils_Tuple2('background-repeat', 'repeat-x'),
								_Utils_Tuple2('background-size', 'cover'),
								_Utils_Tuple2('min-height', '265px')
							]),
						$visotype$elm_dom$Dom$element('div')))
				]),
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('background', 'linear-gradient(#DBF9FF, 10%, ' + (assetInfo.bb + ')')),
						_Utils_Tuple2('position', 'relative'),
						_Utils_Tuple2('min-height', '100vh'),
						_Utils_Tuple2('position', 'relative'),
						_Utils_Tuple2('overflow', 'hidden'),
						_Utils_Tuple2('display', 'flex'),
						_Utils_Tuple2('flex-direction', 'column'),
						_Utils_Tuple2('justify-content', 'space-between')
					]),
				$visotype$elm_dom$Dom$element('div')));
	});
var $author$project$Views$SubjectChoiceView$withThemedBackground = F3(
	function (viewData, appFlowMode, seasonalTheme) {
		if (!appFlowMode.$) {
			return A2(
				$elm$core$Basics$composeR,
				$author$project$Views$SubjectChoiceView$rockyBackground(seasonalTheme),
				$visotype$elm_dom$Dom$addClass('choose-subject-sequential'));
		} else {
			return function (content) {
				return A2(
					$visotype$elm_dom$Dom$addClass,
					'choose-subject-choice-quest-world',
					$author$project$Styles$selectionScreenBackground(
						A2(
							$visotype$elm_dom$Dom$appendChild,
							A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('margin-bottom', '200px'),
								content),
							$visotype$elm_dom$Dom$element('div'))));
			};
		}
	});
var $author$project$Views$SubjectChoiceView$view = F3(
	function (model, loadedData, viewData) {
		return A4(
			$author$project$Views$SubjectChoiceView$withThemedBackground,
			viewData,
			viewData.f8,
			viewData.iQ,
			A3(
				$author$project$Dom$Extra$appendChildMaybe,
				A2($author$project$Views$Components$ScrollIndicator$view, $author$project$Messages$ScrollContainerTo, 0),
				viewData.jT,
				A2(
					$visotype$elm_dom$Dom$appendChild,
					A3(
						$krisajenkins$remotedata$RemoteData$unwrap,
						A2($author$project$Views$Components$LoadingIndicator$render, 'loading-subject-choice', 'Hold tight while we load subject areas!'),
						function (availableQuests) {
							return A2(
								$visotype$elm_dom$Dom$appendNode,
								$visotype$elm_dom$Dom$render(
									A3($author$project$Views$SubjectChoiceView$viewContent, model, viewData, availableQuests)),
								$visotype$elm_dom$Dom$element('div'));
						},
						loadedData),
					$visotype$elm_dom$Dom$element('div'))));
	});
var $author$project$Component$SpeechBubbleBanner$leftCustom = function (content) {
	return $author$project$Component$SpeechBubbleBanner$banner(
		{gH: content, ao: 0, bm: 0});
};
var $author$project$Assets$rockyLookBack = require('/usr/src/app/app/images/monsters/rocky-look-back.svg');
var $author$project$Assets$videoRecordingInstructions = require('/usr/src/app/app/images/cvp-record.png');
var $author$project$Views$VideoRecording$InstructionsView$view = F4(
	function (studentVideo, activeQuest, videoRecordingStatus, viewData) {
		var studentQuestId = activeQuest.a_;
		var quest = activeQuest.it;
		var platform = viewData.ii;
		var appFlowMode = viewData.f8;
		var startButtonAction = function () {
			if (!videoRecordingStatus.$) {
				return $author$project$Messages$NavigateTo(
					$author$project$Routes$VideoRoute(
						$author$project$Routes$VideoRoutes$Recording(studentVideo.a_)));
			} else {
				return $author$project$Messages$NoOp;
			}
		}();
		var previousButtonAction = function () {
			if (!appFlowMode.$) {
				return $author$project$Messages$NavigateTo(
					$author$project$Routes$VideoRoute(
						$author$project$Routes$VideoRoutes$RecordingIntro(studentQuestId)));
			} else {
				return $author$project$Messages$NavigateTo(
					$author$project$Routes$QuestRoute(
						$author$project$Routes$QuestRoutes$Home(studentQuestId)));
			}
		}();
		var previousButton = A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('left', '20px'),
			$author$project$Styles$activityButton(
				A2(
					$visotype$elm_dom$Dom$addClass,
					'back-button',
					$author$project$Views$Components$Button$WithGlobalMsg$previous(previousButtonAction))));
		var monsterStyling = function (element) {
			return A2(
				$author$project$Styles$onTop,
				200,
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('left', '40%'),
							_Utils_Tuple2('bottom', '-18%')
						]),
					$author$project$Styles$monsterOnPath(element)));
		};
		var instructionImageBackground = function () {
			if (!appFlowMode.$) {
				return 'has-background-yellow';
			} else {
				return 'has-background-orange-pale';
			}
		}();
		var continueLinkDestination = $author$project$Routes$QuestRoute(
			activeQuest.es ? $author$project$Routes$QuestRoutes$Home(studentQuestId) : $author$project$Routes$QuestRoutes$Outro(studentQuestId));
		var questCelebrationLink = A2(
			$author$project$Component$Link$to,
			continueLinkDestination,
			_List_fromArray(
				[
					A2(
					$visotype$elm_dom$Dom$appendText,
					'Continue',
					$visotype$elm_dom$Dom$element('span'))
				]));
		var _v0 = quest;
		var videoPromptText = _v0.jL;
		var videoPromptAudioOverride = _v0.jK;
		var fontSizePx = ($elm$core$String$length(videoPromptText) < 170) ? 40 : (($elm$core$String$length(videoPromptText) < 250) ? 32 : 22);
		var instructionText = A2(
			$author$project$Dom$Extra$addDataCy,
			'video-recording-instructions-text',
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('width', 'calc(100vw - 200px)'),
						_Utils_Tuple2('flex-grow', '1')
					]),
				A2(
					$visotype$elm_dom$Dom$appendChildList,
					_List_fromArray(
						[
							A2(
							$author$project$Dom$Extra$addDataCy,
							'video-prompt',
							A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('margin-bottom', '20px'),
								A2(
									$visotype$elm_dom$Dom$appendChild,
									A2(
										$visotype$elm_dom$Dom$addClass,
										'has-text-rocky',
										A2(
											$author$project$Component$Text$inPixels,
											fontSizePx,
											A2(
												$visotype$elm_dom$Dom$appendChild,
												A3(
													$visotype$elm_dom$Dom$addAttributeConditional,
													A2($elm$html$Html$Attributes$attribute, 'alias', videoPromptAudioOverride),
													!$elm$core$String$isEmpty(videoPromptAudioOverride),
													A4($author$project$TextToSpeech$WithGlobalMsg$speakable, viewData, viewData.gj, videoPromptText, $author$project$Models$Voice$default)),
												$visotype$elm_dom$Dom$element('span')))),
									$visotype$elm_dom$Dom$element('div')))),
							A2(
							$visotype$elm_dom$Dom$appendChild,
							A2(
								$visotype$elm_dom$Dom$addClass,
								'has-text-rocky',
								A2(
									$author$project$Component$Text$inPixels,
									fontSizePx,
									A2(
										$visotype$elm_dom$Dom$appendChild,
										A4($author$project$TextToSpeech$WithGlobalMsg$speakable, viewData, viewData.gj, 'Ready to record your answer?', $author$project$Models$Voice$default),
										$visotype$elm_dom$Dom$element('span')))),
							$visotype$elm_dom$Dom$element('div'))
						]),
					$visotype$elm_dom$Dom$element('div'))));
		var videoInstructions = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('flex-direction', 'row'),
					_Utils_Tuple2('max-height', '460px')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						instructionText,
						A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('width', '200px'),
								_Utils_Tuple2('margin-left', '30px'),
								_Utils_Tuple2('display', 'flex'),
								_Utils_Tuple2('align-self', 'stretch'),
								_Utils_Tuple2('justify-content', 'center'),
								_Utils_Tuple2('align-items', 'center')
							]),
						A2(
							$visotype$elm_dom$Dom$addClass,
							instructionImageBackground,
							A2(
								$visotype$elm_dom$Dom$appendChild,
								A2(
									$visotype$elm_dom$Dom$addStyleList,
									_List_fromArray(
										[
											_Utils_Tuple2('width', '160px'),
											_Utils_Tuple2('height', '140px'),
											_Utils_Tuple2('margin-top', '30px'),
											_Utils_Tuple2('padding', '20px'),
											_Utils_Tuple2('display', 'flex')
										]),
									$author$project$Component$Image$render(
										{
											f5: '',
											hb: 140,
											ig: $author$project$Assets$path($author$project$Assets$videoRecordingInstructions),
											jS: 160
										})),
								$visotype$elm_dom$Dom$element('div'))))
					]),
				$visotype$elm_dom$Dom$element('div')));
		var content = function () {
			var instructions = function () {
				if (!appFlowMode.$) {
					return A2(
						$author$project$Views$RockySpeaks$renderWithCustomBanner,
						viewData,
						{
							dt: A2(
								$visotype$elm_dom$Dom$addStyleList,
								_List_fromArray(
									[
										_Utils_Tuple2('position', 'relative'),
										_Utils_Tuple2('z-index', '250')
									]),
								$author$project$Component$SpeechBubbleBanner$leftCustom(videoInstructions)),
							gE: 'video-recording-instructions',
							hN: monsterStyling,
							hU: startButtonAction,
							iK: $author$project$Assets$rockyLookBack
						});
				} else {
					return A4(
						$author$project$Component$HigherGradeInstructions$render,
						'Create a video',
						videoInstructions,
						$elm$core$Maybe$Just(startButtonAction),
						viewData);
				}
			}();
			return A2($author$project$Dom$Extra$addDataCy, 'video-recording-instructions', instructions);
		}();
		return studentVideo.aM ? A2(
			$visotype$elm_dom$Dom$addClass,
			'recording-complete',
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						A2(
						$visotype$elm_dom$Dom$appendText,
						'Congrats on finishing your video!',
						$visotype$elm_dom$Dom$element('span')),
						questCelebrationLink,
						previousButton,
						A3($author$project$Component$Lightbulbs$render, activeQuest, $author$project$Component$Lightbulbs$VideoChallengeLightbulb, viewData)
					]),
				$visotype$elm_dom$Dom$element('div'))) : A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['video-recording']),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						content,
						previousButton,
						A3($author$project$Component$Lightbulbs$render, activeQuest, $author$project$Component$Lightbulbs$VideoChallengeLightbulb, viewData)
					]),
				$visotype$elm_dom$Dom$element('div')));
	});
var $author$project$AppFlow$LastNonQuizActivity = {$: 3};
var $author$project$Views$VideoRecording$IntroView$view = F2(
	function (activeQuest, viewData) {
		var studentQuestId = activeQuest.a_;
		var quest = activeQuest.it;
		var previousButton = A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('left', '20px'),
			$author$project$Styles$activityButton(
				A2(
					$visotype$elm_dom$Dom$addClass,
					'back-button',
					$author$project$Views$Components$Button$WithGlobalMsg$previous(
						$author$project$Messages$NavigateToActivity($author$project$AppFlow$LastNonQuizActivity)))));
		var monsterStyling = function (element) {
			return A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('left', '35%'),
						_Utils_Tuple2('bottom', '20%')
					]),
				$author$project$Styles$monsterOnPath(element));
		};
		var bannerText = 'Great job! Let\'s make a video about ' + ($elm$core$String$toLower(quest.i0) + '.');
		return A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					A2(
					$author$project$Views$RockySpeaks$render,
					viewData,
					{
						gr: bannerText,
						gE: 'video-recording-intro',
						hN: monsterStyling,
						hU: $author$project$Messages$NavigateTo(
							$author$project$Routes$VideoRoute(
								$author$project$Routes$VideoRoutes$Instructions(studentQuestId))),
						iK: $author$project$Assets$rockyStandingForward
					}),
					previousButton,
					A3($author$project$Component$Lightbulbs$render, activeQuest, $author$project$Component$Lightbulbs$VideoChallengeLightbulb, viewData)
				]),
			$visotype$elm_dom$Dom$element('div'));
	});
var $author$project$Models$VideoPlayer$BrowserNative = 0;
var $author$project$LoggedIn$Messages$CameraNotAvailableSkipStudentVideo = {$: 73};
var $author$project$Models$VideoPlayer$Ended = function (a) {
	return {$: 4, a: a};
};
var $author$project$VideoRecording$RecordingAction$FlipCamera = 2;
var $author$project$VideoRecording$RecordingProcess$MoveOn = 1;
var $author$project$VideoRecording$RecordingProcess$NotApplicable = 3;
var $author$project$Messages$PlayRecordedVideo = function (a) {
	return {$: 47, a: a};
};
var $author$project$Models$VideoPlayer$Playing = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Animations$CssAnimations$PulsateInfinitelyCentered = 6;
var $author$project$LoggedIn$Messages$ReplayRecordedStudentVideo = function (a) {
	return {$: 71, a: a};
};
var $author$project$VideoRecording$RecordingAction$Stop = 1;
var $author$project$Models$Modal$VideoDeletionConfirmationModal = {$: 4};
var $author$project$Models$Modal$VideoRecordingConfirmationModal = {$: 3};
var $author$project$LoggedIn$Messages$VideoRecordingPerformAction = function (a) {
	return {$: 59, a: a};
};
var $author$project$LoggedIn$Messages$VideoRecordingPlaybackChange = function (a) {
	return {$: 61, a: a};
};
var $author$project$LoggedIn$Messages$VideoRecordingPlaybackReady = {$: 62};
var $author$project$LoggedIn$Messages$VideoRecordingRetry = {$: 60};
var $author$project$LoggedIn$Messages$VideoRecordingStart = {$: 58};
var $author$project$LoggedIn$Messages$WebVideoRecordingErrored = function (a) {
	return {$: 75, a: a};
};
var $author$project$Assets$appWorking = require('/usr/src/app/app/images/processing-animation.gif');
var $author$project$Styles$centerAbsolute = function (zIndex) {
	return $visotype$elm_dom$Dom$addStyleList(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'z-index',
				$elm$core$String$fromInt(zIndex)),
				_Utils_Tuple2('position', 'absolute'),
				_Utils_Tuple2('top', '50%'),
				_Utils_Tuple2('left', '50%'),
				_Utils_Tuple2('transform', 'translate(-50%, -50%)')
			]));
};
var $author$project$VideoRecording$RecordingProcess$initialRecordingCounts = {gi: 0, gO: 0, cN: 0};
var $author$project$Models$LocalStorage$VideoRecordingCounts$defaultEntry = $author$project$VideoRecording$RecordingProcess$initialRecordingCounts;
var $author$project$Models$LocalStorage$VideoRecordingCounts$get = F2(
	function (key, history) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Models$LocalStorage$VideoRecordingCounts$defaultEntry,
			A2(
				$elm$core$Dict$get,
				$author$project$Models$Ids$toString(key),
				history));
	});
var $author$project$Models$LocalStorage$Helpers$getVideoRecordingCounts = F2(
	function (store, key) {
		return A2($author$project$Models$LocalStorage$VideoRecordingCounts$get, key, store.c9);
	});
var $elm$html$Html$Attributes$loop = $elm$html$Html$Attributes$boolProperty('loop');
var $author$project$VideoRecording$Helpers$maxRecordingLength = (2 * 60) * 1000;
var $author$project$VideoRecording$RecordingProcess$permissionsRequired = function (recordingProcess) {
	if (recordingProcess.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $elm$html$Html$Attributes$preload = $elm$html$Html$Attributes$stringProperty('preload');
var $elm$html$Html$Attributes$property = $elm$virtual_dom$VirtualDom$property;
var $author$project$AppConfiguration$isIos = function (platform) {
	if (platform.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$VideoRecording$RecordingProcess$readyForReview = F2(
	function (recordingProcess, platform) {
		if (recordingProcess.$ === 5) {
			var playbackReady = recordingProcess.a.bQ;
			return playbackReady || $author$project$AppConfiguration$isIos(platform);
		} else {
			return false;
		}
	});
var $author$project$VideoRecording$Helpers$recordingProgressValue = function (duration) {
	return (duration <= 0) ? 0.001 : A3($elm$core$Basics$clamp, duration, $author$project$VideoRecording$Helpers$maxRecordingLength - 0.1, duration);
};
var $author$project$ES$UI$Color$red = {
	at: A3($avh4$elm_color$Color$rgb255, 225, 71, 71),
	au: A3($avh4$elm_color$Color$rgb255, 180, 56, 56),
	ay: A3($avh4$elm_color$Color$rgb255, 255, 104, 104),
	az: A3($avh4$elm_color$Color$rgb255, 255, 164, 164),
	aA: A3($avh4$elm_color$Color$rgb255, 255, 215, 215)
};
var $author$project$Views$Components$Button$WithGlobalMsg$reload = function (message) {
	return A2(
		$author$project$Dom$Extra$addClickAction,
		message,
		A2(
			$author$project$Component$Buttons$Action$button,
			0,
			_Utils_Tuple3(
				0,
				0,
				A2($author$project$Component$Icon$Icon, 14, $author$project$Component$Icon$XLarge))));
};
var $author$project$Models$Modal$VideoRecordingTerminatedModal = {$: 5};
var $author$project$Component$Modal$card = function (_v0) {
	var head = _v0.ha;
	var body = _v0.gs;
	var foot = _v0.g6;
	var modalHead = A2(
		$visotype$elm_dom$Dom$addClass,
		'modal-card-head',
		A2(
			$visotype$elm_dom$Dom$appendChildList,
			head,
			$visotype$elm_dom$Dom$element('div')));
	var modalFoot = A2(
		$visotype$elm_dom$Dom$addStyle,
		_Utils_Tuple2('justify-content', 'space-between'),
		A2(
			$visotype$elm_dom$Dom$addClass,
			'modal-card-foot',
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				foot,
				$visotype$elm_dom$Dom$element('div'))));
	var modalBody = A2(
		$visotype$elm_dom$Dom$addStyle,
		_Utils_Tuple2('position', 'relative'),
		A2(
			$visotype$elm_dom$Dom$addClass,
			'modal-card-body',
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				body,
				$visotype$elm_dom$Dom$element('div'))));
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[modalHead, modalBody, modalFoot]),
		A2(
			$visotype$elm_dom$Dom$addClass,
			'modal-card',
			$visotype$elm_dom$Dom$element('div')));
};
var $elm_community$maybe_extra$Maybe$Extra$foldrValues = F2(
	function (item, list) {
		if (item.$ === 1) {
			return list;
		} else {
			var v = item.a;
			return A2($elm$core$List$cons, v, list);
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$values = A2($elm$core$List$foldr, $elm_community$maybe_extra$Maybe$Extra$foldrValues, _List_Nil);
var $author$project$Component$DialogModal$render = function (_v0) {
	var title = _v0.fx;
	var content = _v0.gH;
	var confirmButton = _v0.dH;
	var cancelButton = _v0.dx;
	return $author$project$Styles$sizeFill(
		$author$project$Component$Modal$card(
			{
				gs: _List_fromArray(
					[content]),
				g6: $elm_community$maybe_extra$Maybe$Extra$values(
					_List_fromArray(
						[confirmButton, cancelButton])),
				ha: _List_fromArray(
					[title])
			}));
};
var $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingNotifyTerminatedConfirmation = F2(
	function (isLoading, message) {
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'confirm-notify-student-video-terminated',
			A2(
				$author$project$Dom$AddClickOnceAction$addClickOnceAction,
				message,
				A2(
					$author$project$Component$Buttons$Contained$button,
					$author$project$Component$Buttons$Button$loadingStateFromDisabledState(isLoading),
					{gG: 2, hi: 1, hI: $elm$core$Maybe$Nothing, ju: 'Ok'})));
	});
var $author$project$Views$VideoRecording$RecordingTerminatedModal$render = function (_v0) {
	var modalState = _v0.hM;
	var buttonsDisabled = _v0.gv;
	var content = $author$project$Component$DialogModal$render(
		{
			dx: $elm$core$Maybe$Nothing,
			dH: $elm$core$Maybe$Just(
				A2($author$project$Views$Components$Button$WithGlobalMsg$videoRecordingNotifyTerminatedConfirmation, buttonsDisabled, $author$project$Messages$CloseModal)),
			gH: $visotype$elm_dom$Dom$element('div'),
			fx: A2(
				$author$project$Component$Text$withSize,
				1,
				A2(
					$author$project$Component$Text$colored,
					$author$project$ES$UI$Color$grey.j1,
					A2(
						$visotype$elm_dom$Dom$appendText,
						'Your video has reached the 2 minute limit!',
						$visotype$elm_dom$Dom$element('div'))))
		});
	return A2(
		$author$project$Dom$Extra$addDataCy,
		'video-recording-terminated-modal',
		A3(
			$author$project$Component$Modal$wrapper,
			$author$project$Models$Modal$VideoRecordingTerminatedModal,
			modalState,
			{
				gH: content,
				hb: 500,
				h5: $elm$core$Maybe$Just($author$project$Messages$CloseModal),
				jS: 675
			}));
};
var $author$project$LoggedIn$Messages$StudentVideoRecordingDeleted = function (a) {
	return {$: 67, a: a};
};
var $author$project$Component$Buttons$Outlined$addOptionalIcon = function (maybeIcon) {
	if (!maybeIcon.$) {
		var icon = maybeIcon.a;
		return $visotype$elm_dom$Dom$appendChild(
			$author$project$Component$Icon$render(icon));
	} else {
		return $elm$core$Basics$identity;
	}
};
var $author$project$Component$Buttons$Outlined$build = F2(
	function (loadingState, _v0) {
		var color = _v0.a;
		var text = _v0.b;
		var maybeIcon = _v0.c;
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Component$Buttons$Button$applyBase,
			A2(
				$elm$core$Basics$composeR,
				$visotype$elm_dom$Dom$addClass('es-outlined-button'),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Component$Buttons$Button$addColorClass(color),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Component$Buttons$Button$addLoadingClass(loadingState),
						A2(
							$elm$core$Basics$composeR,
							$visotype$elm_dom$Dom$appendChildList($author$project$Component$Buttons$Button$loadingLayers),
							A2(
								$elm$core$Basics$composeR,
								$visotype$elm_dom$Dom$appendText(text),
								$author$project$Component$Buttons$Outlined$addOptionalIcon(maybeIcon)))))));
	});
var $author$project$Component$Buttons$Outlined$button = F2(
	function (loadingState, details) {
		return A3(
			$author$project$Component$Buttons$Outlined$build,
			loadingState,
			details,
			$visotype$elm_dom$Dom$element('button'));
	});
var $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingDeleteCancelation = F2(
	function (isLoading, message) {
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'cancel-delete-student-video',
			A2(
				$author$project$Dom$AddClickOnceAction$addClickOnceAction,
				message,
				A2(
					$author$project$Component$Buttons$Outlined$button,
					$author$project$Component$Buttons$Button$loadingStateFromDisabledState(isLoading),
					_Utils_Tuple3(8, 'Cancel', $elm$core$Maybe$Nothing))));
	});
var $author$project$Component$Icon$Trash = 36;
var $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingDeleteConfirmation = F2(
	function (isLoading, message) {
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'delete-student-video',
			A2(
				$author$project$Dom$AddClickOnceAction$addClickOnceAction,
				message,
				A2(
					$author$project$Component$Buttons$Contained$button,
					$author$project$Component$Buttons$Button$loadingStateFromDisabledState(isLoading),
					{
						gG: 8,
						hi: 1,
						hI: $elm$core$Maybe$Just(
							A2($author$project$Component$Icon$Icon, 36, $author$project$Component$Icon$Default)),
						ju: 'Delete'
					})));
	});
var $author$project$Views$VideoRecording$VideoDeletionConfirmationModal$render = F2(
	function (studentQuestId, viewData) {
		var modalState = viewData.hM;
		var buttonsDisabled = viewData.gv;
		var messageColor = $author$project$ES$UI$Color$grey.j1;
		var message = A2(
			$author$project$Component$Text$withSize,
			2,
			A2(
				$author$project$Component$Text$colored,
				messageColor,
				A2(
					$visotype$elm_dom$Dom$appendChild,
					A4($author$project$TextToSpeech$WithGlobalMsg$speakable, viewData, true, 'Do you want to delete this video?', $author$project$Models$Voice$default),
					$visotype$elm_dom$Dom$element('div'))));
		var content = $author$project$Component$DialogModal$render(
			{
				dx: $elm$core$Maybe$Just(
					A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('width', 'calc(50% - 15px)'),
						A2($author$project$Views$Components$Button$WithGlobalMsg$videoRecordingDeleteCancelation, buttonsDisabled, $author$project$Messages$CloseModal))),
				dH: $elm$core$Maybe$Just(
					A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('width', 'calc(50% - 15px)'),
						A2(
							$author$project$Views$Components$Button$WithGlobalMsg$videoRecordingDeleteConfirmation,
							buttonsDisabled,
							$author$project$Messages$LoggedInRequiredMsg(
								$author$project$LoggedIn$Messages$StudentVideoRecordingDeleted(studentQuestId))))),
				gH: A2(
					$visotype$elm_dom$Dom$appendChild,
					message,
					$visotype$elm_dom$Dom$element('div')),
				fx: A2(
					$author$project$Component$Text$withSize,
					1,
					A2(
						$author$project$Component$Text$colored,
						messageColor,
						A2(
							$visotype$elm_dom$Dom$appendChild,
							A4($author$project$TextToSpeech$WithGlobalMsg$speakable, viewData, true, 'Are you sure?', $author$project$Models$Voice$default),
							$visotype$elm_dom$Dom$element('div'))))
			});
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'video-recording-confirm-delete-modal',
			A3(
				$author$project$Component$Modal$wrapper,
				$author$project$Models$Modal$VideoDeletionConfirmationModal,
				modalState,
				{
					gH: content,
					hb: 500,
					h5: $elm$core$Maybe$Just($author$project$Messages$CloseModal),
					jS: 675
				}));
	});
var $author$project$LoggedIn$Messages$WebCompleteStudentVideo = {$: 70};
var $author$project$Component$Icon$ThumbsUpCuff = 35;
var $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingApproveConfirmation = F2(
	function (isLoading, message) {
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'submit-student-video',
			A2(
				$author$project$Dom$AddClickOnceAction$addClickOnceAction,
				message,
				A2(
					$author$project$Component$Buttons$Contained$button,
					$author$project$Component$Buttons$Button$loadingStateFromDisabledState(isLoading),
					{
						gG: 2,
						hi: 1,
						hI: $elm$core$Maybe$Just(
							A2($author$project$Component$Icon$Icon, 35, $author$project$Component$Icon$Default)),
						ju: 'Ok'
					})));
	});
var $author$project$Views$VideoRecording$VideoRecordingConfirmationModal$render = function (viewData) {
	var appFlowMode = viewData.f8;
	var modalState = viewData.hM;
	var buttonsDisabled = viewData.gv;
	var successMonsterName = 3;
	var successMonster = function () {
		if (appFlowMode.$ === 1) {
			return $visotype$elm_dom$Dom$element('div');
		} else {
			var monster = $author$project$Component$Monster$build(
				{gR: 1, hD: 'up', cE: successMonsterName, ic: 0});
			return A2(
				$visotype$elm_dom$Dom$addAttributeList,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'img'),
						A2($elm$html$Html$Attributes$attribute, 'aria-label', 'monster friend'),
						$elm$html$Html$Attributes$width(300),
						$elm$html$Html$Attributes$height(120)
					]),
				A2(
					$author$project$Dom$Extra$addDataCy,
					'video-recording-success-modal-monster',
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('position', 'absolute'),
								_Utils_Tuple2('bottom', '0'),
								_Utils_Tuple2('right', '0')
							]),
						$author$project$Component$Monster$render(monster))));
		}
	}();
	var messageColor = function () {
		if (!appFlowMode.$) {
			return $author$project$ES$UI$Color$green.au;
		} else {
			return $author$project$ES$UI$Color$grey.j1;
		}
	}();
	var message = A2(
		$author$project$Component$Text$withSize,
		2,
		A2(
			$author$project$Component$Text$colored,
			messageColor,
			A2(
				$visotype$elm_dom$Dom$appendChild,
				A4($author$project$TextToSpeech$WithGlobalMsg$speakable, viewData, true, 'Ready for the next challenge?', $author$project$Models$Voice$default),
				$visotype$elm_dom$Dom$element('div'))));
	var content = $author$project$Component$DialogModal$render(
		{
			dx: $elm$core$Maybe$Nothing,
			dH: $elm$core$Maybe$Just(
				A2(
					$author$project$Views$Components$Button$WithGlobalMsg$videoRecordingApproveConfirmation,
					buttonsDisabled,
					$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$WebCompleteStudentVideo))),
			gH: A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[message, successMonster]),
				$visotype$elm_dom$Dom$element('div')),
			fx: A2(
				$author$project$Component$Text$withSize,
				1,
				A2(
					$author$project$Component$Text$colored,
					messageColor,
					A2(
						$visotype$elm_dom$Dom$appendChild,
						A4(
							$author$project$TextToSpeech$WithGlobalMsg$speakable,
							viewData,
							true,
							'Nice Job!',
							$author$project$Component$Monster$voice(successMonsterName)),
						$visotype$elm_dom$Dom$element('div'))))
		});
	return A2(
		$author$project$Dom$Extra$addDataCy,
		'video-recording-success-modal',
		A3(
			$author$project$Component$Modal$wrapper,
			$author$project$Models$Modal$VideoRecordingConfirmationModal,
			modalState,
			{
				gH: content,
				hb: 500,
				h5: $elm$core$Maybe$Just($author$project$Messages$CloseModal),
				jS: 675
			}));
};
var $author$project$VideoRecording$RecordingProcess$CanRetry = 0;
var $author$project$VideoRecording$RecordingProcess$UnknownError = 2;
var $author$project$VideoRecording$RecordingProcess$numberOfErroneousAttempts = function (recordingCounts) {
	return recordingCounts.gi - recordingCounts.gO;
};
var $author$project$VideoRecording$RecordingProcess$retryStatus = F2(
	function (process, counts) {
		var _v0 = _Utils_Tuple2(
			process,
			$author$project$VideoRecording$RecordingProcess$numberOfErroneousAttempts(counts));
		_v0$2:
		while (true) {
			switch (_v0.a.$) {
				case 2:
					if (!_v0.a.b.$) {
						switch (_v0.b) {
							case 1:
								var _v1 = _v0.a;
								return 0;
							case 2:
								var _v2 = _v0.a;
								return 1;
							default:
								break _v0$2;
						}
					} else {
						break _v0$2;
					}
				case 1:
					var _v4 = _v0.a;
					return (counts.cN > 0) ? 1 : 3;
				default:
					return 3;
			}
		}
		var _v3 = _v0.a;
		return 2;
	});
var $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingDelete = function (message) {
	return A2(
		$author$project$Dom$Extra$addClickAction,
		message,
		A2(
			$author$project$Component$Buttons$Action$button,
			0,
			_Utils_Tuple3(
				0,
				8,
				A2($author$project$Component$Icon$Icon, 36, $author$project$Component$Icon$Large))));
};
var $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingFinish = function (message) {
	return A2(
		$author$project$Animations$CssAnimations$applyWithAnimationPreset,
		6,
		A2(
			$author$project$Dom$Extra$addClickAction,
			message,
			A2(
				$author$project$Component$Buttons$Action$button,
				0,
				_Utils_Tuple3(
					0,
					0,
					A2($author$project$Component$Icon$Icon, 5, $author$project$Component$Icon$Large)))));
};
var $author$project$Component$Icon$RotateCamera = 13;
var $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingFlipCamera = function (message) {
	return A2(
		$author$project$Dom$Extra$addDataCy,
		'video-record-rotate-camera',
		A2(
			$author$project$Dom$Extra$addClickAction,
			message,
			A2(
				$author$project$Component$Buttons$Action$button,
				0,
				_Utils_Tuple3(
					0,
					4,
					A2($author$project$Component$Icon$Icon, 13, $author$project$Component$Icon$Large)))));
};
var $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingPlayback = function (message) {
	return A2(
		$author$project$Dom$Extra$addDataCy,
		'video-record-playback-button',
		A2(
			$author$project$Dom$Extra$addClickAction,
			message,
			A2(
				$author$project$Component$Buttons$Action$button,
				0,
				_Utils_Tuple3(
					1,
					4,
					A2($author$project$Component$Icon$Icon, 14, $author$project$Component$Icon$XX_Large)))));
};
var $author$project$Component$Icon$Video = 38;
var $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingStart = function (message) {
	return A2(
		$author$project$Dom$Extra$addClickAction,
		message,
		A2(
			$author$project$Component$Buttons$Action$button,
			0,
			_Utils_Tuple3(
				1,
				0,
				A2($author$project$Component$Icon$Icon, 38, $author$project$Component$Icon$XX_Large))));
};
var $author$project$Component$Icon$Stop = 47;
var $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingStop = function (message) {
	return A2(
		$author$project$Dom$Extra$addDataCy,
		'stop-video-recording-button',
		A2(
			$author$project$Dom$Extra$addClickAction,
			message,
			A2(
				$author$project$Component$Buttons$Action$button,
				0,
				_Utils_Tuple3(
					0,
					0,
					A2($author$project$Component$Icon$Icon, 47, $author$project$Component$Icon$Large)))));
};
var $author$project$LoggedIn$Messages$IosChromePermissionReload = {$: 74};
var $author$project$LoggedIn$Messages$StudentVideoRecordingReloadForPermissions = {$: 72};
var $author$project$Component$Icon$FastForward = 49;
var $author$project$Component$Buttons$Button$Orange = 6;
var $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingChromePermissionSkip = function (message) {
	return A2(
		$author$project$Dom$AddClickOnceAction$addClickOnceAction,
		message,
		A2(
			$author$project$Component$Buttons$Contained$button,
			$author$project$Component$Buttons$Button$loadingStateFromDisabledState(false),
			{
				gG: 6,
				hi: 1,
				hI: $elm$core$Maybe$Just(
					A2($author$project$Component$Icon$Icon, 49, $author$project$Component$Icon$Default)),
				ju: 'Skip'
			}));
};
var $author$project$Views$VideoRecording$PermissionsRequiredView$nextButton = $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingChromePermissionSkip(
	$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$CameraNotAvailableSkipStudentVideo));
var $author$project$Component$Icon$Lock = 21;
var $author$project$Views$VideoRecording$PermissionsRequiredView$permissionIndicator = A2(
	$visotype$elm_dom$Dom$appendChildList,
	_List_fromArray(
		[
			A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('margin-top', '30px'),
			$author$project$Component$Icon$render(
				A2($author$project$Component$Icon$Icon, 2, $author$project$Component$Icon$XX))),
			A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('margin-top', '20px'),
			$author$project$Component$Icon$render(
				A2($author$project$Component$Icon$Icon, 21, $author$project$Component$Icon$XX)))
		]),
	A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('width', '224px'),
				_Utils_Tuple2('height', '224px'),
				_Utils_Tuple2('position', 'absolute'),
				_Utils_Tuple2('top', '-79px'),
				_Utils_Tuple2('left', '5px'),
				_Utils_Tuple2('background-color', 'white'),
				_Utils_Tuple2('border-radius', '112px'),
				_Utils_Tuple2('display', 'flex'),
				_Utils_Tuple2('flex-direction', 'column'),
				_Utils_Tuple2('align-items', 'center'),
				_Utils_Tuple2('justify-content', 'center'),
				_Utils_Tuple2('color', 'black')
			]),
		$visotype$elm_dom$Dom$element('div')));
var $author$project$Views$Components$Button$WithGlobalMsg$buildQuizAudio = F3(
	function (iconGlyph, isLoading, message) {
		return A2(
			$author$project$Dom$Extra$addClickAction,
			message,
			A2(
				$author$project$Component$Buttons$Action$button,
				$author$project$Component$Buttons$Button$loadingStateFromDisabledState(isLoading),
				_Utils_Tuple3(
					0,
					4,
					A2($author$project$Component$Icon$Icon, iconGlyph, $author$project$Component$Icon$Default))));
	});
var $author$project$Views$Components$Button$WithGlobalMsg$quizPassageAudioPlay = F2(
	function (isLoading, message) {
		return A2(
			$author$project$Dom$Extra$addDataCy,
			'audio-play-button',
			A3($author$project$Views$Components$Button$WithGlobalMsg$buildQuizAudio, 17, isLoading, message));
	});
var $author$project$Assets$rockyLookingUp = require('/usr/src/app/app/images/monsters/rocky/looking-up.svg');
var $author$project$Views$VideoRecording$PermissionsRequiredView$rockyLookingUp = A2(
	$visotype$elm_dom$Dom$addStyleList,
	_List_fromArray(
		[
			_Utils_Tuple2('position', 'absolute'),
			_Utils_Tuple2('left', '0px'),
			_Utils_Tuple2('bottom', '0')
		]),
	$author$project$Component$Image$render(
		{
			f5: 'Rocky looking up',
			hb: 282,
			ig: $author$project$Assets$path($author$project$Assets$rockyLookingUp),
			jS: 160
		}));
var $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingChromePermissionDone = function (message) {
	return A2(
		$author$project$Dom$AddClickOnceAction$addClickOnceAction,
		message,
		A2(
			$author$project$Component$Buttons$Contained$button,
			$author$project$Component$Buttons$Button$loadingStateFromDisabledState(false),
			{
				gG: 2,
				hi: 1,
				hI: $elm$core$Maybe$Just(
					A2($author$project$Component$Icon$Icon, 8, $author$project$Component$Icon$Default)),
				ju: 'Done'
			}));
};
var $author$project$Assets$videoRecordingPermissionsChrome = require('/usr/src/app/app/images/video-recording-permissions-chrome.gif');
var $author$project$Assets$videoRecordingPermissionsIosChrome = require('/usr/src/app/app/images/iOS-Chrome-permisssions.gif');
var $author$project$Assets$videoRecordingPermissionsSafari = require('/usr/src/app/app/images/video-recording-permissions-safari.svg');
var $author$project$Views$VideoRecording$PermissionsRequiredView$withTextToSpeech = F3(
	function (viewData, elementType, text) {
		return A2(
			$visotype$elm_dom$Dom$appendChild,
			A4($author$project$TextToSpeech$WithGlobalMsg$speakable, viewData, viewData.gj, text, $author$project$Models$Voice$default),
			$visotype$elm_dom$Dom$element(elementType));
	});
var $author$project$Views$VideoRecording$PermissionsRequiredView$errorDisplayWhenRecordingSupported = function (viewData) {
	var titleWithAudio = F2(
		function (text1, text2) {
			return A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						A2(
						$visotype$elm_dom$Dom$appendChild,
						A2(
							$author$project$Views$Components$Button$WithGlobalMsg$quizPassageAudioPlay,
							false,
							$author$project$Messages$PlayAllAutoplaySpeech(
								$elm$core$Maybe$Just('.auto-tts'))),
						A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2('width', 'auto'),
									_Utils_Tuple2('float', 'left'),
									_Utils_Tuple2('margin-right', '15px'),
									_Utils_Tuple2('position', 'relative'),
									_Utils_Tuple2('top', '15px')
								]),
							$visotype$elm_dom$Dom$element('div'))),
						A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('font-size', '35px'),
								_Utils_Tuple2('font-weight', '400'),
								_Utils_Tuple2('color', '#000')
							]),
						A4(
							$author$project$TextToSpeech$WithGlobalMsg$speakable,
							{gj: true},
							true,
							text1,
							$author$project$Models$Voice$default)),
						$visotype$elm_dom$Dom$element('br'),
						A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('font-size', '35px'),
								_Utils_Tuple2('font-weight', '400'),
								_Utils_Tuple2('color', '#000')
							]),
						A4(
							$author$project$TextToSpeech$WithGlobalMsg$speakable,
							{gj: true},
							true,
							text2,
							$author$project$Models$Voice$default))
					]),
				A2(
					$visotype$elm_dom$Dom$addClass,
					'auto-tts',
					$visotype$elm_dom$Dom$element('div')));
		});
	var title = A2(
		$elm$core$Basics$composeR,
		$author$project$Component$Text$title(2),
		$visotype$elm_dom$Dom$addStyleList(
			_List_fromArray(
				[
					_Utils_Tuple2('font-size', '35px'),
					_Utils_Tuple2('font-weight', '400')
				])));
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		function () {
			var _v0 = viewData.ii;
			if (_v0.$ === 1) {
				if (!_v0.b) {
					var _v1 = _v0.b;
					return _List_fromArray(
						[
							A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2('margin-top', '77px'),
									_Utils_Tuple2('margin-bottom', '60px')
								]),
							A2(
								$visotype$elm_dom$Dom$appendChild,
								A3($author$project$Views$VideoRecording$PermissionsRequiredView$withTextToSpeech, viewData, 'span', 'Click Allow to record your video for eSpark.'),
								title(''))),
							A2(
							$visotype$elm_dom$Dom$addStyle,
							_Utils_Tuple2('margin-bottom', '60px'),
							$author$project$Component$Image$render(
								{
									f5: 'Permission dialog box',
									hb: 185,
									ig: $author$project$Assets$path($author$project$Assets$videoRecordingPermissionsSafari),
									jS: 267
								})),
							A2(
							$author$project$Animations$CssAnimations$applyWithAnimationPreset,
							5,
							$author$project$Views$Components$Button$WithGlobalMsg$reload(
								$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$StudentVideoRecordingReloadForPermissions))),
							$visotype$elm_dom$Dom$element('span'),
							$author$project$Views$VideoRecording$PermissionsRequiredView$rockyLookingUp
						]);
				} else {
					var _v2 = _v0.b;
					return _List_fromArray(
						[
							A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2('padding', '40px 50px 40px 50px'),
									_Utils_Tuple2('width', 'auto'),
									_Utils_Tuple2('margin', '0 auto')
								]),
							A2(titleWithAudio, 'eSpark needs to access your microphone and camera.', 'Click Skip if your teacher is not available right now.')),
							A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[
									A2(
									$visotype$elm_dom$Dom$addStyleList,
									_List_fromArray(
										[
											_Utils_Tuple2('margin-bottom', '100px'),
											_Utils_Tuple2('flex', '1'),
											_Utils_Tuple2('margin-top', '20px')
										]),
									$author$project$Component$Image$render(
										{
											f5: 'Permission dialog box',
											hb: 185,
											ig: $author$project$Assets$path($author$project$Assets$videoRecordingPermissionsIosChrome),
											jS: 267
										})),
									A2(
									$visotype$elm_dom$Dom$appendChildList,
									_List_fromArray(
										[
											A2(
											$visotype$elm_dom$Dom$addStyle,
											_Utils_Tuple2('margin-bottom', '15px'),
											A2(
												$visotype$elm_dom$Dom$appendText,
												'Do these steps',
												$visotype$elm_dom$Dom$element('p'))),
											A2(
											$visotype$elm_dom$Dom$addStyle,
											_Utils_Tuple2('margin-bottom', '5px'),
											A2(
												$visotype$elm_dom$Dom$appendText,
												'1. Open the Settings app',
												$visotype$elm_dom$Dom$element('p'))),
											A2(
											$visotype$elm_dom$Dom$addStyle,
											_Utils_Tuple2('margin-bottom', '5px'),
											A2(
												$visotype$elm_dom$Dom$appendText,
												'2. Scroll to Chrome',
												$visotype$elm_dom$Dom$element('p'))),
											A2(
											$visotype$elm_dom$Dom$addStyle,
											_Utils_Tuple2('margin-bottom', '5px'),
											A2(
												$visotype$elm_dom$Dom$appendText,
												'3. Turn on Microphone and Camera',
												$visotype$elm_dom$Dom$element('p'))),
											A2(
											$visotype$elm_dom$Dom$addStyle,
											_Utils_Tuple2('margin-bottom', '5px'),
											A2(
												$visotype$elm_dom$Dom$appendText,
												'4. Come back to eSpark',
												$visotype$elm_dom$Dom$element('p'))),
											A2(
											$visotype$elm_dom$Dom$appendText,
											'5. Click \'Allow\' again',
											$visotype$elm_dom$Dom$element('p'))
										]),
									A2(
										$visotype$elm_dom$Dom$addStyleList,
										_List_fromArray(
											[
												_Utils_Tuple2('margin-bottom', '30px'),
												_Utils_Tuple2('flex', '1'),
												_Utils_Tuple2('padding', '0 20px'),
												_Utils_Tuple2('color', '#000'),
												_Utils_Tuple2('font-size', '24px'),
												_Utils_Tuple2('text-align', 'left')
											]),
										$visotype$elm_dom$Dom$element('div')))
								]),
							A2(
								$visotype$elm_dom$Dom$addStyleList,
								_List_fromArray(
									[
										_Utils_Tuple2('display', 'flex')
									]),
								$visotype$elm_dom$Dom$element('div'))),
							A2(
							$visotype$elm_dom$Dom$addStyle,
							_Utils_Tuple2('z-index', '20'),
							A2(
								$author$project$Animations$CssAnimations$applyWithAnimationPreset,
								5,
								$author$project$Views$Components$Button$WithGlobalMsg$reload(
									$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$IosChromePermissionReload)))),
							A2(
							$visotype$elm_dom$Dom$addStyle,
							_Utils_Tuple2('bottom', '80px'),
							A2(
								$author$project$Styles$onTop,
								3,
								$author$project$Component$BottomBar$customBar(
									{
										gq: $author$project$ES$UI$Color$transparent,
										gH: $visotype$elm_dom$Dom$element('div'),
										R: $author$project$Views$VideoRecording$PermissionsRequiredView$nextButton,
										io: $visotype$elm_dom$Dom$element('div')
									}))),
							$visotype$elm_dom$Dom$element('span'),
							$author$project$Views$VideoRecording$PermissionsRequiredView$rockyLookingUp
						]);
				}
			} else {
				return _List_fromArray(
					[
						A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('margin-bottom', '5vh'),
								_Utils_Tuple2('margin-top', '145px')
							]),
						title('eSpark wants to use your camera!')),
						A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('min-width', '100px'),
								_Utils_Tuple2('max-height', '40vh'),
								_Utils_Tuple2('margin-bottom', '5vh')
							]),
						A2(
							$visotype$elm_dom$Dom$addAttributeList,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									$author$project$Assets$path($author$project$Assets$videoRecordingPermissionsChrome)),
									$elm$html$Html$Attributes$alt('How to enable permissions')
								]),
							$visotype$elm_dom$Dom$element('img'))),
						A2(
						$visotype$elm_dom$Dom$appendChild,
						$author$project$Views$Components$Button$WithGlobalMsg$videoRecordingChromePermissionDone(
							$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$StudentVideoRecordingReloadForPermissions)),
						A2(
							$visotype$elm_dom$Dom$addStyle,
							_Utils_Tuple2('width', '188px'),
							$visotype$elm_dom$Dom$element('div'))),
						$author$project$Views$VideoRecording$PermissionsRequiredView$permissionIndicator,
						$author$project$Views$VideoRecording$PermissionsRequiredView$rockyLookingUp
					]);
			}
		}(),
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('flex-direction', 'column'),
					_Utils_Tuple2('background-color', '#E5E5E5'),
					_Utils_Tuple2('align-items', 'center'),
					_Utils_Tuple2('height', '100vh'),
					_Utils_Tuple2('z-index', '10')
				]),
			$visotype$elm_dom$Dom$element('div')));
};
var $author$project$Views$VideoRecording$PermissionsRequiredView$errorDisplay = F2(
	function (viewData, bottomBar) {
		var _v0 = viewData.ii;
		if (!_v0.$) {
			return $author$project$Views$VideoRecording$PermissionsRequiredView$errorDisplayWhenRecordingSupported(viewData);
		} else {
			return $author$project$Views$VideoRecording$PermissionsRequiredView$errorDisplayWhenRecordingSupported(viewData);
		}
	});
var $author$project$Views$VideoRecording$PermissionsRequiredView$view = F2(
	function (viewData, bottomBar) {
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('width', '100%')
				]),
			A2(
				$author$project$Styles$centerAbsolute,
				1,
				A2(
					$author$project$Dom$Extra$addDataCy,
					'video-recording-error',
					A2(
						$author$project$Component$Text$colored,
						$author$project$ES$UI$Color$white,
						$author$project$Component$Text$centered(
							A2(
								$visotype$elm_dom$Dom$appendChild,
								A2($author$project$Views$VideoRecording$PermissionsRequiredView$errorDisplay, viewData, bottomBar),
								$visotype$elm_dom$Dom$element('div')))))));
	});
var $author$project$Views$VideoRecording$RecordingView$view = F4(
	function (studentVideo, activeQuest, videoRecordingStatus, viewData) {
		var videoElement = A2(
			$visotype$elm_dom$Dom$addStyle,
			_Utils_Tuple2('position', 'absolute'),
			$author$project$Styles$sizeFill(
				A2(
					$visotype$elm_dom$Dom$addAttributeList,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$autoplay(true),
							$elm$html$Html$Attributes$preload('preload'),
							A2(
							$elm$html$Html$Attributes$property,
							'muted',
							$elm$json$Json$Encode$bool(true)),
							$elm$html$Html$Attributes$loop(true)
						]),
					A2(
						$visotype$elm_dom$Dom$setId,
						'video',
						$visotype$elm_dom$Dom$element('video')))));
		var showBottomBar = !$author$project$VideoRecording$RecordingProcess$permissionsRequired(videoRecordingStatus);
		var retryStatus = A2(
			$author$project$VideoRecording$RecordingProcess$retryStatus,
			videoRecordingStatus,
			A2($author$project$Models$LocalStorage$Helpers$getVideoRecordingCounts, viewData.hz, studentVideo.a_));
		var retryButton = A2(
			$author$project$Animations$CssAnimations$applyWithAnimationPreset,
			5,
			A2(
				$visotype$elm_dom$Dom$addClass,
				'back-button',
				$author$project$Views$Components$Button$WithGlobalMsg$reload(
					$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$VideoRecordingRetry))));
		var recordingProgress = function () {
			if (videoRecordingStatus.$ === 4) {
				var duration = videoRecordingStatus.a;
				if (duration > 0) {
					var countdownValue = $elm$core$Basics$round(($author$project$VideoRecording$Helpers$maxRecordingLength - duration) / 1000);
					var countdownColor = ((countdownValue <= 5) || (countdownValue === 10)) ? $elm$core$Maybe$Just($author$project$ES$UI$Color$red.at) : (((6 <= countdownValue) && (countdownValue <= 9)) ? $elm$core$Maybe$Just($author$project$ES$UI$Color$red.az) : $elm$core$Maybe$Nothing);
					var countdown = function () {
						if (!countdownColor.$) {
							var color = countdownColor.a;
							return A2(
								$author$project$Component$Text$colored,
								color,
								A2(
									$author$project$Styles$setAbsolutePosition,
									{gt: 'auto', hx: 'auto', iJ: '20px', jy: 'auto'},
									A2(
										$visotype$elm_dom$Dom$appendText,
										$elm$core$String$fromInt(countdownValue),
										$visotype$elm_dom$Dom$element('div'))));
						} else {
							return $visotype$elm_dom$Dom$element('div');
						}
					}();
					var bar = A2(
						$visotype$elm_dom$Dom$addAttributeList,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(
								$elm$core$String$fromFloat(
									$author$project$VideoRecording$Helpers$recordingProgressValue(duration))),
								$elm$html$Html$Attributes$max(
								$elm$core$String$fromFloat($author$project$VideoRecording$Helpers$maxRecordingLength))
							]),
						A3(
							$visotype$elm_dom$Dom$addClassConditional,
							'is-red-base',
							!_Utils_eq(countdownColor, $elm$core$Maybe$Nothing),
							A2(
								$visotype$elm_dom$Dom$addClassList,
								_List_fromArray(
									['progress', 'is-small']),
								$visotype$elm_dom$Dom$element('progress'))));
					return A2(
						$author$project$Styles$setAbsolutePosition,
						{gt: 'auto', hx: '0', iJ: '0', jy: '100%'},
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[bar, countdown]),
							$visotype$elm_dom$Dom$element('div')));
				} else {
					return $visotype$elm_dom$Dom$element('span');
				}
			} else {
				return $visotype$elm_dom$Dom$element('span');
			}
		}();
		var previousButton = function () {
			switch (videoRecordingStatus.$) {
				case 5:
					return A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('margin-bottom', '20px'),
						$author$project$Views$Components$Button$WithGlobalMsg$videoRecordingDelete(
							$author$project$Messages$OpenModal($author$project$Models$Modal$VideoDeletionConfirmationModal)));
				case 4:
					return $visotype$elm_dom$Dom$element('div');
				default:
					return A2(
						$visotype$elm_dom$Dom$addClass,
						'back-button',
						$author$project$Views$Components$Button$WithGlobalMsg$previous(
							$author$project$Messages$NavigateTo(
								$author$project$Routes$VideoRoute(
									$author$project$Routes$VideoRoutes$Instructions(studentVideo.a_)))));
			}
		}();
		var playbackElementId = 'recording-playback';
		var playbackDisplay = function () {
			if (videoRecordingStatus.$ === 5) {
				var dataUrl = videoRecordingStatus.a.cg;
				var playback = videoRecordingStatus.a.aT;
				var playbackReady = videoRecordingStatus.a.bQ;
				return A3(
					$visotype$elm_dom$Dom$appendChildConditional,
					playbackReady ? A2(
						$author$project$Styles$centerAbsolute,
						1,
						$author$project$Views$Components$Button$videoWatchingPlay(
							$author$project$Messages$PlayRecordedVideo('recorded-video-playback'))) : A2(
						$visotype$elm_dom$Dom$appendChildList,
						_List_fromArray(
							[
								$author$project$Component$Image$render(
								{
									f5: 'Animation to show the app is processing your video',
									hb: 200,
									ig: $author$project$Assets$path($author$project$Assets$appWorking),
									jS: 200
								}),
								A2(
								$visotype$elm_dom$Dom$appendText,
								'Processing your video...',
								A2(
									$visotype$elm_dom$Dom$addStyleList,
									_List_fromArray(
										[
											_Utils_Tuple2('margin-top', '20px'),
											_Utils_Tuple2('font-size', '35px')
										]),
									$visotype$elm_dom$Dom$element('div')))
							]),
						A2(
							$author$project$Styles$centerAbsolute,
							2,
							A2(
								$visotype$elm_dom$Dom$addStyleList,
								_List_fromArray(
									[
										_Utils_Tuple2('border-radius', '50px'),
										_Utils_Tuple2('background', 'white'),
										_Utils_Tuple2('padding', '54px 15px'),
										_Utils_Tuple2('width', '456px'),
										_Utils_Tuple2('display', 'flex'),
										_Utils_Tuple2('flex-direction', 'column'),
										_Utils_Tuple2('align-items', 'center')
									]),
								$visotype$elm_dom$Dom$element('div')))),
					!($author$project$Models$VideoPlayer$isPlaying(playback) || $author$project$Models$VideoPlayer$isEnded(playback)),
					A2(
						$visotype$elm_dom$Dom$appendChild,
						A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2('width', '100%'),
									_Utils_Tuple2('height', '100%'),
									_Utils_Tuple2('position', 'absolute')
								]),
							A2(
								$visotype$elm_dom$Dom$addAttributeList,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$autoplay(true),
										$elm$html$Html$Attributes$src(
										$author$project$Models$Urls$toString(dataUrl))
									]),
								A2(
									$visotype$elm_dom$Dom$addAction,
									_Utils_Tuple2(
										'error',
										$author$project$Messages$LoggedInRequiredMsg(
											$author$project$LoggedIn$Messages$VideoRecordingPlaybackChange(
												$author$project$Models$VideoPlayer$Ended($author$project$Models$VideoPlayer$defaultPlayerTime)))),
									A2(
										$visotype$elm_dom$Dom$addAction,
										_Utils_Tuple2(
											'ended',
											$author$project$Messages$LoggedInRequiredMsg(
												$author$project$LoggedIn$Messages$VideoRecordingPlaybackChange(
													$author$project$Models$VideoPlayer$Ended($author$project$Models$VideoPlayer$defaultPlayerTime)))),
										A2(
											$visotype$elm_dom$Dom$addAction,
											_Utils_Tuple2(
												'canplaythrough',
												$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$VideoRecordingPlaybackReady)),
											A2(
												$visotype$elm_dom$Dom$addAction,
												_Utils_Tuple2(
													'play',
													$author$project$Messages$LoggedInRequiredMsg(
														$author$project$LoggedIn$Messages$VideoRecordingPlaybackChange(
															A2($author$project$Models$VideoPlayer$Playing, $author$project$Models$VideoPlayer$defaultPlayerTime, 0)))),
												A2(
													$visotype$elm_dom$Dom$setId,
													playbackElementId,
													$visotype$elm_dom$Dom$element('video')))))))),
						A2(
							$visotype$elm_dom$Dom$setId,
							'recorded-video-playback',
							$visotype$elm_dom$Dom$element('div'))));
			} else {
				return $visotype$elm_dom$Dom$element('span');
			}
		}();
		var nextButton = function () {
			switch (videoRecordingStatus.$) {
				case 4:
					return $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingStop(
						$author$project$Messages$LoggedInRequiredMsg(
							$author$project$LoggedIn$Messages$VideoRecordingPerformAction(1)));
				case 5:
					var readyToMoveOn = videoRecordingStatus.a.bT;
					return readyToMoveOn ? A2(
						$author$project$Dom$Extra$addDataCy,
						'enabled-video-recording-next-button',
						$author$project$Views$Components$Button$WithGlobalMsg$videoRecordingFinish(
							$author$project$Messages$OpenModal($author$project$Models$Modal$VideoRecordingConfirmationModal))) : $visotype$elm_dom$Dom$element('div');
				case 2:
					var errorString = videoRecordingStatus.a;
					return $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingFinish(
						$author$project$Messages$LoggedInRequiredMsg(
							$author$project$LoggedIn$Messages$WebVideoRecordingErrored(errorString)));
				case 1:
					return A2(
						$author$project$Dom$Extra$addDataCy,
						'skip-video-recording-button',
						$author$project$Views$Components$Button$WithGlobalMsg$videoRecordingFinish(
							$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$CameraNotAvailableSkipStudentVideo)));
				case 3:
					return (retryStatus === 1) ? $author$project$Views$Components$Button$WithGlobalMsg$videoRecordingFinish(
						$author$project$Messages$LoggedInRequiredMsg(
							$author$project$LoggedIn$Messages$WebVideoRecordingErrored('retried twice'))) : $visotype$elm_dom$Dom$element('div');
				default:
					return $visotype$elm_dom$Dom$element('div');
			}
		}();
		var instructionsBanner = function () {
			var promptLength = $elm$core$String$length(activeQuest.it.jL);
			var promptFontSize = (promptLength <= 120) ? 44 : 24;
			var promptColor = function () {
				var _v4 = viewData.f8;
				if (!_v4.$) {
					return $author$project$ES$UI$Color$orange.ay;
				} else {
					return $author$project$ES$UI$Color$grey.j1;
				}
			}();
			return A2(
				$author$project$Dom$Extra$addDataCy,
				'video-recording-instructions-banner',
				A2(
					$visotype$elm_dom$Dom$appendChild,
					recordingProgress,
					A2(
						$author$project$Dom$Extra$addDataCy,
						'video-recording-prompt',
						A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2('padding', '50px 50px 30px'),
									_Utils_Tuple2('flex', '0'),
									_Utils_Tuple2('max-height', '250px'),
									_Utils_Tuple2('position', 'relative')
								]),
							A3(
								$visotype$elm_dom$Dom$addStyleConditional,
								_Utils_Tuple2('display', 'none'),
								$author$project$VideoRecording$RecordingProcess$permissionsRequired(videoRecordingStatus) && viewData.jM,
								A2(
									$author$project$Styles$filled,
									$author$project$ES$UI$Color$white,
									A2(
										$author$project$Styles$onTop,
										1,
										$author$project$Styles$shadow(
											A2(
												$author$project$Component$Text$lineHeight,
												'1.3em',
												A2(
													$author$project$Component$Text$inPixels,
													promptFontSize,
													A2(
														$author$project$Component$Text$colored,
														promptColor,
														A2(
															$visotype$elm_dom$Dom$appendChild,
															A3(
																$visotype$elm_dom$Dom$addAttributeConditional,
																A2($elm$html$Html$Attributes$attribute, 'alias', activeQuest.it.jK),
																!$elm$core$String$isEmpty(activeQuest.it.jK),
																A4($author$project$TextToSpeech$WithGlobalMsg$speakable, viewData, false, activeQuest.it.jL, $author$project$Models$Voice$default)),
															$visotype$elm_dom$Dom$element('div')))))))))))));
		}();
		var cameraDisplay = viewData.jM ? videoElement : $visotype$elm_dom$Dom$element('span');
		var bottomBarTryAgain = A2(
			$author$project$Styles$onTop,
			3,
			$author$project$Styles$centerContent(
				$author$project$Component$BottomBar$customBar(
					{
						gq: $author$project$ES$UI$Color$transparent,
						gH: $visotype$elm_dom$Dom$element('div'),
						R: $visotype$elm_dom$Dom$element('div'),
						io: retryButton
					})));
		var bottomBarNext = A2(
			$author$project$Styles$onTop,
			3,
			$author$project$Component$BottomBar$customBar(
				{
					gq: $author$project$ES$UI$Color$transparent,
					gH: $visotype$elm_dom$Dom$element('div'),
					R: nextButton,
					io: $visotype$elm_dom$Dom$element('div')
				}));
		var bottomBar = A2(
			$author$project$Styles$onTop,
			3,
			$author$project$Component$BottomBar$customBar(
				{
					gq: $author$project$ES$UI$Color$transparent,
					gH: $visotype$elm_dom$Dom$element('div'),
					R: nextButton,
					io: previousButton
				}));
		var videoStatusOverlay = function () {
			switch (videoRecordingStatus.$) {
				case 0:
					return A2(
						$author$project$Styles$centerAbsolute,
						1,
						A2(
							$visotype$elm_dom$Dom$addClass,
							'video-recording-custom-loading',
							$author$project$Views$Components$Button$WithGlobalMsg$videoRecordingStart($author$project$Messages$NoOp)));
				case 1:
					return A2($author$project$Views$VideoRecording$PermissionsRequiredView$view, viewData, bottomBar);
				case 3:
					var numberOfCameras = videoRecordingStatus.a.cI;
					return A2(
						$visotype$elm_dom$Dom$appendChildList,
						_List_fromArray(
							[
								A2(
								$author$project$Animations$CssAnimations$applyWithAnimationPreset,
								6,
								A2(
									$author$project$Dom$Extra$addDataCy,
									'video-record-button',
									A2(
										$author$project$Styles$centerAbsolute,
										1,
										$author$project$Views$Components$Button$WithGlobalMsg$videoRecordingStart(
											$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$VideoRecordingStart))))),
								(numberOfCameras > 1) ? A2(
								$author$project$Dom$Extra$addDataCy,
								'video-record-flip-camera',
								A2(
									$visotype$elm_dom$Dom$addStyleList,
									_List_fromArray(
										[
											_Utils_Tuple2(
											'z-index',
											$elm$core$String$fromInt(1)),
											_Utils_Tuple2('position', 'absolute'),
											_Utils_Tuple2('top', '3%'),
											_Utils_Tuple2('left', '2%')
										]),
									$author$project$Views$Components$Button$WithGlobalMsg$videoRecordingFlipCamera(
										$author$project$Messages$LoggedInRequiredMsg(
											$author$project$LoggedIn$Messages$VideoRecordingPerformAction(2))))) : $visotype$elm_dom$Dom$element('span')
							]),
						$visotype$elm_dom$Dom$element('div'));
				case 5:
					var playback = videoRecordingStatus.a.aT;
					if (playback.$ === 4) {
						return A2(
							$author$project$Styles$centerAbsolute,
							1,
							$author$project$Views$Components$Button$WithGlobalMsg$videoRecordingPlayback(
								$author$project$Messages$LoggedInRequiredMsg(
									$author$project$LoggedIn$Messages$ReplayRecordedStudentVideo('recording-playback'))));
					} else {
						return $visotype$elm_dom$Dom$element('span');
					}
				default:
					return $visotype$elm_dom$Dom$element('span');
			}
		}();
		var videoFrame = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('position', 'relative'),
					_Utils_Tuple2('flex', '1 1 auto')
				]),
			A2(
				$author$project$Styles$filled,
				$author$project$ES$UI$Color$black,
				A2(
					$visotype$elm_dom$Dom$appendChildList,
					_List_fromArray(
						[
							videoStatusOverlay,
							(!A2($author$project$VideoRecording$RecordingProcess$readyForReview, videoRecordingStatus, viewData.ii)) ? cameraDisplay : $visotype$elm_dom$Dom$element('span'),
							playbackDisplay
						]),
					$visotype$elm_dom$Dom$element('div'))));
		var _v0 = function () {
			switch (retryStatus) {
				case 0:
					return _Utils_Tuple2('Let\'s try again', bottomBarTryAgain);
				case 1:
					return _Utils_Tuple2('Let\'s move on!', bottomBarNext);
				case 2:
					return _Utils_Tuple2('Let\'s move on!', bottomBarNext);
				default:
					return _Utils_Tuple2('', bottomBar);
			}
		}();
		var errorText = _v0.a;
		var visibleBar = _v0.b;
		var errorScreen = (retryStatus === 3) ? $visotype$elm_dom$Dom$element('span') : A2(
			$author$project$Views$Error$render,
			$author$project$Messages$PlayTTSSpeechFromNode,
			A2(
				$author$project$Views$Error$enableAudio,
				viewData.gj,
				$author$project$Views$Error$configView(errorText)));
		return A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('flex-direction', 'column'),
					_Utils_Tuple2('height', '100vh')
				]),
			A2(
				$author$project$Dom$Extra$addDataCy,
				'video-recording-camera',
				A2(
					$visotype$elm_dom$Dom$appendChildList,
					_List_fromArray(
						[
							instructionsBanner,
							videoFrame,
							$author$project$Views$VideoRecording$VideoRecordingConfirmationModal$render(viewData),
							A2($author$project$Views$VideoRecording$VideoDeletionConfirmationModal$render, activeQuest.a_, viewData),
							$author$project$Views$VideoRecording$RecordingTerminatedModal$render(viewData),
							errorScreen,
							showBottomBar ? visibleBar : $visotype$elm_dom$Dom$element('span')
						]),
					$visotype$elm_dom$Dom$element('div'))));
	});
var $author$project$Screen$viewForStudentRoute = F3(
	function (route, student, model) {
		var seasonalTheme = $author$project$Models$SeasonalTheme$fromDate(model.dn);
		var seasonalThemeClass = function () {
			switch (seasonalTheme) {
				case 0:
					return '';
				case 1:
					return 'seasonal-theme-fall';
				case 2:
					return 'seasonal-theme-halloween';
				default:
					return 'seasonal-theme-winter';
			}
		}();
		var layoutTypeClass = $elm$core$String$concat(
			_List_fromArray(
				[
					$author$project$AppFlow$toString(student.f8),
					'-layout'
				]));
		var audioEnabled = student.gj || A3(
			$krisajenkins$remotedata$RemoteData$unwrap,
			false,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.it;
				},
				function ($) {
					return $.iw;
				}),
			model.fZ);
		var viewData = {
			f1: model.f1,
			dm: model.dm,
			f8: student.f8,
			gj: audioEnabled,
			gk: student.gk,
			gv: model.cl,
			gB: A3($author$project$Models$LocalStorage$Helpers$getStudentCharacterTheme, seasonalTheme, model.f7.bv, model.hz),
			gD: model.f7.gD,
			bz: model.f7.bz,
			dJ: student.dJ,
			dO: model.f7.dO,
			d1: model.d1,
			d4: model.d4,
			hq: model.hq,
			ed: model.f7.ed,
			hw: model.hw,
			hz: model.hz,
			hM: model.hM,
			ii: model.f7.ii,
			bR: model.f7.bR,
			iq: student.iq,
			iQ: seasonalTheme,
			jc: _Utils_eq(
				student.f8,
				$author$project$AppFlow$Choice(0)) ? student.jc : _List_Nil,
			b0: model.f7.b0,
			fB: model.fB,
			jI: model.jI,
			jM: A2($author$project$Helpers$VideoRecordingPermission$videoRecordingPermitted, model.f7.ii, student.iq),
			jT: model.jT,
			aI: model.aI
		};
		var characterSelection = function () {
			var _v8 = viewData.hM;
			if (!_v8.$) {
				if (_v8.a.$ === 8) {
					var characterSelectionModel = _v8.a.a;
					return A2(
						$visotype$elm_dom$Dom$appendNode,
						A2(
							$elm$html$Html$map,
							A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$CharacterSelectionMsg, $author$project$Messages$LoggedInRequiredMsg),
							$visotype$elm_dom$Dom$render(
								A2($author$project$CharacterSelection$view, viewData, characterSelectionModel))),
						$visotype$elm_dom$Dom$element('div'));
				} else {
					return $visotype$elm_dom$Dom$element('span');
				}
			} else {
				return $visotype$elm_dom$Dom$element('span');
			}
		}();
		var content = function () {
			var _v1 = _Utils_Tuple2(route, model.fZ);
			_v1$11:
			while (true) {
				_v1$12:
				while (true) {
					switch (_v1.a.$) {
						case 0:
							var homeModel = _v1.a.a;
							return A3($author$project$Views$SubjectChoiceView$view, homeModel, model.go, viewData);
						case 7:
							switch (_v1.a.a.$) {
								case 0:
									return A2($author$project$Views$QuestHomeView$view, model.fZ, viewData);
								case 1:
									switch (_v1.b.$) {
										case 3:
											var activeQuest = _v1.b.a;
											return A3($author$project$Views$QuestIntroView$view, activeQuest, model.$7, viewData);
										case 1:
											break _v1$11;
										default:
											break _v1$12;
									}
								default:
									switch (_v1.b.$) {
										case 3:
											var activeQuest = _v1.b.a;
											return A2($author$project$Views$QuestOutroView$view, activeQuest, viewData);
										case 1:
											break _v1$11;
										default:
											break _v1$12;
									}
							}
						case 9:
							switch (_v1.b.$) {
								case 3:
									switch (_v1.a.a.$) {
										case 1:
											var activeQuest = _v1.b.a;
											var _v2 = activeQuest.jg;
											if (!_v2.$) {
												var aStudentVideo = _v2.a;
												return A4($author$project$Views$VideoRecording$InstructionsView$view, aStudentVideo, activeQuest, model.d, viewData);
											} else {
												return $author$project$Views$NotFoundView$view;
											}
										case 2:
											var activeQuest = _v1.b.a;
											var _v3 = activeQuest.jg;
											if (!_v3.$) {
												var aStudentVideo = _v3.a;
												return A4($author$project$Views$VideoRecording$RecordingView$view, aStudentVideo, activeQuest, model.d, viewData);
											} else {
												return $author$project$Views$NotFoundView$view;
											}
										default:
											var activeQuest = _v1.b.a;
											var _v4 = activeQuest.jg;
											if (!_v4.$) {
												return A2($author$project$Views$VideoRecording$IntroView$view, activeQuest, viewData);
											} else {
												return $author$project$Views$NotFoundView$view;
											}
									}
								case 1:
									break _v1$11;
								default:
									break _v1$12;
							}
						case 8:
							switch (_v1.b.$) {
								case 3:
									var activityRoute = _v1.a.a;
									var activeQuest = _v1.b.a;
									var view = function () {
										switch (activityRoute.$) {
											case 0:
												return $author$project$Views$ActivityIntroView$view;
											case 1:
												return $author$project$Views$ActivityPracticeView$view;
											case 2:
												return $author$project$Views$AssessmentView$view(1);
											default:
												var componentType = activityRoute.c;
												return $author$project$Views$ComponentView$view(componentType);
										}
									}();
									return A4(
										$author$project$Screen$activityView,
										activeQuest,
										$author$project$Routes$ActivityRoutes$studentActivityId(activityRoute),
										viewData,
										view);
								case 1:
									break _v1$11;
								default:
									break _v1$12;
							}
						case 10:
							switch (_v1.b.$) {
								case 3:
									var quizRoute = _v1.a.a;
									var activeQuest = _v1.b.a;
									var view = function () {
										switch (quizRoute.$) {
											case 0:
												return $author$project$Views$ActivityIntroView$view;
											case 2:
												return $author$project$Views$QuizOutroView$view(viewData.jM);
											default:
												return $author$project$Views$AssessmentView$view(1);
										}
									}();
									return A4(
										$author$project$Screen$activityView,
										activeQuest,
										$author$project$Routes$QuizRoutes$studentActivityId(quizRoute),
										viewData,
										view);
								case 1:
									break _v1$11;
								default:
									break _v1$12;
							}
						case 5:
							switch (_v1.b.$) {
								case 3:
									var activeQuest = _v1.b.a;
									var studentActivity = A2(
										$elm$core$Maybe$withDefault,
										$author$project$Helpers$QuizPreviewDummyData$studentActivity,
										$elm$core$List$head(activeQuest.jd));
									return A4($author$project$Views$AssessmentView$view, 0, studentActivity, activeQuest, viewData);
								case 1:
									break _v1$11;
								default:
									break _v1$12;
							}
						case 6:
							switch (_v1.b.$) {
								case 3:
									var componentType = _v1.a.a;
									var activeQuest = _v1.b.a;
									var studentActivity = A2(
										$elm$core$Maybe$withDefault,
										$author$project$Helpers$QuizPreviewDummyData$studentActivity,
										$elm$core$List$head(activeQuest.jd));
									return A4($author$project$Views$ComponentView$view, componentType, studentActivity, activeQuest, viewData);
								case 1:
									break _v1$11;
								default:
									break _v1$12;
							}
						default:
							if (_v1.b.$ === 1) {
								break _v1$11;
							} else {
								break _v1$12;
							}
					}
				}
				return A3($author$project$Views$SubjectChoiceView$view, $author$project$Views$SubjectChoiceView$Model$init, model.go, viewData);
			}
			var _v7 = _v1.b;
			return A2($author$project$Views$Components$LoadingIndicator$render, 'looking-up-user', 'Hold tight while we load your activities!');
		}();
		var contentToShow = (!$author$project$Models$Modal$isOverlayOpen(viewData.hM)) ? content : $visotype$elm_dom$Dom$element('span');
		var moodRecorder = $author$project$Views$MoodRecorder$render(viewData);
		var npsSurvey = function () {
			var _v0 = model.hM;
			if ((!_v0.$) && (_v0.a.$ === 9)) {
				var npsSurveyModel = _v0.a.a;
				return A2(
					$visotype$elm_dom$Dom$appendNode,
					A2(
						$elm$html$Html$map,
						A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$NpsSurveyMsg, $author$project$Messages$LoggedInRequiredMsg),
						$visotype$elm_dom$Dom$render(
							A2($author$project$NpsSurvey$view, viewData, npsSurveyModel))),
					$visotype$elm_dom$Dom$element('div'));
			} else {
				return $visotype$elm_dom$Dom$element('span');
			}
		}();
		return A3(
			$author$project$Dom$Extra$addClassIfElse,
			_Utils_Tuple2('audio-enabled', 'audio-disabled'),
			viewData.gj,
			A2(
				$visotype$elm_dom$Dom$addClass,
				seasonalThemeClass,
				A2(
					$visotype$elm_dom$Dom$addClass,
					'quiz-v2',
					A2(
						$visotype$elm_dom$Dom$addClass,
						layoutTypeClass,
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[contentToShow, moodRecorder, characterSelection, npsSurvey]),
							$visotype$elm_dom$Dom$element('div'))))));
	});
var $author$project$Screen$renderLoggedInView = F2(
	function (student, model) {
		var viewForRoute = function () {
			var _v1 = model.eY;
			switch (_v1.$) {
				case 4:
					return $author$project$Views$NotFoundView$view;
				case 3:
					return $author$project$Views$NotFoundView$view;
				case 11:
					var joinCodeModel = _v1.b;
					return A2(
						$visotype$elm_dom$Dom$appendNode,
						A2(
							$elm$html$Html$map,
							$author$project$Messages$JoinCodeMsg,
							$author$project$JoinCode$view(joinCodeModel)),
						$visotype$elm_dom$Dom$element('div'));
				case 12:
					return A2(
						$visotype$elm_dom$Dom$appendNode,
						A2(
							$elm$html$Html$map,
							$author$project$Messages$JoinCodeMsg,
							$author$project$JoinCode$view($author$project$JoinCode$Model$init)),
						$visotype$elm_dom$Dom$element('div'));
				case 1:
					return $author$project$Views$NotFoundView$view;
				case 13:
					var libraryModel = _v1.a;
					var sectionId = _v1.b;
					return A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						$visotype$elm_dom$Dom$element('span'),
						function (classLibraryModel) {
							return A2(
								$visotype$elm_dom$Dom$appendNode,
								A2(
									$elm$html$Html$map,
									A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$ClassLibraryMsg, $author$project$Messages$LoggedInRequiredMsg),
									A2($author$project$Views$ClassLibrary$view, classLibraryModel, model.f7.gD)),
								$visotype$elm_dom$Dom$element('div'));
						},
						libraryModel);
				default:
					return A3($author$project$Screen$viewForStudentRoute, model.eY, student, model);
			}
		}();
		var maybeTitle = function () {
			if (_Utils_eq(
				student.f8,
				$author$project$AppFlow$Choice(0))) {
				var _v0 = _Utils_Tuple2(model.eY, model.fZ);
				if (((_v0.a.$ === 7) && (!_v0.a.a.$)) && (_v0.b.$ === 3)) {
					var activeQuest = _v0.b.a;
					return $elm$core$Maybe$Just(activeQuest.it.fx);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var navigation = A2($author$project$Component$Header$view, student, maybeTitle);
		return A3(
			$author$project$Screen$renderContainerView,
			'logged-in',
			model.d1,
			_List_fromArray(
				[
					A2($author$project$Views$ExitModal$render, model, student.f8),
					A2(
					$visotype$elm_dom$Dom$appendChildList,
					_List_fromArray(
						[navigation, viewForRoute]),
					$visotype$elm_dom$Dom$element('div'))
				]));
	});
var $author$project$AppConfiguration$isSupportedIos = function (platform) {
	if (platform.$ === 1) {
		var iosVersion = platform.a;
		return iosVersion >= 10;
	} else {
		return false;
	}
};
var $author$project$Assets$esparkLogoHorizontalBlue = require('/usr/src/app/app/images/logo-horizontal-blue.svg');
var $author$project$LoginApp$QRView$logo = function () {
	var eSparkLogo = $author$project$Component$Image$render(
		{
			f5: 'eSpark Logo',
			hb: 120,
			ig: $author$project$Assets$path($author$project$Assets$esparkLogoHorizontalBlue),
			jS: 316
		});
	return A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('width', '300px'),
				_Utils_Tuple2('text-align', 'center'),
				_Utils_Tuple2('margin', '0 auto'),
				_Utils_Tuple2('z-index', '1')
			]),
		A2(
			$visotype$elm_dom$Dom$appendChild,
			eSparkLogo,
			$visotype$elm_dom$Dom$element('div')));
}();
var $author$project$Assets$rockyHoldingQRCode = require('/usr/src/app/app/images/monsters/rocky/holding-qr-code.svg');
var $author$project$LoginApp$QRView$rockyWithQRCode = function () {
	var rocky = $author$project$Component$Image$render(
		{
			f5: 'hold QR code up to the camera',
			hb: 120,
			ig: $author$project$Assets$path($author$project$Assets$rockyHoldingQRCode),
			jS: 316
		});
	return A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('width', '250px'),
				_Utils_Tuple2('margin-left', 'auto'),
				_Utils_Tuple2('padding-top', '20px'),
				_Utils_Tuple2('align-self', 'flex-end')
			]),
		A2(
			$visotype$elm_dom$Dom$appendChild,
			rocky,
			$visotype$elm_dom$Dom$element('div')));
}();
var $author$project$LoginApp$QRView$qrCodeDisplay = A2(
	$visotype$elm_dom$Dom$appendChildList,
	_List_fromArray(
		[
			A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('margin', '0 auto'),
					_Utils_Tuple2('padding-top', '20px')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				$author$project$LoginApp$QRView$logo,
				$visotype$elm_dom$Dom$element('div'))),
			A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('text-align', 'center'),
					_Utils_Tuple2('padding', '20px 20px'),
					_Utils_Tuple2('font-size', '42px')
				]),
			A2(
				$visotype$elm_dom$Dom$appendText,
				'Hold your code up for the camera!',
				$visotype$elm_dom$Dom$element('h3'))),
			A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('display', 'flex'),
					_Utils_Tuple2('flex-wrap', 'wrap')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('width', '450px'),
								_Utils_Tuple2('margin-left', 'calc(50vw - 225px)')
							]),
						A2(
							$visotype$elm_dom$Dom$addAttribute,
							$elm$html$Html$Attributes$id('qr-scanner'),
							$visotype$elm_dom$Dom$element('div'))),
						$author$project$LoginApp$QRView$rockyWithQRCode
					]),
				$visotype$elm_dom$Dom$element('div')))
		]),
	$visotype$elm_dom$Dom$element('div'));
var $author$project$Assets$arrowWithLock = require('/usr/src/app/app/images/arrow-with-lock.svg');
var $author$project$Views$QRCodeScannerView$CameraNotAvailable$arrowWithLock = A2(
	$visotype$elm_dom$Dom$addStyleList,
	_List_fromArray(
		[
			_Utils_Tuple2('position', 'absolute'),
			_Utils_Tuple2('left', '50px'),
			_Utils_Tuple2('top', '0')
		]),
	$author$project$Component$Image$render(
		{
			f5: 'arrow pointing to Chrome browser settings control',
			hb: 160,
			ig: $author$project$Assets$path($author$project$Assets$arrowWithLock),
			jS: 156
		}));
var $author$project$Views$QRCodeScannerView$CameraNotAvailable$deniedPermissionMessage = A2(
	$visotype$elm_dom$Dom$addStyleList,
	_List_fromArray(
		[
			_Utils_Tuple2('max-width', '360px'),
			_Utils_Tuple2('position', 'absolute'),
			_Utils_Tuple2('left', '385px'),
			_Utils_Tuple2('bottom', '260px'),
			_Utils_Tuple2('background-color', 'white'),
			_Utils_Tuple2('border-radius', '25px'),
			_Utils_Tuple2('color', 'black'),
			_Utils_Tuple2('text-align', 'left'),
			_Utils_Tuple2('padding', '20px 30px')
		]),
	A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[
				A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('margin-bottom', '20px')
					]),
				A2(
					$author$project$Component$Text$withSize,
					5,
					A2(
						$visotype$elm_dom$Dom$appendChildList,
						_List_fromArray(
							[
								A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('margin-bottom', '15px'),
								A2(
									$visotype$elm_dom$Dom$appendText,
									'Oops!',
									$visotype$elm_dom$Dom$element('p'))),
								A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('margin-bottom', '15px'),
								A2(
									$visotype$elm_dom$Dom$appendText,
									'In order to use a code to login, you\'ll need to allow the camera on your browser.',
									$visotype$elm_dom$Dom$element('p'))),
								A2(
								$visotype$elm_dom$Dom$addStyle,
								_Utils_Tuple2('margin-bottom', '10px'),
								A2(
									$visotype$elm_dom$Dom$appendText,
									'Follow these steps on a Chrome browser:',
									$visotype$elm_dom$Dom$element('p')))
							]),
						$visotype$elm_dom$Dom$element('div')))),
				A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('margin-left', '15px'),
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[
									A2(
									$visotype$elm_dom$Dom$appendText,
									'Click on the',
									$visotype$elm_dom$Dom$element('span')),
									A2(
									$visotype$elm_dom$Dom$addStyle,
									_Utils_Tuple2('color', 'gray'),
									A2(
										$visotype$elm_dom$Dom$appendText,
										' \"',
										$visotype$elm_dom$Dom$element('span'))),
									A2(
									$visotype$elm_dom$Dom$addStyleList,
									_List_fromArray(
										[
											_Utils_Tuple2('color', 'gray'),
											_Utils_Tuple2('position', 'relative'),
											_Utils_Tuple2('top', '3px')
										]),
									A2(
										$visotype$elm_dom$Dom$addClassList,
										_List_fromArray(
											['es-icon-web-lock']),
										$visotype$elm_dom$Dom$element('span'))),
									A2(
									$visotype$elm_dom$Dom$addStyle,
									_Utils_Tuple2('color', 'gray'),
									A2(
										$visotype$elm_dom$Dom$appendText,
										'\" ',
										$visotype$elm_dom$Dom$element('span'))),
									A2(
									$visotype$elm_dom$Dom$appendText,
									'up there',
									$visotype$elm_dom$Dom$element('span'))
								]),
							$visotype$elm_dom$Dom$element('li'))),
						A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('margin-left', '15px'),
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[
									A2(
									$visotype$elm_dom$Dom$appendText,
									'Click on the',
									$visotype$elm_dom$Dom$element('span')),
									A2(
									$visotype$elm_dom$Dom$addStyle,
									_Utils_Tuple2('color', 'gray'),
									A2(
										$visotype$elm_dom$Dom$appendText,
										' \"',
										$visotype$elm_dom$Dom$element('span'))),
									A2(
									$visotype$elm_dom$Dom$addStyle,
									_Utils_Tuple2('color', 'gray'),
									A2(
										$visotype$elm_dom$Dom$addClassList,
										_List_fromArray(
											['es-icon-web-settings', 'es-icon--md']),
										$visotype$elm_dom$Dom$element('span'))),
									A2(
									$visotype$elm_dom$Dom$addStyle,
									_Utils_Tuple2('color', 'gray'),
									A2(
										$visotype$elm_dom$Dom$appendText,
										'\" ',
										$visotype$elm_dom$Dom$element('span'))),
									A2(
									$visotype$elm_dom$Dom$appendText,
									'for settings',
									$visotype$elm_dom$Dom$element('span'))
								]),
							$visotype$elm_dom$Dom$element('li'))),
						A2(
						$visotype$elm_dom$Dom$addStyle,
						_Utils_Tuple2('margin-left', '15px'),
						A2(
							$visotype$elm_dom$Dom$appendChildList,
							_List_fromArray(
								[
									A2(
									$visotype$elm_dom$Dom$appendText,
									'Make sure that ',
									$visotype$elm_dom$Dom$element('span')),
									A2(
									$visotype$elm_dom$Dom$addStyle,
									_Utils_Tuple2('color', 'gray'),
									A2(
										$visotype$elm_dom$Dom$appendText,
										' \"',
										$visotype$elm_dom$Dom$element('span'))),
									A2(
									$visotype$elm_dom$Dom$addStyleList,
									_List_fromArray(
										[
											_Utils_Tuple2('color', 'gray'),
											_Utils_Tuple2('position', 'relative'),
											_Utils_Tuple2('top', '3px')
										]),
									A2(
										$visotype$elm_dom$Dom$appendText,
										' , ',
										A2(
											$visotype$elm_dom$Dom$addClassList,
											_List_fromArray(
												['es-icon-web-microphone']),
											$visotype$elm_dom$Dom$element('span')))),
									A2(
									$visotype$elm_dom$Dom$addStyleList,
									_List_fromArray(
										[
											_Utils_Tuple2('color', 'gray'),
											_Utils_Tuple2('position', 'relative'),
											_Utils_Tuple2('top', '3px')
										]),
									A2(
										$visotype$elm_dom$Dom$addClassList,
										_List_fromArray(
											['es-icon-web-camera']),
										$visotype$elm_dom$Dom$element('span'))),
									A2(
									$visotype$elm_dom$Dom$addStyle,
									_Utils_Tuple2('color', 'gray'),
									A2(
										$visotype$elm_dom$Dom$appendText,
										' \" ',
										$visotype$elm_dom$Dom$element('span'))),
									A2(
									$visotype$elm_dom$Dom$appendChildList,
									_List_fromArray(
										[
											A2(
											$visotype$elm_dom$Dom$appendText,
											'have \"',
											$visotype$elm_dom$Dom$element('span')),
											A2(
											$visotype$elm_dom$Dom$addStyle,
											_Utils_Tuple2('font-weight', '900'),
											A2(
												$visotype$elm_dom$Dom$appendText,
												'Allow',
												$visotype$elm_dom$Dom$element('span'))),
											A2(
											$visotype$elm_dom$Dom$appendText,
											'\" next to them.',
											$visotype$elm_dom$Dom$element('span'))
										]),
									$visotype$elm_dom$Dom$element('span'))
								]),
							$visotype$elm_dom$Dom$element('li')))
					]),
				$visotype$elm_dom$Dom$element('ol'))
			]),
		$visotype$elm_dom$Dom$element('div')));
var $author$project$Assets$rockyPointingUp = require('/usr/src/app/app/images/monsters/rocky/pointing-up.svg');
var $author$project$Views$QRCodeScannerView$CameraNotAvailable$rockyPointingUp = A2(
	$visotype$elm_dom$Dom$addStyleList,
	_List_fromArray(
		[
			_Utils_Tuple2('position', 'absolute'),
			_Utils_Tuple2('left', '50px'),
			_Utils_Tuple2('bottom', '0')
		]),
	$author$project$Component$Image$render(
		{
			f5: 'rocky pointing up',
			hb: 406,
			ig: $author$project$Assets$path($author$project$Assets$rockyPointingUp),
			jS: 382
		}));
var $author$project$Assets$rockyPointingUpSad = require('/usr/src/app/app/images/monsters/rocky/pointing-up-sad.svg');
var $author$project$Views$QRCodeScannerView$CameraNotAvailable$rockyPointingUpSad = A2(
	$visotype$elm_dom$Dom$addStyleList,
	_List_fromArray(
		[
			_Utils_Tuple2('position', 'absolute'),
			_Utils_Tuple2('left', '50px'),
			_Utils_Tuple2('bottom', '0')
		]),
	$author$project$Component$Image$render(
		{
			f5: 'sad rocky pointing up',
			hb: 406,
			ig: $author$project$Assets$path($author$project$Assets$rockyPointingUpSad),
			jS: 382
		}));
var $author$project$Assets$speechBubbleTailLeftHooked = require('/usr/src/app/app/images/speech-bubble/speech-bubble-tail-left-hooked.png');
var $author$project$Views$QRCodeScannerView$CameraNotAvailable$speechBubbleTailLeftHooked = A2(
	$visotype$elm_dom$Dom$addStyleList,
	_List_fromArray(
		[
			_Utils_Tuple2('position', 'absolute'),
			_Utils_Tuple2('left', '400px'),
			_Utils_Tuple2('bottom', '210px')
		]),
	$author$project$Component$Image$render(
		{
			f5: '',
			hb: 52,
			ig: $author$project$Assets$path($author$project$Assets$speechBubbleTailLeftHooked),
			jS: 72
		}));
var $author$project$Assets$cameraPermissionMessage = require('/usr/src/app/app/images/camera-permission-message.svg');
var $author$project$Views$QRCodeScannerView$CameraNotAvailable$cameraPermissionImage = A2(
	$visotype$elm_dom$Dom$addStyle,
	_Utils_Tuple2('width', '100%'),
	$author$project$Component$Image$render(
		{
			f5: 'click allow for device camera',
			hb: 104,
			ig: $author$project$Assets$path($author$project$Assets$cameraPermissionMessage),
			jS: 365
		}));
var $author$project$Views$QRCodeScannerView$CameraNotAvailable$unknownPermissionMessage = A2(
	$visotype$elm_dom$Dom$addStyleList,
	_List_fromArray(
		[
			_Utils_Tuple2('max-width', '360px'),
			_Utils_Tuple2('position', 'absolute'),
			_Utils_Tuple2('left', '385px'),
			_Utils_Tuple2('bottom', '260px'),
			_Utils_Tuple2('background-color', 'white'),
			_Utils_Tuple2('border-radius', '25px'),
			_Utils_Tuple2('color', 'black'),
			_Utils_Tuple2('text-align', 'left'),
			_Utils_Tuple2('padding', '20px 30px 3px')
		]),
	A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[
				A2(
				$visotype$elm_dom$Dom$addStyle,
				_Utils_Tuple2('margin-bottom', '20px'),
				A2(
					$author$project$Component$Text$withSize,
					5,
					A2(
						$visotype$elm_dom$Dom$appendText,
						'We\'re so excited to eSpark with you! In order to get started we need you to click ALLOW on a few things in your browser above.',
						$visotype$elm_dom$Dom$element('div')))),
				$author$project$Views$QRCodeScannerView$CameraNotAvailable$cameraPermissionImage
			]),
		$visotype$elm_dom$Dom$element('div')));
var $author$project$Views$QRCodeScannerView$CameraNotAvailable$errorDisplay = function (permission) {
	var rockyPointing = function () {
		switch (permission) {
			case 2:
				return $author$project$Views$QRCodeScannerView$CameraNotAvailable$rockyPointingUpSad;
			case 4:
				return $author$project$Views$QRCodeScannerView$CameraNotAvailable$rockyPointingUp;
			case 1:
				return $author$project$Views$QRCodeScannerView$CameraNotAvailable$rockyPointingUp;
			case 3:
				return $visotype$elm_dom$Dom$element('div');
			default:
				return $visotype$elm_dom$Dom$element('div');
		}
	}();
	var enableCameraAndMicMessaging = function () {
		switch (permission) {
			case 1:
				return $author$project$Views$QRCodeScannerView$CameraNotAvailable$unknownPermissionMessage;
			case 2:
				return $author$project$Views$QRCodeScannerView$CameraNotAvailable$deniedPermissionMessage;
			case 4:
				return $author$project$Views$QRCodeScannerView$CameraNotAvailable$unknownPermissionMessage;
			case 0:
				return $visotype$elm_dom$Dom$element('div');
			default:
				return $visotype$elm_dom$Dom$element('div');
		}
	}();
	return A2(
		$visotype$elm_dom$Dom$addStyle,
		_Utils_Tuple2('height', '100vh'),
		A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[enableCameraAndMicMessaging, $author$project$Views$QRCodeScannerView$CameraNotAvailable$arrowWithLock, rockyPointing, $author$project$Views$QRCodeScannerView$CameraNotAvailable$speechBubbleTailLeftHooked]),
			$visotype$elm_dom$Dom$element('div')));
};
var $author$project$Views$QRCodeScannerView$CameraNotAvailable$render = function (permission) {
	return A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('width', '100%'),
				_Utils_Tuple2('padding', '0 50px'),
				_Utils_Tuple2('background-color', '#000000')
			]),
		A2(
			$author$project$Styles$centerAbsolute,
			1,
			A2(
				$author$project$Dom$Extra$addDataCy,
				'video-recording-error',
				A2(
					$author$project$Component$Text$colored,
					$author$project$ES$UI$Color$white,
					$author$project$Component$Text$centered(
						A2(
							$visotype$elm_dom$Dom$appendChild,
							$author$project$Views$QRCodeScannerView$CameraNotAvailable$errorDisplay(permission),
							$visotype$elm_dom$Dom$element('div')))))));
};
var $author$project$LoginApp$QRView$render = function (permission) {
	var view = function () {
		switch (permission) {
			case 0:
				return $visotype$elm_dom$Dom$element('div');
			case 1:
				return $author$project$Views$QRCodeScannerView$CameraNotAvailable$render(permission);
			case 3:
				return $author$project$LoginApp$QRView$qrCodeDisplay;
			case 2:
				return $author$project$Views$QRCodeScannerView$CameraNotAvailable$render(permission);
			default:
				return $author$project$Views$QRCodeScannerView$CameraNotAvailable$render(permission);
		}
	}();
	var previousButton = A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('left', '20px'),
				_Utils_Tuple2('z-index', '1002')
			]),
		$author$project$Styles$activityButton(
			A2(
				$visotype$elm_dom$Dom$addClass,
				'back-button',
				$author$project$Views$Components$Button$WithGlobalMsg$previous(
					$author$project$Messages$NavigateTo(
						$author$project$Routes$LoginRoute(
							$author$project$Routes$LoginRoutes$PasswordLoginRoute(0)))))));
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[view, previousButton]),
		$visotype$elm_dom$Dom$element('div'));
};
var $author$project$LoginApp$Messages$StartGoogleLogin = function (a) {
	return {$: 5, a: a};
};
var $author$project$LoginApp$Messages$SubmitManualPasswordSignin = {$: 2};
var $author$project$LoginApp$Messages$UpdateLoginPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$LoginApp$Messages$UpdateLoginUsername = function (a) {
	return {$: 0, a: a};
};
var $author$project$LoginApp$View$errorNotification = function (text) {
	return A2(
		$visotype$elm_dom$Dom$addClassList,
		_List_fromArray(
			['notification', 'login--error']),
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('background', '#FFD054'),
					_Utils_Tuple2('font-size', '24px'),
					_Utils_Tuple2('line-height', '36px'),
					_Utils_Tuple2('padding', '15px 25px')
				]),
			A2(
				$visotype$elm_dom$Dom$appendText,
				text,
				$visotype$elm_dom$Dom$element('div'))));
};
var $author$project$LoginApp$View$errorToString = function (error) {
	switch (error.$) {
		case 0:
			return 'I don\'t know this username. Did you type the right letters?';
		case 1:
			return 'This password doesn\'t look right. Did you type the right letters?';
		case 2:
			return 'Oops! That code doesn\'t seem to be valid. Try again or ask your teacher for help.';
		default:
			return 'An unexpected error occurred, please try again.';
	}
};
var $author$project$Assets$googleLogo = require('/usr/src/app/app/images/google-logo.svg');
var $author$project$Models$LoggingIn$isLoggingIn = function (_v0) {
	var login = _v0.bI;
	var autoLoginToken = _v0.b8;
	var ongoingLogin = function () {
		switch (login.$) {
			case 1:
				return true;
			case 0:
				return false;
			default:
				return false;
		}
	}();
	var autoLogin = !_Utils_eq(autoLoginToken, $elm$core$Maybe$Nothing);
	return autoLogin || ongoingLogin;
};
var $author$project$LoginApp$View$form = function (loginState) {
	var ssoError = (loginState.dX === 1) ? $author$project$LoginApp$View$errorNotification('Oops! We weren\'t able to find you through Google. Please log in with your username and password.') : ((loginState.dX === 2) ? $author$project$LoginApp$View$errorNotification('Oops! We weren\'t able to find you through Clever. Please log in with your username and password.') : $visotype$elm_dom$Dom$element('span'));
	var passwordSubmitDisabled = _Utils_eq(loginState.b8, $elm$core$Maybe$Nothing) && (($elm$core$String$length(loginState.fF) < 4) || ($elm$core$String$length(loginState.eI) < 4));
	var loginButtonTitle = function () {
		var _v2 = loginState.bI;
		if (_v2.$ === 1) {
			return 'Logging you in...';
		} else {
			return 'Log In';
		}
	}();
	var loggingInNow = $author$project$Models$LoggingIn$isLoggingIn(loginState);
	var submitButton = A2(
		$visotype$elm_dom$Dom$setId,
		'password-login',
		A3(
			$visotype$elm_dom$Dom$addStyleConditional,
			_Utils_Tuple2('display', 'none'),
			passwordSubmitDisabled,
			A2(
				$visotype$elm_dom$Dom$addAction,
				_Utils_Tuple2('click', $author$project$LoginApp$Messages$SubmitManualPasswordSignin),
				A2(
					$author$project$Component$Buttons$Contained$button,
					$author$project$Component$Buttons$Button$loadingStateFromDisabledState(loggingInNow),
					{gG: 2, hi: 1, hI: $elm$core$Maybe$Nothing, ju: loginButtonTitle}))));
	var inputField = A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('height', '80px'),
				_Utils_Tuple2('font-size', '36px'),
				_Utils_Tuple2('line-height', '48px')
			]),
		A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['input', 'is-medium', 'login-input']),
			$visotype$elm_dom$Dom$element('input')));
	var googleButton = A2(
		$visotype$elm_dom$Dom$addStyleList,
		_List_fromArray(
			[
				_Utils_Tuple2('font-size', '28px'),
				_Utils_Tuple2('font-weight', '900'),
				_Utils_Tuple2('color', '#777575'),
				_Utils_Tuple2('background', '#fff'),
				_Utils_Tuple2('padding', '15px 20px'),
				_Utils_Tuple2('margin', '30px auto 24px'),
				_Utils_Tuple2('border-radius', '5px'),
				_Utils_Tuple2('display', 'flex'),
				_Utils_Tuple2('justify-content', 'center'),
				_Utils_Tuple2('align-items', 'center'),
				_Utils_Tuple2('position', 'relative'),
				_Utils_Tuple2('width', '100%'),
				_Utils_Tuple2('height', '82px')
			]),
		A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('z-index', '1'),
							_Utils_Tuple2('position', 'absolute'),
							_Utils_Tuple2('left', '25px')
						]),
					$author$project$Component$Image$render(
						{
							f5: 'sign in with Google',
							hb: 36,
							ig: $author$project$Assets$path($author$project$Assets$googleLogo),
							jS: 36
						})),
					A2(
					$visotype$elm_dom$Dom$appendChild,
					A2(
						$visotype$elm_dom$Dom$appendText,
						'Sign in with Google',
						$visotype$elm_dom$Dom$element('p')),
					$visotype$elm_dom$Dom$element('div'))
				]),
			A2(
				$visotype$elm_dom$Dom$setId,
				'google-login',
				A3(
					$visotype$elm_dom$Dom$addStyleConditional,
					_Utils_Tuple2('visibility', 'hidden'),
					loggingInNow,
					A2(
						$visotype$elm_dom$Dom$addAction,
						_Utils_Tuple2(
							'click',
							$author$project$LoginApp$Messages$StartGoogleLogin($elm$core$Maybe$Nothing)),
						$visotype$elm_dom$Dom$element('button'))))));
	var field = function (input) {
		return A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['field', 'is-centered']),
			A2(
				$visotype$elm_dom$Dom$appendChild,
				input,
				$visotype$elm_dom$Dom$element('div')));
	};
	var addOtherErrorIfPresent = function (element) {
		var _v1 = loginState.bI;
		if ((_v1.$ === 2) && (_v1.a.$ === 3)) {
			var httpError = _v1.a.a;
			return A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						element,
						$author$project$LoginApp$View$errorNotification(
						$author$project$LoginApp$View$errorToString(
							$author$project$Models$LoggingIn$OtherError(httpError)))
					]),
				$visotype$elm_dom$Dom$element('div'));
		} else {
			return element;
		}
	};
	var addErrorIfPresent = F2(
		function (errorType, element) {
			var _v0 = loginState.bI;
			if (_v0.$ === 2) {
				var error = _v0.a;
				return _Utils_eq(error, errorType) ? A2(
					$visotype$elm_dom$Dom$appendChildList,
					_List_fromArray(
						[
							element,
							$author$project$LoginApp$View$errorNotification(
							$author$project$LoginApp$View$errorToString(error))
						]),
					$visotype$elm_dom$Dom$element('div')) : element;
			} else {
				return element;
			}
		});
	var passwordInput = function () {
		var keycodeForEnter = 13;
		var ifEnterPressed = function (keycode) {
			return _Utils_eq(keycode, keycodeForEnter) ? $elm$json$Json$Decode$succeed($author$project$LoginApp$Messages$SubmitManualPasswordSignin) : $elm$json$Json$Decode$fail('not an enter');
		};
		return A3(
			$visotype$elm_dom$Dom$addStyleConditional,
			_Utils_Tuple2('visibility', 'hidden'),
			!_Utils_eq(loginState.b8, $elm$core$Maybe$Nothing),
			A2(
				addErrorIfPresent,
				$author$project$Models$LoggingIn$PasswordError,
				A2(
					$visotype$elm_dom$Dom$addStyle,
					_Utils_Tuple2('margin-top', '10px'),
					A2(
						$visotype$elm_dom$Dom$setId,
						'password',
						A2(
							$visotype$elm_dom$Dom$addInputHandler,
							$author$project$LoginApp$Messages$UpdateLoginPassword,
							A2(
								$visotype$elm_dom$Dom$addAttributeList,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$placeholder('PASSWORD'),
										$elm$html$Html$Attributes$value(loginState.eI),
										A2(
										$elm$html$Html$Events$on,
										'keydown',
										A2($elm$json$Json$Decode$andThen, ifEnterPressed, $elm$html$Html$Events$keyCode)),
										A2($elm$html$Html$Attributes$attribute, 'aria-label', 'Password')
									]),
								inputField))))));
	}();
	var usernameInput = A3(
		$visotype$elm_dom$Dom$addStyleConditional,
		_Utils_Tuple2('visibility', 'hidden'),
		!_Utils_eq(loginState.b8, $elm$core$Maybe$Nothing),
		addOtherErrorIfPresent(
			A2(
				addErrorIfPresent,
				$author$project$Models$LoggingIn$UsernameError,
				A2(
					$visotype$elm_dom$Dom$setId,
					'username',
					A2(
						$visotype$elm_dom$Dom$addInputHandler,
						$author$project$LoginApp$Messages$UpdateLoginUsername,
						A2(
							$visotype$elm_dom$Dom$addAttributeList,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder('USERNAME'),
									$elm$html$Html$Attributes$value(loginState.fF),
									A2($elm$html$Html$Attributes$attribute, 'aria-label', 'User Name')
								]),
							inputField))))));
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[
				ssoError,
				field(googleButton),
				field(usernameInput),
				field(passwordInput),
				field(submitButton)
			]),
		$visotype$elm_dom$Dom$element('div'));
};
var $author$project$LoginApp$View$logo = function () {
	var eSparkLogo = $author$project$Component$Image$render(
		{
			f5: 'eSpark Logo',
			hb: 120,
			ig: $author$project$Assets$path($author$project$Assets$esparkLogoHorizontal),
			jS: 316
		});
	return A2(
		$visotype$elm_dom$Dom$addStyle,
		_Utils_Tuple2('margin-bottom', '40px'),
		A2(
			$visotype$elm_dom$Dom$appendChild,
			eSparkLogo,
			$visotype$elm_dom$Dom$element('div')));
}();
var $author$project$LoginApp$Messages$GoToQRView = {$: 6};
var $author$project$Assets$qrCodeIcon = require('/usr/src/app/app/images/qr-icon.svg');
var $author$project$LoginApp$View$qrLoginButton = F2(
	function (_v0, appConfiguration) {
		var isIos = $author$project$AppConfiguration$isIos(appConfiguration.ii);
		var qrCodeButtonText = isIos ? A2(
			$visotype$elm_dom$Dom$appendChild,
			A2(
				$visotype$elm_dom$Dom$appendText,
				'iOS devices use the Camera app to scan your QR code',
				$visotype$elm_dom$Dom$element('p')),
			$visotype$elm_dom$Dom$element('div')) : A2(
			$visotype$elm_dom$Dom$appendChildList,
			_List_fromArray(
				[
					A2(
					$visotype$elm_dom$Dom$appendText,
					'Have a QR code?',
					$visotype$elm_dom$Dom$element('p')),
					A2(
					$visotype$elm_dom$Dom$appendText,
					'Use it to log in!',
					$visotype$elm_dom$Dom$element('p'))
				]),
			$visotype$elm_dom$Dom$element('div'));
		return A3(
			$visotype$elm_dom$Dom$addStyleConditional,
			_Utils_Tuple2('max-width', '400px'),
			isIos,
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2('font-size', '21px'),
						_Utils_Tuple2('color', 'black'),
						_Utils_Tuple2('background', '#fff'),
						_Utils_Tuple2('padding', '15px 20px'),
						_Utils_Tuple2('margin', '30px auto 20px'),
						_Utils_Tuple2('border-radius', '5px'),
						_Utils_Tuple2('display', 'flex'),
						_Utils_Tuple2('justify-content', 'center'),
						_Utils_Tuple2('text-align', 'left'),
						_Utils_Tuple2('max-width', '300px')
					]),
				A2(
					$visotype$elm_dom$Dom$appendChildList,
					_List_fromArray(
						[
							A2(
							$visotype$elm_dom$Dom$addStyle,
							_Utils_Tuple2('margin-right', '15px'),
							$author$project$Component$Image$render(
								{
									f5: '',
									hb: 60,
									ig: $author$project$Assets$path($author$project$Assets$qrCodeIcon),
									jS: 60
								})),
							qrCodeButtonText
						]),
					A3(
						$visotype$elm_dom$Dom$addActionConditional,
						_Utils_Tuple2('click', $author$project$LoginApp$Messages$GoToQRView),
						!isIos,
						$visotype$elm_dom$Dom$element('button')))));
	});
var $author$project$LoginApp$View$versionInformation = function (appConfiguration) {
	return A2(
		$visotype$elm_dom$Dom$appendChild,
		A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('bottom', '20px'),
					_Utils_Tuple2('left', '20px'),
					_Utils_Tuple2('background', 'transparent'),
					_Utils_Tuple2('position', 'absolute'),
					_Utils_Tuple2('font-style', 'normal'),
					_Utils_Tuple2('font-weight', 'normal'),
					_Utils_Tuple2('font-size', '14px'),
					_Utils_Tuple2('line-height', '17px'),
					_Utils_Tuple2('color', '#FFFFFF'),
					_Utils_Tuple2('opacity', '0.6')
				]),
			A2(
				$visotype$elm_dom$Dom$appendText,
				'Version: ' + $author$project$AppConfiguration$versionText(appConfiguration),
				$visotype$elm_dom$Dom$element('p'))),
		$visotype$elm_dom$Dom$element('div'));
};
var $author$project$LoginApp$View$render = F2(
	function (loginState, appConfiguration) {
		var content = A2(
			$visotype$elm_dom$Dom$addStyleList,
			_List_fromArray(
				[
					_Utils_Tuple2('width', '540px'),
					_Utils_Tuple2('text-align', 'center'),
					_Utils_Tuple2('margin', '0 auto')
				]),
			A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						$author$project$LoginApp$View$logo,
						$author$project$LoginApp$View$form(loginState),
						A2($author$project$LoginApp$View$qrLoginButton, loginState, appConfiguration),
						$author$project$LoginApp$View$versionInformation(appConfiguration)
					]),
				$visotype$elm_dom$Dom$element('div')));
		return $visotype$elm_dom$Dom$render(
			A2(
				$visotype$elm_dom$Dom$addClassList,
				_List_fromArray(
					['hero', 'is-fullheight']),
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2(
							'background',
							'url(\'' + ($author$project$Assets$path($author$project$Assets$loginBackground) + '\')')),
							_Utils_Tuple2('padding-top', '10vh')
						]),
					A2(
						$visotype$elm_dom$Dom$appendChild,
						content,
						$visotype$elm_dom$Dom$element('section')))));
	});
var $author$project$Screen$renderNotLoggedInView = F2(
	function (loginState, model) {
		var content = function () {
			var _v0 = model.eY;
			switch (_v0.$) {
				case 1:
					var loginRoute = _v0.a;
					switch (loginRoute.$) {
						case 0:
							return A2(
								$visotype$elm_dom$Dom$appendNode,
								A2(
									$elm$html$Html$map,
									$author$project$Messages$LoginMessage,
									A2($author$project$LoginApp$View$render, loginState, model.f7)),
								$visotype$elm_dom$Dom$element('div'));
						case 1:
							return A2(
								$visotype$elm_dom$Dom$appendNode,
								A2(
									$elm$html$Html$map,
									$author$project$Messages$LoginMessage,
									A2($author$project$LoginApp$View$render, loginState, model.f7)),
								$visotype$elm_dom$Dom$element('div'));
						case 2:
							return A2(
								$visotype$elm_dom$Dom$appendNode,
								A2(
									$elm$html$Html$map,
									$author$project$Messages$LoginMessage,
									A2($author$project$LoginApp$View$render, loginState, model.f7)),
								$visotype$elm_dom$Dom$element('div'));
						default:
							return $author$project$LoginApp$QRView$render(model.hJ);
					}
				case 11:
					var joinCodeModel = _v0.b;
					return A2(
						$visotype$elm_dom$Dom$appendNode,
						A2(
							$elm$html$Html$map,
							$author$project$Messages$JoinCodeMsg,
							$author$project$JoinCode$view(joinCodeModel)),
						$visotype$elm_dom$Dom$element('div'));
				case 12:
					return A2(
						$visotype$elm_dom$Dom$appendNode,
						A2(
							$elm$html$Html$map,
							$author$project$Messages$JoinCodeMsg,
							$author$project$JoinCode$view($author$project$JoinCode$Model$init)),
						$visotype$elm_dom$Dom$element('div'));
				default:
					var errorMessage = ($author$project$AppConfiguration$isIos(model.f7.ii) && (!$author$project$AppConfiguration$isSupportedIos(model.f7.ii))) ? 'We don\'t support your version of iOS. Please try again with a different device.' : 'Please contact eSpark support and then refresh this page.';
					return A2(
						$author$project$Views$Error$render,
						$author$project$Messages$PlayTTSSpeechFromNode,
						A2(
							$author$project$Views$Error$enableAudio,
							true,
							$author$project$Views$Error$configView(errorMessage)));
			}
		}();
		return A3(
			$author$project$Screen$renderContainerView,
			'not-logged-in',
			model.d1,
			_List_fromArray(
				[content]));
	});
var $author$project$Screen$renderNotYetKnownView = F2(
	function (hasInteractedWithPage, appHasInitialized) {
		return A3(
			$author$project$Screen$renderContainerView,
			'user-state-undetermined',
			hasInteractedWithPage,
			_List_fromArray(
				[
					A2($author$project$Views$Components$LoadingIndicator$render, 'looking-up-user', 'Hold tight while we get your info!')
				]));
	});
var $author$project$EsparkIosAppDisabled$OpenEsparkInBrowser = {$: 1};
var $author$project$EsparkIosAppDisabled$PlayAllSpeech = {$: 2};
var $author$project$EsparkIosAppDisabled$PlayTTS = function (a) {
	return {$: 0, a: a};
};
var $author$project$Component$Buttons$Button$YellowLight = 5;
var $author$project$EsparkIosAppDisabled$view = function (isPlaying) {
	return A2(
		$visotype$elm_dom$Dom$appendChildList,
		_List_fromArray(
			[
				A2(
				$visotype$elm_dom$Dom$addAttributeList,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(
						$author$project$Assets$path($author$project$Assets$rockySubjectChoice)),
						$elm$html$Html$Attributes$alt('Rocky welcomes you!'),
						$elm$html$Html$Attributes$width(313)
					]),
				$visotype$elm_dom$Dom$element('img')),
				A2(
				$visotype$elm_dom$Dom$appendChildList,
				_List_fromArray(
					[
						A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('min-width', '80px'),
								_Utils_Tuple2('height', '80px'),
								_Utils_Tuple2('margin-right', '40px')
							]),
						A2(
							$author$project$Dom$Extra$addClickAction,
							$author$project$EsparkIosAppDisabled$PlayAllSpeech,
							A2(
								$author$project$Component$Buttons$Action$button,
								$author$project$Component$Buttons$Button$loadingStateFromDisabledState(isPlaying),
								_Utils_Tuple3(
									0,
									4,
									A2($author$project$Component$Icon$Icon, 17, $author$project$Component$Icon$Default))))),
						A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'color',
								$avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$white)),
								_Utils_Tuple2('font-size', '40px'),
								_Utils_Tuple2('line-height', '56px')
							]),
						A5(
							$author$project$TextToSpeech$speakable,
							$author$project$EsparkIosAppDisabled$PlayTTS,
							{gj: true},
							true,
							'Hi there! eSpark has moved to the web.\nLet’s continue in your web browser. ',
							5))
					]),
				A2(
					$visotype$elm_dom$Dom$addStyleList,
					_List_fromArray(
						[
							_Utils_Tuple2('display', 'flex'),
							_Utils_Tuple2('width', '60vw'),
							_Utils_Tuple2('margin-top', '40px'),
							_Utils_Tuple2('align-items', 'center')
						]),
					$visotype$elm_dom$Dom$element('div'))),
				A2(
				$visotype$elm_dom$Dom$addAction,
				_Utils_Tuple2('click', $author$project$EsparkIosAppDisabled$OpenEsparkInBrowser),
				A2(
					$visotype$elm_dom$Dom$appendChild,
					A2(
						$author$project$Animations$CssAnimations$applyWithAnimationPreset,
						5,
						A2(
							$visotype$elm_dom$Dom$addStyleList,
							_List_fromArray(
								[
									_Utils_Tuple2(
									'color',
									$avh4$elm_color$Color$toCssString($author$project$ES$UI$Color$grey.j1)),
									_Utils_Tuple2('margin-bottom', '80px')
								]),
							A2(
								$author$project$Component$Buttons$Contained$button,
								0,
								{
									gG: 5,
									hi: 1,
									hI: $elm$core$Maybe$Just(
										A2($author$project$Component$Icon$Icon, 5, $author$project$Component$Icon$Default)),
									ju: 'Go to my browser'
								}))),
					A2(
						$visotype$elm_dom$Dom$addStyleList,
						_List_fromArray(
							[
								_Utils_Tuple2('margin-top', '80px')
							]),
						$visotype$elm_dom$Dom$element('div'))))
			]),
		A2(
			$visotype$elm_dom$Dom$addClassList,
			_List_fromArray(
				['hero', 'is-fullheight', 'main']),
			A2(
				$visotype$elm_dom$Dom$addStyleList,
				_List_fromArray(
					[
						_Utils_Tuple2(
						'background',
						'url(\'' + ($author$project$Assets$path($author$project$Assets$loginBackground) + '\')')),
						_Utils_Tuple2('display', 'flex'),
						_Utils_Tuple2('align-items', 'center')
					]),
				$visotype$elm_dom$Dom$element('section'))));
};
var $author$project$Main$view = function (model) {
	return model.f7.ee ? A2(
		$elm$html$Html$map,
		$author$project$Messages$EsparkIosAppDisabledMsg,
		$visotype$elm_dom$Dom$render(
			$author$project$EsparkIosAppDisabled$view(model.fB))) : $visotype$elm_dom$Dom$render(
		A2(
			$author$project$Model$forLoggedInState,
			model,
			{
				jP: F2(
					function (student, _v0) {
						return A2($author$project$Screen$renderLoggedInView, student, model);
					}),
				jQ: function (loginState) {
					return A2($author$project$Screen$renderNotLoggedInView, loginState, model);
				},
				jR: function (_v1) {
					return A2($author$project$Screen$renderNotYetKnownView, model.d1, model.f9);
				}
			}));
};
var $author$project$Helpers$EncodingHelper$encodeTuple = F2(
	function (_v0, _v1) {
		var encodeA = _v0.a;
		var encodeB = _v0.b;
		var a = _v1.a;
		var b = _v1.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					encodeA(a),
					encodeB(b)
				]));
	});
var $author$project$Models$LocalStorage$TimeTuple$encode = function (encodeValue) {
	return $author$project$Helpers$EncodingHelper$encodeTuple(
		_Utils_Tuple2(
			A2($elm$core$Basics$composeR, $elm$time$Time$posixToMillis, $elm$json$Json$Encode$int),
			encodeValue));
};
var $author$project$Models$LocalStorage$StudentChoices$encodeCharacter = function (character) {
	return $elm$json$Json$Encode$string(
		$author$project$Models$LocalStorage$StudentChoices$characterName(character));
};
var $author$project$Helpers$EncodingHelper$encodeMaybe = F2(
	function (encodeValue, maybe) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$json$Json$Encode$null,
			A2($elm$core$Maybe$map, encodeValue, maybe));
	});
var $author$project$Models$LocalStorage$StudentChoices$encode = function (choices) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'character',
				A2(
					$author$project$Helpers$EncodingHelper$encodeMaybe,
					$author$project$Models$LocalStorage$TimeTuple$encode($author$project$Models$LocalStorage$StudentChoices$encodeCharacter),
					choices.v))
			]));
};
var $author$project$Helpers$EncodingHelper$encodeDict = F2(
	function (encodeValue, dict) {
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$mapSecond(encodeValue),
				$elm$core$Dict$toList(dict)));
	});
var $author$project$Models$LocalStorage$StudentQuestHistory$encodeEntry = function (entry) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'mostRecentActivityId',
				$author$project$Models$Ids$encoder(entry.eC))
			]));
};
var $author$project$Models$LocalStorage$StudentQuestHistory$encode = $author$project$Helpers$EncodingHelper$encodeDict($author$project$Models$LocalStorage$StudentQuestHistory$encodeEntry);
var $author$project$Models$LocalStorage$VideoPlayerTime$encodeEntry = function (entry) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'currentTime',
				$elm$json$Json$Encode$float(entry.cf)),
				_Utils_Tuple2(
				'duration',
				$elm$json$Json$Encode$float(entry.dQ))
			]));
};
var $author$project$Models$LocalStorage$VideoPlayerTime$encode = $author$project$Helpers$EncodingHelper$encodeDict($author$project$Models$LocalStorage$VideoPlayerTime$encodeEntry);
var $author$project$Models$LocalStorage$VideoRecordingCounts$encodeEntry = function (entry) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'attemptCount',
				$elm$json$Json$Encode$int(entry.gi)),
				_Utils_Tuple2(
				'deletedCount',
				$elm$json$Json$Encode$int(entry.gO)),
				_Utils_Tuple2(
				'permissionRetryCount',
				$elm$json$Json$Encode$int(entry.cN))
			]));
};
var $author$project$Models$LocalStorage$VideoRecordingCounts$encode = $author$project$Helpers$EncodingHelper$encodeDict($author$project$Models$LocalStorage$VideoRecordingCounts$encodeEntry);
var $author$project$Models$LocalStorage$encode = function (localStorage) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'v5-student-quest-history',
				$author$project$Models$LocalStorage$StudentQuestHistory$encode(localStorage.c0)),
				_Utils_Tuple2(
				'v5-video-player-time-key',
				$author$project$Models$LocalStorage$VideoPlayerTime$encode(localStorage.c8)),
				_Utils_Tuple2(
				'v5-student-choices',
				$author$project$Models$LocalStorage$StudentChoices$encode(localStorage.c$)),
				_Utils_Tuple2(
				'v5-video-recording-counts',
				$author$project$Models$LocalStorage$VideoRecordingCounts$encode(localStorage.c9))
			]));
};
var $author$project$Ports$updateLocalStorage = _Platform_outgoingPort('updateLocalStorage', $elm$core$Basics$identity);
var $author$project$Models$LocalStorage$Helpers$autoSync = F2(
	function (prevModel, _v0) {
		var updatedModel = _v0.a;
		var cmd = _v0.b;
		return (!_Utils_eq(updatedModel.hz, prevModel.hz)) ? _Utils_Tuple2(
			updatedModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmd,
						$author$project$Ports$updateLocalStorage(
						$author$project$Models$LocalStorage$encode(updatedModel.hz))
					]))) : _Utils_Tuple2(updatedModel, cmd);
	});
var $author$project$Logger$Redshift = 0;
var $author$project$Logger$LogAction$DoNotLog = {$: 2};
var $author$project$Logger$LogAction$LogString = function (a) {
	return {$: 0, a: a};
};
var $author$project$Logger$LogAction$LogStructuredData = function (a) {
	return {$: 1, a: a};
};
var $author$project$AppFlow$encodeDestination = function (destination) {
	switch (destination.$) {
		case 0:
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'destination',
					$elm$json$Json$Encode$string('NextIncompleteActivity'))
				]);
		case 1:
			var studentActivityId = destination.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'destination',
					$elm$json$Json$Encode$string('NextSequentialActivity')),
					_Utils_Tuple2(
					'studentActivityId',
					$author$project$Models$Ids$encoder(studentActivityId))
				]);
		case 2:
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'destination',
					$elm$json$Json$Encode$string('FirstActivity'))
				]);
		case 3:
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'destination',
					$elm$json$Json$Encode$string('LastNonQuizActivity'))
				]);
		case 4:
			var studentActivityId = destination.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'destination',
					$elm$json$Json$Encode$string('PreviousSequentialActivity')),
					_Utils_Tuple2(
					'studentActivityId',
					$author$project$Models$Ids$encoder(studentActivityId))
				]);
		default:
			var studentActivityId = destination.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'destination',
					$elm$json$Json$Encode$string('ActivityWithId')),
					_Utils_Tuple2(
					'studentActivityId',
					$author$project$Models$Ids$encoder(studentActivityId))
				]);
	}
};
var $author$project$Logger$encodeStudentActivityId = function (studentActivityId) {
	return _Utils_Tuple2(
		'student_activity_id',
		$author$project$Models$Ids$encoder(studentActivityId));
};
var $elm$regex$Regex$findAtMost = _Regex_findAtMost;
var $elm_community$string_extra$String$Extra$firstResultHelp = F2(
	function (_default, list) {
		firstResultHelp:
		while (true) {
			if (!list.b) {
				return _default;
			} else {
				if (!list.a.$) {
					var a = list.a.a;
					return a;
				} else {
					var _v1 = list.a;
					var rest = list.b;
					var $temp$default = _default,
						$temp$list = rest;
					_default = $temp$default;
					list = $temp$list;
					continue firstResultHelp;
				}
			}
		}
	});
var $elm_community$string_extra$String$Extra$firstResult = function (list) {
	return A2($elm_community$string_extra$String$Extra$firstResultHelp, '', list);
};
var $elm_community$string_extra$String$Extra$regexEscape = A2(
	$elm$regex$Regex$replace,
	$elm_community$string_extra$String$Extra$regexFromString('[-/\\^$*+?.()|[\\]{}]'),
	function (_v0) {
		var match = _v0.hE;
		return '\\' + match;
	});
var $elm_community$string_extra$String$Extra$rightOf = F2(
	function (pattern, string) {
		return A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.jk;
					},
					$elm_community$string_extra$String$Extra$firstResult),
				A3(
					$elm$regex$Regex$findAtMost,
					1,
					$elm_community$string_extra$String$Extra$regexFromString(
						$elm_community$string_extra$String$Extra$regexEscape(pattern) + '(.*)$'),
					string)));
	});
var $author$project$CharacterSelection$toCharacter = function (_v0) {
	var character = _v0.v;
	if (character.$ === 1) {
		var c = character.a;
		return $elm$core$Maybe$Just(c);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$NpsSurveyResult$encode = function (_v0) {
	var score = _v0.e4;
	var response = _v0.cV;
	var id = _v0.hj;
	var scoreToInt = function (typedScore) {
		switch (typedScore) {
			case 0:
				return 0;
			case 1:
				return 1;
			case 2:
				return 2;
			case 3:
				return 3;
			case 4:
				return 4;
			default:
				return 5;
		}
	};
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'score',
				$elm$json$Json$Encode$int(
					scoreToInt(score))),
				_Utils_Tuple2(
				'response',
				$elm$json$Json$Encode$string(response)),
				_Utils_Tuple2(
				'nps_survey_id',
				$author$project$Models$Ids$encoder(id))
			]));
};
var $author$project$Models$NpsSurveyResult$toString = A2(
	$elm$core$Basics$composeR,
	$author$project$Models$NpsSurveyResult$encode,
	$elm$json$Json$Encode$encode(0));
var $author$project$Models$Modal$modalIdentifierToString = function (modalIdentifier) {
	var asString = function () {
		switch (modalIdentifier.$) {
			case 0:
				return 'IntroVideoModal';
			case 1:
				return 'RatingsModal';
			case 2:
				return 'PrequizMasteryModal';
			case 3:
				return 'VideoRecordingConfirmationModal';
			case 4:
				return 'VideoDeletionConfirmationModal';
			case 5:
				return 'VideoRecordingTerminatedModal';
			case 6:
				var subjectArea = modalIdentifier.a.bp;
				return 'VideoFeedbackModal ' + $author$project$Models$SubjectArea$toString(subjectArea);
			case 7:
				var maybeMood = modalIdentifier.a;
				return 'MoodRecorderOverlay ' + A3($elm_community$maybe_extra$Maybe$Extra$unwrap, 'Nothing', $author$project$Models$StudentMood$moodToString, maybeMood);
			case 8:
				var characterSelectionModel = modalIdentifier.a;
				return function (term) {
					return 'CharacterSelectionOverlay ' + term;
				}(
					A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						'Nothing',
						$author$project$Models$LocalStorage$StudentChoices$characterName,
						$author$project$CharacterSelection$toCharacter(characterSelectionModel)));
			case 9:
				var npsSurveyResult = modalIdentifier.a.h3;
				return 'NpsSurveryOverlay ' + $author$project$Models$NpsSurveyResult$toString(npsSurveyResult);
			default:
				return 'AvatarCustomizationModal';
		}
	}();
	return A2(
		$elm_community$string_extra$String$Extra$rightOf,
		'-',
		$elm_community$string_extra$String$Extra$dasherize(asString));
};
var $author$project$Models$Permission$permissionToString = function (permission) {
	switch (permission) {
		case 0:
			return 'NotYetDetermined';
		case 1:
			return 'Prompting';
		case 2:
			return 'Denied';
		case 3:
			return 'Granted';
		default:
			return 'NeitherGrantedNorDenied';
	}
};
var $author$project$Models$YoutubeVideoPlayer$restrictionSourceAsString = function (restrictionSource) {
	if (!restrictionSource) {
		return 'TimedOutWithoutPlay';
	} else {
		return 'VideoDataFromYoutube';
	}
};
var $author$project$Logger$resultToBool = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.eS;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.d_,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.is,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.eN,
					_Utils_ap(http, url.he)),
				url.ig)));
};
var $author$project$Conversions$String$fromHttpError = function (error) {
	switch (error.$) {
		case 1:
			return 'Timeout exceeded';
		case 2:
			return 'Network error';
		case 3:
			var statusCode = error.a;
			return 'Bad Status: ' + $elm$core$String$fromInt(statusCode);
		case 4:
			var text = error.a;
			return 'Unexpected response: ' + text;
		default:
			var url = error.a;
			return 'Malformed url: ' + url;
	}
};
var $author$project$Models$JoinCodeError$joinCodeErrorToString = function (err) {
	switch (err.$) {
		case 0:
			return 'CodeMissing';
		case 1:
			return 'UnknownCode';
		case 2:
			return 'KeyMissing';
		case 3:
			return 'NoInProgress';
		case 4:
			return 'UnableToSave';
		default:
			var httpError = err.a;
			return 'OtherJoinCodeError: ' + $author$project$Conversions$String$fromHttpError(httpError);
	}
};
var $author$project$Logger$whatToLogJoinCodeMessage = F3(
	function (message, model, prevModel) {
		switch (message.$) {
			case 0:
				return $author$project$Logger$LogAction$DoNotLog;
			case 1:
				return $author$project$Logger$LogAction$LogString('LoginMessage SubmitJoinRequest');
			case 3:
				return $author$project$Logger$LogAction$DoNotLog;
			case 4:
				return $author$project$Logger$LogAction$DoNotLog;
			default:
				if (!message.a.$) {
					return $author$project$Logger$LogAction$LogString('JoinRequestSuccess');
				} else {
					var err = message.a.a;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'error',
									$elm$json$Json$Encode$string(
										$author$project$Models$JoinCodeError$joinCodeErrorToString(err)))
								]),
							hK: 'JoinRequestFailure'
						});
				}
		}
	});
var $author$project$Logger$Utils$dataForHttpResponse = function (result) {
	if (!result.$) {
		return _List_fromArray(
			[
				_Utils_Tuple2(
				'ok',
				$elm$json$Json$Encode$bool(true))
			]);
	} else {
		var err = result.a;
		return _List_fromArray(
			[
				_Utils_Tuple2(
				'ok',
				$elm$json$Json$Encode$bool(false)),
				_Utils_Tuple2(
				'error',
				$elm$json$Json$Encode$string(
					$author$project$Conversions$String$fromHttpError(err)))
			]);
	}
};
var $author$project$Models$ItemScore$loggingEncoder = function (itemScore) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'score',
			A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$int, itemScore.e4)),
			_Utils_Tuple2(
			'maxScore',
			$elm$json$Json$Encode$int(itemScore.eu)),
			_Utils_Tuple2(
			'active',
			$elm$json$Json$Encode$bool(itemScore.ar)),
			_Utils_Tuple2(
			'attemptCount',
			$elm$json$Json$Encode$int(itemScore.iI)),
			_Utils_Tuple2(
			'ref',
			$elm$json$Json$Encode$string(itemScore.iA))
		]);
};
var $author$project$Logger$whatToLogLearnosityMessage = F3(
	function (message, model, prevModel) {
		var encodeItemScores = function (itemScores) {
			var encodeScore = function (itemScore) {
				return $elm$json$Json$Encode$object(
					$author$project$Models$ItemScore$loggingEncoder(itemScore));
			};
			var encodedList = A2($elm$json$Json$Encode$list, encodeScore, itemScores);
			return _List_fromArray(
				[
					_Utils_Tuple2('item_scores', encodedList)
				]);
		};
		switch (message.$) {
			case 0:
				var response = message.b;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'FetchLearnosityAssessmentOptionsResponse'
					});
			case 1:
				var itemScores = message.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: encodeItemScores(itemScores),
						hK: 'LearnosityAnswersStateChanged'
					});
			case 2:
				return $author$project$Logger$LogAction$LogString('LearnosityAssessmentSubmittedV2');
			case 3:
				return $author$project$Logger$LogAction$LogString('LearnosityErrorOnResubmission');
			case 4:
				return $author$project$Logger$LogAction$LogString('ValidateLearnosityAnswerV2');
			case 5:
				return $author$project$Logger$LogAction$LogString('AdvanceToNextLearnosityQuestionV2');
			case 6:
				return $author$project$Logger$LogAction$LogString('EndLearnosityAssessmentReviewV2');
			case 7:
				return $author$project$Logger$LogAction$LogString('AdvanceToFirstUnansweredLearnosityQuestion');
			case 8:
				return $author$project$Logger$LogAction$LogString('SubmitLearnosityAssessmentV2');
			case 9:
				return $author$project$Logger$LogAction$LogString('RetryLearnosityQuestionV2');
			default:
				var questId = message.a;
				var response = message.b;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _Utils_ap(
							$author$project$Logger$Utils$dataForHttpResponse(response),
							_List_fromArray(
								[
									_Utils_Tuple2(
									'quest_id',
									$author$project$Models$Ids$encoder(questId))
								])),
						hK: 'FetchQuizQuestionPreviewOptionsResponse'
					});
		}
	});
var $author$project$VideoRecording$RecordingAction$Record = 0;
var $author$project$Logger$appendEncodedRecordingCounts = function (_v0) {
	var localStorage = _v0.hz;
	var activeQuest = _v0.fZ;
	return $elm$core$Basics$append(
		function (_v1) {
			var attemptCount = _v1.gi;
			var deletedCount = _v1.gO;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'attemptCount',
					$elm$json$Json$Encode$int(attemptCount)),
					_Utils_Tuple2(
					'deletedCount',
					$elm$json$Json$Encode$int(deletedCount))
				]);
		}(
			A3(
				$krisajenkins$remotedata$RemoteData$unwrap,
				$author$project$VideoRecording$RecordingProcess$initialRecordingCounts,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.a_;
					},
					$author$project$Models$LocalStorage$Helpers$getVideoRecordingCounts(localStorage)),
				activeQuest)));
};
var $author$project$Models$WindowPostedMessage$encodeConstructGameMessageData = F2(
	function (fromIframe, windowMessage) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'web_gl_missing',
					$elm$json$Json$Encode$bool(windowMessage.dc)),
					_Utils_Tuple2(
					'web_assembly_missing',
					$elm$json$Json$Encode$bool(windowMessage.db)),
					_Utils_Tuple2(
					'js_modules_missing',
					$elm$json$Json$Encode$bool(windowMessage.cz)),
					_Utils_Tuple2(
					'from_iframe',
					$elm$json$Json$Encode$bool(fromIframe))
				]));
	});
var $author$project$Models$WindowPostedMessage$encode = function (windowMessage) {
	switch (windowMessage.$) {
		case 0:
			var studentActivityId = windowMessage.a;
			var data = windowMessage.b;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'student_activity_id',
					$author$project$Models$Ids$encoder(studentActivityId)),
					_Utils_Tuple2(
					'posted_message_source',
					$elm$json$Json$Encode$string('construct_game')),
					_Utils_Tuple2(
					'data_from_window_message',
					A2($author$project$Models$WindowPostedMessage$encodeConstructGameMessageData, true, data))
				]);
		case 1:
			var studentActivityId = windowMessage.a;
			var data = windowMessage.b;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'student_activity_id',
					$author$project$Models$Ids$encoder(studentActivityId)),
					_Utils_Tuple2(
					'posted_message_source',
					$elm$json$Json$Encode$string('construct_game')),
					_Utils_Tuple2(
					'data_from_window_message',
					A2($author$project$Models$WindowPostedMessage$encodeConstructGameMessageData, false, data))
				]);
		default:
			return _List_Nil;
	}
};
var $author$project$Models$StudentActivity$encodeRating = function (rating) {
	switch (rating) {
		case 0:
			return _Utils_Tuple2(
				'rating',
				$elm$json$Json$Encode$int(1));
		case 1:
			return _Utils_Tuple2(
				'rating',
				$elm$json$Json$Encode$int(-1));
		case 2:
			return _Utils_Tuple2(
				'rating',
				$elm$json$Json$Encode$int(0));
		default:
			return _Utils_Tuple2(
				'rating_not_applicable',
				$elm$json$Json$Encode$bool(true));
	}
};
var $author$project$VideoRecording$RecordingProcess$encodeStatusUpdate = function (statusUpdate) {
	switch (statusUpdate.$) {
		case 0:
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'status',
					$elm$json$Json$Encode$string('loading'))
				]);
		case 1:
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'status',
					$elm$json$Json$Encode$string('camera-not-available'))
				]);
		case 2:
			var error = statusUpdate.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'status',
					$elm$json$Json$Encode$string('errored')),
					_Utils_Tuple2(
					'error',
					$elm$json$Json$Encode$string(error))
				]);
		case 3:
			var numberOfCameras = statusUpdate.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'status',
					$elm$json$Json$Encode$string('ready')),
					_Utils_Tuple2(
					'numberOfCameras',
					$elm$json$Json$Encode$int(numberOfCameras))
				]);
		case 4:
			var recordingDuration = statusUpdate.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'status',
					$elm$json$Json$Encode$string('recording')),
					_Utils_Tuple2(
					'recordingDuration',
					$elm$json$Json$Encode$float(recordingDuration))
				]);
		case 5:
			var dataUrl = statusUpdate.a;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'status',
					$elm$json$Json$Encode$string('recorded')),
					_Utils_Tuple2(
					'dataUrl',
					$elm$json$Json$Encode$string(
						$author$project$Models$Urls$toString(dataUrl)))
				]);
		default:
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'status',
					$elm$json$Json$Encode$string('destroyed'))
				]);
	}
};
var $author$project$Logger$encodeStudentQuestId = function (studentQuestId) {
	return _Utils_Tuple2(
		'student_quest_id',
		$author$project$Models$Ids$encoder(studentQuestId));
};
var $author$project$CharacterSelection$log = function (msg) {
	if (!msg.$) {
		return $author$project$Logger$LogAction$DoNotLog;
	} else {
		var character = msg.a;
		return $author$project$Logger$LogAction$LogStructuredData(
			{
				gL: _List_fromArray(
					[
						_Utils_Tuple2(
						'character',
						$author$project$Models$LocalStorage$StudentChoices$encodeCharacter(character))
					]),
				hK: 'SelectStudentCharacter'
			});
	}
};
var $author$project$NpsSurvey$log = function (msg) {
	switch (msg.$) {
		case 0:
			return $author$project$Logger$LogAction$LogString('NPSUpdateSurveyAndPlayTTS');
		case 1:
			var npsSurveyResult = msg.a;
			return $author$project$Logger$LogAction$LogStructuredData(
				{
					gL: _List_fromArray(
						[
							_Utils_Tuple2(
							'nps_survey_data',
							$author$project$Models$NpsSurveyResult$encode(npsSurveyResult))
						]),
					hK: 'NpsSurveyUpdated'
				});
		case 2:
			return $author$project$Logger$LogAction$LogString('NpsSurveySkipped');
		case 3:
			var response = msg.a;
			return $author$project$Logger$LogAction$LogStructuredData(
				{
					gL: $author$project$Logger$Utils$dataForHttpResponse(response),
					hK: 'NpsSkippedResponse'
				});
		case 4:
			var npsSurveyResult = msg.a;
			return $author$project$Logger$LogAction$LogStructuredData(
				{
					gL: _List_fromArray(
						[
							_Utils_Tuple2(
							'nps_survey_data',
							$author$project$Models$NpsSurveyResult$encode(npsSurveyResult))
						]),
					hK: 'NpsSurveyUpdated'
				});
		case 5:
			var response = msg.a;
			return $author$project$Logger$LogAction$LogStructuredData(
				{
					gL: $author$project$Logger$Utils$dataForHttpResponse(response),
					hK: 'NpsSubmittedResponse'
				});
		default:
			return $author$project$Logger$LogAction$DoNotLog;
	}
};
var $author$project$VideoFeedback$log = function (msg) {
	switch (msg.$) {
		case 0:
			return $author$project$Logger$LogAction$LogString('CancelReviewing');
		case 1:
			return $author$project$Logger$LogAction$LogString('VideoPlaybackEnded');
		case 2:
			return $author$project$Logger$LogAction$LogString('VideoPlaybackErrored');
		case 3:
			return $author$project$Logger$LogAction$LogString('ReviewVideoWithFeedback');
		case 4:
			var studentQuestId = msg.a;
			return $author$project$Logger$LogAction$LogStructuredData(
				{
					gL: _List_fromArray(
						[
							_Utils_Tuple2(
							'student_quest_id',
							$author$project$Models$Ids$encoder(studentQuestId))
						]),
					hK: 'MarkStudentVideoFeedbackSeen'
				});
		case 5:
			var response = msg.a;
			return $author$project$Logger$LogAction$LogStructuredData(
				{
					gL: $author$project$Logger$Utils$dataForHttpResponse(response),
					hK: 'MarkStudentVideoFeedbackSeenResponse'
				});
		case 6:
			return $author$project$Logger$LogAction$LogString('PlayVideo');
		case 7:
			return $author$project$Logger$LogAction$LogString('RestartVideo');
		case 8:
			return $author$project$Logger$LogAction$LogString('PauseVideo');
		case 9:
			return $author$project$Logger$LogAction$DoNotLog;
		case 10:
			return $author$project$Logger$LogAction$LogString('PlaySTTSpeechFromNode');
		case 11:
			return $author$project$Logger$LogAction$DoNotLog;
		case 12:
			var nextQuest = msg.a;
			return $author$project$Logger$LogAction$LogStructuredData(
				{
					gL: _List_fromArray(
						[
							_Utils_Tuple2(
							'studentQuestId',
							$elm$json$Json$Encode$string(
								$author$project$Models$Ids$toString(nextQuest.a_))),
							_Utils_Tuple2(
							'subjectArea',
							$elm$json$Json$Encode$string(
								$author$project$Models$SubjectArea$toString(nextQuest.bp)))
						]),
					hK: 'FinishReviewing'
				});
		default:
			var portName = msg.a;
			var decodeError = msg.b;
			return $author$project$Logger$LogAction$LogStructuredData(
				{
					gL: _List_fromArray(
						[
							_Utils_Tuple2(
							'port',
							$elm$json$Json$Encode$string(portName)),
							_Utils_Tuple2(
							'error',
							A2($elm$core$Basics$composeR, $elm$json$Json$Decode$errorToString, $elm$json$Json$Encode$string)(decodeError))
						]),
					hK: 'Error decoding incoming port data'
				});
	}
};
var $author$project$StoredAppTimer$timedMessageEncoder = function (timedMessages) {
	return A2(
		$elm$json$Json$Encode$list,
		function (timedMessage) {
			return A2(
				$elm$json$Json$Encode$list,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$elm$json$Json$Encode$float(timedMessage.a),
						$elm$json$Json$Encode$string(timedMessage.b)
					]));
		},
		timedMessages);
};
var $author$project$StoredAppTimer$storedAppTimerEncoder = function (maybeAppTimer) {
	if (maybeAppTimer.$ === 1) {
		return $elm$json$Json$Encode$string('null');
	} else {
		var appTimer = maybeAppTimer.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'active',
					$elm$json$Json$Encode$bool(appTimer.ar)),
					_Utils_Tuple2(
					'studentActivityId',
					$author$project$Models$Ids$encoder(appTimer.G)),
					_Utils_Tuple2(
					'timerStartedAt',
					$elm$json$Json$Encode$float(appTimer.bq)),
					_Utils_Tuple2(
					'currentTime',
					$elm$json$Json$Encode$float(appTimer.cf)),
					_Utils_Tuple2(
					'timedMessages',
					$author$project$StoredAppTimer$timedMessageEncoder(appTimer.aF))
				]));
	}
};
var $author$project$VideoRecording$RecordingAction$toString = function (action) {
	switch (action) {
		case 0:
			return 'record';
		case 1:
			return 'stop';
		default:
			return 'flip-camera';
	}
};
var $author$project$Models$VideoPlayer$videoPlayStateToString = function (videoPlayState) {
	switch (videoPlayState.$) {
		case 0:
			return 'not_started';
		case 1:
			return 'starting_play';
		case 2:
			return 'playing';
		case 3:
			return 'paused';
		default:
			return 'ended';
	}
};
var $author$project$Logger$whatToLogLoggedInMessage = F3(
	function (msg, model, prevModel) {
		switch (msg.$) {
			case 7:
				return $author$project$Logger$LogAction$DoNotLog;
			case 8:
				return $author$project$Logger$LogAction$DoNotLog;
			case 10:
				switch (msg.b.$) {
					case 3:
						return $author$project$Logger$LogAction$LogString('HostedVideoDataRetrieved Success');
					case 2:
						return $author$project$Logger$LogAction$LogString('HostedVideoDataRetrieved Failure');
					case 0:
						var _v1 = msg.b;
						return $author$project$Logger$LogAction$DoNotLog;
					default:
						var _v2 = msg.b;
						return $author$project$Logger$LogAction$DoNotLog;
				}
			case 3:
				var studentQuestId = msg.a;
				var subjectArea = msg.b;
				var isAssignment = msg.c;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'studentQuestId',
								$elm$json$Json$Encode$string(
									$author$project$Models$Ids$toString(studentQuestId))),
								_Utils_Tuple2(
								'subjectArea',
								$elm$json$Json$Encode$string(
									$author$project$Models$SubjectArea$toString(subjectArea))),
								_Utils_Tuple2(
								'isAssignment',
								$elm$json$Json$Encode$bool(isAssignment))
							]),
						hK: 'SelectQuestToWorkOn'
					});
			case 11:
				return $author$project$Logger$LogAction$DoNotLog;
			case 19:
				return $author$project$Logger$LogAction$DoNotLog;
			case 17:
				return $author$project$Logger$LogAction$DoNotLog;
			case 18:
				return $author$project$Logger$LogAction$DoNotLog;
			case 22:
				return $author$project$Logger$LogAction$DoNotLog;
			case 9:
				var studentActivityId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId)
							]),
						hK: 'RetrieveMediaServerUrlForStudentActivity'
					});
			case 23:
				var studentActivityId = msg.a;
				var questLoadTime = msg.b;
				var currentTime = msg.c;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId),
								_Utils_Tuple2(
								'seconds_since_quest_load',
								$elm$json$Json$Encode$int(
									$elm$core$Basics$round(
										($elm$time$Time$posixToMillis(currentTime) - $elm$time$Time$posixToMillis(questLoadTime)) / 1000)))
							]),
						hK: 'HostedVideoUrlUnableToLoad'
					});
			case 20:
				return $author$project$Logger$LogAction$LogString('CompleteStudentQuest');
			case 66:
				return $author$project$Logger$LogAction$DoNotLog;
			case 56:
				var statusUpdate = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: A2(
							$author$project$Logger$appendEncodedRecordingCounts,
							model,
							$author$project$VideoRecording$RecordingProcess$encodeStatusUpdate(statusUpdate)),
						hK: 'VideoRecordingStatusUpdate'
					});
			case 57:
				var message = msg.a;
				var decodeError = msg.b;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: A2(
							$author$project$Logger$appendEncodedRecordingCounts,
							model,
							_List_fromArray(
								[
									_Utils_Tuple2(
									'decodingError',
									$elm$json$Json$Encode$string(
										$elm$json$Json$Decode$errorToString(decodeError)))
								])),
						hK: message
					});
			case 58:
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'action',
								$elm$json$Json$Encode$string(
									$author$project$VideoRecording$RecordingAction$toString(0)))
							]),
						hK: 'VideoRecordingPerformAction'
					});
			case 59:
				var action = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'action',
								$elm$json$Json$Encode$string(
									$author$project$VideoRecording$RecordingAction$toString(action)))
							]),
						hK: 'VideoRecordingPerformAction'
					});
			case 60:
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: A2($author$project$Logger$appendEncodedRecordingCounts, model, _List_Nil),
						hK: 'VideoRecordingRetry'
					});
			case 61:
				var playbackState = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'playbackState',
								$elm$json$Json$Encode$string(
									$author$project$Models$VideoPlayer$videoPlayStateToString(playbackState)))
							]),
						hK: 'VideoRecordingPlaybackChange'
					});
			case 62:
				return $author$project$Logger$LogAction$LogString('VideoRecordingPlaybackReady');
			case 74:
				return $author$project$Logger$LogAction$DoNotLog;
			case 63:
				return $author$project$Logger$LogAction$LogString('VideoRecordingPlaybackSkip');
			case 64:
				return $author$project$Logger$LogAction$LogString('VideoRecordingUploadCompleted');
			case 67:
				return $author$project$Logger$LogAction$LogString('StudentVideoRecordingDeleted');
			case 71:
				return $author$project$Logger$LogAction$LogString('ReplayRecordedStudentVideo');
			case 68:
				var response = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'DiscardDeletedStudentVideoResponse'
					});
			case 69:
				return $author$project$Logger$LogAction$LogString('IosCompleteStudentVideo');
			case 70:
				return $author$project$Logger$LogAction$LogString('WebCompleteStudentVideo');
			case 73:
				return $author$project$Logger$LogAction$LogString('CameraNotAvailableSkipStudentVideo');
			case 72:
				return $author$project$Logger$LogAction$LogString('StudentVideoRecordingReloadForPermissions');
			case 75:
				var errorString = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: A2(
							$author$project$Logger$appendEncodedRecordingCounts,
							model,
							_List_fromArray(
								[
									_Utils_Tuple2(
									'error',
									$elm$json$Json$Encode$string(errorString))
								])),
						hK: 'WebVideoRecordingErrored'
					});
			case 0:
				var npsSurveyMsg = msg.a;
				return $author$project$NpsSurvey$log(npsSurveyMsg);
			case 1:
				var characterSelectionMsg = msg.a;
				return $author$project$CharacterSelection$log(characterSelectionMsg);
			case 4:
				var mood = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'mood',
								$elm$json$Json$Encode$string(
									$author$project$Models$StudentMood$moodToString(mood)))
							]),
						hK: 'RecordStudentMood'
					});
			case 5:
				var response = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'RecordStudentMoodResponse'
					});
			case 12:
				var response = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'LoadAvailableQuestsResponse'
					});
			case 13:
				var response = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'LoadStudentQuestResponse'
					});
			case 15:
				return $author$project$Logger$LogAction$LogString('RetryLoadStudentQuest');
			case 14:
				return $author$project$Logger$LogAction$DoNotLog;
			case 16:
				return $author$project$Logger$LogAction$DoNotLog;
			case 2:
				var videoFeedbackMsg = msg.a;
				return $author$project$VideoFeedback$log(videoFeedbackMsg);
			case 21:
				var response = msg.b;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'CompleteStudentQuestResponse'
					});
			case 24:
				var studentActivityId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId)
							]),
						hK: 'StartActivityOnExternalActivityLaunch'
					});
			case 25:
				var studentActivityId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId)
							]),
						hK: 'StartRemedialActivityOnExternalActivityLaunch'
					});
			case 26:
				return $author$project$Logger$LogAction$DoNotLog;
			case 27:
				var studentActivityId = msg.a;
				var contentCompletedAt = msg.b;
				var currentTime = msg.c;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId),
								_Utils_Tuple2(
								'replayedOnDayCompleted',
								$elm$json$Json$Encode$bool(
									A3(
										$elm_community$maybe_extra$Maybe$Extra$unwrap,
										false,
										function (actualContentCompletedAt) {
											return _Utils_eq(
												A2($elm$time$Time$toDay, model.f7.fu, actualContentCompletedAt),
												A2($elm$time$Time$toDay, model.f7.fu, currentTime));
										},
										contentCompletedAt)))
							]),
						hK: 'ReplayStudentActivityOnExternalActivityLaunch'
					});
			case 28:
				return $author$project$Logger$LogAction$LogString('StudentClickedErroredWebActivity');
			case 42:
				return $author$project$Logger$LogAction$LogString('CloseWebAppIntroModal');
			case 46:
				return $author$project$Logger$LogAction$DoNotLog;
			case 47:
				return $author$project$Logger$LogAction$DoNotLog;
			case 43:
				var studentActivityId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId)
							]),
						hK: 'KeepWorkingOnActivity'
					});
			case 48:
				return $author$project$Logger$LogAction$DoNotLog;
			case 49:
				var windowMessage = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Models$WindowPostedMessage$encode(windowMessage),
						hK: 'WebActivityReceivedWindowMessage'
					});
			case 44:
				return $author$project$Logger$LogAction$LogString('DisplayWebAppIframe');
			case 45:
				return $author$project$Logger$LogAction$DoNotLog;
			case 53:
				var studentActivityId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId)
							]),
						hK: 'SetCanCompleteProgress'
					});
			case 54:
				var studentActivityId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId)
							]),
						hK: 'FlashTimerAlert'
					});
			case 29:
				var response = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'MarkStudentVideoFeedbackSeenResponse'
					});
			case 30:
				var studentActivityId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId)
							]),
						hK: 'StudentActivityTimerComplete'
					});
			case 50:
				var appTimer = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'event',
								$elm$json$Json$Encode$string('AppTimerInitialize')),
								_Utils_Tuple2(
								'appTimer',
								$author$project$StoredAppTimer$storedAppTimerEncoder(
									$elm$core$Maybe$Just(appTimer)))
							]),
						hK: 'AppTimerInitialize'
					});
			case 51:
				var appTimer = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'event',
								$elm$json$Json$Encode$string('AppTimerCallbackSetup')),
								_Utils_Tuple2(
								'appTimer',
								$author$project$StoredAppTimer$storedAppTimerEncoder(
									$elm$core$Maybe$Just(appTimer)))
							]),
						hK: 'AppTimerCallbackSetup'
					});
			case 52:
				var appTimer = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'event',
								$elm$json$Json$Encode$string('AppTimerCallbackWithCurrentTime')),
								_Utils_Tuple2(
								'appTimer',
								$author$project$StoredAppTimer$storedAppTimerEncoder(
									$elm$core$Maybe$Just(appTimer)))
							]),
						hK: 'AppTimerCallbackWithCurrentTime'
					});
			case 31:
				var studentActivityId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId)
							]),
						hK: 'VideoActivityPlaybackEnded'
					});
			case 32:
				var studentActivityId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId)
							]),
						hK: 'VideoActivityPlaybackViaEmbeddedYoutubePlayerEnded'
					});
			case 33:
				var response = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'UpdateStudentActivityVideoWatchedResponse'
					});
			case 34:
				var studentActivityId = msg.a;
				var response = msg.b;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _Utils_ap(
							$author$project$Logger$Utils$dataForHttpResponse(response),
							_List_fromArray(
								[
									$author$project$Logger$encodeStudentActivityId(studentActivityId)
								])),
						hK: 'FailPostQuizResponse'
					});
			case 36:
				var studentActivityId = msg.a;
				var response = msg.b;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _Utils_ap(
							$author$project$Logger$Utils$dataForHttpResponse(response),
							_List_fromArray(
								[
									$author$project$Logger$encodeStudentActivityId(studentActivityId)
								])),
						hK: 'ProcessPlacementQuizResponse'
					});
			case 35:
				var studentActivityId = msg.a;
				var response = msg.b;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _Utils_ap(
							$author$project$Logger$Utils$dataForHttpResponse(response),
							_List_fromArray(
								[
									$author$project$Logger$encodeStudentActivityId(studentActivityId)
								])),
						hK: 'PreQuizMasteredResponse'
					});
			case 37:
				var studentActivityId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId)
							]),
						hK: 'ActivityErrored'
					});
			case 39:
				var studentActivityId = msg.a;
				var rating = msg.b;
				var showKeepWorkingButton = msg.c;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId),
								$author$project$Models$StudentActivity$encodeRating(rating)
							]),
						hK: 'CompleteStudentActivity'
					});
			case 40:
				return $author$project$Logger$LogAction$DoNotLog;
			case 41:
				var response = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'CompleteStudentActivityResponse'
					});
			case 55:
				var response = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'FetchStudentVideoUploadUrlResponse'
					});
			case 65:
				var errorString = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'ok',
								$elm$json$Json$Encode$bool(false)),
								_Utils_Tuple2(
								'error',
								$elm$json$Json$Encode$string(errorString))
							]),
						hK: 'VideoRecordingUploadFailed'
					});
			case 76:
				var response = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'CompleteStudentVideoResponse'
					});
			case 77:
				var response = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'ErroredStudentVideoResponse'
					});
			case 78:
				var response = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'StudentVideoUploadedResponse'
					});
			case 38:
				var studentActivityId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId)
							]),
						hK: 'SkipActivity'
					});
			case 6:
				var studentActivityId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentActivityId(studentActivityId)
							]),
						hK: 'SendReturnNotification'
					});
			case 79:
				var studentQuestId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentQuestId(studentQuestId)
							]),
						hK: 'StoredStudentVideoPersisted'
					});
			case 80:
				var studentQuestIds = msg.a;
				return ($elm$core$List$length(studentQuestIds) > 0) ? $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'student_video_count',
								$elm$json$Json$Encode$int(
									$elm$core$List$length(studentQuestIds)))
							]),
						hK: 'StoredStudentVideosIdentified'
					}) : $author$project$Logger$LogAction$DoNotLog;
			case 81:
				var studentQuestId = msg.a;
				var remainingStudentQuestIds = msg.b;
				var response = msg.c;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _Utils_ap(
							_List_fromArray(
								[
									$author$project$Logger$encodeStudentQuestId(studentQuestId),
									_Utils_Tuple2(
									'remaining_student_video_count',
									$elm$json$Json$Encode$int(
										$elm$core$List$length(remainingStudentQuestIds)))
								]),
							$author$project$Logger$Utils$dataForHttpResponse(response)),
						hK: 'StoredStudentVideoFetchUploadUrlResponse'
					});
			case 82:
				var studentQuestId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentQuestId(studentQuestId)
							]),
						hK: 'StoredStudentVideoUploadComplete'
					});
			case 83:
				var studentQuestId = msg.a;
				var errorString = msg.b;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'ok',
								$elm$json$Json$Encode$bool(false)),
								_Utils_Tuple2(
								'error',
								$elm$json$Json$Encode$string(errorString)),
								$author$project$Logger$encodeStudentQuestId(studentQuestId)
							]),
						hK: 'StoredStudentVideoUploadFailed'
					});
			case 84:
				var studentQuestId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentQuestId(studentQuestId)
							]),
						hK: 'StoredStudentVideoRemoved'
					});
			case 85:
				var studentQuestId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentQuestId(studentQuestId)
							]),
						hK: 'StoredStudentVideoNotFound'
					});
			case 86:
				var studentQuestId = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								$author$project$Logger$encodeStudentQuestId(studentQuestId)
							]),
						hK: 'StoredStudentVideoCouldNotBeRetrieved'
					});
			case 87:
				var response = msg.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: $author$project$Logger$Utils$dataForHttpResponse(response),
						hK: 'StoredStudentVideoUploadedResponse'
					});
			case 88:
				return $author$project$Logger$LogAction$DoNotLog;
			case 89:
				return $author$project$Logger$LogAction$DoNotLog;
			case 90:
				return $author$project$Logger$LogAction$LogString('EsparkIframeCompleteActivity');
			case 92:
				return $author$project$Logger$LogAction$DoNotLog;
			case 91:
				return $author$project$Logger$LogAction$LogString('OpenCustomizer');
			case 94:
				return $author$project$Logger$LogAction$LogString('CloseCustomizer');
			case 93:
				return $author$project$Logger$LogAction$LogString('AccessoriesUpdate');
			case 95:
				return $author$project$Logger$LogAction$DoNotLog;
			case 96:
				return $author$project$Logger$LogAction$DoNotLog;
			case 97:
				return $author$project$Logger$LogAction$LogString('OpenClassLibrarySection');
			case 99:
				return $author$project$Logger$LogAction$DoNotLog;
			case 98:
				return $author$project$Logger$LogAction$DoNotLog;
			case 100:
				return $author$project$Logger$LogAction$DoNotLog;
			default:
				return $author$project$Logger$LogAction$DoNotLog;
		}
	});
var $author$project$Models$LoggingIn$loginErrorToString = function (err) {
	switch (err.$) {
		case 0:
			return 'UsernameError';
		case 1:
			return 'PasswordError';
		case 2:
			return 'QRCodeError';
		default:
			var httpError = err.a;
			return 'OtherError: ' + $author$project$Conversions$String$fromHttpError(httpError);
	}
};
var $author$project$Logger$whatToLogLoginMessage = F3(
	function (message, model, prevModel) {
		switch (message.$) {
			case 0:
				return $author$project$Logger$LogAction$DoNotLog;
			case 1:
				return $author$project$Logger$LogAction$DoNotLog;
			case 3:
				if (!message.a.$) {
					return $author$project$Logger$LogAction$LogString('LoginMessage PasswordLoginSuccess');
				} else {
					var err = message.a.a;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'error',
									$elm$json$Json$Encode$string(
										$author$project$Models$LoggingIn$loginErrorToString(err)))
								]),
							hK: 'LoginMessage PasswordLoginFailure'
						});
				}
			case 2:
				return $author$project$Logger$LogAction$LogString('LoginMessage SubmitManualPasswordSignin');
			case 5:
				return $author$project$Logger$LogAction$LogString('LoginMessage StartGoogleLogin');
			case 6:
				return $author$project$Logger$LogAction$LogString('LoginMessage GoToQRView');
			case 11:
				var loginRoute = message.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'url',
								$elm$json$Json$Encode$string(
									$author$project$Routes$LoginRoutes$routeToUrlString(loginRoute)))
							]),
						hK: 'LoginMessage UrlChange'
					});
			case 4:
				var url = message.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'newUrl',
								$elm$json$Json$Encode$string(url))
							]),
						hK: 'LoginMessage RedirectOnLogin'
					});
			case 8:
				return $author$project$Logger$LogAction$DoNotLog;
			case 9:
				var error = message.a;
				return $author$project$Logger$LogAction$LogStructuredData(
					{
						gL: _List_fromArray(
							[
								_Utils_Tuple2(
								'error',
								$elm$json$Json$Encode$string(error))
							]),
						hK: 'LoginMessage QRCodeError'
					});
			case 10:
				return $author$project$Logger$LogAction$LogString('LoginFromQRCode');
			case 7:
				return $author$project$Logger$LogAction$DoNotLog;
			default:
				return $author$project$Logger$LogAction$DoNotLog;
		}
	});
var $author$project$Logger$whatToLog = F3(
	function (msg, model, prevModel) {
		whatToLog:
		while (true) {
			switch (msg.$) {
				case 3:
					var loggedInRequiredMsg = msg.a;
					return A3($author$project$Logger$whatToLogLoggedInMessage, loggedInRequiredMsg, model, prevModel);
				case 15:
					var loginMsg = msg.a;
					return A3($author$project$Logger$whatToLogLoginMessage, loginMsg, model, prevModel);
				case 16:
					var joinCodeMsg = msg.a;
					return A3($author$project$Logger$whatToLogJoinCodeMessage, joinCodeMsg, model, prevModel);
				case 9:
					var learnosityMsg = msg.a;
					return A3($author$project$Logger$whatToLogLearnosityMessage, learnosityMsg, model, prevModel);
				case 1:
					return $author$project$Logger$LogAction$LogString('AppHasInitialized');
				case 2:
					return $author$project$Logger$LogAction$DoNotLog;
				case 8:
					return $author$project$Logger$LogAction$DoNotLog;
				case 44:
					return $author$project$Logger$LogAction$DoNotLog;
				case 54:
					return $author$project$Logger$LogAction$DoNotLog;
				case 0:
					return $author$project$Logger$LogAction$DoNotLog;
				case 67:
					return $author$project$Logger$LogAction$DoNotLog;
				case 68:
					return $author$project$Logger$LogAction$DoNotLog;
				case 69:
					return $author$project$Logger$LogAction$DoNotLog;
				case 11:
					return $author$project$Logger$LogAction$DoNotLog;
				case 12:
					return $author$project$Logger$LogAction$DoNotLog;
				case 7:
					return $author$project$Logger$LogAction$DoNotLog;
				case 13:
					return $author$project$Logger$LogAction$DoNotLog;
				case 14:
					return $author$project$Logger$LogAction$DoNotLog;
				case 5:
					return $author$project$Logger$LogAction$DoNotLog;
				case 55:
					return $author$project$Logger$LogAction$DoNotLog;
				case 73:
					return $author$project$Logger$LogAction$LogString('Page Reload');
				case 61:
					return $author$project$Logger$LogAction$DoNotLog;
				case 63:
					return $author$project$Logger$LogAction$DoNotLog;
				case 43:
					return $author$project$Logger$LogAction$DoNotLog;
				case 31:
					return $author$project$Logger$LogAction$DoNotLog;
				case 32:
					return $author$project$Logger$LogAction$LogString('ExternalWindowOpened');
				case 33:
					return $author$project$Logger$LogAction$DoNotLog;
				case 34:
					return $author$project$Logger$LogAction$DoNotLog;
				case 72:
					return $author$project$Logger$LogAction$DoNotLog;
				case 26:
					return $author$project$Logger$LogAction$DoNotLog;
				case 36:
					return $author$project$Logger$LogAction$LogString('ExternalWindowSuccessfullyClosed');
				case 20:
					return $author$project$Logger$LogAction$DoNotLog;
				case 78:
					return $author$project$Logger$LogAction$DoNotLog;
				case 76:
					return $author$project$Logger$LogAction$DoNotLog;
				case 81:
					return $author$project$Logger$LogAction$DoNotLog;
				case 82:
					return $author$project$Logger$LogAction$DoNotLog;
				case 86:
					return $author$project$Logger$LogAction$DoNotLog;
				case 21:
					return $author$project$Logger$LogAction$LogString('StartLogout');
				case 22:
					return $author$project$Logger$LogAction$LogString('UserLoggedOut');
				case 70:
					return $author$project$Logger$LogAction$LogString('ToggleMoodAndPlayTTS');
				case 40:
					return $author$project$Logger$LogAction$LogString('CloseModal');
				case 41:
					return $author$project$Logger$LogAction$LogString('OpenLogoutModal');
				case 42:
					return $author$project$Logger$LogAction$LogString('CloseLogoutModal');
				case 45:
					return $author$project$Logger$LogAction$LogString('PlayVideo');
				case 46:
					return $author$project$Logger$LogAction$LogString('PlayVideoAfterDelay');
				case 47:
					return $author$project$Logger$LogAction$LogString('PlayRecordedVideo');
				case 48:
					return $author$project$Logger$LogAction$LogString('PlayVideoIfUserHasInteractedWithPage');
				case 49:
					return $author$project$Logger$LogAction$LogString('RestartVideo');
				case 50:
					return $author$project$Logger$LogAction$LogString('PauseVideo');
				case 51:
					return $author$project$Logger$LogAction$LogString('VideoPlaybackEnded');
				case 52:
					var data = msg.a;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'videoKey',
									$elm$json$Json$Encode$string(data.fJ)),
									_Utils_Tuple2(
									'hasNativeVideoPlayerContainer',
									$elm$json$Json$Encode$bool(data.g8)),
									_Utils_Tuple2(
									'hasYoutubeVideoPlayer',
									$elm$json$Json$Encode$bool(data.g9))
								]),
							hK: 'VideoPlaybackErrored'
						});
				case 53:
					return $author$project$Logger$LogAction$DoNotLog;
				case 37:
					return $author$project$Logger$LogAction$LogString('IosBrowserError');
				case 38:
					return $author$project$Logger$LogAction$LogString('IosBrowserTimeout');
				case 58:
					return $author$project$Logger$LogAction$LogString('PlaySTTSpeechFromNode');
				case 59:
					return $author$project$Logger$LogAction$LogString('PlayTTSSpeechFromText');
				case 60:
					return $author$project$Logger$LogAction$LogString('PlayTTSUrl');
				case 56:
					return $author$project$Logger$LogAction$LogString('PlayAllAutoplaySpeech');
				case 57:
					return $author$project$Logger$LogAction$LogString('PlayAllAutoplayQuizContentV2');
				case 74:
					return $author$project$Logger$LogAction$LogString('YoutubeAvailable');
				case 71:
					return $author$project$Logger$LogAction$LogString('AddWebActivityInitiatedFaviconIfOnWebActivity');
				case 75:
					return $author$project$Logger$LogAction$LogString('YoutubeUnavailable');
				case 80:
					return $author$project$Logger$LogAction$LogString('ConnectToEmbeddedYoutubePlayer');
				case 83:
					return $author$project$Logger$LogAction$LogString('PlayEmbeddedYoutubePlayer');
				case 84:
					return $author$project$Logger$LogAction$LogString('PauseEmbeddedYoutubePlayer');
				case 85:
					return $author$project$Logger$LogAction$LogString('RestartEmbeddedYoutubeVideo');
				case 27:
					return $author$project$Logger$LogAction$LogString('RouteCleanupComplete');
				case 87:
					return $author$project$Logger$LogAction$LogString('EsparkIframeNavigateToActivity');
				case 4:
					var submsg = msg.a;
					var $temp$msg = submsg,
						$temp$model = model,
						$temp$prevModel = prevModel;
					msg = $temp$msg;
					model = $temp$model;
					prevModel = $temp$prevModel;
					continue whatToLog;
				case 65:
					var newPermission = msg.a;
					return (!_Utils_eq(prevModel.h1, newPermission)) ? $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'new_permission',
									$elm$json$Json$Encode$string(
										$author$project$Models$Permission$permissionToString(newPermission)))
								]),
							hK: 'NotificationPermissionsUpdate'
						}) : $author$project$Logger$LogAction$DoNotLog;
				case 66:
					var newPermission = msg.a;
					return (!_Utils_eq(prevModel.hJ, newPermission)) ? $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'new_media_capture_permission',
									$elm$json$Json$Encode$string(
										$author$project$Models$Permission$permissionToString(newPermission)))
								]),
							hK: 'MediaCapturePermissionsUpdate'
						}) : $author$project$Logger$LogAction$DoNotLog;
				case 10:
					var portName = msg.a;
					var decodeError = msg.b;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'port',
									$elm$json$Json$Encode$string(portName)),
									_Utils_Tuple2(
									'error',
									A2($elm$core$Basics$composeR, $elm$json$Json$Decode$errorToString, $elm$json$Json$Encode$string)(decodeError))
								]),
							hK: 'Error decoding incoming port data'
						});
				case 24:
					var urlRequest = msg.a;
					var _v1 = function () {
						if (!urlRequest.$) {
							var url = urlRequest.a;
							return _Utils_Tuple2(
								$elm$url$Url$toString(url),
								'internal');
						} else {
							var rawUrlString = urlRequest.a;
							return _Utils_Tuple2(rawUrlString, 'external');
						}
					}();
					var urlAsString = _v1.a;
					var destination = _v1.b;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'url',
									$elm$json$Json$Encode$string(urlAsString)),
									_Utils_Tuple2(
									'destination',
									$elm$json$Json$Encode$string(destination))
								]),
							hK: 'OnUrlRequest'
						});
				case 25:
					var url = msg.a;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'url',
									$elm$json$Json$Encode$string(
										$elm$url$Url$toString(url)))
								]),
							hK: 'OnUrlChange'
						});
				case 28:
					var route = msg.a;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'url',
									$elm$json$Json$Encode$string(
										$author$project$Routes$routeToUrlString(route)))
								]),
							hK: 'OnUrlChange'
						});
				case 6:
					return $author$project$Logger$LogAction$LogString('ChangeSubject');
				case 29:
					var appFlowNavigationDestination = msg.a;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: $author$project$AppFlow$encodeDestination(appFlowNavigationDestination),
							hK: 'NavigateToActivity'
						});
				case 30:
					var url = msg.a.fE;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'url',
									$elm$json$Json$Encode$string(
										$author$project$Models$Urls$toString(url)))
								]),
							hK: 'OpenInBrowser'
						});
				case 35:
					var url = msg.a;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'url',
									$elm$json$Json$Encode$string(
										$author$project$Models$Urls$toString(url)))
								]),
							hK: 'CloseExternalWindowByTimer'
						});
				case 18:
					var maybeString = msg.a;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'data_found',
									$elm$json$Json$Encode$bool(
										!_Utils_eq(maybeString, $elm$core$Maybe$Nothing)))
								]),
							hK: 'StoredUserCredentialsRetrieved'
						});
				case 17:
					var userId = msg.a;
					var response = msg.b;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'ok',
									$elm$json$Json$Encode$bool(
										$author$project$Logger$resultToBool(response))),
									_Utils_Tuple2(
									'student_id',
									$author$project$Models$Ids$encoder(userId))
								]),
							hK: 'AutoLoginCredentialRetrieved'
						});
				case 19:
					var response = msg.b;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'ok',
									$elm$json$Json$Encode$bool(
										$author$project$Logger$resultToBool(response))),
									_Utils_Tuple2(
									'appHasInitialized',
									$elm$json$Json$Encode$bool(model.f9))
								]),
							hK: 'ValidateStudentIdentityResponse'
						});
				case 39:
					var modalIdentifier = msg.a;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'modal',
									$elm$json$Json$Encode$string(
										$author$project$Models$Modal$modalIdentifierToString(modalIdentifier)))
								]),
							hK: 'OpenModal'
						});
				case 62:
					var time = msg.a;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'time',
									$elm$json$Json$Encode$float(time))
								]),
							hK: 'TTSTrackTimeFromStartToPlay'
						});
				case 77:
					var restrictionSource = msg.a;
					var studentActivityId = msg.b;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									$author$project$Logger$encodeStudentActivityId(studentActivityId),
									_Utils_Tuple2(
									'detected_by',
									$elm$json$Json$Encode$string(
										$author$project$Models$YoutubeVideoPlayer$restrictionSourceAsString(restrictionSource)))
								]),
							hK: 'MarkYoutubeVideoAsRestricted'
						});
				case 79:
					var time = msg.a;
					return $author$project$Logger$LogAction$LogStructuredData(
						{
							gL: _List_fromArray(
								[
									_Utils_Tuple2(
									'time',
									$elm$json$Json$Encode$float(time))
								]),
							hK: 'TrackTimeToYoutubeQualityMetric'
						});
				case 88:
					return $author$project$Logger$LogAction$DoNotLog;
				case 23:
					return $author$project$Logger$LogAction$DoNotLog;
				case 89:
					return $author$project$Logger$LogAction$DoNotLog;
				case 90:
					if (msg.a) {
						return $author$project$Logger$LogAction$LogString('LogStateOnUserBecomesKnown: appHasInitialized');
					} else {
						return $author$project$Logger$LogAction$LogString('LogStateOnUserBecomesKnown: app has not initialized');
					}
				case 91:
					return $author$project$Logger$LogAction$DoNotLog;
				default:
					return $author$project$Logger$LogAction$DoNotLog;
			}
		}
	});
var $author$project$Logger$logMessage = F4(
	function (loginInfo, msg, prevModel, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var loggingData = function () {
			var _v3 = A3($author$project$Logger$whatToLog, msg, model, prevModel);
			switch (_v3.$) {
				case 2:
					return $elm$core$Maybe$Nothing;
				case 0:
					var string = _v3.a;
					return $elm$core$Maybe$Just(
						{gL: _List_Nil, hK: string});
				default:
					var data = _v3.a;
					return $elm$core$Maybe$Just(data);
			}
		}();
		var loggingCommand = function (_v2) {
			if (loggingData.$ === 1) {
				return $elm$core$Platform$Cmd$none;
			} else {
				var message = loggingData.a.hK;
				var data = loggingData.a.gL;
				return A2(
					$author$project$Logger$log,
					model,
					{
						gL: data,
						gP: _List_fromArray(
							[0]),
						hK: message,
						jp: _List_Nil
					});
			}
		};
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmd,
						loggingCommand(loginInfo)
					])));
	});
var $author$project$Model$withLoggedInUser = F3(
	function (model, _default, fn) {
		var _v0 = model.jF;
		if (_v0.$ === 2) {
			var student = _v0.a;
			var loginInfo = _v0.b;
			return A2(fn, student, loginInfo);
		} else {
			return _default;
		}
	});
var $author$project$Main$logMessage = F3(
	function (msg, prevModel, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return A3(
			$author$project$Model$withLoggedInUser,
			model,
			_Utils_Tuple2(model, cmd),
			F2(
				function (_v1, loginInfo) {
					return A4(
						$author$project$Logger$logMessage,
						loginInfo,
						msg,
						prevModel,
						_Utils_Tuple2(model, cmd));
				}));
	});
var $author$project$EventPublisher$Event$ActivityClickstreamData = F2(
	function (activity_id, student_activity_id) {
		return {f2: activity_id, jh: student_activity_id};
	});
var $author$project$AppFlow$ActivityWithId = function (a) {
	return {$: 5, a: a};
};
var $author$project$Effect$ChangeFavicon = function (a) {
	return {$: 38, a: a};
};
var $author$project$Effect$CheckForNewVersionAvailabilityEffect = function (a) {
	return {$: 7, a: a};
};
var $author$project$Messages$ConnectToEmbeddedYoutubePlayer = F2(
	function (a, b) {
		return {$: 80, a: a, b: b};
	});
var $author$project$Effect$ConnectToEmbeddedYoutubePlayerEffect = F2(
	function (a, b) {
		return {$: 34, a: a, b: b};
	});
var $author$project$NewVersionAvailability$Delayed = 1;
var $author$project$Models$VideoPlayer$EmbeddedYoutube = 1;
var $author$project$Effect$GetCurrentTimeEffect = function (a) {
	return {$: 37, a: a};
};
var $author$project$Effect$HideNotificationEffect = {$: 49};
var $author$project$Effect$LoadUrlEffect = function (a) {
	return {$: 3, a: a};
};
var $author$project$Models$User$LoggedIn = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$LoginApp$Messages$MediaPermissionsUpdate = function (a) {
	return {$: 7, a: a};
};
var $author$project$Models$Modal$MoodRecorderOverlay = function (a) {
	return {$: 7, a: a};
};
var $author$project$EventPublisher$Event$MoodSelectedClickstreamData = function (mood_id) {
	return {hO: mood_id};
};
var $author$project$Models$VideoPlayer$Paused = function (a) {
	return {$: 3, a: a};
};
var $author$project$Messages$PlayTTSSpeechFromText = function (a) {
	return {$: 59, a: a};
};
var $author$project$Messages$PlayVideoAfterDelay = function (a) {
	return {$: 46, a: a};
};
var $author$project$Effect$PublishEventEffect = F3(
	function (a, b, c) {
		return {$: 63, a: a, b: b, c: c};
	});
var $author$project$LoggedIn$Messages$PublishEventTimestamped = F2(
	function (a, b) {
		return {$: 95, a: a, b: b};
	});
var $author$project$Messages$RecordNavigationTimestamp = function (a) {
	return {$: 26, a: a};
};
var $author$project$Effect$ReloadPageEffect = {$: 5};
var $author$project$AppConfiguration$RemoteConnectionActivated = function (a) {
	return {$: 1, a: a};
};
var $author$project$Effect$SetHeartbeatDataEffect = {$: 64};
var $author$project$Models$VideoPlayer$StartingPlay = function (a) {
	return {$: 1, a: a};
};
var $author$project$EventPublisher$Event$StudentClickStreamClickChangeSubject = {$: 4};
var $author$project$EventPublisher$Event$StudentClickstreamClickActivity = function (a) {
	return {$: 12, a: a};
};
var $author$project$EventPublisher$Event$StudentClickstreamCloseWebappActivityTab = function (a) {
	return {$: 1, a: a};
};
var $author$project$EventPublisher$Event$StudentClickstreamData = F2(
	function (thirdPartyContentUrl, loadedInIframe) {
		return {er: loadedInIframe, fs: thirdPartyContentUrl};
	});
var $author$project$EventPublisher$Event$StudentClickstreamInstructionBarNext = function (a) {
	return {$: 2, a: a};
};
var $author$project$EventPublisher$Event$StudentClickstreamMoodSelected = function (a) {
	return {$: 16, a: a};
};
var $author$project$Models$YoutubeVideoPlayer$TimedOutWithoutPlay = 0;
var $author$project$Messages$UpdateCancelledByDisabledClick = function (a) {
	return {$: 5, a: a};
};
var $author$project$Effect$UpdateEsparkIframeEffect = F2(
	function (a, b) {
		return {$: 56, a: a, b: b};
	});
var $krisajenkins$remotedata$RemoteData$isSuccess = function (data) {
	if (data.$ === 3) {
		var x = data.a;
		return true;
	} else {
		return false;
	}
};
var $author$project$Models$ActiveQuest$activeQuestIsLoaded = $krisajenkins$remotedata$RemoteData$isSuccess;
var $author$project$Helpers$RecordFinders$findStudentActivity = function (studentActivityId) {
	return A2(
		$krisajenkins$remotedata$RemoteData$unwrap,
		$elm$core$Maybe$Nothing,
		$author$project$Helpers$RecordFinders$findStudentActivityFromActiveQuest(studentActivityId));
};
var $author$project$Routes$activityRouteHasWebActivity = F2(
	function (route, activeQuest) {
		if ($author$project$Models$ActiveQuest$activeQuestIsLoaded(activeQuest)) {
			if (route.$ === 8) {
				var activityRoute = route.a;
				return A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					false,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.f_;
						},
						$author$project$Models$Activity$isWebApp),
					A2(
						$author$project$Helpers$RecordFinders$findStudentActivity,
						$author$project$Routes$ActivityRoutes$studentActivityId(activityRoute),
						activeQuest));
			} else {
				return false;
			}
		} else {
			return false;
		}
	});
var $author$project$Models$ActivityWindowState$ExternalWindowClosed = function (a) {
	return {$: 2, a: a};
};
var $author$project$Models$ActivityWindowState$activityWindowClosed = function (studentActivityId) {
	return $author$project$Models$ActivityWindowState$ExternalWindowClosed(studentActivityId);
};
var $author$project$Models$ActivityWindowState$ExternalWindowOpen = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Models$ActivityWindowState$activityWindowOpened = F2(
	function (openTime, studentActivityId) {
		return A2($author$project$Models$ActivityWindowState$ExternalWindowOpen, studentActivityId, openTime);
	});
var $author$project$Effect$add = F2(
	function (someEffect, anotherEffect) {
		return $author$project$Effect$BatchedEffect(
			_List_fromArray(
				[someEffect, anotherEffect]));
	});
var $author$project$Models$YoutubeVideoPlayer$YoutubeIsAvailable = function (a) {
	return {$: 1, a: a};
};
var $author$project$Models$YoutubeVideoPlayer$mapYoutubeAvailable = F2(
	function (fn, youtubeStatus) {
		switch (youtubeStatus.$) {
			case 1:
				var restrictedIds = youtubeStatus.a;
				return fn(restrictedIds);
			case 2:
				var _v1 = youtubeStatus;
				return youtubeStatus;
			default:
				var _v2 = youtubeStatus;
				return youtubeStatus;
		}
	});
var $author$project$Models$YoutubeVideoPlayer$addRestrictedVideo = F2(
	function (youtubeStatus, youtubeId) {
		return A2(
			$author$project$Models$YoutubeVideoPlayer$mapYoutubeAvailable,
			function (restrictedIds) {
				return $author$project$Models$YoutubeVideoPlayer$YoutubeIsAvailable(
					A2($elm$core$List$cons, youtubeId, restrictedIds));
			},
			youtubeStatus);
	});
var $author$project$Effect$ResetTTSEffect = {$: 42};
var $author$project$InteractionSound$returnToEspark = require('/usr/src/app/app/sounds/return-to-espark.mp3');
var $author$project$Helpers$WebAppsHelper$closeExternalWindowCmd = function (url) {
	return $elm$core$Platform$Cmd$batch(
		_List_fromArray(
			[
				$author$project$Ports$closeExternalWindow(
				$author$project$Models$Urls$toString(url)),
				$author$project$Ports$stopInteractionSound(
				$author$project$InteractionSound$src($author$project$InteractionSound$returnToEspark))
			]));
};
var $elm$browser$Browser$Dom$setViewport = _Browser_setViewport;
var $author$project$Helpers$BeforeNavigationHelper$beforeNavigation = F2(
	function (requestedPath, model) {
		var closeExternalTabCmd = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				$author$project$Helpers$WebAppsHelper$closeExternalWindowCmd,
				A2(
					$elm$core$Maybe$andThen,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.f_;
						},
						$author$project$Models$Activity$webAppUrl),
					$author$project$Model$studentActivityForCurrentRoute(model))));
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{hC: false}),
			$author$project$Effect$batch(
				_List_fromArray(
					[
						$author$project$Effect$UpdateUrlEffect(requestedPath),
						$author$project$Effect$ResetTTSEffect,
						$author$project$Effect$CmdEffect(closeExternalTabCmd),
						$author$project$Effect$CmdEffect(
						A2(
							$elm$core$Task$attempt,
							function (_v0) {
								return $author$project$Messages$NoOp;
							},
							A2($elm$browser$Browser$Dom$setViewport, 0, 0)))
					])));
	});
var $author$project$Effect$ChangeWindowTitle = function (a) {
	return {$: 39, a: a};
};
var $author$project$Helpers$WebAppsHelper$esparkDefaultTitle = 'eSpark Student App';
var $author$project$Helpers$WebAppsHelper$changeWindowTitleToDefaultTitleEffect = $author$project$Effect$ChangeWindowTitle($author$project$Helpers$WebAppsHelper$esparkDefaultTitle);
var $author$project$Effect$CloseExternalWindowEffect = function (a) {
	return {$: 28, a: a};
};
var $author$project$Effect$StopInteractionSoundEffect = function (a) {
	return {$: 31, a: a};
};
var $author$project$Helpers$WebAppsHelper$closeExternalWindow = function (url) {
	return $author$project$Effect$batch(
		_List_fromArray(
			[
				$author$project$Effect$CloseExternalWindowEffect(url),
				$author$project$Effect$StopInteractionSoundEffect($author$project$InteractionSound$returnToEspark)
			]));
};
var $author$project$Effect$convertToEffect = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	return _Utils_eq(cmd, $elm$core$Platform$Cmd$none) ? _Utils_Tuple2(model, $author$project$Effect$NoEffect) : _Utils_Tuple2(
		model,
		$author$project$Effect$CmdEffect(cmd));
};
var $author$project$TextToSpeech$defaultQuerySelector = '.main ttgspan[data-audio-auto-play]';
var $author$project$EventPublisher$Event$StudentClickstreamLogout = {$: 15};
var $author$project$Routes$LoginRoutes$default = $author$project$Routes$LoginRoutes$PasswordLoginRoute(0);
var $author$project$Routes$defaultLoginRoute = $author$project$Routes$LoginRoute($author$project$Routes$LoginRoutes$default);
var $author$project$Ports$exitQuizV2 = _Platform_outgoingPort(
	'exitQuizV2',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Helpers$CommandHelper$exitQuizCleanup = $author$project$Ports$exitQuizV2(0);
var $author$project$Routes$isAuthenticationRoute = function (route) {
	switch (route.$) {
		case 1:
			return true;
		case 11:
			return true;
		case 12:
			return true;
		default:
			return false;
	}
};
var $author$project$Helpers$CommandHelper$logEventCmd = function (event) {
	return $author$project$Helpers$Task$getCurrentTime(
		A2(
			$elm$core$Basics$composeR,
			$author$project$LoggedIn$Messages$PublishEventTimestamped(event),
			$author$project$Messages$LoggedInRequiredMsg));
};
var $author$project$Helpers$CommandHelper$ensureUserLoggedOut = F2(
	function (fromUserAction, _v0) {
		var currentModel = _v0.a;
		var existingCommand = _v0.b;
		var updatedModel = $author$project$Model$resetUserState(currentModel);
		var _v1 = $author$project$Routes$isAuthenticationRoute(updatedModel.eY) ? _Utils_Tuple2(updatedModel.eY, $elm$core$Platform$Cmd$none) : _Utils_Tuple2(
			$author$project$Routes$defaultLoginRoute,
			A2(
				$author$project$Helpers$NavigationHelper$pushUrl,
				updatedModel.hR,
				$author$project$Routes$routeToUrlString($author$project$Routes$defaultLoginRoute)));
		var nextRoute = _v1.a;
		var routeCmd = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				updatedModel,
				{hV: nextRoute, eY: nextRoute}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Ports$signOut(fromUserAction),
						$author$project$Helpers$CommandHelper$exitQuizCleanup,
						existingCommand,
						routeCmd,
						$author$project$Helpers$CommandHelper$logEventCmd($author$project$EventPublisher$Event$StudentClickstreamLogout)
					])));
	});
var $author$project$Api$RequestHelper$errorAsString = function (err) {
	switch (err.$) {
		case 1:
			return 'Timeout exceeded';
		case 2:
			return 'Network error';
		case 3:
			var status = err.a;
			return 'Bad status: ' + $elm$core$String$fromInt(status);
		case 4:
			var msg = err.a;
			return 'Unexpected response: ' + msg;
		default:
			var url = err.a;
			return 'Bad url: ' + url;
	}
};
var $author$project$Assets$Favicon$Static = function (a) {
	return {$: 0, a: a};
};
var $author$project$Assets$Favicon$esparkDefault = $author$project$Assets$Favicon$Static(
	{Q: require('/usr/src/app/public/favicon-16x16.png'), T: require('/usr/src/app/public/favicon-16x16.png')});
var $author$project$Effect$TriggerExternalWindowCloseEffect = function (a) {
	return {$: 27, a: a};
};
var $author$project$Models$ActivityWindowState$activityWindowTimestamp = F2(
	function (studentActivityId, activityWindowState) {
		switch (activityWindowState.$) {
			case 1:
				var windowStudentActivityId = activityWindowState.a;
				var openTime = activityWindowState.b;
				return _Utils_eq(windowStudentActivityId, studentActivityId) ? $elm$core$Maybe$Just(openTime) : $elm$core$Maybe$Nothing;
			case 2:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Helpers$WebAppsHelper$timerDataMatchesCurrentData = F3(
	function (activityWindowState, studentActivityId, openTime) {
		return _Utils_eq(
			A2($author$project$Models$ActivityWindowState$activityWindowTimestamp, studentActivityId, activityWindowState),
			$elm$core$Maybe$Just(openTime));
	});
var $author$project$Helpers$WebAppsHelper$evaluateForAutoClose = F4(
	function (url, openTime, activityWindowState, targetStudentActivityId) {
		return A3($author$project$Helpers$WebAppsHelper$timerDataMatchesCurrentData, activityWindowState, targetStudentActivityId, openTime) ? $author$project$Effect$TriggerExternalWindowCloseEffect(url) : $author$project$Effect$NoEffect;
	});
var $author$project$Effect$PlayInteractionSoundEffect = function (a) {
	return {$: 30, a: a};
};
var $author$project$Assets$Favicon$Dynamic = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Assets$Favicon$FrameRate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Assets$Favicon$Infinite = {$: 1};
var $author$project$Assets$Favicon$webActivityTimerEnding = A3(
	$author$project$Assets$Favicon$Dynamic,
	_List_fromArray(
		[
			{Q: require('/usr/src/app/app/images/favicons/web-activity-timer-ending-1@2x.png'), T: require('/usr/src/app/app/images/favicons/web-activity-timer-ending-1.png')},
			{Q: require('/usr/src/app/app/images/favicons/web-activity-timer-ending-2@2x.png'), T: require('/usr/src/app/app/images/favicons/web-activity-timer-ending-2.png')}
		]),
	$author$project$Assets$Favicon$FrameRate(400),
	$author$project$Assets$Favicon$Infinite);
var $author$project$Helpers$WebAppsHelper$evaluateForReturnPrompts = F3(
	function (openTime, activityWindowState, targetStudentActivityId) {
		return A3($author$project$Helpers$WebAppsHelper$timerDataMatchesCurrentData, activityWindowState, targetStudentActivityId, openTime) ? $author$project$Effect$batch(
			_List_fromArray(
				[
					$author$project$Effect$ChangeWindowTitle('Time\'s Up!'),
					$author$project$Effect$ChangeFavicon($author$project$Assets$Favicon$webActivityTimerEnding),
					$author$project$Effect$PlayInteractionSoundEffect($author$project$InteractionSound$returnToEspark)
				])) : $author$project$Effect$NoEffect;
	});
var $author$project$Ports$componentRemove = _Platform_outgoingPort('componentRemove', $elm$json$Json$Encode$string);
var $author$project$Helpers$CommandHelper$exitComponentCleanup = function (oldRoute) {
	if ((oldRoute.$ === 8) && (oldRoute.a.$ === 3)) {
		var _v1 = oldRoute.a;
		var componentType = _v1.c;
		var data = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'componentType',
					$elm$json$Json$Encode$string(
						$author$project$Models$Activity$componentTypeToString(componentType)))
				]));
		return $author$project$Ports$componentRemove(
			A2($elm$json$Json$Encode$encode, 0, data));
	} else {
		return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Models$Ids$findByIdFunc = F2(
	function (idFunc, id) {
		return $elm_community$list_extra$List$Extra$find(
			A2(
				$elm$core$Basics$composeR,
				idFunc,
				$author$project$Models$Ids$idEquals(id)));
	});
var $author$project$ErrorHandling$handleHttpError = F2(
	function (model, httpError) {
		switch (httpError.$) {
			case 2:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				if (httpError.a === 401) {
					return A3(
						$author$project$Model$withLoggedInUser,
						model,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						F2(
							function (_v1, _v2) {
								return _Utils_Tuple2(
									$author$project$Model$resetUserState(model),
									A3(
										$author$project$Helpers$NavigationHelper$updateUrl,
										model.hR,
										model.f7,
										$author$project$Routes$routeToUrlString(
											$author$project$Routes$LoginRoute(
												$author$project$Routes$LoginRoutes$PasswordLoginRoute(0)))));
							}));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$ErrorHandling$logHttpError = F4(
	function (student, model, httpError, actionDescription) {
		var logError = F2(
			function (message, sendToSentry) {
				return A2(
					$author$project$Logger$log,
					model,
					{
						gL: _List_Nil,
						gP: sendToSentry ? _List_fromArray(
							[1, 0]) : _List_fromArray(
							[0]),
						hK: message,
						jp: _List_fromArray(
							[
								_Utils_Tuple2(
								'action',
								$elm$json$Json$Encode$string(actionDescription))
							])
					});
			});
		switch (httpError.$) {
			case 2:
				return A2(logError, 'Http Network Error', true);
			case 1:
				return A2(logError, 'Http Timeout', true);
			case 3:
				if (httpError.a === 401) {
					return A2(logError, 'Http Not Authenticated', false);
				} else {
					var statusCode = httpError.a;
					return A2(
						logError,
						$elm$core$String$fromInt(statusCode) + ' Bad Status',
						true);
				}
			case 0:
				return A2(logError, 'Http Unknown Error', true);
			default:
				return A2(logError, 'Http Unknown Error', true);
		}
	});
var $author$project$ErrorHandling$handleHttpErrorLoggedIn = F4(
	function (student, model, httpError, actionDescription) {
		var addLoggingCmds = function (cmds) {
			return $elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmds,
						A4($author$project$ErrorHandling$logHttpError, student, model, httpError, actionDescription)
					]));
		};
		return A2(
			$elm$core$Tuple$mapSecond,
			addLoggingCmds,
			A2($author$project$ErrorHandling$handleHttpError, model, httpError));
	});
var $author$project$ErrorHandling$handleIncomingPortError = F4(
	function (student, model, errorString, actionDescription) {
		return _Utils_Tuple2(
			model,
			A2(
				$author$project$Logger$log,
				model,
				{
					gL: _List_fromArray(
						[
							_Utils_Tuple2(
							'error',
							$elm$json$Json$Encode$string(errorString))
						]),
					gP: _List_fromArray(
						[1]),
					hK: 'Incoming Port Error',
					jp: _List_fromArray(
						[
							_Utils_Tuple2(
							'port',
							$elm$json$Json$Encode$string(actionDescription))
						])
				}));
	});
var $author$project$Models$StudentActivity$setProgressErrored = function (studentActivity) {
	return _Utils_update(
		studentActivity,
		{ir: $author$project$Models$StudentActivity$ErroredStudentActivity});
};
var $author$project$Models$StudentActivity$mapList = F2(
	function (mapper, record) {
		return _Utils_update(
			record,
			{
				jd: mapper(record.jd)
			});
	});
var $elm_community$list_extra$List$Extra$updateIf = F3(
	function (predicate, update, list) {
		return A2(
			$elm$core$List$map,
			function (item) {
				return predicate(item) ? update(item) : item;
			},
			list);
	});
var $author$project$Models$Ids$updateIfId = F2(
	function (id, updateFn) {
		return A2(
			$elm_community$list_extra$List$Extra$updateIf,
			$author$project$Models$Ids$idEquals(id),
			updateFn);
	});
var $author$project$Model$updateStudentActivityById = F3(
	function (studentActivityId, updateFn, model) {
		var mapper = $author$project$Models$StudentActivity$mapList(
			A2($author$project$Models$Ids$updateIfId, studentActivityId, updateFn));
		return _Utils_update(
			model,
			{
				fZ: A2($krisajenkins$remotedata$RemoteData$map, mapper, model.fZ)
			});
	});
var $author$project$Model$markStudentActivityErrored = function (studentActivityId) {
	return A2($author$project$Model$updateStudentActivityById, studentActivityId, $author$project$Models$StudentActivity$setProgressErrored);
};
var $author$project$Helpers$CommandHelper$withValidatedStudentActivity = F2(
	function (model, resultFunction) {
		var _v0 = _Utils_Tuple2(model.eY, model.fZ);
		if (((_v0.a.$ === 8) && (_v0.a.a.$ === 1)) && (_v0.b.$ === 3)) {
			var _v1 = _v0.a.a;
			var studentActivityId = _v1.b;
			var activeQuest = _v0.b.a;
			var _v2 = A2($author$project$Helpers$RecordFinders$findStudentActivity, studentActivityId, model.fZ);
			if (_v2.$ === 1) {
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			} else {
				var aStudentActivity = _v2.a;
				return A2(resultFunction, aStudentActivity, activeQuest);
			}
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Helpers$CommandHelper$markActivityErrored = function (model) {
	return A2(
		$author$project$Helpers$CommandHelper$withValidatedStudentActivity,
		model,
		F2(
			function (studentActivity, _v0) {
				return _Utils_Tuple2(
					A2($author$project$Model$markStudentActivityErrored, studentActivity.hj, model),
					A2(
						$author$project$Helpers$Task$performWithDelay,
						1000,
						$author$project$Messages$PlayAllAutoplaySpeech($elm$core$Maybe$Nothing)));
			}));
};
var $author$project$Main$handleIosBrowserProblem = function (model) {
	return $author$project$Models$ActiveQuest$activeQuestIsLoaded(model.fZ) ? $author$project$Effect$convertToEffect(
		$author$project$Helpers$CommandHelper$markActivityErrored(model)) : _Utils_Tuple2(
		_Utils_update(
			model,
			{df: true}),
		$author$project$Effect$NoEffect);
};
var $author$project$Effect$UserJoinNewSectionEffect = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$Effect$UserLoginWithJoinCodeEffect = function (a) {
	return {$: 10, a: a};
};
var $author$project$Messages$LogStateOnUserBecomesKnown = function (a) {
	return {$: 90, a: a};
};
var $author$project$Messages$SetYoutubeToUnavailableIfNotKnown = {$: 76};
var $author$project$EventPublisher$Event$StudentClickstreamLogin = {$: 14};
var $author$project$Models$Modal$CharacterSelectionOverlay = function (a) {
	return {$: 8, a: a};
};
var $author$project$Models$Modal$NpsSurveyOverlay = function (a) {
	return {$: 9, a: a};
};
var $author$project$EventPublisher$Event$StudentClickstreamOpenCharacterSelection = {$: 17};
var $author$project$CharacterSelection$NoneSelected = {$: 0};
var $author$project$Ports$listenForPLEUpdates = _Platform_outgoingPort(
	'listenForPLEUpdates',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$CharacterSelection$init = function (productFeatures) {
	return _Utils_Tuple2(
		{v: $author$project$CharacterSelection$NoneSelected},
		$author$project$Ports$listenForPLEUpdates(0));
};
var $author$project$Models$NpsSurveyResult$NotSelected = 0;
var $author$project$Models$NpsSurveyResult$init = function (id) {
	return {hj: id, cV: '', e4: 0};
};
var $author$project$NpsSurvey$init = function (npsSurveyId) {
	return {
		h3: $author$project$Models$NpsSurveyResult$init(npsSurveyId),
		ak: $krisajenkins$remotedata$RemoteData$NotAsked
	};
};
var $author$project$Helpers$InitialSessionFlow$checkForUpdates = F3(
	function (_v0, _v1, _v2) {
		var npsSurveyId = _v0.h2;
		var recordedMoodToday = _v0.iz;
		var appFlowMode = _v0.f8;
		var productFeatures = _v0.iq;
		var currentModel = _v2.a;
		var currentCommands = _v2.b;
		var updateIfNeeded = F2(
			function (sessionFlowUpdate, _v10) {
				var model = _v10.a;
				var commands = _v10.b;
				return (_Utils_eq(model, currentModel) && _Utils_eq(commands, currentCommands)) ? sessionFlowUpdate(
					_Utils_Tuple2(model, commands)) : _Utils_Tuple2(model, commands);
			});
		var npsSurveyUpdates = function (_v9) {
			var model = _v9.a;
			var commands = _v9.b;
			if (!npsSurveyId.$) {
				var anNpsSurveyId = npsSurveyId.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $author$project$Models$Modal$Open(
								$author$project$Models$Modal$NpsSurveyOverlay(
									$author$project$NpsSurvey$init(anNpsSurveyId)))
						}),
					commands);
			} else {
				return _Utils_Tuple2(model, commands);
			}
		};
		var moodRecorderUpdates = function (_v7) {
			var model = _v7.a;
			var commands = _v7.b;
			return (!recordedMoodToday) ? _Utils_Tuple2(
				_Utils_update(
					model,
					{
						hM: $author$project$Models$Modal$Open(
							$author$project$Models$Modal$MoodRecorderOverlay($elm$core$Maybe$Nothing))
					}),
				commands) : _Utils_Tuple2(model, commands);
		};
		var characterSelectionUpdates = function (_v6) {
			var model = _v6.a;
			var commands = _v6.b;
			var logOpenEventCmd = $author$project$Helpers$Task$getCurrentTime(
				A2(
					$elm$core$Basics$composeR,
					$author$project$LoggedIn$Messages$PublishEventTimestamped($author$project$EventPublisher$Event$StudentClickstreamOpenCharacterSelection),
					$author$project$Messages$LoggedInRequiredMsg));
			var _v3 = $author$project$CharacterSelection$init(productFeatures);
			var characterSelectionModel = _v3.a;
			var characterSelectionCmds = _v3.b;
			var _v4 = _Utils_Tuple2(
				appFlowMode,
				A2($author$project$Models$LocalStorage$Helpers$getStudentRecentCharacter, model.f7.bv, model.hz));
			if ((_v4.a.$ === 1) && (_v4.b.$ === 1)) {
				var _v5 = _v4.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $author$project$Models$Modal$Open(
								$author$project$Models$Modal$CharacterSelectionOverlay(characterSelectionModel))
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								commands,
								logOpenEventCmd,
								A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$CharacterSelectionMsg, $author$project$Messages$LoggedInRequiredMsg),
								characterSelectionCmds)
							])));
			} else {
				return _Utils_Tuple2(model, commands);
			}
		};
		return A2(
			updateIfNeeded,
			moodRecorderUpdates,
			A2(
				updateIfNeeded,
				characterSelectionUpdates,
				A2(
					updateIfNeeded,
					npsSurveyUpdates,
					_Utils_Tuple2(currentModel, currentCommands))));
	});
var $author$project$Ports$enableHotjarTracking = _Platform_outgoingPort(
	'enableHotjarTracking',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$int(a),
					$elm$json$Json$Encode$int(b)
				]));
	});
var $author$project$Models$ActiveQuest$isComplete = function (includeVideoRecording) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Models$ActiveQuest$questStatus(includeVideoRecording),
		$elm$core$Basics$eq(2));
};
var $author$project$Routing$Updates$ensureQuestStateAllowsRoute = F3(
	function (newRoute, student, _v0) {
		var model = _v0.a;
		var existingEffects = _v0.b;
		if ((newRoute.$ === 7) && (newRoute.a.$ === 2)) {
			var videoRecordingPermitted = A2($author$project$Helpers$VideoRecordingPermission$videoRecordingPermitted, model.f7.ii, student.iq);
			return _Utils_Tuple2(
				model,
				A3(
					$krisajenkins$remotedata$RemoteData$unwrap,
					existingEffects,
					function (activeQuest) {
						return A2($author$project$Models$ActiveQuest$isComplete, videoRecordingPermitted, activeQuest) ? existingEffects : $author$project$Effect$UpdateUrlEffect(
							$author$project$Routes$routeToUrlString(
								$author$project$Routes$QuestRoute(
									$author$project$Routes$QuestRoutes$Home(activeQuest.a_))));
					},
					model.fZ));
		} else {
			return _Utils_Tuple2(model, existingEffects);
		}
	});
var $author$project$LoggedIn$Messages$CheckIfAvailableQuestsAreStillLoading = {$: 14};
var $author$project$LoggedIn$Messages$LoadClassLibraryModel = function (a) {
	return {$: 98, a: a};
};
var $author$project$Effect$StartVideoRecordingEffect = F2(
	function (a, b) {
		return {$: 16, a: a, b: b};
	});
var $author$project$Helpers$QuizPreviewDummyData$quest = {
	hj: $author$project$Models$Ids$fromInt(-1),
	hr: false,
	iv: 5,
	iw: false,
	i0: 'Preview some quiz questions!',
	bp: 0,
	fx: 'Quiz Question Preview',
	jK: '',
	jL: 'N/A'
};
var $author$project$Helpers$QuizPreviewDummyData$activeQuest = {
	dq: false,
	du: false,
	eq: $elm$time$Time$millisToPosix(0),
	es: false,
	it: $author$project$Helpers$QuizPreviewDummyData$quest,
	jd: _List_fromArray(
		[$author$project$Helpers$QuizPreviewDummyData$studentActivity]),
	a_: $author$project$Models$Ids$fromInt(-1),
	jg: $elm$core$Maybe$Nothing
};
var $author$project$Helpers$QuizPreviewDummyData$contentActivity = {
	f0: $author$project$Models$Activity$Challenge,
	dl: false,
	dE: 2,
	dG: $elm$core$Maybe$Nothing,
	d6: $elm$core$Maybe$Nothing,
	hj: $author$project$Models$Ids$fromInt(-1),
	ea: $author$project$Models$Activity$NoInstructions,
	em: $elm$core$Maybe$Nothing,
	hG: 7,
	ij: 2,
	cQ: $author$project$Models$Ids$fromInt(-1),
	e5: $author$project$Models$Urls$fromString('fooabr'),
	e9: 1,
	fx: 'CTC Preview Activity'
};
var $author$project$Helpers$QuizPreviewDummyData$studentActivityForChallenge = {
	f_: $author$project$Helpers$QuizPreviewDummyData$contentActivity,
	gd: $elm$core$Maybe$Nothing,
	dP: 1,
	hj: $author$project$Models$Ids$fromInt(-1),
	hs: false,
	ir: $author$project$Models$StudentActivity$ActiveStudentActivity(
		A2(
			$author$project$Models$StudentActivity$ContentActivity,
			$author$project$Models$StudentActivity$CompletedContent(
				$elm$time$Time$millisToPosix(0)),
			$author$project$Models$StudentActivity$HasAssessment($author$project$Models$AssessmentStatus$NotCurrentlyTakingAssessment))),
	a_: $author$project$Models$Ids$fromInt(-1),
	fL: $author$project$Models$StudentActivity$None
};
var $author$project$Helpers$QuizPreviewDummyData$activeQuestReadyToStartChallenge = {
	dq: false,
	du: false,
	eq: $elm$time$Time$millisToPosix(0),
	es: false,
	it: $author$project$Helpers$QuizPreviewDummyData$quest,
	jd: _List_fromArray(
		[$author$project$Helpers$QuizPreviewDummyData$studentActivityForChallenge]),
	a_: $author$project$Models$Ids$fromInt(-1),
	jg: $elm$core$Maybe$Nothing
};
var $author$project$Ports$componentShow = _Platform_outgoingPort('componentShow', $elm$json$Json$Encode$string);
var $author$project$LoggedIn$Messages$LoadAvailableQuestsResponse = function (a) {
	return {$: 12, a: a};
};
var $author$project$Models$AvailableQuest$AvailableQuestData = F7(
	function (subjectAreas, availableQuests, videoFeedbackQuests, assignments, readingQuest, mathQuest, classLibraries) {
		return {gh: assignments, go: availableQuests, gF: classLibraries, cB: mathQuest, cS: readingQuest, jj: subjectAreas, c7: videoFeedbackQuests};
	});
var $author$project$Models$AvailableQuest$AdaptivePathQuest = F2(
	function (studentQuest, feedback) {
		return {cs: feedback, jf: studentQuest};
	});
var $author$project$Models$AvailableQuest$AvailableStudentQuest = F5(
	function (subjectArea, studentQuestId, quest, studentVideo, thumbnailUrl) {
		return {it: quest, a_: studentQuestId, jg: studentVideo, bp: subjectArea, c2: thumbnailUrl};
	});
var $author$project$Models$Quest$Quest = F9(
	function (id, title, shortDescription, videoPromptText, videoPromptAudioOverride, questionCount, isPlacement, quizRequiresAudio, subjectArea) {
		return {hj: id, hr: isPlacement, iv: questionCount, iw: quizRequiresAudio, i0: shortDescription, bp: subjectArea, fx: title, jK: videoPromptAudioOverride, jL: videoPromptText};
	});
var $author$project$Models$SubjectArea$Writing = 2;
var $author$project$Models$SubjectArea$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (subjectArea) {
		switch (subjectArea) {
			case 'Mathematics':
				return $elm$json$Json$Decode$succeed(0);
			case 'Reading':
				return $elm$json$Json$Decode$succeed(1);
			case 'Language':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Invalid subject area ' + subjectArea);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Models$Quest$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'subject_area',
	$author$project$Models$SubjectArea$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'quiz_requires_audio',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'placement_quiz',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'question_count',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'audio_override',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'student_video_prompt_text',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'short_description',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'title',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'id',
									$author$project$Models$Ids$decoder,
									$elm$json$Json$Decode$succeed($author$project$Models$Quest$Quest))))))))));
var $author$project$Models$AvailableQuest$availableStudentQuestDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'thumbnail_url',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'student_video',
		$elm$json$Json$Decode$maybe($author$project$Models$StudentVideo$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'quest',
			$author$project$Models$Quest$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'student_quest_id',
				$author$project$Models$Ids$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'subject_area',
					$author$project$Models$SubjectArea$decoder,
					$elm$json$Json$Decode$succeed($author$project$Models$AvailableQuest$AvailableStudentQuest))))));
var $author$project$Models$AvailableQuest$adaptivePathQuestDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Models$AvailableQuest$AdaptivePathQuest,
	A2($elm$json$Json$Decode$field, 'student_quest', $author$project$Models$AvailableQuest$availableStudentQuestDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'feedback',
		$elm$json$Json$Decode$maybe($author$project$Models$AvailableQuest$availableStudentQuestDecoder)));
var $author$project$Models$AvailableQuest$AvailableAssignment = F7(
	function (subjectArea, studentQuestId, quest, thumbnailUrl, teacherName, teacherProfileUrl, assignDateInMs) {
		return {gf: assignDateInMs, it: quest, a_: studentQuestId, bp: subjectArea, fr: teacherName, jt: teacherProfileUrl, c2: thumbnailUrl};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt = F3(
	function (path, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$at, path, valDecoder),
			decoder);
	});
var $author$project$Models$AvailableQuest$assignmentDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
	_List_fromArray(
		['assignment_info', 'assign_date_in_ms']),
	A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
		_List_fromArray(
			['assignment_info', 'teacher_profile_url']),
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
			_List_fromArray(
				['assignment_info', 'teacher_name']),
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'thumbnail_url',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'quest',
					$author$project$Models$Quest$decoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'student_quest_id',
						$author$project$Models$Ids$decoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'subject_area',
							$author$project$Models$SubjectArea$decoder,
							$elm$json$Json$Decode$succeed($author$project$Models$AvailableQuest$AvailableAssignment))))))));
var $author$project$Models$AvailableQuest$AvailableQuest = F6(
	function (subjectArea, studentQuestId, quest, assigned, studentVideo, thumbnailUrl) {
		return {gg: assigned, it: quest, a_: studentQuestId, jg: studentVideo, bp: subjectArea, c2: thumbnailUrl};
	});
var $author$project$Models$AvailableQuest$availableQuestDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'thumbnail_url',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'student_video',
		$elm$json$Json$Decode$maybe($author$project$Models$StudentVideo$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'assigned',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'quest',
				$author$project$Models$Quest$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'student_quest_id',
					$author$project$Models$Ids$decoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'subject_area',
						$author$project$Models$SubjectArea$decoder,
						$elm$json$Json$Decode$succeed($author$project$Models$AvailableQuest$AvailableQuest)))))));
var $author$project$Models$AvailableQuest$ClassLibrary = F6(
	function (libraryName, sectionId, sectionName, teacherName, teacherImageUrl, storyCount) {
		return {hy: libraryName, iR: sectionId, iS: sectionName, ja: storyCount, js: teacherImageUrl, fr: teacherName};
	});
var $author$project$Models$AvailableQuest$classLibraryDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Models$AvailableQuest$ClassLibrary,
	A2(
		$elm$json$Json$Decode$field,
		'library_name',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'section_id', $author$project$Models$Ids$decoder),
	A2($elm$json$Json$Decode$field, 'section_name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'teacher_name', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'teacher_image',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'story_count', $elm$json$Json$Decode$int));
var $author$project$Models$AvailableQuest$decoder = A8(
	$elm$json$Json$Decode$map7,
	$author$project$Models$AvailableQuest$AvailableQuestData,
	A2(
		$elm$json$Json$Decode$field,
		'subject_areas',
		$elm$json$Json$Decode$list($author$project$Models$SubjectArea$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'quests',
		$elm$json$Json$Decode$list($author$project$Models$AvailableQuest$availableQuestDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'video_feedback_quests',
		$elm$json$Json$Decode$list($author$project$Models$AvailableQuest$availableQuestDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'assignments',
		$elm$json$Json$Decode$list($author$project$Models$AvailableQuest$assignmentDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'reading',
		$elm$json$Json$Decode$maybe($author$project$Models$AvailableQuest$adaptivePathQuestDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'math',
		$elm$json$Json$Decode$maybe($author$project$Models$AvailableQuest$adaptivePathQuestDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'class_library_sections',
		$elm$json$Json$Decode$list($author$project$Models$AvailableQuest$classLibraryDecoder)));
var $author$project$Api$StudentQuestApi$fetchAllAvailable = F2(
	function (loginInfo, appConfig) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$author$project$Models$AvailableQuest$decoder,
			$author$project$LoggedIn$Messages$LoadAvailableQuestsResponse,
			A2(
				$author$project$Api$AuthedRequest$get,
				'/api/v5/student_quests/',
				A5($author$project$Api$RequestHelper$createConfig, $author$project$LoggedIn$Messages$LoadAvailableQuestsResponse, loginInfo, appConfig, 0, $author$project$Models$AvailableQuest$decoder)));
	});
var $author$project$Learnosity$Messages$FetchLearnosityAssessmentOptionsResponse = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Api$LearnosityAssessmentApi$fetchAssessmentOptions = F3(
	function (studentActivityId, loginInfo, appConfig) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$elm$json$Json$Decode$value,
			$author$project$Messages$wrapLearnosityMessage(
				$author$project$Learnosity$Messages$FetchLearnosityAssessmentOptionsResponse(studentActivityId)),
			A2(
				$author$project$Api$AuthedRequest$get,
				'/api/v5/student_activities/' + ($author$project$Models$StudentActivity$idToString(studentActivityId) + '/learnosity_assessment'),
				A5(
					$author$project$Api$RequestHelper$createConfig,
					$author$project$Messages$wrapLearnosityMessage(
						$author$project$Learnosity$Messages$FetchLearnosityAssessmentOptionsResponse(studentActivityId)),
					loginInfo,
					appConfig,
					0,
					$elm$json$Json$Decode$value)));
	});
var $author$project$Learnosity$Messages$FetchQuizQuestionPreviewOptionsResponse = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Api$LearnosityAssessmentApi$fetchQuizQuestionPreviewOptions = F3(
	function (questId, loginInfo, appConfig) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$elm$json$Json$Decode$value,
			$author$project$Messages$wrapLearnosityMessage(
				$author$project$Learnosity$Messages$FetchQuizQuestionPreviewOptionsResponse(questId)),
			A2(
				$author$project$Api$AuthedRequest$get,
				'/api/v5/quiz_question_preview/' + $author$project$Models$Ids$toString(questId),
				A5(
					$author$project$Api$RequestHelper$createConfig,
					$author$project$Messages$wrapLearnosityMessage(
						$author$project$Learnosity$Messages$FetchQuizQuestionPreviewOptionsResponse(questId)),
					loginInfo,
					appConfig,
					0,
					$elm$json$Json$Decode$value)));
	});
var $author$project$Api$LearnosityAssessmentApi$fetchQuizQuestionPreviewOptionsById = F3(
	function (_v0, loginInfo, appConfig) {
		var learnosityId = _v0;
		return A3(
			$author$project$Api$HttpRetry$send,
			$elm$json$Json$Decode$value,
			$author$project$Messages$wrapLearnosityMessage(
				$author$project$Learnosity$Messages$FetchQuizQuestionPreviewOptionsResponse(
					$author$project$Models$Ids$fromInt(0))),
			A2(
				$author$project$Api$AuthedRequest$get,
				'/api/v5/quiz_question_preview_by_id/' + learnosityId,
				A5(
					$author$project$Api$RequestHelper$createConfig,
					$author$project$Messages$wrapLearnosityMessage(
						$author$project$Learnosity$Messages$FetchQuizQuestionPreviewOptionsResponse(
							$author$project$Models$Ids$fromInt(0))),
					loginInfo,
					appConfig,
					0,
					$elm$json$Json$Decode$value)));
	});
var $author$project$Activities$ChoiceText$Model$ChoiceTextNotLoaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Activities$ChoiceText$Model$ClassLibraryMode = function (a) {
	return {$: 1, a: a};
};
var $author$project$Activities$ChoiceText$startChoiceText = _Platform_outgoingPort('startChoiceText', $elm$json$Json$Encode$string);
var $author$project$Activities$ChoiceText$initLibrary = function (sectionId) {
	var model = $author$project$Activities$ChoiceText$Model$ChoiceTextNotLoaded(
		$author$project$Activities$ChoiceText$Model$ClassLibraryMode(sectionId));
	return _Utils_Tuple2(
		model,
		$author$project$Activities$ChoiceText$startChoiceText(
			$author$project$Activities$ChoiceText$Model$modeToIdentifier(model)));
};
var $author$project$AppFlow$Navigation$initialRouteForQuest = F2(
	function (appFlowMode, studentQuestId) {
		return $author$project$Routes$QuestRoute(
			function () {
				if (!appFlowMode.$) {
					return $author$project$Routes$QuestRoutes$Intro(studentQuestId);
				} else {
					var _v1 = appFlowMode.a;
					return $author$project$Routes$QuestRoutes$Home(studentQuestId);
				}
			}());
	});
var $author$project$Models$StudentActivity$nextUnlockedIncomplete = function (studentActivities) {
	return $elm$core$List$head(
		A2(
			$elm$core$List$filter,
			$author$project$Models$StudentActivity$isUnlocked(studentActivities),
			A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Models$StudentActivity$isUnavailable),
				A2(
					$elm$core$List$filter,
					A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Models$StudentActivity$isCompleted),
					studentActivities))));
};
var $author$project$Models$StudentActivity$ChoiceTextView = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Models$StudentActivity$EbookView = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Models$Modal$IntroVideoModal = {$: 0};
var $author$project$Messages$PlayVideoIfUserHasInteractedWithPage = function (a) {
	return {$: 48, a: a};
};
var $author$project$Effect$MarkActivityStartedEffect = F2(
	function (a, b) {
		return {$: 51, a: a, b: b};
	});
var $author$project$Helpers$ActivityEffectHelper$generalActivityEffects = F3(
	function (studentActivity, onRouteChange, loginInfo) {
		var activityType = studentActivity.f_.f0;
		var markActivityStartedEffect = function () {
			var _v0 = _Utils_Tuple2(activityType, onRouteChange);
			if ((_v0.a.$ === 1) && _v0.b) {
				return $author$project$Effect$NoEffect;
			} else {
				return _Utils_eq(studentActivity.ir, $author$project$Models$StudentActivity$UnstartedStudentActivity) ? A2($author$project$Effect$MarkActivityStartedEffect, studentActivity.hj, loginInfo) : $author$project$Effect$NoEffect;
			}
		}();
		return markActivityStartedEffect;
	});
var $author$project$Models$Activity$hostedVideoUrlToPlay = F2(
	function (productFeatures, hostedVideoData) {
		if (!hostedVideoData.$) {
			return $elm$core$Maybe$Nothing;
		} else {
			var s3Url = hostedVideoData.a;
			var cdnUrl = hostedVideoData.b;
			return A2($author$project$Models$ProductFeature$isEnabled, 9, productFeatures) ? $elm$core$Maybe$Just(
				$author$project$Models$Urls$toString(cdnUrl)) : $elm$core$Maybe$Just(
				$author$project$Models$Urls$toString(s3Url));
		}
	});
var $author$project$Activities$Ebook$CheckForEbookActivity = function (a) {
	return {$: 1, a: a};
};
var $author$project$Activities$Ebook$Model$EbookNotLoaded = {$: 0};
var $author$project$Activities$Ebook$initEbookReader = _Platform_outgoingPort(
	'initEbookReader',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'activityUrl',
					$elm$json$Json$Encode$string($.b7)),
					_Utils_Tuple2(
					'clientUrl',
					$elm$json$Json$Encode$string($.cb)),
					_Utils_Tuple2(
					'studentActivityId',
					$elm$json$Json$Encode$string($.G))
				]));
	});
var $author$project$Activities$Ebook$init = F3(
	function (ebookUrl, studentActivity, clientBaseUrl) {
		return _Utils_Tuple2(
			$author$project$Activities$Ebook$Model$EbookNotLoaded,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Activities$Ebook$initEbookReader(
						{
							b7: $author$project$Models$Urls$toString(ebookUrl),
							cb: clientBaseUrl,
							G: $author$project$Models$Ids$toString(studentActivity.hj)
						}),
						A2(
						$author$project$Helpers$Task$performWithDelay,
						15000,
						$author$project$Activities$Ebook$CheckForEbookActivity(studentActivity.hj))
					])));
	});
var $author$project$Activities$ChoiceText$Model$ActivityMode = function (a) {
	return {$: 0, a: a};
};
var $author$project$Activities$ChoiceText$CheckForChoiceTextActivity = {$: 0};
var $author$project$Activities$ChoiceText$initActivity = function (studentActivity) {
	var model = $author$project$Activities$ChoiceText$Model$ChoiceTextNotLoaded(
		$author$project$Activities$ChoiceText$Model$ActivityMode(studentActivity.hj));
	return _Utils_Tuple2(
		model,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Activities$ChoiceText$startChoiceText(
					$author$project$Activities$ChoiceText$Model$modeToIdentifier(model)),
					A2($author$project$Helpers$Task$performWithDelay, 10000, $author$project$Activities$ChoiceText$CheckForChoiceTextActivity)
				])));
};
var $author$project$Ports$requestNotificationPermissions = _Platform_outgoingPort(
	'requestNotificationPermissions',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Helpers$ActivityEffectHelper$RouteChange = 0;
var $author$project$Effect$SendReturnNotificationEffect = F2(
	function (a, b) {
		return {$: 50, a: a, b: b};
	});
var $author$project$Models$Activity$hasPracticeContentOutsideESparkApp = function (_v0) {
	var activityType = _v0.f0;
	if (activityType.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Effect$AppTimerInitializeEffect = function (a) {
	return {$: 54, a: a};
};
var $author$project$AppTimer$timedMessagesFor = F2(
	function (studentActivity, appTimerLength) {
		var completionDelay = function () {
			var webAppCompletionDelay = studentActivity.f_.dl ? (studentActivity.f_.hG / 1000) : 60.0;
			var defaultTime = function () {
				var _v0 = studentActivity.f_.f0;
				if (_v0.$ === 1) {
					return webAppCompletionDelay;
				} else {
					return 90.0;
				}
			}();
			return A2($elm$core$Maybe$withDefault, defaultTime, appTimerLength) * 1000;
		}();
		return studentActivity.f_.dl ? _List_fromArray(
			[
				_Utils_Tuple2(
				A2($elm$core$Basics$min, 30 * 1000, completionDelay / 3),
				'SetCanCompleteProgressEffect'),
				_Utils_Tuple2(
				A2($elm$core$Basics$max, completionDelay - (35 * 1000), completionDelay / 3),
				'FlashTimerAlertEffect'),
				_Utils_Tuple2(
				A2($elm$core$Basics$min, 5 * 1000, completionDelay / 3),
				'StudentActivityTimerCompleteEffect')
			]) : _List_fromArray(
			[
				_Utils_Tuple2(completionDelay, 'StudentActivityTimerCompleteEffect')
			]);
	});
var $author$project$AppTimer$initialAppTimer = F2(
	function (studentActivity, appTimerLength) {
		return {
			ar: false,
			cf: 0.0,
			G: studentActivity.hj,
			aF: A2($author$project$AppTimer$timedMessagesFor, studentActivity, appTimerLength),
			bq: 0.0
		};
	});
var $author$project$AppTimer$reset = F2(
	function (studentActivity, appTimerLength) {
		return $author$project$Effect$AppTimerInitializeEffect(
			A2($author$project$AppTimer$initialAppTimer, studentActivity, appTimerLength));
	});
var $author$project$Models$Activity$returnEffectsDelay = function (_v0) {
	var maximumActivityTime = _v0.hG;
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$List$maximum(
			_List_fromArray(
				[0, maximumActivityTime - (21 * 1000)])));
};
var $author$project$Effect$AppTimerCallbackEffect = F2(
	function (a, b) {
		return {$: 53, a: a, b: b};
	});
var $author$project$AppTimer$startTimer = F3(
	function (studentActivity, maybeAppTimer, appTimerLength) {
		var restoreAppTimerEffect = function (appTimer) {
			var elapsedTime = appTimer.cf - appTimer.bq;
			var delay = function (nextDelay) {
				return ((_Utils_cmp(elapsedTime, nextDelay) > 0) || (elapsedTime < 0)) ? 10 : (nextDelay - elapsedTime);
			};
			var nextTimeMessageDelay = A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				0,
				A2($elm$core$Basics$composeR, $elm$core$Tuple$first, delay),
				$elm$core$List$head(appTimer.aF));
			return A2(
				$author$project$Effect$AppTimerCallbackEffect,
				_Utils_update(
					appTimer,
					{ar: true}),
				nextTimeMessageDelay);
		};
		var newAppTimerEffect = $author$project$Effect$AppTimerInitializeEffect(
			A2($author$project$AppTimer$initialAppTimer, studentActivity, appTimerLength));
		if (maybeAppTimer.$ === 1) {
			return newAppTimerEffect;
		} else {
			var appTimer = maybeAppTimer.a;
			return (!_Utils_eq(appTimer.G, studentActivity.hj)) ? newAppTimerEffect : (((!appTimer.ar) && _Utils_eq(appTimer.G, studentActivity.hj)) ? restoreAppTimerEffect(appTimer) : $author$project$Effect$NoEffect);
		}
	});
var $author$project$Helpers$ActivityEffectHelper$timedActivityEffects = F3(
	function (studentActivity, eventType, model) {
		var studentActivityProgress = studentActivity.ir;
		var returnNotificationDelay = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Models$Activity$returnEffectsDelay(studentActivity.f_),
			model.f7.dO.iG);
		var returnNotificationEffect = function () {
			if ($author$project$Models$Activity$hasPracticeContentOutsideESparkApp(studentActivity.f_)) {
				var _v7 = model.f7.ii;
				if (!_v7.$) {
					return A2($author$project$Effect$SendReturnNotificationEffect, studentActivity.hj, returnNotificationDelay);
				} else {
					return A2($author$project$Effect$SendReturnNotificationEffect, studentActivity.hj, returnNotificationDelay);
				}
			} else {
				return $author$project$Effect$NoEffect;
			}
		}();
		var isTimedActivityType = function () {
			var _v6 = studentActivity.f_.f0;
			if (_v6.$ === 1) {
				return true;
			} else {
				return false;
			}
		}();
		var isActiveOrUnstarted = function () {
			switch (studentActivityProgress.$) {
				case 1:
					return true;
				case 0:
					return true;
				default:
					return false;
			}
		}();
		var resetAppTimerEffect = (isTimedActivityType && isActiveOrUnstarted) ? A2($author$project$AppTimer$reset, studentActivity, model.f7.dO.gb) : $author$project$Effect$NoEffect;
		var resumeAppTimer = function (appTimer) {
			return (_Utils_eq(appTimer.G, studentActivity.hj) && isActiveOrUnstarted) ? A3(
				$author$project$AppTimer$startTimer,
				studentActivity,
				$elm$core$Maybe$Just(appTimer),
				model.f7.dO.gb) : $author$project$Effect$NoEffect;
		};
		var startOrResumeAppTimerEffect = (isTimedActivityType && isActiveOrUnstarted) ? A3($author$project$AppTimer$startTimer, studentActivity, model.$7, model.f7.dO.gb) : $author$project$Effect$NoEffect;
		var fullStartTimerEffects = $author$project$Effect$batch(
			_List_fromArray(
				[startOrResumeAppTimerEffect, returnNotificationEffect]));
		var activityType = studentActivity.f_.f0;
		var refreshEffects = function () {
			var _v3 = _Utils_Tuple2(activityType, studentActivityProgress);
			if ((_v3.b.$ === 1) && (!_v3.b.a.$)) {
				var _v4 = _v3.b.a;
				return resetAppTimerEffect;
			} else {
				return $author$project$Effect$NoEffect;
			}
		}();
		var routeChangeEffects = function () {
			var _v2 = _Utils_Tuple2(activityType, model.$7);
			if ((_v2.a.$ === 1) && (!_v2.b.$)) {
				var appTimer = _v2.b.a;
				return resumeAppTimer(appTimer);
			} else {
				return $author$project$Effect$NoEffect;
			}
		}();
		var startTimedAppEffects = function () {
			var _v1 = _Utils_Tuple2(activityType, studentActivityProgress);
			if (_v1.a.$ === 1) {
				return fullStartTimerEffects;
			} else {
				return $author$project$Effect$NoEffect;
			}
		}();
		switch (eventType) {
			case 0:
				return routeChangeEffects;
			case 1:
				return refreshEffects;
			default:
				return startTimedAppEffects;
		}
	});
var $author$project$Helpers$ActivityEffectHelper$timedActivityEffectsForRouteChange = F2(
	function (studentActivity, model) {
		return A3($author$project$Helpers$ActivityEffectHelper$timedActivityEffects, studentActivity, 0, model);
	});
var $author$project$Routing$UpdateHelpers$practiceRouteUpdates = F4(
	function (studentActivityId, student, loginInfo, _v0) {
		var model = _v0.a;
		var existingCommand = _v0.b;
		var _v1 = A2($author$project$Helpers$RecordFinders$findStudentActivity, studentActivityId, model.fZ);
		if (_v1.$ === 1) {
			return _Utils_Tuple2(model, existingCommand);
		} else {
			var studentActivity = _v1.a;
			var usingYoutube = A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				false,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.fW;
					},
					$author$project$Models$YoutubeVideoPlayer$canUseEmbeddedPlayer(model.aI)),
				$author$project$Models$Activity$youtubeData(studentActivity.f_)) && (!A2($author$project$Models$ProductFeature$isEnabled, 1, student.iq));
			var isWebApp = $author$project$Models$Activity$isWebApp(studentActivity.f_);
			var getsIntroVideoModal = isWebApp && (student.hT && ((!$author$project$Models$StudentActivity$hasBeenRatedOnce(studentActivity)) && (!studentActivity.f_.dl)));
			var checkIfVideoHasPlayedCommand = A2(
				$author$project$Helpers$Task$performWithDelay,
				5000,
				$author$project$Messages$CheckIfYoutubeVideoIsPlaying(studentActivity.hj));
			var videoActivityPracticeCommand = function () {
				var _v5 = studentActivity.f_.f0;
				if (!_v5.$) {
					var hostedVideoData = _v5.a;
					var youtubeData = _v5.b;
					return (!$author$project$Models$YoutubeVideoPlayer$isYoutubeStatusKnown(model.aI)) ? $elm$core$Platform$Cmd$none : (usingYoutube ? $elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Ports$connectToEmbeddedYoutubePlayer(
								{
									f$: $author$project$Models$StudentActivity$activityKey(studentActivity.hj),
									i3: A2($author$project$Models$YoutubeVideoPlayer$startTime, model.jI, youtubeData),
									G: $author$project$Models$Ids$toString(studentActivity.hj)
								}),
								checkIfVideoHasPlayedCommand
							])) : ($elm_community$maybe_extra$Maybe$Extra$isJust(
						A2($author$project$Models$Activity$hostedVideoUrlToPlay, student.iq, hostedVideoData)) ? A2(
						$author$project$Helpers$Task$performWithDelay,
						1000,
						$author$project$Messages$PlayVideoIfUserHasInteractedWithPage(studentActivity.hj)) : $elm$core$Platform$Cmd$none));
				} else {
					return $elm$core$Platform$Cmd$none;
				}
			}();
			var autoStartVideoCmd = A2(
				$author$project$Helpers$Task$performWithDelay,
				1000,
				$author$project$Messages$PlayVideo(
					$author$project$VideoFeedback$videoKey(
						$author$project$Models$Ids$toString(studentActivityId))));
			if (getsIntroVideoModal) {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $author$project$Models$Modal$Open($author$project$Models$Modal$IntroVideoModal)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[existingCommand, autoStartVideoCmd])));
			} else {
				if ($elm_community$maybe_extra$Maybe$Extra$isJust(
					$author$project$Models$Activity$ebookUrl(studentActivity.f_))) {
					var _v2 = $author$project$Models$Activity$ebookUrl(studentActivity.f_);
					if (!_v2.$) {
						var ebookUrl = _v2.a;
						var _v3 = A3($author$project$Activities$Ebook$init, ebookUrl, studentActivity, model.f7.bz);
						var ebookModel = _v3.a;
						var ebookCmd = _v3.b;
						return _Utils_Tuple2(
							A3(
								$author$project$Model$updateStudentActivityById,
								studentActivityId,
								function (sa) {
									return _Utils_update(
										sa,
										{
											fL: A2(
												$author$project$Models$StudentActivity$EbookView,
												ebookModel,
												A2($author$project$Models$StudentActivity$iframeActivityFooterVisibility, studentActivity.f_, studentActivity.fL))
										});
								},
								model),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$elm$core$Platform$Cmd$map,
										A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$EbookActivityMsg, $author$project$Messages$LoggedInRequiredMsg),
										ebookCmd),
										existingCommand
									])));
					} else {
						return _Utils_Tuple2(model, existingCommand);
					}
				} else {
					if ($author$project$Models$Activity$isChoiceText(studentActivity.f_)) {
						var _v4 = $author$project$Activities$ChoiceText$initActivity(studentActivity);
						var choiceTextModel = _v4.a;
						var choiceTextCmd = _v4.b;
						return _Utils_Tuple2(
							A3(
								$author$project$Model$updateStudentActivityById,
								studentActivityId,
								function (sa) {
									return _Utils_update(
										sa,
										{
											fL: A2(
												$author$project$Models$StudentActivity$ChoiceTextView,
												choiceTextModel,
												A2($author$project$Models$StudentActivity$iframeActivityFooterVisibility, sa.f_, sa.fL))
										});
								},
								model),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$elm$core$Platform$Cmd$map,
										A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$ChoiceTextActivityMsg, $author$project$Messages$LoggedInRequiredMsg),
										choiceTextCmd),
										existingCommand
									])));
					} else {
						if (isWebApp) {
							return _Utils_Tuple2(
								model,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											existingCommand,
											A3(
											$author$project$Effect$Resolution$performEffect,
											model.hR,
											model,
											A2($author$project$Helpers$ActivityEffectHelper$timedActivityEffectsForRouteChange, studentActivity, model)),
											A3(
											$author$project$Effect$Resolution$performEffect,
											model.hR,
											model,
											A3($author$project$Helpers$ActivityEffectHelper$generalActivityEffects, studentActivity, true, loginInfo)),
											$author$project$Ports$requestNotificationPermissions(0)
										])));
						} else {
							return _Utils_Tuple2(
								model,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											existingCommand,
											A3(
											$author$project$Effect$Resolution$performEffect,
											model.hR,
											model,
											A2($author$project$Helpers$ActivityEffectHelper$timedActivityEffectsForRouteChange, studentActivity, model)),
											A3(
											$author$project$Effect$Resolution$performEffect,
											model.hR,
											model,
											A3($author$project$Helpers$ActivityEffectHelper$generalActivityEffects, studentActivity, true, loginInfo)),
											videoActivityPracticeCommand
										])));
						}
					}
				}
			}
		}
	});
var $author$project$Ports$scrollTo = _Platform_outgoingPort('scrollTo', $elm$json$Json$Encode$string);
var $krisajenkins$remotedata$RemoteData$succeed = $krisajenkins$remotedata$RemoteData$Success;
var $author$project$Routing$Updates$makeUpdatesForNewRoute = F4(
	function (newRoute, student, loginInfo, _v0) {
		var model = _v0.a;
		var existingCommands = _v0.b;
		var _v1 = function () {
			var _v2 = _Utils_Tuple2(newRoute, model.fZ);
			_v2$13:
			while (true) {
				switch (_v2.a.$) {
					case 0:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{fZ: $krisajenkins$remotedata$RemoteData$NotAsked, go: $krisajenkins$remotedata$RemoteData$Loading}),
							$author$project$Effect$batch(
								_List_fromArray(
									[
										$author$project$Effect$CmdEffect(
										A2(
											$elm$core$Platform$Cmd$map,
											$author$project$Messages$LoggedInRequiredMsg,
											A2($author$project$Api$StudentQuestApi$fetchAllAvailable, loginInfo, model.f7))),
										A2(
										$author$project$Effect$PerformLaterEffect,
										5000,
										$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$CheckIfAvailableQuestsAreStillLoading))
									])));
					case 7:
						switch (_v2.a.a.$) {
							case 1:
								return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
							case 0:
								var studentQuestId = _v2.a.a.a;
								var startingRoute = A2($author$project$AppFlow$Navigation$initialRouteForQuest, student.f8, studentQuestId);
								var redirectHomeEffect = (!_Utils_eq(startingRoute, model.eY)) ? $author$project$Effect$UpdateUrlEffect(
									$author$project$Routes$routeToUrlString(startingRoute)) : $author$project$Effect$NoEffect;
								var nextActivity = A3(
									$krisajenkins$remotedata$RemoteData$unwrap,
									$elm$core$Maybe$Nothing,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.jd;
										},
										$author$project$Models$StudentActivity$nextUnlockedIncomplete),
									model.fZ);
								var selectorForScrolling = function () {
									if (!nextActivity.$) {
										var aStudentActivity = nextActivity.a;
										return 'activity-' + $author$project$Models$StudentActivity$idToString(aStudentActivity.hj);
									} else {
										return 'activity-1';
									}
								}();
								return _Utils_Tuple2(
									model,
									$author$project$Effect$batch(
										_List_fromArray(
											[
												redirectHomeEffect,
												$author$project$Effect$CmdEffect(
												$author$project$Ports$scrollTo(selectorForScrolling))
											])));
							default:
								break _v2$13;
						}
					case 9:
						if ((_v2.a.a.$ === 2) && (_v2.b.$ === 3)) {
							var activeQuest = _v2.b.a;
							return _Utils_Tuple2(
								model,
								A2($author$project$Effect$StartVideoRecordingEffect, false, loginInfo));
						} else {
							break _v2$13;
						}
					case 8:
						switch (_v2.a.a.$) {
							case 1:
								var _v4 = _v2.a.a;
								var studentActivityId = _v4.b;
								return A2(
									$elm$core$Tuple$mapSecond,
									$author$project$Effect$CmdEffect,
									A4(
										$author$project$Routing$UpdateHelpers$practiceRouteUpdates,
										studentActivityId,
										student,
										loginInfo,
										_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
							case 2:
								var _v5 = _v2.a.a;
								var studentActivityId = _v5.c;
								var _v6 = A2($author$project$Helpers$RecordFinders$findStudentActivity, studentActivityId, model.fZ);
								if (_v6.$ === 1) {
									return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
								} else {
									var studentActivity = _v6.a;
									var activityStatusCommand = function () {
										var _v7 = studentActivity.ir;
										if (!_v7.$) {
											return A2(
												$elm$core$Platform$Cmd$map,
												$author$project$Messages$LoggedInRequiredMsg,
												A3($author$project$Api$StudentActivityApi$start, studentActivity.hj, loginInfo, model.f7));
										} else {
											return $elm$core$Platform$Cmd$none;
										}
									}();
									return _Utils_Tuple2(
										model,
										$author$project$Effect$CmdEffect(
											$elm$core$Platform$Cmd$batch(
												_List_fromArray(
													[
														A3($author$project$Api$LearnosityAssessmentApi$fetchAssessmentOptions, studentActivity.hj, loginInfo, model.f7),
														activityStatusCommand
													]))));
								}
							case 3:
								var _v8 = _v2.a.a;
								var studentActivityId = _v8.b;
								var componentType = _v8.c;
								var data = $elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'studentActivityId',
											$elm$json$Json$Encode$string(
												$author$project$Models$Ids$toString(studentActivityId))),
											_Utils_Tuple2(
											'studentId',
											$elm$json$Json$Encode$string(
												$author$project$Models$Ids$toString(student.hj))),
											_Utils_Tuple2(
											'componentType',
											$elm$json$Json$Encode$string(
												$author$project$Models$Activity$componentTypeToString(componentType))),
											_Utils_Tuple2(
											'audioEnabled',
											$elm$json$Json$Encode$bool(student.gj))
										]));
								return _Utils_Tuple2(
									model,
									$author$project$Effect$CmdEffect(
										$author$project$Ports$componentShow(
											A2($elm$json$Json$Encode$encode, 0, data))));
							default:
								break _v2$13;
						}
					case 6:
						var componentType = _v2.a.a;
						var data = $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'componentType',
									$elm$json$Json$Encode$string(
										$author$project$Models$Activity$componentTypeToString(componentType))),
									_Utils_Tuple2(
									'audioEnabled',
									$elm$json$Json$Encode$bool(student.gj))
								]));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									fZ: $krisajenkins$remotedata$RemoteData$succeed($author$project$Helpers$QuizPreviewDummyData$activeQuest)
								}),
							$author$project$Effect$CmdEffect(
								$author$project$Ports$componentShow(
									A2($elm$json$Json$Encode$encode, 0, data))));
					case 10:
						if (_v2.a.a.$ === 1) {
							var _v9 = _v2.a.a;
							var studentActivityId = _v9.c;
							var _v10 = A2($author$project$Helpers$RecordFinders$findStudentActivity, studentActivityId, model.fZ);
							if (_v10.$ === 1) {
								return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
							} else {
								var studentActivity = _v10.a;
								return _Utils_Tuple2(
									model,
									$author$project$Effect$CmdEffect(
										$elm$core$Platform$Cmd$batch(
											_List_fromArray(
												[
													A3($author$project$Api$LearnosityAssessmentApi$fetchAssessmentOptions, studentActivity.hj, loginInfo, model.f7),
													A2(
													$elm$core$Platform$Cmd$map,
													$author$project$Messages$LoggedInRequiredMsg,
													A3($author$project$Api$StudentActivityApi$start, studentActivity.hj, loginInfo, model.f7))
												]))));
							}
						} else {
							break _v2$13;
						}
					case 5:
						switch (_v2.a.a.$) {
							case 0:
								var questId = _v2.a.a.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											fZ: $krisajenkins$remotedata$RemoteData$succeed($author$project$Helpers$QuizPreviewDummyData$activeQuest)
										}),
									$author$project$Effect$CmdEffect(
										A3($author$project$Api$LearnosityAssessmentApi$fetchQuizQuestionPreviewOptions, questId, loginInfo, model.f7)));
							case 1:
								var learnosityId = _v2.a.a.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											fZ: $krisajenkins$remotedata$RemoteData$succeed($author$project$Helpers$QuizPreviewDummyData$activeQuest)
										}),
									$author$project$Effect$CmdEffect(
										A3($author$project$Api$LearnosityAssessmentApi$fetchQuizQuestionPreviewOptionsById, learnosityId, loginInfo, model.f7)));
							default:
								var learnosityId = _v2.a.a.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											fZ: $krisajenkins$remotedata$RemoteData$succeed($author$project$Helpers$QuizPreviewDummyData$activeQuestReadyToStartChallenge)
										}),
									$author$project$Effect$CmdEffect(
										A3($author$project$Api$LearnosityAssessmentApi$fetchQuizQuestionPreviewOptionsById, learnosityId, loginInfo, model.f7)));
						}
					case 13:
						var _v11 = _v2.a;
						var sectionId = _v11.b;
						var _v12 = $author$project$Activities$ChoiceText$initLibrary(sectionId);
						var choiceTextModel = _v12.a;
						var choiceTextCmds = _v12.b;
						return _Utils_Tuple2(
							model,
							$author$project$Effect$batch(
								_List_fromArray(
									[
										$author$project$Effect$PerformNowEffect(
										$author$project$Messages$LoggedInRequiredMsg(
											$author$project$LoggedIn$Messages$LoadClassLibraryModel(choiceTextModel))),
										$author$project$Effect$CmdEffect(
										A2(
											$elm$core$Platform$Cmd$map,
											A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$ClassLibraryMsg, $author$project$Messages$LoggedInRequiredMsg),
											choiceTextCmds))
									])));
					default:
						break _v2$13;
				}
			}
			return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
		}();
		var updatedModel = _v1.a;
		var additionalCommands = _v1.b;
		return _Utils_Tuple2(
			updatedModel,
			$author$project$Effect$batch(
				_List_fromArray(
					[
						$author$project$Effect$CmdEffect(existingCommands),
						additionalCommands
					])));
	});
var $author$project$Ports$setResumeUrl = _Platform_outgoingPort('setResumeUrl', $elm$json$Json$Encode$string);
var $author$project$Routing$Updates$setResumeUrl = F4(
	function (newRoute, _v0, _v1, _v2) {
		var model = _v2.a;
		var commands = _v2.b;
		return _Utils_Tuple2(
			model,
			$author$project$Effect$batch(
				_List_fromArray(
					[
						commands,
						$author$project$Effect$CmdEffect(
						$author$project$Ports$setResumeUrl(
							$author$project$Routes$routeToUrlString(newRoute)))
					])));
	});
var $author$project$SentryDebugData$studentData = function (student) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'id',
			$author$project$Models$Ids$encoder(student.b1)),
			_Utils_Tuple2(
			'username',
			$elm$json$Json$Encode$string(student.fF)),
			_Utils_Tuple2(
			'name',
			$elm$json$Json$Encode$string(student.cE))
		]);
};
var $author$project$SentryDebugData$toPair = F2(
	function (key, val) {
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(key),
					val
				]));
	});
var $author$project$SentryDebugData$addPair = F3(
	function (key, val, pairs) {
		return A2(
			$elm$core$List$cons,
			A2($author$project$SentryDebugData$toPair, key, val),
			pairs);
	});
var $author$project$Models$ProductFeature$enabledActiveFeatures = function (_v0) {
	var features = _v0;
	return $elm$core$Dict$keys(
		$author$project$Models$ProductFeature$enabledActiveFeaturesDict(features));
};
var $author$project$AppFlow$encode = function (mode) {
	return $elm$json$Json$Encode$string(
		function () {
			if (!mode.$) {
				return 'Sequential';
			} else {
				var _v1 = mode.a;
				return 'Choice-Theme';
			}
		}());
};
var $author$project$ProductVersion$encode = function (productVersion) {
	if (!productVersion) {
		return $elm$json$Json$Encode$string('4');
	} else {
		return $elm$json$Json$Encode$string('5');
	}
};
var $author$project$Routes$maybeStudentActivityFromRoute = F2(
	function (activeQuest, route) {
		return A2(
			$elm$core$Maybe$andThen,
			function (a) {
				return A2($author$project$Helpers$RecordFinders$findStudentActivity, a, activeQuest);
			},
			$author$project$Routes$studentActivityIdFromRoute(route));
	});
var $author$project$SentryDebugData$routeToPath = function (route) {
	return A2(
		$elm$core$String$join,
		'/',
		A2(
			$elm$core$List$map,
			function (part) {
				return _Utils_eq(
					$elm$core$String$toInt(part),
					$elm$core$Maybe$Nothing) ? part : ':id';
			},
			A2($elm$core$String$split, '/', route)));
};
var $author$project$SentryDebugData$tagData = F3(
	function (student, model, route) {
		var routeString = $author$project$Routes$routeToUrlString(route);
		var addStudentQuestPairs = function () {
			var _v1 = model.fZ;
			if (_v1.$ === 3) {
				var studentQuestId = _v1.a.a_;
				var masteryShown = _v1.a.es;
				var quest = _v1.a.it;
				return A2(
					$elm$core$Basics$composeR,
					A2(
						$author$project$SentryDebugData$addPair,
						'student_quest_id',
						$author$project$Models$Ids$encoder(studentQuestId)),
					A2(
						$elm$core$Basics$composeR,
						A2(
							$author$project$SentryDebugData$addPair,
							'quest_id',
							$author$project$Models$Ids$encoder(quest.hj)),
						A2(
							$author$project$SentryDebugData$addPair,
							'student_quest_mastery_shown',
							$elm$json$Json$Encode$bool(masteryShown))));
			} else {
				return $elm$core$Basics$identity;
			}
		}();
		var addStudentPairs = A2(
			$elm$core$Basics$composeR,
			A2(
				$author$project$SentryDebugData$addPair,
				'organization_id',
				$author$project$Models$Ids$encoder(student.ib.hj)),
			A2(
				$elm$core$Basics$composeR,
				A2(
					$author$project$SentryDebugData$addPair,
					'organization_name',
					$elm$json$Json$Encode$string(student.ib.cE)),
				A2(
					$elm$core$Basics$composeR,
					A2(
						$author$project$SentryDebugData$addPair,
						'audio_enabled',
						$elm$json$Json$Encode$bool(student.gj)),
					A2(
						$elm$core$Basics$composeR,
						A2(
							$author$project$SentryDebugData$addPair,
							'app_flow_mode',
							$author$project$AppFlow$encode(student.f8)),
						A2(
							$author$project$SentryDebugData$addPair,
							'product_version',
							$author$project$ProductVersion$encode(student.eR))))));
		var addStudentActivityPairs = function () {
			var _v0 = A2($author$project$Routes$maybeStudentActivityFromRoute, model.fZ, route);
			if (!_v0.$) {
				var studentActivity = _v0.a;
				var activity = studentActivity.f_;
				var progress = studentActivity.ir;
				return A2(
					$elm$core$Basics$composeR,
					A2(
						$author$project$SentryDebugData$addPair,
						'student_activity_id',
						$author$project$Models$Ids$encoder(studentActivity.hj)),
					A2(
						$elm$core$Basics$composeR,
						A2(
							$author$project$SentryDebugData$addPair,
							'activity_id',
							$author$project$Models$Ids$encoder(activity.hj)),
						A2(
							$elm$core$Basics$composeR,
							A2(
								$author$project$SentryDebugData$addPair,
								'student_activity_progress',
								$elm$json$Json$Encode$string(
									$author$project$Models$StudentActivity$progressToString(progress))),
							A2(
								$author$project$SentryDebugData$addPair,
								'activity_type',
								$elm$json$Json$Encode$string(
									$author$project$Models$Activity$typeToString(activity.f0))))));
			} else {
				return $elm$core$Basics$identity;
			}
		}();
		var addProductFeaturePairs = function (list) {
			return A2(
				$elm$core$List$append,
				list,
				A2(
					$elm$core$List$map,
					function (feature) {
						return A2(
							$author$project$SentryDebugData$toPair,
							'pf_' + feature,
							$elm$json$Json$Encode$string('enabled'));
					},
					$author$project$Models$ProductFeature$enabledActiveFeatures(student.iq)));
		};
		return addProductFeaturePairs(
			addStudentActivityPairs(
				addStudentQuestPairs(
					addStudentPairs(
						_List_fromArray(
							[
								A2(
								$author$project$SentryDebugData$toPair,
								'route',
								$elm$json$Json$Encode$string(routeString)),
								A2(
								$author$project$SentryDebugData$toPair,
								'routePath',
								$elm$json$Json$Encode$string(
									$author$project$SentryDebugData$routeToPath(routeString))),
								A2(
								$author$project$SentryDebugData$toPair,
								'hasInteractedWithPage',
								$elm$json$Json$Encode$bool(model.d1))
							])))));
	});
var $author$project$SentryDebugData$encode = F3(
	function (student, model, route) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'user',
					$elm$json$Json$Encode$object(
						$author$project$SentryDebugData$studentData(student))),
					_Utils_Tuple2(
					'tags',
					A2(
						$elm$json$Json$Encode$list,
						$elm$core$Basics$identity,
						A3($author$project$SentryDebugData$tagData, student, model, route)))
				]));
	});
var $author$project$Ports$storeDebugData = _Platform_outgoingPort('storeDebugData', $elm$json$Json$Encode$string);
var $author$project$Routing$Updates$storeDebugData = F4(
	function (newRoute, student, _v0, _v1) {
		var model = _v1.a;
		var previousEffects = _v1.b;
		var dataString = A2(
			$elm$json$Json$Encode$encode,
			0,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'data',
						A3($author$project$SentryDebugData$encode, student, model, newRoute))
					])));
		return _Utils_Tuple2(
			model,
			$author$project$Effect$batch(
				_List_fromArray(
					[
						previousEffects,
						$author$project$Effect$CmdEffect(
						$author$project$Ports$storeDebugData(dataString))
					])));
	});
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $author$project$Models$Activity$idToString = $author$project$Models$Ids$toString;
var $author$project$Models$Modal$isCharacterSelectionOpen = function (modalState) {
	if ((!modalState.$) && (modalState.a.$ === 8)) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Models$Modal$isMoodScreenOpen = function (modalState) {
	if ((!modalState.$) && (modalState.a.$ === 7)) {
		return true;
	} else {
		return false;
	}
};
var $krisajenkins$remotedata$RemoteData$toMaybe = A2(
	$elm$core$Basics$composeR,
	$krisajenkins$remotedata$RemoteData$map($elm$core$Maybe$Just),
	$krisajenkins$remotedata$RemoteData$withDefault($elm$core$Maybe$Nothing));
var $author$project$TeacherTour$UpdateEsparkIframe$updateData = function (model) {
	var isVideoRecordingFlow = function () {
		var _v0 = model.eY;
		if (_v0.$ === 9) {
			return true;
		} else {
			return false;
		}
	}();
	var currentActivityId = A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.f_;
			},
			function ($) {
				return $.hj;
			}),
		$author$project$Model$studentActivityForCurrentRoute(model));
	var activeQuest = $krisajenkins$remotedata$RemoteData$toMaybe(model.fZ);
	var activitiesCompleted = A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Dict$empty,
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.jd;
				},
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$map(
						function (sa) {
							return _Utils_Tuple2(
								$author$project$Models$Activity$idToString(sa.f_.hj),
								$author$project$Models$StudentActivity$isCompleted(sa));
						}),
					$elm$core$Dict$fromList)),
			activeQuest));
	var currentQuestId = A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.it;
			},
			function ($) {
				return $.hj;
			}),
		activeQuest);
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'questId',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Models$Ids$encoder, currentQuestId)),
				_Utils_Tuple2(
				'activityId',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Models$Ids$encoder, currentActivityId)),
				_Utils_Tuple2(
				'isVideoRecording',
				$elm$json$Json$Encode$bool(isVideoRecordingFlow)),
				_Utils_Tuple2(
				'isMoodScreenOpen',
				$elm$json$Json$Encode$bool(
					$author$project$Models$Modal$isMoodScreenOpen(model.hM))),
				_Utils_Tuple2(
				'isCharacterSelectionOpen',
				$elm$json$Json$Encode$bool(
					$author$project$Models$Modal$isCharacterSelectionOpen(model.hM))),
				_Utils_Tuple2(
				'completedActivities',
				A3($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $elm$json$Json$Encode$bool, activitiesCompleted))
			]));
};
var $author$project$Routing$Updates$updateEsparkIframeWithQuestData = F2(
	function (newRoute, _v0) {
		var model = _v0.a;
		var existingEffects = _v0.b;
		var _v1 = model.f7.cn;
		if (_v1.$ === 1) {
			var origin = _v1.a.id;
			return _Utils_Tuple2(
				model,
				$author$project$Effect$batch(
					_List_fromArray(
						[
							existingEffects,
							$author$project$Effect$CmdEffect(
							$author$project$Ports$updateEsparkIframeWithQuestData(
								A2(
									$elm$core$Tuple$pair,
									origin,
									$author$project$TeacherTour$UpdateEsparkIframe$updateData(
										_Utils_update(
											model,
											{eY: newRoute})))))
						])));
		} else {
			return _Utils_Tuple2(model, existingEffects);
		}
	});
var $author$project$Routes$activitySubroute = function (route) {
	switch (route.$) {
		case 10:
			return $elm$core$Maybe$Nothing;
		case 8:
			var activityRoute = route.a;
			return $elm$core$Maybe$Just(activityRoute);
		case 5:
			return $elm$core$Maybe$Nothing;
		case 6:
			return $elm$core$Maybe$Nothing;
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Nothing;
		case 3:
			return $elm$core$Maybe$Nothing;
		case 4:
			return $elm$core$Maybe$Nothing;
		case 7:
			return $elm$core$Maybe$Nothing;
		case 9:
			return $elm$core$Maybe$Nothing;
		case 11:
			return $elm$core$Maybe$Nothing;
		case 12:
			return $elm$core$Maybe$Nothing;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Routes$ActivityRoutes$isPractice = function (route) {
	switch (route.$) {
		case 0:
			return false;
		case 1:
			return true;
		case 2:
			return false;
		default:
			return false;
	}
};
var $author$project$Routes$isActivityPracticeRoute = function (route) {
	return A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		false,
		$author$project$Routes$ActivityRoutes$isPractice,
		$author$project$Routes$activitySubroute(route));
};
var $author$project$Routes$ActivityRoutes$isAssessment = function (route) {
	switch (route.$) {
		case 0:
			return false;
		case 1:
			return false;
		case 2:
			return true;
		default:
			return true;
	}
};
var $author$project$Routes$QuizRoutes$isAssessment = function (route) {
	switch (route.$) {
		case 1:
			return true;
		case 2:
			return false;
		default:
			return false;
	}
};
var $author$project$Routes$isAssessment = function (route) {
	switch (route.$) {
		case 10:
			var quizRoute = route.a;
			return $author$project$Routes$QuizRoutes$isAssessment(quizRoute);
		case 8:
			var activityRoute = route.a;
			return $author$project$Routes$ActivityRoutes$isAssessment(activityRoute);
		case 5:
			return true;
		case 6:
			if (route.a === 1) {
				var _v1 = route.a;
				return true;
			} else {
				return false;
			}
		case 0:
			return false;
		case 1:
			return false;
		case 2:
			return false;
		case 3:
			return false;
		case 4:
			return false;
		case 7:
			return false;
		case 9:
			return false;
		case 12:
			return false;
		case 11:
			return false;
		default:
			return false;
	}
};
var $author$project$Model$loggedInUserAudioStatusBasedOnAbility = function (model) {
	var _v0 = model.jF;
	switch (_v0.$) {
		case 0:
			return false;
		case 1:
			return false;
		default:
			var student = _v0.a;
			return student.gk;
	}
};
var $author$project$Routing$Updates$autoplayPollyAudioIfEnabled = F4(
	function (newRoute, _v0, _v1, _v2) {
		var audioEnabled = _v0.gj;
		var model = _v2.a;
		var existingEffects = _v2.b;
		var isActiveWebApp = function (studentActivity) {
			return $author$project$Models$Activity$isWebApp(studentActivity.f_) && (!($author$project$Models$StudentActivity$hasCompletedContentForActiveActivity(studentActivity) || $author$project$Models$StudentActivity$isCompleted(studentActivity)));
		};
		var onWebActivityPage = $author$project$Routes$isActivityPracticeRoute(newRoute) && A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			false,
			isActiveWebApp,
			$author$project$Model$studentActivityForCurrentRoute(model));
		return ((!$author$project$Routes$isAssessment(newRoute)) && (audioEnabled || (onWebActivityPage && $author$project$Model$loggedInUserAudioStatusBasedOnAbility(model)))) ? A2(
			$author$project$Routing$Updates$autoplayPollyAudio,
			newRoute,
			_Utils_Tuple2(model, existingEffects)) : _Utils_Tuple2(model, existingEffects);
	});
var $author$project$Routing$Updates$CorrectQuestLoaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routing$Updates$CorrectQuestNotLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Routing$Updates$QuestIsLoading = {$: 1};
var $author$project$Routing$Updates$RouteIsNotForQuest = {$: 3};
var $author$project$Routing$Updates$determineQuestCorrectness = F2(
	function (route, currentQuest) {
		var questCorrectnessFromStudentQuestId = function (expectedStudentQuestId) {
			switch (currentQuest.$) {
				case 3:
					var activeQuest = currentQuest.a;
					return _Utils_eq(expectedStudentQuestId, activeQuest.a_) ? $author$project$Routing$Updates$CorrectQuestLoaded(activeQuest) : $author$project$Routing$Updates$CorrectQuestNotLoaded(expectedStudentQuestId);
				case 0:
					return $author$project$Routing$Updates$CorrectQuestNotLoaded(expectedStudentQuestId);
				case 1:
					return $author$project$Routing$Updates$QuestIsLoading;
				default:
					return $author$project$Routing$Updates$CorrectQuestNotLoaded(expectedStudentQuestId);
			}
		};
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			$author$project$Routing$Updates$RouteIsNotForQuest,
			questCorrectnessFromStudentQuestId,
			$author$project$Routes$studentQuestIdFromRoute(route));
	});
var $author$project$LoggedIn$Messages$CheckIfStudentQuestIsStillLoading = {$: 16};
var $author$project$EventPublisher$Event$StudentClickstreamViewQuest = function (a) {
	return {$: 18, a: a};
};
var $author$project$LoggedIn$Messages$LoadStudentQuestResponse = function (a) {
	return {$: 13, a: a};
};
var $author$project$Models$ActiveQuest$ActiveQuest = F8(
	function (quest, assignment, studentQuestId, masteryShown, studentActivities, studentVideo, beingCompleted, loadedAt) {
		return {dq: assignment, du: beingCompleted, eq: loadedAt, es: masteryShown, it: quest, jd: studentActivities, a_: studentQuestId, jg: studentVideo};
	});
var $author$project$Models$ActiveQuest$decoder = A8(
	$elm$json$Json$Decode$map7,
	$author$project$Models$ActiveQuest$ActiveQuest,
	A2($elm$json$Json$Decode$field, 'quest', $author$project$Models$Quest$decoder),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['student_quest', 'assignment']),
		$elm$json$Json$Decode$bool),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['student_quest', 'id']),
		$author$project$Models$Ids$decoder),
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['student_quest', 'mastery_shown']),
				$elm$json$Json$Decode$bool),
				$elm$json$Json$Decode$succeed(false)
			])),
	A2(
		$elm$json$Json$Decode$field,
		'student_activities',
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$List$sortBy($author$project$Models$StudentActivity$displaySequence),
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$List$filterMap($elm$core$Basics$identity),
				$elm$json$Json$Decode$list(
					$elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[
								A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Models$StudentActivity$decoder),
								$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
							])))))),
	A2(
		$elm$json$Json$Decode$field,
		'student_video',
		$elm$json$Json$Decode$maybe($author$project$Models$StudentVideo$decoder)),
	$elm$json$Json$Decode$succeed(false));
var $author$project$Api$HttpRetry$sendTask = F2(
	function (resultToMessage, task) {
		return A3($author$project$Api$HttpRetry$sendTaskWithConfig, $author$project$Api$HttpRetry$defaultConfig, resultToMessage, task);
	});
var $author$project$Api$RequestHelper$withCurrentTime = F2(
	function (decoder, request) {
		var task = A2($author$project$Api$RequestHelper$toTask, decoder, request);
		return A3(
			$elm$core$Task$map2,
			F2(
				function (almostDecodedQuest, time) {
					return almostDecodedQuest(time);
				}),
			task,
			$elm$time$Time$now);
	});
var $author$project$Api$StudentQuestApi$fetch = F3(
	function (id, loginInfo, appConfig) {
		var decoder = A2(
			$elm$json$Json$Decode$map,
			function (activeQuest) {
				return activeQuest(
					$elm$time$Time$millisToPosix(1000));
			},
			$author$project$Models$ActiveQuest$decoder);
		return A2(
			$author$project$Api$HttpRetry$sendTask,
			$author$project$LoggedIn$Messages$LoadStudentQuestResponse,
			A2(
				$author$project$Api$RequestHelper$withCurrentTime,
				$author$project$Models$ActiveQuest$decoder,
				A2(
					$author$project$Api$AuthedRequest$get,
					'/api/v5/student_quests/' + $author$project$Models$Ids$toString(id),
					$author$project$Api$StudentQuestApi$addLoginMethodToQueryParams(
						A5($author$project$Api$RequestHelper$createConfig, $author$project$LoggedIn$Messages$LoadStudentQuestResponse, loginInfo, appConfig, 0, decoder)))));
	});
var $author$project$Routing$Updates$loadStudentQuest = F3(
	function (studentQuestId, loginInfo, _v0) {
		var model = _v0.a;
		var previousEffects = _v0.b;
		var activeQuest = $krisajenkins$remotedata$RemoteData$toMaybe(model.fZ);
		var currentQuestId = A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.it;
				},
				function ($) {
					return $.hj;
				}),
			activeQuest);
		var defaultQuestId = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Models$Ids$fromString('empty'),
			currentQuestId);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{fZ: $krisajenkins$remotedata$RemoteData$Loading}),
			$author$project$Effect$batch(
				_List_fromArray(
					[
						$author$project$Effect$CmdEffect(
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Messages$LoggedInRequiredMsg,
							A3($author$project$Api$StudentQuestApi$fetch, studentQuestId, loginInfo, model.f7))),
						previousEffects,
						A2(
						$author$project$Effect$PerformLaterEffect,
						5000,
						$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$CheckIfStudentQuestIsStillLoading)),
						$author$project$Effect$GetCurrentTimeEffect(
						A2(
							$elm$core$Basics$composeR,
							$author$project$LoggedIn$Messages$PublishEventTimestamped(
								$author$project$EventPublisher$Event$StudentClickstreamViewQuest(
									{
										iu: A2(
											$elm$core$Maybe$withDefault,
											0,
											$author$project$Models$Ids$toInt(defaultQuestId)),
										ji: A2(
											$elm$core$Maybe$withDefault,
											0,
											$author$project$Models$Ids$toInt(studentQuestId))
									})),
							$author$project$Messages$LoggedInRequiredMsg))
					])));
	});
var $author$project$Effect$LogMessageEffect = function (a) {
	return {$: 61, a: a};
};
var $author$project$Routing$Updates$validateActivityDataFromRoute = F3(
	function (newRoute, activeQuest, _v0) {
		var model = _v0.a;
		var previousEffects = _v0.b;
		var handleCheckWrongActivityRoute = function (studentActivityIdFromRoute) {
			var _v4 = A2($author$project$Helpers$RecordFinders$findStudentActivityFromActiveQuest, studentActivityIdFromRoute, activeQuest);
			if (_v4.$ === 1) {
				return _Utils_Tuple2(
					model,
					$author$project$Effect$batch(
						_List_fromArray(
							[
								$author$project$Effect$UpdateUrlEffect(
								$author$project$Routes$routeToUrlString(
									$author$project$Routes$QuestRoute(
										$author$project$Routes$QuestRoutes$Home(activeQuest.a_)))),
								$author$project$Effect$LogMessageEffect(
								{
									gL: _List_fromArray(
										[
											_Utils_Tuple2(
											'url',
											$elm$json$Json$Encode$string(model.jE)),
											_Utils_Tuple2(
											'newRouteUrl',
											$elm$json$Json$Encode$string(
												$author$project$Routes$routeToUrlString(newRoute))),
											_Utils_Tuple2(
											'student_activity_id_from_route',
											$author$project$Models$Ids$encoder(studentActivityIdFromRoute)),
											_Utils_Tuple2(
											'quest_student_activity_ids',
											A2(
												$elm$json$Json$Encode$list,
												$author$project$Models$Ids$encoder,
												A2(
													$elm$core$List$map,
													function ($) {
														return $.hj;
													},
													activeQuest.jd)))
										]),
									gP: _List_fromArray(
										[0, 1]),
									hK: 'Route Error: Wrong activity',
									jp: _List_Nil
								})
							])));
			} else {
				return _Utils_Tuple2(model, previousEffects);
			}
		};
		_v1$4:
		while (true) {
			switch (newRoute.$) {
				case 8:
					var activityRoute = newRoute.a;
					return handleCheckWrongActivityRoute(
						$author$project$Routes$ActivityRoutes$studentActivityId(activityRoute));
				case 10:
					var quizRoute = newRoute.a;
					return handleCheckWrongActivityRoute(
						$author$project$Routes$QuizRoutes$studentActivityId(quizRoute));
				case 9:
					switch (newRoute.a.$) {
						case 0:
							var _v2 = activeQuest.jg;
							if (_v2.$ === 1) {
								return _Utils_Tuple2(
									model,
									$author$project$Effect$batch(
										_List_fromArray(
											[
												$author$project$Effect$UpdateUrlEffect(
												$author$project$Routes$routeToUrlString(
													$author$project$Routes$QuestRoute(
														$author$project$Routes$QuestRoutes$Home(activeQuest.a_)))),
												$author$project$Effect$LogMessageEffect(
												{
													gL: _List_fromArray(
														[
															_Utils_Tuple2(
															'url',
															$elm$json$Json$Encode$string(model.jE)),
															_Utils_Tuple2(
															'newRouteUrl',
															$elm$json$Json$Encode$string(
																$author$project$Routes$routeToUrlString(newRoute)))
														]),
													gP: _List_fromArray(
														[0, 1]),
													hK: 'Route Error: Student video missing',
													jp: _List_Nil
												})
											])));
							} else {
								return _Utils_Tuple2(model, previousEffects);
							}
						case 1:
							var _v3 = activeQuest.jg;
							if (_v3.$ === 1) {
								return _Utils_Tuple2(
									model,
									$author$project$Effect$batch(
										_List_fromArray(
											[
												$author$project$Effect$UpdateUrlEffect(
												$author$project$Routes$routeToUrlString(
													$author$project$Routes$QuestRoute(
														$author$project$Routes$QuestRoutes$Home(activeQuest.a_)))),
												$author$project$Effect$LogMessageEffect(
												{
													gL: _List_fromArray(
														[
															_Utils_Tuple2(
															'url',
															$elm$json$Json$Encode$string(model.jE)),
															_Utils_Tuple2(
															'newRouteUrl',
															$elm$json$Json$Encode$string(
																$author$project$Routes$routeToUrlString(newRoute)))
														]),
													gP: _List_fromArray(
														[0, 1]),
													hK: 'Route Error: Student video missing',
													jp: _List_Nil
												})
											])));
							} else {
								return _Utils_Tuple2(model, previousEffects);
							}
						default:
							break _v1$4;
					}
				default:
					break _v1$4;
			}
		}
		return _Utils_Tuple2(model, previousEffects);
	});
var $author$project$Routing$Updates$updatesBasedOnQuestState = F4(
	function (route, currentQuest, student, loginInfo) {
		var _v0 = A2($author$project$Routing$Updates$determineQuestCorrectness, route, currentQuest);
		switch (_v0.$) {
			case 2:
				var desiredStudentQuestId = _v0.a;
				return A2($author$project$Routing$Updates$loadStudentQuest, desiredStudentQuestId, loginInfo);
			case 0:
				var activeQuest = _v0.a;
				return A2(
					$elm$core$Basics$composeR,
					A3($author$project$Routing$Updates$autoplayPollyAudioIfEnabled, route, student, loginInfo),
					A2($author$project$Routing$Updates$validateActivityDataFromRoute, route, activeQuest));
			case 1:
				return $elm$core$Basics$identity;
			default:
				return A3($author$project$Routing$Updates$autoplayPollyAudioIfEnabled, route, student, loginInfo);
		}
	});
var $author$project$Routing$Updates$forRouteLoggedIn = F4(
	function (newRoute, student, loginInfo, _v0) {
		var model = _v0.a;
		var existingCommands = _v0.b;
		var routeChangeCommands = A2(
			$elm$core$Basics$composeR,
			A3($author$project$Routing$Updates$makeUpdatesForNewRoute, newRoute, student, loginInfo),
			A2(
				$elm$core$Basics$composeR,
				$author$project$Routing$Updates$handleLogoutIfAppropriate(newRoute),
				A2(
					$elm$core$Basics$composeR,
					A4($author$project$Routing$Updates$updatesBasedOnQuestState, newRoute, model.fZ, student, loginInfo),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Routing$Updates$resetVideoPlayState(newRoute),
						A2(
							$elm$core$Basics$composeR,
							$author$project$Routing$Updates$resetVideoRecordingState,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Routing$Updates$trackMostRecentActivity(newRoute),
								A2(
									$elm$core$Basics$composeR,
									A3($author$project$Routing$Updates$setResumeUrl, newRoute, student, loginInfo),
									A2(
										$elm$core$Basics$composeR,
										$author$project$Routing$Updates$updateEsparkIframeWithQuestData(newRoute),
										A2(
											$elm$core$Basics$composeR,
											A3($author$project$Routing$Updates$storeDebugData, newRoute, student, loginInfo),
											A2(
												$elm$core$Basics$composeR,
												$author$project$Routing$Updates$focusOnLoginView(newRoute),
												A2($author$project$Routing$Updates$ensureQuestStateAllowsRoute, newRoute, student)))))))))));
		return A3(
			$author$project$Routing$Updates$forRoute,
			newRoute,
			routeChangeCommands,
			_Utils_Tuple2(model, existingCommands));
	});
var $author$project$Ports$initializeYoutube = _Platform_outgoingPort(
	'initializeYoutube',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$AuthPersistence$loginInfoToJSON = function (loginInfo) {
	var jsonValue = function () {
		var tokenJSON = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'access_token',
					$elm$json$Json$Encode$string(loginInfo.fX)),
					_Utils_Tuple2(
					'login_method',
					$elm$json$Json$Encode$string(
						$author$project$Models$LoggingIn$loginMethodToString(loginInfo.hB)))
				]));
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2('authToken', tokenJSON)
				]));
	}();
	return A2($elm$json$Json$Encode$encode, 0, jsonValue);
};
var $author$project$Ports$performAndLogDeviceCheck = _Platform_outgoingPort(
	'performAndLogDeviceCheck',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$silenceLogger = _Platform_outgoingPort(
	'silenceLogger',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$storeUserCredentials = _Platform_outgoingPort('storeUserCredentials', $elm$json$Json$Encode$string);
var $author$project$HealthChecks$updateTTSHealth = F2(
	function (updatedTTSHealth, appHealth) {
		return _Utils_update(
			appHealth,
			{jA: updatedTTSHealth});
	});
var $author$project$HealthChecks$TTS$useHtmlAudio = function (model) {
	return _Utils_update(
		model,
		{jm: true});
};
var $author$project$Helpers$CommandHelper$onUserBecomesKnown = F3(
	function (student, loginInfo, modelWithPossibleUpdates) {
		var youtubeCmd = $elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Ports$initializeYoutube(0),
					A2($author$project$Helpers$Task$performWithDelay, 5000, $author$project$Messages$SetYoutubeToUnavailableIfNotKnown)
				]));
		var versionUpdates = F3(
			function (_v3, currentLoginInfo, _v4) {
				var currentModel = _v4.a;
				var currentCommands = _v4.b;
				var silenceLogger = A2($author$project$Models$ProductFeature$isEnabled, 0, student.iq) ? $author$project$Ports$silenceLogger(0) : $elm$core$Platform$Cmd$none;
				var performDeviceCheck = A2($author$project$Models$ProductFeature$isEnabled, 2, student.iq) ? $author$project$Ports$performAndLogDeviceCheck(0) : $elm$core$Platform$Cmd$none;
				var enableHotjarTracking = A2($author$project$Models$ProductFeature$isEnabled, 5, student.iq) ? A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					$elm$core$Platform$Cmd$none,
					$author$project$Ports$enableHotjarTracking,
					A3(
						$elm$core$Maybe$map2,
						$elm$core$Tuple$pair,
						$author$project$Models$Ids$toInt(student.b1),
						$author$project$Models$Ids$toInt(student.ib.hj))) : $elm$core$Platform$Cmd$none;
				var initialCommands = $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A2(
							$author$project$Helpers$Task$performWithDelay,
							5000,
							$author$project$Messages$LogStateOnUserBecomesKnown(modelWithPossibleUpdates.f9)),
							$author$project$Ports$storeUserCredentials(
							$author$project$AuthPersistence$loginInfoToJSON(loginInfo)),
							$author$project$Ports$retrieveStoredStudentVideos(0),
							$author$project$Ports$storeUserCredentials(
							$author$project$AuthPersistence$loginInfoToJSON(loginInfo)),
							silenceLogger,
							performDeviceCheck,
							enableHotjarTracking,
							youtubeCmd
						]));
				var _v2 = A2($author$project$Models$ProductFeature$isEnabled, 7, student.iq) ? _Utils_Tuple2(
					_Utils_update(
						modelWithPossibleUpdates,
						{
							ga: A2(
								$author$project$HealthChecks$updateTTSHealth,
								$author$project$HealthChecks$TTS$useHtmlAudio(modelWithPossibleUpdates.ga.jA),
								modelWithPossibleUpdates.ga)
						}),
					$author$project$Ports$switchToHtmlAudio(0)) : _Utils_Tuple2(modelWithPossibleUpdates, $elm$core$Platform$Cmd$none);
				var modelWithTTSUpdate = _v2.a;
				var ttsCommand = _v2.b;
				return _Utils_Tuple2(
					modelWithTTSUpdate,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[initialCommands, currentCommands, ttsCommand])));
			});
		var sendEventMsgForLogin = function (_v1) {
			var otherModel = _v1.a;
			var effects = _v1.b;
			return _Utils_Tuple2(
				otherModel,
				A2(
					$author$project$Effect$add,
					effects,
					$author$project$Effect$GetCurrentTimeEffect(
						A2(
							$elm$core$Basics$composeR,
							$author$project$LoggedIn$Messages$PublishEventTimestamped($author$project$EventPublisher$Event$StudentClickstreamLogin),
							$author$project$Messages$LoggedInRequiredMsg))));
		};
		var sendEventMsgForHeartbeat = function (_v0) {
			var otherModel = _v0.a;
			var effects = _v0.b;
			return _Utils_Tuple2(
				otherModel,
				A2($author$project$Effect$add, effects, $author$project$Effect$SetHeartbeatDataEffect));
		};
		return sendEventMsgForHeartbeat(
			sendEventMsgForLogin(
				A4(
					$author$project$Routing$Updates$forRouteLoggedIn,
					modelWithPossibleUpdates.eY,
					student,
					loginInfo,
					A3(
						$author$project$Helpers$InitialSessionFlow$checkForUpdates,
						student,
						loginInfo,
						A3(
							versionUpdates,
							student,
							loginInfo,
							_Utils_Tuple2(modelWithPossibleUpdates, $elm$core$Platform$Cmd$none))))));
	});
var $author$project$JoinCode$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var updatedJoinCode = msg.a;
				return _Utils_update(
					model,
					{
						ht: $elm$core$String$toUpper(updatedJoinCode)
					});
			case 1:
				return _Utils_update(
					model,
					{cZ: $krisajenkins$remotedata$RemoteData$Loading});
			case 2:
				if (!msg.a.$) {
					var response = msg.a.a;
					return _Utils_update(
						model,
						{
							cZ: $krisajenkins$remotedata$RemoteData$Success(response)
						});
				} else {
					var error = msg.a.a;
					return _Utils_update(
						model,
						{
							cZ: $krisajenkins$remotedata$RemoteData$Failure(error)
						});
				}
			case 3:
				return model;
			default:
				return model;
		}
	});
var $author$project$Main$handleJoinCodeUpdate = F4(
	function (backRoute, joinCodeMsg, joinCodeModel, model) {
		var updatedJoinCodeModel = A2($author$project$JoinCode$update, joinCodeMsg, joinCodeModel);
		var updatedModel = _Utils_update(
			model,
			{
				eY: A2($author$project$Routes$JoinCodeRoute, backRoute, updatedJoinCodeModel)
			});
		switch (joinCodeMsg.$) {
			case 1:
				var _v1 = model.jF;
				if (_v1.$ === 2) {
					var loginInfo = _v1.b;
					return _Utils_Tuple2(
						updatedModel,
						A2($author$project$Effect$UserJoinNewSectionEffect, updatedJoinCodeModel.ht, loginInfo));
				} else {
					return A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						_Utils_Tuple2(updatedModel, $author$project$Effect$NoEffect),
						function (key) {
							return _Utils_Tuple2(
								updatedModel,
								$author$project$Effect$UserLoginWithJoinCodeEffect(
									{gm: key, ht: updatedJoinCodeModel.ht}));
						},
						updatedJoinCodeModel.gm);
				}
			case 2:
				if (!joinCodeMsg.a.$) {
					var student = joinCodeMsg.a.a.jb;
					var auth = joinCodeMsg.a.a.gl;
					var updatedLoginInfo = A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						auth,
						function (method) {
							return _Utils_update(
								auth,
								{hB: method});
						},
						updatedJoinCodeModel.hB);
					return A2(
						$elm$core$Tuple$mapSecond,
						$author$project$Effect$add(
							$author$project$Effect$UpdateUrlEffect(
								$author$project$Routes$routeToUrlString($author$project$Routes$homeRoute))),
						A3(
							$author$project$Helpers$CommandHelper$onUserBecomesKnown,
							student,
							updatedLoginInfo,
							_Utils_update(
								updatedModel,
								{
									jF: A2($author$project$Models$User$LoggedIn, student, updatedLoginInfo)
								})));
				} else {
					return _Utils_Tuple2(updatedModel, $author$project$Effect$NoEffect);
				}
			case 0:
				return _Utils_Tuple2(updatedModel, $author$project$Effect$NoEffect);
			case 3:
				var redirectUrl = joinCodeMsg.a;
				return _Utils_Tuple2(
					updatedModel,
					$author$project$Effect$LoadUrlEffect(redirectUrl));
			default:
				return _Utils_Tuple2(
					updatedModel,
					$author$project$Effect$UpdateUrlEffect(
						A2(
							$elm$core$Maybe$withDefault,
							$author$project$Routes$routeToUrlString($author$project$Routes$homeRoute),
							backRoute)));
		}
	});
var $author$project$Messages$ClearDisabledByClick = {$: 7};
var $author$project$Effect$ReloadAppCodeEffect = {$: 8};
var $author$project$Routes$isRefreshAppContentOk = function (route) {
	switch (route.$) {
		case 8:
			var activityRoute = route.a;
			return !$author$project$Routes$ActivityRoutes$isAssessment(activityRoute);
		case 0:
			return true;
		case 9:
			return true;
		default:
			return false;
	}
};
var $author$project$Helpers$CommandHelper$resetOngoingAnimation = function (_v0) {
	var updatingModel = _v0.a;
	var existingEffect = _v0.b;
	return _Utils_Tuple2(
		_Utils_update(
			updatingModel,
			{hq: false}),
		existingEffect);
};
var $author$project$Main$initializeNewRoute = F2(
	function (newRoute, _v0) {
		var model = _v0.a;
		var effects = _v0.b;
		return (model.cF && $author$project$Routes$isRefreshAppContentOk(newRoute)) ? _Utils_Tuple2(model, $author$project$Effect$ReloadAppCodeEffect) : $author$project$Helpers$CommandHelper$resetOngoingAnimation(
			A2(
				$elm$core$Tuple$mapSecond,
				$author$project$Effect$add(
					$author$project$Effect$PerformNowEffect($author$project$Messages$ClearDisabledByClick)),
				A4(
					$author$project$Model$withLoggedInUser,
					model,
					$author$project$Routing$Updates$forRouteNotLoggedIn(newRoute),
					$author$project$Routing$Updates$forRouteLoggedIn(newRoute),
					_Utils_Tuple2(model, effects))));
	});
var $author$project$Routes$ActivityRoutes$isComponent = function (route) {
	switch (route.$) {
		case 0:
			return false;
		case 1:
			return false;
		case 2:
			return false;
		default:
			return true;
	}
};
var $author$project$Routes$isComponent = function (route) {
	switch (route.$) {
		case 8:
			var activityRoute = route.a;
			return $author$project$Routes$ActivityRoutes$isComponent(activityRoute);
		case 6:
			return true;
		default:
			return false;
	}
};
var $author$project$LoginApp$Main$routeCleanupComplete = function (user) {
	if (user.$ === 1) {
		var loginState = user.a;
		return $author$project$Models$User$NotLoggedIn(
			_Utils_update(
				loginState,
				{bg: 0}));
	} else {
		return user;
	}
};
var $author$project$Main$handleRouteCleanupComplete = F2(
	function (source, model) {
		switch (source) {
			case 0:
				return A2(
					$author$project$Main$initializeNewRoute,
					model.hV,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
			case 1:
				var updatedModel = _Utils_update(
					model,
					{
						jF: $author$project$LoginApp$Main$routeCleanupComplete(model.jF)
					});
				return A2(
					$author$project$Main$initializeNewRoute,
					model.hV,
					_Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none));
			default:
				return (_Utils_eq(model.eY, model.hV) && $author$project$Routes$isComponent(model.hV)) ? _Utils_Tuple2(model, $author$project$Effect$NoEffect) : A2(
					$author$project$Main$initializeNewRoute,
					model.hV,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
		}
	});
var $author$project$Effect$SwitchToHtmlAudioEffect = {$: 43};
var $author$project$HealthChecks$TTS$CheckIfSpeechFinishedInExpectedTime = function (a) {
	return {$: 9, a: a};
};
var $author$project$HealthChecks$TTS$Ended = {$: 2};
var $author$project$HealthChecks$TTS$PerformAfterDelay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$HealthChecks$TTS$Playing = {$: 1};
var $author$project$HealthChecks$TTS$Stopped = {$: 3};
var $author$project$HealthChecks$TTS$LogError = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$HealthChecks$TTS$handlePortError = F3(
	function (portName, decodeError, model) {
		return _Utils_Tuple2(
			model,
			_List_fromArray(
				[
					A2(
					$author$project$HealthChecks$TTS$LogError,
					'HealthChecks.TTS Port decode failure',
					_List_fromArray(
						[
							_Utils_Tuple2(
							'error',
							$elm$json$Json$Encode$string(
								$elm$json$Json$Decode$errorToString(decodeError))),
							_Utils_Tuple2(
							'port',
							$elm$json$Json$Encode$string(portName))
						]))
				]));
	});
var $author$project$HealthChecks$TTS$LogEvent = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$HealthChecks$TTS$logWithEventData = F5(
	function (eventName, updateContext, eventData, _v0, model) {
		var hasChangedPage = _v0.bD;
		return A2(
			$author$project$HealthChecks$TTS$LogEvent,
			eventName,
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$elm$core$Maybe$Just(
						_Utils_Tuple2(
							'currentUrl',
							$elm$json$Json$Encode$string(updateContext.V))),
						$elm$core$Maybe$Just(
						_Utils_Tuple2(
							'ttsActiveLength',
							$elm$json$Json$Encode$int(
								(($elm$time$Time$posixToMillis(eventData.cf) - $elm$time$Time$posixToMillis(updateContext.fA)) / 1000) | 0))),
						$elm$core$Maybe$Just(
						_Utils_Tuple2(
							'speechId',
							$elm$json$Json$Encode$string(eventData.h))),
						$elm$core$Maybe$Just(
						_Utils_Tuple2(
							'hasInteractedWithPage',
							$elm$json$Json$Encode$bool(updateContext.d1))),
						$elm$core$Maybe$Just(
						_Utils_Tuple2(
							'switchedToHtmlAudio',
							$elm$json$Json$Encode$bool(model.jm))),
						$elm$core$Maybe$Just(
						_Utils_Tuple2(
							'hasChangedPage',
							$elm$json$Json$Encode$bool(hasChangedPage))),
						A2(
						$elm$core$Maybe$map,
						function (duration) {
							return _Utils_Tuple2(
								'duration',
								$elm$json$Json$Encode$float(duration));
						},
						eventData.dQ),
						A2(
						$elm$core$Maybe$map,
						function (error) {
							return _Utils_Tuple2(
								'error',
								$elm$json$Json$Encode$string(error));
						},
						eventData.a9),
						A2(
						$elm$core$Maybe$map,
						function (additionalData) {
							return _Utils_Tuple2('additionalData', additionalData);
						},
						eventData.dj)
					])));
	});
var $author$project$HealthChecks$TTS$Errored = function (a) {
	return {$: 4, a: a};
};
var $author$project$HealthChecks$TTS$PlaybackError = 0;
var $author$project$HealthChecks$TTS$SwitchToHtmlAudio = {$: 3};
var $author$project$HealthChecks$TTS$onSpeechPlaybackErrored = F4(
	function (eventData, updateContext, internalContext, model) {
		var isAudioDecodingIssue = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			false,
			$elm$core$String$contains('Decoding audio data failed'),
			eventData.a9);
		var _v0 = model.k;
		var speechesDict = _v0.b;
		var _v1 = isAudioDecodingIssue ? _Utils_Tuple2(
			_Utils_update(
				model,
				{jm: true}),
			_List_fromArray(
				[$author$project$HealthChecks$TTS$SwitchToHtmlAudio])) : _Utils_Tuple2(model, _List_Nil);
		var modelWithHtmlSwitchUpdates = _v1.a;
		var htmlSwitchParentMsgs = _v1.b;
		var modelWithSpeechUpdate = function () {
			var _v2 = A2($elm$core$Dict$get, eventData.h, speechesDict);
			if (!_v2.$) {
				return _Utils_update(
					modelWithHtmlSwitchUpdates,
					{
						k: A2(
							$author$project$HealthChecks$TTS$SpeechPool,
							updateContext.V,
							A3(
								$elm$core$Dict$update,
								eventData.h,
								$elm$core$Basics$always(
									$elm$core$Maybe$Just(
										$author$project$HealthChecks$TTS$Errored(0))),
								speechesDict))
					});
			} else {
				return modelWithHtmlSwitchUpdates;
			}
		}();
		return _Utils_Tuple2(
			modelWithSpeechUpdate,
			A2(
				$elm$core$List$cons,
				A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechPlaybackErrored', updateContext, eventData, internalContext, model),
				htmlSwitchParentMsgs));
	});
var $author$project$HealthChecks$TTS$CheckIfSpeechNotPlayedIn2Seconds = function (a) {
	return {$: 7, a: a};
};
var $author$project$HealthChecks$TTS$CheckIfSpeechNotPlayedIn4Seconds = function (a) {
	return {$: 8, a: a};
};
var $author$project$HealthChecks$TTS$Requested = {$: 0};
var $author$project$HealthChecks$TTS$onSpeechRequested = F4(
	function (eventData, updateContext, internalContext, model) {
		var _v0 = model.k;
		var speechesDict = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					k: A2(
						$author$project$HealthChecks$TTS$SpeechPool,
						updateContext.V,
						A3($elm$core$Dict$insert, eventData.h, $author$project$HealthChecks$TTS$Requested, speechesDict))
				}),
			_List_fromArray(
				[
					A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechRequested', updateContext, eventData, internalContext, model),
					A2(
					$author$project$HealthChecks$TTS$PerformAfterDelay,
					2000,
					$author$project$HealthChecks$TTS$CheckIfSpeechNotPlayedIn2Seconds(eventData)),
					A2(
					$author$project$HealthChecks$TTS$PerformAfterDelay,
					4000,
					$author$project$HealthChecks$TTS$CheckIfSpeechNotPlayedIn4Seconds(eventData))
				]));
	});
var $author$project$HealthChecks$TTS$SynthesisError = 1;
var $author$project$HealthChecks$TTS$onSpeechSynthesisErrored = F4(
	function (eventData, updateContext, internalContext, model) {
		var _v0 = model.k;
		var speechesDict = _v0.b;
		return _Utils_Tuple2(
			function () {
				var _v1 = A2($elm$core$Dict$get, eventData.h, speechesDict);
				if (!_v1.$) {
					return _Utils_update(
						model,
						{
							k: A2(
								$author$project$HealthChecks$TTS$SpeechPool,
								updateContext.V,
								A3(
									$elm$core$Dict$update,
									eventData.h,
									$elm$core$Basics$always(
										$elm$core$Maybe$Just(
											$author$project$HealthChecks$TTS$Errored(1))),
									speechesDict))
						});
				} else {
					return model;
				}
			}(),
			_List_fromArray(
				[
					A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechSynthesisErrored', updateContext, eventData, internalContext, model)
				]));
	});
var $author$project$HealthChecks$TTS$speechActive = F2(
	function (speechId, _v0) {
		var speechesDict = _v0.b;
		var _v1 = A2($elm$core$Dict$get, speechId, speechesDict);
		if (_v1.$ === 1) {
			return false;
		} else {
			switch (_v1.a.$) {
				case 0:
					var _v2 = _v1.a;
					return true;
				case 1:
					var _v3 = _v1.a;
					return true;
				case 2:
					var _v4 = _v1.a;
					return false;
				case 3:
					var _v5 = _v1.a;
					return false;
				default:
					return false;
			}
		}
	});
var $author$project$HealthChecks$TTS$waitingToPlay = F2(
	function (speechId, _v0) {
		var speechesDict = _v0.b;
		var _v1 = A2($elm$core$Dict$get, speechId, speechesDict);
		if (_v1.$ === 1) {
			return false;
		} else {
			switch (_v1.a.$) {
				case 0:
					var _v2 = _v1.a;
					return true;
				case 1:
					var _v3 = _v1.a;
					return false;
				case 2:
					var _v4 = _v1.a;
					return false;
				case 3:
					var _v5 = _v1.a;
					return false;
				default:
					return false;
			}
		}
	});
var $author$project$HealthChecks$TTS$handleMessagesFromSamePage = F3(
	function (updateContext, msg, model) {
		var internalContext = {bD: false};
		var _v0 = model.k;
		var speechesDict = _v0.b;
		switch (msg.$) {
			case 0:
				var eventData = msg.a;
				return A4($author$project$HealthChecks$TTS$onSpeechRequested, eventData, updateContext, internalContext, model);
			case 1:
				var eventData = msg.a;
				var _v2 = A2($elm$core$Dict$get, eventData.h, speechesDict);
				if (!_v2.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								k: A2(
									$author$project$HealthChecks$TTS$SpeechPool,
									updateContext.V,
									A3(
										$elm$core$Dict$update,
										eventData.h,
										$elm$core$Basics$always(
											$elm$core$Maybe$Just($author$project$HealthChecks$TTS$Playing)),
										speechesDict))
							}),
						A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$elm$core$Maybe$Just(
									A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechPlayed', updateContext, eventData, internalContext, model)),
									A2(
									$elm$core$Maybe$map,
									function (duration) {
										return A2(
											$author$project$HealthChecks$TTS$PerformAfterDelay,
											duration + 1000,
											$author$project$HealthChecks$TTS$CheckIfSpeechFinishedInExpectedTime(eventData));
									},
									eventData.dQ)
								])));
				} else {
					return _Utils_Tuple2(model, _List_Nil);
				}
			case 2:
				var eventData = msg.a;
				var _v3 = A2($elm$core$Dict$get, eventData.h, speechesDict);
				if (!_v3.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								k: A2(
									$author$project$HealthChecks$TTS$SpeechPool,
									updateContext.V,
									A3(
										$elm$core$Dict$update,
										eventData.h,
										$elm$core$Basics$always(
											$elm$core$Maybe$Just($author$project$HealthChecks$TTS$Ended)),
										speechesDict))
							}),
						_List_fromArray(
							[
								A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechEnded', updateContext, eventData, internalContext, model)
							]));
				} else {
					return _Utils_Tuple2(model, _List_Nil);
				}
			case 3:
				var eventData = msg.a;
				var _v4 = A2($elm$core$Dict$get, eventData.h, speechesDict);
				if (!_v4.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								k: A2(
									$author$project$HealthChecks$TTS$SpeechPool,
									updateContext.V,
									A3(
										$elm$core$Dict$update,
										eventData.h,
										$elm$core$Basics$always(
											$elm$core$Maybe$Just($author$project$HealthChecks$TTS$Stopped)),
										speechesDict))
							}),
						_List_fromArray(
							[
								A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechStopped', updateContext, eventData, internalContext, model)
							]));
				} else {
					return _Utils_Tuple2(model, _List_Nil);
				}
			case 4:
				var eventData = msg.a;
				return A4($author$project$HealthChecks$TTS$onSpeechPlaybackErrored, eventData, updateContext, internalContext, model);
			case 5:
				var eventData = msg.a;
				return A4($author$project$HealthChecks$TTS$onSpeechSynthesisErrored, eventData, updateContext, internalContext, model);
			case 6:
				var eventData = msg.a;
				var _v5 = A2($elm$core$Dict$get, eventData.h, speechesDict);
				if (!_v5.$) {
					return _Utils_Tuple2(
						model,
						_List_fromArray(
							[
								A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechErroredAfterSynthesis', updateContext, eventData, internalContext, model)
							]));
				} else {
					return _Utils_Tuple2(model, _List_Nil);
				}
			case 11:
				var eventData = msg.a;
				var _v6 = A2($elm$core$Dict$get, eventData.h, speechesDict);
				if (!_v6.$) {
					return _Utils_Tuple2(
						model,
						_List_fromArray(
							[
								A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechUnlockedAfterAudioErrored', updateContext, eventData, internalContext, model)
							]));
				} else {
					return _Utils_Tuple2(model, _List_Nil);
				}
			case 12:
				var eventData = msg.a;
				var _v7 = A2($elm$core$Dict$get, eventData.h, speechesDict);
				if (!_v7.$) {
					return _Utils_Tuple2(
						model,
						_List_fromArray(
							[
								A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechPlaybackErroredLocked', updateContext, eventData, internalContext, model)
							]));
				} else {
					return _Utils_Tuple2(model, _List_Nil);
				}
			case 13:
				var eventData = msg.a;
				var _v8 = A2($elm$core$Dict$get, eventData.h, speechesDict);
				if (!_v8.$) {
					return _Utils_Tuple2(
						model,
						_List_fromArray(
							[
								A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechPlaybackSucceedAfterUnlock', updateContext, eventData, internalContext, model)
							]));
				} else {
					return _Utils_Tuple2(model, _List_Nil);
				}
			case 7:
				var originalEventData = msg.a;
				return A2($author$project$HealthChecks$TTS$waitingToPlay, originalEventData.h, model.k) ? _Utils_Tuple2(
					model,
					_List_fromArray(
						[
							A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechNotPlayedAfter2Seconds', updateContext, originalEventData, internalContext, model)
						])) : _Utils_Tuple2(model, _List_Nil);
			case 8:
				var originalEventData = msg.a;
				return A2($author$project$HealthChecks$TTS$waitingToPlay, originalEventData.h, model.k) ? _Utils_Tuple2(
					model,
					_List_fromArray(
						[
							A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechNotPlayedAfter4Seconds', updateContext, originalEventData, internalContext, model)
						])) : _Utils_Tuple2(model, _List_Nil);
			case 9:
				var originalEventData = msg.a;
				return A2($author$project$HealthChecks$TTS$speechActive, originalEventData.h, model.k) ? _Utils_Tuple2(
					model,
					_List_fromArray(
						[
							A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechNotFinishedInExpectedTime', updateContext, originalEventData, internalContext, model)
						])) : _Utils_Tuple2(model, _List_Nil);
			case 14:
				var originalEventData = msg.a;
				return A2($author$project$HealthChecks$TTS$speechActive, originalEventData.h, model.k) ? _Utils_Tuple2(
					model,
					_List_fromArray(
						[
							A5($author$project$HealthChecks$TTS$logWithEventData, 'HealthChecks.TTS.SpeechLoadedAfterPlayError', updateContext, originalEventData, internalContext, model)
						])) : _Utils_Tuple2(model, _List_Nil);
			default:
				var portName = msg.a;
				var decodeError = msg.b;
				return A3($author$project$HealthChecks$TTS$handlePortError, portName, decodeError, model);
		}
	});
var $author$project$HealthChecks$TTS$handleMessagesOnPageChange = F3(
	function (updateContext, msg, model) {
		var modelWithNewSpeechPool = _Utils_update(
			model,
			{
				k: A2($author$project$HealthChecks$TTS$SpeechPool, updateContext.V, $elm$core$Dict$empty)
			});
		var internalContext = {bD: true};
		switch (msg.$) {
			case 0:
				var eventData = msg.a;
				return A4($author$project$HealthChecks$TTS$onSpeechRequested, eventData, updateContext, internalContext, modelWithNewSpeechPool);
			case 4:
				var eventData = msg.a;
				return A4($author$project$HealthChecks$TTS$onSpeechPlaybackErrored, eventData, updateContext, internalContext, modelWithNewSpeechPool);
			case 5:
				var eventData = msg.a;
				return A4($author$project$HealthChecks$TTS$onSpeechSynthesisErrored, eventData, updateContext, internalContext, modelWithNewSpeechPool);
			case 10:
				var portName = msg.a;
				var decodeError = msg.b;
				return A3($author$project$HealthChecks$TTS$handlePortError, portName, decodeError, modelWithNewSpeechPool);
			default:
				return _Utils_Tuple2(modelWithNewSpeechPool, _List_Nil);
		}
	});
var $author$project$HealthChecks$TTS$update = F3(
	function (updateContext, msg, model) {
		var _v0 = model.k;
		var previousUrl = _v0.a;
		return (!_Utils_eq(updateContext.V, previousUrl)) ? A3($author$project$HealthChecks$TTS$handleMessagesOnPageChange, updateContext, msg, model) : A3($author$project$HealthChecks$TTS$handleMessagesFromSamePage, updateContext, msg, model);
	});
var $author$project$Main$handleTTSHealthCheckMsg = F2(
	function (ttsHealthCheckMsg, model) {
		var handleTTSHealthParentMsg = function (parentMsg) {
			switch (parentMsg.$) {
				case 0:
					var delay = parentMsg.a;
					var nextMsg = parentMsg.b;
					return A2(
						$author$project$Effect$PerformLaterEffect,
						delay,
						$author$project$Messages$TTSHealthCheckMsg(nextMsg));
				case 1:
					var eventName = parentMsg.a;
					var data = parentMsg.b;
					return $author$project$Effect$LogMessageEffect(
						{
							gL: data,
							gP: _List_fromArray(
								[0]),
							hK: eventName,
							jp: _List_Nil
						});
				case 2:
					var eventName = parentMsg.a;
					var data = parentMsg.b;
					return $author$project$Effect$LogMessageEffect(
						{
							gL: data,
							gP: _List_fromArray(
								[1]),
							hK: eventName,
							jp: _List_Nil
						});
				default:
					return $author$project$Effect$batch(
						_List_fromArray(
							[
								$author$project$Effect$SwitchToHtmlAudioEffect,
								A2(
								$author$project$Effect$PerformLaterEffect,
								100,
								$author$project$Messages$PlayAllAutoplaySpeech($elm$core$Maybe$Nothing))
							]));
			}
		};
		var _v0 = A3(
			$author$project$HealthChecks$TTS$update,
			{
				V: $author$project$Routes$routeToUrlString(model.eY),
				d1: model.d1,
				fA: model.f7.bv
			},
			ttsHealthCheckMsg,
			model.ga.jA);
		var updatedTTSHealth = _v0.a;
		var ttsParentMsgs = _v0.b;
		var effects = $author$project$Effect$batch(
			A2($elm$core$List$map, handleTTSHealthParentMsg, ttsParentMsgs));
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					ga: A2($author$project$HealthChecks$updateTTSHealth, updatedTTSHealth, model.ga)
				}),
			effects);
	});
var $author$project$Models$WindowFocus$WindowFocusLost = function (a) {
	return {$: 1, a: a};
};
var $author$project$Effect$PauseEmbeddedYoutubePlayerEffect = {$: 36};
var $author$project$Effect$PauseVideoEffect = {$: 33};
var $author$project$Effect$PlayEmbeddedYoutubePlayerEffect = {$: 35};
var $author$project$Effect$PlayVideoEffect = function (a) {
	return {$: 32, a: a};
};
var $author$project$Models$ProductFeature$emptyList = _List_Nil;
var $author$project$Main$changeVideoStateOnFocusChange = F3(
	function (hasFocus, studentActivity, _v0) {
		var model = _v0.a;
		var currentEffects = _v0.b;
		var youtubeId = A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.fW;
			},
			A2(
				$elm$core$Maybe$andThen,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.f_;
					},
					$author$project$Models$Activity$youtubeData),
				$author$project$Model$studentActivityForCurrentRoute(model)));
		var productFeatures = A3(
			$author$project$Model$withLoggedInUser,
			model,
			$author$project$Models$ProductFeature$emptyList,
			F2(
				function (student, _v1) {
					return student.iq;
				}));
		var usingYoutube = (!A2($author$project$Models$ProductFeature$isEnabled, 1, productFeatures)) && A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			false,
			$author$project$Models$YoutubeVideoPlayer$canUseEmbeddedPlayer(model.aI),
			youtubeId);
		var pauseEffect = usingYoutube ? $author$project$Effect$PauseEmbeddedYoutubePlayerEffect : $author$project$Effect$PauseVideoEffect;
		var mediaServerVideoKey = $author$project$Models$StudentActivity$activityKey(studentActivity.hj);
		var playEffect = usingYoutube ? $author$project$Effect$PlayEmbeddedYoutubePlayerEffect : $author$project$Effect$PlayVideoEffect(mediaServerVideoKey);
		var effect = ((!hasFocus) && $author$project$Models$VideoPlayer$isPlaying(model.jI)) ? pauseEffect : ((hasFocus && $author$project$Models$VideoPlayer$isPaused(model.jI)) ? playEffect : $author$project$Effect$NoEffect);
		return _Utils_Tuple2(
			model,
			$author$project$Effect$BatchedEffect(
				_List_fromArray(
					[currentEffects, effect])));
	});
var $author$project$Models$WindowFocus$secondsSinceFocusLost = F2(
	function (currentTime, focusState) {
		if (focusState.$ === 1) {
			var lostTime = focusState.a;
			return $elm$core$Maybe$Just(
				function (difference) {
					return (difference / 1000) | 0;
				}(
					$elm$time$Time$posixToMillis(currentTime) - $elm$time$Time$posixToMillis(lostTime)));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Main$handleWindowFocusChange = F3(
	function (currentTime, hasFocus, model) {
		var ttsEffect = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			$author$project$Effect$NoEffect,
			function (seconds) {
				return (seconds > 10) ? $author$project$Effect$ResetTTSEffect : $author$project$Effect$NoEffect;
			},
			A2($author$project$Models$WindowFocus$secondsSinceFocusLost, currentTime, model.fS));
		var focusValue = hasFocus ? $author$project$Models$WindowFocus$WindowHasFocus : $author$project$Models$WindowFocus$WindowFocusLost(currentTime);
		var updatedModel = _Utils_update(
			model,
			{fS: focusValue});
		var _v0 = $author$project$Model$studentActivityForCurrentRoute(model);
		if (!_v0.$) {
			var studentActivity = _v0.a;
			return A3(
				$author$project$Main$changeVideoStateOnFocusChange,
				hasFocus,
				studentActivity,
				_Utils_Tuple2(updatedModel, ttsEffect));
		} else {
			return _Utils_Tuple2(updatedModel, ttsEffect);
		}
	});
var $author$project$EventPublisher$Event$StudentClickstreamEsparkLostFocus = {$: 7};
var $author$project$RandomNumberGenerator$newSeeds = function (seeds) {
	return $TSFoster$elm_uuid$UUID$step(seeds).b;
};
var $author$project$LoggedIn$publishEffectWithUuid = F2(
	function (model, effect) {
		var seeds = $author$project$RandomNumberGenerator$newSeeds(model.cR);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{cR: seeds}),
			effect(
				$author$project$RandomNumberGenerator$uuid(model.cR)));
	});
var $author$project$Main$handleWindowLostFocus = F2(
	function (currentTime, model) {
		return A2(
			$author$project$LoggedIn$publishEffectWithUuid,
			model,
			A2($author$project$Effect$PublishEventEffect, $author$project$EventPublisher$Event$StudentClickstreamEsparkLostFocus, currentTime));
	});
var $author$project$EventPublisher$Event$StudentClickstreamReturnToEspark = {$: 9};
var $author$project$Main$handleWindowRegainedFocus = F2(
	function (currentTime, model) {
		return A2(
			$author$project$LoggedIn$publishEffectWithUuid,
			model,
			A2($author$project$Effect$PublishEventEffect, $author$project$EventPublisher$Event$StudentClickstreamReturnToEspark, currentTime));
	});
var $author$project$Models$WindowFocus$hasFocus = function (focusState) {
	return _Utils_eq(focusState, $author$project$Models$WindowFocus$WindowHasFocus);
};
var $author$project$Models$YoutubeVideoPlayer$initialAvailableStatus = $author$project$Models$YoutubeVideoPlayer$YoutubeIsAvailable(_List_Nil);
var $author$project$Api$RequestHelper$is401 = $elm$core$Basics$eq(
	$elm$http$Http$BadStatus(401));
var $author$project$Logger$logRedshift = F3(
	function (model, message, data) {
		return A2(
			$author$project$Logger$log,
			model,
			{
				gL: data,
				gP: _List_fromArray(
					[0]),
				hK: message,
				jp: _List_Nil
			});
	});
var $author$project$AuthPersistence$loginInfoFromJSON = function (json) {
	var _v0 = A2(
		$elm$json$Json$Decode$decodeString,
		A2(
			$elm$json$Json$Decode$field,
			'authToken',
			$author$project$Models$User$loginInfoDecoder($author$project$Models$LoggingIn$LoginMethodUnknown)),
		json);
	if (!_v0.$) {
		var loginInfo = _v0.a;
		return $elm$core$Maybe$Just(loginInfo);
	} else {
		var err = _v0.a;
		var _v1 = A2($author$project$Helpers$BrowserConsole$debugLog, 'Error in parsing stored credentials', err);
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Models$Modal$modalDomIdentifier = function (modalIdentifier) {
	switch (modalIdentifier.$) {
		case 7:
			return 'overlay';
		case 8:
			return 'overlay';
		case 9:
			return 'overlay';
		case 0:
			return 'modal';
		case 1:
			return 'modal';
		case 2:
			return 'modal';
		case 3:
			return 'modal';
		case 4:
			return 'modal';
		case 5:
			return 'modal';
		case 6:
			return 'modal';
		default:
			return 'modal';
	}
};
var $author$project$Helpers$CommandHelper$autoplayTTSIfSupported = function (_v0) {
	var model = _v0.a;
	var existingCommands = _v0.b;
	var audioCommand = A3(
		$author$project$Model$withLoggedInUser,
		model,
		$elm$core$Platform$Cmd$none,
		F2(
			function (_v1, _v2) {
				var audioEnabled = _v1.gj;
				return audioEnabled ? $author$project$Helpers$Task$performNow(
					$author$project$Messages$PlayAllAutoplaySpeech($elm$core$Maybe$Nothing)) : $elm$core$Platform$Cmd$none;
			}));
	return _Utils_Tuple2(
		model,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[existingCommands, audioCommand])));
};
var $author$project$Helpers$CommandHelper$clearDisabledByClick = function (_v0) {
	var updatingModel = _v0.a;
	var existingCommand = _v0.b;
	return _Utils_Tuple2(
		updatingModel,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					existingCommand,
					$author$project$Helpers$Task$performNow($author$project$Messages$ClearDisabledByClick)
				])));
};
var $author$project$Models$Modal$isSame = F2(
	function (first, second) {
		var _v0 = _Utils_Tuple2(first, second);
		switch (_v0.a.$) {
			case 1:
				if (_v0.b.$ === 1) {
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					return true;
				} else {
					var _v3 = _v0.a;
					return false;
				}
			case 2:
				if (_v0.b.$ === 2) {
					var _v4 = _v0.a;
					var _v5 = _v0.b;
					return true;
				} else {
					var _v6 = _v0.a;
					return false;
				}
			case 3:
				if (_v0.b.$ === 3) {
					var _v7 = _v0.a;
					var _v8 = _v0.b;
					return true;
				} else {
					var _v9 = _v0.a;
					return false;
				}
			case 4:
				if (_v0.b.$ === 4) {
					var _v10 = _v0.a;
					var _v11 = _v0.b;
					return true;
				} else {
					var _v12 = _v0.a;
					return false;
				}
			case 5:
				if (_v0.b.$ === 5) {
					var _v13 = _v0.a;
					var _v14 = _v0.b;
					return true;
				} else {
					var _v15 = _v0.a;
					return false;
				}
			case 6:
				if (_v0.b.$ === 6) {
					return true;
				} else {
					return false;
				}
			case 7:
				if (_v0.b.$ === 7) {
					return true;
				} else {
					return false;
				}
			case 8:
				if (_v0.b.$ === 8) {
					return true;
				} else {
					return false;
				}
			case 9:
				if (_v0.b.$ === 9) {
					return true;
				} else {
					return false;
				}
			case 0:
				if (!_v0.b.$) {
					var _v16 = _v0.a;
					var _v17 = _v0.b;
					return true;
				} else {
					var _v18 = _v0.a;
					return false;
				}
			default:
				if (_v0.b.$ === 10) {
					var _v19 = _v0.a;
					var _v20 = _v0.b;
					return true;
				} else {
					var _v21 = _v0.a;
					return false;
				}
		}
	});
var $author$project$Helpers$CommandHelper$updateWithModalState = F2(
	function (updatedModalState, _v0) {
		var updatingModel = _v0.a;
		var existingCommand = _v0.b;
		if (!updatedModalState.$) {
			var newModalIdentifier = updatedModalState.a;
			var isSameModal = function () {
				var _v3 = updatingModel.hM;
				if (!_v3.$) {
					var oldModalIdentifier = _v3.a;
					return A2($author$project$Models$Modal$isSame, oldModalIdentifier, newModalIdentifier);
				} else {
					return false;
				}
			}();
			var _v2 = $author$project$Helpers$CommandHelper$clearDisabledByClick(
				_Utils_Tuple2(
					_Utils_update(
						updatingModel,
						{hM: updatedModalState}),
					existingCommand));
			var newModel = _v2.a;
			var newCommands = _v2.b;
			return isSameModal ? _Utils_Tuple2(newModel, newCommands) : $author$project$Helpers$CommandHelper$autoplayTTSIfSupported(
				_Utils_Tuple2(newModel, newCommands));
		} else {
			return $author$project$Helpers$CommandHelper$clearDisabledByClick(
				_Utils_Tuple2(
					_Utils_update(
						updatingModel,
						{hM: updatedModalState}),
					existingCommand));
		}
	});
var $author$project$Main$navigateToActivity = F3(
	function (student, navigationDestination, model) {
		return A3(
			$krisajenkins$remotedata$RemoteData$unwrap,
			_Utils_Tuple2(model, $author$project$Effect$NoEffect),
			function (activeQuest) {
				return $author$project$Effect$convertToEffect(
					A2(
						$author$project$Helpers$CommandHelper$updateWithModalState,
						$author$project$Models$Modal$Closed,
						A2(
							$author$project$Effect$Resolution$convertToCmd,
							model.hR,
							A2(
								$author$project$Helpers$BeforeNavigationHelper$beforeNavigation,
								$author$project$Routes$routeToUrlString(
									A3(
										$author$project$AppFlow$Navigation$routeForNextActivity,
										A2($author$project$Helpers$VideoRecordingPermission$videoRecordingPermitted, model.f7.ii, student.iq),
										activeQuest,
										navigationDestination)),
								model))));
			},
			model.fZ);
	});
var $author$project$Effect$StopQRCodeScannerEffect = {$: 41};
var $author$project$LoginApp$Main$routeCleanup = F2(
	function (user, _v0) {
		if (user.$ === 1) {
			var loginState = user.a;
			return (!(!loginState.bg)) ? $author$project$Effect$StopQRCodeScannerEffect : $author$project$Effect$NoEffect;
		} else {
			return $author$project$Effect$NoEffect;
		}
	});
var $author$project$LoginApp$Main$needsRouteCleanup = F2(
	function (user, route) {
		return _Utils_eq(
			route,
			$author$project$Routes$LoginRoute($author$project$Routes$LoginRoutes$QRCodeReader)) && (!_Utils_eq(
			$author$project$Effect$NoEffect,
			A2($author$project$LoginApp$Main$routeCleanup, user, route)));
	});
var $author$project$NewVersionAvailability$Immediate = 0;
var $author$project$Ports$appLoaded = _Platform_outgoingPort(
	'appLoaded',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Init$onAppLoadedEffects = function () {
	var checkForNewAppVersion = $author$project$Effect$CheckForNewVersionAvailabilityEffect(0);
	var appLoadedEffect = $author$project$Effect$CmdEffect(
		$author$project$Ports$appLoaded(0));
	return $author$project$Effect$batch(
		_List_fromArray(
			[checkForNewAppVersion, appLoadedEffect]));
}();
var $author$project$Effect$OpenNewBrowserTabEffect = function (a) {
	return {$: 23, a: a};
};
var $author$project$Helpers$WebAppsHelper$openInBrowser = F3(
	function (_v0, forceOpenInIosSafari, platform) {
		var url = _v0.fE;
		var instructions = _v0.ea;
		if (platform.$ === 1) {
			return $author$project$Effect$OpenNewBrowserTabEffect(url);
		} else {
			return $author$project$Effect$OpenNewBrowserTabEffect(url);
		}
	});
var $author$project$Ports$pauseEmbeddedYoutubePlayer = _Platform_outgoingPort(
	'pauseEmbeddedYoutubePlayer',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$pauseVideo = _Platform_outgoingPort(
	'pauseVideo',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Helpers$VideoPlayback$pauseVideo = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{
				jI: $author$project$Models$VideoPlayer$Paused(
					$author$project$Models$VideoPlayer$playerTime(model.jI))
			}),
		$author$project$Ports$pauseVideo(0));
};
var $author$project$AppConfiguration$platformToString = function (platform) {
	if (platform.$ === 1) {
		return 'WebOnIos';
	} else {
		return 'Web';
	}
};
var $author$project$Ports$playEmbeddedYoutubePlayer = _Platform_outgoingPort(
	'playEmbeddedYoutubePlayer',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$playVideo = _Platform_outgoingPort(
	'playVideo',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$float(b)
				]));
	});
var $author$project$Helpers$VideoPlayback$playVideo = F3(
	function (model, videoKey, restartVideo) {
		var time = restartVideo ? $author$project$Models$VideoPlayer$defaultPlayerTime : $author$project$Models$VideoPlayer$playerTime(model.jI);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					jI: $author$project$Models$VideoPlayer$StartingPlay(time)
				}),
			$author$project$Ports$playVideo(
				_Utils_Tuple2(videoKey, time.cf)));
	});
var $author$project$Models$VideoPlayer$updateToEnded = function (videoPlayState) {
	var _v0 = $author$project$Models$VideoPlayer$playerTime(videoPlayState);
	var duration = _v0.dQ;
	return $author$project$Models$VideoPlayer$Ended(
		{cf: duration, dQ: duration});
};
var $author$project$Helpers$VideoPlayback$playbackEnded = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{
				jI: $author$project$Models$VideoPlayer$updateToEnded(model.jI)
			}),
		$elm$core$Platform$Cmd$none);
};
var $author$project$Helpers$VideoPlayback$playbackErrored = function (model) {
	var time = $author$project$Models$VideoPlayer$playerTime(model.jI);
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{
				jI: $author$project$Models$VideoPlayer$NotStarted(
					$elm$core$Maybe$Just(time))
			}),
		$elm$core$Platform$Cmd$none);
};
var $author$project$Ports$restartEmbeddedYoutubeVideo = _Platform_outgoingPort(
	'restartEmbeddedYoutubeVideo',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Helpers$VideoPlayback$rightClickedOnVideo = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$Effect$EvaluateWebAppForAutoCloseAfterDelayEffect = F4(
	function (a, b, c, d) {
		return {$: 25, a: a, b: b, c: c, d: d};
	});
var $author$project$Effect$EvaluateWebAppReturnSoundAfterDelayEffect = F3(
	function (a, b, c) {
		return {$: 26, a: a, b: b, c: c};
	});
var $author$project$Helpers$WebAppsHelper$scheduleReturnEffects = F2(
	function (openTime, webAppLaunchParams) {
		var maximumActivityTime = webAppLaunchParams.hG;
		var url = webAppLaunchParams.fE;
		var studentActivityId = webAppLaunchParams.G;
		return $author$project$Effect$batch(
			_List_fromArray(
				[
					A4($author$project$Effect$EvaluateWebAppForAutoCloseAfterDelayEffect, studentActivityId, url, maximumActivityTime, openTime),
					A3(
					$author$project$Effect$EvaluateWebAppReturnSoundAfterDelayEffect,
					studentActivityId,
					$author$project$Models$Activity$returnEffectsDelay(webAppLaunchParams),
					openTime),
					$author$project$Effect$StopInteractionSoundEffect($author$project$InteractionSound$returnToEspark)
				]));
	});
var $author$project$Ports$scrollContainerTo = _Platform_outgoingPort('scrollContainerTo', $elm$json$Json$Encode$float);
var $author$project$Main$shouldPlayVideoAfterDelay = F2(
	function (studentActivityId, model) {
		var onSameActivity = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			false,
			$author$project$Models$Ids$idEquals(studentActivityId),
			$author$project$Model$studentActivityForCurrentRoute(model));
		var notYetPlaying = !$author$project$Models$VideoPlayer$isPlaying(model.jI);
		return onSameActivity && notYetPlaying;
	});
var $author$project$Ports$triggerTTSPlayFromNode = _Platform_outgoingPort(
	'triggerTTSPlayFromNode',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$core$Basics$identity(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$Ports$triggerTTSPlayFromText = _Platform_outgoingPort(
	'triggerTTSPlayFromText',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$Ports$triggerTTSPlaySelector = _Platform_outgoingPort(
	'triggerTTSPlaySelector',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$Ports$triggerTTSPlayUrl = _Platform_outgoingPort('triggerTTSPlayUrl', $elm$json$Json$Encode$string);
var $author$project$Ports$triggerTTSRetagAndPlay = _Platform_outgoingPort(
	'triggerTTSRetagAndPlay',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$Models$YoutubeVideoPlayer$YoutubeIsUnavailable = {$: 2};
var $author$project$Models$YoutubeVideoPlayer$unavailableStatus = $author$project$Models$YoutubeVideoPlayer$YoutubeIsUnavailable;
var $author$project$Animations$Types$NextButton = 0;
var $mdgriffith$elm_style_animation$Animation$extractInitialWait = function (steps) {
	var _v0 = $elm$core$List$head(steps);
	if (_v0.$ === 1) {
		return _Utils_Tuple2(
			$elm$time$Time$millisToPosix(0),
			_List_Nil);
	} else {
		var step = _v0.a;
		if (step.$ === 4) {
			var till = step.a;
			var _v2 = $mdgriffith$elm_style_animation$Animation$extractInitialWait(
				A2($elm$core$List$drop, 1, steps));
			var additionalTime = _v2.a;
			var remainingSteps = _v2.b;
			return _Utils_Tuple2(
				$elm$time$Time$millisToPosix(
					$elm$time$Time$posixToMillis(till) + $elm$time$Time$posixToMillis(additionalTime)),
				remainingSteps);
		} else {
			return _Utils_Tuple2(
				$elm$time$Time$millisToPosix(0),
				steps);
		}
	}
};
var $mdgriffith$elm_style_animation$Animation$interrupt = F2(
	function (steps, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				cw: A2(
					$elm$core$List$cons,
					$mdgriffith$elm_style_animation$Animation$extractInitialWait(steps),
					model.cw),
				bY: true
			});
	});
var $mdgriffith$elm_style_animation$Animation$Model$Send = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Messenger$send = function (msg) {
	return $mdgriffith$elm_style_animation$Animation$Model$Send(msg);
};
var $author$project$Animations$setAnimationStatus = F2(
	function (state, status) {
		return _Utils_update(
			state,
			{cZ: status});
	});
var $author$project$Animations$setAnimationStyle = F2(
	function (state, style) {
		return _Utils_update(
			state,
			{aE: style});
	});
var $mdgriffith$elm_style_animation$Animation$Model$To = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_style_animation$Animation$to = function (props) {
	return $mdgriffith$elm_style_animation$Animation$Model$To(props);
};
var $mdgriffith$elm_style_animation$Animation$Model$refreshTiming = F2(
	function (now, timing) {
		var dt = $elm$time$Time$posixToMillis(now) - $elm$time$Time$posixToMillis(timing.dI);
		return {
			dI: now,
			gT: ((dt > 34) || (!$elm$time$Time$posixToMillis(timing.dI))) ? $elm$time$Time$millisToPosix(
				$elm$core$Basics$round(16.666)) : $elm$time$Time$millisToPosix(dt)
		};
	});
var $mdgriffith$elm_style_animation$Animation$Model$Loop = function (a) {
	return {$: 7, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$Repeat = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $mdgriffith$elm_style_animation$Animation$Model$Step = {$: 0};
var $mdgriffith$elm_style_animation$Animation$Model$Wait = function (a) {
	return {$: 4, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$isCmdDone = function (cmd) {
	var motionDone = function (motion) {
		return (!motion.jG) && _Utils_eq(motion.il, motion.jq);
	};
	switch (cmd.$) {
		case 0:
			var m1 = cmd.a;
			var m2 = cmd.b;
			return motionDone(m1) && motionDone(m2);
		case 1:
			var m1 = cmd.a;
			var m2 = cmd.b;
			return motionDone(m1) && motionDone(m2);
		case 2:
			var m1 = cmd.a;
			var m2 = cmd.b;
			return motionDone(m1) && motionDone(m2);
		case 3:
			var m1 = cmd.a;
			var m2 = cmd.b;
			return motionDone(m1) && motionDone(m2);
		case 4:
			var motion = cmd.a;
			return motionDone(motion);
		case 5:
			var motion = cmd.a;
			return motionDone(motion);
		case 6:
			var motion = cmd.a;
			return motionDone(motion);
		case 7:
			var motion = cmd.a;
			return motionDone(motion);
		case 8:
			var control1 = cmd.a.a5;
			var control2 = cmd.a.a6;
			var point = cmd.a.ab;
			return motionDone(control1.a) && (motionDone(control1.b) && (motionDone(control2.a) && (motionDone(control2.b) && (motionDone(point.a) && motionDone(point.b)))));
		case 9:
			var control1 = cmd.a.a5;
			var control2 = cmd.a.a6;
			var point = cmd.a.ab;
			return motionDone(control1.a) && (motionDone(control1.b) && (motionDone(control2.a) && (motionDone(control2.b) && (motionDone(point.a) && motionDone(point.b)))));
		case 10:
			var control = cmd.a.a4;
			var point = cmd.a.ab;
			return motionDone(control.a) && (motionDone(control.b) && (motionDone(point.a) && motionDone(point.b)));
		case 11:
			var control = cmd.a.a4;
			var point = cmd.a.ab;
			return motionDone(control.a) && (motionDone(control.b) && (motionDone(point.a) && motionDone(point.b)));
		case 12:
			var coords = cmd.a;
			return A2(
				$elm$core$List$all,
				function (_v1) {
					var x = _v1.a;
					var y = _v1.b;
					return motionDone(x) && motionDone(y);
				},
				coords);
		case 13:
			var coords = cmd.a;
			return A2(
				$elm$core$List$all,
				function (_v2) {
					var x = _v2.a;
					var y = _v2.b;
					return motionDone(x) && motionDone(y);
				},
				coords);
		case 14:
			var coords = cmd.a;
			return A2(
				$elm$core$List$all,
				function (_v3) {
					var x = _v3.a;
					var y = _v3.b;
					return motionDone(x) && motionDone(y);
				},
				coords);
		case 15:
			var coords = cmd.a;
			return A2(
				$elm$core$List$all,
				function (_v4) {
					var x = _v4.a;
					var y = _v4.b;
					return motionDone(x) && motionDone(y);
				},
				coords);
		case 16:
			var arc = cmd.a;
			return motionDone(arc.jW) && (motionDone(arc.j4) && (motionDone(arc.bh) && (motionDone(arc.bn) && motionDone(arc.a8))));
		case 17:
			var arc = cmd.a;
			return motionDone(arc.jW) && (motionDone(arc.j4) && (motionDone(arc.bh) && (motionDone(arc.bn) && motionDone(arc.a8))));
		default:
			return true;
	}
};
var $mdgriffith$elm_style_animation$Animation$Model$isDone = function (property) {
	var motionDone = function (motion) {
		var runningInterpolation = A2($elm$core$Maybe$withDefault, motion.aR, motion.ho);
		switch (runningInterpolation.$) {
			case 0:
				return (!motion.jG) && _Utils_eq(motion.il, motion.jq);
			case 1:
				var eased = runningInterpolation.a;
				return (eased.ir === 1) || ((!eased.ir) && _Utils_eq(motion.il, motion.jq));
			default:
				var speed = runningInterpolation.a;
				return _Utils_eq(motion.il, motion.jq);
		}
	};
	switch (property.$) {
		case 0:
			return true;
		case 1:
			var m1 = property.b;
			var m2 = property.c;
			var m3 = property.d;
			var m4 = property.e;
			return A2(
				$elm$core$List$all,
				motionDone,
				_List_fromArray(
					[m1, m2, m3, m4]));
		case 2:
			var shadow = property.c;
			return A2(
				$elm$core$List$all,
				motionDone,
				_List_fromArray(
					[shadow.Z, shadow._, shadow.fg, shadow.U, shadow.cT, shadow.cu, shadow.b9, shadow.aJ]));
		case 3:
			var m1 = property.b;
			return motionDone(m1);
		case 4:
			var m1 = property.b;
			var m2 = property.c;
			return motionDone(m1) && motionDone(m2);
		case 5:
			var m1 = property.b;
			var m2 = property.c;
			var m3 = property.d;
			return A2(
				$elm$core$List$all,
				motionDone,
				_List_fromArray(
					[m1, m2, m3]));
		case 6:
			var m1 = property.b;
			var m2 = property.c;
			var m3 = property.d;
			var m4 = property.e;
			return A2(
				$elm$core$List$all,
				motionDone,
				_List_fromArray(
					[m1, m2, m3, m4]));
		case 7:
			var m1 = property.b;
			return motionDone(m1);
		case 8:
			var ms = property.a;
			return A2(
				$elm$core$List$all,
				function (_v1) {
					var x = _v1.a;
					var y = _v1.b;
					return motionDone(x) && motionDone(y);
				},
				ms);
		default:
			var cmds = property.a;
			return A2($elm$core$List$all, $mdgriffith$elm_style_animation$Animation$Model$isCmdDone, cmds);
	}
};
var $mdgriffith$elm_style_animation$Animation$Model$Easing = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_style_animation$Animation$Model$matchPoints = F2(
	function (points1, points2) {
		var diff = $elm$core$List$length(points1) - $elm$core$List$length(points2);
		if (diff > 0) {
			var _v0 = $elm$core$List$head(
				$elm$core$List$reverse(points2));
			if (_v0.$ === 1) {
				return _Utils_Tuple2(points1, points2);
			} else {
				var last2 = _v0.a;
				return _Utils_Tuple2(
					points1,
					_Utils_ap(
						points2,
						A2(
							$elm$core$List$repeat,
							$elm$core$Basics$abs(diff),
							last2)));
			}
		} else {
			if (diff < 0) {
				var _v1 = $elm$core$List$head(
					$elm$core$List$reverse(points1));
				if (_v1.$ === 1) {
					return _Utils_Tuple2(points1, points2);
				} else {
					var last1 = _v1.a;
					return _Utils_Tuple2(
						_Utils_ap(
							points1,
							A2(
								$elm$core$List$repeat,
								$elm$core$Basics$abs(diff),
								last1)),
						points2);
				}
			} else {
				return _Utils_Tuple2(points1, points2);
			}
		}
	});
var $mdgriffith$elm_style_animation$Animation$Model$setPathTarget = F2(
	function (cmd, targetCmd) {
		var setMotionTarget = F2(
			function (motion, targetMotion) {
				var _v27 = motion.aR;
				if (_v27.$ === 1) {
					var ease = _v27.a;
					return _Utils_update(
						motion,
						{
							aR: $mdgriffith$elm_style_animation$Animation$Model$Easing(
								_Utils_update(
									ease,
									{cY: motion.il})),
							jq: targetMotion.il
						});
				} else {
					return _Utils_update(
						motion,
						{jq: targetMotion.il});
				}
			});
		switch (cmd.$) {
			case 0:
				var m1 = cmd.a;
				var m2 = cmd.b;
				if (!targetCmd.$) {
					var t1 = targetCmd.a;
					var t2 = targetCmd.b;
					return A2(
						$mdgriffith$elm_style_animation$Animation$Model$Move,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2));
				} else {
					return cmd;
				}
			case 1:
				var m1 = cmd.a;
				var m2 = cmd.b;
				if (targetCmd.$ === 1) {
					var t1 = targetCmd.a;
					var t2 = targetCmd.b;
					return A2(
						$mdgriffith$elm_style_animation$Animation$Model$MoveTo,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2));
				} else {
					return cmd;
				}
			case 2:
				var m1 = cmd.a;
				var m2 = cmd.b;
				if (targetCmd.$ === 2) {
					var t1 = targetCmd.a;
					var t2 = targetCmd.b;
					return A2(
						$mdgriffith$elm_style_animation$Animation$Model$Line,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2));
				} else {
					return cmd;
				}
			case 3:
				var m1 = cmd.a;
				var m2 = cmd.b;
				if (targetCmd.$ === 3) {
					var t1 = targetCmd.a;
					var t2 = targetCmd.b;
					return A2(
						$mdgriffith$elm_style_animation$Animation$Model$LineTo,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2));
				} else {
					return cmd;
				}
			case 4:
				var m1 = cmd.a;
				if (targetCmd.$ === 4) {
					var t1 = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$Horizontal(
						A2(setMotionTarget, m1, t1));
				} else {
					return cmd;
				}
			case 5:
				var m1 = cmd.a;
				if (targetCmd.$ === 5) {
					var t1 = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$HorizontalTo(
						A2(setMotionTarget, m1, t1));
				} else {
					return cmd;
				}
			case 6:
				var m1 = cmd.a;
				if (targetCmd.$ === 6) {
					var t1 = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$Vertical(
						A2(setMotionTarget, m1, t1));
				} else {
					return cmd;
				}
			case 7:
				var m1 = cmd.a;
				if (targetCmd.$ === 7) {
					var t1 = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$VerticalTo(
						A2(setMotionTarget, m1, t1));
				} else {
					return cmd;
				}
			case 8:
				var points = cmd.a;
				if (targetCmd.$ === 8) {
					var targets = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$Curve(
						{
							a5: _Utils_Tuple2(
								A2(setMotionTarget, points.a5.a, targets.a5.a),
								A2(setMotionTarget, points.a5.b, targets.a5.b)),
							a6: _Utils_Tuple2(
								A2(setMotionTarget, points.a6.a, targets.a6.a),
								A2(setMotionTarget, points.a6.b, targets.a6.b)),
							ab: _Utils_Tuple2(
								A2(setMotionTarget, points.ab.a, targets.ab.a),
								A2(setMotionTarget, points.ab.b, targets.ab.b))
						});
				} else {
					return cmd;
				}
			case 9:
				var points = cmd.a;
				if (targetCmd.$ === 9) {
					var targets = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$CurveTo(
						{
							a5: _Utils_Tuple2(
								A2(setMotionTarget, points.a5.a, targets.a5.a),
								A2(setMotionTarget, points.a5.b, targets.a5.b)),
							a6: _Utils_Tuple2(
								A2(setMotionTarget, points.a6.a, targets.a6.a),
								A2(setMotionTarget, points.a6.b, targets.a6.b)),
							ab: _Utils_Tuple2(
								A2(setMotionTarget, points.ab.a, targets.ab.a),
								A2(setMotionTarget, points.ab.b, targets.ab.b))
						});
				} else {
					return cmd;
				}
			case 10:
				var points = cmd.a;
				if (targetCmd.$ === 10) {
					var targets = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$Quadratic(
						{
							a4: _Utils_Tuple2(
								A2(setMotionTarget, points.a4.a, targets.a4.a),
								A2(setMotionTarget, points.a4.b, targets.a4.b)),
							ab: _Utils_Tuple2(
								A2(setMotionTarget, points.ab.a, targets.ab.a),
								A2(setMotionTarget, points.ab.b, targets.ab.b))
						});
				} else {
					return cmd;
				}
			case 11:
				var points = cmd.a;
				if (targetCmd.$ === 11) {
					var targets = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$QuadraticTo(
						{
							a4: _Utils_Tuple2(
								A2(setMotionTarget, points.a4.a, targets.a4.a),
								A2(setMotionTarget, points.a4.b, targets.a4.b)),
							ab: _Utils_Tuple2(
								A2(setMotionTarget, points.ab.a, targets.ab.a),
								A2(setMotionTarget, points.ab.b, targets.ab.b))
						});
				} else {
					return cmd;
				}
			case 12:
				var coords = cmd.a;
				if (targetCmd.$ === 12) {
					var targetCoords = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$SmoothQuadratic(
						A3(
							$elm$core$List$map2,
							F2(
								function (_v14, _v15) {
									var x1 = _v14.a;
									var y1 = _v14.b;
									var x2 = _v15.a;
									var y2 = _v15.b;
									return _Utils_Tuple2(
										A2(setMotionTarget, x1, x2),
										A2(setMotionTarget, y1, y2));
								}),
							coords,
							targetCoords));
				} else {
					return cmd;
				}
			case 13:
				var coords = cmd.a;
				if (targetCmd.$ === 13) {
					var targetCoords = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$SmoothQuadraticTo(
						A3(
							$elm$core$List$map2,
							F2(
								function (_v17, _v18) {
									var x1 = _v17.a;
									var y1 = _v17.b;
									var x2 = _v18.a;
									var y2 = _v18.b;
									return _Utils_Tuple2(
										A2(setMotionTarget, x1, x2),
										A2(setMotionTarget, y1, y2));
								}),
							coords,
							targetCoords));
				} else {
					return cmd;
				}
			case 14:
				var coords = cmd.a;
				if (targetCmd.$ === 14) {
					var targetCoords = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$Smooth(
						A3(
							$elm$core$List$map2,
							F2(
								function (_v20, _v21) {
									var x1 = _v20.a;
									var y1 = _v20.b;
									var x2 = _v21.a;
									var y2 = _v21.b;
									return _Utils_Tuple2(
										A2(setMotionTarget, x1, x2),
										A2(setMotionTarget, y1, y2));
								}),
							coords,
							targetCoords));
				} else {
					return cmd;
				}
			case 15:
				var coords = cmd.a;
				if (targetCmd.$ === 15) {
					var targetCoords = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$SmoothTo(
						A3(
							$elm$core$List$map2,
							F2(
								function (_v23, _v24) {
									var x1 = _v23.a;
									var y1 = _v23.b;
									var x2 = _v24.a;
									var y2 = _v24.b;
									return _Utils_Tuple2(
										A2(setMotionTarget, x1, x2),
										A2(setMotionTarget, y1, y2));
								}),
							coords,
							targetCoords));
				} else {
					return cmd;
				}
			case 16:
				var arc = cmd.a;
				if (targetCmd.$ === 16) {
					var target = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$ClockwiseArc(
						function () {
							var y = arc.j4;
							var x = arc.jW;
							var startAngle = arc.bn;
							var radius = arc.bh;
							var endAngle = arc.a8;
							return _Utils_update(
								arc,
								{
									a8: A2(setMotionTarget, endAngle, target.a8),
									bh: A2(setMotionTarget, radius, target.bh),
									bn: A2(setMotionTarget, startAngle, target.bn),
									jW: A2(setMotionTarget, x, target.jW),
									j4: A2(setMotionTarget, y, target.j4)
								});
						}());
				} else {
					return cmd;
				}
			case 17:
				var arc = cmd.a;
				if (targetCmd.$ === 17) {
					var target = targetCmd.a;
					return $mdgriffith$elm_style_animation$Animation$Model$AntiClockwiseArc(
						function () {
							var y = arc.j4;
							var x = arc.jW;
							var startAngle = arc.bn;
							var radius = arc.bh;
							var endAngle = arc.a8;
							return _Utils_update(
								arc,
								{
									a8: A2(setMotionTarget, endAngle, target.a8),
									bh: A2(setMotionTarget, radius, target.bh),
									bn: A2(setMotionTarget, startAngle, target.bn),
									jW: A2(setMotionTarget, x, target.jW),
									j4: A2(setMotionTarget, y, target.j4)
								});
						}());
				} else {
					return cmd;
				}
			default:
				return $mdgriffith$elm_style_animation$Animation$Model$Close;
		}
	});
var $mdgriffith$elm_style_animation$Animation$Model$setTarget = F3(
	function (overrideInterpolation, current, newTarget) {
		var setMotionTarget = F2(
			function (motion, targetMotion) {
				var newMotion = overrideInterpolation ? _Utils_update(
					motion,
					{
						ho: $elm$core$Maybe$Just(targetMotion.aR)
					}) : motion;
				var _v13 = newMotion.ho;
				if (_v13.$ === 1) {
					var _v14 = newMotion.aR;
					if (_v14.$ === 1) {
						var ease = _v14.a;
						return _Utils_update(
							newMotion,
							{
								aR: $mdgriffith$elm_style_animation$Animation$Model$Easing(
									_Utils_update(
										ease,
										{ir: 0, cY: motion.il})),
								jq: targetMotion.il
							});
					} else {
						return _Utils_update(
							newMotion,
							{jq: targetMotion.il});
					}
				} else {
					var override = _v13.a;
					if (override.$ === 1) {
						var ease = override.a;
						return _Utils_update(
							newMotion,
							{
								ho: $elm$core$Maybe$Just(
									$mdgriffith$elm_style_animation$Animation$Model$Easing(
										_Utils_update(
											ease,
											{ir: 0, cY: motion.il}))),
								jq: targetMotion.il
							});
					} else {
						return _Utils_update(
							newMotion,
							{jq: targetMotion.il});
					}
				}
			});
		switch (current.$) {
			case 0:
				var name = current.a;
				var value = current.b;
				return A2($mdgriffith$elm_style_animation$Animation$Model$ExactProperty, name, value);
			case 1:
				var name = current.a;
				var m1 = current.b;
				var m2 = current.c;
				var m3 = current.d;
				var m4 = current.e;
				if (newTarget.$ === 1) {
					var t1 = newTarget.b;
					var t2 = newTarget.c;
					var t3 = newTarget.d;
					var t4 = newTarget.e;
					return A5(
						$mdgriffith$elm_style_animation$Animation$Model$ColorProperty,
						name,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2),
						A2(setMotionTarget, m3, t3),
						A2(setMotionTarget, m4, t4));
				} else {
					return current;
				}
			case 2:
				var name = current.a;
				var inset = current.b;
				var shadow = current.c;
				if (newTarget.$ === 2) {
					var targetShadow = newTarget.c;
					return A3(
						$mdgriffith$elm_style_animation$Animation$Model$ShadowProperty,
						name,
						inset,
						{
							aJ: A2(setMotionTarget, shadow.aJ, targetShadow.aJ),
							b9: A2(setMotionTarget, shadow.b9, targetShadow.b9),
							U: A2(setMotionTarget, shadow.U, targetShadow.U),
							cu: A2(setMotionTarget, shadow.cu, targetShadow.cu),
							Z: A2(setMotionTarget, shadow.Z, targetShadow.Z),
							_: A2(setMotionTarget, shadow._, targetShadow._),
							cT: A2(setMotionTarget, shadow.cT, targetShadow.cT),
							fg: A2(setMotionTarget, shadow.fg, targetShadow.fg)
						});
				} else {
					return current;
				}
			case 3:
				var name = current.a;
				var m1 = current.b;
				if (newTarget.$ === 3) {
					var t1 = newTarget.b;
					return A2(
						$mdgriffith$elm_style_animation$Animation$Model$Property,
						name,
						A2(setMotionTarget, m1, t1));
				} else {
					return current;
				}
			case 4:
				var name = current.a;
				var m1 = current.b;
				var m2 = current.c;
				if (newTarget.$ === 4) {
					var t1 = newTarget.b;
					var t2 = newTarget.c;
					return A3(
						$mdgriffith$elm_style_animation$Animation$Model$Property2,
						name,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2));
				} else {
					return current;
				}
			case 5:
				var name = current.a;
				var m1 = current.b;
				var m2 = current.c;
				var m3 = current.d;
				if (newTarget.$ === 5) {
					var t1 = newTarget.b;
					var t2 = newTarget.c;
					var t3 = newTarget.d;
					return A4(
						$mdgriffith$elm_style_animation$Animation$Model$Property3,
						name,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2),
						A2(setMotionTarget, m3, t3));
				} else {
					return current;
				}
			case 6:
				var name = current.a;
				var m1 = current.b;
				var m2 = current.c;
				var m3 = current.d;
				var m4 = current.e;
				if (newTarget.$ === 6) {
					var t1 = newTarget.b;
					var t2 = newTarget.c;
					var t3 = newTarget.d;
					var t4 = newTarget.e;
					return A5(
						$mdgriffith$elm_style_animation$Animation$Model$Property4,
						name,
						A2(setMotionTarget, m1, t1),
						A2(setMotionTarget, m2, t2),
						A2(setMotionTarget, m3, t3),
						A2(setMotionTarget, m4, t4));
				} else {
					return current;
				}
			case 7:
				var name = current.a;
				var m1 = current.b;
				if (newTarget.$ === 7) {
					var t1 = newTarget.b;
					return A2(
						$mdgriffith$elm_style_animation$Animation$Model$AngleProperty,
						name,
						A2(setMotionTarget, m1, t1));
				} else {
					return current;
				}
			case 8:
				var currentPts = current.a;
				if (newTarget.$ === 8) {
					var targetPts = newTarget.a;
					var _v9 = A2($mdgriffith$elm_style_animation$Animation$Model$matchPoints, currentPts, targetPts);
					var m1s = _v9.a;
					var m2s = _v9.b;
					return $mdgriffith$elm_style_animation$Animation$Model$Points(
						A3(
							$elm$core$List$map2,
							F2(
								function (_v10, _v11) {
									var x1 = _v10.a;
									var y1 = _v10.b;
									var x2 = _v11.a;
									var y2 = _v11.b;
									return _Utils_Tuple2(
										A2(setMotionTarget, x1, x2),
										A2(setMotionTarget, y1, y2));
								}),
							m1s,
							m2s));
				} else {
					return current;
				}
			default:
				var cmds = current.a;
				if (newTarget.$ === 9) {
					var targets = newTarget.a;
					return $mdgriffith$elm_style_animation$Animation$Model$Path(
						A3($elm$core$List$map2, $mdgriffith$elm_style_animation$Animation$Model$setPathTarget, cmds, targets));
				} else {
					return current;
				}
		}
	});
var $mdgriffith$elm_style_animation$Animation$Model$zipPropertiesGreedy = F2(
	function (initialProps, newTargetProps) {
		var propertyMatch = F2(
			function (prop1, prop2) {
				return _Utils_eq(
					$mdgriffith$elm_style_animation$Animation$Model$propertyName(prop1),
					$mdgriffith$elm_style_animation$Animation$Model$propertyName(prop2));
			});
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, _v2) {
					var stackA = _v2.a;
					var stackB = _v2.b;
					var result = _v2.c;
					var _v3 = $elm$core$List$head(stackA);
					if (_v3.$ === 1) {
						return _Utils_Tuple3(stackA, stackB, result);
					} else {
						var a = _v3.a;
						var _v4 = A2(
							$elm$core$List$partition,
							propertyMatch(a),
							stackB);
						var matchingBs = _v4.a;
						var nonMatchingBs = _v4.b;
						return _Utils_Tuple3(
							A2($elm$core$List$drop, 1, stackA),
							function () {
								if (!matchingBs.b) {
									return nonMatchingBs;
								} else {
									var b = matchingBs.a;
									var remainingBs = matchingBs.b;
									return _Utils_ap(remainingBs, nonMatchingBs);
								}
							}(),
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									a,
									$elm$core$List$head(matchingBs)),
								result));
					}
				}),
			_Utils_Tuple3(initialProps, newTargetProps, _List_Nil),
			A2(
				$elm$core$List$repeat,
				$elm$core$List$length(initialProps),
				0));
		var warnings = _v0.b;
		var props = _v0.c;
		var _v6 = warnings;
		return $elm$core$List$reverse(props);
	});
var $mdgriffith$elm_style_animation$Animation$Model$startTowards = F3(
	function (overrideInterpolation, current, target) {
		return A2(
			$elm$core$List$filterMap,
			function (propPair) {
				if (!propPair.b.$) {
					var cur = propPair.a;
					var to = propPair.b.a;
					return $elm$core$Maybe$Just(
						A3($mdgriffith$elm_style_animation$Animation$Model$setTarget, overrideInterpolation, cur, to));
				} else {
					var prop = propPair.a;
					var _v1 = propPair.b;
					return $elm$core$Maybe$Just(prop);
				}
			},
			A2($mdgriffith$elm_style_animation$Animation$Model$zipPropertiesGreedy, current, target));
	});
var $mdgriffith$elm_style_animation$Animation$Model$tolerance = 0.01;
var $elm$core$Basics$truncate = _Basics_truncate;
var $mdgriffith$elm_style_animation$Animation$Model$vTolerance = 0.1;
var $mdgriffith$elm_style_animation$Animation$Model$stepInterpolation = F2(
	function (posix, motion) {
		var interpolationToUse = A2($elm$core$Maybe$withDefault, motion.aR, motion.ho);
		var dtms = $elm$time$Time$posixToMillis(posix);
		switch (interpolationToUse.$) {
			case 2:
				var perSecond = interpolationToUse.a.eJ;
				var _v1 = function () {
					if (_Utils_cmp(motion.il, motion.jq) < 0) {
						var _new = motion.il + (perSecond * (dtms / 1000));
						return _Utils_Tuple2(
							_new,
							_Utils_cmp(_new, motion.jq) > -1);
					} else {
						var _new = motion.il - (perSecond * (dtms / 1000));
						return _Utils_Tuple2(
							_new,
							_Utils_cmp(_new, motion.jq) < 1);
					}
				}();
				var newPos = _v1.a;
				var finished = _v1.b;
				return finished ? _Utils_update(
					motion,
					{il: motion.jq, jG: 0.0}) : _Utils_update(
					motion,
					{il: newPos, jG: perSecond * 1000});
			case 0:
				var stiffness = interpolationToUse.a.i8;
				var damping = interpolationToUse.a.gK;
				var fspring = stiffness * (motion.jq - motion.il);
				var fdamper = ((-1) * damping) * motion.jG;
				var dt = dtms / 1000;
				var a = fspring + fdamper;
				var newVelocity = motion.jG + (a * dt);
				var newPos = motion.il + (newVelocity * dt);
				var dx = $elm$core$Basics$abs(motion.jq - newPos);
				return ((_Utils_cmp(dx, $mdgriffith$elm_style_animation$Animation$Model$tolerance) < 0) && (_Utils_cmp(
					$elm$core$Basics$abs(newVelocity),
					$mdgriffith$elm_style_animation$Animation$Model$vTolerance) < 0)) ? _Utils_update(
					motion,
					{il: motion.jq, jG: 0.0}) : _Utils_update(
					motion,
					{il: newPos, jG: newVelocity});
			default:
				var progress = interpolationToUse.a.ir;
				var duration = interpolationToUse.a.dQ;
				var ease = interpolationToUse.a.cm;
				var start = interpolationToUse.a.cY;
				var durationMs = $elm$time$Time$posixToMillis(duration);
				var newProgress = (((dtms / durationMs) + progress) < 1) ? ((dtms / durationMs) + progress) : 1;
				var eased = ease(newProgress);
				var distance = motion.jq - start;
				var newPos = ((((eased * distance) + start) * 10000) | 0) / 10000;
				var newVelocity = (newProgress === 1) ? 0 : ((newPos - motion.il) / dtms);
				var _v2 = motion.ho;
				if (_v2.$ === 1) {
					return _Utils_update(
						motion,
						{
							aR: $mdgriffith$elm_style_animation$Animation$Model$Easing(
								{dQ: duration, cm: ease, ir: newProgress, cY: start}),
							il: newPos,
							jG: newVelocity
						});
				} else {
					var override = _v2.a;
					return _Utils_update(
						motion,
						{
							ho: $elm$core$Maybe$Just(
								$mdgriffith$elm_style_animation$Animation$Model$Easing(
									{dQ: duration, cm: ease, ir: newProgress, cY: start})),
							il: newPos,
							jG: newVelocity
						});
				}
		}
	});
var $mdgriffith$elm_style_animation$Animation$Model$stepPath = F2(
	function (dt, cmd) {
		var stepCoords = function (coords) {
			return A2(
				$elm$core$List$map,
				function (_v1) {
					var x = _v1.a;
					var y = _v1.b;
					return _Utils_Tuple2(
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, x),
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, y));
				},
				coords);
		};
		switch (cmd.$) {
			case 0:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$mdgriffith$elm_style_animation$Animation$Model$Move,
					A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, m1),
					A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, m2));
			case 1:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$mdgriffith$elm_style_animation$Animation$Model$MoveTo,
					A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, m1),
					A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, m2));
			case 2:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$mdgriffith$elm_style_animation$Animation$Model$Line,
					A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, m1),
					A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, m2));
			case 3:
				var m1 = cmd.a;
				var m2 = cmd.b;
				return A2(
					$mdgriffith$elm_style_animation$Animation$Model$LineTo,
					A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, m1),
					A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, m2));
			case 4:
				var motion = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$Horizontal(
					A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion));
			case 5:
				var motion = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$HorizontalTo(
					A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion));
			case 6:
				var motion = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$Vertical(
					A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion));
			case 7:
				var motion = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$VerticalTo(
					A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion));
			case 8:
				var control1 = cmd.a.a5;
				var control2 = cmd.a.a6;
				var point = cmd.a.ab;
				return $mdgriffith$elm_style_animation$Animation$Model$Curve(
					{
						a5: _Utils_Tuple2(
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, control1.a),
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, control1.b)),
						a6: _Utils_Tuple2(
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, control2.a),
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, control2.b)),
						ab: _Utils_Tuple2(
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, point.a),
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, point.b))
					});
			case 9:
				var control1 = cmd.a.a5;
				var control2 = cmd.a.a6;
				var point = cmd.a.ab;
				return $mdgriffith$elm_style_animation$Animation$Model$CurveTo(
					{
						a5: _Utils_Tuple2(
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, control1.a),
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, control1.b)),
						a6: _Utils_Tuple2(
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, control2.a),
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, control2.b)),
						ab: _Utils_Tuple2(
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, point.a),
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, point.b))
					});
			case 10:
				var control = cmd.a.a4;
				var point = cmd.a.ab;
				return $mdgriffith$elm_style_animation$Animation$Model$Quadratic(
					{
						a4: _Utils_Tuple2(
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, control.a),
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, control.b)),
						ab: _Utils_Tuple2(
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, point.a),
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, point.b))
					});
			case 11:
				var control = cmd.a.a4;
				var point = cmd.a.ab;
				return $mdgriffith$elm_style_animation$Animation$Model$QuadraticTo(
					{
						a4: _Utils_Tuple2(
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, control.a),
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, control.b)),
						ab: _Utils_Tuple2(
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, point.a),
							A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, point.b))
					});
			case 12:
				var coords = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$SmoothQuadratic(
					stepCoords(coords));
			case 13:
				var coords = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$SmoothQuadraticTo(
					stepCoords(coords));
			case 14:
				var coords = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$Smooth(
					stepCoords(coords));
			case 15:
				var coords = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$SmoothTo(
					stepCoords(coords));
			case 16:
				var arc = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$ClockwiseArc(
					_Utils_update(
						arc,
						{
							a8: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, arc.a8),
							bh: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, arc.bh),
							bn: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, arc.bn),
							jW: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, arc.jW),
							j4: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, arc.j4)
						}));
			case 17:
				var arc = cmd.a;
				return $mdgriffith$elm_style_animation$Animation$Model$AntiClockwiseArc(
					_Utils_update(
						arc,
						{
							a8: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, arc.a8),
							bh: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, arc.bh),
							bn: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, arc.bn),
							jW: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, arc.jW),
							j4: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, arc.j4)
						}));
			default:
				return $mdgriffith$elm_style_animation$Animation$Model$Close;
		}
	});
var $mdgriffith$elm_style_animation$Animation$Model$step = F2(
	function (dt, props) {
		var stepProp = function (property) {
			switch (property.$) {
				case 0:
					var name = property.a;
					var value = property.b;
					return A2($mdgriffith$elm_style_animation$Animation$Model$ExactProperty, name, value);
				case 3:
					var name = property.a;
					var motion = property.b;
					return A2(
						$mdgriffith$elm_style_animation$Animation$Model$Property,
						name,
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion));
				case 4:
					var name = property.a;
					var motion1 = property.b;
					var motion2 = property.c;
					return A3(
						$mdgriffith$elm_style_animation$Animation$Model$Property2,
						name,
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion1),
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion2));
				case 5:
					var name = property.a;
					var motion1 = property.b;
					var motion2 = property.c;
					var motion3 = property.d;
					return A4(
						$mdgriffith$elm_style_animation$Animation$Model$Property3,
						name,
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion1),
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion2),
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion3));
				case 6:
					var name = property.a;
					var motion1 = property.b;
					var motion2 = property.c;
					var motion3 = property.d;
					var motion4 = property.e;
					return A5(
						$mdgriffith$elm_style_animation$Animation$Model$Property4,
						name,
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion1),
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion2),
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion3),
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion4));
				case 7:
					var name = property.a;
					var motion = property.b;
					return A2(
						$mdgriffith$elm_style_animation$Animation$Model$AngleProperty,
						name,
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, motion));
				case 1:
					var name = property.a;
					var red = property.b;
					var green = property.c;
					var blue = property.d;
					var alpha = property.e;
					return A5(
						$mdgriffith$elm_style_animation$Animation$Model$ColorProperty,
						name,
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, red),
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, green),
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, blue),
						A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, alpha));
				case 2:
					var name = property.a;
					var inset = property.b;
					var shadow = property.c;
					return A3(
						$mdgriffith$elm_style_animation$Animation$Model$ShadowProperty,
						name,
						inset,
						{
							aJ: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, shadow.aJ),
							b9: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, shadow.b9),
							U: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, shadow.U),
							cu: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, shadow.cu),
							Z: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, shadow.Z),
							_: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, shadow._),
							cT: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, shadow.cT),
							fg: A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, shadow.fg)
						});
				case 8:
					var points = property.a;
					return $mdgriffith$elm_style_animation$Animation$Model$Points(
						A2(
							$elm$core$List$map,
							function (_v1) {
								var x = _v1.a;
								var y = _v1.b;
								return _Utils_Tuple2(
									A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, x),
									A2($mdgriffith$elm_style_animation$Animation$Model$stepInterpolation, dt, y));
							},
							points));
				default:
					var cmds = property.a;
					return $mdgriffith$elm_style_animation$Animation$Model$Path(
						A2(
							$elm$core$List$map,
							$mdgriffith$elm_style_animation$Animation$Model$stepPath(dt),
							cmds));
			}
		};
		return A2($elm$core$List$map, stepProp, props);
	});
var $mdgriffith$elm_style_animation$Animation$Model$alreadyThere = F2(
	function (current, target) {
		return A2(
			$elm$core$List$all,
			$mdgriffith$elm_style_animation$Animation$Model$isDone,
			A2(
				$mdgriffith$elm_style_animation$Animation$Model$step,
				$elm$time$Time$millisToPosix(0),
				A3($mdgriffith$elm_style_animation$Animation$Model$startTowards, false, current, target)));
	});
var $mdgriffith$elm_style_animation$Animation$Model$replaceProps = F2(
	function (props, replacements) {
		var replacementNames = A2($elm$core$List$map, $mdgriffith$elm_style_animation$Animation$Model$propertyName, replacements);
		var removed = A2(
			$elm$core$List$filter,
			function (prop) {
				return !A2(
					$elm$core$List$member,
					$mdgriffith$elm_style_animation$Animation$Model$propertyName(prop),
					replacementNames);
			},
			props);
		return _Utils_ap(removed, replacements);
	});
var $mdgriffith$elm_style_animation$Animation$Model$resolveSteps = F3(
	function (currentStyle, steps, dt) {
		resolveSteps:
		while (true) {
			var _v0 = $elm$core$List$head(steps);
			if (_v0.$ === 1) {
				return _Utils_Tuple3(currentStyle, _List_Nil, _List_Nil);
			} else {
				var currentStep = _v0.a;
				switch (currentStep.$) {
					case 4:
						var n = currentStep.a;
						if ($elm$time$Time$posixToMillis(n) <= 0) {
							var $temp$currentStyle = currentStyle,
								$temp$steps = A2($elm$core$List$drop, 1, steps),
								$temp$dt = dt;
							currentStyle = $temp$currentStyle;
							steps = $temp$steps;
							dt = $temp$dt;
							continue resolveSteps;
						} else {
							return _Utils_Tuple3(
								currentStyle,
								_List_Nil,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_style_animation$Animation$Model$Wait(
										$elm$time$Time$millisToPosix(
											$elm$time$Time$posixToMillis(n) - $elm$time$Time$posixToMillis(dt))),
									A2($elm$core$List$drop, 1, steps)));
						}
					case 5:
						var msg = currentStep.a;
						var _v2 = A3(
							$mdgriffith$elm_style_animation$Animation$Model$resolveSteps,
							currentStyle,
							A2($elm$core$List$drop, 1, steps),
							dt);
						var newStyle = _v2.a;
						var msgs = _v2.b;
						var remainingSteps = _v2.c;
						return _Utils_Tuple3(
							newStyle,
							A2($elm$core$List$cons, msg, msgs),
							remainingSteps);
					case 1:
						var target = currentStep.a;
						if (A2($mdgriffith$elm_style_animation$Animation$Model$alreadyThere, currentStyle, target)) {
							return _Utils_Tuple3(
								currentStyle,
								_List_Nil,
								A2($elm$core$List$drop, 1, steps));
						} else {
							var $temp$currentStyle = A3($mdgriffith$elm_style_animation$Animation$Model$startTowards, false, currentStyle, target),
								$temp$steps = A2(
								$elm$core$List$cons,
								$mdgriffith$elm_style_animation$Animation$Model$Step,
								A2($elm$core$List$drop, 1, steps)),
								$temp$dt = dt;
							currentStyle = $temp$currentStyle;
							steps = $temp$steps;
							dt = $temp$dt;
							continue resolveSteps;
						}
					case 2:
						var target = currentStep.a;
						if (A2($mdgriffith$elm_style_animation$Animation$Model$alreadyThere, currentStyle, target)) {
							return _Utils_Tuple3(
								currentStyle,
								_List_Nil,
								A2($elm$core$List$drop, 1, steps));
						} else {
							var $temp$currentStyle = A3($mdgriffith$elm_style_animation$Animation$Model$startTowards, true, currentStyle, target),
								$temp$steps = A2(
								$elm$core$List$cons,
								$mdgriffith$elm_style_animation$Animation$Model$Step,
								A2($elm$core$List$drop, 1, steps)),
								$temp$dt = dt;
							currentStyle = $temp$currentStyle;
							steps = $temp$steps;
							dt = $temp$dt;
							continue resolveSteps;
						}
					case 3:
						var props = currentStep.a;
						var $temp$currentStyle = A2($mdgriffith$elm_style_animation$Animation$Model$replaceProps, currentStyle, props),
							$temp$steps = A2($elm$core$List$drop, 1, steps),
							$temp$dt = dt;
						currentStyle = $temp$currentStyle;
						steps = $temp$steps;
						dt = $temp$dt;
						continue resolveSteps;
					case 0:
						var stepped = A2($mdgriffith$elm_style_animation$Animation$Model$step, dt, currentStyle);
						return A2($elm$core$List$all, $mdgriffith$elm_style_animation$Animation$Model$isDone, stepped) ? _Utils_Tuple3(
							A2(
								$elm$core$List$map,
								$mdgriffith$elm_style_animation$Animation$Model$mapToMotion(
									function (m) {
										return _Utils_update(
											m,
											{ho: $elm$core$Maybe$Nothing});
									}),
								stepped),
							_List_Nil,
							A2($elm$core$List$drop, 1, steps)) : _Utils_Tuple3(stepped, _List_Nil, steps);
					case 7:
						var substeps = currentStep.a;
						var $temp$currentStyle = currentStyle,
							$temp$steps = _Utils_ap(
							substeps,
							_List_fromArray(
								[
									$mdgriffith$elm_style_animation$Animation$Model$Loop(substeps)
								])),
							$temp$dt = dt;
						currentStyle = $temp$currentStyle;
						steps = $temp$steps;
						dt = $temp$dt;
						continue resolveSteps;
					default:
						var n = currentStep.a;
						var substeps = currentStep.b;
						if (n <= 0) {
							var $temp$currentStyle = currentStyle,
								$temp$steps = A2($elm$core$List$drop, 1, steps),
								$temp$dt = dt;
							currentStyle = $temp$currentStyle;
							steps = $temp$steps;
							dt = $temp$dt;
							continue resolveSteps;
						} else {
							var $temp$currentStyle = currentStyle,
								$temp$steps = _Utils_ap(
								substeps,
								_Utils_ap(
									_List_fromArray(
										[
											A2($mdgriffith$elm_style_animation$Animation$Model$Repeat, n - 1, substeps)
										]),
									A2($elm$core$List$drop, 1, steps))),
								$temp$dt = dt;
							currentStyle = $temp$currentStyle;
							steps = $temp$steps;
							dt = $temp$dt;
							continue resolveSteps;
						}
				}
			}
		}
	});
var $mdgriffith$elm_style_animation$Animation$Model$updateAnimation = F2(
	function (_v0, _v1) {
		var now = _v0;
		var model = _v1;
		var timing = A2($mdgriffith$elm_style_animation$Animation$Model$refreshTiming, now, model.fw);
		var _v2 = A2(
			$elm$core$List$partition,
			function (_v4) {
				var wait = _v4.a;
				var mySteps = _v4.b;
				return $elm$time$Time$posixToMillis(wait) <= 0;
			},
			A2(
				$elm$core$List$map,
				function (_v3) {
					var wait = _v3.a;
					var mySteps = _v3.b;
					return _Utils_Tuple2(
						$elm$time$Time$millisToPosix(
							$elm$time$Time$posixToMillis(wait) - $elm$time$Time$posixToMillis(timing.gT)),
						mySteps);
				},
				model.cw));
		var readyInterruption = _v2.a;
		var queuedInterruptions = _v2.b;
		var _v5 = function () {
			var _v6 = $elm$core$List$head(readyInterruption);
			if (!_v6.$) {
				var _v7 = _v6.a;
				var wait = _v7.a;
				var interrupt = _v7.b;
				return _Utils_Tuple2(
					interrupt,
					A2(
						$elm$core$List$map,
						$mdgriffith$elm_style_animation$Animation$Model$mapToMotion(
							function (m) {
								return _Utils_update(
									m,
									{ho: $elm$core$Maybe$Nothing});
							}),
						model.aE));
			} else {
				return _Utils_Tuple2(model.i7, model.aE);
			}
		}();
		var steps = _v5.a;
		var style = _v5.b;
		var _v8 = A3($mdgriffith$elm_style_animation$Animation$Model$resolveSteps, style, steps, timing.gT);
		var revisedStyle = _v8.a;
		var sentMessages = _v8.b;
		var revisedSteps = _v8.c;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					cw: queuedInterruptions,
					bY: (!(!$elm$core$List$length(revisedSteps))) || (!(!$elm$core$List$length(queuedInterruptions))),
					i7: revisedSteps,
					aE: revisedStyle,
					fw: timing
				}),
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$map,
					function (m) {
						return A2(
							$elm$core$Task$perform,
							$elm$core$Basics$identity,
							$elm$core$Task$succeed(m));
					},
					sentMessages)));
	});
var $mdgriffith$elm_style_animation$Animation$Messenger$update = F2(
	function (tick, animation) {
		return A2($mdgriffith$elm_style_animation$Animation$Model$updateAnimation, tick, animation);
	});
var $author$project$Animations$Types$Finished = 2;
var $author$project$Animations$Types$InProgress = 1;
var $author$project$Animations$Messages$SetAnimationStatus = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Animations$wrapAnimationWithStatuses = F2(
	function (elem, steps) {
		return $elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						$mdgriffith$elm_style_animation$Animation$Messenger$send(
						$author$project$Messages$AnimationMessage(
							A2($author$project$Animations$Messages$SetAnimationStatus, elem, 1)))
					]),
					steps,
					_List_fromArray(
					[
						$mdgriffith$elm_style_animation$Animation$Messenger$send(
						$author$project$Messages$AnimationMessage(
							A2($author$project$Animations$Messages$SetAnimationStatus, elem, 2)))
					])
				]));
	});
var $author$project$Animations$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var animationMsg = msg.a;
				var _v1 = A2($mdgriffith$elm_style_animation$Animation$Messenger$update, animationMsg, model.R.aE);
				var nextButtonStyle = _v1.a;
				var cmds = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							R: A2($author$project$Animations$setAnimationStyle, model.R, nextButtonStyle)
						}),
					cmds);
			case 2:
				var nextButtonAction = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							R: A2(
								$author$project$Animations$setAnimationStyle,
								model.R,
								A2(
									$mdgriffith$elm_style_animation$Animation$interrupt,
									A2(
										$author$project$Animations$wrapAnimationWithStatuses,
										0,
										_List_fromArray(
											[
												$mdgriffith$elm_style_animation$Animation$to(
												_List_fromArray(
													[
														$mdgriffith$elm_style_animation$Animation$scale(1.0)
													])),
												$mdgriffith$elm_style_animation$Animation$to(
												_List_fromArray(
													[
														$mdgriffith$elm_style_animation$Animation$scale(0.85)
													])),
												$mdgriffith$elm_style_animation$Animation$to(
												_List_fromArray(
													[
														$mdgriffith$elm_style_animation$Animation$scale(1.15)
													])),
												$mdgriffith$elm_style_animation$Animation$to(
												_List_fromArray(
													[
														$mdgriffith$elm_style_animation$Animation$scale(1.0)
													])),
												$mdgriffith$elm_style_animation$Animation$Messenger$send(nextButtonAction)
											])),
									model.R.aE))
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var elem = msg.a;
				var status = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							R: A2($author$project$Animations$setAnimationStatus, model.R, status)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$EsparkIosAppDisabled$PlayAllAutoplaySpeech = {$: 1};
var $author$project$EsparkIosAppDisabled$PlayTTSFromNode = function (a) {
	return {$: 0, a: a};
};
var $author$project$Ports$openInBrowser = _Platform_outgoingPort('openInBrowser', $elm$json$Json$Encode$string);
var $author$project$EsparkIosAppDisabled$update = function (msg) {
	switch (msg.$) {
		case 0:
			var node = msg.a;
			return _Utils_Tuple2(
				$elm$core$Platform$Cmd$none,
				$elm$core$Maybe$Just(
					$author$project$EsparkIosAppDisabled$PlayTTSFromNode(node)));
		case 2:
			return _Utils_Tuple2(
				$elm$core$Platform$Cmd$none,
				$elm$core$Maybe$Just($author$project$EsparkIosAppDisabled$PlayAllAutoplaySpeech));
		default:
			return _Utils_Tuple2(
				$author$project$Ports$openInBrowser('https://espark.app'),
				$elm$core$Maybe$Nothing);
	}
};
var $author$project$Helpers$Hoverable$update = F2(
	function (hoverMsg, model) {
		if (!hoverMsg.$) {
			var hoverState = hoverMsg.a;
			return A2($elm$core$List$cons, hoverState, model);
		} else {
			var hoverState = hoverMsg.a;
			return A2(
				$elm$core$List$filter,
				function (state) {
					return !_Utils_eq(state, hoverState);
				},
				model);
		}
	});
var $author$project$Learnosity$Model$Busy = 1;
var $author$project$Models$AssessmentStatus$CurrentlyTakingAssessment = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Ports$advanceToNextLearnosityQuestionV2 = _Platform_outgoingPort(
	'advanceToNextLearnosityQuestionV2',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Learnosity$AssessmentStateUpdater$alwaysUpdate = F2(
	function (_v0, newAssessmentStatus) {
		return newAssessmentStatus;
	});
var $author$project$LoggedIn$Messages$CompleteStudentActivityResponse = function (a) {
	return {$: 41, a: a};
};
var $author$project$Api$StudentActivityApi$complete = F4(
	function (id, maybeRating, loginInfo, appConfig) {
		var _v0 = function () {
			if (!maybeRating.$) {
				var rating = maybeRating.a;
				return _Utils_Tuple2(
					'complete',
					$author$project$Api$AuthedRequest$Body(
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									$author$project$Models$StudentActivity$encodeRating(rating)
								]))));
			} else {
				return _Utils_Tuple2('skip', $author$project$Api$AuthedRequest$EmptyBody);
			}
		}();
		var endpointSegment = _v0.a;
		var requestBody = _v0.b;
		var endpoint = A2(
			$elm$core$String$join,
			'/',
			_List_fromArray(
				[
					'/api/v5/student_activities',
					$author$project$Models$StudentActivity$idToString(id),
					endpointSegment
				]));
		return A4(
			$author$project$Api$HttpRetry$sendWithConfig,
			{cx: 3000, cy: 1000, bk: 4},
			$author$project$Models$StudentActivity$decoder,
			$author$project$LoggedIn$Messages$CompleteStudentActivityResponse,
			A2(
				$author$project$Api$AuthedRequest$put,
				endpoint,
				A5($author$project$Api$RequestHelper$createConfig, $author$project$LoggedIn$Messages$CompleteStudentActivityResponse, loginInfo, appConfig, requestBody, $author$project$Models$StudentActivity$decoder)));
	});
var $author$project$Models$AssessmentStatus$currentQuestionRef = function (assessmentScores) {
	return A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.iA;
		},
		A2(
			$elm_community$list_extra$List$Extra$find,
			function ($) {
				return $.ar;
			},
			assessmentScores));
};
var $author$project$Ports$gotoLearnosityQuestion = _Platform_outgoingPort('gotoLearnosityQuestion', $elm$json$Json$Encode$int);
var $author$project$Api$StudentActivityApi$skip = F3(
	function (id, loginInfo, appConfig) {
		return A4($author$project$Api$StudentActivityApi$complete, id, $elm$core$Maybe$Nothing, loginInfo, appConfig);
	});
var $author$project$Helpers$CommandHelper$handleActivityError = F3(
	function (loginInfo, model, studentActivityId) {
		var _v0 = model.jE;
		switch (_v0) {
			case '':
				return A3(
					$author$project$Helpers$NavigationHelper$updateUrl,
					model.hR,
					model.f7,
					$author$project$Routes$routeToUrlString(model.eY) + '?retry=1');
			case 'retry=1':
				return A2(
					$author$project$Helpers$NavigationHelper$loadUrl,
					model.hR,
					$author$project$Routes$routeToUrlString(model.eY) + '?retry=2');
			case 'retry=2':
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Messages$LoggedInRequiredMsg,
					A3($author$project$Api$StudentActivityApi$skip, studentActivityId, loginInfo, model.f7));
			default:
				return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Models$ItemScore$isAnswered = function (itemScore) {
	return A2(
		$elm$core$List$member,
		itemScore.iM,
		_List_fromArray(
			[2, 3]));
};
var $author$project$Models$AssessmentStatus$indexOfFirstNotAnsweredQuestion = $elm_community$list_extra$List$Extra$findIndex(
	A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Models$ItemScore$isAnswered));
var $author$project$Models$ActiveQuest$loadedAssessmentScores = function (activityId) {
	return A2(
		$elm$core$Basics$composeR,
		$krisajenkins$remotedata$RemoteData$toMaybe,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map(
				function ($) {
					return $.jd;
				}),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$andThen(
					$author$project$Models$Ids$findById(activityId)),
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Maybe$andThen($author$project$Models$StudentActivity$assessmentStatus),
					$elm$core$Maybe$andThen($author$project$Models$AssessmentStatus$assessmentScoresForStatus)))));
};
var $author$project$Learnosity$quizScores = function (model) {
	return A2(
		$elm$core$Maybe$andThen,
		function (activityId) {
			return A2($author$project$Models$ActiveQuest$loadedAssessmentScores, activityId, model.fZ);
		},
		$author$project$Routes$studentActivityIdFromRoute(model.eY));
};
var $author$project$Learnosity$indexOfFirstUnansweredQuestion = function (model) {
	var questionIndex = A2(
		$elm$core$Maybe$andThen,
		$author$project$Models$AssessmentStatus$indexOfFirstNotAnsweredQuestion,
		$author$project$Learnosity$quizScores(model));
	var lastQuestionIndex = A2(
		$elm$core$Maybe$map,
		$elm$core$List$length,
		$author$project$Learnosity$quizScores(model));
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2($elm_community$maybe_extra$Maybe$Extra$or, questionIndex, lastQuestionIndex));
};
var $author$project$Learnosity$maybeCurrentStudentActivity = F2(
	function (route, activeQuest) {
		if ($author$project$Models$ActiveQuest$activeQuestIsLoaded(activeQuest)) {
			switch (route.$) {
				case 8:
					var activityRoute = route.a;
					return A2(
						$author$project$Helpers$RecordFinders$findStudentActivity,
						$author$project$Routes$ActivityRoutes$studentActivityId(activityRoute),
						activeQuest);
				case 10:
					var quizRoute = route.a;
					return A2(
						$author$project$Helpers$RecordFinders$findStudentActivity,
						$author$project$Routes$QuizRoutes$studentActivityId(quizRoute),
						activeQuest);
				case 5:
					if (route.a.$ === 2) {
						return $elm$core$Maybe$Just($author$project$Helpers$QuizPreviewDummyData$studentActivityForChallenge);
					} else {
						return $elm$core$Maybe$Just($author$project$Helpers$QuizPreviewDummyData$studentActivity);
					}
				case 6:
					return $elm$core$Maybe$Just($author$project$Helpers$QuizPreviewDummyData$studentActivity);
				default:
					return $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Models$ItemScore$scoreEncoder = function (itemScore) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'max_score',
				$elm$json$Json$Encode$int(itemScore.eu)),
				_Utils_Tuple2(
				'score',
				$elm$json$Json$Encode$int(
					A2($elm$core$Maybe$withDefault, 0, itemScore.e4))),
				_Utils_Tuple2(
				'time',
				$elm$json$Json$Encode$int(itemScore.a$)),
				_Utils_Tuple2(
				'response_id',
				$elm$json$Json$Encode$string(
					A2($elm$core$Maybe$withDefault, '', itemScore.aj))),
				_Utils_Tuple2(
				'question_type',
				$elm$json$Json$Encode$string(itemScore.aV)),
				_Utils_Tuple2(
				'revisions',
				$elm$json$Json$Encode$int(itemScore.iI)),
				_Utils_Tuple2(
				'response_value',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, itemScore.eX))
			]));
};
var $author$project$Models$ItemScore$scoreListEncoder = function (itemScores) {
	return $elm$json$Json$Encode$object(
		A2(
			$elm$core$List$map,
			function (itemScore) {
				return _Utils_Tuple2(
					itemScore.iA,
					$author$project$Models$ItemScore$scoreEncoder(itemScore));
			},
			itemScores));
};
var $author$project$Models$ItemScore$scoresEncoder = function (itemScores) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'scores',
				$author$project$Models$ItemScore$scoreListEncoder(itemScores))
			]));
};
var $author$project$Api$LearnosityAssessmentApi$putAssessmentComplete = F4(
	function (itemScores, studentActivityId, loginInfo, appConfig) {
		var body = $author$project$Models$ItemScore$scoresEncoder(itemScores);
		return A3(
			$author$project$Api$HttpRetry$send,
			$elm$json$Json$Decode$value,
			$author$project$Messages$wrapLearnosityMessage(
				$elm$core$Basics$always($author$project$Learnosity$Messages$LearnosityAssessmentSubmittedV2)),
			A2(
				$author$project$Api$AuthedRequest$put,
				'/api/v5/student_activities/' + ($author$project$Models$StudentActivity$idToString(studentActivityId) + '/learnosity_complete'),
				A5(
					$author$project$Api$RequestHelper$createConfig,
					$author$project$Messages$wrapLearnosityMessage(
						$elm$core$Basics$always($author$project$Learnosity$Messages$LearnosityAssessmentSubmittedV2)),
					loginInfo,
					appConfig,
					$author$project$Api$AuthedRequest$Body(body),
					$elm$json$Json$Decode$value)));
	});
var $author$project$Ports$retryLearnosityQuestionV2 = _Platform_outgoingPort(
	'retryLearnosityQuestionV2',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$showLearnosityQuizV2 = _Platform_outgoingPort('showLearnosityQuizV2', $elm$json$Json$Encode$string);
var $author$project$Ports$submitLearnosityAssessmentV2 = _Platform_outgoingPort(
	'submitLearnosityAssessmentV2',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Models$AssessmentStatus$ReviewingAssessment = function (a) {
	return {$: 1, a: a};
};
var $author$project$Messages$PlayAllAutoplayQuizContentV2 = function (a) {
	return {$: 57, a: a};
};
var $author$project$TextToSpeech$humanAudioOverrideSelector = '[data-audio-override-url]';
var $author$project$Learnosity$Audio$selector = function (ref) {
	var textSelector = A2(
		$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
		'[data-reference=\'{0}\'] .lrn_sharedpassage, [data-reference=\'{0}\'] .lrn_stimulus',
		_List_fromArray(
			[ref]));
	return A2(
		$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
		'{0}, {0} {1}',
		_List_fromArray(
			[textSelector, $author$project$TextToSpeech$humanAudioOverrideSelector]));
};
var $author$project$Learnosity$Audio$autoplayMessage = function (ref) {
	return $author$project$Messages$PlayAllAutoplayQuizContentV2(
		$author$project$Learnosity$Audio$selector(ref));
};
var $author$project$Learnosity$audioCmds = F3(
	function (student, currentQuestionShouldAlwaysPlayTTSAudio, currentQuestionRef) {
		return (student.gj || currentQuestionShouldAlwaysPlayTTSAudio) ? $author$project$Helpers$Task$performNow(
			$author$project$Learnosity$Audio$autoplayMessage(currentQuestionRef)) : $elm$core$Platform$Cmd$none;
	});
var $author$project$Models$ItemScore$hasHumanAudioOverrideInAssessmentStimulus = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.aZ;
	},
	$elm$core$String$contains('data-type=\"audioplayer\"'));
var $author$project$Models$AssessmentStatus$currentQuestionHasHumanAudioOverrideInStimulus = A2(
	$elm$core$Basics$composeR,
	$author$project$Models$AssessmentStatus$currentQuestion,
	A2($elm_community$maybe_extra$Maybe$Extra$unwrap, false, $author$project$Models$ItemScore$hasHumanAudioOverrideInAssessmentStimulus));
var $author$project$Models$ItemScore$isNotAnswered = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.iM;
	},
	$elm$core$Basics$eq(0));
var $author$project$Models$AssessmentStatus$currentQuestionIsNotAnswered = A2(
	$elm$core$Basics$composeR,
	$author$project$Models$AssessmentStatus$currentQuestion,
	A2($elm_community$maybe_extra$Maybe$Extra$unwrap, false, $author$project$Models$ItemScore$isNotAnswered));
var $author$project$Models$ItemScore$isValidated = function (itemScore) {
	var _v0 = itemScore.iM;
	switch (_v0) {
		case 0:
			return false;
		case 1:
			return false;
		case 2:
			return true;
		default:
			return true;
	}
};
var $author$project$Models$AssessmentStatus$currentQuestionIsValidated = A2(
	$elm$core$Basics$composeR,
	$author$project$Models$AssessmentStatus$currentQuestion,
	A2($elm_community$maybe_extra$Maybe$Extra$unwrap, false, $author$project$Models$ItemScore$isValidated));
var $author$project$Models$AssessmentStatus$currentQuestionIsCorrect = A2(
	$elm$core$Basics$composeR,
	$author$project$Models$AssessmentStatus$currentQuestion,
	A2($elm_community$maybe_extra$Maybe$Extra$unwrap, false, $author$project$Models$ItemScore$isCorrect));
var $author$project$InteractionSound$quizCorrect = require('/usr/src/app/app/sounds/quiz-correct-answer.mp3');
var $author$project$InteractionSound$quizIncorrect = require('/usr/src/app/app/sounds/quiz-incorrect-answer.mp3');
var $author$project$Learnosity$feedbackSound = function (itemScores) {
	return $author$project$Models$AssessmentStatus$currentQuestionIsCorrect(itemScores) ? $author$project$InteractionSound$quizCorrect : $author$project$InteractionSound$quizIncorrect;
};
var $author$project$Ports$triggerTTSRetagContent = _Platform_outgoingPort(
	'triggerTTSRetagContent',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Learnosity$cmdsForCurrentQuestion = F3(
	function (switchedToHtmlAudio, student, itemScores) {
		var currentQuestionShouldAlwaysPlayTTSAudio = $author$project$Models$AssessmentStatus$currentQuestionHasHumanAudioOverrideInStimulus(itemScores);
		return $author$project$Models$AssessmentStatus$currentQuestionIsNotAnswered(itemScores) ? A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			$elm$core$Platform$Cmd$none,
			A2($author$project$Learnosity$audioCmds, student, currentQuestionShouldAlwaysPlayTTSAudio),
			$author$project$Models$AssessmentStatus$currentQuestionRef(itemScores)) : ($author$project$Models$AssessmentStatus$currentQuestionIsValidated(itemScores) ? $elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Ports$triggerTTSRetagContent(0),
					$author$project$Ports$playInteractionSound(
					_Utils_Tuple2(
						$author$project$InteractionSound$src(
							$author$project$Learnosity$feedbackSound(itemScores)),
						switchedToHtmlAudio))
				])) : $elm$core$Platform$Cmd$none);
	});
var $author$project$Learnosity$currentQuestionStatusFromScores = F2(
	function (model, itemScores) {
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			$author$project$Models$AssessmentStatus$QuestionUnanswered,
			function (studentActivity) {
				return A2($author$project$Models$AssessmentStatus$assessmentCurrentQuestionStatus, studentActivity.f_.f0, itemScores);
			},
			A2($author$project$Learnosity$maybeCurrentStudentActivity, model.eY, model.fZ));
	});
var $author$project$Learnosity$withValidatedQuizActivity = F2(
	function (model, resultFunction) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
			A3(
				$elm$core$Maybe$map2,
				resultFunction,
				A2($author$project$Learnosity$maybeCurrentStudentActivity, model.eY, model.fZ),
				$krisajenkins$remotedata$RemoteData$toMaybe(model.fZ)));
	});
var $author$project$Learnosity$updateAssessmentStatus = F3(
	function (quizStatusUpdateFn, newAssessmentStatus, currentModel) {
		var updateStudentActivityProgress = function (currentProgress) {
			_v1$3:
			while (true) {
				switch (currentProgress.$) {
					case 2:
						if (currentProgress.b.$ === 1) {
							var contentCompletedAt = currentProgress.a;
							var oldAssessmentStatus = currentProgress.b.a;
							return A2(
								$author$project$Models$StudentActivity$CompletedStudentActivity,
								contentCompletedAt,
								$author$project$Models$StudentActivity$HasAssessment(
									A2(quizStatusUpdateFn, oldAssessmentStatus, newAssessmentStatus)));
						} else {
							break _v1$3;
						}
					case 1:
						if (!currentProgress.a.$) {
							if (currentProgress.a.b.$ === 1) {
								var _v2 = currentProgress.a;
								var contentCompletionProgress = _v2.a;
								var oldAssessmentStatus = _v2.b.a;
								return $author$project$Models$StudentActivity$ActiveStudentActivity(
									A2(
										$author$project$Models$StudentActivity$ContentActivity,
										contentCompletionProgress,
										$author$project$Models$StudentActivity$HasAssessment(
											A2(quizStatusUpdateFn, oldAssessmentStatus, newAssessmentStatus))));
							} else {
								break _v1$3;
							}
						} else {
							var oldAssessmentStatus = currentProgress.a.a;
							return $author$project$Models$StudentActivity$ActiveStudentActivity(
								$author$project$Models$StudentActivity$AssessmentActivity(
									A2(quizStatusUpdateFn, oldAssessmentStatus, newAssessmentStatus)));
						}
					default:
						break _v1$3;
				}
			}
			return currentProgress;
		};
		var updatedStudentActivity = function (studentActivity) {
			return _Utils_update(
				studentActivity,
				{
					ir: updateStudentActivityProgress(studentActivity.ir)
				});
		};
		var updateModel = F2(
			function (studentActivity, _v0) {
				return _Utils_Tuple2(
					A3($author$project$Model$updateStudentActivityById, studentActivity.hj, updatedStudentActivity, currentModel),
					$elm$core$Platform$Cmd$none);
			});
		return A2($author$project$Learnosity$withValidatedQuizActivity, currentModel, updateModel);
	});
var $author$project$Learnosity$synchronizeLearnosityQuizState = F3(
	function (model, student, itemScores) {
		var isCompletedActivity = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			false,
			$author$project$Models$StudentActivity$isCompleted,
			A2($author$project$Learnosity$maybeCurrentStudentActivity, model.eY, model.fZ));
		var quizStatus = isCompletedActivity ? $author$project$Models$AssessmentStatus$ReviewingAssessment(itemScores) : A2(
			$author$project$Models$AssessmentStatus$CurrentlyTakingAssessment,
			A2($author$project$Learnosity$currentQuestionStatusFromScores, model, itemScores),
			itemScores);
		var _v0 = A3($author$project$Learnosity$updateAssessmentStatus, $author$project$Learnosity$AssessmentStateUpdater$alwaysUpdate, quizStatus, model);
		var updatedModel = _v0.a;
		var quizCommand = _v0.b;
		return _Utils_Tuple2(
			updatedModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Learnosity$cmdsForCurrentQuestion, model.ga.jA.jm, student, itemScores),
						quizCommand
					])));
	});
var $author$project$Ports$validateLearnosityAnswerV2 = _Platform_outgoingPort('validateLearnosityAnswerV2', $elm$json$Json$Encode$string);
var $author$project$Learnosity$update = F4(
	function (student, loginInfo, learnosityMsg, model) {
		var currentQuestionRef = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				A2($elm$core$Basics$composeR, $author$project$Models$StudentActivity$itemScores, $author$project$Models$AssessmentStatus$currentQuestionRef),
				A2($author$project$Learnosity$maybeCurrentStudentActivity, model.eY, model.fZ)));
		switch (learnosityMsg.$) {
			case 0:
				if (!learnosityMsg.b.$) {
					var jsonValue = learnosityMsg.b.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$showLearnosityQuizV2(
							A2($elm$json$Json$Encode$encode, 0, jsonValue)));
				} else {
					var studentActivityId = learnosityMsg.a;
					var httpError = learnosityMsg.b.a;
					var _v1 = A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, httpError, 'fetching Learnosity quiz');
					var updatedModel = _v1.a;
					var httpErrorCommand = _v1.b;
					return _Utils_Tuple2(
						updatedModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									httpErrorCommand,
									A3($author$project$Helpers$CommandHelper$handleActivityError, loginInfo, model, studentActivityId)
								])));
				}
			case 4:
				return _Utils_Tuple2(
					model,
					$author$project$Ports$validateLearnosityAnswerV2(currentQuestionRef));
			case 5:
				return _Utils_Tuple2(
					model,
					$author$project$Ports$advanceToNextLearnosityQuestionV2(0));
			case 7:
				var questionIndex = $author$project$Learnosity$indexOfFirstUnansweredQuestion(model);
				return _Utils_Tuple2(
					model,
					$author$project$Ports$gotoLearnosityQuestion(questionIndex));
			case 6:
				var _v2 = model.eY;
				_v2$2:
				while (true) {
					switch (_v2.$) {
						case 10:
							if ((_v2.a.$ === 1) && (!_v2.a.a)) {
								var _v3 = _v2.a;
								var _v4 = _v3.a;
								var studentQuestId = _v3.b;
								return _Utils_Tuple2(
									model,
									A2(
										$author$project$Helpers$NavigationHelper$pushUrl,
										model.hR,
										$author$project$Routes$routeToUrlString(
											$author$project$Routes$QuestRoute(
												$author$project$Routes$QuestRoutes$Home(studentQuestId)))));
							} else {
								break _v2$2;
							}
						case 8:
							if ((_v2.a.$ === 2) && (!_v2.a.a)) {
								var _v5 = _v2.a;
								var _v6 = _v5.a;
								var studentQuestId = _v5.b;
								return _Utils_Tuple2(
									model,
									A2(
										$author$project$Helpers$NavigationHelper$pushUrl,
										model.hR,
										$author$project$Routes$routeToUrlString(
											$author$project$Routes$QuestRoute(
												$author$project$Routes$QuestRoutes$Home(studentQuestId)))));
							} else {
								break _v2$2;
							}
						default:
							break _v2$2;
					}
				}
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 8:
				var submitAssessmentCmds = function (maybeStudentActivity) {
					return A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						$elm$core$Platform$Cmd$none,
						function (studentActivity) {
							return $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Ports$submitLearnosityAssessmentV2(0),
										A4(
										$author$project$Api$LearnosityAssessmentApi$putAssessmentComplete,
										$author$project$Models$StudentActivity$itemScores(studentActivity),
										studentActivity.hj,
										loginInfo,
										model.f7)
									]));
						},
						maybeStudentActivity);
				};
				var _v7 = model.eY;
				_v7$2:
				while (true) {
					switch (_v7.$) {
						case 10:
							if ((_v7.a.$ === 1) && (!_v7.a.a)) {
								var _v8 = _v7.a;
								var _v9 = _v8.a;
								var studentQuestId = _v8.b;
								var studentActivityId = _v8.c;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											eY: $author$project$Routes$QuizRoute(
												A3($author$project$Routes$QuizRoutes$Assessment, 1, studentQuestId, studentActivityId))
										}),
									submitAssessmentCmds(
										A2($author$project$Learnosity$maybeCurrentStudentActivity, model.eY, model.fZ)));
							} else {
								break _v7$2;
							}
						case 8:
							if ((_v7.a.$ === 2) && (!_v7.a.a)) {
								var _v10 = _v7.a;
								var _v11 = _v10.a;
								var studentQuestId = _v10.b;
								var studentActivityId = _v10.c;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											eY: $author$project$Routes$QuizRoute(
												A3($author$project$Routes$QuizRoutes$Assessment, 1, studentQuestId, studentActivityId))
										}),
									submitAssessmentCmds(
										A2($author$project$Learnosity$maybeCurrentStudentActivity, model.eY, model.fZ)));
							} else {
								break _v7$2;
							}
						default:
							break _v7$2;
					}
				}
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 9:
				var _v12 = A3(
					$author$project$Learnosity$updateAssessmentStatus,
					$author$project$Learnosity$AssessmentStateUpdater$alwaysUpdate,
					A2($author$project$Models$AssessmentStatus$CurrentlyTakingAssessment, $author$project$Models$AssessmentStatus$QuestionUnanswered, _List_Nil),
					model);
				var newModel = _v12.a;
				return _Utils_Tuple2(
					newModel,
					$author$project$Ports$retryLearnosityQuestionV2(0));
			case 2:
				var outputFunction = F2(
					function (studentActivity, _v13) {
						return $author$project$Models$Activity$isChallenge(studentActivity.f_) ? A2(
							$author$project$Helpers$CommandHelper$updateWithModalState,
							$author$project$Models$Modal$Open($author$project$Models$Modal$RatingsModal),
							_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)) : _Utils_Tuple2(
							model,
							A2(
								$elm$core$Platform$Cmd$map,
								$author$project$Messages$LoggedInRequiredMsg,
								A4(
									$author$project$Api$StudentActivityApi$complete,
									studentActivity.hj,
									$elm$core$Maybe$Just(3),
									loginInfo,
									model.f7)));
					});
				return A2($author$project$Learnosity$withValidatedQuizActivity, model, outputFunction);
			case 1:
				var itemScores = learnosityMsg.a;
				return A3($author$project$Learnosity$synchronizeLearnosityQuizState, model, student, itemScores);
			case 3:
				var outputFunction = F2(
					function (studentActivity, _v14) {
						return _Utils_Tuple2(
							model,
							A2(
								$elm$core$Platform$Cmd$map,
								$author$project$Messages$LoggedInRequiredMsg,
								A4(
									$author$project$Api$StudentActivityApi$complete,
									studentActivity.hj,
									$elm$core$Maybe$Just(3),
									loginInfo,
									model.f7)));
					});
				return A2($author$project$Learnosity$withValidatedQuizActivity, model, outputFunction);
			default:
				if (!learnosityMsg.b.$) {
					var jsonValue = learnosityMsg.b.a;
					var jsonString = A2($elm$json$Json$Encode$encode, 0, jsonValue);
					return _Utils_Tuple2(
						model,
						$author$project$Ports$showLearnosityQuizV2(jsonString));
				} else {
					var httpError = learnosityMsg.b.a;
					return A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, httpError, 'fetching quiz question previewquiz');
				}
		}
	});
var $author$project$EventPublisher$Event$AcceptChanges = 1;
var $author$project$LoggedIn$Messages$AppTimerCallbackWithCurrentTime = F2(
	function (a, b) {
		return {$: 52, a: a, b: b};
	});
var $author$project$EventPublisher$Event$CloseCustomizerModal = 0;
var $author$project$LoggedIn$Messages$CompleteStudentActivityTimestamped = F4(
	function (a, b, c, d) {
		return {$: 40, a: a, b: b, c: c, d: d};
	});
var $author$project$LoggedIn$Messages$DiscardDeletedStudentVideoResponse = function (a) {
	return {$: 68, a: a};
};
var $author$project$VideoRecording$RecordingProcess$Errored = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Models$Activity$Failed = 2;
var $author$project$VideoRecording$RecordingProcess$FileUploadCompleted = {$: 2};
var $author$project$VideoRecording$RecordingProcess$FileUploadFailed = function (a) {
	return {$: 3, a: a};
};
var $author$project$LoggedIn$Messages$HostedVideoUrlUnableToLoadTimestamped = F3(
	function (a, b, c) {
		return {$: 23, a: a, b: b, c: c};
	});
var $author$project$Models$StudentActivity$KeepWorking = {$: 1};
var $author$project$Effect$ListenForWebActivityWindowMessageEffect = function (a) {
	return {$: 29, a: a};
};
var $author$project$Effect$LogErrorEffect = F2(
	function (a, b) {
		return {$: 62, a: a, b: b};
	});
var $author$project$HealthChecks$MediaAccessWithNoParamsFailed = 3;
var $author$project$HealthChecks$MediaAccessWithNoParamsSucceeded = 2;
var $author$project$HealthChecks$MediaAccessWithYoutubeParamFailed = 5;
var $author$project$HealthChecks$MediaAccessWithYoutubeParamSucceeded = 4;
var $author$project$Messages$OpenInBrowser = function (a) {
	return {$: 30, a: a};
};
var $author$project$Effect$ProvideAuthInfoToChoiceTextEffect = F4(
	function (a, b, c, d) {
		return {$: 59, a: a, b: b, c: c, d: d};
	});
var $author$project$Effect$ProvideAuthInfoToPLEEffect = F4(
	function (a, b, c, d) {
		return {$: 57, a: a, b: b, c: c, d: d};
	});
var $author$project$Effect$RecordCustomizerOpenedEffect = function (a) {
	return {$: 58, a: a};
};
var $author$project$Effect$RecordStudentMoodEffect = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Messages$ReloadPage = {$: 73};
var $author$project$Effect$ReplayRecordedStudentVideoEffect = function (a) {
	return {$: 22, a: a};
};
var $author$project$LoggedIn$Messages$ReplayStudentActivityOnExternalActivityLaunchTimestamped = F3(
	function (a, b, c) {
		return {$: 27, a: a, b: b, c: c};
	});
var $author$project$Messages$ResetWebAppNotificationTimers = function (a) {
	return {$: 43, a: a};
};
var $author$project$LoggedIn$Messages$RetryLoadStudentQuest = {$: 15};
var $author$project$Logger$SentryMessage = 2;
var $author$project$Models$StudentActivity$Skipped = 2;
var $author$project$EventPublisher$Event$StudentClickstreamClickRatingsModal = function (a) {
	return {$: 0, a: a};
};
var $author$project$EventPublisher$Event$StudentClickstreamClose_Character_Customizer = function (a) {
	return {$: 20, a: a};
};
var $author$project$EventPublisher$Event$StudentClickstreamLaunchWebappActivity = function (a) {
	return {$: 3, a: a};
};
var $author$project$EventPublisher$Event$StudentClickstreamLaunch_Character_Customizer = {$: 19};
var $author$project$EventPublisher$Event$StudentClickstreamOpenClassLibrary = function (a) {
	return {$: 21, a: a};
};
var $author$project$EventPublisher$Event$StudentClickstreamRelaunchWebappActivity = function (a) {
	return {$: 8, a: a};
};
var $author$project$EventPublisher$Event$StudentClickstreamStartVideoRecording = {$: 13};
var $author$project$EventPublisher$Event$StudentClickstreamToggleInstructionBar = function (a) {
	return {$: 10, a: a};
};
var $author$project$LoggedIn$Messages$StudentVideoUploadedResponse = function (a) {
	return {$: 78, a: a};
};
var $author$project$HealthChecks$VerifyingMediaAccessAfterError = 1;
var $author$project$LoggedIn$Messages$VideoRecordingPlaybackSkip = {$: 63};
var $author$project$VideoRecording$Helpers$autoStopRecording = F2(
	function (recordingProcess, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		if (recordingProcess.$ === 4) {
			var duration = recordingProcess.a;
			return (_Utils_cmp(duration, $author$project$VideoRecording$Helpers$maxRecordingLength) > -1) ? _Utils_Tuple2(
				_Utils_update(
					model,
					{
						hM: $author$project$Models$Modal$Open($author$project$Models$Modal$VideoRecordingTerminatedModal)
					}),
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							cmd,
							A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Messages$LoggedInRequiredMsg,
							$author$project$Helpers$Task$performNow(
								$author$project$LoggedIn$Messages$VideoRecordingPerformAction(1)))
						]))) : _Utils_Tuple2(model, cmd);
		} else {
			return _Utils_Tuple2(model, cmd);
		}
	});
var $author$project$Ports$cancelUploadVideo = _Platform_outgoingPort('cancelUploadVideo', $elm$json$Json$Encode$string);
var $author$project$VideoRecording$RecordingProcess$getVideoDataBlobUrl = function (recordingProcess) {
	if (recordingProcess.$ === 5) {
		var dataUrl = recordingProcess.a.cg;
		return $elm$core$Maybe$Just(dataUrl);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Ports$uploadVideo = _Platform_outgoingPort('uploadVideo', $elm$json$Json$Encode$string);
var $author$project$VideoRecording$Helpers$autoUpload = F2(
	function (previousProcess, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var updatedUrl = $author$project$VideoRecording$RecordingProcess$getVideoDataBlobUrl(model.d);
		var previousUrl = $author$project$VideoRecording$RecordingProcess$getVideoDataBlobUrl(previousProcess);
		return _Utils_eq(previousUrl, updatedUrl) ? _Utils_Tuple2(model, cmd) : _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmd,
						A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							A2($elm$core$Basics$composeR, $author$project$Models$Urls$toString, $author$project$Ports$cancelUploadVideo),
							previousUrl)),
						A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							A2($elm$core$Basics$composeR, $author$project$Models$Urls$toString, $author$project$Ports$uploadVideo),
							updatedUrl))
					])));
	});
var $author$project$Effect$FlashTimerAlertEffect = function (a) {
	return {$: 48, a: a};
};
var $author$project$Effect$SetCanCompleteProgressEffect = function (a) {
	return {$: 47, a: a};
};
var $author$project$Effect$StudentActivityTimerCompleteEffect = function (a) {
	return {$: 55, a: a};
};
var $author$project$AppTimer$mapEffectForMessage = F2(
	function (message, studentActivityId) {
		switch (message) {
			case 'StudentActivityTimerCompleteEffect':
				return $author$project$Effect$StudentActivityTimerCompleteEffect(studentActivityId);
			case 'SetCanCompleteProgressEffect':
				return $author$project$Effect$SetCanCompleteProgressEffect(studentActivityId);
			case 'FlashTimerAlertEffect':
				return $author$project$Effect$FlashTimerAlertEffect(studentActivityId);
			default:
				return $author$project$Effect$NoEffect;
		}
	});
var $author$project$AppTimer$callback = function (appTimer) {
	var remainingTimedMessages = function () {
		var _v0 = $elm$core$List$tail(appTimer.aF);
		if (_v0.$ === 1) {
			return _List_Nil;
		} else {
			var newList = _v0.a;
			return newList;
		}
	}();
	var updatedAppTimer = (!appTimer.ar) ? _Utils_update(
		appTimer,
		{ar: true, bq: appTimer.cf}) : _Utils_update(
		appTimer,
		{aF: remainingTimedMessages});
	var maybeUpdatedDelay = A2(
		$elm$core$Maybe$map,
		$elm$core$Tuple$first,
		$elm$core$List$head(updatedAppTimer.aF));
	var newCallbackEffect = _Utils_eq(updatedAppTimer.aF, _List_Nil) ? $author$project$Effect$NoEffect : A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		$author$project$Effect$NoEffect,
		$author$project$Effect$AppTimerCallbackEffect(updatedAppTimer),
		maybeUpdatedDelay);
	var currentEffect = function (currentTimedMessage) {
		return A2($author$project$AppTimer$mapEffectForMessage, currentTimedMessage.b, appTimer.G);
	};
	var effectAndCallback = function (currentTimedMessage) {
		return $author$project$Effect$BatchedEffect(
			_List_fromArray(
				[
					currentEffect(currentTimedMessage),
					newCallbackEffect
				]));
	};
	return appTimer.ar ? A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		$author$project$Effect$NoEffect,
		effectAndCallback,
		$elm$core$List$head(appTimer.aF)) : A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		$author$project$Effect$NoEffect,
		$author$project$Effect$AppTimerCallbackEffect(updatedAppTimer),
		maybeUpdatedDelay);
};
var $author$project$VideoRecording$RecordingProcess$CameraNotAvailable = {$: 1};
var $author$project$VideoRecording$RecordingProcess$cameraNotReadyStateFor = function (recordingCounts) {
	return (recordingCounts.cN > 0) ? A2($author$project$VideoRecording$RecordingProcess$Errored, 'Permissions still not available after retry - moving on', $elm$core$Maybe$Nothing) : $author$project$VideoRecording$RecordingProcess$CameraNotAvailable;
};
var $author$project$Helpers$CommandHelper$closeSessionModal = F3(
	function (student, loginInfo, _v0) {
		var updatingModel = _v0.a;
		var existingCommand = _v0.b;
		return function (_v1) {
			var model = _v1.a;
			var commands = _v1.b;
			return ((!_Utils_eq(updatingModel.hM, $author$project$Models$Modal$Closed)) && _Utils_eq(model.hM, $author$project$Models$Modal$Closed)) ? $author$project$Helpers$CommandHelper$autoplayTTSIfSupported(
				_Utils_Tuple2(model, commands)) : _Utils_Tuple2(model, commands);
		}(
			A3(
				$author$project$Helpers$InitialSessionFlow$checkForUpdates,
				student,
				loginInfo,
				A2(
					$author$project$Helpers$CommandHelper$updateWithModalState,
					$author$project$Models$Modal$Closed,
					_Utils_Tuple2(updatingModel, existingCommand))));
	});
var $author$project$LoggedIn$Messages$CompleteStudentQuestResponse = F2(
	function (a, b) {
		return {$: 21, a: a, b: b};
	});
var $author$project$Models$ActiveQuest$nextStudentQuestIdDecoder = A2(
	$elm$json$Json$Decode$field,
	'next_student_quest_id',
	$elm$json$Json$Decode$maybe($author$project$Models$Ids$decoder));
var $author$project$Api$StudentQuestApi$complete = F4(
	function (id, questEndingBehavior, loginInfo, appConfig) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$author$project$Models$ActiveQuest$nextStudentQuestIdDecoder,
			$author$project$LoggedIn$Messages$CompleteStudentQuestResponse(questEndingBehavior),
			A2(
				$author$project$Api$AuthedRequest$put,
				'/api/v5/student_quests/' + ($author$project$Models$Ids$toString(id) + '/complete'),
				A5(
					$author$project$Api$RequestHelper$createConfig,
					$author$project$LoggedIn$Messages$CompleteStudentQuestResponse(questEndingBehavior),
					loginInfo,
					appConfig,
					$author$project$Api$AuthedRequest$EmptyBody,
					$author$project$Models$ActiveQuest$nextStudentQuestIdDecoder)));
	});
var $author$project$LoggedIn$completeQuest = F4(
	function (loginInfo, appConfiguration, questEndingBehavior, studentQuestId) {
		return A2(
			$elm$core$Platform$Cmd$map,
			$author$project$Messages$LoggedInRequiredMsg,
			A4($author$project$Api$StudentQuestApi$complete, studentQuestId, questEndingBehavior, loginInfo, appConfiguration));
	});
var $author$project$LoggedIn$completeQuestIfNeeded = F4(
	function (loginInfo, model, questEndingBehavior, studentQuestId) {
		var updateActiveQuest = function (activeQuest) {
			return _Utils_update(
				activeQuest,
				{du: true});
		};
		var _v0 = model.fZ;
		if (_v0.$ === 3) {
			var activeQuest = _v0.a;
			return (_Utils_eq(activeQuest.a_, studentQuestId) && (!activeQuest.du)) ? _Utils_Tuple2(
				_Utils_update(
					model,
					{
						fZ: $krisajenkins$remotedata$RemoteData$succeed(
							updateActiveQuest(activeQuest))
					}),
				$author$project$Effect$CmdEffect(
					A4($author$project$LoggedIn$completeQuest, loginInfo, model.f7, questEndingBehavior, studentQuestId))) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
		} else {
			return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
		}
	});
var $author$project$Messages$ClearAnimations = {$: 55};
var $author$project$Models$StudentActivity$Rated = function (a) {
	return {$: 0, a: a};
};
var $author$project$InteractionSound$lightbulb = require('/usr/src/app/app/sounds/lightbulb.mp3');
var $author$project$Models$StudentActivity$setProgressCompleted = F2(
	function (currentTime, studentActivity) {
		var _v0 = studentActivity.ir;
		if (((_v0.$ === 1) && (!_v0.a.$)) && (_v0.a.a.$ === 1)) {
			if (!_v0.a.b.$) {
				var _v1 = _v0.a;
				var _v2 = _v1.a;
				var _v3 = _v1.b;
				return _Utils_update(
					studentActivity,
					{
						ir: A2(
							$author$project$Models$StudentActivity$CompletedStudentActivity,
							$elm$core$Maybe$Just(currentTime),
							$author$project$Models$StudentActivity$NoAssessment)
					});
			} else {
				var _v4 = _v0.a;
				var _v5 = _v4.a;
				var assessmentProgress = _v4.b;
				return _Utils_update(
					studentActivity,
					{
						ir: $author$project$Models$StudentActivity$ActiveStudentActivity(
							A2(
								$author$project$Models$StudentActivity$ContentActivity,
								$author$project$Models$StudentActivity$CompletedContent(currentTime),
								assessmentProgress))
					});
			}
		} else {
			return studentActivity;
		}
	});
var $author$project$LoggedIn$completeStudentActivity = F7(
	function (studentActivityId, rating, showKeepWorkingButton, currentTime, student, loginInfo, model) {
		var webAppActivityUrl = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			'',
			$author$project$Models$Urls$toString,
			A2(
				$elm$core$Maybe$andThen,
				$author$project$Models$Activity$webAppUrl,
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.f_;
					},
					A2($author$project$Model$studentActivityForId, model, studentActivityId))));
		var playSoundEffect = _Utils_eq(student.f8, $author$project$AppFlow$Sequential) ? $author$project$Effect$PlayInteractionSoundEffect($author$project$InteractionSound$lightbulb) : $author$project$Effect$NoEffect;
		var isIframeLoaded = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			false,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.f_;
				},
				function ($) {
					return $.dl;
				}),
			A2($author$project$Model$studentActivityForId, model, studentActivityId));
		var completeStudentActivityEffect = $author$project$Effect$CmdEffect(
			A2(
				$elm$core$Platform$Cmd$map,
				$author$project$Messages$LoggedInRequiredMsg,
				A4(
					$author$project$Api$StudentActivityApi$complete,
					studentActivityId,
					$elm$core$Maybe$Just(rating),
					loginInfo,
					model.f7)));
		var closeExternalTabEffect = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Effect$NoEffect,
			A2(
				$elm$core$Maybe$map,
				$author$project$Helpers$WebAppsHelper$closeExternalWindow,
				A2(
					$elm$core$Maybe$andThen,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.f_;
						},
						$author$project$Models$Activity$webAppUrl),
					A2($author$project$Model$studentActivityForId, model, studentActivityId))));
		var clearAnimationsEffect = A2($author$project$Effect$PerformLaterEffect, 1500, $author$project$Messages$ClearAnimations);
		return _Utils_Tuple2(
			A3(
				$author$project$Model$updateStudentActivityById,
				studentActivityId,
				$author$project$Models$StudentActivity$setProgressCompleted(currentTime),
				_Utils_update(
					model,
					{hq: true, hM: $author$project$Models$Modal$Closed})),
			$author$project$Effect$batch(
				_List_fromArray(
					[
						playSoundEffect,
						completeStudentActivityEffect,
						clearAnimationsEffect,
						closeExternalTabEffect,
						$author$project$Effect$HideNotificationEffect,
						$author$project$Effect$GetCurrentTimeEffect(
						A2(
							$elm$core$Basics$composeR,
							$author$project$LoggedIn$Messages$PublishEventTimestamped(
								$author$project$EventPublisher$Event$StudentClickstreamClickRatingsModal(
									{
										hu: showKeepWorkingButton,
										er: isIframeLoaded,
										iZ: $author$project$Models$StudentActivity$Rated(rating),
										fs: webAppActivityUrl
									})),
							$author$project$Messages$LoggedInRequiredMsg))
					])));
	});
var $author$project$Models$WindowPostedMessage$constructGameMissingRequirements = function (constructGameMessageData) {
	return constructGameMessageData.dc || (constructGameMessageData.db || constructGameMessageData.cz);
};
var $lukewestby$elm_http_builder$HttpBuilder$delete = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('DELETE');
var $author$project$Api$AuthedRequest$delete = F2(
	function (endpoint, config) {
		var baseUrl = config.bx;
		var queryParams = config.bS;
		return $author$project$Api$RequestHelper$requestBuilderToRequest(
			A2(
				$author$project$Api$AuthedRequest$buildRequestNoBody,
				config,
				$lukewestby$elm_http_builder$HttpBuilder$delete(
					A2(
						$author$project$Api$RequestHelper$constructUrl,
						_Utils_ap(baseUrl, endpoint),
						queryParams))));
	});
var $author$project$Api$StudentVideoUploadApi$discardRecording = F4(
	function (studentQuestId, loginInfo, appConfig, resultMsg) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$author$project$Models$StudentVideo$decoder,
			resultMsg,
			A2(
				$author$project$Api$AuthedRequest$delete,
				'/api/v5/student_quests/' + ($author$project$Models$Ids$toString(studentQuestId) + '/student_video'),
				A5($author$project$Api$RequestHelper$createConfig, resultMsg, loginInfo, appConfig, 0, $author$project$Models$StudentVideo$decoder)));
	});
var $author$project$Models$BrowserNotification$encodeContent = function (content) {
	var encodeMaybeString = function (maybeString) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$json$Json$Encode$null,
			A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, maybeString));
	};
	return A2(
		$elm$json$Json$Encode$encode,
		0,
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'title',
					$elm$json$Json$Encode$string(content.fx)),
					_Utils_Tuple2(
					'body',
					encodeMaybeString(content.gs)),
					_Utils_Tuple2(
					'icon',
					encodeMaybeString(content.hg)),
					_Utils_Tuple2(
					'image',
					encodeMaybeString(content.d7)),
					_Utils_Tuple2(
					'studentActivityId',
					$author$project$Models$Ids$encoder(content.G))
				])));
};
var $author$project$Assets$returnNotification = require('/usr/src/app/app/images/notifications/return-notification.png');
var $author$project$Ports$sendNotification = _Platform_outgoingPort('sendNotification', $elm$json$Json$Encode$string);
var $author$project$Helpers$ReturnNotificationCommand$generate = F2(
	function (studentActivityIdForNotification, model) {
		var returnNotificationContent = function (_v2) {
			return {
				gs: $elm$core$Maybe$Nothing,
				hg: $elm$core$Maybe$Nothing,
				d7: $elm$core$Maybe$Just(
					$author$project$Assets$path($author$project$Assets$returnNotification)),
				G: studentActivityIdForNotification,
				fx: 'It\'s time to return to eSpark!'
			};
		};
		var notificationIsAppropriate = function (studentActivity) {
			return (!$author$project$Models$WindowFocus$hasFocus(model.fS)) && _Utils_eq(studentActivity.hj, studentActivityIdForNotification);
		};
		var outputFunction = F2(
			function (studentActivity, _v1) {
				if (notificationIsAppropriate(studentActivity)) {
					return _Utils_Tuple2(
						model,
						$author$project$Ports$sendNotification(
							$author$project$Models$BrowserNotification$encodeContent(
								returnNotificationContent(studentActivity))));
				} else {
					var _v0 = A2(
						$author$project$Helpers$BrowserConsole$debugLog,
						'Did not fire notification (focus lost, same activity)',
						_Utils_Tuple2(
							!$author$project$Models$WindowFocus$hasFocus(model.fS),
							_Utils_eq(studentActivity.hj, studentActivityIdForNotification)));
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			});
		return A2($author$project$Helpers$CommandHelper$withValidatedStudentActivity, model, outputFunction);
	});
var $author$project$LoggedIn$getVideoRecordingCounts = function (_v0) {
	var localStorage = _v0.hz;
	var activeQuest = _v0.fZ;
	return A3(
		$krisajenkins$remotedata$RemoteData$unwrap,
		$author$project$VideoRecording$RecordingProcess$initialRecordingCounts,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.a_;
			},
			$author$project$Models$LocalStorage$Helpers$getVideoRecordingCounts(localStorage)),
		activeQuest);
};
var $author$project$LoggedIn$Messages$HostedVideoDataRetrieved = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $author$project$Models$Activity$EnqueuedForCaching = 1;
var $author$project$Models$Activity$VideoUrlLoaded = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Api$MediaServerApi$watchUrlsDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Models$Activity$VideoUrlLoaded,
	A2(
		$elm$json$Json$Decode$field,
		'url',
		A2($elm$json$Json$Decode$map, $author$project$Models$Urls$fromString, $elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$field,
		'cdn_url',
		A2($elm$json$Json$Decode$map, $author$project$Models$Urls$fromString, $elm$json$Json$Decode$string)));
var $author$project$Api$MediaServerApi$mediaServerResponseDecoder = function (mediaServerUrl) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$author$project$Api$MediaServerApi$watchUrlsDecoder,
				A2(
				$elm$json$Json$Decode$field,
				'enqueued',
				$elm$json$Json$Decode$succeed(
					A2($author$project$Models$Activity$VideoUrlNotYetLoaded, 1, mediaServerUrl))),
				$elm$json$Json$Decode$fail('Media server returned unexpected response')
			]));
};
var $author$project$Api$MediaServerApi$getVideoUrl = F3(
	function (devFlags, studentActivityId, mediaServerUrl) {
		var resolver = A2(
			$elm$core$Basics$composeR,
			$krisajenkins$remotedata$RemoteData$fromResult,
			$author$project$LoggedIn$Messages$HostedVideoDataRetrieved(studentActivityId));
		return A4(
			$author$project$Api$HttpRetry$sendWithConfig,
			{cx: 1 * 1000, cy: 1000, bk: 1},
			$author$project$Api$MediaServerApi$mediaServerResponseDecoder(mediaServerUrl),
			resolver,
			$author$project$Api$RequestHelper$requestBuilderToRequest(
				A2(
					$lukewestby$elm_http_builder$HttpBuilder$withTimeout,
					A2($elm$core$Basics$min, 3000, devFlags.iC),
					A2(
						$lukewestby$elm_http_builder$HttpBuilder$withExpect,
						A2(
							$elm$http$Http$expectJson,
							resolver,
							$author$project$Api$MediaServerApi$mediaServerResponseDecoder(mediaServerUrl)),
						$lukewestby$elm_http_builder$HttpBuilder$get(
							$author$project$Models$Urls$toString(mediaServerUrl))))));
	});
var $author$project$EventPublisher$Event$StudentClickstreamAvatarSelected = function (a) {
	return {$: 11, a: a};
};
var $author$project$Models$LocalStorage$StudentChoices$setCharacter = F2(
	function (character, choices) {
		return _Utils_update(
			choices,
			{
				v: $elm$core$Maybe$Just(character)
			});
	});
var $author$project$Models$LocalStorage$Helpers$setStudentCharacter = F2(
	function (character, store) {
		return _Utils_update(
			store,
			{
				c$: A2($author$project$Models$LocalStorage$StudentChoices$setCharacter, character, store.c$)
			});
	});
var $author$project$CharacterSelection$CharacterChosen = $elm$core$Basics$identity;
var $author$project$CharacterSelection$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var character = msg.a;
			return _Utils_Tuple2(
				{
					v: $author$project$CharacterSelection$Selected(character)
				},
				$elm$core$Maybe$Nothing);
		} else {
			var character = msg.a;
			return _Utils_Tuple2(
				model,
				$elm$core$Maybe$Just(character));
		}
	});
var $author$project$LoggedIn$handleCharacterSelectionMsg = F5(
	function (student, loginInfo, model, characterSelectionMsg, characterSelectionModel) {
		var _v0 = A2($author$project$CharacterSelection$update, characterSelectionMsg, characterSelectionModel);
		var updatedCharacterSelectionModel = _v0.a;
		var parentMsg = _v0.b;
		if (parentMsg.$ === 1) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						hM: $author$project$Models$Modal$Open(
							$author$project$Models$Modal$CharacterSelectionOverlay(updatedCharacterSelectionModel))
					}),
				$author$project$Effect$NoEffect);
		} else {
			var character = parentMsg.a;
			var updatedLocalStorage = A2(
				$author$project$Models$LocalStorage$Helpers$setStudentCharacter,
				_Utils_Tuple2(model.f7.bv, character),
				model.hz);
			var sendEventMsgForCharacterSelect = $author$project$Effect$GetCurrentTimeEffect(
				function (msg) {
					return function (time) {
						return $author$project$Messages$LoggedInRequiredMsg(
							msg(time));
					};
				}(
					$author$project$LoggedIn$Messages$PublishEventTimestamped(
						$author$project$EventPublisher$Event$StudentClickstreamAvatarSelected(
							{
								gJ: '',
								iY: $author$project$Models$LocalStorage$StudentChoices$characterName(character)
							}))));
			return A2(
				$elm$core$Tuple$mapSecond,
				$author$project$Effect$add(sendEventMsgForCharacterSelect),
				$author$project$Effect$convertToEffect(
					A3(
						$author$project$Helpers$CommandHelper$closeSessionModal,
						student,
						loginInfo,
						_Utils_Tuple2(
							_Utils_update(
								model,
								{hz: updatedLocalStorage}),
							$elm$core$Platform$Cmd$none))));
		}
	});
var $author$project$Models$StudentActivity$WebAppDisplayIFrame = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Models$StudentActivity$setIframeActivityDisplayFooterVisibility = F2(
	function (newVisibility, studentActivity) {
		var updatedViewStatus = function () {
			var _v0 = studentActivity.fL;
			switch (_v0.$) {
				case 1:
					var timerAlertStatus = _v0.a;
					return A2($author$project$Models$StudentActivity$WebAppDisplayIFrame, timerAlertStatus, newVisibility);
				case 2:
					return studentActivity.fL;
				case 3:
					var ebookModel = _v0.a;
					return A2($author$project$Models$StudentActivity$EbookView, ebookModel, newVisibility);
				case 4:
					var choiceTextModel = _v0.a;
					return A2($author$project$Models$StudentActivity$ChoiceTextView, choiceTextModel, newVisibility);
				default:
					return studentActivity.fL;
			}
		}();
		return _Utils_update(
			studentActivity,
			{fL: updatedViewStatus});
	});
var $author$project$LoggedIn$handleDisplayActivityFooter = function (studentActivity) {
	var _v0 = studentActivity.ir;
	if (((_v0.$ === 1) && (!_v0.a.$)) && (!_v0.a.a.$)) {
		var _v1 = _v0.a;
		var _v2 = _v1.a;
		return A2($author$project$Models$StudentActivity$setIframeActivityDisplayFooterVisibility, 0, studentActivity);
	} else {
		return studentActivity;
	}
};
var $author$project$LoggedIn$handleSetProgressToCanComplete = function (studentActivity) {
	var _v0 = studentActivity.ir;
	if (((_v0.$ === 1) && (!_v0.a.$)) && (!_v0.a.a.$)) {
		var _v1 = _v0.a;
		var _v2 = _v1.a;
		var activityAssessmentProgress = _v1.b;
		return A2(
			$author$project$Models$StudentActivity$setIframeActivityDisplayFooterVisibility,
			0,
			_Utils_update(
				studentActivity,
				{
					ir: $author$project$Models$StudentActivity$ActiveStudentActivity(
						A2($author$project$Models$StudentActivity$ContentActivity, $author$project$Models$StudentActivity$CanCompleteContent, activityAssessmentProgress))
				}));
	} else {
		return studentActivity;
	}
};
var $author$project$Activities$ChoiceText$ActivityCanBeCompleted = {$: 0};
var $author$project$Activities$ChoiceText$ActivityErrored = function (a) {
	return {$: 9, a: a};
};
var $author$project$Activities$ChoiceText$ChoiceTextHasLoaded = function (a) {
	return {$: 3, a: a};
};
var $author$project$Activities$ChoiceText$ChoiceTextHasNotLoaded = {$: 4};
var $author$project$Activities$ChoiceText$Model$ChoiceTextLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Activities$ChoiceText$GoToQuestPage = {$: 2};
var $author$project$Activities$ChoiceText$GoToSubjectChoice = {$: 1};
var $author$project$Activities$ChoiceText$NoActionRequired = {$: 6};
var $author$project$Activities$ChoiceText$PlayTTSOnError = function (a) {
	return {$: 7, a: a};
};
var $author$project$Activities$ChoiceText$PopUpBottomBarEnabled = {$: 8};
var $author$project$Activities$ChoiceText$update = F2(
	function (msg, model) {
		if (!model.$) {
			var choiceTextMode = model.a;
			switch (msg.$) {
				case 1:
					var origin = msg.a;
					return _Utils_Tuple2(
						$author$project$Activities$ChoiceText$Model$ChoiceTextLoaded(choiceTextMode),
						$author$project$Activities$ChoiceText$ChoiceTextHasLoaded(origin));
				case 0:
					return _Utils_Tuple2(model, $author$project$Activities$ChoiceText$ChoiceTextHasNotLoaded);
				case 5:
					var value = msg.a;
					return _Utils_Tuple2(
						model,
						$author$project$Activities$ChoiceText$PlayTTSOnError(value));
				case 3:
					return _Utils_Tuple2(model, $author$project$Activities$ChoiceText$GoToSubjectChoice);
				case 4:
					return _Utils_Tuple2(model, $author$project$Activities$ChoiceText$GoToQuestPage);
				case 6:
					return _Utils_Tuple2(model, $author$project$Activities$ChoiceText$PopUpBottomBarEnabled);
				default:
					return _Utils_Tuple2(model, $author$project$Activities$ChoiceText$NoActionRequired);
			}
		} else {
			var choiceTextMode = model.a;
			switch (msg.$) {
				case 1:
					return _Utils_Tuple2(
						$author$project$Activities$ChoiceText$Model$ChoiceTextLoaded(choiceTextMode),
						$author$project$Activities$ChoiceText$NoActionRequired);
				case 0:
					return _Utils_Tuple2(
						$author$project$Activities$ChoiceText$Model$ChoiceTextLoaded(choiceTextMode),
						$author$project$Activities$ChoiceText$NoActionRequired);
				case 5:
					var value = msg.a;
					return _Utils_Tuple2(
						model,
						$author$project$Activities$ChoiceText$PlayTTSOnError(value));
				case 2:
					return _Utils_Tuple2(model, $author$project$Activities$ChoiceText$ActivityCanBeCompleted);
				case 3:
					return _Utils_Tuple2(model, $author$project$Activities$ChoiceText$GoToSubjectChoice);
				case 4:
					return _Utils_Tuple2(model, $author$project$Activities$ChoiceText$GoToQuestPage);
				case 6:
					return _Utils_Tuple2(model, $author$project$Activities$ChoiceText$PopUpBottomBarEnabled);
				case 7:
					var error = msg.a;
					return _Utils_Tuple2(
						model,
						$author$project$Activities$ChoiceText$ActivityErrored(error));
				default:
					var error = msg.a;
					return _Utils_Tuple2(
						model,
						$author$project$Activities$ChoiceText$ActivityErrored(error));
			}
		}
	});
var $author$project$LoggedIn$handleChoiceTextMsg = F5(
	function (choiceTextActivityMsg, choiceTextModel, studentActivity, loginInfo, model) {
		var _v0 = A2($author$project$Activities$ChoiceText$update, choiceTextActivityMsg, choiceTextModel);
		var updatedChoiceTextModel = _v0.a;
		var parentMsg = _v0.b;
		var modelWithUpdatedStudentActivity = A3(
			$author$project$Model$updateStudentActivityById,
			studentActivity.hj,
			function (sa) {
				return _Utils_update(
					sa,
					{
						fL: A2(
							$author$project$Models$StudentActivity$ChoiceTextView,
							updatedChoiceTextModel,
							A2($author$project$Models$StudentActivity$iframeActivityFooterVisibility, sa.f_, sa.fL))
					});
			},
			model);
		switch (parentMsg.$) {
			case 6:
				return _Utils_Tuple2(modelWithUpdatedStudentActivity, $author$project$Effect$NoEffect);
			case 4:
				return _Utils_Tuple2(
					A3($author$project$Model$updateStudentActivityById, studentActivity.hj, $author$project$Models$StudentActivity$setProgressErrored, modelWithUpdatedStudentActivity),
					$author$project$Effect$CmdEffect(
						A3(
							$author$project$Logger$logRedshift,
							modelWithUpdatedStudentActivity,
							'ChoiceText did not load',
							_List_fromArray(
								[
									_Utils_Tuple2(
									'student_activity_id',
									$author$project$Models$Ids$encoder(studentActivity.hj))
								]))));
			case 7:
				var node = parentMsg.a;
				return _Utils_Tuple2(
					modelWithUpdatedStudentActivity,
					$author$project$Effect$CmdEffect(
						$author$project$Helpers$Task$performNow(
							$author$project$Messages$PlayTTSSpeechFromNode(node))));
			case 0:
				return _Utils_Tuple2(
					A3($author$project$Model$updateStudentActivityById, studentActivity.hj, $author$project$LoggedIn$handleSetProgressToCanComplete, modelWithUpdatedStudentActivity),
					$author$project$Effect$NoEffect);
			case 3:
				var origin = parentMsg.a;
				var _v2 = modelWithUpdatedStudentActivity;
				var appConfiguration = _v2.f7;
				var updatedAppConfiguration = _Utils_update(
					appConfiguration,
					{
						dA: $author$project$AppConfiguration$RemoteConnectionActivated(
							{id: origin})
					});
				var updatedModel = _Utils_update(
					modelWithUpdatedStudentActivity,
					{f7: updatedAppConfiguration});
				return _Utils_Tuple2(
					updatedModel,
					$author$project$Effect$batch(
						_List_fromArray(
							[
								A2($author$project$Effect$MarkActivityStartedEffect, studentActivity.hj, loginInfo),
								A4(
								$author$project$Effect$ProvideAuthInfoToChoiceTextEffect,
								$author$project$Activities$ChoiceText$iframeId(updatedChoiceTextModel),
								origin,
								loginInfo,
								model.i$)
							])));
			case 5:
				var cmds = parentMsg.a;
				return _Utils_Tuple2(
					modelWithUpdatedStudentActivity,
					$author$project$Effect$CmdEffect(
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$ChoiceTextActivityMsg, $author$project$Messages$LoggedInRequiredMsg),
							cmds)));
			case 1:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$UpdateUrlEffect(
						$author$project$Routes$routeToUrlString(
							$author$project$Routes$HomeRoute($author$project$Views$SubjectChoiceView$Model$init))));
			case 2:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$UpdateUrlEffect(
						$author$project$Routes$routeToUrlString(
							$author$project$Routes$QuestRoute(
								$author$project$Routes$QuestRoutes$Home(studentActivity.a_)))));
			case 8:
				return _Utils_Tuple2(
					A3($author$project$Model$updateStudentActivityById, studentActivity.hj, $author$project$LoggedIn$handleDisplayActivityFooter, modelWithUpdatedStudentActivity),
					$author$project$Effect$NoEffect);
			default:
				var error = parentMsg.a;
				return _Utils_Tuple2(
					A3(
						$author$project$Model$updateStudentActivityById,
						studentActivity.hj,
						A2(
							$elm$core$Basics$composeR,
							$author$project$Models$StudentActivity$setProgressErrored,
							$author$project$Models$StudentActivity$setIframeActivityDisplayFooterVisibility(0)),
						modelWithUpdatedStudentActivity),
					$author$project$Effect$CmdEffect(
						A3(
							$author$project$Logger$logRedshift,
							modelWithUpdatedStudentActivity,
							'ChoiceText or ChoiceWriting encountered an error ',
							_List_fromArray(
								[
									_Utils_Tuple2(
									'student_activity_id',
									$author$project$Models$Ids$encoder(studentActivity.hj)),
									_Utils_Tuple2(
									'error',
									$elm$json$Json$Encode$string(error))
								]))));
		}
	});
var $author$project$LoggedIn$handleClickQuestHomeNextButton = F3(
	function (loginInfo, studentQuestId, model) {
		var _v0 = A4($author$project$LoggedIn$completeQuestIfNeeded, loginInfo, model, 0, studentQuestId);
		var updatedModel = _v0.a;
		var completeQuestEffect = _v0.b;
		return A3(
			$krisajenkins$remotedata$RemoteData$unwrap,
			_Utils_Tuple2(model, $author$project$Effect$NoEffect),
			function (activeQuest) {
				return activeQuest.es ? _Utils_Tuple2(updatedModel, completeQuestEffect) : _Utils_Tuple2(
					model,
					$author$project$Effect$UpdateUrlEffect(
						$author$project$Routes$routeToUrlString(
							$author$project$Routes$QuestRoute(
								$author$project$Routes$QuestRoutes$Outro(studentQuestId)))));
			},
			model.fZ);
	});
var $author$project$LoggedIn$Messages$FailPostQuizResponse = F2(
	function (a, b) {
		return {$: 34, a: a, b: b};
	});
var $author$project$Api$StudentQuestApi$failPostQuiz = F4(
	function (id, studentActivityId, loginInfo, appConfig) {
		var decoder = A2(
			$elm$json$Json$Decode$map,
			function (activeQuest) {
				return activeQuest(
					$elm$time$Time$millisToPosix(1000));
			},
			$author$project$Models$ActiveQuest$decoder);
		var body = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'postquiz_student_activity_id',
					$author$project$Models$Ids$encoder(studentActivityId))
				]));
		return A2(
			$author$project$Api$HttpRetry$sendTask,
			$author$project$LoggedIn$Messages$FailPostQuizResponse(studentActivityId),
			A2(
				$author$project$Api$RequestHelper$withCurrentTime,
				$author$project$Models$ActiveQuest$decoder,
				A2(
					$author$project$Api$AuthedRequest$put,
					'/api/v5/student_quests/' + ($author$project$Models$Ids$toString(id) + '/fail_postquiz'),
					A5(
						$author$project$Api$RequestHelper$createConfig,
						$author$project$LoggedIn$Messages$FailPostQuizResponse(studentActivityId),
						loginInfo,
						appConfig,
						$author$project$Api$AuthedRequest$Body(body),
						decoder))));
	});
var $author$project$LoggedIn$Messages$PreQuizMasteredResponse = F2(
	function (a, b) {
		return {$: 35, a: a, b: b};
	});
var $author$project$Api$StudentQuestApi$preQuizMastered = F4(
	function (id, studentActivityId, loginInfo, appConfig) {
		var decoder = A2(
			$elm$json$Json$Decode$map,
			function (activeQuest) {
				return activeQuest(
					$elm$time$Time$millisToPosix(1000));
			},
			$author$project$Models$ActiveQuest$decoder);
		var body = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'prequiz_student_activity_id',
					$author$project$Models$Ids$encoder(studentActivityId))
				]));
		return A2(
			$author$project$Api$HttpRetry$sendTask,
			$author$project$LoggedIn$Messages$PreQuizMasteredResponse(studentActivityId),
			A2(
				$author$project$Api$RequestHelper$withCurrentTime,
				$author$project$Models$ActiveQuest$decoder,
				A2(
					$author$project$Api$AuthedRequest$put,
					'/api/v5/student_quests/' + ($author$project$Models$Ids$toString(id) + '/update_prequiz_mastery'),
					A5(
						$author$project$Api$RequestHelper$createConfig,
						$author$project$LoggedIn$Messages$PreQuizMasteredResponse(studentActivityId),
						loginInfo,
						appConfig,
						$author$project$Api$AuthedRequest$Body(body),
						decoder))));
	});
var $author$project$LoggedIn$Messages$ProcessPlacementQuizResponse = F2(
	function (a, b) {
		return {$: 36, a: a, b: b};
	});
var $author$project$Api$StudentQuestApi$processPlacementQuiz = F4(
	function (id, studentActivityId, loginInfo, appConfig) {
		var decoder = A2(
			$elm$json$Json$Decode$map,
			function (activeQuest) {
				return activeQuest(
					$elm$time$Time$millisToPosix(1000));
			},
			$author$project$Models$ActiveQuest$decoder);
		return A2(
			$author$project$Api$HttpRetry$sendTask,
			$author$project$LoggedIn$Messages$ProcessPlacementQuizResponse(studentActivityId),
			A2(
				$author$project$Api$RequestHelper$withCurrentTime,
				$author$project$Models$ActiveQuest$decoder,
				A2(
					$author$project$Api$AuthedRequest$put,
					'/api/v5/student_quests/' + ($author$project$Models$Ids$toString(id) + '/process_placement_quiz'),
					A5(
						$author$project$Api$RequestHelper$createConfig,
						$author$project$LoggedIn$Messages$ProcessPlacementQuizResponse(studentActivityId),
						loginInfo,
						appConfig,
						$author$project$Api$AuthedRequest$EmptyBody,
						decoder))));
	});
var $author$project$LoggedIn$handleCompleteStudentActivityResponse = F4(
	function (updatedStudentActivity, student, loginInfo, model) {
		var _v0 = model.fZ;
		if (_v0.$ === 3) {
			var activeQuest = _v0.a;
			var updatedStudentActivityModel = A3(
				$author$project$Model$updateStudentActivityById,
				updatedStudentActivity.hj,
				function (currentStudentActivity) {
					return _Utils_update(
						updatedStudentActivity,
						{f_: currentStudentActivity.f_});
				},
				model);
			var updateIframeCommand = function () {
				var _v10 = model.f7.cn;
				if (_v10.$ === 1) {
					var origin = _v10.a.id;
					return $author$project$Ports$updateEsparkIframeWithQuestData(
						A2(
							$elm$core$Tuple$pair,
							origin,
							$author$project$TeacherTour$UpdateEsparkIframe$updateData(updatedStudentActivityModel)));
				} else {
					return $elm$core$Platform$Cmd$none;
				}
			}();
			var newModalState = function () {
				var _v8 = _Utils_Tuple2(updatedStudentActivity.f_.f0, updatedStudentActivity.gd);
				if ((_v8.a.$ === 7) && (!_v8.b.$)) {
					var _v9 = _v8.a;
					var someQuizData = _v8.b.a;
					return _Utils_eq(someQuizData.e4, someQuizData.eu) ? $author$project$Models$Modal$Open($author$project$Models$Modal$PrequizMasteryModal) : $author$project$Models$Modal$Closed;
				} else {
					return $author$project$Models$Modal$Closed;
				}
			}();
			var _v1 = function () {
				var _v2 = updatedStudentActivityModel.fZ;
				if (_v2.$ === 3) {
					var updatedActiveQuest = _v2.a;
					var questId = updatedActiveQuest.a_;
					var _v3 = function () {
						if ($author$project$Models$StudentActivity$canStartCheckForUnderstanding(updatedStudentActivity)) {
							return _Utils_Tuple2(
								false,
								$author$project$Routes$activityAssessmentRoute(updatedStudentActivity));
						} else {
							if ($author$project$Models$Activity$isPrePostQuiz(updatedStudentActivity.f_) && ((!_Utils_eq(updatedStudentActivity.gd, $elm$core$Maybe$Nothing)) && (!activeQuest.it.hr))) {
								return _Utils_Tuple2(
									false,
									$author$project$Routes$QuizRoute(
										A2($author$project$Routes$QuizRoutes$Outro, questId, updatedStudentActivity.hj)));
							} else {
								var _v4 = student.f8;
								if (!_v4.$) {
									return _Utils_Tuple2(
										true,
										A3(
											$author$project$AppFlow$Navigation$routeForNextActivity,
											A2($author$project$Helpers$VideoRecordingPermission$videoRecordingPermitted, model.f7.ii, student.iq),
											updatedActiveQuest,
											$author$project$AppFlow$NextIncompleteActivity));
								} else {
									return _Utils_Tuple2(
										false,
										$author$project$Routes$QuestRoute(
											$author$project$Routes$QuestRoutes$Home(questId)));
								}
							}
						}
					}();
					var newAnimationState = _v3.a;
					var urlTo = _v3.b;
					return _Utils_Tuple2(
						newAnimationState,
						A3(
							$author$project$Helpers$NavigationHelper$updateUrl,
							model.hR,
							model.f7,
							$author$project$Routes$routeToUrlString(urlTo)));
				} else {
					return _Utils_Tuple2(false, $elm$core$Platform$Cmd$none);
				}
			}();
			var animationStateUpdate = _v1.a;
			var navigationCommand = _v1.b;
			var nextCommand = function () {
				var _v5 = _Utils_Tuple2(updatedStudentActivity.f_.f0, updatedStudentActivity.gd);
				_v5$2:
				while (true) {
					if (!_v5.b.$) {
						switch (_v5.a.$) {
							case 8:
								var _v6 = _v5.a;
								var someQuizData = _v5.b.a;
								return activeQuest.it.hr ? A2(
									$elm$core$Platform$Cmd$map,
									$author$project$Messages$LoggedInRequiredMsg,
									A4($author$project$Api$StudentQuestApi$processPlacementQuiz, updatedStudentActivity.a_, updatedStudentActivity.hj, loginInfo, model.f7)) : ((!someQuizData.$8) ? A2(
									$elm$core$Platform$Cmd$map,
									$author$project$Messages$LoggedInRequiredMsg,
									A4($author$project$Api$StudentQuestApi$failPostQuiz, updatedStudentActivity.a_, updatedStudentActivity.hj, loginInfo, model.f7)) : navigationCommand);
							case 7:
								var _v7 = _v5.a;
								var someQuizData = _v5.b.a;
								return _Utils_eq(someQuizData.e4, someQuizData.eu) ? A2(
									$elm$core$Platform$Cmd$map,
									$author$project$Messages$LoggedInRequiredMsg,
									A4($author$project$Api$StudentQuestApi$preQuizMastered, updatedStudentActivity.a_, updatedStudentActivity.hj, loginInfo, model.f7)) : navigationCommand;
							default:
								break _v5$2;
						}
					} else {
						break _v5$2;
					}
				}
				return navigationCommand;
			}();
			return A2(
				$author$project$Helpers$CommandHelper$updateWithModalState,
				newModalState,
				_Utils_Tuple2(
					_Utils_update(
						updatedStudentActivityModel,
						{hq: animationStateUpdate}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[nextCommand, updateIframeCommand]))));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$LoggedIn$handleConstructGameMessageMissingRequirements = F4(
	function (studentActivityUpdater, effect, studentActivityId, model) {
		return _Utils_Tuple2(
			A3($author$project$Model$updateStudentActivityById, studentActivityId, studentActivityUpdater, model),
			A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				$author$project$Effect$NoEffect,
				effect,
				A2(
					$elm$core$Maybe$andThen,
					$author$project$Models$Activity$webAppUrl,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.f_;
						},
						A2($author$project$Model$studentActivityForId, model, studentActivityId)))));
	});
var $author$project$Activities$Ebook$ActivityCanBeCompleted = function (a) {
	return {$: 0, a: a};
};
var $author$project$Activities$Ebook$CmdAction = function (a) {
	return {$: 3, a: a};
};
var $author$project$Activities$Ebook$EbookErrored = function (a) {
	return {$: 5, a: a};
};
var $author$project$Activities$Ebook$EbookHasLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Activities$Ebook$EbookHasNotLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Activities$Ebook$NoActionRequired = {$: 4};
var $author$project$Activities$Ebook$Model$PageLoaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Activities$Ebook$Model$PageNotLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Activities$Ebook$PlayTTSOnError = function (a) {
	return {$: 6, a: a};
};
var $author$project$Activities$Ebook$changeToNextPage = _Platform_outgoingPort('changeToNextPage', $elm$json$Json$Encode$int);
var $author$project$Activities$Ebook$changeToPrevPage = _Platform_outgoingPort('changeToPrevPage', $elm$json$Json$Encode$int);
var $author$project$Activities$Ebook$Model$init = {
	C: 1,
	ai: $author$project$Activities$Ebook$Model$FirstPage(1)
};
var $author$project$Activities$Ebook$Model$pageNumber = function (currentPage) {
	switch (currentPage.$) {
		case 0:
			var _int = currentPage.a;
			return _int;
		case 1:
			var _int = currentPage.a;
			return _int;
		default:
			var _int = currentPage.a;
			return _int;
	}
};
var $author$project$Activities$Ebook$nextPageNumber = F2(
	function (currentPage, numberOfPagesDisplayed) {
		var currentPageNumber = $author$project$Activities$Ebook$Model$pageNumber(currentPage);
		return (currentPageNumber === 1) ? (currentPageNumber + 1) : ((currentPageNumber === 2) ? (currentPageNumber + 1) : (currentPageNumber + numberOfPagesDisplayed));
	});
var $author$project$Activities$Ebook$previousPageNumber = F2(
	function (currentPage, numberOfPagesDisplayed) {
		var currentPageNumber = $author$project$Activities$Ebook$Model$pageNumber(currentPage);
		return (currentPageNumber === 1) ? 1 : ((currentPageNumber === 2) ? 1 : (currentPageNumber - numberOfPagesDisplayed));
	});
var $author$project$Activities$Ebook$toggleAudio = _Platform_outgoingPort(
	'toggleAudio',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Activities$Ebook$update = F2(
	function (msg, model) {
		switch (model.$) {
			case 0:
				switch (msg.$) {
					case 0:
						var studentActivityId = msg.a;
						return _Utils_Tuple2(
							$author$project$Activities$Ebook$Model$PageLoaded($author$project$Activities$Ebook$Model$init),
							$author$project$Activities$Ebook$EbookHasLoaded(studentActivityId));
					case 1:
						var studentActivityId = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Activities$Ebook$EbookHasNotLoaded(studentActivityId));
					case 7:
						var error = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Activities$Ebook$EbookErrored(error));
					case 8:
						var value = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Activities$Ebook$PlayTTSOnError(value));
					default:
						return _Utils_Tuple2(model, $author$project$Activities$Ebook$NoActionRequired);
				}
			case 1:
				var modelData = model.a;
				switch (msg.$) {
					case 0:
						return _Utils_Tuple2(
							$author$project$Activities$Ebook$Model$PageLoaded(modelData),
							$author$project$Activities$Ebook$NoActionRequired);
					case 1:
						var studentActivityId = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Activities$Ebook$EbookHasNotLoaded(studentActivityId));
					case 7:
						var error = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Activities$Ebook$EbookErrored(error));
					case 8:
						var value = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Activities$Ebook$PlayTTSOnError(value));
					case 5:
						var page = msg.a.ai;
						var studentActivityId = msg.a.G;
						return _Utils_Tuple2(
							model,
							$author$project$Activities$Ebook$Model$isLast(page) ? $author$project$Activities$Ebook$ActivityCanBeCompleted(studentActivityId) : $author$project$Activities$Ebook$NoActionRequired);
					default:
						return _Utils_Tuple2(model, $author$project$Activities$Ebook$NoActionRequired);
				}
			default:
				var modelData = model.a;
				switch (msg.$) {
					case 0:
						return _Utils_Tuple2(
							$author$project$Activities$Ebook$Model$PageLoaded(modelData),
							$author$project$Activities$Ebook$NoActionRequired);
					case 1:
						return _Utils_Tuple2(
							$author$project$Activities$Ebook$Model$PageLoaded(modelData),
							$author$project$Activities$Ebook$NoActionRequired);
					case 3:
						return _Utils_Tuple2(
							$author$project$Activities$Ebook$Model$PageNotLoaded(modelData),
							$author$project$Activities$Ebook$CmdAction(
								$author$project$Activities$Ebook$changeToNextPage(
									A2($author$project$Activities$Ebook$nextPageNumber, modelData.ai, modelData.C))));
					case 4:
						return _Utils_Tuple2(
							$author$project$Activities$Ebook$Model$PageNotLoaded(modelData),
							$author$project$Activities$Ebook$CmdAction(
								$author$project$Activities$Ebook$changeToPrevPage(
									A2($author$project$Activities$Ebook$previousPageNumber, modelData.ai, modelData.C))));
					case 5:
						var page = msg.a.ai;
						var studentActivityId = msg.a.G;
						var numberOfPagesDisplayed = msg.a.C;
						return _Utils_Tuple2(
							$author$project$Activities$Ebook$Model$PageLoaded(
								_Utils_update(
									modelData,
									{C: numberOfPagesDisplayed, ai: page})),
							$author$project$Activities$Ebook$Model$isLast(page) ? $author$project$Activities$Ebook$ActivityCanBeCompleted(studentActivityId) : $author$project$Activities$Ebook$NoActionRequired);
					case 6:
						var page = msg.a.ai;
						var numberOfPagesDisplayed = msg.a.C;
						return _Utils_Tuple2(
							$author$project$Activities$Ebook$Model$PageLoaded(
								_Utils_update(
									modelData,
									{C: numberOfPagesDisplayed, ai: page})),
							$author$project$Activities$Ebook$NoActionRequired);
					case 2:
						return _Utils_Tuple2(
							$author$project$Activities$Ebook$Model$PageLoaded(modelData),
							$author$project$Activities$Ebook$CmdAction(
								$author$project$Activities$Ebook$toggleAudio(0)));
					case 7:
						var error = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Activities$Ebook$EbookErrored(error));
					default:
						var value = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Activities$Ebook$PlayTTSOnError(value));
				}
		}
	});
var $author$project$LoggedIn$handleEbookMsg = F5(
	function (ebookActivityMsg, ebookModel, studentActivity, loginInfo, model) {
		var _v0 = A2($author$project$Activities$Ebook$update, ebookActivityMsg, ebookModel);
		var updatedEbookModel = _v0.a;
		var parentMsg = _v0.b;
		var modelWithUpdatedStudentActivity = A3(
			$author$project$Model$updateStudentActivityById,
			studentActivity.hj,
			function (sa) {
				return _Utils_update(
					sa,
					{
						fL: A2(
							$author$project$Models$StudentActivity$EbookView,
							updatedEbookModel,
							A2($author$project$Models$StudentActivity$iframeActivityFooterVisibility, sa.f_, sa.fL))
					});
			},
			model);
		switch (parentMsg.$) {
			case 4:
				return _Utils_Tuple2(modelWithUpdatedStudentActivity, $author$project$Effect$NoEffect);
			case 2:
				var studentActivityId = parentMsg.a;
				return _Utils_Tuple2(
					A3($author$project$Model$updateStudentActivityById, studentActivityId, $author$project$Models$StudentActivity$setProgressErrored, modelWithUpdatedStudentActivity),
					$author$project$Effect$CmdEffect(
						A3(
							$author$project$Logger$logRedshift,
							modelWithUpdatedStudentActivity,
							'Ebook did not load',
							_List_fromArray(
								[
									_Utils_Tuple2(
									'student_activity_id',
									$author$project$Models$Ids$encoder(studentActivityId))
								]))));
			case 5:
				var errorData = parentMsg.a;
				var redshiftError = function (errorType) {
					return $author$project$Effect$CmdEffect(
						A3(
							$author$project$Logger$logRedshift,
							modelWithUpdatedStudentActivity,
							errorType,
							_List_fromArray(
								[
									_Utils_Tuple2(
									'student_activity_id',
									$author$project$Models$Ids$encoder(errorData.G)),
									_Utils_Tuple2(
									'error_msg',
									$elm$json$Json$Encode$string(errorData.a9)),
									_Utils_Tuple2(
									'pages_displayed',
									$elm$json$Json$Encode$int(errorData.C)),
									_Utils_Tuple2(
									'current_page',
									$elm$json$Json$Encode$int(errorData.eH)),
									_Utils_Tuple2(
									'is_first',
									$elm$json$Json$Encode$bool(errorData.ad)),
									_Utils_Tuple2(
									'is_last',
									$elm$json$Json$Encode$bool(errorData.ae))
								])));
				};
				return errorData.bc ? _Utils_Tuple2(
					A3($author$project$Model$updateStudentActivityById, errorData.G, $author$project$Models$StudentActivity$setProgressErrored, modelWithUpdatedStudentActivity),
					$author$project$Effect$batch(
						_List_fromArray(
							[
								redshiftError('Ebook critical errored'),
								A2(
								$author$project$Effect$PerformLaterEffect,
								100,
								$author$project$Messages$PlayAllAutoplaySpeech($elm$core$Maybe$Nothing))
							]))) : _Utils_Tuple2(
					modelWithUpdatedStudentActivity,
					redshiftError('Ebook errored'));
			case 6:
				var node = parentMsg.a;
				return _Utils_Tuple2(
					modelWithUpdatedStudentActivity,
					$author$project$Effect$CmdEffect(
						$author$project$Helpers$Task$performNow(
							$author$project$Messages$PlayTTSSpeechFromNode(node))));
			case 0:
				var studentActivityId = parentMsg.a;
				return _Utils_Tuple2(
					A3($author$project$Model$updateStudentActivityById, studentActivityId, $author$project$LoggedIn$handleSetProgressToCanComplete, modelWithUpdatedStudentActivity),
					$author$project$Effect$NoEffect);
			case 1:
				var studentActivityId = parentMsg.a;
				return _Utils_Tuple2(
					modelWithUpdatedStudentActivity,
					A2($author$project$Effect$MarkActivityStartedEffect, studentActivityId, loginInfo));
			default:
				var cmds = parentMsg.a;
				return _Utils_Tuple2(
					modelWithUpdatedStudentActivity,
					$author$project$Effect$CmdEffect(
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$EbookActivityMsg, $author$project$Messages$LoggedInRequiredMsg),
							cmds)));
		}
	});
var $author$project$LoggedIn$Messages$StoredStudentVideoFetchUploadUrlResponse = F3(
	function (a, b, c) {
		return {$: 81, a: a, b: b, c: c};
	});
var $author$project$Helpers$StoredStudentVideoHelper$nextUploadCommand = F3(
	function (studentQuestIds, failedStoredStudentVideoUploadStudentQuestIds, loginInfo) {
		var _v0 = A2(
			$elm$core$List$filter,
			function (id) {
				return !A2($elm$core$List$member, id, failedStoredStudentVideoUploadStudentQuestIds);
			},
			studentQuestIds);
		if (!_v0.b) {
			return $author$project$Effect$NoEffect;
		} else {
			var studentQuestId = _v0.a;
			var remainingStudentQuestIds = _v0.b;
			return A3(
				$author$project$Effect$FetchVideoUploadUrlEffect,
				studentQuestId,
				loginInfo,
				A2($author$project$LoggedIn$Messages$StoredStudentVideoFetchUploadUrlResponse, studentQuestId, remainingStudentQuestIds));
		}
	});
var $author$project$Helpers$StoredStudentVideoHelper$handleFetchUploadUrlError = F3(
	function (remainingStudentQuestIds, loginInfo, model) {
		return _Utils_Tuple2(
			model,
			A3($author$project$Helpers$StoredStudentVideoHelper$nextUploadCommand, remainingStudentQuestIds, model.bC, loginInfo));
	});
var $author$project$LoggedIn$handleIosCompleteStudentVideo = F3(
	function (_v0, loginInfo, model) {
		var _v1 = model.fZ;
		if (_v1.$ === 3) {
			var activeQuest = _v1.a;
			var updatedActiveQuestWithCompletedVideo = function () {
				var maybeUpdatedStudentVideo = A2(
					$elm$core$Maybe$map,
					function (sv) {
						return _Utils_update(
							sv,
							{aM: true});
					},
					activeQuest.jg);
				return _Utils_update(
					activeQuest,
					{jg: maybeUpdatedStudentVideo});
			}();
			var navigateToOutroCmd = function () {
				var questOutroRoute = $author$project$Routes$QuestRoute(
					$author$project$Routes$QuestRoutes$Outro(activeQuest.a_));
				return A3(
					$author$project$Helpers$NavigationHelper$updateUrl,
					model.hR,
					model.f7,
					$author$project$Routes$routeToUrlString(questOutroRoute));
			}();
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						fZ: $krisajenkins$remotedata$RemoteData$succeed(updatedActiveQuestWithCompletedVideo)
					}),
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							navigateToOutroCmd,
							A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Messages$LoggedInRequiredMsg,
							A4($author$project$Api$StudentVideoUploadApi$markRecorded, activeQuest.a_, loginInfo, model.f7, $author$project$LoggedIn$Messages$CompleteStudentVideoResponse))
						])));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Api$RequestHelper$is404 = $elm$core$Basics$eq(
	$elm$http$Http$BadStatus(404));
var $author$project$ErrorHandling$handleHttpErrorNotLoggedIn = F2(
	function (model, httpError) {
		return A2($author$project$ErrorHandling$handleHttpError, model, httpError);
	});
var $author$project$Helpers$CommandHelper$withErrorHandling = F3(
	function (model, httpError, actionDescription) {
		var _v0 = model.jF;
		if (_v0.$ === 2) {
			var student = _v0.a;
			return $author$project$Helpers$CommandHelper$clearDisabledByClick(
				A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, httpError, actionDescription));
		} else {
			return $author$project$Helpers$CommandHelper$clearDisabledByClick(
				A2($author$project$ErrorHandling$handleHttpErrorNotLoggedIn, model, httpError));
		}
	});
var $author$project$LoggedIn$handleLoadStudentQuestResponseError = F4(
	function (httpError, _v0, _v1, model) {
		var navigateHomeCmd = A2(
			$author$project$Helpers$NavigationHelper$pushUrl,
			model.hR,
			$author$project$Routes$routeToUrlString($author$project$Routes$homeRoute));
		var isNoQuest = $author$project$Api$RequestHelper$is404(httpError);
		var _v2 = A3($author$project$Helpers$CommandHelper$withErrorHandling, model, httpError, 'loading student data');
		var updatedModel = _v2.a;
		var errorCommand = _v2.b;
		return isNoQuest ? _Utils_Tuple2(
			_Utils_update(
				model,
				{
					fZ: $krisajenkins$remotedata$RemoteData$Failure(httpError)
				}),
			navigateHomeCmd) : _Utils_Tuple2(
			_Utils_update(
				updatedModel,
				{
					fZ: $krisajenkins$remotedata$RemoteData$Failure(httpError)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[errorCommand, navigateHomeCmd])));
	});
var $author$project$StudentUsageTracker$Model$fromActiveQuest = function (activeQuest) {
	return $elm$core$Maybe$Just(
		{bG: activeQuest.eq, a_: activeQuest.a_});
};
var $author$project$Helpers$ActiveQuestLoadHelper$makeUnavailableAppsNotForThisPlatform = F2(
	function (platform, studentActivity) {
		var unavailableActivity = _Utils_update(
			studentActivity,
			{ir: $author$project$Models$StudentActivity$UnavailableStudentActivity});
		var _v0 = _Utils_Tuple2(platform, studentActivity.f_.ij);
		if (!_v0.a.$) {
			switch (_v0.b) {
				case 1:
					var _v1 = _v0.b;
					return studentActivity;
				case 0:
					var _v2 = _v0.b;
					return unavailableActivity;
				default:
					var _v3 = _v0.b;
					return studentActivity;
			}
		} else {
			switch (_v0.b) {
				case 1:
					var _v4 = _v0.a;
					var _v5 = _v0.b;
					return unavailableActivity;
				case 0:
					var _v6 = _v0.a;
					var _v7 = _v0.b;
					return studentActivity;
				default:
					var _v8 = _v0.a;
					var _v9 = _v0.b;
					return studentActivity;
			}
		}
	});
var $author$project$Helpers$ActiveQuestLoadHelper$initializeActiveQuest = F2(
	function (platform, activeQuest) {
		var updatedStudentActivities = A2(
			$elm$core$List$map,
			$author$project$Helpers$ActiveQuestLoadHelper$makeUnavailableAppsNotForThisPlatform(platform),
			activeQuest.jd);
		return _Utils_update(
			activeQuest,
			{jd: updatedStudentActivities});
	});
var $author$project$Helpers$MediaServer$loadMediaServerUrls = F2(
	function (_v0, studentActivities) {
		var devFlags = _v0.dO;
		var getVideoUrlFromMediaServer = function (studentActivity) {
			var _v1 = $author$project$Models$Activity$hostedVideoUrlToRetrieve(studentActivity.f_);
			if (!_v1.$) {
				var aMediaServerUrl = _v1.a;
				return A3($author$project$Api$MediaServerApi$getVideoUrl, devFlags, studentActivity.hj, aMediaServerUrl);
			} else {
				return $elm$core$Platform$Cmd$none;
			}
		};
		return $elm$core$Platform$Cmd$batch(
			A2($elm$core$List$map, getVideoUrlFromMediaServer, studentActivities));
	});
var $author$project$LoggedIn$handleLoadStudentQuestResponseSuccess = F4(
	function (activeQuestData, student, loginInfo, model) {
		var initializedQuest = A2($author$project$Helpers$ActiveQuestLoadHelper$initializeActiveQuest, model.f7.ii, activeQuestData);
		var updatedModel = _Utils_update(
			model,
			{
				fZ: $krisajenkins$remotedata$RemoteData$succeed(initializedQuest),
				df: false,
				c6: $author$project$StudentUsageTracker$Model$fromActiveQuest(initializedQuest)
			});
		var updateHeartbeatCmd = A3($author$project$Effect$Resolution$performEffect, updatedModel.hR, updatedModel, $author$project$Effect$SetHeartbeatDataEffect);
		var _v0 = updatedModel.df ? $author$project$Helpers$CommandHelper$markActivityErrored(updatedModel) : _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
		var modelWithDelayedActivityUpdates = _v0.a;
		var delayedActivityErrorCommand = _v0.b;
		var loadWebAppTimerCommand = function () {
			var _v2 = _Utils_Tuple2(
				$author$project$Routes$isActivityPracticeRoute(model.eY),
				$author$project$Model$studentActivityForCurrentRoute(modelWithDelayedActivityUpdates));
			if (_v2.a && (!_v2.b.$)) {
				var studentActivity = _v2.b.a;
				return A3(
					$author$project$Effect$Resolution$performEffect,
					model.hR,
					model,
					$author$project$Effect$batch(
						_List_fromArray(
							[
								A2($author$project$Helpers$ActivityEffectHelper$timedActivityEffectsForRouteChange, studentActivity, model),
								A3($author$project$Helpers$ActivityEffectHelper$generalActivityEffects, studentActivity, true, loginInfo)
							])));
			} else {
				return $elm$core$Platform$Cmd$none;
			}
		}();
		var _v1 = model.eY;
		if (!_v1.$) {
			var initialRoute = A2($author$project$AppFlow$Navigation$initialRouteForQuest, student.f8, activeQuestData.a_);
			return _Utils_Tuple2(
				modelWithDelayedActivityUpdates,
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A3(
							$author$project$Helpers$NavigationHelper$updateUrl,
							model.hR,
							model.f7,
							$author$project$Routes$routeToUrlString(initialRoute)),
							delayedActivityErrorCommand,
							updateHeartbeatCmd,
							A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Messages$LoggedInRequiredMsg,
							A2($author$project$Helpers$MediaServer$loadMediaServerUrls, model.f7, activeQuestData.jd))
						])));
		} else {
			return A2(
				$author$project$Effect$Resolution$convertToCmd,
				model.hR,
				A4(
					$author$project$Routing$Updates$forRouteLoggedIn,
					model.eY,
					student,
					loginInfo,
					_Utils_Tuple2(
						modelWithDelayedActivityUpdates,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									delayedActivityErrorCommand,
									A2(
									$elm$core$Platform$Cmd$map,
									$author$project$Messages$LoggedInRequiredMsg,
									A2($author$project$Helpers$MediaServer$loadMediaServerUrls, model.f7, activeQuestData.jd)),
									loadWebAppTimerCommand,
									updateHeartbeatCmd
								])))));
		}
	});
var $author$project$NpsSurvey$NpsDone = {$: 0};
var $author$project$NpsSurvey$NpsErrored = function (a) {
	return {$: 1, a: a};
};
var $author$project$NpsSurvey$PlayTTSFromNode = function (a) {
	return {$: 2, a: a};
};
var $author$project$NpsSurvey$PlayTTSSpeechFromText = function (a) {
	return {$: 3, a: a};
};
var $author$project$NpsSurvey$NpsSubmittedResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$NpsSurvey$sendSurveyResult = F4(
	function (resultMsg, data, loginInfo, appConfig) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$elm$json$Json$Decode$succeed(0),
			resultMsg,
			A2(
				$author$project$Api$AuthedRequest$put,
				'/api/v5/students/survey_response',
				A5(
					$author$project$Api$RequestHelper$createConfig,
					$author$project$NpsSurvey$NpsSubmittedResponse,
					loginInfo,
					appConfig,
					$author$project$Api$AuthedRequest$Body(data),
					$elm$json$Json$Decode$succeed(0))));
	});
var $author$project$NpsSurvey$completeNps = F3(
	function (npsSurveyResult, loginInfo, appConfiguration) {
		return A4(
			$author$project$NpsSurvey$sendSurveyResult,
			$author$project$NpsSurvey$NpsSubmittedResponse,
			$author$project$Models$NpsSurveyResult$encode(npsSurveyResult),
			loginInfo,
			appConfiguration);
	});
var $author$project$NpsSurvey$NpsSkippedResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Models$NpsSurveyResult$encodeSkipped = function (_v0) {
	var id = _v0.hj;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'skipped',
				$elm$json$Json$Encode$bool(true)),
				_Utils_Tuple2(
				'nps_survey_id',
				$author$project$Models$Ids$encoder(id))
			]));
};
var $author$project$NpsSurvey$skipNps = F3(
	function (npsSurveyResult, loginInfo, appConfiguration) {
		return A4(
			$author$project$NpsSurvey$sendSurveyResult,
			$author$project$NpsSurvey$NpsSkippedResponse,
			$author$project$Models$NpsSurveyResult$encodeSkipped(npsSurveyResult),
			loginInfo,
			appConfiguration);
	});
var $author$project$NpsSurvey$update = F4(
	function (loginInfo, appConfiguration, msg, model) {
		switch (msg.$) {
			case 0:
				var ttsText = msg.a;
				var updatedSurveyResult = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h3: updatedSurveyResult}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just(
						$author$project$NpsSurvey$PlayTTSSpeechFromText(ttsText)));
			case 1:
				var updatedSurveyResult = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h3: updatedSurveyResult}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ak: $krisajenkins$remotedata$RemoteData$Loading}),
					A3($author$project$NpsSurvey$skipNps, model.h3, loginInfo, appConfiguration),
					$elm$core$Maybe$Nothing);
			case 3:
				if (!msg.a.$) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								ak: $krisajenkins$remotedata$RemoteData$Success(0)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Just($author$project$NpsSurvey$NpsDone));
				} else {
					var httpError = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								ak: $krisajenkins$remotedata$RemoteData$Failure(httpError)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Just(
							$author$project$NpsSurvey$NpsErrored(httpError)));
				}
			case 4:
				var updatedSurveyResult = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{h3: updatedSurveyResult, ak: $krisajenkins$remotedata$RemoteData$Loading}),
					A3($author$project$NpsSurvey$completeNps, updatedSurveyResult, loginInfo, appConfiguration),
					$elm$core$Maybe$Nothing);
			case 5:
				if (!msg.a.$) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								ak: $krisajenkins$remotedata$RemoteData$Success(0)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Just($author$project$NpsSurvey$NpsDone));
				} else {
					var httpError = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								ak: $krisajenkins$remotedata$RemoteData$Failure(httpError)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Just(
							$author$project$NpsSurvey$NpsErrored(httpError)));
				}
			default:
				var node = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just(
						$author$project$NpsSurvey$PlayTTSFromNode(node)));
		}
	});
var $author$project$Model$updateLoggedInUser = F3(
	function (student, loginInfo, model) {
		var _v0 = model.jF;
		if (_v0.$ === 2) {
			return _Utils_update(
				model,
				{
					jF: A2($author$project$Models$User$LoggedIn, student, loginInfo)
				});
		} else {
			return model;
		}
	});
var $author$project$LoggedIn$handleNpsSurveyMsg = F5(
	function (student, loginInfo, model, npsSurveyMsg, npsSurveyModel) {
		var updatedStudent = _Utils_update(
			student,
			{h2: $elm$core$Maybe$Nothing});
		var _v0 = A4($author$project$NpsSurvey$update, loginInfo, model.f7, npsSurveyMsg, npsSurveyModel);
		var updatedNpsSurveyModel = _v0.a;
		var updatedNpsSurveyCmds = _v0.b;
		var parentMsg = _v0.c;
		var _v1 = A3(
			$author$project$Helpers$CommandHelper$closeSessionModal,
			updatedStudent,
			loginInfo,
			_Utils_Tuple2(
				A3($author$project$Model$updateLoggedInUser, updatedStudent, loginInfo, model),
				$elm$core$Platform$Cmd$none));
		var modelWithNpsClosed = _v1.a;
		var modalCloseCmds = _v1.b;
		if (!parentMsg.$) {
			switch (parentMsg.a.$) {
				case 0:
					var _v3 = parentMsg.a;
					return _Utils_Tuple2(modelWithNpsClosed, modalCloseCmds);
				case 1:
					var httpError = parentMsg.a.a;
					return A2(
						$elm$core$Tuple$mapSecond,
						function (cmds) {
							return $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[modalCloseCmds, cmds]));
						},
						A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, modelWithNpsClosed, httpError, 'submitting NPS'));
				case 2:
					var node = parentMsg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hM: $author$project$Models$Modal$Open(
									$author$project$Models$Modal$NpsSurveyOverlay(updatedNpsSurveyModel))
							}),
						$author$project$Helpers$Task$performNow(
							$author$project$Messages$PlayTTSSpeechFromNode(node)));
				default:
					var ttsText = parentMsg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hM: $author$project$Models$Modal$Open(
									$author$project$Models$Modal$NpsSurveyOverlay(updatedNpsSurveyModel))
							}),
						$author$project$Helpers$Task$performNow(
							$author$project$Messages$PlayTTSSpeechFromText(ttsText)));
			}
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						hM: $author$project$Models$Modal$Open(
							$author$project$Models$Modal$NpsSurveyOverlay(updatedNpsSurveyModel))
					}),
				A2(
					$elm$core$Platform$Cmd$map,
					A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$NpsSurveyMsg, $author$project$Messages$LoggedInRequiredMsg),
					updatedNpsSurveyCmds));
		}
	});
var $author$project$LoggedIn$handleQuestUpdateResponse = F5(
	function (nextRoute, updatedActiveQuest, _v0, _v1, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					fZ: $krisajenkins$remotedata$RemoteData$succeed(updatedActiveQuest)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Helpers$NavigationHelper$updateUrl,
						model.hR,
						model.f7,
						$author$project$Routes$routeToUrlString(nextRoute)),
						A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Messages$LoggedInRequiredMsg,
						A2($author$project$Helpers$MediaServer$loadMediaServerUrls, model.f7, updatedActiveQuest.jd))
					])));
	});
var $author$project$LoggedIn$handleStudentActivityTimerComplete = F4(
	function (studentActivityId, _v0, _v1, model) {
		var outputFunction = F2(
			function (studentActivity, _v4) {
				return (!($author$project$Models$StudentActivity$isErrored(studentActivity) || $author$project$Models$StudentActivity$hasCompletedContentForActiveActivity(studentActivity))) ? A2(
					$author$project$Helpers$CommandHelper$updateWithModalState,
					$author$project$Models$Modal$Open($author$project$Models$Modal$RatingsModal),
					_Utils_Tuple2(
						A3($author$project$Model$updateStudentActivityById, studentActivity.hj, $author$project$LoggedIn$handleSetProgressToCanComplete, model),
						$elm$core$Platform$Cmd$none)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			});
		var _v2 = model.eY;
		if ((_v2.$ === 8) && (_v2.a.$ === 1)) {
			var _v3 = _v2.a;
			var studentActivityIdFromRoute = _v3.b;
			return _Utils_eq(studentActivityId, studentActivityIdFromRoute) ? A2($author$project$Helpers$CommandHelper$withValidatedStudentActivity, model, outputFunction) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$LoggedIn$handleStudentVideoResponse = F4(
	function (updatedStudentVideo, _v0, _v1, model) {
		var updateStudentVideo = function (activeQuest) {
			return _Utils_update(
				activeQuest,
				{
					jg: $elm$core$Maybe$Just(updatedStudentVideo)
				});
		};
		var updatedActiveQuest = A2($krisajenkins$remotedata$RemoteData$map, updateStudentVideo, model.fZ);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{fZ: updatedActiveQuest}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$LoggedIn$Messages$UpdateStudentActivityVideoWatchedResponse = function (a) {
	return {$: 33, a: a};
};
var $author$project$Api$StudentActivityApi$markVideoWatched = F3(
	function (id, loginInfo, appConfig) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$author$project$Models$StudentActivity$decoder,
			$author$project$LoggedIn$Messages$UpdateStudentActivityVideoWatchedResponse,
			A2(
				$author$project$Api$AuthedRequest$put,
				'/api/v5/student_activities/' + ($author$project$Models$StudentActivity$idToString(id) + '/watched_video'),
				A5($author$project$Api$RequestHelper$createConfig, $author$project$LoggedIn$Messages$UpdateStudentActivityVideoWatchedResponse, loginInfo, appConfig, $author$project$Api$AuthedRequest$EmptyBody, $author$project$Models$StudentActivity$decoder)));
	});
var $author$project$LoggedIn$handleVideoActivityPlaybackEnded = F4(
	function (studentActivityId, _v0, loginInfo, model) {
		var markVideoCompleteRequestCmd = A2(
			$elm$core$Platform$Cmd$map,
			$author$project$Messages$LoggedInRequiredMsg,
			A3($author$project$Api$StudentActivityApi$markVideoWatched, studentActivityId, loginInfo, model.f7));
		var _v1 = _Utils_Tuple2(
			_Utils_update(
				model,
				{
					jI: $author$project$Models$VideoPlayer$updateToEnded(model.jI)
				}),
			markVideoCompleteRequestCmd);
		var modelWithEndedVideo = _v1.a;
		var audioCmdIfEnabled = _v1.b;
		var outputFunction = F2(
			function (studentActivity, _v2) {
				var playbackEndedMessage = _Utils_Tuple2(
					A3($author$project$Model$updateStudentActivityById, studentActivity.hj, $author$project$LoggedIn$handleSetProgressToCanComplete, modelWithEndedVideo),
					audioCmdIfEnabled);
				return $author$project$Models$StudentActivity$isCompleted(studentActivity) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : ((studentActivity.hs || $author$project$Models$StudentActivity$canStartCheckForUnderstanding(studentActivity)) ? playbackEndedMessage : A2(
					$author$project$Helpers$CommandHelper$updateWithModalState,
					$author$project$Models$Modal$Open($author$project$Models$Modal$RatingsModal),
					playbackEndedMessage));
			});
		return A2($author$project$Helpers$CommandHelper$withValidatedStudentActivity, model, outputFunction);
	});
var $author$project$EventPublisher$Event$StudentClickStreamClickSelectAdaptivePathQuest = function (a) {
	return {$: 5, a: a};
};
var $author$project$EventPublisher$Event$StudentClickStreamClickSelectAssignmentQuest = function (a) {
	return {$: 6, a: a};
};
var $author$project$LoggedIn$selectQuestToWorkOn = F4(
	function (studentQuestId, subjectArea, isAssignment, model) {
		return A2(
			$elm$core$Tuple$mapSecond,
			$author$project$Effect$add(
				$author$project$Effect$GetCurrentTimeEffect(
					A2(
						$elm$core$Basics$composeR,
						$author$project$LoggedIn$Messages$PublishEventTimestamped(
							isAssignment ? $author$project$EventPublisher$Event$StudentClickStreamClickSelectAssignmentQuest(subjectArea) : $author$project$EventPublisher$Event$StudentClickStreamClickSelectAdaptivePathQuest(subjectArea)),
						$author$project$Messages$LoggedInRequiredMsg))),
			A2(
				$author$project$Helpers$BeforeNavigationHelper$beforeNavigation,
				$author$project$Routes$routeToUrlString(
					$author$project$Routes$QuestRoute(
						$author$project$Routes$QuestRoutes$Home(studentQuestId))),
				model));
	});
var $author$project$VideoFeedback$EndReview = {$: 0};
var $author$project$VideoFeedback$FinishReview = function (a) {
	return {$: 1, a: a};
};
var $author$project$VideoFeedback$MarkStudentVideoFeedbackSeenResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$VideoFeedback$PlayTTSFromNode = function (a) {
	return {$: 4, a: a};
};
var $author$project$VideoFeedback$PortDecodingError = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$VideoFeedback$StudentVideoApiError = function (a) {
	return {$: 3, a: a};
};
var $author$project$VideoFeedback$StudentVideoReviewed = function (a) {
	return {$: 2, a: a};
};
var $author$project$Triple$addThird = F2(
	function (three, _v0) {
		var one = _v0.a;
		var two = _v0.b;
		return _Utils_Tuple3(one, two, three);
	});
var $author$project$Api$StudentVideoUploadApi$markVideoFeedbackComplete = F4(
	function (studentQuestId, loginInfo, appConfig, resultMsg) {
		return A3(
			$author$project$Api$HttpRetry$send,
			$author$project$Models$StudentVideo$decoder,
			resultMsg,
			A2(
				$author$project$Api$AuthedRequest$put,
				'/api/v5/student_quests/' + ($author$project$Models$Ids$toString(studentQuestId) + '/student_video/feedback_seen'),
				A5($author$project$Api$RequestHelper$createConfig, resultMsg, loginInfo, appConfig, $author$project$Api$AuthedRequest$EmptyBody, $author$project$Models$StudentVideo$decoder)));
	});
var $author$project$Models$VideoPlayer$updatePlayerTime = F3(
	function (_v0, player, videoPlayState) {
		var currentTime = _v0.cf;
		var duration = _v0.dQ;
		var resultingTime = F2(
			function (oldTime, newTime) {
				return A2($elm$core$Basics$max, oldTime, newTime);
			});
		switch (videoPlayState.$) {
			case 3:
				var timeData = videoPlayState.a;
				return $author$project$Models$VideoPlayer$Paused(
					{
						cf: A2(resultingTime, timeData.cf, currentTime),
						dQ: duration
					});
			case 1:
				var timeData = videoPlayState.a;
				return A2(
					$author$project$Models$VideoPlayer$Playing,
					{
						cf: A2(resultingTime, timeData.cf, currentTime),
						dQ: duration
					},
					player);
			case 2:
				var timeData = videoPlayState.a;
				var currentPlayer = videoPlayState.b;
				return _Utils_eq(currentPlayer, player) ? A2(
					$author$project$Models$VideoPlayer$Playing,
					{
						cf: A2(resultingTime, timeData.cf, currentTime),
						dQ: duration
					},
					player) : videoPlayState;
			case 4:
				var timeData = videoPlayState.a;
				return $author$project$Models$VideoPlayer$Ended(
					{
						cf: A2(resultingTime, timeData.cf, currentTime),
						dQ: duration
					});
			default:
				var timeData = videoPlayState.a;
				return $author$project$Models$VideoPlayer$NotStarted(
					$elm$core$Maybe$Just(
						{
							cf: A3(
								$elm_community$maybe_extra$Maybe$Extra$unwrap,
								0,
								function (data) {
									return A2(resultingTime, data.cf, currentTime);
								},
								timeData),
							dQ: duration
						}));
		}
	});
var $author$project$Helpers$VideoPlayback$updateVideoTime = F4(
	function (player, videoKey, maybePlayerTime, model) {
		var updatedVideoPlayState = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			$author$project$Models$VideoPlayer$NotStarted($elm$core$Maybe$Nothing),
			function (updatedTimes) {
				return A3($author$project$Models$VideoPlayer$updatePlayerTime, updatedTimes, player, model.jI);
			},
			maybePlayerTime);
		return _Utils_update(
			model,
			{jI: updatedVideoPlayState});
	});
var $author$project$VideoFeedback$update = F4(
	function (loginInfo, appConfiguration, msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just($author$project$VideoFeedback$EndReview));
			case 3:
				var videoId = msg.a;
				return A2(
					$author$project$Triple$addThird,
					$elm$core$Maybe$Nothing,
					A3(
						$author$project$Helpers$VideoPlayback$playVideo,
						_Utils_update(
							model,
							{b4: true}),
						videoId,
						false));
			case 1:
				return A2(
					$author$project$Triple$addThird,
					$elm$core$Maybe$Nothing,
					$author$project$Helpers$VideoPlayback$playbackEnded(model));
			case 2:
				return A2(
					$author$project$Triple$addThird,
					$elm$core$Maybe$Nothing,
					$author$project$Helpers$VideoPlayback$playbackErrored(model));
			case 4:
				var studentQuestId = msg.a;
				return _Utils_Tuple3(
					model,
					A4($author$project$Api$StudentVideoUploadApi$markVideoFeedbackComplete, studentQuestId, loginInfo, appConfiguration, $author$project$VideoFeedback$MarkStudentVideoFeedbackSeenResponse),
					$elm$core$Maybe$Nothing);
			case 6:
				var videoId = msg.a;
				return A2(
					$author$project$Triple$addThird,
					$elm$core$Maybe$Nothing,
					A3($author$project$Helpers$VideoPlayback$playVideo, model, videoId, false));
			case 7:
				var videoId = msg.a;
				return A2(
					$author$project$Triple$addThird,
					$elm$core$Maybe$Nothing,
					A3($author$project$Helpers$VideoPlayback$playVideo, model, videoId, true));
			case 8:
				return A2(
					$author$project$Triple$addThird,
					$elm$core$Maybe$Nothing,
					$author$project$Helpers$VideoPlayback$pauseVideo(model));
			case 9:
				return A2(
					$author$project$Triple$addThird,
					$elm$core$Maybe$Nothing,
					$author$project$Helpers$VideoPlayback$rightClickedOnVideo(model));
			case 11:
				var _v1 = msg.a;
				var videoId = _v1.a;
				var maybePlayerTime = _v1.b;
				return _Utils_Tuple3(
					A4($author$project$Helpers$VideoPlayback$updateVideoTime, 0, videoId, maybePlayerTime, model),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 10:
				var node = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just(
						$author$project$VideoFeedback$PlayTTSFromNode(node)));
			case 12:
				var nextQuest = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just(
						$author$project$VideoFeedback$FinishReview(nextQuest)));
			case 5:
				if (!msg.a.$) {
					var updatedStudentVideo = msg.a.a;
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Just(
							$author$project$VideoFeedback$StudentVideoReviewed(updatedStudentVideo)));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Just(
							$author$project$VideoFeedback$StudentVideoApiError(error)));
				}
			default:
				var portName = msg.a;
				var decodeError = msg.b;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just(
						A2($author$project$VideoFeedback$PortDecodingError, portName, decodeError)));
		}
	});
var $author$project$Models$AvailableQuest$updateStudentVideo = F2(
	function (availableQuestData, newStudentVideo) {
		var updatedVideoFeedbackQuests = A3(
			$elm_community$list_extra$List$Extra$updateIf,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.a_;
				},
				$elm$core$Basics$eq(newStudentVideo.a_)),
			function (studentQuest) {
				return _Utils_update(
					studentQuest,
					{
						jg: $elm$core$Maybe$Just(newStudentVideo)
					});
			},
			availableQuestData.c7);
		var updatedStudentVideo = function (maybeStudentVideo) {
			return A2(
				$elm$core$Maybe$map,
				function (studentVideo) {
					return _Utils_eq(studentVideo.a_, newStudentVideo.a_) ? newStudentVideo : studentVideo;
				},
				maybeStudentVideo);
		};
		var updatedFeedback = function (maybeFeedback) {
			return A2(
				$elm$core$Maybe$map,
				function (feedback) {
					return _Utils_update(
						feedback,
						{
							jg: updatedStudentVideo(feedback.jg)
						});
				},
				maybeFeedback);
		};
		var updatedAdaptivePathQuest = function (maybeAdaptivePathQuest) {
			return A2(
				$elm$core$Maybe$map,
				function (adaptivePathQuest) {
					return _Utils_update(
						adaptivePathQuest,
						{
							cs: updatedFeedback(adaptivePathQuest.cs)
						});
				},
				maybeAdaptivePathQuest);
		};
		return _Utils_update(
			availableQuestData,
			{
				cB: updatedAdaptivePathQuest(availableQuestData.cB),
				cS: updatedAdaptivePathQuest(availableQuestData.cS),
				c7: updatedVideoFeedbackQuests
			});
	});
var $author$project$LoggedIn$handleVideoFeedbackMsg = F5(
	function (student, loginInfo, model, videoFeedbackMsg, videoFeedbackModel) {
		var _v0 = A4($author$project$VideoFeedback$update, loginInfo, model.f7, videoFeedbackMsg, videoFeedbackModel);
		var updatedVideoFeedbackModel = _v0.a;
		var videoFeedbackCmds = _v0.b;
		var parentMsg = _v0.c;
		var updatedModel = _Utils_update(
			model,
			{
				hM: $author$project$Models$Modal$Open(
					$author$project$Models$Modal$VideoFeedbackModal(updatedVideoFeedbackModel))
			});
		var cmds = A2(
			$elm$core$Platform$Cmd$map,
			A2($elm$core$Basics$composeR, $author$project$LoggedIn$Messages$VideoFeedbackMsg, $author$project$Messages$LoggedInRequiredMsg),
			videoFeedbackCmds);
		if (!parentMsg.$) {
			switch (parentMsg.a.$) {
				case 0:
					var _v2 = parentMsg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hM: $author$project$Models$Modal$Closed}),
						$author$project$Effect$NoEffect);
				case 1:
					var nextStudentQuest = parentMsg.a.a;
					return A4(
						$author$project$LoggedIn$selectQuestToWorkOn,
						nextStudentQuest.a_,
						nextStudentQuest.bp,
						false,
						_Utils_update(
							model,
							{hM: $author$project$Models$Modal$Closed}));
				case 2:
					var updatedStudentVideo = parentMsg.a.a;
					return $author$project$Effect$convertToEffect(
						A3(
							$krisajenkins$remotedata$RemoteData$unwrap,
							_Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none),
							function (availableQuestsData) {
								return _Utils_Tuple2(
									_Utils_update(
										updatedModel,
										{
											go: $krisajenkins$remotedata$RemoteData$succeed(
												A2($author$project$Models$AvailableQuest$updateStudentVideo, availableQuestsData, updatedStudentVideo))
										}),
									$elm$core$Platform$Cmd$none);
							},
							updatedModel.go));
				case 3:
					var error = parentMsg.a.a;
					return $author$project$Effect$convertToEffect(
						A3($author$project$Helpers$CommandHelper$withErrorHandling, updatedModel, error, 'marking video feedback seen complete'));
				case 4:
					var node = parentMsg.a.a;
					return _Utils_Tuple2(
						updatedModel,
						$author$project$Effect$PerformNowEffect(
							$author$project$Messages$PlayTTSSpeechFromNode(node)));
				default:
					var _v3 = parentMsg.a;
					var portName = _v3.a;
					var decodeError = _v3.b;
					var _v4 = A2(
						$author$project$Helpers$BrowserConsole$debugLog,
						'Port decode failure!',
						_Utils_Tuple2(portName, decodeError));
					return $author$project$Effect$convertToEffect(
						A4(
							$author$project$ErrorHandling$handleIncomingPortError,
							student,
							model,
							$elm$json$Json$Decode$errorToString(decodeError),
							portName));
			}
		} else {
			return $author$project$Effect$convertToEffect(
				_Utils_Tuple2(updatedModel, cmds));
		}
	});
var $author$project$Effect$MarkRecordedVideoUploadUrlEffect = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Models$LocalStorage$VideoRecordingCounts$remove = F2(
	function (key, history) {
		return A2(
			$elm$core$Dict$remove,
			$author$project$Models$Ids$toString(key),
			history);
	});
var $author$project$Models$LocalStorage$Helpers$removeVideoRecordingCounts = F2(
	function (key, store) {
		return _Utils_update(
			store,
			{
				c9: A2($author$project$Models$LocalStorage$VideoRecordingCounts$remove, key, store.c9)
			});
	});
var $author$project$LoggedIn$handleCompleteStudentVideo = F2(
	function (apiEffect, model) {
		var _v0 = model.fZ;
		if (_v0.$ === 3) {
			var activeQuest = _v0.a;
			var updatedActiveQuestWithCompletedVideo = function () {
				var maybeUpdatedStudentVideo = A2(
					$elm$core$Maybe$map,
					function (sv) {
						return _Utils_update(
							sv,
							{aM: true});
					},
					activeQuest.jg);
				return _Utils_update(
					activeQuest,
					{jg: maybeUpdatedStudentVideo});
			}();
			var studentQuestId = updatedActiveQuestWithCompletedVideo.a_;
			var navigateToOutroEffect = function () {
				var questOutroRoute = $author$project$Routes$QuestRoute(
					$author$project$Routes$QuestRoutes$Outro(activeQuest.a_));
				return $author$project$Effect$UpdateUrlEffect(
					$author$project$Routes$routeToUrlString(questOutroRoute));
			}();
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						fZ: $krisajenkins$remotedata$RemoteData$succeed(updatedActiveQuestWithCompletedVideo),
						hz: A2($author$project$Models$LocalStorage$Helpers$removeVideoRecordingCounts, studentQuestId, model.hz)
					}),
				$author$project$Effect$batch(
					_List_fromArray(
						[
							navigateToOutroEffect,
							apiEffect(activeQuest)
						])));
		} else {
			return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
		}
	});
var $author$project$LoggedIn$handleWebCompleteStudentVideo = F2(
	function (loginInfo, model) {
		return A2(
			$author$project$LoggedIn$handleCompleteStudentVideo,
			function (activeQuest) {
				return A2($author$project$Effect$MarkRecordedVideoUploadUrlEffect, activeQuest.a_, loginInfo);
			},
			model);
	});
var $author$project$Effect$MarkErroredVideoUploadUrlEffect = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$LoggedIn$handleWebErroredStudentVideo = F2(
	function (loginInfo, model) {
		return A2(
			$author$project$LoggedIn$handleCompleteStudentVideo,
			function (activeQuest) {
				return A2($author$project$Effect$MarkErroredVideoUploadUrlEffect, activeQuest.a_, loginInfo);
			},
			model);
	});
var $author$project$Ports$highfive = _Platform_outgoingPort(
	'highfive',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Models$LocalStorage$VideoRecordingCounts$set = F3(
	function (key, entry, history) {
		return A3(
			$elm$core$Dict$insert,
			$author$project$Models$Ids$toString(key),
			entry,
			history);
	});
var $author$project$Models$LocalStorage$VideoRecordingCounts$incrementAttemptCount = F2(
	function (studentQuestId, videoRecordingCounts) {
		return function (entry) {
			return A3(
				$author$project$Models$LocalStorage$VideoRecordingCounts$set,
				studentQuestId,
				_Utils_update(
					entry,
					{gi: entry.gi + 1}),
				videoRecordingCounts);
		}(
			A2($author$project$Models$LocalStorage$VideoRecordingCounts$get, studentQuestId, videoRecordingCounts));
	});
var $author$project$Models$LocalStorage$Helpers$incrementVideoRecordingAttemptCount = F2(
	function (key, store) {
		return _Utils_update(
			store,
			{
				c9: A2($author$project$Models$LocalStorage$VideoRecordingCounts$incrementAttemptCount, key, store.c9)
			});
	});
var $author$project$Models$LocalStorage$VideoRecordingCounts$incrementDeletedCount = F2(
	function (studentQuestId, videoRecordingCounts) {
		return function (entry) {
			return A3(
				$author$project$Models$LocalStorage$VideoRecordingCounts$set,
				studentQuestId,
				_Utils_update(
					entry,
					{gO: entry.gO + 1}),
				videoRecordingCounts);
		}(
			A2($author$project$Models$LocalStorage$VideoRecordingCounts$get, studentQuestId, videoRecordingCounts));
	});
var $author$project$Models$LocalStorage$Helpers$incrementVideoRecordingDeletedCount = F2(
	function (key, store) {
		return _Utils_update(
			store,
			{
				c9: A2($author$project$Models$LocalStorage$VideoRecordingCounts$incrementDeletedCount, key, store.c9)
			});
	});
var $author$project$Models$LocalStorage$VideoRecordingCounts$incrementPermissionRetryCount = F2(
	function (studentQuestId, videoRecordingCounts) {
		return function (entry) {
			return A3(
				$author$project$Models$LocalStorage$VideoRecordingCounts$set,
				studentQuestId,
				_Utils_update(
					entry,
					{cN: entry.cN + 1}),
				videoRecordingCounts);
		}(
			A2($author$project$Models$LocalStorage$VideoRecordingCounts$get, studentQuestId, videoRecordingCounts));
	});
var $author$project$Models$LocalStorage$Helpers$incrementVideoRecordingPermissionRetryCount = F2(
	function (key, store) {
		return _Utils_update(
			store,
			{
				c9: A2($author$project$Models$LocalStorage$VideoRecordingCounts$incrementPermissionRetryCount, key, store.c9)
			});
	});
var $author$project$VideoRecording$RecordingProcess$isErrored = function (recordingProcess) {
	if (recordingProcess.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$VideoRecording$RecordingProcess$isRecorded = function (recordingProcess) {
	if (recordingProcess.$ === 5) {
		return true;
	} else {
		return false;
	}
};
var $author$project$VideoRecording$RecordingProcess$isStarting = function (recordingProcess) {
	if (!recordingProcess.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$AppTimer$isValidAppTimer = F2(
	function (appTimer, maybeModelAppTimer) {
		if (!maybeModelAppTimer.$) {
			var modelAppTimer = maybeModelAppTimer.a;
			return _Utils_eq(appTimer.bq, modelAppTimer.bq) || (!appTimer.ar);
		} else {
			return false;
		}
	});
var $author$project$Routes$VideoRoutes$isRecordingRoute = function (route) {
	switch (route.$) {
		case 0:
			return false;
		case 1:
			return false;
		default:
			return true;
	}
};
var $author$project$Routes$isVideoRecording = function (route) {
	if (route.$ === 9) {
		var videoRoute = route.a;
		return $author$project$Routes$VideoRoutes$isRecordingRoute(videoRoute);
	} else {
		return false;
	}
};
var $author$project$LoggedIn$Messages$DisplayWebAppIframe = function (a) {
	return {$: 44, a: a};
};
var $author$project$Effect$LogGameActivityBrowserHealthEffect = function (a) {
	return {$: 44, a: a};
};
var $author$project$LoggedIn$Messages$OpenActivityInNewTab = F2(
	function (a, b) {
		return {$: 48, a: a, b: b};
	});
var $author$project$Assets$Favicon$webActivityInProgressFavicons = _List_fromArray(
	[
		{Q: require('/usr/src/app/app/images/favicons/web-activity-in-progress-1@2x.png'), T: require('/usr/src/app/app/images/favicons/web-activity-in-progress-1.png')},
		{Q: require('/usr/src/app/app/images/favicons/web-activity-in-progress-2@2x.png'), T: require('/usr/src/app/app/images/favicons/web-activity-in-progress-2.png')},
		{Q: require('/usr/src/app/app/images/favicons/web-activity-in-progress-3@2x.png'), T: require('/usr/src/app/app/images/favicons/web-activity-in-progress-3.png')},
		{Q: require('/usr/src/app/app/images/favicons/web-activity-in-progress-4@2x.png'), T: require('/usr/src/app/app/images/favicons/web-activity-in-progress-4.png')},
		{Q: require('/usr/src/app/app/images/favicons/web-activity-in-progress-5@2x.png'), T: require('/usr/src/app/app/images/favicons/web-activity-in-progress-5.png')}
	]);
var $author$project$Assets$Favicon$webActivityInProgressFrameCount = $elm$core$List$length($author$project$Assets$Favicon$webActivityInProgressFavicons);
var $author$project$Assets$Favicon$calculateWebActivityInProgressFrameRate = function (maximumActivityTime) {
	return maximumActivityTime / $author$project$Assets$Favicon$webActivityInProgressFrameCount;
};
var $author$project$Helpers$ActivityEffectHelper$StartTimedApp = 2;
var $author$project$Helpers$ActivityEffectHelper$timedActivityEffectsForStartTimedApp = F2(
	function (studentActivity, model) {
		return A3($author$project$Helpers$ActivityEffectHelper$timedActivityEffects, studentActivity, 2, model);
	});
var $author$project$Assets$Favicon$DefaultIteration = {$: 0};
var $author$project$Assets$Favicon$webActivityInProgress = function (frameRate) {
	return A3(
		$author$project$Assets$Favicon$Dynamic,
		$author$project$Assets$Favicon$webActivityInProgressFavicons,
		$author$project$Assets$Favicon$FrameRate(frameRate),
		$author$project$Assets$Favicon$DefaultIteration);
};
var $author$project$Models$StudentActivity$webAppLaunchParams = function (_v0) {
	var id = _v0.hj;
	var activity = _v0.f_;
	var _v1 = activity.f0;
	if (_v1.$ === 1) {
		var url = _v1.a;
		return $elm$core$Maybe$Just(
			{
				ea: $author$project$Models$Activity$instructionsToString(activity.ea),
				hG: activity.hG,
				G: id,
				fE: url
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$LoggedIn$launchStudentActivity = F4(
	function (launchEvent, studentActivityId, loginInfo, model) {
		var startActivityOnExternalLaunchEffect = function (aStudentActivity) {
			var webAppActivityUrlString = A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				'',
				$author$project$Models$Urls$toString,
				$author$project$Models$Activity$webAppUrl(aStudentActivity.f_));
			var startActivityMessage = aStudentActivity.f_.dl ? $author$project$Messages$LoggedInRequiredMsg(
				$author$project$LoggedIn$Messages$DisplayWebAppIframe(aStudentActivity.hj)) : A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				$author$project$Messages$NoOp,
				A2(
					$elm$core$Basics$composeR,
					$author$project$LoggedIn$Messages$OpenActivityInNewTab(aStudentActivity.hj),
					$author$project$Messages$LoggedInRequiredMsg),
				$author$project$Models$StudentActivity$webAppLaunchParams(aStudentActivity));
			return _Utils_Tuple2(
				model,
				$author$project$Effect$batch(
					_List_fromArray(
						[
							A2($author$project$Helpers$ActivityEffectHelper$timedActivityEffectsForStartTimedApp, aStudentActivity, model),
							A3($author$project$Helpers$ActivityEffectHelper$generalActivityEffects, aStudentActivity, false, loginInfo),
							$author$project$Effect$ChangeFavicon(
							$author$project$Assets$Favicon$webActivityInProgress(
								$author$project$Assets$Favicon$calculateWebActivityInProgressFrameRate(aStudentActivity.f_.hG))),
							$author$project$Effect$GetCurrentTimeEffect(
							A2(
								$elm$core$Basics$composeR,
								$author$project$LoggedIn$Messages$PublishEventTimestamped(
									launchEvent(
										{er: aStudentActivity.f_.dl, fs: webAppActivityUrlString})),
								$author$project$Messages$LoggedInRequiredMsg)),
							$author$project$Effect$LogGameActivityBrowserHealthEffect(studentActivityId),
							$author$project$Effect$PerformNowEffect(startActivityMessage)
						])));
		};
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			_Utils_Tuple2(model, $author$project$Effect$NoEffect),
			startActivityOnExternalLaunchEffect,
			A2($author$project$Model$studentActivityForId, model, studentActivityId));
	});
var $author$project$Helpers$ReturnNotificationCommand$encodeStudentActivityId = function (maybeContent) {
	return A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		$elm$json$Json$Encode$null,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.G;
			},
			$author$project$Models$Ids$encoder),
		maybeContent);
};
var $author$project$Helpers$ReturnNotificationCommand$hasImage = function (maybeContent) {
	return $elm$json$Json$Encode$bool(
		!_Utils_eq(
			$elm$core$Maybe$Nothing,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.d7;
				},
				maybeContent)));
};
var $author$project$Helpers$ReturnNotificationCommand$logReturn = F3(
	function (model, maybeContent, timeToReturnInMs) {
		var loggingData = _List_fromArray(
			[
				_Utils_Tuple2(
				'student_activity_id',
				$author$project$Helpers$ReturnNotificationCommand$encodeStudentActivityId(maybeContent)),
				_Utils_Tuple2(
				'has_image',
				$author$project$Helpers$ReturnNotificationCommand$hasImage(maybeContent)),
				_Utils_Tuple2(
				'time_to_return_in_seconds',
				$elm$json$Json$Encode$float(timeToReturnInMs / 1000))
			]);
		return A3($author$project$Logger$logRedshift, model, 'StudentReturnedViaNotification', loggingData);
	});
var $author$project$Helpers$ReturnNotificationCommand$logSent = F2(
	function (model, maybeContent) {
		var loggingData = _List_fromArray(
			[
				_Utils_Tuple2(
				'student_activity_id',
				$author$project$Helpers$ReturnNotificationCommand$encodeStudentActivityId(maybeContent)),
				_Utils_Tuple2(
				'has_image',
				$author$project$Helpers$ReturnNotificationCommand$hasImage(maybeContent))
			]);
		return A3($author$project$Logger$logRedshift, model, 'ReturnNotificationSent', loggingData);
	});
var $author$project$Helpers$StoredStudentVideoHelper$markVideoUploadedFailure = function (model) {
	return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
};
var $author$project$Helpers$StoredStudentVideoHelper$markVideoUploadedSuccess = function (model) {
	return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
};
var $author$project$LoggedIn$Messages$HostedVideoHealthCheckResult = function (a) {
	return {$: 11, a: a};
};
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{gs: $elm$http$Http$emptyBody, dU: r.dU, d3: _List_Nil, ex: 'GET', jx: $elm$core$Maybe$Nothing, jz: $elm$core$Maybe$Nothing, fE: r.fE});
};
var $author$project$Api$MediaServerApi$performHealthCheck = F2(
	function (includeYoutubeParam, mediaServerHealthCheckUrl) {
		return A2(
			$elm$core$Platform$Cmd$map,
			$author$project$LoggedIn$Messages$HostedVideoHealthCheckResult,
			$elm$http$Http$get(
				{
					dU: A2(
						$elm$http$Http$expectJson,
						$elm$core$Basics$identity,
						A2(
							$elm$json$Json$Decode$map,
							$elm$core$Basics$always(0),
							A2($elm$json$Json$Decode$field, 'status', $elm$json$Json$Decode$string))),
					fE: includeYoutubeParam ? (mediaServerHealthCheckUrl + '?url=https://www.youtube.com/watch?v=7yh9i0PAjck&app=desktop') : mediaServerHealthCheckUrl
				}));
	});
var $author$project$Helpers$Task$performWithDelayAndTimestamp = F2(
	function (timeInMilliseconds, msg) {
		return A2(
			$elm$core$Task$perform,
			msg,
			A3(
				$elm$core$Task$map2,
				F2(
					function (_v0, currentTime) {
						return currentTime;
					}),
				$elm$core$Process$sleep(timeInMilliseconds),
				$elm$time$Time$now));
	});
var $author$project$VideoRecording$RecordingProcess$Recorded = function (a) {
	return {$: 5, a: a};
};
var $author$project$VideoRecording$RecordingProcess$playbackIsReady = function (recordingProcess) {
	if (recordingProcess.$ === 5) {
		var data = recordingProcess.a;
		return $author$project$VideoRecording$RecordingProcess$Recorded(
			_Utils_update(
				data,
				{bQ: true}));
	} else {
		return A2($author$project$Helpers$BrowserConsole$debugLog, 'received playbackReady in wrong state', recordingProcess);
	}
};
var $author$project$Routes$questHomeRoute = A2($elm$core$Basics$composeR, $author$project$Routes$QuestRoutes$Home, $author$project$Routes$QuestRoute);
var $author$project$VideoRecording$RecordingProcess$recordingReadyStatus = function (process) {
	switch (process.$) {
		case 3:
			var readyStatus = process.a;
			return $elm$core$Maybe$Just(readyStatus);
		case 2:
			var readyStatus = process.b;
			return readyStatus;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$VideoRecording$RecordingProcess$Ready = function (a) {
	return {$: 3, a: a};
};
var $author$project$VideoRecording$RecordingProcess$Recording = function (a) {
	return {$: 4, a: a};
};
var $author$project$VideoRecording$RecordingProcess$UploadingFile = {$: 1};
var $author$project$VideoRecording$RecordingProcess$recordingStatusFromStatusUpdate = F2(
	function (counts, status) {
		switch (status.$) {
			case 0:
				return $author$project$VideoRecording$RecordingProcess$NotStarted;
			case 1:
				return $author$project$VideoRecording$RecordingProcess$cameraNotReadyStateFor(counts);
			case 2:
				var errorMessage = status.a;
				return A2($author$project$VideoRecording$RecordingProcess$Errored, errorMessage, $elm$core$Maybe$Nothing);
			case 3:
				var numberOfCameras = status.a;
				return ($author$project$VideoRecording$RecordingProcess$numberOfErroneousAttempts(counts) >= 1) ? A2(
					$author$project$VideoRecording$RecordingProcess$Errored,
					'Oops try again',
					$elm$core$Maybe$Just(
						{cI: numberOfCameras})) : $author$project$VideoRecording$RecordingProcess$Ready(
					{cI: numberOfCameras});
			case 4:
				var recordingDuration = status.a;
				return $author$project$VideoRecording$RecordingProcess$Recording(recordingDuration);
			case 5:
				var dataUrl = status.a;
				return $author$project$VideoRecording$RecordingProcess$Recorded(
					{
						cg: dataUrl,
						aT: $author$project$Models$VideoPlayer$NotStarted($elm$core$Maybe$Nothing),
						bQ: false,
						bT: false,
						c5: $author$project$VideoRecording$RecordingProcess$UploadingFile
					});
			default:
				return $author$project$VideoRecording$RecordingProcess$NotStarted;
		}
	});
var $author$project$VideoRecording$RecordingProcess$retryErrorToReady = function (oldProcess) {
	if ((oldProcess.$ === 2) && (!oldProcess.b.$)) {
		var readyStatus = oldProcess.b.a;
		return $author$project$VideoRecording$RecordingProcess$Ready(readyStatus);
	} else {
		return oldProcess;
	}
};
var $author$project$Models$Activity$saveHostedVideoData = F2(
	function (hostedVideoData, activity) {
		var _v0 = activity.f0;
		if (!_v0.$) {
			var data = _v0.b;
			return _Utils_update(
				activity,
				{
					f0: A2($author$project$Models$Activity$YoutubeVideo, hostedVideoData, data)
				});
		} else {
			return activity;
		}
	});
var $author$project$Models$Activity$updateHostedVideoUrlLoadingState = F2(
	function (activity, loadingState) {
		var _v0 = activity.f0;
		if ((!_v0.$) && (!_v0.a.$)) {
			var _v1 = _v0.a;
			var mediaServerUrl = _v1.b;
			var ytData = _v0.b;
			return _Utils_update(
				activity,
				{
					f0: A2(
						$author$project$Models$Activity$YoutubeVideo,
						A2($author$project$Models$Activity$VideoUrlNotYetLoaded, loadingState, mediaServerUrl),
						ytData)
				});
		} else {
			return activity;
		}
	});
var $author$project$LoggedIn$setHostedVideoLoadingStateToFetchingIfFailed = F2(
	function (model, studentActivityId) {
		return A3(
			$author$project$Model$updateStudentActivityById,
			studentActivityId,
			function (studentActivity) {
				return $author$project$Models$Activity$hostedVideoFailedToLoad(studentActivity.f_) ? _Utils_update(
					studentActivity,
					{
						f_: A2($author$project$Models$Activity$updateHostedVideoUrlLoadingState, studentActivity.f_, 0)
					}) : studentActivity;
			},
			model);
	});
var $author$project$Models$StudentActivity$FlashTimerAlert = 1;
var $author$project$Models$StudentActivity$setIframeActivityDisplayFlashAlert = function (studentActivity) {
	return _Utils_update(
		studentActivity,
		{
			fL: A2(
				$author$project$Models$StudentActivity$WebAppDisplayIFrame,
				1,
				A2($author$project$Models$StudentActivity$iframeActivityFooterVisibility, studentActivity.f_, studentActivity.fL))
		});
};
var $author$project$Models$StudentActivity$NoTimerAlert = 0;
var $author$project$Models$StudentActivity$setInitialIframeActivityDisplay = function (studentActivity) {
	return _Utils_update(
		studentActivity,
		{
			fL: A2($author$project$Models$StudentActivity$WebAppDisplayIFrame, 0, 1)
		});
};
var $author$project$Models$StudentActivity$WebAppIframeError = {$: 2};
var $author$project$Models$StudentActivity$setWebAppIframeError = function (studentActivity) {
	return _Utils_update(
		studentActivity,
		{fL: $author$project$Models$StudentActivity$WebAppIframeError});
};
var $author$project$AppFlow$shouldGoToNextQuest = $elm$core$Basics$eq(0);
var $author$project$VideoRecording$RecordingProcess$skipPlaybackIfNotPlayed = function (recordingProcess) {
	if (recordingProcess.$ === 5) {
		var data = recordingProcess.a;
		var playback = data.aT;
		return (!($author$project$Models$VideoPlayer$isPlaying(playback) || $author$project$Models$VideoPlayer$isEnded(playback))) ? $author$project$VideoRecording$RecordingProcess$Recorded(
			_Utils_update(
				data,
				{
					aT: $author$project$Models$VideoPlayer$Ended(
						$author$project$Models$VideoPlayer$playerTime(playback)),
					bT: true
				})) : recordingProcess;
	} else {
		return A2($author$project$Helpers$BrowserConsole$debugLog, 'received skipPlaybackIfNotPlayed in wrong state', recordingProcess);
	}
};
var $author$project$Helpers$StoredStudentVideoHelper$startUploading = F3(
	function (studentQuestIds, loginInfo, model) {
		return _Utils_Tuple2(
			model,
			A3($author$project$Helpers$StoredStudentVideoHelper$nextUploadCommand, studentQuestIds, model.bC, loginInfo));
	});
var $author$project$Ports$startVideoActivity = _Platform_outgoingPort(
	'startVideoActivity',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$json$Json$Encode$string(b)
				]));
	});
var $author$project$Helpers$ActivityEffectHelper$Refresh = 1;
var $author$project$Helpers$ActivityEffectHelper$timedActivityEffectsForRefresh = F2(
	function (studentActivity, model) {
		return A3($author$project$Helpers$ActivityEffectHelper$timedActivityEffects, studentActivity, 1, model);
	});
var $author$project$Effect$RecordUsageEffect = F2(
	function (a, b) {
		return {$: 60, a: a, b: b};
	});
var $author$project$StudentUsageTracker$Model$secondsSinceLastTracking = F2(
	function (currentTime, _v0) {
		var previousUsageData = _v0;
		return A2(
			$elm$core$Maybe$map,
			function (_v1) {
				var studentQuestId = _v1.a_;
				var lastUsageRecordedAt = _v1.bG;
				return _Utils_Tuple2(
					studentQuestId,
					A2(
						$elm$core$Basics$max,
						(($elm$time$Time$posixToMillis(currentTime) - $elm$time$Time$posixToMillis(lastUsageRecordedAt)) / 1000) | 0,
						0));
			},
			previousUsageData);
	});
var $author$project$StudentUsageTracker$defaultRecordEffect = F3(
	function (currentTime, loginInfo, model) {
		var _v0 = A2($author$project$StudentUsageTracker$Model$secondsSinceLastTracking, currentTime, model);
		if (_v0.$ === 1) {
			return $author$project$Effect$NoEffect;
		} else {
			var _v1 = _v0.a;
			var studentQuestId = _v1.a;
			var secondsSinceLastTracking = _v1.b;
			return (secondsSinceLastTracking > 0) ? A2(
				$author$project$Effect$RecordUsageEffect,
				loginInfo,
				_Utils_Tuple2(studentQuestId, secondsSinceLastTracking)) : $author$project$Effect$NoEffect;
		}
	});
var $author$project$StudentUsageTracker$maxMinutesForNonQuizStudentActivity = 7;
var $author$project$StudentUsageTracker$maxMinutesPerQuizQuestion = 2;
var $author$project$StudentUsageTracker$maxMinutesForStudentActivity = function (studentActivity) {
	return $author$project$Models$Activity$isPrePostQuiz(studentActivity.f_) ? ($author$project$StudentUsageTracker$maxMinutesPerQuizQuestion * $elm$core$List$length(
		$author$project$Models$StudentActivity$itemScores(studentActivity))) : $author$project$StudentUsageTracker$maxMinutesPerQuizQuestion;
};
var $author$project$StudentUsageTracker$Model$updatedTrackingData = F2(
	function (studentQuestId, currentTime) {
		return A2(
			$elm$core$Maybe$map,
			function (id) {
				return {bG: currentTime, a_: id};
			},
			studentQuestId);
	});
var $author$project$StudentUsageTracker$update = F5(
	function (appModel, studentQuestId, loginInfo, _v0, model) {
		var currentTime = _v0;
		var timeSinceLastNavigation = $elm$time$Time$posixToMillis(currentTime) - $elm$time$Time$posixToMillis(appModel.hw);
		var currentStudentActivity = $author$project$Model$studentActivityForCurrentRoute(appModel);
		var isOutsideEspark = A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			false,
			function (_v2) {
				var activity = _v2.f_;
				return $author$project$Models$Activity$isWebApp(activity) && activity.dl;
			},
			currentStudentActivity);
		var maxMinutesForCurrentActivity = A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $author$project$StudentUsageTracker$maxMinutesForNonQuizStudentActivity, $author$project$StudentUsageTracker$maxMinutesForStudentActivity, currentStudentActivity);
		var recordEffect = function () {
			var _v1 = A2($author$project$Models$WindowFocus$secondsSinceFocusLost, currentTime, appModel.fS);
			if (_v1.$ === 1) {
				return (_Utils_cmp(timeSinceLastNavigation, (maxMinutesForCurrentActivity * 60) * 1000) < 0) ? A3($author$project$StudentUsageTracker$defaultRecordEffect, currentTime, loginInfo, model) : $author$project$Effect$NoEffect;
			} else {
				var secondsSinceLost = _v1.a;
				return ((secondsSinceLost > 0) && ((secondsSinceLost < 60) || (isOutsideEspark && (_Utils_cmp(secondsSinceLost, 7 * 60) < 0)))) ? A3($author$project$StudentUsageTracker$defaultRecordEffect, currentTime, loginInfo, model) : $author$project$Effect$NoEffect;
			}
		}();
		return _Utils_Tuple2(
			A2($author$project$StudentUsageTracker$Model$updatedTrackingData, studentQuestId, currentTime),
			recordEffect);
	});
var $author$project$HealthChecks$updateHostedVideoStatus = F2(
	function (newState, appHealth) {
		var hostedVideo = appHealth.bE;
		return _Utils_update(
			appHealth,
			{bE: newState});
	});
var $author$project$VideoRecording$RecordingProcess$updatePlayback = F2(
	function (playback, recordingProcess) {
		if (recordingProcess.$ === 5) {
			var data = recordingProcess.a;
			return $author$project$Models$VideoPlayer$isEnded(playback) ? $author$project$VideoRecording$RecordingProcess$Recorded(
				_Utils_update(
					data,
					{aT: playback, bT: true})) : $author$project$VideoRecording$RecordingProcess$Recorded(
				_Utils_update(
					data,
					{aT: playback}));
		} else {
			return A2($author$project$Helpers$BrowserConsole$debugLog, 'received uploadPlayback in wrong state', recordingProcess);
		}
	});
var $author$project$VideoRecording$RecordingProcess$updateUploadStatus = F2(
	function (uploadStatus, recordingProcess) {
		if (recordingProcess.$ === 5) {
			var data = recordingProcess.a;
			return $author$project$VideoRecording$RecordingProcess$Recorded(
				_Utils_update(
					data,
					{c5: uploadStatus}));
		} else {
			return A2($author$project$Helpers$BrowserConsole$debugLog, 'received uploadStatus in wrong state', recordingProcess);
		}
	});
var $author$project$LoggedIn$updateVideoRecordingCounts = F2(
	function (model, incrementFunction) {
		return A3(
			$krisajenkins$remotedata$RemoteData$unwrap,
			model,
			function (_v0) {
				var studentQuestId = _v0.a_;
				var newLocalStorage = A2(incrementFunction, studentQuestId, model.hz);
				return _Utils_update(
					model,
					{hz: newLocalStorage});
			},
			model.fZ);
	});
var $author$project$VideoRecording$RecordingProcess$uploadErrorHack = function (errorMessage) {
	return $author$project$VideoRecording$RecordingProcess$Recorded(
		{
			cg: $author$project$Models$Urls$fromString('foo'),
			aT: $author$project$Models$VideoPlayer$NotStarted($elm$core$Maybe$Nothing),
			bQ: true,
			bT: true,
			c5: $author$project$VideoRecording$RecordingProcess$FileUploadFailed(errorMessage)
		});
};
var $author$project$Effect$UploadStoredStudentVideoEffect = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Helpers$StoredStudentVideoHelper$uploadVideo = F3(
	function (studentQuestId, videoUploadData, model) {
		return _Utils_Tuple2(
			model,
			A2($author$project$Effect$UploadStoredStudentVideoEffect, studentQuestId, videoUploadData));
	});
var $author$project$Ports$videoRecordingAction = _Platform_outgoingPort('videoRecordingAction', $elm$json$Json$Encode$string);
var $author$project$Effect$RetrieveStoredStudentVideoEffect = {$: 21};
var $author$project$Helpers$StoredStudentVideoHelper$videoRemoved = function (model) {
	return _Utils_Tuple2(model, $author$project$Effect$RetrieveStoredStudentVideoEffect);
};
var $author$project$Helpers$StoredStudentVideoHelper$videoUploadFailed = F2(
	function (studentQuestId, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					bC: A2($elm$core$List$cons, studentQuestId, model.bC)
				}),
			$author$project$Effect$RetrieveStoredStudentVideoEffect);
	});
var $author$project$Effect$MarkStudentVideoUploadedEffect = F2(
	function (a, b) {
		return {$: 20, a: a, b: b};
	});
var $author$project$Effect$RemoveStoredStudentVideoEffect = function (a) {
	return {$: 19, a: a};
};
var $author$project$Helpers$StoredStudentVideoHelper$videoUploaded = F3(
	function (studentQuestId, loginInfo, model) {
		return _Utils_Tuple2(
			model,
			$author$project$Effect$batch(
				_List_fromArray(
					[
						$author$project$Effect$RemoveStoredStudentVideoEffect(studentQuestId),
						A2($author$project$Effect$MarkStudentVideoUploadedEffect, studentQuestId, loginInfo)
					])));
	});
var $author$project$LoggedIn$update = F4(
	function (student, loginInfo, msg, model) {
		switch (msg.$) {
			case 3:
				var studentQuestId = msg.a;
				var subjectArea = msg.b;
				var isAssignment = msg.c;
				return A4($author$project$LoggedIn$selectQuestToWorkOn, studentQuestId, subjectArea, isAssignment, model);
			case 4:
				var mood = msg.a;
				return _Utils_Tuple2(
					model,
					A2($author$project$Effect$RecordStudentMoodEffect, loginInfo, mood));
			case 5:
				if (!msg.a.$) {
					var updatedStudent = _Utils_update(
						student,
						{iz: true});
					return $author$project$Effect$convertToEffect(
						A3(
							$author$project$Helpers$CommandHelper$closeSessionModal,
							updatedStudent,
							loginInfo,
							_Utils_Tuple2(
								A3($author$project$Model$updateLoggedInUser, updatedStudent, loginInfo, model),
								$elm$core$Platform$Cmd$none)));
				} else {
					var httpError = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, httpError, 'recording StudentMood data'));
				}
			case 2:
				var videoFeedbackMsg = msg.a;
				var _v1 = model.hM;
				if (!_v1.$) {
					if (_v1.a.$ === 6) {
						var videoFeedbackModel = _v1.a.a;
						return A5($author$project$LoggedIn$handleVideoFeedbackMsg, student, loginInfo, model, videoFeedbackMsg, videoFeedbackModel);
					} else {
						return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
					}
				} else {
					return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				}
			case 0:
				var npsSurveyMsg = msg.a;
				var _v2 = model.hM;
				if (!_v2.$) {
					if (_v2.a.$ === 9) {
						var npsSurveyModel = _v2.a.a;
						return $author$project$Effect$convertToEffect(
							A5($author$project$LoggedIn$handleNpsSurveyMsg, student, loginInfo, model, npsSurveyMsg, npsSurveyModel));
					} else {
						return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
					}
				} else {
					return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				}
			case 1:
				var characterSelectionMsg = msg.a;
				var _v3 = model.hM;
				if (!_v3.$) {
					if (_v3.a.$ === 8) {
						var characterSelectionModel = _v3.a.a;
						return A5($author$project$LoggedIn$handleCharacterSelectionMsg, student, loginInfo, model, characterSelectionMsg, characterSelectionModel);
					} else {
						return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
					}
				} else {
					return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				}
			case 6:
				var studentActivityIdForNotification = msg.a;
				return $author$project$Effect$convertToEffect(
					A2($author$project$Helpers$ReturnNotificationCommand$generate, studentActivityIdForNotification, model));
			case 7:
				var maybeContent = msg.a;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						A2($author$project$Helpers$ReturnNotificationCommand$logSent, model, maybeContent)));
			case 8:
				var maybeContent = msg.a;
				var timeToReturnInMs = msg.b;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						A3($author$project$Helpers$ReturnNotificationCommand$logReturn, model, maybeContent, timeToReturnInMs)));
			case 9:
				var studentActivityId = msg.a;
				var url = msg.b;
				return _Utils_Tuple2(
					A2($author$project$LoggedIn$setHostedVideoLoadingStateToFetchingIfFailed, model, studentActivityId),
					$author$project$Effect$CmdEffect(
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Messages$LoggedInRequiredMsg,
							A3($author$project$Api$MediaServerApi$getVideoUrl, model.f7.dO, studentActivityId, url))));
			case 10:
				switch (msg.b.$) {
					case 3:
						var studentActivityId = msg.a;
						var hostedVideoData = msg.b.a;
						var updatedModel = A3(
							$author$project$Model$updateStudentActivityById,
							studentActivityId,
							function (studentActivity) {
								return _Utils_update(
									studentActivity,
									{
										f_: A2($author$project$Models$Activity$saveHostedVideoData, hostedVideoData, studentActivity.f_)
									});
							},
							model);
						var retryEnqueuedEffect = function () {
							if ((!hostedVideoData.$) && (hostedVideoData.a === 1)) {
								var _v5 = hostedVideoData.a;
								var url = hostedVideoData.b;
								return A2(
									$author$project$Effect$PerformLaterEffect,
									10000,
									$author$project$Messages$LoggedInRequiredMsg(
										A2($author$project$LoggedIn$Messages$RetrieveMediaServerUrlForStudentActivity, studentActivityId, url)));
							} else {
								return $author$project$Effect$NoEffect;
							}
						}();
						return _Utils_Tuple2(updatedModel, retryEnqueuedEffect);
					case 2:
						var studentActivityId = msg.a;
						var httpError = msg.b.a;
						var _v6 = $author$project$Effect$convertToEffect(
							A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, httpError, 'loading V2 Video URL'));
						var updatedModel = _v6.a;
						var loggingEffect = _v6.b;
						var _v7 = function () {
							var _v8 = updatedModel.ga.bE;
							if (!_v8) {
								return _Utils_Tuple2(
									_Utils_update(
										updatedModel,
										{
											ga: A2($author$project$HealthChecks$updateHostedVideoStatus, 1, model.ga)
										}),
									$author$project$Effect$CmdEffect(
										A2(
											$elm$core$Platform$Cmd$map,
											$author$project$Messages$LoggedInRequiredMsg,
											A2($author$project$Api$MediaServerApi$performHealthCheck, false, model.f7.bK))));
							} else {
								return _Utils_Tuple2(updatedModel, $author$project$Effect$NoEffect);
							}
						}();
						var modelWithHealthCheck = _v7.a;
						var healthCheckEffect = _v7.b;
						return _Utils_Tuple2(
							A3(
								$author$project$Model$updateStudentActivityById,
								studentActivityId,
								function (studentActivity) {
									return _Utils_update(
										studentActivity,
										{
											f_: A2($author$project$Models$Activity$updateHostedVideoUrlLoadingState, studentActivity.f_, 2)
										});
								},
								modelWithHealthCheck),
							$author$project$Effect$batch(
								_List_fromArray(
									[loggingEffect, healthCheckEffect])));
					default:
						return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				}
			case 11:
				if (!msg.a.$) {
					var _v9 = model.ga.bE;
					switch (_v9) {
						case 1:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										ga: A2($author$project$HealthChecks$updateHostedVideoStatus, 2, model.ga)
									}),
								$author$project$Effect$CmdEffect(
									A2(
										$elm$core$Platform$Cmd$map,
										$author$project$Messages$LoggedInRequiredMsg,
										A2($author$project$Api$MediaServerApi$performHealthCheck, true, model.f7.bK))));
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										ga: A2($author$project$HealthChecks$updateHostedVideoStatus, 4, model.ga)
									}),
								$author$project$Effect$CmdEffect(
									A2(
										$author$project$Logger$log,
										model,
										{
											gL: _List_Nil,
											gP: _List_fromArray(
												[2]),
											hK: 'Media Access Health Check Result',
											jp: _List_fromArray(
												[
													_Utils_Tuple2(
													'mediaCheckResult',
													$elm$json$Json$Encode$string('SucceededWithYoutubeParam'))
												])
										})));
						default:
							return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
					}
				} else {
					var _v10 = model.ga.bE;
					switch (_v10) {
						case 1:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										ga: A2($author$project$HealthChecks$updateHostedVideoStatus, 3, model.ga)
									}),
								$author$project$Effect$CmdEffect(
									A2(
										$author$project$Logger$log,
										model,
										{
											gL: _List_Nil,
											gP: _List_fromArray(
												[2]),
											hK: 'Media Access Health Check Result',
											jp: _List_fromArray(
												[
													_Utils_Tuple2(
													'mediaCheckResult',
													$elm$json$Json$Encode$string('FailedWithNoParams'))
												])
										})));
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										ga: A2($author$project$HealthChecks$updateHostedVideoStatus, 5, model.ga)
									}),
								$author$project$Effect$CmdEffect(
									A2(
										$author$project$Logger$log,
										model,
										{
											gL: _List_Nil,
											gP: _List_fromArray(
												[2]),
											hK: 'Media Access Health Check Result',
											jp: _List_fromArray(
												[
													_Utils_Tuple2(
													'mediaCheckResult',
													$elm$json$Json$Encode$string('FailedWithYoutubeParam'))
												])
										})));
						default:
							return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
					}
				}
			case 12:
				if (!msg.a.$) {
					var availableQuestsData = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								go: $krisajenkins$remotedata$RemoteData$succeed(availableQuestsData)
							}),
						$author$project$Effect$NoEffect);
				} else {
					var httpError = msg.a.a;
					var count = function () {
						var _v12 = model.bW;
						if (!_v12.$) {
							var cnt = _v12.a;
							return cnt;
						} else {
							return 0;
						}
					}();
					var _v11 = A3($author$project$Helpers$CommandHelper$withErrorHandling, model, httpError, 'loading student data');
					var updatedModel = _v11.a;
					return (count > 5) ? _Utils_Tuple2(
						_Utils_update(
							updatedModel,
							{
								go: $krisajenkins$remotedata$RemoteData$Failure(httpError),
								bW: $elm$core$Maybe$Nothing
							}),
						$author$project$Effect$CmdEffect(
							A2(
								$author$project$Logger$log,
								model,
								{
									gL: _List_Nil,
									gP: _List_fromArray(
										[0, 1]),
									hK: 'RetryLoadStudentQuest failed after ' + ($elm$core$String$fromInt(count) + ' times'),
									jp: _List_Nil
								}))) : ((count > 1) ? _Utils_Tuple2(
						_Utils_update(
							updatedModel,
							{
								bW: $elm$core$Maybe$Just(count + 1)
							}),
						$author$project$Effect$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Effect$PerformLaterEffect,
									5000,
									$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$RetryLoadStudentQuest)),
									$author$project$Effect$CmdEffect(
									A2(
										$author$project$Logger$log,
										model,
										{
											gL: _List_Nil,
											gP: _List_fromArray(
												[0, 1]),
											hK: 'RetryLoadStudentQuest ' + ($elm$core$String$fromInt(count) + ' times'),
											jp: _List_Nil
										}))
								]))) : _Utils_Tuple2(
						_Utils_update(
							updatedModel,
							{
								bW: $elm$core$Maybe$Just(count + 1)
							}),
						A2(
							$author$project$Effect$PerformLaterEffect,
							3000,
							$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$RetryLoadStudentQuest))));
				}
			case 13:
				if (!msg.a.$) {
					var activeQuestData = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A4($author$project$LoggedIn$handleLoadStudentQuestResponseSuccess, activeQuestData, student, loginInfo, model));
				} else {
					var httpError = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A4($author$project$LoggedIn$handleLoadStudentQuestResponseError, httpError, student, loginInfo, model));
				}
			case 15:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$CmdEffect(
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Messages$LoggedInRequiredMsg,
							A2($author$project$Api$StudentQuestApi$fetchAllAvailable, loginInfo, model.f7))));
			case 14:
				return _Utils_eq(model.go, $krisajenkins$remotedata$RemoteData$Loading) ? $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A3(
									$author$project$Logger$logRedshift,
									model,
									'Student Stuck Waiting for Available Quests',
									_List_fromArray(
										[
											_Utils_Tuple2(
											'platform',
											$elm$json$Json$Encode$string(
												$author$project$AppConfiguration$platformToString(model.f7.ii)))
										]))
								])))) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 16:
				return _Utils_eq(model.fZ, $krisajenkins$remotedata$RemoteData$Loading) ? $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A3(
									$author$project$Logger$logRedshift,
									model,
									'Student Stuck Waiting for Student Quest',
									_List_fromArray(
										[
											_Utils_Tuple2(
											'platform',
											$elm$json$Json$Encode$string(
												$author$project$AppConfiguration$platformToString(model.f7.ii)))
										]))
								])))) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 17:
				var interactionSound = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Effect$batch(
						_List_fromArray(
							[
								$author$project$Effect$PlayInteractionSoundEffect(interactionSound),
								$author$project$Effect$CmdEffect(
								$author$project$Ports$highfive(0))
							])));
			case 18:
				var studentQuestId = msg.a;
				var questEndingBehavior = msg.b;
				var interactionSound = msg.c;
				var _v13 = A4($author$project$LoggedIn$completeQuestIfNeeded, loginInfo, model, questEndingBehavior, studentQuestId);
				var updatedModel = _v13.a;
				var completeQuestEffect = _v13.b;
				return _Utils_Tuple2(
					updatedModel,
					$author$project$Effect$batch(
						_List_fromArray(
							[
								$author$project$Effect$PlayInteractionSoundEffect(interactionSound),
								completeQuestEffect
							])));
			case 19:
				var studentQuestId = msg.a;
				return A3($author$project$LoggedIn$handleClickQuestHomeNextButton, loginInfo, studentQuestId, model);
			case 20:
				var studentQuestId = msg.a;
				var questEndingBehavior = msg.b;
				return A4($author$project$LoggedIn$completeQuestIfNeeded, loginInfo, model, questEndingBehavior, studentQuestId);
			case 21:
				if (!msg.b.$) {
					var questEndingBehavior = msg.a;
					var nextStudentQuestId = msg.b.a;
					return $author$project$Effect$convertToEffect(
						_Utils_Tuple2(
							_Utils_update(
								model,
								{go: $krisajenkins$remotedata$RemoteData$NotAsked}),
							A3(
								$author$project$Helpers$NavigationHelper$updateUrl,
								model.hR,
								model.f7,
								$author$project$Routes$routeToUrlString(
									$author$project$AppFlow$shouldGoToNextQuest(questEndingBehavior) ? A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $author$project$Routes$homeRoute, $author$project$Routes$questHomeRoute, nextStudentQuestId) : $author$project$Routes$homeRoute))));
				} else {
					var httpError = msg.b.a;
					return $author$project$Effect$convertToEffect(
						A3($author$project$Helpers$CommandHelper$withErrorHandling, model, httpError, 'completing student quest'));
				}
			case 24:
				var studentActivityId = msg.a;
				return A4($author$project$LoggedIn$launchStudentActivity, $author$project$EventPublisher$Event$StudentClickstreamLaunchWebappActivity, studentActivityId, loginInfo, model);
			case 25:
				var studentActivityId = msg.a;
				return A4($author$project$LoggedIn$launchStudentActivity, $author$project$EventPublisher$Event$StudentClickstreamLaunchWebappActivity, studentActivityId, loginInfo, model);
			case 26:
				var studentActivityId = msg.a;
				var contentCompletedAt = msg.b;
				return _Utils_Tuple2(
					model,
					$author$project$Effect$GetCurrentTimeEffect(
						A2(
							$elm$core$Basics$composeR,
							A2($author$project$LoggedIn$Messages$ReplayStudentActivityOnExternalActivityLaunchTimestamped, studentActivityId, contentCompletedAt),
							$author$project$Messages$LoggedInRequiredMsg)));
			case 27:
				var studentActivityId = msg.a;
				return A4($author$project$LoggedIn$launchStudentActivity, $author$project$EventPublisher$Event$StudentClickstreamRelaunchWebappActivity, studentActivityId, loginInfo, model);
			case 28:
				return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 44:
				var studentActivityId = msg.a;
				return _Utils_Tuple2(
					A3($author$project$Model$updateStudentActivityById, studentActivityId, $author$project$Models$StudentActivity$setInitialIframeActivityDisplay, model),
					$author$project$Effect$ListenForWebActivityWindowMessageEffect(studentActivityId));
			case 48:
				var studentActivityId = msg.a;
				var params = msg.b;
				return _Utils_Tuple2(
					model,
					$author$project$Effect$BatchedEffect(
						_List_fromArray(
							[
								$author$project$Effect$PerformNowEffect(
								$author$project$Messages$OpenInBrowser(params)),
								$author$project$Effect$ListenForWebActivityWindowMessageEffect(studentActivityId)
							])));
			case 45:
				var studentActivityId = msg.a;
				var newVisibility = msg.b;
				var webAppActivityUrl = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'',
					$author$project$Models$Urls$toString,
					A2(
						$elm$core$Maybe$andThen,
						$author$project$Models$Activity$webAppUrl,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.f_;
							},
							A2($author$project$Model$studentActivityForId, model, studentActivityId))));
				var isIframeLoaded = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					false,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.f_;
						},
						function ($) {
							return $.dl;
						}),
					A2($author$project$Model$studentActivityForId, model, studentActivityId));
				return _Utils_Tuple2(
					A3(
						$author$project$Model$updateStudentActivityById,
						studentActivityId,
						$author$project$Models$StudentActivity$setIframeActivityDisplayFooterVisibility(newVisibility),
						model),
					$author$project$Effect$GetCurrentTimeEffect(
						A2(
							$elm$core$Basics$composeR,
							$author$project$LoggedIn$Messages$PublishEventTimestamped(
								$author$project$EventPublisher$Event$StudentClickstreamToggleInstructionBar(
									{er: isIframeLoaded, fs: webAppActivityUrl})),
							$author$project$Messages$LoggedInRequiredMsg)));
			case 29:
				if (!msg.a.$) {
					var updatedStudentActivity = msg.a.a;
					return $krisajenkins$remotedata$RemoteData$isSuccess(model.fZ) ? _Utils_Tuple2(
						A3(
							$author$project$Model$updateStudentActivityById,
							updatedStudentActivity.hj,
							function (currentStudentActivity) {
								return _Utils_update(
									updatedStudentActivity,
									{f_: currentStudentActivity.f_, fL: currentStudentActivity.fL});
							},
							model),
						$author$project$Effect$NoEffect) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				} else {
					var httpError = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A3($author$project$Helpers$CommandHelper$withErrorHandling, model, httpError, 'updating student activity to active with retries.'));
				}
			case 30:
				var studentActivityId = msg.a;
				return $author$project$Effect$convertToEffect(
					A4($author$project$LoggedIn$handleStudentActivityTimerComplete, studentActivityId, student, loginInfo, model));
			case 31:
				var studentActivityId = msg.a;
				return $author$project$Effect$convertToEffect(
					A4($author$project$LoggedIn$handleVideoActivityPlaybackEnded, studentActivityId, student, loginInfo, model));
			case 32:
				var studentActivityId = msg.a;
				return $author$project$Effect$convertToEffect(
					A4($author$project$LoggedIn$handleVideoActivityPlaybackEnded, studentActivityId, student, loginInfo, model));
			case 33:
				if (!msg.a.$) {
					return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				} else {
					var httpError = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A3($author$project$Helpers$CommandHelper$withErrorHandling, model, httpError, 'updating watched video in activity'));
				}
			case 100:
				var studentActivityId = msg.b;
				return _Utils_Tuple2(
					model,
					$author$project$Effect$GetCurrentTimeEffect(
						A2(
							$elm$core$Basics$composeR,
							A3($author$project$LoggedIn$Messages$CompleteStudentActivityTimestamped, studentActivityId, 3, false),
							$author$project$Messages$LoggedInRequiredMsg)));
			case 101:
				var componentType = msg.a;
				var studentActivityId = msg.b;
				var message = msg.c;
				var errorData = msg.d;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						A2($author$project$Model$markStudentActivityErrored, studentActivityId, model),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Logger$log,
									model,
									{
										gL: _List_fromArray(
											[
												_Utils_Tuple2(
												'student_activity_id',
												$author$project$Models$Ids$encoder(studentActivityId)),
												_Utils_Tuple2('errorData', errorData)
											]),
										gP: _List_fromArray(
											[0, 1]),
										hK: 'ComponentErrored:' + ($author$project$Models$Activity$componentTypeToString(componentType) + (': ' + message)),
										jp: _List_Nil
									}),
									$author$project$Ports$componentRemove(
									A2(
										$elm$json$Json$Encode$encode,
										0,
										$elm$json$Json$Encode$object(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'componentType',
													$elm$json$Json$Encode$string(
														$author$project$Models$Activity$componentTypeToString(componentType)))
												]))))
								]))));
			case 34:
				if (!msg.b.$) {
					var studentActivityId = msg.a;
					var updatedActiveQuest = msg.b.a;
					return $author$project$Effect$convertToEffect(
						A5(
							$author$project$LoggedIn$handleQuestUpdateResponse,
							$author$project$Routes$QuizRoute(
								A2($author$project$Routes$QuizRoutes$Outro, updatedActiveQuest.a_, studentActivityId)),
							updatedActiveQuest,
							student,
							loginInfo,
							model));
				} else {
					var httpError = msg.b.a;
					return $author$project$Effect$convertToEffect(
						A3($author$project$Helpers$CommandHelper$withErrorHandling, model, httpError, 'marking postquiz failed'));
				}
			case 36:
				if (!msg.b.$) {
					var updatedActiveQuest = msg.b.a;
					return $author$project$Effect$convertToEffect(
						A5(
							$author$project$LoggedIn$handleQuestUpdateResponse,
							A3(
								$author$project$AppFlow$Navigation$routeForNextActivity,
								A2($author$project$Helpers$VideoRecordingPermission$videoRecordingPermitted, model.f7.ii, student.iq),
								updatedActiveQuest,
								$author$project$AppFlow$NextIncompleteActivity),
							updatedActiveQuest,
							student,
							loginInfo,
							model));
				} else {
					var httpError = msg.b.a;
					return $author$project$Effect$convertToEffect(
						A3($author$project$Helpers$CommandHelper$withErrorHandling, model, httpError, 'processing placement quiz'));
				}
			case 35:
				if (!msg.b.$) {
					var studentActivityId = msg.a;
					var updatedActiveQuest = msg.b.a;
					return $author$project$Effect$convertToEffect(
						A5(
							$author$project$LoggedIn$handleQuestUpdateResponse,
							$author$project$Routes$QuizRoute(
								A2($author$project$Routes$QuizRoutes$Outro, updatedActiveQuest.a_, studentActivityId)),
							updatedActiveQuest,
							student,
							loginInfo,
							model));
				} else {
					var httpError = msg.b.a;
					return $author$project$Effect$convertToEffect(
						A3($author$project$Helpers$CommandHelper$withErrorHandling, model, httpError, 'marking prequiz mastery failed'));
				}
			case 37:
				var studentActivityId = msg.a;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						A3($author$project$Helpers$CommandHelper$handleActivityError, loginInfo, model, studentActivityId)));
			case 38:
				var studentActivityId = msg.a;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Messages$LoggedInRequiredMsg,
							A3($author$project$Api$StudentActivityApi$skip, studentActivityId, loginInfo, model.f7))));
			case 39:
				var studentActivityId = msg.a;
				var rating = msg.b;
				var showKeepWorkingButton = msg.c;
				return _Utils_Tuple2(
					model,
					$author$project$Effect$GetCurrentTimeEffect(
						A2(
							$elm$core$Basics$composeR,
							A3($author$project$LoggedIn$Messages$CompleteStudentActivityTimestamped, studentActivityId, rating, showKeepWorkingButton),
							$author$project$Messages$LoggedInRequiredMsg)));
			case 40:
				var studentActivityId = msg.a;
				var rating = msg.b;
				var showKeepWorkingButton = msg.c;
				var currentTime = msg.d;
				return A7($author$project$LoggedIn$completeStudentActivity, studentActivityId, rating, showKeepWorkingButton, currentTime, student, loginInfo, model);
			case 41:
				if (!msg.a.$) {
					var updatedStudentActivity = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A4($author$project$LoggedIn$handleCompleteStudentActivityResponse, updatedStudentActivity, student, loginInfo, model));
				} else {
					var httpError = msg.a.a;
					var _v14 = function () {
						var _v15 = $author$project$Model$studentActivityForCurrentRoute(model);
						if (!_v15.$) {
							var studentActivity = _v15.a;
							return $author$project$Effect$convertToEffect(
								A4($author$project$LoggedIn$handleCompleteStudentActivityResponse, studentActivity, student, loginInfo, model));
						} else {
							return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
						}
					}();
					var updatedModel = _v14.a;
					var updateEffect = _v14.b;
					return A2(
						$elm$core$Tuple$mapSecond,
						$author$project$Effect$add(updateEffect),
						$author$project$Effect$convertToEffect(
							A3($author$project$Helpers$CommandHelper$withErrorHandling, updatedModel, httpError, 'completing student activity')));
				}
			case 22:
				var studentActivityId = msg.a;
				var _v16 = model.fZ;
				if (_v16.$ === 3) {
					var loadedAt = _v16.a.eq;
					return _Utils_Tuple2(
						model,
						$author$project$Effect$GetCurrentTimeEffect(
							A2(
								$elm$core$Basics$composeR,
								A2($author$project$LoggedIn$Messages$HostedVideoUrlUnableToLoadTimestamped, studentActivityId, loadedAt),
								$author$project$Messages$LoggedInRequiredMsg)));
				} else {
					return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				}
			case 23:
				var studentActivityId = msg.a;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						A2($author$project$Model$markStudentActivityErrored, studentActivityId, model),
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Messages$LoggedInRequiredMsg,
							A3($author$project$Api$StudentActivityApi$skip, studentActivityId, loginInfo, model.f7))));
			case 42:
				return $author$project$Effect$convertToEffect(
					A3(
						$author$project$Helpers$CommandHelper$closeSessionModal,
						student,
						loginInfo,
						_Utils_Tuple2(
							_Utils_update(
								model,
								{
									jF: A2(
										$author$project$Models$User$LoggedIn,
										_Utils_update(
											student,
											{hT: false}),
										loginInfo)
								}),
							$author$project$Ports$requestNotificationPermissions(0))));
			case 43:
				var studentActivityId = msg.a;
				var webAppTimerEffect = function (_v21) {
					var activity = _v21.f_;
					return $author$project$Models$Activity$isWebApp(activity) ? $author$project$Effect$GetCurrentTimeEffect($author$project$Messages$ResetWebAppNotificationTimers) : $author$project$Effect$NoEffect;
				};
				var webAppActivityUrl = function (_v20) {
					var activity = _v20.f_;
					var _v19 = $author$project$Models$Activity$webAppUrl(activity);
					if (!_v19.$) {
						var url = _v19.a;
						return $author$project$Models$Urls$toString(url);
					} else {
						return '';
					}
				};
				var addTimerCommands = function (_v18) {
					var updatedModel = _v18.a;
					var effects = _v18.b;
					var _v17 = A2($author$project$Model$studentActivityForId, updatedModel, studentActivityId);
					if (!_v17.$) {
						var studentActivity = _v17.a;
						return _Utils_Tuple2(
							updatedModel,
							A2(
								$author$project$Effect$add,
								effects,
								$author$project$Effect$batch(
									_List_fromArray(
										[
											A2($author$project$Helpers$ActivityEffectHelper$timedActivityEffectsForRefresh, studentActivity, model),
											webAppTimerEffect(studentActivity),
											$author$project$Effect$GetCurrentTimeEffect(
											A2(
												$elm$core$Basics$composeR,
												$author$project$LoggedIn$Messages$PublishEventTimestamped(
													$author$project$EventPublisher$Event$StudentClickstreamClickRatingsModal(
														{
															hu: true,
															er: studentActivity.f_.dl,
															iZ: $author$project$Models$StudentActivity$KeepWorking,
															fs: webAppActivityUrl(studentActivity)
														})),
												$author$project$Messages$LoggedInRequiredMsg))
										]))));
					} else {
						return _Utils_Tuple2(updatedModel, effects);
					}
				};
				return addTimerCommands(
					$author$project$Effect$convertToEffect(
						A2(
							$author$project$Helpers$CommandHelper$updateWithModalState,
							$author$project$Models$Modal$Closed,
							_Utils_Tuple2(model, $elm$core$Platform$Cmd$none))));
			case 46:
				var ebookActivityMsg = msg.a;
				return A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					_Utils_Tuple2(model, $author$project$Effect$NoEffect),
					function (sa) {
						var _v22 = sa.fL;
						if (_v22.$ === 3) {
							var ebookModel = _v22.a;
							return A5($author$project$LoggedIn$handleEbookMsg, ebookActivityMsg, ebookModel, sa, loginInfo, model);
						} else {
							return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
						}
					},
					$author$project$Model$studentActivityForCurrentRoute(model));
			case 47:
				var choiceTextActivityMsg = msg.a;
				return A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					_Utils_Tuple2(model, $author$project$Effect$NoEffect),
					function (sa) {
						var _v23 = sa.fL;
						if (_v23.$ === 4) {
							var choiceTextModel = _v23.a;
							return A5($author$project$LoggedIn$handleChoiceTextMsg, choiceTextActivityMsg, choiceTextModel, sa, loginInfo, model);
						} else {
							return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
						}
					},
					$author$project$Model$studentActivityForCurrentRoute(model));
			case 49:
				switch (msg.a.$) {
					case 0:
						var _v24 = msg.a;
						var studentActivityId = _v24.a;
						var constructGameMessageData = _v24.b;
						return $author$project$Models$WindowPostedMessage$constructGameMissingRequirements(constructGameMessageData) ? A4($author$project$LoggedIn$handleConstructGameMessageMissingRequirements, $author$project$Models$StudentActivity$setWebAppIframeError, $author$project$Effect$OpenNewBrowserTabEffect, studentActivityId, model) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
					case 1:
						var _v25 = msg.a;
						var studentActivityId = _v25.a;
						var constructGameMessageData = _v25.b;
						return $author$project$Models$WindowPostedMessage$constructGameMissingRequirements(constructGameMessageData) ? A4($author$project$LoggedIn$handleConstructGameMessageMissingRequirements, $author$project$Models$StudentActivity$setProgressErrored, $author$project$Effect$CloseExternalWindowEffect, studentActivityId, model) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
					default:
						var _v26 = msg.a;
						return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				}
			case 50:
				var appTimer = msg.a;
				var currentTime = msg.b;
				var currentTimeInMs = $elm$time$Time$posixToMillis(currentTime);
				var updatedAppTimer = _Utils_update(
					appTimer,
					{cf: currentTimeInMs, bq: currentTimeInMs});
				var deactivatedAppTimer = _Utils_update(
					updatedAppTimer,
					{ar: false});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							$7: $elm$core$Maybe$Just(updatedAppTimer)
						}),
					$author$project$Effect$BatchedEffect(
						_List_fromArray(
							[
								$author$project$Effect$CmdEffect(
								$author$project$Ports$storeAppTimer(
									$author$project$StoredAppTimer$storedAppTimerEncoder(
										$elm$core$Maybe$Just(deactivatedAppTimer)))),
								$author$project$AppTimer$callback(updatedAppTimer)
							])));
			case 51:
				var appTimer = msg.a;
				var delay = msg.b;
				var deactivatedAppTimer = _Utils_update(
					appTimer,
					{ar: false});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							$7: $elm$core$Maybe$Just(appTimer)
						}),
					$author$project$Effect$CmdEffect(
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Ports$storeAppTimer(
									$author$project$StoredAppTimer$storedAppTimerEncoder(
										$elm$core$Maybe$Just(deactivatedAppTimer))),
									A2(
									$author$project$Helpers$Task$performWithDelayAndTimestamp,
									delay,
									A2(
										$elm$core$Basics$composeR,
										$author$project$LoggedIn$Messages$AppTimerCallbackWithCurrentTime(appTimer),
										$author$project$Messages$LoggedInRequiredMsg))
								]))));
			case 52:
				var appTimer = msg.a;
				var currentTime = msg.b;
				var currentTimeInMs = $elm$time$Time$posixToMillis(currentTime);
				var updatedAppTimer = _Utils_update(
					appTimer,
					{cf: currentTimeInMs});
				return A2($author$project$AppTimer$isValidAppTimer, appTimer, model.$7) ? _Utils_Tuple2(
					model,
					$author$project$AppTimer$callback(updatedAppTimer)) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 53:
				var studentActivityId = msg.a;
				return _Utils_Tuple2(
					A3($author$project$Model$updateStudentActivityById, studentActivityId, $author$project$LoggedIn$handleSetProgressToCanComplete, model),
					$author$project$Effect$NoEffect);
			case 54:
				var studentActivityId = msg.a;
				return _Utils_Tuple2(
					A3($author$project$Model$updateStudentActivityById, studentActivityId, $author$project$Models$StudentActivity$setIframeActivityDisplayFlashAlert, model),
					$author$project$Effect$NoEffect);
			case 55:
				if (!msg.a.$) {
					var videoUploadData = msg.a.a;
					var videoRecordingCommand = A3(
						$krisajenkins$remotedata$RemoteData$unwrap,
						$author$project$Effect$NoEffect,
						function (activeQuest) {
							return $author$project$Effect$CmdEffect(
								$author$project$Ports$startVideoActivity(
									_Utils_Tuple2(
										$author$project$Models$VideoUploadData$encodeForWeb(videoUploadData),
										$author$project$Models$Ids$toString(activeQuest.a_))));
						},
						model.fZ);
					return A2($author$project$Helpers$VideoRecordingPermission$videoRecordingPermitted, model.f7.ii, student.iq) ? _Utils_Tuple2(model, videoRecordingCommand) : _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d: $author$project$VideoRecording$RecordingProcess$cameraNotReadyStateFor(
									$author$project$LoggedIn$getVideoRecordingCounts(model))
							}),
						$author$project$Effect$NoEffect);
				} else {
					var httpError = msg.a.a;
					var _v27 = A3($author$project$Helpers$CommandHelper$withErrorHandling, model, httpError, 'getting video upload URL');
					var updatedModel = _v27.a;
					var errorCommand = _v27.b;
					return $author$project$Effect$convertToEffect(
						_Utils_Tuple2(
							_Utils_update(
								updatedModel,
								{
									d: $author$project$VideoRecording$RecordingProcess$uploadErrorHack('Unable to fetch video upload URL')
								}),
							errorCommand));
				}
			case 56:
				var updateStatus = msg.a;
				var previousRecordingStatus = model.d;
				var newRecordingStatus = $author$project$VideoRecording$RecordingProcess$isRecorded(model.d) ? model.d : A2(
					$author$project$VideoRecording$RecordingProcess$recordingStatusFromStatusUpdate,
					$author$project$LoggedIn$getVideoRecordingCounts(model),
					updateStatus);
				return ($author$project$Routes$isVideoRecording(model.eY) && (!$author$project$VideoRecording$RecordingProcess$isErrored(newRecordingStatus))) ? $author$project$Effect$convertToEffect(
					A2(
						$author$project$VideoRecording$Helpers$autoUpload,
						previousRecordingStatus,
						A2(
							$author$project$VideoRecording$Helpers$autoStopRecording,
							newRecordingStatus,
							_Utils_Tuple2(
								_Utils_update(
									model,
									{d: newRecordingStatus}),
								$elm$core$Platform$Cmd$none)))) : _Utils_Tuple2(
					_Utils_update(
						model,
						{d: newRecordingStatus}),
					$author$project$Effect$NoEffect);
			case 66:
				var hasFailedPreviousCheck = msg.a;
				return ($author$project$Routes$isVideoRecording(model.eY) && $author$project$VideoRecording$RecordingProcess$isStarting(model.d)) ? (hasFailedPreviousCheck ? _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: A2(
								$author$project$VideoRecording$RecordingProcess$Errored,
								'Unable to start camera after retry',
								$author$project$VideoRecording$RecordingProcess$recordingReadyStatus(model.d))
						}),
					$author$project$Effect$NoEffect) : _Utils_Tuple2(
					model,
					A2($author$project$Effect$StartVideoRecordingEffect, true, loginInfo))) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 57:
				var decodeError = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: A2(
								$author$project$VideoRecording$RecordingProcess$Errored,
								$elm$json$Json$Decode$errorToString(decodeError),
								$author$project$VideoRecording$RecordingProcess$recordingReadyStatus(model.d))
						}),
					$author$project$Effect$NoEffect);
			case 59:
				if (!msg.a) {
					var _v28 = msg.a;
					return $author$project$Effect$convertToEffect(
						_Utils_Tuple2(
							A2($author$project$LoggedIn$updateVideoRecordingCounts, model, $author$project$Models$LocalStorage$Helpers$incrementVideoRecordingAttemptCount),
							$author$project$Ports$videoRecordingAction(
								$author$project$VideoRecording$RecordingAction$toString(0))));
				} else {
					var action = msg.a;
					return $author$project$Effect$convertToEffect(
						_Utils_Tuple2(
							model,
							$author$project$Ports$videoRecordingAction(
								$author$project$VideoRecording$RecordingAction$toString(action))));
				}
			case 58:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$batch(
						_List_fromArray(
							[
								$author$project$Effect$CmdEffect(
								$author$project$Ports$videoRecordingAction(
									$author$project$VideoRecording$RecordingAction$toString(0))),
								$author$project$Effect$GetCurrentTimeEffect(
								A2(
									$elm$core$Basics$composeL,
									$author$project$Messages$LoggedInRequiredMsg,
									$author$project$LoggedIn$Messages$PublishEventTimestamped($author$project$EventPublisher$Event$StudentClickstreamStartVideoRecording)))
							])));
			case 60:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: $author$project$VideoRecording$RecordingProcess$retryErrorToReady(model.d)
						}),
					$author$project$Effect$NoEffect);
			case 61:
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: A2($author$project$VideoRecording$RecordingProcess$updatePlayback, state, model.d)
						}),
					$author$project$Effect$NoEffect);
			case 62:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: $author$project$VideoRecording$RecordingProcess$playbackIsReady(model.d)
						}),
					A2(
						$author$project$Effect$PerformLaterEffect,
						5000,
						$author$project$Messages$LoggedInRequiredMsg($author$project$LoggedIn$Messages$VideoRecordingPlaybackSkip)));
			case 63:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d: $author$project$VideoRecording$RecordingProcess$skipPlaybackIfNotPlayed(model.d)
						}),
					$author$project$Effect$NoEffect);
			case 64:
				var _v29 = _Utils_Tuple2(model.fZ, model.d);
				if ((_v29.a.$ === 3) && (_v29.b.$ === 5)) {
					var activeQuest = _v29.a.a;
					return $author$project$Effect$convertToEffect(
						_Utils_Tuple2(
							_Utils_update(
								model,
								{
									d: A2($author$project$VideoRecording$RecordingProcess$updateUploadStatus, $author$project$VideoRecording$RecordingProcess$FileUploadCompleted, model.d)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$elm$core$Platform$Cmd$map,
										$author$project$Messages$LoggedInRequiredMsg,
										A4($author$project$Api$StudentVideoUploadApi$markUploaded, activeQuest.a_, loginInfo, model.f7, $author$project$LoggedIn$Messages$StudentVideoUploadedResponse)),
										$author$project$Ports$removeStoredStudentVideo(
										$author$project$Models$Ids$toString(activeQuest.a_))
									]))));
				} else {
					return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				}
			case 65:
				var errorString = msg.a;
				var _v30 = A4($author$project$ErrorHandling$handleIncomingPortError, student, model, errorString, 'uploading video to S3');
				var updatedModel = _v30.a;
				var errorCommand = _v30.b;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						_Utils_update(
							updatedModel,
							{
								d: A2(
									$author$project$VideoRecording$RecordingProcess$updateUploadStatus,
									$author$project$VideoRecording$RecordingProcess$FileUploadFailed(errorString),
									model.d)
							}),
						errorCommand));
			case 67:
				var studentQuestId = msg.a;
				var _v31 = A2(
					$author$project$Helpers$CommandHelper$updateWithModalState,
					$author$project$Models$Modal$Closed,
					_Utils_Tuple2(
						model,
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Messages$LoggedInRequiredMsg,
							A4($author$project$Api$StudentVideoUploadApi$discardRecording, studentQuestId, loginInfo, model.f7, $author$project$LoggedIn$Messages$DiscardDeletedStudentVideoResponse))));
				var updatedModalModel = _v31.a;
				var apiCmd = _v31.b;
				var updatedModalAndRecordingStatusModel = _Utils_update(
					updatedModalModel,
					{d: $author$project$VideoRecording$RecordingProcess$NotStarted});
				return _Utils_Tuple2(
					A2($author$project$LoggedIn$updateVideoRecordingCounts, updatedModalAndRecordingStatusModel, $author$project$Models$LocalStorage$Helpers$incrementVideoRecordingDeletedCount),
					$author$project$Effect$batch(
						_List_fromArray(
							[
								$author$project$Effect$CmdEffect(apiCmd),
								A2($author$project$Effect$StartVideoRecordingEffect, false, loginInfo)
							])));
			case 68:
				if (!msg.a.$) {
					var updatedStudentVideo = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A4($author$project$LoggedIn$handleStudentVideoResponse, updatedStudentVideo, student, loginInfo, model));
				} else {
					var error = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, error, 'marking video recording complete'));
				}
			case 69:
				return $author$project$Effect$convertToEffect(
					A3($author$project$LoggedIn$handleIosCompleteStudentVideo, student, loginInfo, model));
			case 70:
				return A2($author$project$LoggedIn$handleWebCompleteStudentVideo, loginInfo, model);
			case 71:
				var elementId = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Effect$ReplayRecordedStudentVideoEffect(elementId));
			case 72:
				return _Utils_Tuple2(
					A2(
						$author$project$LoggedIn$updateVideoRecordingCounts,
						_Utils_update(
							model,
							{d: $author$project$VideoRecording$RecordingProcess$NotStarted}),
						$author$project$Models$LocalStorage$Helpers$incrementVideoRecordingPermissionRetryCount),
					A2($author$project$Effect$PerformLaterEffect, 100, $author$project$Messages$ReloadPage));
			case 74:
				return _Utils_Tuple2(model, $author$project$Effect$ReloadPageEffect);
			case 73:
				return A2($author$project$LoggedIn$handleWebCompleteStudentVideo, loginInfo, model);
			case 75:
				return A2($author$project$LoggedIn$handleWebErroredStudentVideo, loginInfo, model);
			case 76:
				if (!msg.a.$) {
					var updatedStudentVideo = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A4($author$project$LoggedIn$handleStudentVideoResponse, updatedStudentVideo, student, loginInfo, model));
				} else {
					var error = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, error, 'marking video recording complete'));
				}
			case 77:
				if (!msg.a.$) {
					var updatedStudentVideo = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A4($author$project$LoggedIn$handleStudentVideoResponse, updatedStudentVideo, student, loginInfo, model));
				} else {
					var error = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, error, 'marking video recording errored'));
				}
			case 78:
				if (!msg.a.$) {
					var updatedStudentVideo = msg.a.a;
					return _Utils_Tuple2(
						A3(
							$krisajenkins$remotedata$RemoteData$unwrap,
							model,
							function (activeQuest) {
								return _Utils_update(
									model,
									{
										fZ: $krisajenkins$remotedata$RemoteData$succeed(
											_Utils_update(
												activeQuest,
												{
													jg: $elm$core$Maybe$Just(updatedStudentVideo)
												}))
									});
							},
							model.fZ),
						$author$project$Effect$NoEffect);
				} else {
					var error = msg.a.a;
					var _v32 = A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, error, 'marking video upload complete');
					var updatedModel = _v32.a;
					var errorCommand = _v32.b;
					return $author$project$Effect$convertToEffect(
						_Utils_Tuple2(
							_Utils_update(
								updatedModel,
								{
									d: $author$project$VideoRecording$RecordingProcess$uploadErrorHack('HTTP error in marking complete')
								}),
							errorCommand));
				}
			case 79:
				return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 80:
				var studentQuestIds = msg.a;
				return A3($author$project$Helpers$StoredStudentVideoHelper$startUploading, studentQuestIds, loginInfo, model);
			case 81:
				if (!msg.c.$) {
					var studentQuestId = msg.a;
					var videoUploadData = msg.c.a;
					return A3($author$project$Helpers$StoredStudentVideoHelper$uploadVideo, studentQuestId, videoUploadData, model);
				} else {
					var remainingStudentQuestIds = msg.b;
					var httpError = msg.c.a;
					var _v33 = A3($author$project$Helpers$CommandHelper$withErrorHandling, model, httpError, 'getting video upload URL');
					var errorCommand = _v33.b;
					return A2(
						$elm$core$Tuple$mapSecond,
						$author$project$Effect$add(
							$author$project$Effect$CmdEffect(errorCommand)),
						A3($author$project$Helpers$StoredStudentVideoHelper$handleFetchUploadUrlError, remainingStudentQuestIds, loginInfo, model));
				}
			case 82:
				var studentQuestId = msg.a;
				var _v34 = A2($author$project$Helpers$BrowserConsole$debugLog, 'Uploaded stored student video', studentQuestId);
				return A3($author$project$Helpers$StoredStudentVideoHelper$videoUploaded, studentQuestId, loginInfo, model);
			case 83:
				var studentQuestId = msg.a;
				var _v35 = A2($author$project$Helpers$BrowserConsole$debugLog, 'Unable to upload stored student video', studentQuestId);
				return A2($author$project$Helpers$StoredStudentVideoHelper$videoUploadFailed, studentQuestId, model);
			case 84:
				return $author$project$Helpers$StoredStudentVideoHelper$videoRemoved(model);
			case 85:
				return $author$project$Helpers$StoredStudentVideoHelper$videoRemoved(model);
			case 86:
				return $author$project$Helpers$StoredStudentVideoHelper$videoRemoved(model);
			case 87:
				if (!msg.a.$) {
					return $author$project$Helpers$StoredStudentVideoHelper$markVideoUploadedSuccess(model);
				} else {
					var error = msg.a.a;
					var _v36 = A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, error, 'marking stored video upload complete');
					var errorCommand = _v36.b;
					return A2(
						$elm$core$Tuple$mapSecond,
						$author$project$Effect$add(
							$author$project$Effect$CmdEffect(errorCommand)),
						$author$project$Helpers$StoredStudentVideoHelper$markVideoUploadedFailure(model));
				}
			case 88:
				var studentUsageTrackerMsg = msg.a;
				var _v37 = A5(
					$author$project$StudentUsageTracker$update,
					model,
					$author$project$Routes$studentQuestIdFromRoute(model.eY),
					loginInfo,
					studentUsageTrackerMsg,
					model.c6);
				var updatedUsageTracking = _v37.a;
				var effect = _v37.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c6: updatedUsageTracking}),
					effect);
			case 89:
				if (!msg.a.$) {
					return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				} else {
					var httpError = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, httpError, 'updating minutes spent'));
				}
			case 90:
				var activityId = msg.a;
				return A3(
					$krisajenkins$remotedata$RemoteData$unwrap,
					_Utils_Tuple2(model, $author$project$Effect$NoEffect),
					function (_v38) {
						var studentActivities = _v38.jd;
						return A3(
							$elm_community$maybe_extra$Maybe$Extra$unwrap,
							_Utils_Tuple2(model, $author$project$Effect$NoEffect),
							function (studentActivity) {
								return _Utils_Tuple2(
									model,
									$author$project$Effect$GetCurrentTimeEffect(
										A2(
											$elm$core$Basics$composeR,
											A3($author$project$LoggedIn$Messages$CompleteStudentActivityTimestamped, studentActivity.hj, 2, false),
											$author$project$Messages$LoggedInRequiredMsg)));
							},
							$elm$core$List$head(
								A2(
									$elm$core$List$filter,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.f_;
										},
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.hj;
											},
											$elm$core$Basics$eq(activityId))),
									studentActivities)));
					},
					model.fZ);
			case 91:
				var _v39 = A2(
					$author$project$Helpers$CommandHelper$updateWithModalState,
					$author$project$Models$Modal$Open($author$project$Models$Modal$AvatarCustomizationModal),
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
				var modelWithOpenModal = _v39.a;
				var modalCmds = _v39.b;
				return _Utils_Tuple2(
					modelWithOpenModal,
					$author$project$Effect$batch(
						_List_fromArray(
							[
								$author$project$Effect$CmdEffect(modalCmds),
								$author$project$Effect$GetCurrentTimeEffect(
								A2(
									$elm$core$Basics$composeL,
									$author$project$Messages$LoggedInRequiredMsg,
									$author$project$LoggedIn$Messages$PublishEventTimestamped($author$project$EventPublisher$Event$StudentClickstreamLaunch_Character_Customizer))),
								(!student.dJ) ? $author$project$Effect$RecordCustomizerOpenedEffect(loginInfo) : $author$project$Effect$NoEffect
							])));
			case 96:
				if (!msg.a.$) {
					var updatedStudent = _Utils_update(
						student,
						{dJ: true});
					return $author$project$Effect$convertToEffect(
						_Utils_Tuple2(
							A3($author$project$Model$updateLoggedInUser, updatedStudent, loginInfo, model),
							$elm$core$Platform$Cmd$none));
				} else {
					var httpError = msg.a.a;
					return $author$project$Effect$convertToEffect(
						A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, httpError, 'recording StudentCustomizerOpened data'));
				}
			case 92:
				var origin = msg.a;
				var _v40 = model;
				var appConfiguration = _v40.f7;
				var updatedAppConfiguration = _Utils_update(
					appConfiguration,
					{
						eL: $author$project$AppConfiguration$RemoteConnectionActivated(
							{id: origin})
					});
				var updatedModel = _Utils_update(
					model,
					{f7: updatedAppConfiguration});
				return _Utils_Tuple2(
					updatedModel,
					A4($author$project$Effect$ProvideAuthInfoToPLEEffect, $author$project$AvatarCustomizationModal$pleIframeId, origin, loginInfo, model.i$));
			case 93:
				var newAccessoriesJson = msg.a;
				var _v41 = A2(
					$author$project$Helpers$CommandHelper$updateWithModalState,
					$author$project$Models$Modal$Closed,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
				var modelWithOpenModal = _v41.a;
				var modalCmds = _v41.b;
				var _v42 = A2(
					$elm$json$Json$Decode$decodeValue,
					$elm$json$Json$Decode$list($author$project$Models$StudentAccessory$decoder),
					newAccessoriesJson);
				if (!_v42.$) {
					var newAccessories = _v42.a;
					return _Utils_Tuple2(
						_Utils_update(
							modelWithOpenModal,
							{
								jF: A2(
									$author$project$Models$User$LoggedIn,
									_Utils_update(
										student,
										{jc: newAccessories}),
									loginInfo)
							}),
						$author$project$Effect$batch(
							_List_fromArray(
								[
									$author$project$Effect$CmdEffect(modalCmds),
									$author$project$Effect$GetCurrentTimeEffect(
									A2(
										$elm$core$Basics$composeL,
										$author$project$Messages$LoggedInRequiredMsg,
										$author$project$LoggedIn$Messages$PublishEventTimestamped(
											$author$project$EventPublisher$Event$StudentClickstreamClose_Character_Customizer(
												{g_: 1}))))
								])));
				} else {
					var parsingErr = _v42.a;
					return _Utils_Tuple2(
						modelWithOpenModal,
						$author$project$Effect$batch(
							_List_fromArray(
								[
									$author$project$Effect$CmdEffect(modalCmds),
									$author$project$Effect$GetCurrentTimeEffect(
									A2(
										$elm$core$Basics$composeL,
										$author$project$Messages$LoggedInRequiredMsg,
										$author$project$LoggedIn$Messages$PublishEventTimestamped(
											$author$project$EventPublisher$Event$StudentClickstreamClose_Character_Customizer(
												{g_: 1})))),
									A2(
									$author$project$Effect$LogErrorEffect,
									'Bad student accessory data from the PLE',
									$elm$json$Json$Decode$errorToString(
										A2($author$project$Helpers$BrowserConsole$debugLog, 'Error parsing PLE accessories', parsingErr)))
								])));
				}
			case 94:
				var _v43 = A2(
					$author$project$Helpers$CommandHelper$updateWithModalState,
					$author$project$Models$Modal$Closed,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
				var modelWithOpenModal = _v43.a;
				var modalCmds = _v43.b;
				return _Utils_Tuple2(
					modelWithOpenModal,
					$author$project$Effect$batch(
						_List_fromArray(
							[
								$author$project$Effect$CmdEffect(modalCmds),
								$author$project$Effect$GetCurrentTimeEffect(
								A2(
									$elm$core$Basics$composeL,
									$author$project$Messages$LoggedInRequiredMsg,
									$author$project$LoggedIn$Messages$PublishEventTimestamped(
										$author$project$EventPublisher$Event$StudentClickstreamClose_Character_Customizer(
											{g_: 0}))))
							])));
			case 97:
				var sectionId = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Effect$batch(
						_List_fromArray(
							[
								$author$project$Effect$UpdateUrlEffect(
								$author$project$Routes$routeToUrlString(
									A2($author$project$Routes$ClassLibraryRoute, $elm$core$Maybe$Nothing, sectionId))),
								$author$project$Effect$GetCurrentTimeEffect(
								A2(
									$elm$core$Basics$composeL,
									$author$project$Messages$LoggedInRequiredMsg,
									$author$project$LoggedIn$Messages$PublishEventTimestamped(
										$author$project$EventPublisher$Event$StudentClickstreamOpenClassLibrary(
											{iT: sectionId}))))
							])));
			case 99:
				var classLibraryMsg = msg.a;
				var _v44 = model.eY;
				if ((_v44.$ === 13) && (!_v44.a.$)) {
					var classLibraryModel = _v44.a.a;
					var sectionId = _v44.b;
					var _v45 = A2($author$project$Activities$ChoiceText$update, classLibraryMsg, classLibraryModel);
					var updatedClassLibraryModel = _v45.a;
					var parentMsg = _v45.b;
					var updatedModel = _Utils_update(
						model,
						{
							eY: A2(
								$author$project$Routes$ClassLibraryRoute,
								$elm$core$Maybe$Just(updatedClassLibraryModel),
								sectionId)
						});
					switch (parentMsg.$) {
						case 3:
							var origin = parentMsg.a;
							return _Utils_Tuple2(
								updatedModel,
								A4(
									$author$project$Effect$ProvideAuthInfoToChoiceTextEffect,
									$author$project$Activities$ChoiceText$iframeId(updatedClassLibraryModel),
									origin,
									loginInfo,
									model.i$));
						case 1:
							return _Utils_Tuple2(
								model,
								$author$project$Effect$UpdateUrlEffect(
									$author$project$Routes$routeToUrlString(
										$author$project$Routes$HomeRoute($author$project$Views$SubjectChoiceView$Model$init))));
						default:
							return _Utils_Tuple2(updatedModel, $author$project$Effect$NoEffect);
					}
				} else {
					return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				}
			case 98:
				var classLibraryModel = msg.a;
				var _v47 = model.eY;
				if (_v47.$ === 13) {
					var sectionId = _v47.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								eY: A2(
									$author$project$Routes$ClassLibraryRoute,
									$elm$core$Maybe$Just(classLibraryModel),
									sectionId)
							}),
						$author$project$Effect$NoEffect);
				} else {
					return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				}
			default:
				var event = msg.a;
				var currentTime = msg.b;
				return A2(
					$author$project$LoggedIn$publishEffectWithUuid,
					model,
					A2($author$project$Effect$PublishEventEffect, event, currentTime));
		}
	});
var $author$project$Models$LoggingIn$Errored = function (a) {
	return {$: 2, a: a};
};
var $author$project$Effect$InitializeQRCodeScannerEffect = {$: 40};
var $author$project$Effect$LoginFromQRCodeEffect = function (a) {
	return {$: 12, a: a};
};
var $author$project$Models$LoggingIn$QRCodeError = {$: 2};
var $author$project$Models$LoggingIn$QRCodeScannerInitializing = 1;
var $author$project$Models$LoggingIn$QRCodeScannerScanning = 2;
var $author$project$LoginApp$Main$processMessage = F4(
	function (loginState, msg, appConfiguration, route) {
		switch (msg.$) {
			case 0:
				var updatedUsername = msg.a;
				var updatedLoginState = _Utils_update(
					loginState,
					{
						fF: $elm$core$String$toUpper(updatedUsername)
					});
				return _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(updatedLoginState),
					$author$project$Effect$NoEffect);
			case 1:
				var updatedPassword = msg.a;
				var updatedLoginState = _Utils_update(
					loginState,
					{
						eI: $elm$core$String$toUpper(updatedPassword)
					});
				return _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(updatedLoginState),
					$author$project$Effect$NoEffect);
			case 2:
				var updatedLoginState = _Utils_update(
					loginState,
					{bI: $author$project$Models$LoggingIn$Ongoing});
				return _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(updatedLoginState),
					$author$project$Effect$UserLoginAttemptEffect(
						_Utils_Tuple2(loginState.fF, loginState.eI)));
			case 6:
				return _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(loginState),
					$author$project$Effect$UpdateUrlEffect('qr'));
			case 11:
				var loginRoute = msg.a;
				return _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(loginState),
					$author$project$Effect$UpdateUrlEffect(
						$author$project$Routes$LoginRoutes$routeToUrlString(loginRoute)));
			case 3:
				if (!msg.a.$) {
					var student = msg.a.a.jb;
					var auth = msg.a.a.gl;
					var authWithLoginMethod = _Utils_update(
						auth,
						{hB: loginState.hB});
					return _Utils_Tuple2(
						A2($author$project$Models$User$LoggedIn, student, authWithLoginMethod),
						$author$project$Effect$NoEffect);
				} else {
					var error = msg.a.a;
					var updatedLoginState = _Utils_update(
						loginState,
						{
							bI: $author$project$Models$LoggingIn$Errored(error)
						});
					return _Utils_Tuple2(
						$author$project$Models$User$NotLoggedIn(updatedLoginState),
						$author$project$Effect$NoEffect);
				}
			case 4:
				var newUrl = msg.a;
				return _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(loginState),
					$author$project$Effect$LoadUrlEffect(newUrl));
			case 5:
				var code = msg.a;
				return _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(loginState),
					$author$project$Effect$LoadUrlEffect(
						A2(
							$author$project$SSOLogin$googleRedirectUrl,
							A2($elm$core$Maybe$withDefault, '', code),
							appConfiguration)));
			case 8:
				var result = msg.a;
				return A2($elm$core$String$contains, appConfiguration.bz, result) ? _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(
						_Utils_update(
							loginState,
							{
								hB: $author$project$Models$LoggingIn$PrepopulatedUsernamePassword($author$project$Models$LoggingIn$PrepopulatedSourceQRCode)
							})),
					$author$project$Effect$LoginFromQRCodeEffect(result)) : _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(
						_Utils_update(
							loginState,
							{
								bI: $author$project$Models$LoggingIn$Errored($author$project$Models$LoggingIn$QRCodeError)
							})),
					$author$project$Effect$NoEffect);
			case 9:
				var error = msg.a;
				var _v1 = A2($author$project$Helpers$BrowserConsole$debugLog, 'QR Code Read Error', error);
				return _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(
						_Utils_update(
							loginState,
							{
								bI: $author$project$Models$LoggingIn$Errored($author$project$Models$LoggingIn$QRCodeError)
							})),
					$author$project$Effect$NoEffect);
			case 10:
				var loginUrl = msg.a;
				return _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(loginState),
					$author$project$Effect$UpdateUrlEffect(loginUrl));
			case 7:
				var newPermission = msg.a;
				return (_Utils_eq(
					route,
					$author$project$Routes$LoginRoute($author$project$Routes$LoginRoutes$QRCodeReader)) && ((newPermission === 3) && (!loginState.bg))) ? _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(
						_Utils_update(
							loginState,
							{bg: 1})),
					$author$project$Effect$InitializeQRCodeScannerEffect) : _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(loginState),
					$author$project$Effect$NoEffect);
			default:
				return _Utils_Tuple2(
					$author$project$Models$User$NotLoggedIn(
						_Utils_update(
							loginState,
							{bg: 2})),
					$author$project$Effect$NoEffect);
		}
	});
var $author$project$LoginApp$Main$update = F4(
	function (user, msg, appConfiguration, route) {
		if (user.$ === 1) {
			var loginState = user.a;
			return A4($author$project$LoginApp$Main$processMessage, loginState, msg, appConfiguration, route);
		} else {
			return _Utils_Tuple2(user, $author$project$Effect$NoEffect);
		}
	});
var $author$project$Views$SubjectChoiceView$update = F2(
	function (msg, model) {
		if (!msg) {
			return _Utils_update(
				model,
				{fb: true});
		} else {
			return _Utils_update(
				model,
				{fc: true});
		}
	});
var $author$project$Models$LocalStorage$VideoPlayerTime$set = F3(
	function (key, entry, history) {
		return A3($elm$core$Dict$insert, key, entry, history);
	});
var $author$project$Models$LocalStorage$Helpers$setVideoPlayerTimeEntry = F3(
	function (key, entry, store) {
		return _Utils_update(
			store,
			{
				c8: A3($author$project$Models$LocalStorage$VideoPlayerTime$set, key, entry, store.c8)
			});
	});
var $author$project$Main$updateVideoTime = F4(
	function (player, videoKey, maybePlayerTime, model) {
		var updatedLocalStorage = function () {
			if (maybePlayerTime.$ === 1) {
				return model.hz;
			} else {
				var playerTime = maybePlayerTime.a;
				return A3($author$project$Models$LocalStorage$Helpers$setVideoPlayerTimeEntry, videoKey, playerTime, model.hz);
			}
		}();
		return A4(
			$author$project$Helpers$VideoPlayback$updateVideoTime,
			player,
			videoKey,
			maybePlayerTime,
			_Utils_update(
				model,
				{hz: updatedLocalStorage}));
	});
var $author$project$Assets$Favicon$webActivity = $author$project$Assets$Favicon$Static(
	{Q: require('/usr/src/app/app/images/favicons/web-activity@2x.png'), T: require('/usr/src/app/app/images/favicons/web-activity.png')});
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{f9: true}),
					$author$project$Init$onAppLoadedEffects);
			case 10:
				var portName = msg.a;
				var decodeError = msg.b;
				var _v1 = A2(
					$author$project$Helpers$BrowserConsole$debugLog,
					'Port decode failure!',
					_Utils_Tuple2(portName, decodeError));
				return $author$project$Effect$convertToEffect(
					A3(
						$author$project$Model$withLoggedInUser,
						model,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						F2(
							function (student, _v2) {
								return A4(
									$author$project$ErrorHandling$handleIncomingPortError,
									student,
									model,
									$elm$json$Json$Decode$errorToString(decodeError),
									portName);
							})));
			case 3:
				var loggedInMsg = msg.a;
				return A3(
					$author$project$Model$withLoggedInUser,
					model,
					_Utils_Tuple2(model, $author$project$Effect$NoEffect),
					F2(
						function (student, loginInfo) {
							return A4($author$project$LoggedIn$update, student, loginInfo, loggedInMsg, model);
						}));
			case 2:
				var homeMsg = msg.a;
				var _v3 = model.eY;
				if (!_v3.$) {
					var homeModel = _v3.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								eY: $author$project$Routes$HomeRoute(
									A2($author$project$Views$SubjectChoiceView$update, homeMsg, homeModel))
							}),
						$author$project$Effect$NoEffect);
				} else {
					return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				}
			case 4:
				var subMsg = msg.a;
				return model.cl ? _Utils_Tuple2(
					model,
					$author$project$Effect$PerformNowEffect(
						$author$project$Messages$UpdateCancelledByDisabledClick(subMsg))) : _Utils_Tuple2(
					_Utils_update(
						model,
						{cl: true}),
					$author$project$Effect$PerformNowEffect(subMsg));
			case 5:
				return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cl: false}),
					$author$project$Effect$NoEffect);
			case 8:
				return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 9:
				var learnosityMsg = msg.a;
				return $author$project$Effect$convertToEffect(
					A3(
						$author$project$Model$withLoggedInUser,
						model,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						F2(
							function (student, loginInfo) {
								return A4($author$project$Learnosity$update, student, loginInfo, learnosityMsg, model);
							})));
			case 15:
				var loginMsg = msg.a;
				var _v4 = A4($author$project$LoginApp$Main$update, model.jF, loginMsg, model.f7, model.eY);
				var updatedUser = _v4.a;
				var loginAppCommand = _v4.b;
				var updatedModel = _Utils_update(
					model,
					{jF: updatedUser});
				var _v5 = _Utils_Tuple2(model.jF, updatedUser);
				if (_v5.a.$ === 2) {
					var _v6 = _v5.a;
					return _Utils_Tuple2(updatedModel, loginAppCommand);
				} else {
					if (_v5.b.$ === 2) {
						var _v7 = _v5.b;
						var student = _v7.a;
						var loginInfo = _v7.b;
						return A3($author$project$Helpers$CommandHelper$onUserBecomesKnown, student, loginInfo, updatedModel);
					} else {
						return _Utils_Tuple2(updatedModel, loginAppCommand);
					}
				}
			case 16:
				var joinCodeMsg = msg.a;
				var _v8 = model.eY;
				if (_v8.$ === 11) {
					var backRoute = _v8.a;
					var joinCodeModel = _v8.b;
					return A4($author$project$Main$handleJoinCodeUpdate, backRoute, joinCodeMsg, joinCodeModel, model);
				} else {
					return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				}
			case 17:
				if (!msg.b.$) {
					var loginInfo = msg.b.a;
					return $author$project$Effect$convertToEffect(
						_Utils_Tuple2(
							model,
							A2($author$project$Api$LoginApi$validate, loginInfo, model.f7)));
				} else {
					var httpError = msg.b.a;
					var _v9 = A2(
						$author$project$Helpers$CommandHelper$ensureUserLoggedOut,
						false,
						A3(
							$author$project$Model$withLoggedInUser,
							model,
							_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
							F2(
								function (student, _v10) {
									return A4($author$project$ErrorHandling$handleHttpErrorLoggedIn, student, model, httpError, 'redeeming autologin code');
								})));
					var updatedModel = _v9.a;
					var updatedCommands = _v9.b;
					return $author$project$Effect$convertToEffect(
						_Utils_Tuple2(
							updatedModel,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										updatedCommands,
										A3(
										$author$project$Helpers$NavigationHelper$updateUrl,
										model.hR,
										model.f7,
										$author$project$Routes$routeToUrlString(
											$author$project$Routes$LoginRoute(
												$author$project$Routes$LoginRoutes$PasswordLoginRoute(0))))
									]))));
				}
			case 18:
				var maybeJsonString = msg.a;
				return $author$project$Effect$convertToEffect(
					function () {
						if (maybeJsonString.$ === 1) {
							return A2(
								$author$project$Helpers$CommandHelper$ensureUserLoggedOut,
								false,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
						} else {
							var aJSONString = maybeJsonString.a;
							var _v12 = $author$project$AuthPersistence$loginInfoFromJSON(aJSONString);
							if (_v12.$ === 1) {
								return A2(
									$author$project$Helpers$CommandHelper$ensureUserLoggedOut,
									false,
									_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
							} else {
								var someLoginInfo = _v12.a;
								return _Utils_Tuple2(
									model,
									A2($author$project$Api$LoginApi$validate, someLoginInfo, model.f7));
							}
						}
					}());
			case 19:
				if (!msg.b.$) {
					var loginInfo = msg.a;
					var student = msg.b.a;
					var updatedModel = _Utils_update(
						model,
						{
							jF: A2($author$project$Models$User$LoggedIn, student, loginInfo)
						});
					return A3($author$project$Helpers$CommandHelper$onUserBecomesKnown, student, loginInfo, updatedModel);
				} else {
					var httpError = msg.b.a;
					var _v13 = function () {
						if ($author$project$Api$RequestHelper$is401(httpError)) {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						} else {
							var _v14 = A3($author$project$Helpers$CommandHelper$withErrorHandling, model, httpError, 'validating user');
							var newModel = _v14.a;
							var errorHandlingCmd = _v14.b;
							return _Utils_Tuple2(
								newModel,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A3(
											$author$project$Logger$logRedshift,
											model,
											'Unable to validate student identity',
											_List_fromArray(
												[
													_Utils_Tuple2(
													'error',
													$elm$json$Json$Encode$string(
														$author$project$Api$RequestHelper$errorAsString(httpError)))
												])),
											errorHandlingCmd
										])));
						}
					}();
					var updatedModel = _v13.a;
					var errorCommand = _v13.b;
					return $author$project$Effect$convertToEffect(
						A2(
							$author$project$Helpers$CommandHelper$ensureUserLoggedOut,
							false,
							_Utils_Tuple2(updatedModel, errorCommand)));
				}
			case 20:
				return _Utils_eq(model.jF, $author$project$Models$User$NotYetKnown) ? $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A3(
									$author$project$Logger$logRedshift,
									model,
									'Student Stuck Waiting for Identification',
									_List_fromArray(
										[
											_Utils_Tuple2(
											'platform',
											$elm$json$Json$Encode$string(
												$author$project$AppConfiguration$platformToString(model.f7.ii)))
										]))
								])))) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 21:
				return $author$project$Effect$convertToEffect(
					A2(
						$author$project$Helpers$CommandHelper$ensureUserLoggedOut,
						true,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
			case 22:
				return A2(
					$author$project$Routing$Updates$forRouteNotLoggedIn,
					model.eY,
					_Utils_Tuple2(
						$author$project$Model$resetUserState(model),
						$elm$core$Platform$Cmd$none));
			case 23:
				var studentActivity = msg.a;
				var subMsg = msg.b;
				var url = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'',
					$author$project$Models$Urls$toString,
					$author$project$Models$Activity$webAppUrl(studentActivity.f_));
				return _Utils_Tuple2(
					model,
					$author$project$Effect$batch(
						_List_fromArray(
							[
								$author$project$Effect$PerformNowEffect(subMsg),
								$author$project$Effect$GetCurrentTimeEffect(
								A2(
									$elm$core$Basics$composeR,
									$author$project$LoggedIn$Messages$PublishEventTimestamped(
										$author$project$EventPublisher$Event$StudentClickstreamInstructionBarNext(
											A2($author$project$EventPublisher$Event$StudentClickstreamData, url, true))),
									$author$project$Messages$LoggedInRequiredMsg))
							])));
			case 24:
				var urlRequest = msg.a;
				if (!urlRequest.$) {
					var requestedUrl = urlRequest.a;
					return A2(
						$author$project$Helpers$BeforeNavigationHelper$beforeNavigation,
						$elm$url$Url$toString(requestedUrl),
						model);
				} else {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$author$project$Effect$LoadUrlEffect(url));
				}
			case 25:
				var url = msg.a;
				var routeNeedsLoginAppCleanup = A2($author$project$LoginApp$Main$needsRouteCleanup, model.jF, model.eY);
				var newRoute = $author$project$Routes$parseUrl(url);
				var routeNeedsComponentCleanup = $author$project$Routes$isComponent(model.eY) && (!_Utils_eq(model.eY, newRoute));
				var routeNeedsQuizCleanup = $author$project$Routes$isAssessment(model.eY) && (!_Utils_eq(model.eY, newRoute));
				var updatedModel = _Utils_update(
					model,
					{
						hV: newRoute,
						jE: A2($elm$core$Maybe$withDefault, '', url.is)
					});
				var navigatingToWebActivity = A2($author$project$Routes$activityRouteHasWebActivity, updatedModel.hV, updatedModel.fZ);
				var faviconChangeCmd = (!$author$project$Routes$isActivityPracticeRoute(updatedModel.hV)) ? $author$project$Ports$updateFavicon(
					$author$project$Assets$Favicon$animationEncoder($author$project$Assets$Favicon$esparkDefault)) : (($author$project$Routes$isActivityPracticeRoute(updatedModel.hV) && navigatingToWebActivity) ? $author$project$Ports$updateFavicon(
					$author$project$Assets$Favicon$animationEncoder($author$project$Assets$Favicon$webActivity)) : (($author$project$Routes$isActivityPracticeRoute(updatedModel.hV) && (!navigatingToWebActivity)) ? $author$project$Ports$updateFavicon(
					$author$project$Assets$Favicon$animationEncoder($author$project$Assets$Favicon$esparkDefault)) : $elm$core$Platform$Cmd$none));
				var _v16 = ((!_Utils_eq(
					url.ig,
					$author$project$Routes$routeToUrlString(model.eY))) || (!_Utils_eq(
					A2($elm$core$Maybe$withDefault, '', url.is),
					model.jE))) ? (routeNeedsComponentCleanup ? $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						updatedModel,
						$author$project$Helpers$CommandHelper$exitComponentCleanup(model.eY))) : (routeNeedsQuizCleanup ? $author$project$Effect$convertToEffect(
					_Utils_Tuple2(updatedModel, $author$project$Helpers$CommandHelper$exitQuizCleanup)) : (routeNeedsLoginAppCleanup ? _Utils_Tuple2(
					updatedModel,
					A2($author$project$LoginApp$Main$routeCleanup, model.jF, model.eY)) : A2(
					$author$project$Main$initializeNewRoute,
					updatedModel.hV,
					_Utils_Tuple2(
						updatedModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									faviconChangeCmd,
									$author$project$Ports$updateWindowTitle($author$project$Helpers$WebAppsHelper$esparkDefaultTitle)
								]))))))) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				var modelWithRouteUpdates = _v16.a;
				var effects = _v16.b;
				return _Utils_Tuple2(
					modelWithRouteUpdates,
					A2(
						$author$project$Effect$add,
						effects,
						$author$project$Effect$batch(
							_List_fromArray(
								[
									$author$project$Effect$GetCurrentTimeEffect($author$project$Messages$RecordNavigationTimestamp),
									$author$project$Effect$SetHeartbeatDataEffect
								]))));
			case 26:
				var currentTime = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hw: currentTime}),
					$author$project$Effect$NoEffect);
			case 27:
				var source = msg.a;
				return A2($author$project$Main$handleRouteCleanupComplete, source, model);
			case 28:
				var route = msg.a;
				return A2(
					$author$project$Helpers$BeforeNavigationHelper$beforeNavigation,
					$author$project$Routes$routeToUrlString(route),
					model);
			case 29:
				var navigationDestination = msg.a;
				return A3(
					$author$project$Model$withLoggedInUser,
					model,
					_Utils_Tuple2(model, $author$project$Effect$NoEffect),
					F2(
						function (student, _v17) {
							return A3($author$project$Main$navigateToActivity, student, navigationDestination, model);
						}));
			case 6:
				var _v18 = A2(
					$author$project$Helpers$BeforeNavigationHelper$beforeNavigation,
					$author$project$Routes$routeToUrlString($author$project$Routes$homeRoute),
					model);
				var updatedModel = _v18.a;
				var effects = _v18.b;
				return _Utils_Tuple2(
					updatedModel,
					$author$project$Effect$batch(
						_List_fromArray(
							[
								$author$project$Effect$CmdEffect(
								$author$project$Helpers$Task$getCurrentTime(
									A2(
										$elm$core$Basics$composeL,
										$author$project$Messages$LoggedInRequiredMsg,
										$author$project$LoggedIn$Messages$PublishEventTimestamped($author$project$EventPublisher$Event$StudentClickStreamClickChangeSubject)))),
								effects
							])));
			case 30:
				var params = msg.a;
				var forceOpenInIosSafari = A3(
					$author$project$Model$withLoggedInUser,
					model,
					false,
					F2(
						function (student, _v19) {
							return A2($author$project$Models$ProductFeature$isEnabled, 3, student.iq);
						}));
				return _Utils_Tuple2(
					model,
					A3($author$project$Helpers$WebAppsHelper$openInBrowser, params, forceOpenInIosSafari, model.f7.ii));
			case 31:
				var nextMsg = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Effect$GetCurrentTimeEffect(nextMsg));
			case 32:
				var openTime = msg.a;
				var updateModel = function (studentActivity) {
					return _Utils_update(
						model,
						{
							f1: A2($author$project$Models$ActivityWindowState$activityWindowOpened, openTime, studentActivity.hj)
						});
				};
				var studentActivityFromRoute = $author$project$Model$studentActivityForCurrentRoute(model);
				var webAppLaunchParams = A2($elm$core$Maybe$andThen, $author$project$Models$StudentActivity$webAppLaunchParams, studentActivityFromRoute);
				var _v20 = _Utils_Tuple2(studentActivityFromRoute, webAppLaunchParams);
				if ((!_v20.a.$) && (!_v20.b.$)) {
					var studentActivity = _v20.a.a;
					var launchParams = _v20.b.a;
					return _Utils_Tuple2(
						updateModel(studentActivity),
						A2($author$project$Helpers$WebAppsHelper$scheduleReturnEffects, openTime, launchParams));
				} else {
					return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
				}
			case 33:
				var url = msg.b;
				var openTime = msg.c;
				var effect = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					$author$project$Effect$NoEffect,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.hj;
						},
						A3($author$project$Helpers$WebAppsHelper$evaluateForAutoClose, url, openTime, model.f1)),
					$author$project$Model$studentActivityForCurrentRoute(model));
				return _Utils_Tuple2(
					model,
					A2($author$project$Effect$add, effect, $author$project$Effect$HideNotificationEffect));
			case 34:
				var openTime = msg.b;
				var effect = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					$author$project$Effect$NoEffect,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.hj;
						},
						A2($author$project$Helpers$WebAppsHelper$evaluateForReturnPrompts, openTime, model.f1)),
					$author$project$Model$studentActivityForCurrentRoute(model));
				return _Utils_Tuple2(model, effect);
			case 35:
				var url = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Effect$batch(
						_List_fromArray(
							[
								$author$project$Helpers$WebAppsHelper$closeExternalWindow(url),
								$author$project$Effect$ChangeFavicon($author$project$Assets$Favicon$webActivity),
								$author$project$Helpers$WebAppsHelper$changeWindowTitleToDefaultTitleEffect,
								$author$project$Effect$GetCurrentTimeEffect(
								A2(
									$elm$core$Basics$composeR,
									$author$project$LoggedIn$Messages$PublishEventTimestamped(
										$author$project$EventPublisher$Event$StudentClickstreamCloseWebappActivityTab(
											A2(
												$author$project$EventPublisher$Event$StudentClickstreamData,
												$author$project$Models$Urls$toString(url),
												false))),
									$author$project$Messages$LoggedInRequiredMsg))
							])));
			case 36:
				return function (updatedModel) {
					return _Utils_Tuple2(updatedModel, $author$project$Effect$NoEffect);
				}(
					A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						model,
						A2(
							$elm$core$Basics$composeR,
							$author$project$Models$ActivityWindowState$activityWindowClosed,
							function (activityWindowState) {
								return _Utils_update(
									model,
									{f1: activityWindowState});
							}),
						$author$project$Routes$studentActivityIdFromRoute(model.eY)));
			case 37:
				return $author$project$Main$handleIosBrowserProblem(model);
			case 38:
				return $author$project$Main$handleIosBrowserProblem(model);
			case 72:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d1: true}),
					$author$project$Effect$NoEffect);
			case 73:
				return _Utils_Tuple2(model, $author$project$Effect$ReloadPageEffect);
			case 39:
				var modalIdentifier = msg.a;
				return $author$project$Effect$convertToEffect(
					A2(
						$author$project$Helpers$CommandHelper$updateWithModalState,
						$author$project$Models$Modal$Open(modalIdentifier),
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
			case 40:
				return $author$project$Effect$convertToEffect(
					A2(
						$author$project$Helpers$CommandHelper$updateWithModalState,
						$author$project$Models$Modal$Closed,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
			case 41:
				return $author$project$Effect$convertToEffect(
					A2(
						$author$project$Helpers$CommandHelper$updateWithModalState,
						$author$project$Models$Modal$Closed,
						_Utils_Tuple2(
							_Utils_update(
								model,
								{hC: true}),
							$elm$core$Platform$Cmd$none)));
			case 42:
				return $author$project$Effect$convertToEffect(
					A2(
						$author$project$Helpers$CommandHelper$updateWithModalState,
						$author$project$Models$Modal$Closed,
						_Utils_Tuple2(
							_Utils_update(
								model,
								{hC: false}),
							$elm$core$Platform$Cmd$none)));
			case 43:
				var openTime = msg.a;
				var updateModel = function (studentActivity) {
					return _Utils_update(
						model,
						{
							f1: A2($author$project$Models$ActivityWindowState$activityWindowOpened, openTime, studentActivity.hj)
						});
				};
				var effects = function (studentActivity) {
					return A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						$author$project$Effect$NoEffect,
						$author$project$Helpers$WebAppsHelper$scheduleReturnEffects(openTime),
						$author$project$Models$StudentActivity$webAppLaunchParams(studentActivity));
				};
				var updates = function (studentActivity) {
					return _Utils_Tuple2(
						updateModel(studentActivity),
						effects(studentActivity));
				};
				return A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					_Utils_Tuple2(model, $author$project$Effect$NoEffect),
					updates,
					$author$project$Model$studentActivityForCurrentRoute(model));
			case 45:
				var videoKey = msg.a;
				return $author$project$Models$WindowFocus$hasFocus(model.fS) ? $author$project$Effect$convertToEffect(
					A3($author$project$Helpers$VideoPlayback$playVideo, model, videoKey, false)) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 46:
				var studentActivityId = msg.a;
				return (A2($author$project$Main$shouldPlayVideoAfterDelay, studentActivityId, model) && $author$project$Models$WindowFocus$hasFocus(model.fS)) ? $author$project$Effect$convertToEffect(
					A3(
						$author$project$Helpers$VideoPlayback$playVideo,
						model,
						$author$project$Models$StudentActivity$activityKey(studentActivityId),
						false)) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 47:
				var videoKey = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Effect$CmdEffect(
						$author$project$Ports$playVideo(
							_Utils_Tuple2(videoKey, 0))));
			case 48:
				var studentActivityId = msg.a;
				return (model.d1 && A2($author$project$Main$shouldPlayVideoAfterDelay, studentActivityId, model)) ? $author$project$Effect$convertToEffect(
					A3(
						$author$project$Helpers$VideoPlayback$playVideo,
						model,
						$author$project$Models$StudentActivity$activityKey(studentActivityId),
						false)) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 49:
				var videoKey = msg.a;
				return $author$project$Effect$convertToEffect(
					A3($author$project$Helpers$VideoPlayback$playVideo, model, videoKey, true));
			case 50:
				return $author$project$Effect$convertToEffect(
					$author$project$Helpers$VideoPlayback$pauseVideo(model));
			case 44:
				var _v21 = msg.a;
				var videoKey = _v21.a;
				var maybePlayerTime = _v21.b;
				return _Utils_Tuple2(
					A4($author$project$Main$updateVideoTime, 0, videoKey, maybePlayerTime, model),
					$author$project$Effect$NoEffect);
			case 52:
				return $author$project$Effect$convertToEffect(
					$author$project$Helpers$VideoPlayback$playbackErrored(model));
			case 51:
				return $author$project$Effect$convertToEffect(
					$author$project$Helpers$VideoPlayback$playbackEnded(model));
			case 53:
				return $author$project$Effect$convertToEffect(
					$author$project$Helpers$VideoPlayback$rightClickedOnVideo(model));
			case 74:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aI: $author$project$Models$YoutubeVideoPlayer$initialAvailableStatus}),
					A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						$author$project$Effect$NoEffect,
						function (studentActivity) {
							return A3(
								$elm_community$maybe_extra$Maybe$Extra$unwrap,
								$author$project$Effect$NoEffect,
								function (youtubeData) {
									return A2(
										$author$project$Effect$PerformLaterEffect,
										0,
										A2($author$project$Messages$ConnectToEmbeddedYoutubePlayer, studentActivity.hj, youtubeData));
								},
								$author$project$Models$Activity$youtubeData(studentActivity.f_));
						},
						$author$project$Model$studentActivityForCurrentRoute(model)));
			case 75:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aI: $author$project$Models$YoutubeVideoPlayer$unavailableStatus}),
					A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						$author$project$Effect$NoEffect,
						function (studentActivity) {
							return $author$project$Models$Activity$isYoutubeVideo(studentActivity.f_) ? A2(
								$author$project$Effect$PerformLaterEffect,
								0,
								$author$project$Messages$PlayVideoAfterDelay(studentActivity.hj)) : $author$project$Effect$NoEffect;
						},
						$author$project$Model$studentActivityForCurrentRoute(model)));
			case 76:
				return _Utils_Tuple2(
					(!$author$project$Models$YoutubeVideoPlayer$isYoutubeStatusKnown(model.aI)) ? _Utils_update(
						model,
						{aI: $author$project$Models$YoutubeVideoPlayer$unavailableStatus}) : model,
					$author$project$Effect$NoEffect);
			case 77:
				var studentActivityId = msg.b;
				return _Utils_Tuple2(
					A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						model,
						function (youtubeId) {
							return _Utils_update(
								model,
								{
									aI: A2($author$project$Models$YoutubeVideoPlayer$addRestrictedVideo, model.aI, youtubeId)
								});
						},
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.fW;
							},
							A2(
								$elm$core$Maybe$andThen,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.f_;
									},
									$author$project$Models$Activity$youtubeData),
								A2($author$project$Helpers$RecordFinders$findStudentActivity, studentActivityId, model.fZ)))),
					A2(
						$author$project$Effect$PerformLaterEffect,
						500,
						$author$project$Messages$PlayVideoAfterDelay(studentActivityId)));
			case 78:
				var studentActivityId = msg.a;
				return (model.d1 && A2($author$project$Main$shouldPlayVideoAfterDelay, studentActivityId, model)) ? _Utils_Tuple2(
					model,
					$author$project$Effect$PerformNowEffect(
						A2($author$project$Messages$MarkYoutubeVideoAsRestricted, 0, studentActivityId))) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 79:
				return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 81:
				var _v22 = msg.a;
				var videoKey = _v22.a;
				var maybePlayerTime = _v22.b;
				var updatedModel = $author$project$Models$VideoPlayer$isPlaying(model.jI) ? A4($author$project$Main$updateVideoTime, 1, videoKey, maybePlayerTime, model) : A4(
					$author$project$Main$updateVideoTime,
					1,
					videoKey,
					maybePlayerTime,
					_Utils_update(
						model,
						{
							jI: A2(
								$author$project$Models$VideoPlayer$Playing,
								$author$project$Models$VideoPlayer$playerTime(model.jI),
								1)
						}));
				return _Utils_Tuple2(updatedModel, $author$project$Effect$NoEffect);
			case 82:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							jI: $author$project$Models$VideoPlayer$Paused(
								$author$project$Models$VideoPlayer$playerTime(model.jI))
						}),
					$author$project$Effect$NoEffect);
			case 80:
				var studentActivityId = msg.a;
				var youtubeData = msg.b;
				return _Utils_Tuple2(
					model,
					A2($author$project$Effect$ConnectToEmbeddedYoutubePlayerEffect, studentActivityId, youtubeData));
			case 83:
				return $author$project$Models$WindowFocus$hasFocus(model.fS) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{
							jI: $author$project$Models$VideoPlayer$StartingPlay(
								$author$project$Models$VideoPlayer$playerTime(model.jI))
						}),
					$author$project$Effect$CmdEffect(
						$author$project$Ports$playEmbeddedYoutubePlayer(0))) : _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 84:
				return _Utils_Tuple2(
					model,
					$author$project$Effect$CmdEffect(
						$author$project$Ports$pauseEmbeddedYoutubePlayer(0)));
			case 85:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							jI: A2($author$project$Models$VideoPlayer$Playing, $author$project$Models$VideoPlayer$defaultPlayerTime, 1)
						}),
					$author$project$Effect$CmdEffect(
						$author$project$Ports$restartEmbeddedYoutubeVideo(0)));
			case 54:
				var animationMsg = msg.a;
				var _v23 = A2($author$project$Animations$update, animationMsg, model.dm);
				var updatedAnimations = _v23.a;
				var cmds = _v23.b;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{dm: updatedAnimations}),
						cmds));
			case 55:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hq: false}),
					$author$project$Effect$NoEffect);
			case 58:
				var domNode = msg.a;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						$author$project$Ports$triggerTTSPlayFromNode(
							_Utils_Tuple2(domNode, 'Matthew'))));
			case 59:
				var text = msg.a;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						$author$project$Ports$triggerTTSPlayFromText(
							_Utils_Tuple2(text, 'Matthew'))));
			case 57:
				var itemSelectors = msg.a;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						$author$project$Ports$triggerTTSPlaySelector(
							_Utils_Tuple2(itemSelectors, 'Matthew'))));
			case 60:
				var url = msg.a;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						$author$project$Ports$triggerTTSPlayUrl(url)));
			case 56:
				var maybeSelector = msg.a;
				var autoplaySelector = function () {
					var _v24 = model.hM;
					if (!_v24.$) {
						var modalIdentifier = _v24.a;
						return '.' + ($author$project$Models$Modal$modalDomIdentifier(modalIdentifier) + ' ttgspan[data-audio-auto-play]');
					} else {
						return A2($elm$core$Maybe$withDefault, $author$project$TextToSpeech$defaultQuerySelector, maybeSelector);
					}
				}();
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						$author$project$Ports$triggerTTSRetagAndPlay(
							_Utils_Tuple2(autoplaySelector, 'Matthew'))));
			case 61:
				var isCurrentlySpeaking = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fB: isCurrentlySpeaking}),
					$author$project$Effect$NoEffect);
			case 62:
				return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 63:
				var ttsHealthCheckMsg = msg.a;
				return A2($author$project$Main$handleTTSHealthCheckMsg, ttsHealthCheckMsg, model);
			case 64:
				var hoverMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d4: A2($author$project$Helpers$Hoverable$update, hoverMsg, model.d4)
						}),
					$author$project$Effect$NoEffect);
			case 65:
				var newPermission = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h1: newPermission}),
					$author$project$Effect$NoEffect);
			case 66:
				var newPermission = msg.a;
				var _v25 = model.eY;
				if (_v25.$ === 1) {
					return function (_v26) {
						var user = _v26.a;
						var effect = _v26.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{hJ: newPermission, jF: user}),
							effect);
					}(
						A4(
							$author$project$LoginApp$Main$update,
							model.jF,
							$author$project$LoginApp$Messages$MediaPermissionsUpdate(newPermission),
							model.f7,
							model.eY));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hJ: newPermission}),
						$author$project$Effect$NoEffect);
				}
			case 67:
				var hasFocus = msg.a;
				var currentTime = msg.b;
				return A3($author$project$Main$handleWindowFocusChange, currentTime, hasFocus, model);
			case 68:
				var currentTime = msg.b;
				return A2($author$project$Main$handleWindowLostFocus, currentTime, model);
			case 69:
				var currentTime = msg.b;
				return A2($author$project$Main$handleWindowRegainedFocus, currentTime, model);
			case 11:
				var windowInfo = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							jT: $elm$core$Maybe$Just(windowInfo)
						}),
					$author$project$Effect$NoEffect);
			case 12:
				var scrollY = msg.a;
				return $author$project$Effect$convertToEffect(
					_Utils_Tuple2(
						model,
						$author$project$Ports$scrollContainerTo(scrollY)));
			case 14:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cF: true}),
					$author$project$Effect$NoEffect);
			case 13:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cF: false}),
					$author$project$Effect$CheckForNewVersionAvailabilityEffect(1));
			case 70:
				var ttsText = msg.a;
				var toggleMood = msg.b;
				var logInEffect = function (_v27) {
					var aModel = _v27.a;
					var effect = _v27.b;
					return A2(
						$author$project$LoggedIn$publishEffectWithUuid,
						aModel,
						A2(
							$author$project$Effect$PublishEventEffect,
							$author$project$EventPublisher$Event$StudentClickstreamMoodSelected(
								$author$project$EventPublisher$Event$MoodSelectedClickstreamData(
									$author$project$Models$StudentMood$moodToString(
										A2($elm$core$Maybe$withDefault, 0, toggleMood)))),
							$elm$time$Time$millisToPosix(0)));
				};
				return $author$project$Effect$convertToEffect(
					A2(
						$author$project$Helpers$CommandHelper$updateWithModalState,
						$author$project$Models$Modal$Open(
							$author$project$Models$Modal$MoodRecorderOverlay(toggleMood)),
						_Utils_Tuple2(
							model,
							$author$project$Helpers$Task$performNow(
								$author$project$Messages$PlayTTSSpeechFromText(ttsText)))));
			case 71:
				var isOnWebActivity = msg.a;
				var faviconIfWebActivityEffect = isOnWebActivity ? $author$project$Effect$ChangeFavicon($author$project$Assets$Favicon$webActivity) : $author$project$Effect$NoEffect;
				return _Utils_Tuple2(model, faviconIfWebActivityEffect);
			case 86:
				var origin = msg.a;
				var _v28 = model;
				var appConfiguration = _v28.f7;
				var updatedAppConfiguration = _Utils_update(
					appConfiguration,
					{
						cn: $author$project$AppConfiguration$RemoteConnectionActivated(
							{id: origin})
					});
				var updatedModel = _Utils_update(
					model,
					{f7: updatedAppConfiguration});
				return _Utils_Tuple2(
					updatedModel,
					A2(
						$author$project$Effect$UpdateEsparkIframeEffect,
						origin,
						$author$project$TeacherTour$UpdateEsparkIframe$updateData(updatedModel)));
			case 87:
				var activityId = msg.a;
				var navigateToActivityWrapper = F2(
					function (student, _v29) {
						return A3(
							$krisajenkins$remotedata$RemoteData$unwrap,
							_Utils_Tuple2(model, $author$project$Effect$NoEffect),
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.jd;
								},
								A2(
									$elm$core$Basics$composeR,
									A2(
										$author$project$Models$Ids$findByIdFunc,
										function ($) {
											return $.f_;
										},
										activityId),
									A2(
										$elm$core$Basics$composeR,
										$elm$core$Maybe$map(
											function (studentActivity) {
												return A3(
													$author$project$Main$navigateToActivity,
													student,
													$author$project$AppFlow$ActivityWithId(studentActivity.hj),
													model);
											}),
										$elm$core$Maybe$withDefault(
											_Utils_Tuple2(model, $author$project$Effect$NoEffect))))),
							model.fZ);
					});
				return A3(
					$author$project$Model$withLoggedInUser,
					model,
					_Utils_Tuple2(model, $author$project$Effect$NoEffect),
					navigateToActivityWrapper);
			case 88:
				return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			case 89:
				var esparkIosAppDisabledMsg = msg.a;
				var _v30 = A2(
					$elm$core$Tuple$mapFirst,
					$elm$core$Platform$Cmd$map($author$project$Messages$EsparkIosAppDisabledMsg),
					$author$project$EsparkIosAppDisabled$update(esparkIosAppDisabledMsg));
				var cmd = _v30.a;
				var parentMsg = _v30.b;
				var parentCmd = function () {
					if (parentMsg.$ === 1) {
						return $elm$core$Platform$Cmd$none;
					} else {
						if (!parentMsg.a.$) {
							var node = parentMsg.a.a;
							return $author$project$Helpers$Task$performNow(
								$author$project$Messages$PlayTTSSpeechFromNode(node));
						} else {
							var _v32 = parentMsg.a;
							return $author$project$Helpers$Task$performNow(
								$author$project$Messages$PlayAllAutoplaySpeech($elm$core$Maybe$Nothing));
						}
					}
				}();
				return _Utils_Tuple2(
					model,
					$author$project$Effect$CmdEffect(
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[cmd, parentCmd]))));
			case 90:
				return _Utils_Tuple2(model, $author$project$Effect$NoEffect);
			default:
				var activityId = msg.a;
				var studentActivityId = msg.b;
				return A2(
					$author$project$LoggedIn$publishEffectWithUuid,
					model,
					A2(
						$author$project$Effect$PublishEventEffect,
						$author$project$EventPublisher$Event$StudentClickstreamClickActivity(
							A2($author$project$EventPublisher$Event$ActivityClickstreamData, activityId, studentActivityId)),
						$elm$time$Time$millisToPosix(0)));
		}
	});
var $author$project$Main$wrappedUpdate = F2(
	function (msg, prevModel) {
		return A3(
			$author$project$Main$logMessage,
			msg,
			prevModel,
			A2(
				$author$project$Models$LocalStorage$Helpers$autoSync,
				prevModel,
				A2(
					$author$project$Effect$Resolution$convertToCmd,
					prevModel.hR,
					A2($author$project$Main$update, msg, prevModel))));
	});
var $author$project$Main$main = $elm$browser$Browser$application(
	{
		hn: $author$project$Main$init,
		h7: $author$project$Messages$OnUrlChange,
		h8: $author$project$Messages$OnUrlRequest,
		jl: $author$project$Main$subscriptions,
		jC: $author$project$Main$wrappedUpdate,
		jN: A2(
			$elm$core$Basics$composeR,
			$author$project$Main$view,
			function (node) {
				return {
					gs: _List_fromArray(
						[node]),
					fx: 'eSpark Student Login'
				};
			})
	});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (timezoneOffset) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (supportsVideoRecording) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (studentAppBuildTimestamp) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (studentAppBuildHash) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (storedAppTimer) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (serverBaseUrl) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (randomSeed) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (pleBaseUrl) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (os) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (newVersionCheckUrl) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (mediaServerHealthCheckUrl) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (localStorage) {
																									return A2(
																										$elm$json$Json$Decode$andThen,
																										function (isTablet) {
																											return A2(
																												$elm$json$Json$Decode$andThen,
																												function (inEsparkIosApp) {
																													return A2(
																														$elm$json$Json$Decode$andThen,
																														function (esparkClientVersion) {
																															return A2(
																																$elm$json$Json$Decode$andThen,
																																function (esparkAccessToken) {
																																	return A2(
																																		$elm$json$Json$Decode$andThen,
																																		function (environment) {
																																			return A2(
																																				$elm$json$Json$Decode$andThen,
																																				function (deviceIdentifier) {
																																					return A2(
																																						$elm$json$Json$Decode$andThen,
																																						function (devFlags) {
																																							return A2(
																																								$elm$json$Json$Decode$andThen,
																																								function (decodedResumeURL) {
																																									return A2(
																																										$elm$json$Json$Decode$andThen,
																																										function (clientBaseUrl) {
																																											return A2(
																																												$elm$json$Json$Decode$andThen,
																																												function (choiceTextBaseUrl) {
																																													return A2(
																																														$elm$json$Json$Decode$andThen,
																																														function (browser) {
																																															return A2(
																																																$elm$json$Json$Decode$andThen,
																																																function (appStartedAt) {
																																																	return $elm$json$Json$Decode$succeed(
																																																		{bv: appStartedAt, ca: browser, gD: choiceTextBaseUrl, bz: clientBaseUrl, gM: decodedResumeURL, dO: devFlags, gQ: deviceIdentifier, dS: environment, gW: esparkAccessToken, gX: esparkClientVersion, d8: inEsparkIosApp, ef: isTablet, hz: localStorage, bK: mediaServerHealthCheckUrl, bM: newVersionCheckUrl, cM: os, bR: pleBaseUrl, ix: randomSeed, fa: serverBaseUrl, i9: storedAppTimer, b0: studentAppBuildHash, bo: studentAppBuildTimestamp, fp: supportsVideoRecording, fv: timezoneOffset});
																																																},
																																																A2($elm$json$Json$Decode$field, 'appStartedAt', $elm$json$Json$Decode$int));
																																														},
																																														A2(
																																															$elm$json$Json$Decode$field,
																																															'browser',
																																															A2(
																																																$elm$json$Json$Decode$andThen,
																																																function (version) {
																																																	return A2(
																																																		$elm$json$Json$Decode$andThen,
																																																		function (name) {
																																																			return $elm$json$Json$Decode$succeed(
																																																				{cE: name, jH: version});
																																																		},
																																																		A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
																																																},
																																																A2($elm$json$Json$Decode$field, 'version', $elm$json$Json$Decode$string))));
																																												},
																																												A2($elm$json$Json$Decode$field, 'choiceTextBaseUrl', $elm$json$Json$Decode$string));
																																										},
																																										A2($elm$json$Json$Decode$field, 'clientBaseUrl', $elm$json$Json$Decode$string));
																																								},
																																								A2(
																																									$elm$json$Json$Decode$field,
																																									'decodedResumeURL',
																																									$elm$json$Json$Decode$oneOf(
																																										_List_fromArray(
																																											[
																																												$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																																												A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
																																											]))));
																																						},
																																						A2(
																																							$elm$json$Json$Decode$field,
																																							'devFlags',
																																							A2(
																																								$elm$json$Json$Decode$andThen,
																																								function (videoPlaybackControls) {
																																									return A2(
																																										$elm$json$Json$Decode$andThen,
																																										function (returnNotificationDelay) {
																																											return A2(
																																												$elm$json$Json$Decode$andThen,
																																												function (requestTimeoutLength) {
																																													return A2(
																																														$elm$json$Json$Decode$andThen,
																																														function (eventLoggingFrequency) {
																																															return A2(
																																																$elm$json$Json$Decode$andThen,
																																																function (appTimerLength) {
																																																	return A2(
																																																		$elm$json$Json$Decode$andThen,
																																																		function (animate) {
																																																			return $elm$json$Json$Decode$succeed(
																																																				{f6: animate, gb: appTimerLength, gY: eventLoggingFrequency, iC: requestTimeoutLength, iG: returnNotificationDelay, jJ: videoPlaybackControls});
																																																		},
																																																		A2($elm$json$Json$Decode$field, 'animate', $elm$json$Json$Decode$bool));
																																																},
																																																A2(
																																																	$elm$json$Json$Decode$field,
																																																	'appTimerLength',
																																																	$elm$json$Json$Decode$oneOf(
																																																		_List_fromArray(
																																																			[
																																																				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																																																				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
																																																			]))));
																																														},
																																														A2(
																																															$elm$json$Json$Decode$field,
																																															'eventLoggingFrequency',
																																															$elm$json$Json$Decode$oneOf(
																																																_List_fromArray(
																																																	[
																																																		$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																																																		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
																																																	]))));
																																												},
																																												A2($elm$json$Json$Decode$field, 'requestTimeoutLength', $elm$json$Json$Decode$float));
																																										},
																																										A2(
																																											$elm$json$Json$Decode$field,
																																											'returnNotificationDelay',
																																											$elm$json$Json$Decode$oneOf(
																																												_List_fromArray(
																																													[
																																														$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																																														A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
																																													]))));
																																								},
																																								A2($elm$json$Json$Decode$field, 'videoPlaybackControls', $elm$json$Json$Decode$bool))));
																																				},
																																				A2(
																																					$elm$json$Json$Decode$field,
																																					'deviceIdentifier',
																																					$elm$json$Json$Decode$oneOf(
																																						_List_fromArray(
																																							[
																																								$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																																								A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
																																							]))));
																																		},
																																		A2($elm$json$Json$Decode$field, 'environment', $elm$json$Json$Decode$string));
																																},
																																A2(
																																	$elm$json$Json$Decode$field,
																																	'esparkAccessToken',
																																	$elm$json$Json$Decode$oneOf(
																																		_List_fromArray(
																																			[
																																				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																																				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
																																			]))));
																														},
																														A2(
																															$elm$json$Json$Decode$field,
																															'esparkClientVersion',
																															$elm$json$Json$Decode$oneOf(
																																_List_fromArray(
																																	[
																																		$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																																		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
																																	]))));
																												},
																												A2($elm$json$Json$Decode$field, 'inEsparkIosApp', $elm$json$Json$Decode$bool));
																										},
																										A2($elm$json$Json$Decode$field, 'isTablet', $elm$json$Json$Decode$bool));
																								},
																								A2($elm$json$Json$Decode$field, 'localStorage', $elm$json$Json$Decode$string));
																						},
																						A2($elm$json$Json$Decode$field, 'mediaServerHealthCheckUrl', $elm$json$Json$Decode$string));
																				},
																				A2($elm$json$Json$Decode$field, 'newVersionCheckUrl', $elm$json$Json$Decode$string));
																		},
																		A2(
																			$elm$json$Json$Decode$field,
																			'os',
																			A2(
																				$elm$json$Json$Decode$andThen,
																				function (version) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (name) {
																							return $elm$json$Json$Decode$succeed(
																								{cE: name, jH: version});
																						},
																						A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
																				},
																				A2($elm$json$Json$Decode$field, 'version', $elm$json$Json$Decode$string))));
																},
																A2($elm$json$Json$Decode$field, 'pleBaseUrl', $elm$json$Json$Decode$string));
														},
														A2($elm$json$Json$Decode$field, 'randomSeed', $elm$json$Json$Decode$float));
												},
												A2($elm$json$Json$Decode$field, 'serverBaseUrl', $elm$json$Json$Decode$string));
										},
										A2(
											$elm$json$Json$Decode$field,
											'storedAppTimer',
											$elm$json$Json$Decode$oneOf(
												_List_fromArray(
													[
														$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
														A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
													]))));
								},
								A2($elm$json$Json$Decode$field, 'studentAppBuildHash', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'studentAppBuildTimestamp', $elm$json$Json$Decode$int));
				},
				A2($elm$json$Json$Decode$field, 'supportsVideoRecording', $elm$json$Json$Decode$bool));
		},
		A2($elm$json$Json$Decode$field, 'timezoneOffset', $elm$json$Json$Decode$int)))(0)}});}(this));