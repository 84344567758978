import * as SentryClient from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import escapeRegExp from "lodash/escapeRegExp";

import {
  logXhrBreadcrumb,
  logBreadcrumb,
  logNavBreadcrumb,
  logLearnosityErrorBreadcrumb
} from "javascript/breadcrumbLogging";

import SentryDataStorage from "javascript/Monitor/DebugDataStorage";

/*
  This wraps the Sentry client so we can catch errors in case the library
  isn't fully loaded when we start reporting.
*/

export default class Sentry {
  static config = {
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_DEPLOY_ENVIRONMENT,
    debug: process.env.SENTRY_DEBUG,
    tracesSampleRate: 0.01,
    integrations: [new TracingIntegrations.BrowserTracing()],
    beforeBreadcrumb,
    beforeSend,
    integrations
  };

  static init(retry = false) {
    try {
      SentryClient.init(Sentry.config);
    } catch (e) {
      console.log("Sentry error initializing library", e);
    }
  }

  static captureException(exception, data = {}, init = true) {
    try {
      data.level = data.level || "error"; // "fatal" is also an option
      executeWithScope(SentryClient.captureException.bind(null, exception), mergeDebugScope(data));
    } catch (e) {
      console.log("Sentry error capturing exception.", e);
      // avoid infinite loops retrying initialization when calling this function internally in this file.
      init && Sentry.init(true);
    }
  }

  static captureMessage(message, data = {}, init = true) {
    try {
      data.level = data.level || "debug";
      executeWithScope(SentryClient.captureMessage.bind(null, message), mergeDebugScope(data));
    } catch (e) {
      console.log("Sentry error capturing message.", e);
      // avoid infinite loops retrying initialization when calling this function internally in this file.
      init && Sentry.init(true);
    }
  }

  static addBreadcrumb(args, init = true) {
    try {
      SentryClient.addBreadcrumb(args);
    } catch (e) {
      console.log("Sentry error adding breadcrumb.", e);
      // avoid infinite loops retrying initialization when calling this function internally in this file.
      init && Sentry.init(true);
    }
  }

  static configureScope(callback, init = true) {
    try {
      SentryClient.configureScope(callback);
    } catch (e) {
      console.log("Sentry error configuring scope.", e);
      // avoid infinite loops retrying initialization when calling this function internally in this file.
      init && Sentry.init(true);
    }
  }

  static withScope(callback, init = true) {
    try {
      SentryClient.withScope(callback);
    } catch (e) {
      console.log("Sentry error executing code with scope.", e);
      // avoid infinite loops retrying initialization when calling this function internally in this file.
      init && Sentry.init(true);
    }
  }

  static startTransaction(data) {
    try {
      return SentryClient.startTransaction(data);
    } catch (e) {
      console.log("Sentry error executing code with scope.", e);
      // avoid infinite loops retrying initialization when calling this function internally in this file.
      init && Sentry.init(true);
    }
  }
}

/* INTERNAL, these call functions on the above wrapped Sentry client, named Sentry (not the imported SentryClient) */

// Incoming scope takes precidence over what is stored in local storage.
function mergeDebugScope({ level = "debug", ...scope }) {
  return { level, ...SentryDataStorage.getAndMerge(scope) };
}

function executeWithScope(callback, values = {}) {
  Sentry.withScope(scope => {
    setScopeValues(scope, values);
    callback();
  }, false);
}

function setScopeValues(scope, { user, level, extra = [], tags = [] } = {}) {
  user && scope.setUser(user);
  level && scope.setLevel(level);

  // See https://docs.sentry.io/platforms/javascript/enriching-events/tags/ for documentation of
  // maximum lengths
  tags.forEach(([prop, val]) => {
    scope.setTag(prop.slice(0, 32), typeof val === "string" ? val.slice(0, 200) : val);
  });
  extra.forEach(([prop, val]) => scope.setExtra(prop, val));
}

/* INTERNAL, these functions handle complicated logic for extracting debugging information from breadcrumbs generated
  by Sentry upon encountering a Learnosity error anywhere within its life cycle. We were previously injecting error handlers
  manually through their callbacks, but that doesn't capture all related errors. Noticing that Sentry was able to catch them,
  as breadcrumbs, we're hooking in and generating a full sentry error message.

  Example:
  incoming breadcrumb has message
  "20013: Requested items could not be retrieved. Items references: caf8d938-0fec-450b-93f6-89a56eb7ffbc. View this error on LearnosityItems.errors[0]"

  `learnositySegments` would be:
    [
      "20013: Requested items could not be retrieved. Items references: caf8d938-0fec-450b-93f6-89a56eb7ffbc. View this error on ",
      "Items.errors[0]"
    ]

    Error messages are scoped to the app and could be LearnosityAssess, LearnosityApp, LearnosityItems, so we need to extract that piece.
  `learnosityErrorParsingSegments` would be `["Items", "errors", "0", ""]`

  Errors are also stored in the global learnosity objects and often hold additional debugging information in `details`. This information isn't included in
  the message. Using the thrid element in the array, we can get the full error message. Using the first element, we can construct the global object
  to get that from, and use it to add some further info to the Sentry error message. See below Sentry.captureMessage().
*/

function beforeBreadcrumb(breadcrumb, _hint) {
  if (breadcrumb.category === "console") {
    // if we are actually sending events to Sentry (determined by whether we set a value for the DSN),
    // send the console.log messages but remove the extra data it generates.
    if (Boolean(process.env.SENTRY_DSN)) {
      delete breadcrumb.data;
    } else {
      // otherwise, don't get into an infinite loop due to generating breadcrumbs from console.logs
      // within this callback; it already logged to the console.
      return null;
    }
  } else if (breadcrumb.category === "xhr" || breadcrumb.category === "fetch") {
    const url = breadcrumb.data.url;

    if (process.env.LOG_APP_EVENTS) {
      logXhrBreadcrumb(breadcrumb);
    }
  } else if (process.env.LOG_APP_EVENTS && breadcrumb.category === "navigation") {
    logNavBreadcrumb(breadcrumb);
    // default log format
  } else if (process.env.LOG_APP_EVENTS) {
    logBreadcrumb(breadcrumb);
  }

  return breadcrumb;
}

function integrations(ints) {
  // Filter out the breadcrumbs integration and replace it below.
  // https://docs.sentry.io/platforms/javascript/#default-integrations
  const defaultIntegrations = ints.filter(integration => integration.name !== "Breadcrumbs");

  // Construct a new Breadcrumbs integration which can toggle off wrapping of console messages in a
  // Sentry object in development so that identifying where log messages originate is easier.
  // https://docs.sentry.io/platforms/javascript/#breadcrumbs-1
  const breadcrumbIntegration = new SentryClient.Integrations.Breadcrumbs({
    beacon: true,
    console: !process.env.SENTRY_UNWRAP_CONSOLE, // when console is false you can inspect where console.log messages are originating
    dom: true,
    fetch: true,
    history: true,
    sentry: true,
    xhr: true
  });

  // This is an  attempt to tame the extremely high volume of the same issue errors reported within
  // https://sentry.io/organizations/espark-learning/issues/1198006872/
  // https://docs.sentry.io/platforms/javascript/#dedupe
  const dedupeIntegration = new Integrations.Dedupe();

  return [...defaultIntegrations, breadcrumbIntegration, dedupeIntegration];
}

// https://docs.sentry.io/error-reporting/configuration/filtering/?platform=browser
function beforeSend(event, hint) {
  if (shouldSend(event, hint)) {
    if (process.env.SENTRY_DEBUG) {
      console.log("----------SENTRY - SENDING event", event);
    }
    // 2019/08/30 CA: This can add some missed context for unhandled rejections that otherwise lack helpful information.
    // https://sentry.io/organizations/espark-learning/issues/1134363105
    // https://github.com/getsentry/sentry-javascript/issues/2210
    if (hint && hint.originalException instanceof Event) {
      event.extra.isTrusted = hint.originalException.isTrusted;
      event.extra.detail = hint.originalException.detail;
      event.extra.type = hint.originalException.type;
    }

    return event;
  } else {
    if (process.env.SENTRY_DEBUG) {
      console.log("--------SENTRY - EXCLUDING event", event);
    }
    return null;
  }
}

function shouldSend(event, hint) {
  try {
    return !globallyExcluded(event, hint) && !quizErrorOnNonQuizRoute(event, hint);
  } catch (_e) {
    // something failed in trying to filter events; keep it!
    return true;
  }
}

// ***********************************************************
// TODO: Extract the concerns below into a separate file.
// Below is helpers for the `shouldSend` function to determine which events to send to Sentry.

// Matches will *NOT* be sent to Sentry.
// TODO: use the escapeRegExp function instead of manually escaping.
const EXCLUDE_ALL_ERRORS_ESCAPED_STRINGS = [
  "replaceData is not a function", // https://sentry.io/organizations/espark-learning/issues/901743688
  "Cannot read property \\'childNodes\\' of undefined", // https://sentry.io/organizations/espark-learning/issues/903429402
  "null is not an object \\(evaluating \\'[a-z]\\.childNodes\\'\\)", // https://sentry.io/organizations/espark-learning/issues/944409663
  "play\\(\\) failed because the user didn\\'t interact", // https://sentry.io/organizations/espark-learning/issues/746719761
  "Failed to execute \\'removeChild\\' on \\'Node\\'", // https://sentry.io/organizations/espark-learning/issues/903145004
  "The play\\(\\) request was interrupted", // https://sentry.io/organizations/espark-learning/issues/861431913
  "The operation was aborted", // https://sentry.io/organizations/espark-learning/issues/1198006872
  "^CustomEvent$", // https://sentry.io/organizations/espark-learning/issues/1134363105
  "The operation would yield an incorrect node tree", // https://sentry.io/organizations/espark-learning/issues/1119094532
  "ResizeObserver loop limit exceeded", // https://sentry.io/organizations/espark-learning/issues/1197794549
  "ResizeObserver loop completed with undelivered notifications" // https://espark-learning.sentry.io/issues/4217033333
];

// Matches will *NOT* be sent to Sentry are specific to occurances on NON-QUIZ pages (that is, exceptions with these string that occur
// ON quiz pages will not be excluded as a result of adding it to this list.)
const EXCLUDE_FOR_NON_QUIZ_ESCAPED_STRINGS = [
  escapeRegExp("undefined is not an object (evaluating 't[\".items\"].region_params')"),
  escapeRegExp("LearnosityError"),
  escapeRegExp("Cannot read property 'resetInstance' of undefined"),
  escapeRegExp("App stub"),
  escapeRegExp("undefined is not an object (evaluating 'this.activity.regions')")
];

function globallyExcluded(event, _hint) {
  const regex = new RegExp(EXCLUDE_ALL_ERRORS_ESCAPED_STRINGS.join("|"));
  return regex.test(eventMessage(event));
}

function quizErrorOnNonQuizRoute(event, _hint) {
  const url = eventUrl(event);

  const isPageWithLearnosity = url =>
    url.includes("quiz") && !(url.endsWith("intro") || url.endsWith("outro"));

  // stop checking if we can't confirm which page we're on OR we know we are definitely ON a
  // page with an assessment
  if (!url || isPageWithLearnosity(url)) {
    return false;
  }

  // if we're on a NON-quiz page and the stacktrace has *learnosity.com* hosted source files
  // EXCLUDE as a quiz-error on a non-quiz page! (this will likely be the most robust filter)
  const { exception } = event;
  if (exception && hasLearnosityStackFrames(exception)) {
    return true;
  }

  // if we're on a NON-quiz page and the mesesage matches our defined list of exclusion strings
  // EXCLUDE as a quiz-error on a non-quiz page!
  // Note: not all events attach a stack trace, so adding specific strings is still valid.
  const regex = new RegExp(EXCLUDE_FOR_NON_QUIZ_ESCAPED_STRINGS.join("|"));
  return regex.test(escapeRegExp(eventMessage(event)));
}

function eventUrl(event) {
  const { tags, request } = event;

  // the url is found in a various locations
  if (request && request.url) {
    return request.url;
  } else {
    const tagUrl = tags && tags.find(({ key }) => key === "url");
    return tagUrl && tagUrl.value;
  }
}

function eventMessage(event) {
  const { message, extra, exception, title } = event;

  // message data is found in various locations when not at the top level.
  return (
    message ||
    (extra && extra.__serialized__ && extra.__serialized__.message) ||
    (exception && exception.values && exception.values[0] && exception.values[0].value) ||
    title
  );
}

function hasLearnosityStackFrames(exception) {
  return exception.values[0].stacktrace.frames.some(({ filename }) => {
    return /learnosity\.com/.test(filename);
  });
}
